import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import { getEmptyErrorState, validate } from '../../../../utils/formValidation/validator';
import { clearKeys } from '../../../../utils/objectUtils';
import RadioButtons from '../../../formElements/RadioButtons';
import SingleSelect from '../../../formElements/SingleSelect';
import TextAreaField from '../../../formElements/TextAreaField';
import TextInputField from '../../../formElements/TextInputField';
import FormActions from '../../../ui/formActions/FormActions';
import SmartDefs from '../../../ui/notices/smartDefs/SmartDefs';

import { moduleTemplateEditorValidationFields } from './ModuleTemplateEditorValidationFields';

import app from '../../../../app.module.css';

const MODULE_DESCRIPTION_LIMIT = 250;
const ACTION_DESCRIPTION_LIMIT = 750;

const ModuleTemplateEditor = ({
    type,
    entry,
    disabled = false,
    onAdd,
    onUpdate,
    barrierNameDetails,
    isOpen,
    onClose
}) => {
    // LOCAL STATE
    const initialEntry = {
        id: uuid(),
        name: '',
        description: '',
        smartTarget: null
    };
    const validationFields = { ...moduleTemplateEditorValidationFields };
    const [newEntry, setNewEntry] = useState(initialEntry);
    const [errors, setErrors] = useState(getEmptyErrorState(validationFields));
    const [keys, setKeys] = useState({ smartTargetBarrierNameId: '1' });

    // USE EFFECTS
    useEffect(() => {
        type === 'edit' && setNewEntry(entry);
    }, []);

    // HELPER FUNCTIONS
    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    // EVENT HANDLERS
    const onModuleChange = (key, value) => {
        clearError(key);
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onSmartTargetFlagChange = (option) => {
        setNewEntry((prev) => ({
            ...prev,
            smartTarget: option ? { name: '', barrierNameId: '', action: '' } : null
        }));
        if (!option) {
            clearError('smartTargetActionName');
            clearError('smartTargetBarrierNameId');
            clearError('smartTargetActionDescription');
        }
    };

    const onSmartTargetChange = (key, value, errorKey) => {
        clearError(errorKey);
        setNewEntry((prev) => ({
            ...prev,
            smartTarget: { ...newEntry.smartTarget, [key]: value }
        }));
    };

    const onCancel = () => {
        setErrors(getEmptyErrorState(validationFields));
        setKeys(clearKeys(keys));
        onClose();
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const validation = validate(
            {
                ...newEntry,
                smartTargetFlag: !!newEntry.smartTarget,
                smartTargetActionName: newEntry.smartTarget?.name,
                smartTargetBarrierNameId: newEntry.smartTarget?.barrierNameId,
                smartTargetActionDescription: newEntry.smartTarget?.action
            },
            validationFields
        );
        setErrors(validation.errors);
        if (!validation.isValid) return;

        type === 'edit' ? onUpdate(newEntry) : onAdd(newEntry);
    };

    // RENDER
    return (
        <div>
            <Dialog open={isOpen} onClose={onCancel} aria-labelledby="form-dialog-title" fullWidth>
                <form onSubmit={onSubmit} data-testid="dialog_form_start">
                    <DialogTitle id="form-dialog-title">{`${type === 'edit' ? 'Edit' : 'Create'} Module Template`}</DialogTitle>
                    <DialogContent data-testid="dialog-content">
                        <TextInputField
                            id={'moduleName'}
                            label={'Module Name'}
                            placeholder={'Enter module name'}
                            mandatory={true}
                            value={newEntry.name || ''}
                            error={errors.name}
                            onChange={(e) => onModuleChange('name', e.target.value)}
                        />
                        <TextAreaField
                            id={'moduleDescription'}
                            label={'Module Description'}
                            placeholder={'Enter module description'}
                            mandatory={true}
                            maxLength={MODULE_DESCRIPTION_LIMIT}
                            value={newEntry.description}
                            count={`${newEntry.description.length}/${MODULE_DESCRIPTION_LIMIT}`}
                            error={errors.description}
                            onChange={(e) => onModuleChange('description', e.target.value)}
                        />
                        <RadioButtons
                            id={'smartTargetRadio'}
                            label={'Add Smart Target?'}
                            value={!!newEntry.smartTarget}
                            onChange={onSmartTargetFlagChange}
                        />
                        <div hidden={!newEntry.smartTarget}>
                            <h2 className={app.sectionHeading}>Smart Target Details:</h2>
                            <TextInputField
                                id={'smartTargetActionName'}
                                label={'What shall we call this action?'}
                                placeholder={'Enter the name of the action'}
                                mandatory={true}
                                value={newEntry.smartTarget?.name || ''}
                                error={errors.smartTargetActionName}
                                onChange={(e) =>
                                    onSmartTargetChange(
                                        'name',
                                        e.target.value,
                                        'smartTargetActionName'
                                    )
                                }
                            />
                            <SingleSelect
                                id={'smartTargetBarrierNameId'}
                                key={keys.smartTargetBarrierNameId}
                                label={'Which barrier name aligns to this smart target?'}
                                placeholder={'Select barrier name'}
                                mandatory={true}
                                menuItems={barrierNameDetails || []}
                                selectedId={newEntry.smartTarget?.barrierNameId || ''}
                                selected={
                                    barrierNameDetails.find(
                                        (el) => el.id === newEntry.smartTarget?.barrierNameId
                                    ) || {}
                                }
                                error={errors.smartTargetBarrierNameId}
                                onChange={(chosenId) =>
                                    onSmartTargetChange(
                                        'barrierNameId',
                                        chosenId,
                                        'smartTargetBarrierNameId'
                                    )
                                }
                            />
                            <SmartDefs />
                            <TextAreaField
                                id={'smartTargetActionDescription'}
                                label={'Action'}
                                placeholder={'Enter action description'}
                                mandatory={true}
                                maxLength={ACTION_DESCRIPTION_LIMIT}
                                value={newEntry.smartTarget?.action || ''}
                                count={`${newEntry.smartTarget?.action.length}/${ACTION_DESCRIPTION_LIMIT}`}
                                error={errors.smartTargetActionDescription}
                                onChange={(e) =>
                                    onSmartTargetChange(
                                        'action',
                                        e.target.value,
                                        'smartTargetActionDescription'
                                    )
                                }
                            />
                        </div>
                        <FormActions
                            id="publish"
                            data-testid="editmodule"
                            btnText={type === 'edit' ? 'Update' : 'Create'}
                            disabled={disabled}
                            onClose={onSubmit}
                            onCancel={onCancel}
                        />
                    </DialogContent>
                </form>
            </Dialog>
        </div>
    );
};

ModuleTemplateEditor.propTypes = {
    type: PropTypes.string,
    entry: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    onAdd: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    barrierNameDetails: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};
export default ModuleTemplateEditor;
