import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';

import {
    loadJobSectorDetails,
    loadPoolStatusDetails,
    loadVacancyStageDetails
} from '../../../../store/directusService';
import { loadParticipantDocumentDetails } from '../../../../store/documentService';
import { setErrorMessage } from '../../../../store/formsState';
import { loadParticipant } from '../../../../store/participantService';
import {
    createVacancySubmissions,
    loadPoolApplicationsForOwnersByPoolIds,
    loadPoolsForOwnersByUser
} from '../../../../store/recruitmentService';
import { searchUsersByLoggedInUserServiceIds } from '../../../../store/userService';
import { getFutureDate } from '../../../../utils/dateFunctions';
import { NO_UPDATE_ERROR } from '../../../../utils/formValidation/commonErrorConstants';
import { JOB_SECTOR_DETAILS_ERROR } from '../../../../utils/formValidation/loadingErrorMessageConstants';
import { clearKeys } from '../../../../utils/objectUtils';
import { addEmailAddressAsNameToArray } from '../../../../utils/userArrayUtils';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import Button from '../../../formElements/Button';
import MultiSelect from '../../../formElements/MultiSelect';
import SingleSelect from '../../../formElements/SingleSelect';
import DynamicSearch from '../../../search/DynamicSearch';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import SubmissionPoolTable from './SubmissionPoolTable';

import form from '../../../../commonStyles/formStyles.module.css';
import classes from '../../recruitmentStyles/submission.module.css';

const SubmissionPoolTableManagement = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const poolOwnerRoles = [MANAGER, RECRUITMENT_MANAGER];
    const message = `Vacancy submissions have been added`;
    const [rows, setRows] = useState([]);
    const [updatedRows, setUpdatedRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const [poolApplicationsLoaded, setPoolApplicationsLoaded] = useState(false);
    const [poolOwners, setPoolOwners] = useState([]);
    const [selectedPoolOwner, setSelectedPoolOwner] = useState('');
    const [preSelectedJobSectorIds, setPreSelectedJobSectorIds] = useState([]);
    const [selectedJobSectorIds, setSelectedJobSectorIds] = useState([]);
    const [arrayJobSectors, setArrayJobSectors] = useState([]);

    const [keys, setKeys] = useState({ sectorIds: '0', ownerIds: '1' });

    // STORE STATE
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const jobSectorDetails = useSelector(
        (state) => state.entities.directusService.jobSectorDetails
    );
    const poolStatusDetails = useSelector(
        (state) => state.entities.directusService.poolStatusDetails
    );
    const vacancyStageDetails = useSelector(
        (state) => state.entities.directusService.vacancyStageDetails
    );
    const participantDocuments = useSelector(
        (state) => state.entities.documentService.participantDocuments
    );
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const currentVacancy = useSelector((state) => state.entities.recruitmentService.currentVacancy);
    const poolApplicationsForOwners = useSelector(
        (state) => state.entities.recruitmentService.poolApplicationsForOwners
    );
    const poolsForOwners = useSelector((state) => state.entities.recruitmentService.poolsForOwners);
    const vacancySubmissions = useSelector(
        (state) => state.entities.recruitmentService.vacancySubmissions
    );
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);
    const userMetaData = useSelector(
        (state) => state.entities.userService.usersByLoggedInUserServiceIdsMetaData
    );
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);

    // USE EFFECTS
    useEffect(() => {
        setKeys(clearKeys(keys));

        jobSectorDetails?.length < 1 && dispatch(loadJobSectorDetails());
        poolStatusDetails?.length < 1 && dispatch(loadPoolStatusDetails());
        vacancyStageDetails?.length < 1 && dispatch(loadVacancyStageDetails());
    }, []);

    useEffect(() => {
        if (!loggedInUser || Object.keys(loggedInUser).length < 1) return;
        if (loggedInUser.roles.some((el) => poolOwnerRoles.includes(el))) {
            setSelectedPoolOwner(loggedInUser.id);
            setPoolOwnersArray([loggedInUser]);
        }
    }, [loggedInUser.id]);

    useEffect(() => {
        if (!users.length) return;
        let updatedPoolOwners = users.filter((el) =>
            el.userTypes?.find((entry) => poolOwnerRoles.includes(entry.role))
        );
        if (!updatedPoolOwners.length && users.length < userMetaData.totalElements) {
            onLoadMorePoolOwners();
            return;
        }
        if (selectedPoolOwner && !updatedPoolOwners.some((el) => el.id === selectedPoolOwner)) {
            // Put selected pool owner at the top of dropdown if it's not in the updated pool owners array
            const selectedAdvisor = poolOwners.find((el) => el.id === selectedPoolOwner);
            updatedPoolOwners = [selectedAdvisor, ...updatedPoolOwners];
        }
        setPoolOwnersArray(updatedPoolOwners);
    }, [users]);

    useEffect(() => {
        if (!selectedPoolOwner) return;
        dispatch(loadPoolsForOwnersByUser(selectedPoolOwner));
    }, [selectedPoolOwner]);

    useEffect(() => {
        if (jobSectorDetails?.length && arrayJobSectors?.length === 0) {
            setPreSelectedJobSectorIds(jobSectorDetails.map((el) => el.id));
            setArrayJobSectors(jobSectorDetails);
        }
    }, [jobSectorDetails]);

    useEffect(() => {
        if (poolsForOwners?.length < 1) {
            setRows([]);
            // setFilteredRows([]);
            setUpdatedRows([]);
            setPoolApplicationsLoaded(true);
            return;
        }

        setPoolApplicationsLoaded(false);
        const filteredPools = poolsForOwners.filter((el) => el.ownerId === selectedPoolOwner);

        if (filteredPools?.length < 1) {
            setRows([]);
            setUpdatedRows([]);
            setPoolApplicationsLoaded(true);
            return;
        }

        const filteredPoolIds = filteredPools.map((el) => el.id);
        const filteredPoolsWithId = filteredPools.map((el) => ({
            ...el,
            id: uuid(),
            poolId: el.id
        }));

        const filteredRowsWithSectorName = filteredPoolsWithId.map((el) => ({
            ...el,
            jobSectorName: jobSectorDetails.find((entry) => entry.id === el.jobSectorId)?.name || ''
        }));

        dispatch(loadPoolApplicationsForOwnersByPoolIds(filteredPoolIds));
        setRows(filteredRowsWithSectorName);
        setUpdatedRows(filteredRowsWithSectorName);
    }, [poolsForOwners]);

    useEffect(() => {
        if (poolApplicationsForOwners.length < 1 || rows?.length < 1) return;
        let updatedPoolsWithApplications = rows;

        poolApplicationsForOwners.forEach((el) => {
            let newPoolEntryArray = [];
            updatedPoolsWithApplications = updatedPoolsWithApplications.map((entry) => {
                if (entry.poolId === el.participantPoolId) {
                    if (entry.participantId) {
                        const newPoolEntry = {
                            id: uuid(),
                            ownerId: entry.ownerId,
                            jobSectorId: entry.jobSectorId,
                            jobSectorName: entry.jobSectorName,
                            teamIds: entry.teamIds,
                            poolId: entry.poolId,
                            requirements: entry.requirements,
                            ptCode: '',
                            ptName: '',
                            filename: '',
                            participantId: el.participantId,
                            goldStandardCVId: el.goldStandardCVId,
                            startDate: el.startDate || '',
                            statusId: el.statusId
                        };
                        // Check for duplicate
                        if (newPoolEntryArray.length > 0) {
                            if (
                                newPoolEntryArray.some(
                                    (npe) =>
                                        npe.jobSectorId === newPoolEntry.jobSectorId &&
                                        npe.poolId === newPoolEntry.poolId
                                )
                            ) {
                                return entry;
                            }
                        }
                        newPoolEntryArray = [...newPoolEntryArray, newPoolEntry];
                        return entry;
                    } else {
                        return {
                            ...entry,
                            ptCode: '',
                            ptName: '',
                            filename: '',
                            participantId: el.participantId,
                            goldStandardCVId: el.goldStandardCVId,
                            startDate: el.startDate || '',
                            statusId: el.statusId
                        };
                    }
                } else {
                    if (entry.participantId) {
                        return entry;
                    } else {
                        return {
                            ...entry,
                            ptCode: '',
                            ptName: '',
                            filename: '',
                            participantId: null,
                            goldStandardCVId: null,
                            startDate: '',
                            statusId: '',
                            statusName: ''
                        };
                    }
                }
            });
            if (Object.keys(newPoolEntryArray).length > 0) {
                updatedPoolsWithApplications = [
                    ...updatedPoolsWithApplications,
                    ...newPoolEntryArray
                ];
            }
        });
        // We only want entries where the participant has been accepted to a pool
        const statusId =
            poolStatusDetails.find((el) => el.name.toLowerCase() === 'accepted')?.id || '';
        const acceptedPools = updatedPoolsWithApplications.filter((el) => el.statusId === statusId);
        // We also need to check whether they have already been submitted to
        // a vacancy submission and remove duplicates without having to dispatch
        // again for efficiency
        const poolsNotAlreadySubmitted = acceptedPools.filter(
            (el) => !vacancySubmissions.find((entry) => entry.participantId === el.participantId)
        );
        if (!poolApplicationsLoaded) {
            setPoolApplicationsLoaded(true);
            setRows(poolsNotAlreadySubmitted);
            setUpdatedRows(poolsNotAlreadySubmitted);
            const participantIds = [
                ...new Set(poolsNotAlreadySubmitted.map((el) => el.participantId))
            ];
            participantIds.map((el) => {
                if (el) {
                    dispatch(loadParticipant(el));
                    dispatch(loadParticipantDocumentDetails(el));
                }
            });
        } else {
            const poolsRemoveDuplicates = poolsNotAlreadySubmitted.filter(
                (
                    (el) =>
                    ({ ptCode, participantId, poolId }) =>
                        (ptCode !== '' && !el.has(participantId)) ||
                        (ptCode !== '' && !el.has(poolId) && el.add(poolId))
                )(new Set())
            );
            setRows(poolsRemoveDuplicates);
            setUpdatedRows(poolsRemoveDuplicates);
        }
    }, [poolApplicationsForOwners, vacancySubmissions]);

    useEffect(() => {
        if (Object.keys(currentParticipant)?.length > 0) {
            rows.map((el) => {
                if (el.participantId === currentParticipant.id) {
                    el.ptCode = currentParticipant.ptCode;
                    el.ptName = `${currentParticipant.firstName} ${currentParticipant.lastName}`;
                }
            });
            setUpdatedRows(rows);
        }
    }, [currentParticipant]);

    useEffect(() => {
        if (participantDocuments?.length > 0) {
            rows.map((el) => {
                participantDocuments.map((entry) => {
                    if (
                        el.participantId === entry.participantId &&
                        el.goldStandardCVId === entry.id
                    )
                        el.filename = entry?.filename || '';
                });
            });
            setUpdatedRows(rows);
        }
    }, [participantDocuments]);

    useEffect(() => {
        if (jobSectorDetails?.length && arrayJobSectors?.length === 0) {
            setPreSelectedJobSectorIds(jobSectorDetails.map((el) => el.id));
            setArrayJobSectors(jobSectorDetails);
        }
    }, [jobSectorDetails]);

    useEffect(() => {
        if (rows.length < 1 || selectedJobSectorIds.length < 1) return;
        const filteredRows = rows.filter((el) =>
            selectedJobSectorIds.some((v) => v === el.jobSectorId)
        );
        // setFilteredRows(rows);
        setUpdatedRows(filteredRows);
    }, [selectedJobSectorIds]);

    useEffect(() => {
        if (successMessage === `Pool applications for owners have been loaded`) {
            setPoolApplicationsLoaded(true);
        }
    }, [successMessage]);

    useEffect(() => {
        const filteredRows = !searchTerm
            ? rows
            : rows.filter((el) => el.ptName.toLowerCase().includes(searchTerm.toLowerCase()));
        setUpdatedRows(filteredRows);
    }, [searchTerm]);

    // HELPER FNS
    const loadingError = () => {
        if (jobSectorDetails?.length < 1) return JOB_SECTOR_DETAILS_ERROR;
        if (!poolApplicationsLoaded) return 'pool applications not loaded';
    };

    const setPoolOwnersArray = (poolOwners) => {
        const updatedPoolOwners = addEmailAddressAsNameToArray(poolOwners);
        setPoolOwners(updatedPoolOwners);
    };

    // EVENT HANDLERS
    const onSearch = (searchRes) => setSearchTerm(searchRes);

    const onPoolOwnerChange = (chosenId) => {
        if (!chosenId) chosenId = '';
        setSelectedPoolOwner(chosenId);
    };

    const onJobSectorChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setSelectedJobSectorIds(chosenIds);
    };

    const onRowChange = (row) => {
        const rows = updatedRows.map((el) => (el.id === row.id ? row : el));
        setUpdatedRows(rows);
    };

    const onLoadMorePoolOwners = () => {
        if ((!users.length || users.length < userMetaData.totalElements) && !userMetaData.last) {
            dispatch(
                searchUsersByLoggedInUserServiceIds(
                    loggedInUser.serviceIds,
                    !users.length ? 0 : userMetaData.number + 1
                )
            );
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const statusId =
            vacancyStageDetails.find((el) => el.name.toLowerCase() === 'submitted')?.id || '';

        const vacancySubmissionIds = updatedRows
            .filter((el) => el.update === true)
            .map((el) => ({
                participantId: el.participantId,
                statusId: statusId,
                goldStandardCvId: el.goldStandardCVId
            }));
        if (vacancySubmissionIds?.length < 1) {
            dispatch(setErrorMessage(NO_UPDATE_ERROR));
        } else {
            const payload = { submissions: vacancySubmissionIds };
            dispatch(createVacancySubmissions(payload, currentVacancy.id, message));
        }
    };

    // AWAITING CONTENT
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} time={3} />;

    // RENDER
    return (
        <>
            <form onSubmit={onSubmit} data-testid="form_start" className={form.form}>
                <div className={classes.submissionToolBar}>
                    <div className={classes.search}>
                        <DynamicSearch
                            search={onSearch}
                            searchTerm={searchTerm}
                            placeholder="Enter Search Term"
                        />
                    </div>
                    <SingleSelect
                        id={'poolOwnerId'}
                        key={keys.ownerId}
                        label={'Pool Owner'}
                        placeholder="Select Pool Owner..."
                        disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                        mandatory={true}
                        menuItems={poolOwners}
                        selectedId={selectedPoolOwner}
                        selected={poolOwners?.find((el) => el.id === selectedPoolOwner) || {}}
                        onLoadMoreItems={onLoadMorePoolOwners}
                        moreItemsToLoad={
                            !userMetaData.totalElements || users.length < userMetaData.totalElements
                        }
                        onChange={(e) => {
                            if (e !== null && e !== undefined) {
                                onPoolOwnerChange(e);
                            }
                        }}
                    />
                    <MultiSelect
                        id="jobSectors"
                        key={keys.sectorIds}
                        label="Job Sector"
                        placeholder="Job Sector"
                        disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                        menuItems={jobSectorDetails || []}
                        preSelectedIds={preSelectedJobSectorIds || []}
                        preSelects={jobSectorDetails || []}
                        onChange={(chosenIds) => onJobSectorChange(chosenIds)}
                    />
                </div>

                {updatedRows.length < 1 ? (
                    <LoadingSpinner content="No pools found" />
                ) : (
                    <SubmissionPoolTable
                        rows={updatedRows}
                        roles={loggedInUser.roles}
                        searchTerm={searchTerm}
                        onRowChange={onRowChange}
                    />
                )}

                <div className={classes.bottomBorder}>
                    <Button
                        id="create"
                        content="Update"
                        disabled={
                            !hasRole(acceptedRoles, loggedInUser.roles) ||
                            !getFutureDate(currentVacancy.vacancyClosingDate)
                        }
                    />
                </div>
            </form>
        </>
    );
};

export default SubmissionPoolTableManagement;
