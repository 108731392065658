import {
    validateAdviser,
    validateAppointmentType,
    validateDate,
    validateEndTime,
    validateLocation,
    validateParticipant,
    validateService,
    validateStartTime
} from './validationFunctions';

export const validate = (newEntry) => {
    let newErrors = {};

    newErrors = validateAppointmentType(newEntry.typeId, newErrors);
    newErrors = validateService(newEntry.serviceId, newErrors);
    newErrors = validateAdviser(newEntry.userId, newErrors);
    newErrors = validateLocation(newEntry.locationId, newErrors);
    newErrors = validateStartTime(newEntry.startTime, newEntry.endTime, newErrors);
    newErrors = validateEndTime(newEntry.endTime, newEntry.startTime, newErrors);
    newErrors = validateDate(newEntry.date, newErrors);
    newErrors = !newEntry?.numberOfSlots
        ? validateParticipant(newEntry.participantId, newErrors)
        : newErrors;

    return newErrors;
};
