import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { hasRole, PRAP, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import DateSelect from '../../formElements/DateSelect';
import MultiSelect from '../../formElements/MultiSelect';
import SingleSelect from '../../formElements/SingleSelect';

import form from '../../../commonStyles/formStyles.module.css';

export const initialAttendanceOutcomeStatus = {
    contract: {},
    attendanceStatuses: [],
    date: String(new Date().toISOString().split('T')[0])
};

const ModalContentAttendanceOutcome = ({
    contracts = [],
    errors,
    selects,
    attendanceStatuses,
    onChange
}) => {
    return (
        <div className={form.formSection}>
            <div className={form.formColumn}>
                <MultiSelect
                    id={'attendanceId'}
                    label="Attendance Statuses"
                    placeholder="Select Attendance Statuses..."
                    mandatory={true}
                    menuItems={attendanceStatuses || []}
                    preSelectedIds={(selects.attendanceStatuses || []).map((el) => el.id)}
                    preSelects={selects.attendanceStatuses || []}
                    error={errors.attendanceStatuses}
                    onChange={(ids) =>
                        onChange(
                            'attendanceStatuses',
                            attendanceStatuses.filter((el) => ids.includes(el.id))
                        )
                    }
                />
                <DateSelect
                    id="datePicker"
                    label={'Date Range'}
                    value={selects.date}
                    isDefault={true}
                    mandatory={true}
                    max={new Date().toISOString().split('T')[0]}
                    onDateChange={(date) => onChange('date', date)}
                />
            </div>
            <div className={form.formColumn}>
                <SingleSelect
                    id="contractId"
                    label="Contract"
                    placeholder="Select Contract..."
                    mandatory={true}
                    menuItems={contracts}
                    selectedId={selects.contract?.id || ''}
                    selected={
                        (selects.contract?.id &&
                            contracts.find((el) => el.id === selects.contract.id)) ||
                        {}
                    }
                    error={errors.contract}
                    onChange={(id) =>
                        onChange(
                            'contract',
                            contracts.find((el) => el.id === id)
                        )
                    }
                />
            </div>
        </div>
    );
};

export const ModalActionAttendanceOutcome = ({ onGenerate, disabled }) => {
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);

    return (
        <>
            <div></div>
            <Button
                id="generateDataSets"
                disabled={!hasRole([PRAP, QUALITY, SUPERUSER], roles) || disabled}
                content="Generate"
                onClick={onGenerate}
            />
        </>
    );
};

export default ModalContentAttendanceOutcome;

ModalContentAttendanceOutcome.propTypes = {
    contracts: PropTypes.arrayOf(PropTypes.object),
    errors: PropTypes.object,
    selects: PropTypes.object,
    attendanceStatuses: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func
};

ModalActionAttendanceOutcome.propTypes = {
    onGenerate: PropTypes.func,
    disabled: PropTypes.bool
};
