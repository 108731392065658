import React from 'react';
import PropTypes from 'prop-types';

import RestoreIcon from '@mui/icons-material/Restore';

import common from './formElementStyles/commonFormElements.module.css';
import field from './formElementStyles/staticField.module.css';

const StaticField = ({
    id = '',
    label,
    content = '',
    link = '',
    onClick,
    customClass = '',
    onSearchAuditItems,
    isAuditable = false
}) => {
    return (
        <div className={field.staticField} data-testid={`static_field-${id}`}>
            <label htmlFor={label} className={common.formLabel}>
                {label}
                {isAuditable && (
                    <div>
                        <RestoreIcon onClick={() => onSearchAuditItems(label)} />
                    </div>
                )}
            </label>
            {content && <div className={`${customClass ? field[customClass] : ''}`}>{content}</div>}
            {link && (
                <div className={field.staticTextLink} onClick={onClick}>
                    {link}
                </div>
            )}
        </div>
    );
};

StaticField.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    content: PropTypes.any,
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    onClick: PropTypes.func,
    customClass: PropTypes.string,
    onSearchAuditItems: PropTypes.func,
    isAuditable: PropTypes.bool
};

export default StaticField;
