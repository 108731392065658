import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '@mui/material';

const EventContent = ({ eventInfo, onNav }) => {
    const {
        participant,
        location,
        appointmentType,
        content,
        timeContent,
        eventType,
        slots,
        filledSlots,
        hasLink
    } = eventInfo.event.extendedProps;
    const renderEventContent = () => (
        <div className="fc-event-main-frame">
            {eventInfo.timeText && eventType !== 'hidden' && (
                <div className="fc-event-time">{timeContent}</div>
            )}
            <div className="fc-event-title-container">
                <div className="fc-event-title">
                    {eventType !== 'user' && <span className="content-text">{content}</span>}
                    {appointmentType && <span>{appointmentType}</span>}
                    {slots && <span> - {filledSlots} </span>}
                    {participant && !hasLink && <span> - {participant}</span>}
                    {participant && hasLink && (
                        <>
                            <span> - </span>
                            <span
                                className="event-link"
                                onClick={() => onNav(eventInfo.event.extendedProps)}>
                                {participant}
                            </span>
                        </>
                    )}
                    {eventType === 'user' && location && <span> - {location} </span>}
                </div>
            </div>
        </div>
    );

    return (
        <Tooltip title={renderEventContent()} placement="top">
            {renderEventContent()}
        </Tooltip>
    );
};

EventContent.propTypes = {
    eventInfo: PropTypes.object,
    onNav: PropTypes.func
};

export default EventContent;
