import React from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';

import classes from '../../../../../commonStyles/pdf.module.css';

const AppointmentDetails = ({ currentAppointment, appointment, nextAppointment }) => {
    const documentId =
        currentAppointment?.calendarAppointmentId?.slice(-9) || uuid().slice(-9) || '-';
    const dateConducted = currentAppointment.attendanceTime.split(',')[0] || '-';
    const timeStarted = currentAppointment.attendanceTime.split(',')[1] || '-';
    const timeEnded = format(new Date(), `HH :mm`).replace(/:/g, ' : ');

    // RENDER
    return (
        <div className={classes.dualColumnSet} data-testid="appointment-details">
            <div className={classes.column}>
                <div className={classes.row}>
                    <span>Appointment:</span>
                    <span>{appointment?.type || '-'}</span>
                </div>
                <div className={classes.row}>
                    <span>Appointment status:</span>
                    <span>{currentAppointment?.attendanceName || '-'}</span>
                </div>
                <div className={classes.row}>
                    <span>Mandated:</span>
                    <span>{appointment?.mandatory ? 'Yes' : 'No'}</span>
                </div>
                <div className={classes.row}>
                    <span>Document ID:</span>
                    <span>{documentId}</span>
                </div>
                <div className={classes.row}>
                    <span>Date Conducted:</span>
                    <span>{dateConducted}</span>
                </div>
                <div className={classes.row}>
                    <span>Time Started:</span>
                    <span>{timeStarted}</span>
                </div>
                <div className={classes.row}>
                    <span>Time Ended:</span>
                    <span>{timeEnded}</span>
                </div>
            </div>

            <div className={classes.column}>
                {nextAppointment && (
                    <div className={classes.row}>
                        <span>Next Appointment:</span>
                        <span>{nextAppointment?.appointmentType || '-'}</span>
                    </div>
                )}
                {nextAppointment.date && (
                    <div className={classes.row}>
                        <span>Date:</span>
                        <span>{nextAppointment.date || '-'}</span>
                    </div>
                )}
                {nextAppointment.startTime && (
                    <div className={classes.row}>
                        <span>Time:</span>
                        <span>{nextAppointment.startTime?.replace(/:/g, ' : ') || '-'}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

AppointmentDetails.propTypes = {
    currentAppointment: PropTypes.object,
    appointment: PropTypes.object,
    nextAppointment: PropTypes.object
};

export default AppointmentDetails;
