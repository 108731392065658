import { createSelector } from '@reduxjs/toolkit';

const selectCurrentUserEntry = (state) => state.entities.userService.currentUser;
const selectUserTypeEntries = (state) => state.entities.userService.userTypes;
const selectLoggedInUserEntry = (state) => state.entities.userService.loggedInUser;

const selectUserEntries = (state) => state.entities.userService.users;

const selectUsersByLoggedInUserServiceIdsEntries = (state) =>
    state.entities.userService.usersByLoggedInUserServiceIds;
const selectUsersMinimalDetailEntries = (state) => state.entities.userService.usersMinimalDetails;
const selectUserEntriesById = (state) => state.entities.userService.usersById;
const selectUserEntriesForVacancies = (state) => state.entities.userService.usersForVacancies;

export const selectCurrentUser = createSelector(
    selectCurrentUserEntry,
    (currentUser) => currentUser
);

export const selectUserTypes = createSelector(selectUserTypeEntries, (userTypes) => userTypes);

export const selectLoggedInUser = createSelector(
    selectLoggedInUserEntry,
    (loggedInUser) => loggedInUser
);

export const selectUserRoles = createSelector(
    selectLoggedInUser,
    (loggedInUser) => loggedInUser.roles
);

export const selectLoggedInUserName = createSelector(
    selectLoggedInUser,
    (loggedInUser) => `${loggedInUser.firstName} ${loggedInUser.lastName}`
);

export const selectUsers = createSelector(selectUserEntries, (users) => users);

export const selectUsersForNames = createSelector(
    selectUsersByLoggedInUserServiceIdsEntries,
    selectUsersMinimalDetailEntries,
    (usersByLoggedInUserServiceIds, usersMinimalDetails) => [
        ...usersByLoggedInUserServiceIds,
        ...usersMinimalDetails
    ]
);

export const selectUsersForNamesById = createSelector(selectUsersForNames, (usersForNames) =>
    usersForNames.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {})
);

export const selectUsersById = createSelector(selectUserEntriesById, (users) => users);

export const selectUsersForVacancies = createSelector(
    selectUserEntriesForVacancies,
    (usersForVacancies) => usersForVacancies
);

export const selectUser = () =>
    createSelector(
        selectUserEntries,
        (_, id) => id,
        (users, id) => users.find((el) => el.id === id) || {}
    );

export const selectUserName = () =>
    createSelector(
        selectUserEntries,
        (_, id) => id,
        (users, id) => {
            const user = users.find((el) => el.id === id);
            return user ? `${user.firstName} ${user.lastName}` : '';
        }
    );

export const selectAllUsersPrimaryServiceIds = createSelector(selectUserEntries, (users) => [
    ...new Set(users.map((el) => el.primaryService).filter((el) => el))
]);

export const selectLoggedInUserFirstAndLastName = createSelector(
    selectLoggedInUser,
    (loggedInUser) => {
        const { firstName, lastName } = loggedInUser;
        if (!firstName || !lastName) return '';
        return `${firstName} ${lastName}`;
    }
);
