import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from '@mui/material';
import Typography from '@mui/material/Typography';

import { loadTimelineTypeDetails } from '../../store/directusService';
import { createParticipantTimelineEvent } from '../../store/participantService';
import { ADVISER, AUDIT, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../utils/userRoles';
import LabelledTextField from '../ui/editors/LabelledTextField';
import DDLOptionPicker from '../ui/pickers/DDLOptionPicker';

import classes from './timeLineStyles/timeLine.module.css';

const CHARACTER_LIMIT = 250;

const TimeLineEditor = ({ open, onTimeLineEditor, timelineStatusDetails }) => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });
    // LOCAL STATE
    // State for create timeLine newEntry
    // This matches the payload sent to the backend
    const initialState = {
        id: null,
        eventDate: null,
        eventTime: null,
        notes: '',
        eventStatusId: null,
        itemTypeId: null,
        participantId: null,
        userId: null
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const acceptedRoles = [ADVISER, AUDIT, MANAGER, QUALITY, SUPERUSER];
    const [isOpen, setIsOpen] = React.useState(false);
    const [notes, setNotes] = useState('');
    const [clearSelectedTimelineType, setIsClearSelectedTimelineType] = useState('1');
    const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false);

    // STORE STATE
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const { id, roles, firstName, lastName } = useSelector(
        (state) => state.entities.userService.loggedInUser
    );
    const { currentParticipant } = useSelector((state) => state.entities.participantService);
    const { timelineTypeDetails } = useSelector((state) => state.entities.directusService);

    const dispatch = useDispatch();

    // EVENT HANDLERS
    const handleClickOutside = () => {
        // Do nothing. User cannot exit by clicking outside dialog.
    };

    const handleCancel = () => {
        onTimeLineEditor(false);
        clearData();
        setNotes('');
    };
    const handleItemTypeChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, itemTypeId: chosenId }));
    };

    const handleNotesChange = (e) => {
        setNotes(e.target.value);
        setNewEntry((prev) => ({ ...prev, notes: e.target.value }));
    };

    const handleEventDateChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            eventDate: e.target.value
        }));
    };

    const handleEventTimeChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            eventTime: e.target.value
        }));
    };

    const clearData = () => {
        setNewEntry(initialState);
        setNotes('');
        setIsClearSelectedTimelineType(Math.random());
    };

    const onCreated = () => {
        onTimeLineEditor(false);
        clearData();
    };

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadTimelineTypeDetails());
    }, []);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        return () => {
            setNewEntry((prev) => ({ ...prev, participantId: currentParticipant.id }));
        };
    }, [currentParticipant]);

    useEffect(() => {
        if (successMessage === `Timeline entry has been added`) {
            setIsUpdateButtonDisabled(false);
            onCreated();
        }
    }, [successMessage]);

    const onSubmit = () => {
        setIsUpdateButtonDisabled(true);
        setValue('itemTypeId', newEntry.itemTypeId, { shouldValidate: true });
        setValue('notes', notes, { shouldValidate: true });
        const validTime = newEntry.eventTime.substring(0, 2) + newEntry.eventTime.substring(3);
        const eventStatus = timelineStatusDetails.find(
            (item) => item.name.toLowerCase() === 'attended'
        );

        const payload = {
            ...newEntry,
            eventStatusId: eventStatus.id,
            eventTime: validTime,
            eventType: 'Communication',
            participantId: currentParticipant.id,
            userId: id
        };
        dispatch(createParticipantTimelineEvent(payload));
    };

    return (
        <div
            onSubmit={(e) => {
                //  This stops the Submit event from bubbling up to the form enclosing this component
                e.preventDefault();
                e.stopPropagation();
            }}>
            <Dialog open={isOpen} onClose={handleClickOutside} aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle id="form-dialog-title">Add Timeline</DialogTitle>
                    <DialogContent data-testid="dialog-content">
                        <DDLOptionPicker
                            label={'Item Type'}
                            id={'itemTypeId'}
                            key={clearSelectedTimelineType}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={timelineTypeDetails}
                            error={errors.itemTypeId}
                            {...register('itemTypeId')}
                            onChange={(e) => {
                                if (e !== null && e !== undefined) {
                                    handleItemTypeChange(e);
                                }
                            }}
                        />
                        <Typography variant="h6">Adviser/Coach</Typography>
                        <p className={classes.adviserName}>{firstName + ' ' + lastName}</p>
                        <LabelledTextField
                            label={'Notes'}
                            id={'notes'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            multiline
                            rows={5}
                            value={notes}
                            placeholder={'Enter notes'}
                            counter={'true'}
                            helperText={`${notes.length}` + '/' + CHARACTER_LIMIT}
                            inputProps={{
                                maxLength: CHARACTER_LIMIT
                            }}
                            error={errors.notes}
                            {...register('notes')}
                            onChange={handleNotesChange}
                        />
                        <TextField
                            id="eventDate"
                            disabled={!hasRole(acceptedRoles, roles)}
                            required={true}
                            data-testid="eventDate"
                            label="Date of Event"
                            type="date"
                            value={newEntry.eventDate || ''}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleEventDateChange}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <TextField
                            id="eventTime"
                            disabled={!hasRole(acceptedRoles, roles)}
                            required={true}
                            data-testid="eventTime"
                            label="Time of Event"
                            type="time"
                            value={newEntry.eventTime || ''}
                            sx={{ width: 160 }}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleEventTimeChange}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={!hasRole(acceptedRoles, roles) || isUpdateButtonDisabled}>
                            Update
                        </Button>
                        <Button onClick={handleCancel} color="primary" variant="contained">
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    notes: Yup.string().trim().min(50, 'Event description must be at least fifty characters.'),
    itemTypeId: Yup.string().required('Please select an item type.')
});

TimeLineEditor.propTypes = {
    onTimeLineEditor: PropTypes.func,
    open: PropTypes.bool.isRequired,
    timelineStatusDetails: PropTypes.array.isRequired
};

export default TimeLineEditor;
