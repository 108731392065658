import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tab, Tabs } from '@mui/material';

import { checkExists, getParticipantURL } from '../../../api/commonHTTP';
import { loadContractDetails } from '../../../store/directusService';
import {
    createParticipantSectionHeadings,
    loadParticipantSectionHeadings,
    updateParticipantSectionHeadings
} from '../../../store/participantService';
import { POLARIS_VICTORIA_BLUE, POLARIS_WHITE } from '../../../themes/theme';
import { hasRole, LOCAL_ADMIN, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import SingleSelect from '../../formElements/SingleSelect';
import NotFound from '../../notFound/NotFound';
import ConfigureServiceAdmin from '../configureServices/ConfigureServiceAdmin';
import ParticipantDetails from '../ParticipantDetails';

import { checkForContract, initialErrorState, validate } from './validateConfigureContract';

import app from '../../../app.module.css';
import form from '../../../commonStyles/formStyles.module.css';

const ConfigureContract = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [SUPERUSER, QUALITY];
    const NO_SELECTION = 'no selection';
    const initialState = {
        participantBarriers: false,
        actionPlanGoals: false,
        serviceStandards: false,
        wellbeing: false,
        workRequirements: false,
        workHistory: false,
        education: false,
        submissions: false,
        placements: false,
        healthInformation: false,
        circumstances: false,
        employability: false,
        financialRequests: false,
        assetRequests: false
    };

    const [initialHeadings, setInitialHeadings] = useState({});
    const [isHeading, setIsHeading] = useState(initialState);
    const [contractId, setContractId] = useState('');
    const [contractName, setContractName] = useState('');
    const [showPanel, setShowPanel] = useState('contractDetails');
    const [value, setValue] = useState(0);
    const [errors, setErrors] = useState(initialErrorState);
    const [isDisabled, setIsDisabled] = useState(false);

    // STORE STATE
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const contractDetails = useSelector((state) => state.entities.directusService.contractDetails);
    const participantSectionHeadings = useSelector(
        (state) => state.entities.participantService.participantSectionHeadings
    );

    //  USE EFFECTS
    useEffect(() => {
        dispatch(loadContractDetails());
    }, []);

    useEffect(() => {
        if (contractId !== '' && contractId !== NO_SELECTION) {
            if (Object.keys(participantSectionHeadings).length !== 0) {
                // eslint-disable-next-line no-unused-vars
                const { id, contractId, ...rest } = participantSectionHeadings;
                setInitialHeadings(rest);
                setIsHeading(rest);
            } else {
                setIsHeading(initialState);
            }
        } else {
            setIsHeading(initialState);
        }
    }, [participantSectionHeadings]);

    useEffect(() => {
        setIsDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    // HELPER FNS
    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    // EVENT HANDLERS
    const onContractChange = (chosenId) => {
        clearError('contract');
        if (chosenId !== '' && chosenId !== NO_SELECTION) {
            setContractId(chosenId);
            const entry = contractDetails.find((el) => el.id === chosenId);
            if (entry) setContractName(entry.contract);
            dispatch(loadParticipantSectionHeadings(chosenId, entry.contract));
        } else if (chosenId === '') {
            setContractId(chosenId);
        }
    };

    const onChangeTab = (event, newValue) => {
        setValue(newValue);
        newValue === 0 ? setShowPanel('contractDetails') : setShowPanel('contractServices');
    };

    const onCheckForContract = () => {
        const newErrors = checkForContract(contractId, errors);
        setErrors(newErrors);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (showPanel !== 'contractDetails') return;
        const { newErrors } = validate(contractId, initialHeadings, isHeading);
        setErrors(newErrors);
        if (Object.values(newErrors).some((el) => el.error)) return;
        let data;
        if (
            await checkExists(
                getParticipantURL() + 'rest/participant-section-heading/exists/' + contractId
            )
        ) {
            data = {
                id: participantSectionHeadings.id,
                contractId,
                ...isHeading
            };
            dispatch(updateParticipantSectionHeadings(data, contractName));
        } else {
            data = { id: null, contractId, ...isHeading };
            dispatch(createParticipantSectionHeadings(data, contractName));
        }
    };

    // RENDER
    if (!hasRole(acceptedRoles, roles)) return <NotFound />;

    return (
        <>
            <div className={form.formWrapper} data-testid="form_start">
                <form className={form.form} onSubmit={onSubmit}>
                    <h2 className={app.mainHeading}>Configure Contract</h2>
                    <div className={form.formSection}>
                        <SingleSelect
                            id={'contract'}
                            label={'Contract'}
                            placeholder={'Select contract'}
                            mandatory={true}
                            menuItems={contractDetails || []}
                            selectedId={contractId || ''}
                            selected={contractDetails?.find((el) => el.id === contractId) || {}}
                            error={errors?.contract}
                            onChange={(contractId) => onContractChange(contractId)}
                        />
                    </div>
                    <div className={form.formSection}>
                        <Tabs
                            onChange={onChangeTab}
                            value={value}
                            hidden={!hasRole([SUPERUSER, LOCAL_ADMIN], roles)}
                            TabIndicatorProps={{ hidden: true }}
                            sx={{
                                marginBottom: '16px',
                                '& button:hover': {
                                    backgroundColor: `${POLARIS_VICTORIA_BLUE}`,
                                    color: `${POLARIS_WHITE}`
                                },
                                '& button.Mui-selected': {
                                    backgroundColor: `${POLARIS_VICTORIA_BLUE}`,
                                    color: `${POLARIS_WHITE}`
                                }
                            }}>
                            <Tab
                                label="Contract Details"
                                sx={{
                                    border: `2px solid ${POLARIS_VICTORIA_BLUE}`,
                                    margin: '0px 4px',
                                    borderRadius: '4px'
                                }}
                                value={0}
                            />
                            <Tab
                                label="Contract Services"
                                sx={{
                                    border: `2px solid ${POLARIS_VICTORIA_BLUE}`,
                                    margin: '0px 4px',
                                    borderRadius: '4px'
                                }}
                                value={1}
                            />
                        </Tabs>
                    </div>
                    <div hidden={showPanel === 'contractServices'}>
                        <Button
                            id="updateSectionHeadings"
                            content="UPDATE SECTION HEADINGS"
                            disabled={!hasRole(acceptedRoles, roles) || isDisabled}
                            error={errors?.button}
                            clearError={() => clearError('button')}
                        />
                    </div>
                </form>
            </div>

            {showPanel === 'contractDetails' ? (
                <ParticipantDetails
                    submissions={isHeading.submissions}
                    placements={isHeading.placements}
                    education={isHeading.education}
                    workHistory={isHeading.workHistory}
                    workRequirements={isHeading.workRequirements}
                    wellbeing={isHeading.wellbeing}
                    serviceStandards={isHeading.serviceStandards}
                    actionPlanGoals={isHeading.actionPlanGoals}
                    participantBarriers={isHeading.participantBarriers}
                    employability={isHeading.employability}
                    circumstances={isHeading.circumstances}
                    healthInformation={isHeading.healthInformation}
                    financialRequests={isHeading.financialRequests}
                    assetRequests={isHeading.assetRequests}
                    contractId={contractId}
                    onHeadingChange={(values) => setIsHeading(values)}
                />
            ) : (
                <ConfigureServiceAdmin
                    contractId={contractId}
                    onCheckForContract={onCheckForContract}
                />
            )}
        </>
    );
};

export default ConfigureContract;
