import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RestoreIcon from '@mui/icons-material/Restore';

import IconError from '../IconError';
import TrackVisibility from '../ui/TrackVisibility';

import common from './formElementStyles/commonFormElements.module.css';
import selector from './formElementStyles/singleSelect.module.css';

const SingleSelect = ({
    id,
    selectedId = '',
    selected = {},
    disabled,
    mandatory = false,
    label,
    placeholder,
    menuItems,
    error = {},
    onChange,
    onLoadMoreItems,
    moreItemsToLoad,
    onSearchAuditItems,
    isAuditable = false,
    customClass = '',
    customErrorClass = '',
    capitalizeList = true
}) => {
    // LOCAL STATE
    const toggleRef = useRef(null);
    const listRef = useRef(null);
    const menuItemsLengthRef = useRef(null);
    const [listItems, setListItems] = useState([]);
    const [rows, setRows] = useState([]);
    const [isListOpen, setIsListOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [showSearch, setShowSearch] = useState(true);

    // USE EFFECTS
    useEffect(() => {
        setShowSearch(!selectedId);
    }, [selectedId]);

    useEffect(() => {
        let items = menuItems.slice();
        if (!onLoadMoreItems) {
            items = items.sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
        }
        setListItems(items);
        setRows(items);
        if (onLoadMoreItems) {
            // Load more if all items from the last loaded page have been filtered out
            if (isListOpen && menuItems.length === menuItemsLengthRef.current) {
                onLoadMoreItems();
            } else {
                menuItemsLengthRef.current = menuItems.length;
            }
        }
    }, [menuItems]);

    useEffect(() => {
        if (listItems.length < 1) return;
        const filtered = searchTerm
            ? listItems.filter((el) => el.name.toLowerCase().includes(searchTerm.toLowerCase()))
            : listItems;
        setRows(filtered);
        if (searchTerm) onOpenList();
    }, [searchTerm]);

    useEffect(() => {
        if (isListOpen) {
            document.addEventListener('click', onCloseList);
            if (!menuItems.length && moreItemsToLoad) onLoadMoreItems();
        }
        return () => document.removeEventListener('click', onCloseList);
    }, [isListOpen]);

    // EVENT HANDLERS
    const onSelect = (_, item) => {
        onChange(item.id);

        setSearchTerm('');
        setShowSearch(false);
        onToggle();
    };

    const onSearchClick = () => {
        if (disabled) return;
        onChange('');
        setShowSearch(true);
    };

    const onOpenList = () => {
        if (disabled) return;
        if (toggleRef.current && listRef.current) {
            if (!toggleRef.current.classList.contains('toggleOpen')) {
                toggleRef.current.classList.add('toggleOpen');
                setIsListOpen(true);
            }
        }
    };

    const onCloseList = (e) => {
        const selectorEl = document.getElementById(id);
        const listEl = document.querySelector('.showList');
        if (
            e &&
            typeof e.composedPath === 'function' &&
            !e.composedPath().includes(selectorEl) &&
            !e.composedPath().includes(listEl)
        ) {
            if (toggleRef.current && listRef.current) {
                if (toggleRef.current.classList.contains('toggleOpen')) {
                    setSearchTerm('');
                    toggleRef.current.classList.remove('toggleOpen');
                    setIsListOpen(false);
                }
            }
        }
    };

    const onToggle = (e) => {
        if (e) e.stopPropagation();
        if (disabled) return;
        if (toggleRef.current && listRef.current) {
            if (toggleRef.current.classList.contains('toggleOpen')) {
                toggleRef.current.classList.remove('toggleOpen');
                setIsListOpen(false);
            } else {
                toggleRef.current.classList.add('toggleOpen');
                setIsListOpen(true);
            }
        }
    };

    return (
        <div
            className={`${isListOpen ? `${selector.singleSelectExpanded}` : ''} ${
                selector.singleSelect
            } ${selector[customClass]}`}>
            <label id={`label-${id}`} htmlFor={id} className={common.formLabel}>
                <span>
                    {label} {mandatory && <sup>*</sup>}
                </span>
                {isAuditable && (
                    <div>
                        <RestoreIcon onClick={() => onSearchAuditItems(label)} />
                    </div>
                )}
            </label>
            <div
                className={`${selector.selector} ${
                    disabled ? `${selector.selectorDisabled}` : `${selector.selectorActive}`
                }`}
                id={id}
                aria-labelledby={`label-${id}`}
                onClick={onToggle}>
                {!showSearch ? (
                    <span
                        className={selector.singleSelection}
                        onClick={onSearchClick}
                        data-testid={`selected-option-${id}`}>
                        {selected.name}
                    </span>
                ) : (
                    <span className={selector.selectorSearchInput}>
                        <input
                            id={id}
                            type="search"
                            autoComplete="off"
                            placeholder={placeholder}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            disabled={disabled}
                        />
                    </span>
                )}
                <span
                    className={`${selector.toggle} ${
                        disabled ? `${selector.toggleDisabled}` : `${selector.toggleActive}`
                    }`}
                    data-testid={`dropDownIcon-${id}`}
                    ref={toggleRef}>
                    <ArrowDropDownIcon />
                </span>
            </div>

            <>
                <div
                    className={`${selector.selectList} ${selector.scroller} ${capitalizeList ? selector.capitalize : ''} ${
                        isListOpen ? `${selector.showList}` : `${selector.hideList}`
                    }`}
                    ref={listRef}>
                    <ul>
                        {rows.length > 0 ? (
                            rows.map((el, index) => (
                                <li
                                    key={el.id}
                                    data-testid={`selectItem-${el.id}`}
                                    onClick={(e) => onSelect(e, el)}
                                    className={el.id === selectedId ? selector.selected : ''}>
                                    {moreItemsToLoad &&
                                    searchTerm === '' &&
                                    index + 1 === rows.length ? (
                                        <TrackVisibility
                                            onVisible={onLoadMoreItems}
                                            options={{ threshold: 0.1 }}>
                                            {el.name}
                                        </TrackVisibility>
                                    ) : (
                                        el.name
                                    )}
                                </li>
                            ))
                        ) : (
                            <div className={selector.noItems}>No Items</div>
                        )}
                    </ul>
                </div>
            </>
            {error?.error && (
                <div
                    className={`${isListOpen ? selector.errorExpanded : `${selector.error} ${selector[customErrorClass]}`} `}>
                    <IconError text={error} />
                </div>
            )}
        </div>
    );
};

export default SingleSelect;

SingleSelect.propTypes = {
    id: PropTypes.string,
    disabled: PropTypes.bool,
    mandatory: PropTypes.bool,
    menuItems: PropTypes.arrayOf(PropTypes.object),
    selectedId: PropTypes.string,
    selected: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.object,
    onChange: PropTypes.func,
    allowSetSelected: PropTypes.bool,
    onLoadMoreItems: PropTypes.func,
    moreItemsToLoad: PropTypes.bool,
    onSearchAuditItems: PropTypes.func,
    isAuditable: PropTypes.bool,
    customClass: PropTypes.string,
    customErrorClass: PropTypes.string,
    capitalizeList: PropTypes.bool
};
