import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { setErrorMessage } from '../../../../../store/formsState';
import { createPoolApplication } from '../../../../../store/recruitmentService';
import { countDays } from '../../../../../utils/dateFunctions';
import { NO_UPDATE_ERROR } from '../../../../../utils/formValidation/commonErrorConstants';
import { clearKeys } from '../../../../../utils/objectUtils';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../../utils/userRoles';
import SingleSelect from '../../../../formElements/SingleSelect';
import FormActions from '../../../../ui/formActions/FormActions';

import SelectPoolsTableManagement from './SelectPoolsTableManagement';
import { initialErrorState, validate } from './validateApplyToPoolEditor';

import form from '../../../../../commonStyles/formStyles.module.css';
import classes from '../../submissionStyles/pools.module.css';

const ApplyToPoolEditor = ({ open, onPoolEditor, participantDocuments, poolApplications }) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        goldStandardCVId: null,
        participantId: null,
        participantPoolIds: null
    };
    const [newEntry, setNewEntry] = useState(initialState);
    const [keys, setKeys] = useState({ goldStandardCVId: '0' });
    const [errors, setErrors] = useState(initialErrorState);
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [isOpen, setIsOpen] = React.useState(false);
    const [rowsUpdated, setRowsUpdated] = useState([]);
    const [mockInterview, setMockInterview] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    // STORE STATE
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const participantAppointments = useSelector(
        (state) => state.entities.calendarService.participantAppointments
    );
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const currentParticipantEmployability = useSelector(
        (state) => state.entities.participantService.currentParticipantEmployability
    );
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);

    // USE EFFECTS
    useEffect(() => {
        setNewEntry((prev) => ({ ...prev, participantId: currentParticipant.id }));
    }, []);

    useEffect(() => {
        if (participantAppointments?.length < 1) setMockInterview(false);
        else {
            const mockInterviewAppointments = participantAppointments.filter(
                (el) => el.type.toLowerCase() === 'mock interview'
            );
            if (!mockInterviewAppointments) return;

            let attendedStatuses = [];

            mockInterviewAppointments.forEach((el) => {
                attendedStatuses = [
                    ...attendedStatuses,
                    ...el.attendances.filter((entry) => entry.status.toLowerCase() === 'attended')
                ];
            });

            if (attendedStatuses.length < 1) return;

            setMockInterview(attendedStatuses.some((el) => countDays(el.date, new Date()) < 31));
        }
    }, [participantAppointments]);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        if (successMessage === `Pool application(s) have been added`) {
            setIsDisabled(false);
            clearData();
        }
    }, [successMessage]);

    // HELPER FNS
    const getPreSelect = (arr, id) => arr.find((el) => el.id === id) || {};

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    const clearData = () => {
        setRowsUpdated([]);
        setNewEntry(initialState);
        setKeys(clearKeys(keys));
        onPoolEditor(false);
    };

    // EVENT HANDLERS
    const onUpdate = (key, value) => {
        errors[key] && clearError(key);
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onModalClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setIsOpen(false);
        }
    };

    const onRowsUpdated = (rowsUpdated) => {
        setRowsUpdated(rowsUpdated);
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const newErrors = validate(newEntry);

        if (Object.keys(newErrors)?.length > 0) {
            setErrors(newErrors);
            return;
        }

        const participantPoolIds = rowsUpdated
            .filter((el) => el.update === true)
            .map((el) => el.id);
        if (participantPoolIds?.length < 1) {
            dispatch(setErrorMessage(NO_UPDATE_ERROR));
        } else {
            setIsDisabled(true);
            const payload = {
                ...newEntry,
                participantId: currentParticipant.id,
                participantPoolIds: participantPoolIds
            };
            dispatch(createPoolApplication(payload));
        }
    };

    // RENDER
    return (
        <div>
            <Dialog
                className="muiDialogWrapper"
                open={isOpen}
                onClose={onModalClose}
                aria-labelledby="form-dialog-title">
                <form onSubmit={onSubmit} data-testid="form_start" className={form.form}>
                    <DialogTitle id="form-dialog-title">Select Pool(s) For Application</DialogTitle>
                    <DialogContent data-testid="dialog-content">
                        <SingleSelect
                            id={'goldStandardCVId'}
                            key={keys.goldStandardCVId}
                            label={'Gold Standard CV'}
                            placeholder="Select Gold Standard CV..."
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            mandatory={true}
                            menuItems={participantDocuments}
                            selectedId={newEntry.goldStandardCVId}
                            selected={getPreSelect(participantDocuments, newEntry.goldStandardCVId)}
                            error={errors.goldStandardCVId}
                            onChange={(chosenId) => onUpdate('goldStandardCVId', chosenId)}
                        />
                        <h3>Criteria For Pool Applications:</h3>
                        <section>
                            {participantDocuments?.length < 1 ? (
                                <CancelIcon className={classes.red} />
                            ) : (
                                <CheckCircleIcon className={classes.green} />
                            )}{' '}
                            Have a Gold Standard CV attached to their record
                        </section>
                        <section>
                            {Object.keys(currentParticipantEmployability).length < 1 ||
                            currentParticipantEmployability?.rightToWorkDocumentTypeId === null ? (
                                <CancelIcon className={classes.red} />
                            ) : (
                                <CheckCircleIcon className={classes.green} />
                            )}{' '}
                            Right to Work Documents available
                        </section>
                        <section>
                            {mockInterview === false ? (
                                <CancelIcon className={classes.red} />
                            ) : (
                                <CheckCircleIcon className={classes.green} />
                            )}{' '}
                            Participant has attended at least one mock interview within last month
                        </section>
                        <p>Select a Pool to submit your Participant to for consideration</p>
                        <SelectPoolsTableManagement
                            onRowsUpdated={onRowsUpdated}
                            poolApplications={poolApplications}
                        />
                    </DialogContent>

                    <DialogActions>
                        <FormActions
                            id="submit"
                            btnText="Update"
                            disabled={
                                !hasRole(acceptedRoles, loggedInUser.roles) ||
                                participantDocuments?.length < 1 ||
                                currentParticipantEmployability?.rightToWorkDocumentTypeId ===
                                    null ||
                                currentParticipantEmployability?.rightToWorkDocumentTypeId ===
                                    undefined ||
                                mockInterview === false ||
                                newEntry.participantPoolIds?.length < 1 ||
                                isDisabled
                            }
                            onClose={onSubmit}
                            onCancel={clearData}
                            customClass="noTopBorder"
                        />
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

ApplyToPoolEditor.propTypes = {
    onPoolEditor: PropTypes.func,
    open: PropTypes.bool.isRequired,
    participantDocuments: PropTypes.array.isRequired,
    poolApplications: PropTypes.array
};

export default ApplyToPoolEditor;
