import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectUsersAppointmentsAndSlots } from '../../../store/calendarSelectors';
import {
    searchParticipantAppointmentsAndSlots,
    searchUserAppointmentsAndSlots,
    setCurrentAppointment,
    setOpenAppointmentSchedulerPanel,
    updateAppointmentSlot,
    updateParticipantAppointment
} from '../../../store/calendarService';
import {
    loadCourseModulesByUserId,
    loadCoursesByParticipantId
} from '../../../store/courseService';
import {
    loadAppointmentTypeDetails,
    loadAppointmentTypeDetailsByContractId,
    loadContractDetailsForContractId,
    loadLocationIdsAndNames,
    loadLocationsByServiceId,
    loadServiceDetailsByService,
    loadServiceDetailsForContractId,
    setCurrentContractId,
    setCurrentServiceId
} from '../../../store/directusService';
import { selectCurrentParticipantsName } from '../../../store/participantSelectors';
import {
    loadParticipantEmployability,
    setCurrentlySelectedParticipant
} from '../../../store/participantService';
import {
    POLARIS_BLACK,
    POLARIS_GREY,
    POLARIS_ROYAL_BLUE,
    POLARIS_WHITE
} from '../../../themes/theme';
import {
    ADVISER,
    AUDIT,
    hasRole,
    LOCAL_ADMIN,
    MANAGER,
    PRAP,
    QUALITY,
    READ_ONLY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import Calendar from '../calendar/Calendar';
import AdviserSelect from '../components/AdviserSelect';
import {
    configCourseEventContent,
    configParticipantEventContent,
    formatFullCalendarTimes,
    getBusinessHours,
    getServiceIds,
    mapColors,
    mapCommonParams
} from '../utils/calendarUtils';

import ParticipantAppointmentCreate from './ParticipantAppointmentCreate';
import ParticipantAppointmentEdit from './ParticipantAppointmentEdit';
import ParticipantAppointmentSlotsEdit from './ParticipantAppointmentSlotsEdit';
import ParticipantAppointmentSlotsView from './ParticipantAppointmentSlotsView';
import ParticipantAppointmentView from './ParticipantAppointmentView';

import classes from '../../../app.module.css';

let userClickCount = 0;

const ParticipantCalendar = () => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onSelectUsersAppointmentsAndSlots = useMemo(selectUsersAppointmentsAndSlots, []);

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const viewOnlyRoles = [AUDIT, PRAP, READ_ONLY, LOCAL_ADMIN];
    const [currentCalendarOwner, setCurrentCalendarOwner] = useState({});
    const [hiddenEvents, setHiddenEvents] = useState([]);
    const [events, setEvents] = useState([]);
    const [newEvent, setNewEvent] = useState({});
    const [currentEvent, setCurrentEvent] = useState({});

    // STORE STATE
    const usersAppointmentsAndSlots = useSelector((state) =>
        onSelectUsersAppointmentsAndSlots(state, currentCalendarOwner?.id)
    );
    const users = useSelector((state) => state.entities.userService.usersBySearch);
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);
    const { roles } = loggedInUser;
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const participantsName = useSelector(selectCurrentParticipantsName);
    const usersCurrentAppointment = useSelector(
        (state) => state.entities.calendarService.usersCurrentAppointment
    );
    const openAppointmentSchedulerPanel = useSelector(
        (state) => state.entities.calendarService.openAppointmentSchedulerPanel
    );
    const participantsAppointmentsAndSlots = useSelector(
        (state) => state.entities.calendarService.participantAppointmentsAndSlots
    );
    const courseModules = useSelector((state) => state.entities.courseService.courseModules);
    const participantsCourses = useSelector(
        (state) => state.entities.courseService.participantsCourses
    );
    const locations = useSelector((state) => state.entities.directusService.locationIdsAndNames);
    const appointmentTypes = useSelector(
        (state) => state.entities.directusService.appointmentTypeDetails
    );
    const serviceDetailsById = useSelector(
        (state) => state.entities.directusService.serviceDetailsById
    );
    const appointmentTypesByContractId = useSelector(
        (state) => state.entities.directusService.appointmentTypeDetailsByContractId
    );
    const locationsByServiceId = useSelector(
        (state) => state.entities.directusService.locationsByServiceId
    );
    const contractDetailsById = useSelector(
        (state) => state.entities.directusService.contractDetailsById
    );
    const servicesByContractId = useSelector(
        (state) => state.entities.directusService.servicesByContractId
    );

    // CONFIG FNS
    const configParticipantEvents = () => {
        const businessHours = getBusinessHours();
        let appEvents;
        if (participantsAppointmentsAndSlots?.length < 1) appEvents = [{}];
        else
            appEvents = participantsAppointmentsAndSlots.map((el) => {
                const { date, start, end, location } = mapCommonParams(el, locations);
                const { appointmentType, slots, filledSlots } = configParticipantEventContent(
                    el,
                    appointmentTypes
                );
                const { color, textColor } = mapColors(el.id, usersCurrentAppointment?.id);
                const editable = el.id === usersCurrentAppointment?.id;
                const hasPermission =
                    hasRole(acceptedRoles, roles) || loggedInUser.id === el.userId;
                return {
                    id: el.id,
                    eventType: 'user',
                    hasLink: true,
                    className: !hasPermission ? 'non-click-event' : '',
                    slots,
                    filledSlots,
                    location,
                    timeContent: `${start.slice(-5)} - ${end.slice(-5)}`,
                    participantEntry: currentParticipant,
                    participant: participantsName,
                    appointmentType,
                    hasPermission,
                    date,
                    start,
                    end,
                    color,
                    textColor,
                    constraint: businessHours,
                    editable
                };
            });

        const modules = participantsCourses?.map((el) => el.modules).flat();
        let moduleEvents;
        if (modules?.length < 1) moduleEvents = [];
        else {
            moduleEvents = modules?.map((el) => {
                // course modules are non interactable.
                const { date, start, end, location } = mapCommonParams(el, locations);
                const content = configCourseEventContent(el, participantsCourses, location);
                return {
                    eventType: 'courseModules',
                    className: 'non-interactive-events',
                    content,
                    timeContent: `${start.slice(-5)} - ${end.slice(-5)}`,
                    editable: false,
                    date,
                    start,
                    end,
                    color: POLARIS_ROYAL_BLUE,
                    textColor: POLARIS_WHITE
                };
            });
        }
        setEvents([...appEvents, ...moduleEvents]);
        if (appEvents?.length > 0) {
            const cur = appEvents?.find((el) => el.id === usersCurrentAppointment?.id);
            if (cur) setCurrentEvent(cur);
        }
    };

    const configUserHiddenEvents = () => {
        const filteredModules = courseModules?.filter(
            (el) => !el.participants.some((entry) => entry.participantId === currentParticipant.id)
        );
        let filteredAppointments = usersAppointmentsAndSlots
            ?.filter((el) => el.participantId !== currentParticipant.id)
            ?.filter((el) => !el.participantIds?.includes(currentParticipant.id));
        const allUserAppointments = [...filteredAppointments, ...filteredModules];
        let events;
        if (allUserAppointments?.length < 1) events = [];
        else
            events = allUserAppointments?.map((el) => {
                const { date, start, end } = mapCommonParams(el);
                return {
                    eventType: 'hidden',
                    className: 'busy-events',
                    content: `${start.slice(-5)} - ${end.slice(-5)} Busy`,
                    editable: false,
                    color: POLARIS_GREY,
                    textColor: POLARIS_BLACK,
                    date,
                    start,
                    end
                };
            });
        setHiddenEvents(events);
    };

    // USE EFFECTS
    useEffect(() => {
        appointmentTypes?.length < 1 && dispatch(loadAppointmentTypeDetails());
        locations?.length < 1 && dispatch(loadLocationIdsAndNames());
        fetchAdviserData();
    }, []);

    useEffect(() => {
        setCurrentCalendarOwner(loggedInUser);
    }, [loggedInUser.id]);

    useEffect(() => {
        if (Object.keys(currentParticipant)?.length < 1) return;
        const { serviceId, contractId } = currentParticipant;
        if (!(serviceId in serviceDetailsById)) dispatch(loadServiceDetailsByService([serviceId]));
        dispatch(loadCoursesByParticipantId(currentParticipant.id));
        dispatch(searchParticipantAppointmentsAndSlots(currentParticipant.id));
        if (!(contractId in appointmentTypesByContractId)) {
            dispatch(setCurrentContractId(contractId));
            dispatch(loadAppointmentTypeDetailsByContractId([contractId]));
        }
        if (!(serviceId in locationsByServiceId)) {
            dispatch(setCurrentServiceId(serviceId));
            dispatch(loadLocationsByServiceId([serviceId]));
        }
        dispatch(loadParticipantEmployability(currentParticipant.id));
    }, [currentParticipant]);

    useEffect(() => {
        configUserHiddenEvents();
        configParticipantEvents();
    }, [
        usersAppointmentsAndSlots,
        courseModules,
        participantsCourses,
        participantsAppointmentsAndSlots,
        locations,
        appointmentTypes
    ]);

    useEffect(() => {
        if (!usersCurrentAppointment?.id) {
            setNewEvent({});
            setCurrentEvent({});
        }
        configParticipantEvents();
    }, [usersCurrentAppointment]);

    useEffect(() => {
        fetchAdviserData();
    }, [currentCalendarOwner.id]);

    // HELPER FNS
    const fetchAdviserData = () => {
        if (currentCalendarOwner?.id) {
            dispatch(searchUserAppointmentsAndSlots(currentCalendarOwner.id));
            dispatch(loadCourseModulesByUserId(currentCalendarOwner.id));
        }
    };

    // EVENT HANDLERS
    const onDragEditTimes = (time, update) => {
        if (update) {
            const { date, startTime, endTime } = formatFullCalendarTimes(time);
            const updatedAppointment = { ...usersCurrentAppointment, date, startTime, endTime };
            currentEvent.slots
                ? dispatch(
                      updateAppointmentSlot(
                          updatedAppointment.userId,
                          currentEvent.appointmentType,
                          updatedAppointment
                      )
                  )
                : dispatch(
                      updateParticipantAppointment(
                          updatedAppointment.userId,
                          currentEvent.appointmentType,
                          updatedAppointment
                      )
                  );
        }
        dispatch(setCurrentAppointment({}));
    };

    const onTogglePanel = (open) => {
        dispatch(setOpenAppointmentSchedulerPanel(open));
    };

    const onSelectEvent = (id) => {
        setNewEvent({});
        if (id !== usersCurrentAppointment?.id) {
            const selectedAppointment = participantsAppointmentsAndSlots.find((el) => el.id === id);
            selectedAppointment && dispatch(setCurrentAppointment(selectedAppointment));
        }
        if (id === currentEvent.id) {
            userClickCount++;
            setTimeout(() => (userClickCount = 0), 300);
            if (userClickCount > 1) dispatch(setOpenAppointmentSchedulerPanel(true));
        }
    };

    const onSelectSlot = (start, end) => {
        const { date, startTime, endTime } = formatFullCalendarTimes({ start, end });
        setNewEvent({ date, startTime, endTime });
        onTogglePanel(true);
    };

    const onAdviserChange = (chosenId) => {
        if (!chosenId) {
            if (currentCalendarOwner.id !== loggedInUser.id) {
                setCurrentCalendarOwner(loggedInUser);
            }
        } else if (chosenId && chosenId !== currentCalendarOwner.id) {
            const chosenAdviser = [...users, loggedInUser].find((el) => el.id === chosenId);
            setCurrentCalendarOwner({
                ...chosenAdviser,
                contractIds: chosenAdviser?.contracts || chosenAdviser?.contractIds,
                serviceIds: getServiceIds(chosenAdviser)
            });
        }
    };

    const onNav = (selectedEvent) => {
        dispatch(setCurrentlySelectedParticipant(selectedEvent.participantEntry));
        const { contractId } = selectedEvent.participantEntry;
        if (!(contractId in contractDetailsById))
            dispatch(loadContractDetailsForContractId(contractId));
        if (!(contractId in servicesByContractId))
            dispatch(loadServiceDetailsForContractId(contractId));
        navigate('/edit_participant');
    };

    // RENDER
    return (
        <div className={classes.container}>
            <div className="calendar-wrapper">
                <AdviserSelect
                    userId={currentCalendarOwner.id}
                    serviceIds={loggedInUser.serviceIds}
                    initialAdviser={loggedInUser}
                    onAdviserChange={onAdviserChange}
                    disabled={!hasRole(acceptedRoles, roles)}
                    customClass="calendarToolbarAdviserSelect"
                />

                <Calendar
                    events={[...events, ...hiddenEvents]}
                    currentEvent={currentEvent}
                    onDragEditTimes={onDragEditTimes}
                    onSelectSlot={onSelectSlot}
                    onSelectEvent={onSelectEvent}
                    allowSlotSelect={true}
                    initialView="timeGridDay"
                    onNav={onNav}
                />
                {openAppointmentSchedulerPanel &&
                    currentCalendarOwner &&
                    (Object.keys(newEvent).length ? (
                        <ParticipantAppointmentCreate
                            newAppointment={newEvent}
                            currentCalendarOwner={currentCalendarOwner}
                        />
                    ) : !hasRole(acceptedRoles, roles) && hasRole(viewOnlyRoles, roles) ? (
                        currentEvent.slots ? (
                            <ParticipantAppointmentSlotsView
                                usersCurrentAppointment={usersCurrentAppointment}
                                currentLocation={currentEvent.location}
                            />
                        ) : (
                            <ParticipantAppointmentView
                                usersCurrentAppointment={usersCurrentAppointment}
                                currentCalendarOwner={currentCalendarOwner}
                                currentLocation={currentEvent.location}
                                currentParticipantEntry={currentEvent.participantEntry}
                            />
                        )
                    ) : currentEvent.slots ? (
                        <ParticipantAppointmentSlotsEdit
                            usersCurrentAppointment={usersCurrentAppointment}
                            currentLocation={currentEvent.location}
                        />
                    ) : (
                        <ParticipantAppointmentEdit
                            usersCurrentAppointment={usersCurrentAppointment}
                            currentLocation={currentEvent.location}
                            currentParticipantEntry={currentEvent.participantEntry}
                        />
                    ))}
            </div>
        </div>
    );
};

export default ParticipantCalendar;
