import React from 'react';
import PropTypes from 'prop-types';

import { TableCell, TableRow } from '@mui/material';

import { formatJustTime, reverseFormatDate } from '../../../utils/dateFunctions';

import classes from '../../../commonStyles/tables.module.css';

const AuditEventHistoryTableRow = ({ row }) => (
    <TableRow hover className={`${classes.basicRowStripes}`}>
        <TableCell>{row.history?.oldValue || ' - '}</TableCell>
        <TableCell>{row.history?.newValue || ' - '}</TableCell>
        <TableCell>{row.emailAddress || ' - '}</TableCell>
        <TableCell>{reverseFormatDate(row.loggedDate) || ' - '}</TableCell>
        <TableCell>{formatJustTime(row.loggedTime) || ' - '}</TableCell>
    </TableRow>
);
export default AuditEventHistoryTableRow;

AuditEventHistoryTableRow.propTypes = {
    row: PropTypes.object
};
