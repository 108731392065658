import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import {
    DEFAULT_TABLE_ROW_OPTIONS,
    DEFAULT_TABLE_ROWS_PER_PAGE
} from '../../../../utils/formValidation/commonConstants';
import { stableSort } from '../../../../utils/sortFunctions';

import AddParticipantRow from './AddParticipantRow';

const AddParticipantTable = ({ rows, roles, type, onAddRow }) => {
    // LOCAL STATE
    const headCells = [
        { id: 'ptCode', numeric: false, label: 'ID', align: 'center' },
        {
            id: 'firstName',
            numeric: false,
            label: 'First Name',
            align: 'center',
            sortable: true,
            width: '22%'
        },
        {
            id: 'lastName',
            numeric: false,
            label: 'Last Name',
            align: 'center',
            sortable: true,
            width: '22%'
        },
        {
            id: 'emailAddress',
            numeric: false,
            label: `${type === 'SMS' ? 'Phone No.' : 'Email Address'}`,
            align: 'center',
            sortable: true,
            width: '22%'
        },
        {
            id: 'currentStatus',
            numeric: false,
            label: 'Status',
            align: 'center',
            sortable: true,
            width: '22%'
        },
        {
            id: 'select',
            numeric: false,
            label: 'Action',
            align: 'center',
            sortable: false,
            width: '12%'
        }
    ];
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('lastName');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_TABLE_ROWS_PER_PAGE);

    // EVENT HANDLERS

    const onSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPage(0);
    };

    const onChangePage = (e, newPage) => {
        const page = newPage > 0 ? newPage : 0;
        setPage(page);
    };

    const onChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    // RENDER

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table sx={{ minWidth: 320 }} aria-labelledby="tableTitle" size="medium">
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        align={headCell.align}
                                        sx={{
                                            width: headCell.width,
                                            paddingLeft:
                                                headCell.sortable && headCell.align === 'center'
                                                    ? '32px'
                                                    : ''
                                        }}
                                        sortDirection={orderBy === headCell.id ? order : false}>
                                        {headCell.sortable ? (
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={() => onSort(headCell.id)}>
                                                <Typography variant={'subtitle3'} color={'primary'}>
                                                    {headCell.label}
                                                </Typography>
                                                {orderBy === headCell.id ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {order === 'desc'
                                                            ? 'sorted descending'
                                                            : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        ) : (
                                            <Typography variant={'subtitle3'} color={'primary'}>
                                                {headCell.label}
                                            </Typography>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {stableSort(rows, orderBy, order)
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <AddParticipantRow
                                        key={row.id}
                                        row={row}
                                        roles={roles}
                                        type={type}
                                        onAddRow={onAddRow}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    data-testid="tablePagination"
                    rowsPerPageOptions={DEFAULT_TABLE_ROW_OPTIONS}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};

export default AddParticipantTable;

AddParticipantTable.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    roles: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
    onAddRow: PropTypes.func
};
