import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import {
    loadPoolAcceptedRejectionReasonsDetails,
    loadPoolRequestedRejectionReasonsDetails
} from '../../../../store/directusService';
import { updatePoolApplicationRejected } from '../../../../store/recruitmentService';
import {
    POOL_ACCEPTED_REJECTION_REASONS_DETAILS_ERROR,
    POOL_REQUESTED_REJECTION_REASONS_DETAILS_ERROR
} from '../../../../utils/formValidation/loadingErrorMessageConstants';
import { clearKeys } from '../../../../utils/objectUtils';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import MultiSelect from '../../../formElements/MultiSelect';
import TextAreaField from '../../../formElements/TextAreaField';
import FormActions from '../../../ui/formActions/FormActions';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import { initialErrorState, validate } from './validatePoolApplicationRejectEditor';

import form from '../../../../commonStyles/formStyles.module.css';

const CHARACTER_LIMIT = 500;

const PoolApplicationRejectEditor = ({
    open,
    onPoolApplicationRejectEditor,
    onPoolApplicationRejectCancelled,
    updatedPoolApplications,
    rejectedRow,
    onPoolRejectedUpdate
}) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const initialState = {
        participantPoolApplicationId: null,
        reasonIds: [],
        notes: ''
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [keys, setKeys] = useState({ reasonIds: '0' });
    const [errors, setErrors] = useState(initialErrorState);
    const [isOpen, setIsOpen] = React.useState(false);
    const [rejectionReasonsDetails, setRejectionReasonsDetails] = useState([]);

    // STORE STATE
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const poolAcceptedRejectionReasonsDetails = useSelector(
        (state) => state.entities.directusService.poolAcceptedRejectionReasonsDetails
    );
    const poolRequestedRejectionReasonsDetails = useSelector(
        (state) => state.entities.directusService.poolRequestedRejectionReasonsDetails
    );
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);

    // USE EFFECTS
    useEffect(() => {
        if (!poolAcceptedRejectionReasonsDetails || poolAcceptedRejectionReasonsDetails?.length < 1)
            dispatch(loadPoolAcceptedRejectionReasonsDetails());
        if (
            !poolRequestedRejectionReasonsDetails ||
            poolRequestedRejectionReasonsDetails?.length < 1
        )
            dispatch(loadPoolRequestedRejectionReasonsDetails());
    }, []);

    useEffect(() => {
        const statusName =
            updatedPoolApplications.find((el) => el.id === rejectedRow.id)?.statusName || '';

        statusName.toLowerCase() === 'accepted'
            ? setRejectionReasonsDetails(poolAcceptedRejectionReasonsDetails)
            : setRejectionReasonsDetails(poolRequestedRejectionReasonsDetails);
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        if (successMessage === `Pool application rejected has been updated`) {
            onCreated();
        }
    }, [successMessage]);

    // HELPER FNS
    const loadingError = () => {
        if (poolAcceptedRejectionReasonsDetails?.length < 1)
            return POOL_ACCEPTED_REJECTION_REASONS_DETAILS_ERROR;
        if (poolRequestedRejectionReasonsDetails?.length < 1)
            return POOL_REQUESTED_REJECTION_REASONS_DETAILS_ERROR;
    };

    const clearData = () => {
        setNewEntry(initialState);
        setKeys(clearKeys(keys));
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    const poolApplicationCancelled = () => {
        onPoolApplicationRejectCancelled(true, rejectedRow);
    };

    // EVENT HANDLERS
    const onRejectReasonsChange = (chosenIds) => {
        clearError('rejectionReasonIds');
        setNewEntry((prev) => ({ ...prev, reasonIds: chosenIds }));
    };

    const onNotesChange = (e) => {
        setNewEntry((prev) => ({ ...prev, notes: e.target.value }));
    };

    const onModalClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setIsOpen(false);
        }
    };

    const onCancel = () => {
        onPoolApplicationRejectEditor(false);
        poolApplicationCancelled(rejectedRow);
        clearData();
    };

    const onCreated = () => {
        onPoolApplicationRejectEditor(false);
        clearData();
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const newErrors = validate(newEntry);

        if (Object.keys(newErrors)?.length > 0) {
            setErrors(newErrors);
            return;
        }

        onPoolRejectedUpdate(false);
        dispatch(
            updatePoolApplicationRejected({
                ...newEntry,
                participantPoolApplicationId: rejectedRow.participantPoolApplicationId
            })
        );
    };

    // AWAITING CONTENT
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} />;

    // RENDER
    return (
        <Dialog open={isOpen} onClose={onModalClose} aria-labelledby="form-dialog-title">
            <form onSubmit={onSubmit} data-testid="form_start" className={form.form}>
                <DialogTitle id="form-dialog-title">Pool Application Rejection</DialogTitle>
                <DialogContent data-testid="dialog-content">
                    <MultiSelect
                        id="reasonIds"
                        key={keys.reasonIds}
                        label="Rejection Reason"
                        placeholder="Rejection Reason"
                        disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                        mandatory={true}
                        menuItems={rejectionReasonsDetails || []}
                        preSelectedIds={newEntry.reasonIds}
                        preSelects={rejectionReasonsDetails?.filter((el) =>
                            newEntry.reasonIds.includes(el.id)
                        )}
                        error={errors.reasonIds}
                        onChange={(chosenIds) => onRejectReasonsChange(chosenIds)}
                    />
                    <TextAreaField
                        id={'notes'}
                        label={'Rejection Feedback'}
                        placeholder={'Enter rejection feedback'}
                        disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                        value={newEntry.notes || ''}
                        count={`${newEntry.notes.length}/${CHARACTER_LIMIT}`}
                        maxLength={CHARACTER_LIMIT}
                        onChange={onNotesChange}
                    />
                </DialogContent>

                <DialogActions>
                    <FormActions
                        id="create"
                        btnText="Update"
                        disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                        onClose={onSubmit}
                        onCancel={onCancel}
                        customClass="noTopBorder"
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
};

PoolApplicationRejectEditor.propTypes = {
    open: PropTypes.bool.isRequired,
    onPoolApplicationRejectEditor: PropTypes.func,
    onPoolApplicationRejectCancelled: PropTypes.func,
    updatedPoolApplications: PropTypes.array.isRequired,
    rejectedRow: PropTypes.object,
    onPoolRejectedUpdate: PropTypes.func
};

export default PoolApplicationRejectEditor;
