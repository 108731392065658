import PropTypes from 'prop-types';

import { CircularProgress } from '@mui/material';

import { LOADING } from '../../utils/formValidation/loadingErrorMessageConstants';

import app from '../../app.module.css';

const BusyIndicator = ({ className = '' }) => {
    return (
        <div data-testid="testIdBusyIndicator" className={className}>
            <div className={app.sectionHeading}>{LOADING}</div>
            <div>
                <CircularProgress />
            </div>
        </div>
    );
};

BusyIndicator.propTypes = {
    className: PropTypes.string
};

export default BusyIndicator;
