import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    loadAttractionDetailDetailsByAttraction,
    loadAttractionDetails,
    loadDriversLicenceDetails,
    loadEthnicityDetails,
    loadFirstLanguageDetails,
    loadHousingDetails,
    loadMaritalStatusDetails
} from '../../../store/directusService';
import {
    loadParticipantGeneralInformation,
    updateParticipantGeneralInformation
} from '../../../store/participantService';
import { getConfiguredItems } from '../../../utils/directusFunctions';
import {
    ATTRACTION_DETAILS_ERROR,
    ETHNICITY_DETAILS_ERROR,
    HOUSING_DETAILS_ERROR,
    LANGUAGE_DETAILS_ERROR,
    LICENCE_DETAILS_ERROR,
    MARITAL_STATUS_DETAILS_ERROR
} from '../../../utils/formValidation/loadingErrorMessageConstants';
import { clearKeys } from '../../../utils/objectUtils';
import {
    ADVISER,
    hasRole,
    MANAGER,
    PRAP,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import MultiSelect from '../../formElements/MultiSelect';
import RadioButtons from '../../formElements/RadioButtons';
import SingleSelect from '../../formElements/SingleSelect';
import TextInputField from '../../formElements/TextInputField';
import LoadingSpinner from '../../ui/LoadingSpinner';
import PillList from '../../ui/pillList/PillList';

import { initialErrorState, validate } from './validateParticipantInformation';

import form from '../../../commonStyles/formStyles.module.css';

const RADIO_BUTTON_NO = 'no';
const RADIO_BUTTON_YES = 'yes';
const RADIO_BUTTON_PREFER_NOT_TO_SAY = 'prefer not to say';

const ParticipantInformation = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        accessToOwnTransport: null,
        accessToPublicTransport: null,
        attractionId: null,
        attractionDetailId: null,
        hasDependents: 'no',
        dependents: [],
        driversLicenseIds: [],
        ethnicityId: null,
        firstLanguageId: null,
        hobbies: [],
        housingId: null,
        maritalStatusId: null,
        poNumber: ''
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [initialStateOnEntry, setInitialStateOnEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);

    const acceptedRoles = [ADVISER, MANAGER, PRAP, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const acceptedRolesForPoNumber = [PRAP, QUALITY, SUPERUSER];

    const [preSelectedDriverLicenses, setPreSelectedDriverLicenses] = useState([]);
    const [preSelectedDriverLicenseIds, setPreSelectedDriverLicenseIds] = useState([]);

    const [arrayEthnicities, setArrayEthnicities] = useState([]);
    const [arrayFirstLanguages, setArrayFirstLanguages] = useState([]);
    const [arrayDriversLicences, setArrayDriversLicences] = useState([]);
    const [arrayMaritalStatuses, setArrayMaritalStatuses] = useState([]);
    const [arrayHousing, setArrayHousing] = useState([]);
    const [arrayAttraction, setArrayAttraction] = useState([]);
    const [arrayAttractionDetail, setArrayAttractionDetail] = useState([]);

    const [dependentsTextContent, setDependentsTextContent] = useState('');
    const [hobbiesTextContent, setHobbiesTextContent] = useState('');
    const [showHobbiesTextField, setShowHobbiesTextField] = useState(false);
    const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const [keys, setKeys] = useState({
        ethnicity: '0',
        firstLanguage: '1',
        driversLicences: '2',
        maritalStatus: '3',
        housing: '4',
        attraction: '5',
        attractionDetail: '6'
    });

    // STORE STATE
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const errorMessage = useSelector((state) => state.entities.formsState.errorMessage);
    const { currentParticipant, currentParticipantGeneralInformation } = useSelector(
        (state) => state.entities.participantService
    );
    const {
        firstLanguageDetails,
        driversLicenceDetails,
        ethnicityDetails,
        maritalStatusDetails,
        housingDetails,
        attractionDetails,
        attractionDetailDetails
    } = useSelector((state) => state.entities.directusService);

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadEthnicityDetails());
        dispatch(loadFirstLanguageDetails());
        dispatch(loadDriversLicenceDetails());
        dispatch(loadMaritalStatusDetails());
        dispatch(loadHousingDetails());
        dispatch(loadAttractionDetails());
    }, []);

    useEffect(() => {
        setKeys(clearKeys(keys));
        setNewEntry(initialState);
        setInitialStateOnEntry(initialState);
        if (currentParticipant?.id) {
            dispatch(loadParticipantGeneralInformation(currentParticipant.id));
        }
    }, [currentParticipant.id]);

    useEffect(() => {
        if (currentParticipantGeneralInformation?.participantId === currentParticipant?.id) {
            if (currentParticipantGeneralInformation.attractionId) {
                dispatch(
                    loadAttractionDetailDetailsByAttraction(
                        currentParticipantGeneralInformation.attractionId
                    )
                );
            }

            setPreSelectedDriverLicenseIds(currentParticipantGeneralInformation.driversLicenseIds);
            // eslint-disable-next-line no-unused-vars
            const { participantId, ...rest } = currentParticipantGeneralInformation;
            setNewEntry((prev) => ({ ...prev, ...rest }));
            setInitialStateOnEntry((prev) => ({ ...prev, ...rest }));
            setShowHobbiesTextField(
                currentParticipantGeneralInformation.hobbies &&
                    currentParticipantGeneralInformation.hobbies.length > 0
            );
        }
    }, [currentParticipantGeneralInformation.participantId]);

    useEffect(() => {
        setArrayEthnicities(
            getConfiguredItems(ethnicityDetails, currentParticipant?.contractId) || []
        );
    }, [ethnicityDetails]);

    useEffect(() => {
        setArrayFirstLanguages(
            getConfiguredItems(firstLanguageDetails, currentParticipant?.contractId) || []
        );
    }, [firstLanguageDetails]);

    useEffect(() => {
        setArrayDriversLicences(
            getConfiguredItems(driversLicenceDetails, currentParticipant?.contractId) || []
        );
    }, [driversLicenceDetails]);

    useEffect(() => {
        if (
            currentParticipantGeneralInformation?.participantId === currentParticipant?.id &&
            arrayDriversLicences?.length !== 0
        ) {
            const preSelectedDriverLicenses = arrayDriversLicences.filter((el) =>
                currentParticipantGeneralInformation.driversLicenseIds?.includes(el.id)
            );
            setPreSelectedDriverLicenses(preSelectedDriverLicenses);
        }
    }, [arrayDriversLicences, currentParticipantGeneralInformation.participantId]);

    useEffect(() => {
        setArrayMaritalStatuses(
            getConfiguredItems(maritalStatusDetails, currentParticipant?.contractId) || []
        );
    }, [maritalStatusDetails]);

    useEffect(() => {
        setArrayHousing(getConfiguredItems(housingDetails, currentParticipant?.contractId) || []);
    }, [housingDetails]);

    useEffect(() => {
        setArrayAttraction(
            getConfiguredItems(attractionDetails, currentParticipant?.contractId) || []
        );
    }, [attractionDetails]);

    useEffect(() => {
        setArrayAttractionDetail(attractionDetailDetails);
    }, [attractionDetailDetails]);

    useEffect(() => {
        if (successMessage === 'Participant general details have been updated') {
            setIsUpdating(false);
            setIsUpdateButtonDisabled(false);
        }
    }, [successMessage]);

    useEffect(() => {
        if (errorMessage) setIsUpdateButtonDisabled(false);
    }, [errorMessage]);

    useEffect(() => {
        if (!isUpdating) {
            setIsUpdateButtonDisabled(Object.values(errors).some((el) => el.error));
        }
    }, [errors]);

    // HELPER FNS
    const loadingError = () => {
        if (arrayEthnicities?.length < 1) return ETHNICITY_DETAILS_ERROR;
        if (arrayFirstLanguages?.length < 1) return LANGUAGE_DETAILS_ERROR;
        if (arrayDriversLicences?.length < 1) return LICENCE_DETAILS_ERROR;
        if (arrayMaritalStatuses?.length < 1) return MARITAL_STATUS_DETAILS_ERROR;
        if (arrayHousing?.length < 1) return HOUSING_DETAILS_ERROR;
        if (arrayAttraction?.length < 1) return ATTRACTION_DETAILS_ERROR;
    };
    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    // EVENT HANDLERS
    const onAttractionChange = (chosenId) => {
        onUpdate('attractionId', chosenId);
        if (chosenId) {
            setArrayAttractionDetail([]);
            dispatch(loadAttractionDetailDetailsByAttraction(chosenId));
        }
    };

    const onUpdateHasDependents = (option) => {
        onUpdate('hasDependents', option);
        if (option !== RADIO_BUTTON_YES) {
            onUpdate('dependents', []);
        }
    };

    const onAddingDependent = () => {
        if (
            dependentsTextContent.length === 0 ||
            newEntry.dependents.includes(dependentsTextContent)
        )
            return;
        onUpdate('dependents', [...newEntry.dependents, dependentsTextContent]);
        setDependentsTextContent('');
    };

    const onRemovingDependent = (dependentToBeRemoved) => {
        onUpdate(
            'dependents',
            newEntry.dependents.filter((dependent) => dependent !== dependentToBeRemoved)
        );
    };

    const onAddingHobby = () => {
        if (hobbiesTextContent.length === 0 || newEntry.hobbies.includes(hobbiesTextContent))
            return;
        onUpdate('hobbies', [...newEntry.hobbies, hobbiesTextContent]);
        setHobbiesTextContent('');
    };

    const onRemovingHobby = (hobbyToBeRemoved) => {
        onUpdate(
            'hobbies',
            newEntry.hobbies.filter((hobby) => hobby !== hobbyToBeRemoved)
        );
    };

    const onUpdate = (key, value) => {
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { newErrors } = validate(newEntry, initialStateOnEntry);
        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) return;

        setIsUpdating(true);
        setIsUpdateButtonDisabled(true);
        const payload = {
            ...newEntry,
            participantId: currentParticipant.id,
            attractionDetailId: newEntry.attractionId === null ? null : newEntry.attractionDetailId
        };
        dispatch(updateParticipantGeneralInformation(payload));
    };

    // AWAITING CONTENT
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} />;

    // RENDER
    return (
        <div className={form.formWrapper}>
            <form onSubmit={onSubmit} data-testid="form_start" className={form.form}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <SingleSelect
                            id={'housingId'}
                            key={keys.housing}
                            label={'Housing'}
                            placeholder={'Search housing...'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={arrayHousing}
                            selectedId={newEntry.housingId}
                            selected={arrayHousing.find((el) => el.id === newEntry.housingId) || {}}
                            onChange={(chosenId) => onUpdate('housingId', chosenId)}
                        />
                        <SingleSelect
                            id={'attractionId'}
                            key={keys.attraction}
                            label={'Attraction'}
                            placeholder={'Search attraction...'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={arrayAttraction}
                            selectedId={newEntry.attractionId}
                            selected={
                                arrayAttraction.find((el) => el.id === newEntry.attractionId) || {}
                            }
                            onChange={(chosenId) => onAttractionChange(chosenId)}
                        />
                        <SingleSelect
                            id={'attractionDetailId'}
                            key={keys.attractionDetail}
                            label={'Attraction Detail'}
                            placeholder={'Search attraction details...'}
                            disabled={!newEntry.attractionId || !hasRole(acceptedRoles, roles)}
                            menuItems={arrayAttractionDetail}
                            selectedId={newEntry.attractionDetailId}
                            selected={
                                arrayAttractionDetail.find(
                                    (el) => el.id === newEntry.attractionDetailId
                                ) || {}
                            }
                            onChange={(chosenId) => onUpdate('attractionDetailId', chosenId)}
                        />
                        <MultiSelect
                            id="driversLicences"
                            key={keys.driversLicences}
                            label="Driver's Licence"
                            disabled={!hasRole(acceptedRoles, roles)}
                            placeholder="Drivers Licence"
                            menuItems={arrayDriversLicences}
                            preSelectedIds={preSelectedDriverLicenseIds}
                            preSelects={preSelectedDriverLicenses}
                            onChange={(chosenIds) => onUpdate('driversLicenseIds', chosenIds)}
                        />
                        <RadioButtons
                            id={'accessToOwnTransport'}
                            label={'Access to own transport'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={newEntry.accessToOwnTransport || false}
                            onChange={(option) => onUpdate('accessToOwnTransport', option)}
                        />
                        <RadioButtons
                            id={'accessToPublicTransport'}
                            label={'Access to Public transport'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={newEntry.accessToPublicTransport || false}
                            onChange={(option) => onUpdate('accessToPublicTransport', option)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <SingleSelect
                            id={'ethnicityId'}
                            key={keys.ethnicity}
                            label={'Ethnicity'}
                            placeholder={'Search ethnicities...'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={arrayEthnicities}
                            selectedId={newEntry.ethnicityId}
                            selected={
                                arrayEthnicities.find((el) => el.id === newEntry.ethnicityId) || {}
                            }
                            onChange={(chosenId) => onUpdate('ethnicityId', chosenId)}
                        />
                        <SingleSelect
                            id={'firstLanguageId'}
                            key={keys.firstLanguage}
                            label={'First Language'}
                            placeholder={'Search first languages...'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={arrayFirstLanguages}
                            selectedId={newEntry.firstLanguageId}
                            selected={
                                arrayFirstLanguages.find(
                                    (el) => el.id === newEntry.firstLanguageId
                                ) || {}
                            }
                            onChange={(chosenId) => onUpdate('firstLanguageId', chosenId)}
                        />
                        <SingleSelect
                            id={'maritalStatusId'}
                            key={keys.maritalStatus}
                            label={'Marital Status'}
                            placeholder={'Search marital statuses...'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={arrayMaritalStatuses}
                            selectedId={newEntry.maritalStatusId}
                            selected={
                                arrayMaritalStatuses.find(
                                    (el) => el.id === newEntry.maritalStatusId
                                ) || {}
                            }
                            onChange={(chosenId) => onUpdate('maritalStatusId', chosenId)}
                        />
                        <RadioButtons
                            id={'hasDependents'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            label={'Dependents'}
                            radioButtons={[
                                { id: RADIO_BUTTON_NO, label: 'No', onChangeVal: RADIO_BUTTON_NO },
                                {
                                    id: RADIO_BUTTON_YES,
                                    label: 'Yes',
                                    onChangeVal: RADIO_BUTTON_YES
                                },
                                {
                                    id: RADIO_BUTTON_PREFER_NOT_TO_SAY,
                                    label: 'Prefer not to say',
                                    onChangeVal: RADIO_BUTTON_PREFER_NOT_TO_SAY
                                }
                            ]}
                            value={newEntry.hasDependents}
                            onChange={onUpdateHasDependents}
                        />
                        <PillList
                            id={'dependent-pills'}
                            pills={newEntry.dependents}
                            onRemoving={onRemovingDependent}
                        />
                        {newEntry.hasDependents === RADIO_BUTTON_YES && (
                            <TextInputField
                                id={'dependents'}
                                label={"Enter Dependant's Name"}
                                disabled={!hasRole(acceptedRoles, roles)}
                                value={dependentsTextContent}
                                onChange={(e) => setDependentsTextContent(e.target.value)}
                                onEnter={onAddingDependent}
                            />
                        )}
                        <RadioButtons
                            id={'hobbiesRadio'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            label={'Hobbies and Interests'}
                            value={showHobbiesTextField}
                            onChange={(option) => setShowHobbiesTextField(option)}
                        />
                        <PillList
                            id={'hobby-pills'}
                            pills={newEntry.hobbies}
                            onRemoving={onRemovingHobby}
                        />
                        {showHobbiesTextField && (
                            <TextInputField
                                id={'hobbiesTextField'}
                                label={'Enter Hobby or Interests'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                value={hobbiesTextContent}
                                onChange={(e) => setHobbiesTextContent(e.target.value)}
                                onEnter={onAddingHobby}
                            />
                        )}
                        <TextInputField
                            id={'poNumber'}
                            label={'PO Number'}
                            placeholder={'Enter value'}
                            disabled={!hasRole(acceptedRolesForPoNumber, roles)}
                            value={newEntry.poNumber || ''}
                            onChange={(e) =>
                                setNewEntry((prev) => ({
                                    ...prev,
                                    poNumber: e.target.value
                                        .replace(/[^0-9a-z\s-/]/gi, '')
                                        .slice(0, 100)
                                }))
                            }
                        />
                    </div>
                </div>

                <Button
                    data-testid="testIdSubmitButton"
                    content={'Update Participant Information'}
                    disabled={!hasRole(acceptedRoles, roles) || isUpdateButtonDisabled}
                    error={errors.button}
                    clearError={() => clearError('button')}
                />
            </form>
        </div>
    );
};

export default ParticipantInformation;
