import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import { clearLocalStorage, saveAccessToken, setIdToken } from '../store/userService';

import { b2cPolicies } from './authConfig';

const Masl = () => {
    /**
     * useMsal is hook that returns the PublicClientApplication instance,
     * an array of all accounts currently signed in and an inProgress value
     * that tells you what msal is currently doing. For more, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */
    const { instance } = useMsal();
    const dispatch = useDispatch();
    useEffect(() => {
        const callbackId = instance.addEventCallback((event) => {
            if (
                (event.eventType === EventType.LOGIN_SUCCESS ||
                    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                event.payload.account
            ) {
                // set idToken in store
                dispatch(setIdToken(event.payload.idToken));
                // save idToken in localStorage for refresh
                saveAccessToken(event.payload.idToken);
            } else if (event.eventType === EventType.LOGIN_FAILURE) {
                // Check for forgot password error
                // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                    const resetPasswordRequest = {
                        authority: b2cPolicies.authorities.forgotPassword.authority,
                        scopes: []
                    };
                    instance.loginRedirect(resetPasswordRequest);
                }
            } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
                clearLocalStorage();
                instance.loginRedirect({
                    authority: b2cPolicies.authorities.signUpSignIn.authority
                });
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, [instance]);
};

export default Masl;
