import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ClearIcon from '@mui/icons-material/Clear';

import { compareUnorderedArrays } from '../../utils/arrayUtils';
import IconError from '../IconError';
import TrackVisibility from '../ui/TrackVisibility';

import common from './formElementStyles/commonFormElements.module.css';
import local from './formElementStyles/multiSelect.module.css';

const MultiSelect = ({
    id,
    disabled = false,
    removable = true,
    nonEditableIds = [],
    mandatory = false,
    error = {},
    label,
    placeholder,
    menuItems,
    preSelectedIds = [],
    preSelects = [],
    onChange,
    compareUpdatedItems = false,
    selectAllDisabled = false,
    maxPills = null,
    onLoadMoreItems,
    totalItems,
    customClass = '',
    capitalizeList = true
}) => {
    const toggleRef = useRef(null);
    const listRef = useRef(null);
    const pillListRef = useRef(null);
    const menuItemsLengthRef = useRef(null);
    const [listItems, setListItems] = useState([]);
    const [rows, setRows] = useState([]);
    const [selected, setSelected] = useState(preSelects);
    const [selectedIds, setSelectedIds] = useState(preSelectedIds);
    const [isListOpen, setIsListOpen] = useState(false);
    const [isCountOpen, setIsCountOpen] = useState(false);
    const [areAllSelected, setAreAllSelected] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const moreItemsToLoad = onLoadMoreItems ? !totalItems || menuItems.length < totalItems : false;

    // HELPER FNS

    const isSelected = (id) => selected.find((el) => el.id === id);

    const onCheckAll = () => {
        if (menuItems.length > 0 && preSelects.length === menuItems.length) setAreAllSelected(true);
        else setAreAllSelected(false);
    };

    const isEditable = (id) => {
        return !(nonEditableIds.includes(id) || !removable);
    };

    // USE EFFECTS
    useEffect(() => {
        if (
            compareUnorderedArrays(preSelectedIds, selectedIds) &&
            compareUnorderedArrays(preSelects, selected)
        )
            return;
        setSelectedIds(preSelectedIds);
        setSelected(preSelects);
        onCheckAll();
    }, [preSelectedIds, preSelects]);

    useEffect(() => {
        const items = menuItems.slice().sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
        setListItems(items);
        setRows(items);
        onCheckAll();
        if (compareUpdatedItems && menuItems.length < listItems.length) {
            const menuItemIds = menuItems.map((el) => el.id);
            const filterSelected = selected.filter((el) => menuItemIds.includes(el.id));
            setSelected(filterSelected);
            setSelectedIds(filterSelected.map((el) => el.id));
        }
        if (onLoadMoreItems) {
            // Load more if all items from the last loaded page have been filtered out
            if (isListOpen && menuItems.length === menuItemsLengthRef.current) {
                onLoadMoreItems();
            } else {
                menuItemsLengthRef.current = menuItems.length;
            }
        }
    }, [menuItems]);

    useEffect(() => {
        if (isListOpen && onLoadMoreItems && !totalItems) onLoadMoreItems();
    }, [selectedIds, isListOpen]);

    useEffect(() => {
        onChange(selectedIds);
    }, [selectedIds]);

    useEffect(() => {
        if (listItems.length < 1) return;
        const filtered = searchTerm
            ? listItems.filter((el) => el.name.toLowerCase().includes(searchTerm.toLowerCase()))
            : listItems;
        setRows(filtered);
        onOpenList();
    }, [searchTerm]);

    useEffect(() => {
        if (isListOpen) {
            ['click', 'mouseout'].forEach((el) => document.addEventListener(el, onCloseList));
        }
        return () =>
            ['click', 'mouseout'].forEach((el) => document.removeEventListener(el, onCloseList));
    }, [isListOpen]);

    // EVENT HANDLERS

    const onSelect = (_, item) => {
        if (!isEditable(item.id)) return;
        if (selected.find((el) => el.id === item.id)) {
            setSelected(selected.filter((el) => el.id !== item.id));
            setSelectedIds(selectedIds.filter((el) => el !== item.id));
            areAllSelected && setAreAllSelected(false);
        } else {
            if (selected.length === listItems.length - 1) setAreAllSelected(true);
            setSelected((prev) => [...prev, item]);
            setSelectedIds((prev) => [...prev, item.id]);
            setSearchTerm('');
        }
    };

    const onRemovePill = (item) => {
        if (!isEditable(item.id)) return;
        setSelected(selected.filter((el) => el.id !== item.id));
        setSelectedIds(selectedIds.filter((el) => el !== item.id));
        areAllSelected && setAreAllSelected(false);
    };

    const onSelectAll = () => {
        if (selected.length === menuItems.length) return;
        const selectableItems = rows.filter(
            (el) => isEditable(el.id) || selectedIds.includes(el.id)
        );
        setSelected(selectableItems);
        setSelectedIds(selectableItems.map((el) => el.id));
        setAreAllSelected(true);
    };

    const onDeselectAll = () => {
        if (!removable) return;
        const nonRemovableItems = rows.filter(
            (el) => !isEditable(el.id) && selectedIds.includes(el.id)
        );
        setSelected(nonRemovableItems);
        setSelectedIds(nonRemovableItems.map((el) => el.id));
        setAreAllSelected(false);
    };

    const onOpenList = () => {
        if (disabled) return;
        if (toggleRef.current && listRef.current) {
            if (!toggleRef.current.classList.contains('toggleOpen')) {
                toggleRef.current.classList.add('toggleOpen');
                setIsListOpen(true);
            }
        }
    };

    const onCloseList = (e) => {
        const selectorEl = document.getElementById(id);
        const listEl = document.querySelector(`.${local.showList}`);
        const selectAllEl = document.querySelector(`.${local.selectAll}`);
        if (
            e &&
            typeof e.composedPath === 'function' &&
            !e.composedPath().includes(selectorEl) &&
            !e.composedPath().includes(listEl) &&
            !e.composedPath().includes(selectAllEl)
        ) {
            if (toggleRef.current && listRef.current) {
                if (toggleRef.current.classList.contains('toggleOpen')) {
                    toggleRef.current.classList.remove('toggleOpen');
                    setIsListOpen(false);
                }
            }
        }
    };

    const onToggle = () => {
        if (disabled) return;
        if (toggleRef.current && listRef.current) {
            if (toggleRef.current.classList.contains('toggleOpen')) {
                toggleRef.current.classList.remove('toggleOpen');
                setIsListOpen(false);
            } else {
                toggleRef.current.classList.add('toggleOpen');
                setIsListOpen(true);
            }
        }
    };

    return (
        <div className={`${local.multiSelect} ${local[customClass] ?? ''}`} data-testid={id}>
            <label id={`label-${id}`} htmlFor={id} className={common.formLabel}>
                <span>
                    {label} {mandatory && <sup>*</sup>}
                </span>
            </label>
            <div
                data-testid={`${id}-selector`}
                className={`${local.selector} ${
                    disabled ? `${local.selectorDisabled}` : `${local.selectorActive}`
                } ${selectAllDisabled ? `${local.noAllSelectorInput}` : ''} ${
                    selected.length < 1 ? `${local.removeColGap}` : ''
                }`}
                id={id}
                disabled={disabled}
                aria-labelledby={`label-${id}`}>
                <span
                    className={`${local.pillList} ${local.scroller} ${
                        isCountOpen ? `${local.pillListExpand}` : ''
                    }`}
                    ref={pillListRef}>
                    {selected.map((el, index) => {
                        if (!isCountOpen && maxPills !== null && index >= maxPills) return null;
                        return (
                            <span
                                role={'pill'}
                                className={`${local.selectedPill} ${
                                    disabled ? `${local.pillDisabled}` : ''
                                }`}
                                key={el.id}>
                                <span className={local.selectedPillText}>{el.name}</span>
                                {isEditable(el.id) && (
                                    <span
                                        role={'button'}
                                        className={`${local.clearIcon} ${
                                            disabled ? `${local.clearIconDisabled}` : ''
                                        }`}
                                        onClick={() => onRemovePill(el)}>
                                        <ClearIcon
                                            data-testid={`clear_icon-${el.name?.toLowerCase()}`}
                                        />
                                    </span>
                                )}
                            </span>
                        );
                    })}
                </span>

                <span className={local.count} onClick={() => setIsCountOpen(!isCountOpen)}>
                    {selected.length > 0 ? `x${selected.length}` : ''}
                </span>
                <span className={local.searchInput}>
                    <input
                        id={id}
                        type="search"
                        value={searchTerm}
                        placeholder={placeholder}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        disabled={disabled}
                    />
                </span>

                <span
                    className={`${local.toggle} ${
                        disabled ? `${local.toggleDisabled}` : `${local.toggleActive}`
                    }`}
                    data-testid={`dropDownIcon-${id}`}
                    onClick={onToggle}
                    ref={toggleRef}>
                    <ArrowDropDownIcon />
                </span>
            </div>

            <div className={local.selectListWrapper}>
                <div
                    className={`${local.selectList} ${local.scroller} ${capitalizeList ? local.capitalize : ''} ${
                        isListOpen
                            ? `${local.showList} ${
                                  selectAllDisabled ? `${local.showListNoAllSelector}` : ''
                              }`
                            : `${local.hideList}`
                    } ${selectAllDisabled ? `${local.noAllSelector}` : ''}`}
                    ref={listRef}>
                    {isListOpen && (
                        <ul data-testid={`list-${id}`}>
                            {rows.map((el, index) =>
                                isSelected(el.id) ? (
                                    <li
                                        key={el.id}
                                        onClick={(e) => onSelect(e, el)}
                                        className={local.selected}>
                                        <CheckBoxIcon />
                                        <span>{el.name}</span>
                                    </li>
                                ) : (
                                    <li key={el.id} onClick={(e) => onSelect(e, el)}>
                                        <CheckBoxOutlineBlankIcon />
                                        {searchTerm === '' &&
                                        moreItemsToLoad &&
                                        index + 1 === rows.length ? (
                                            <TrackVisibility
                                                onVisible={onLoadMoreItems}
                                                options={{ threshold: 0.1 }}>
                                                {el.name}
                                            </TrackVisibility>
                                        ) : (
                                            <span>{el.name}</span>
                                        )}
                                    </li>
                                )
                            )}
                        </ul>
                    )}
                </div>
                {menuItems.length > 0 && !selectAllDisabled ? (
                    <ul className={`${local.selectList} ${isListOpen ? local.selectAll : ''}`}>
                        {menuItems.length === selected.length ? (
                            <li onClick={onDeselectAll} className={local.selected}>
                                <CheckBoxIcon />
                                Deselect All
                            </li>
                        ) : (
                            <li onClick={onSelectAll}>
                                <CheckBoxOutlineBlankIcon />
                                Select All
                            </li>
                        )}
                    </ul>
                ) : (
                    !selectAllDisabled && (
                        <div
                            className={`${isListOpen ? `${local.selectAll} ${local.noSelectionsExpanded}` : ''} ${local.noSelections}`}>
                            <span> No Items </span>
                        </div>
                    )
                )}
            </div>
            {error?.error && (
                <div
                    className={`${
                        selectAllDisabled
                            ? local.noSelectAllError
                            : isListOpen
                              ? local.errorMessageListOpen
                              : local.errorMessage
                    }`}>
                    <IconError text={error} />
                </div>
            )}
        </div>
    );
};

export default MultiSelect;

MultiSelect.propTypes = {
    id: PropTypes.string,
    disabled: PropTypes.bool,
    removable: PropTypes.bool,
    nonEditableIds: PropTypes.arrayOf(PropTypes.string),
    mandatory: PropTypes.bool,
    error: PropTypes.object,
    menuItems: PropTypes.arrayOf(PropTypes.object),
    preSelectedIds: PropTypes.arrayOf(PropTypes.string),
    preSelects: PropTypes.arrayOf(PropTypes.object),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    compareUpdatedItems: PropTypes.bool,
    selectAllDisabled: PropTypes.bool,
    maxPills: PropTypes.number,
    onLoadMoreItems: PropTypes.func,
    totalItems: PropTypes.number,
    customClass: PropTypes.string,
    capitalizeList: PropTypes.bool
};
