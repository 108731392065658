import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { MAX_EMAIL, MAX_PHONE } from '../../../../utils/formValidation/commonConstants';
import { charVal } from '../../../../utils/formValidation/validationPatterns';
import { hasRole } from '../../../../utils/userRoles';
import Button from '../../../formElements/Button';
import TextAreaField from '../../../formElements/TextAreaField';
import TextInputField from '../../../formElements/TextInputField';
import { validate } from '../validation/validateCreateBusinessContact';

import modal from '../../../../commonStyles/modal.module.css';

const NOTES_CHARACTER_LIMIT = 750;

const CreateBusinessContact = ({
    type,
    entry = {},
    onAddContact,
    onEditContact,
    onCancel,
    isOpen,
    isDisabled = false,
    roles,
    acceptedRoles
}) => {
    const initialState = {
        id: '',
        firstName: '',
        lastName: '',
        positions: '',
        officeNumber: '',
        mobileNumber: '',
        email: '',
        notes: ''
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [disabled, setDisabled] = useState(true);
    const [errors, setErrors] = useState({});

    // USE EFFECTS

    useEffect(() => {
        if (type === 'create') return;
        setNewEntry({ ...entry, notes: entry.notes || '' });
    }, [type]);

    useEffect(() => {
        setDisabled(isDisabled);
    }, [isDisabled]);

    useEffect(() => {
        setDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    // HELPER FNS

    const clearError = (key) =>
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false, message: '' } }));

    // EVENT HANDLERS

    const onUpdate = (e) => {
        const { id, value } = e.target;
        if (id in errors) clearError(id);
        setNewEntry((prev) => ({ ...prev, [id]: value }));
    };

    const onCancelContact = () => {
        setErrors({});
        setNewEntry(initialState);
        onCancel();
    };

    const onContactSubmit = (e) => {
        e.preventDefault();
        const newErrors = validate(newEntry, errors);
        setErrors(newErrors);
        if (Object.values(newErrors).some((el) => el.error)) return;
        type === 'create' ? onAddContact(newEntry) : onEditContact(newEntry);
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onCancel}
            fullWidth={true}
            aria-labelledby="form-dialog-title">
            <form data-testid="form_start">
                <DialogTitle id="form-dialog-title">
                    {type === 'create' ? 'Create' : 'Edit'} Business Contact
                </DialogTitle>
                <DialogContent data-testid="dialog-content">
                    <TextInputField
                        id={'firstName'}
                        label={'First Name'}
                        placeholder={'Enter business contact first name'}
                        disabled={!hasRole(acceptedRoles, roles)}
                        mandatory={true}
                        maxLength={charVal.MAX_NAME}
                        value={newEntry.firstName || ''}
                        error={errors.firstName}
                        onChange={onUpdate}
                    />
                    <TextInputField
                        id={'lastName'}
                        label={'Last Name'}
                        placeholder={'Enter business contact last name'}
                        disabled={!hasRole(acceptedRoles, roles)}
                        mandatory={true}
                        maxLength={charVal.MAX_NAME}
                        value={newEntry.lastName || ''}
                        error={errors.lastName}
                        onChange={onUpdate}
                    />
                    <TextInputField
                        id={'positions'}
                        label={'Role Title'}
                        placeholder={'Enter contact role title'}
                        disabled={!hasRole(acceptedRoles, roles)}
                        maxLength={charVal.MAX_POSITIONS}
                        value={newEntry.positions || ''}
                        error={errors.positions}
                        onChange={onUpdate}
                    />
                    <TextInputField
                        id="officeNumber"
                        label="Office Phone Number"
                        placeholder={'Enter office phone number'}
                        disabled={!hasRole(acceptedRoles, roles)}
                        mandatory={true}
                        maxLength={MAX_PHONE}
                        value={newEntry.officeNumber || ''}
                        error={errors.officeNumber}
                        onChange={onUpdate}
                    />
                    <TextInputField
                        id="mobileNumber"
                        label="Mobile Phone Number"
                        placeholder={'Enter mobile phone number'}
                        disabled={!hasRole(acceptedRoles, roles)}
                        mandatory={false}
                        maxLength={MAX_PHONE}
                        value={newEntry.mobileNumber || ''}
                        error={errors.mobileNumber}
                        onChange={onUpdate}
                    />
                    <TextInputField
                        id={'email'}
                        label={'Email Address'}
                        placeholder={'Enter email address'}
                        disabled={!hasRole(acceptedRoles, roles)}
                        mandatory={true}
                        maxLength={MAX_EMAIL}
                        value={newEntry.email || ''}
                        error={errors.email}
                        onChange={onUpdate}
                    />
                    <TextAreaField
                        id={'notes'}
                        label={'Contact Notes'}
                        placeholder={'Enter business contact notes'}
                        disabled={!hasRole(acceptedRoles, roles)}
                        maxLength={NOTES_CHARACTER_LIMIT}
                        value={newEntry.notes || ''}
                        count={`${newEntry.notes.length}/${NOTES_CHARACTER_LIMIT}`}
                        onChange={onUpdate}
                    />
                </DialogContent>

                <DialogActions>
                    <div className={modal.formActionButtons}>
                        <Button
                            id="businessContactCreateModalUpdate"
                            type="button"
                            content={type === 'create' ? 'Create' : 'Update'}
                            disabled={disabled || !hasRole(acceptedRoles, roles)}
                            onClick={onContactSubmit}
                        />

                        <Button
                            id="businessContactCreateModalCancel"
                            type="button"
                            content="Cancel"
                            onClick={onCancelContact}
                        />
                    </div>
                </DialogActions>
            </form>
        </Dialog>
    );
};

CreateBusinessContact.propTypes = {
    type: PropTypes.string,
    entry: PropTypes.object,
    isDisabled: PropTypes.bool,
    onAddContact: PropTypes.func,
    onEditContact: PropTypes.func,
    onCancel: PropTypes.func,
    isOpen: PropTypes.bool,
    roles: PropTypes.arrayOf(PropTypes.string),
    acceptedRoles: PropTypes.arrayOf(PropTypes.string)
};

export default CreateBusinessContact;
