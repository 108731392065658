import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    loadAppointmentTypeDetails,
    loadAttendanceDetails
} from '../../../../../store/directusService';
import { loadPoolsByJobSector } from '../../../../../store/recruitmentService';
import { getNameFromId } from '../../../../../utils/directusFunctions';
import { clearKeys } from '../../../../../utils/objectUtils';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../../utils/userRoles';
import SingleSelect from '../../../../formElements/SingleSelect';
import LoadingSpinner from '../../../../ui/LoadingSpinner';

import SelectPoolsTable from './SelectPoolsTable';
import { initialErrorState } from './validateSelectPoolsTableManagement';

import classes from '../../submissionStyles/pools.module.css';

const SelectPoolsTableManagement = ({ onRowsUpdated, poolApplications }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const [keys, setKeys] = useState({ jobSectorId: '0' });
    const [errors, setErrors] = useState(initialErrorState);
    const acceptedRoles = [ADVISER, SUPERUSER, MANAGER, QUALITY, RECRUITMENT_MANAGER];
    const [updatedRows, setUpdatedRows] = useState([]);
    const [selectedJobSector, setSelectedJobSector] = useState('');

    // STORE STATE
    const jobSectorDetails = useSelector(
        (state) => state.entities.directusService.jobSectorDetails
    );
    const poolStatusDetails = useSelector(
        (state) => state.entities.directusService.poolStatusDetails
    );
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const selectedPools = useSelector((state) => state.entities.recruitmentService.pools);
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadAttendanceDetails);
        dispatch(loadAppointmentTypeDetails());
        setUpdatedRows([]);
        setKeys(clearKeys(keys));
    }, []);

    useEffect(() => {
        if (!selectedJobSector) {
            setUpdatedRows([]);
        } else {
            dispatch(loadPoolsByJobSector(selectedJobSector));
        }
    }, [selectedJobSector]);

    useEffect(() => {
        if (selectedPools?.length < 1 || !selectedJobSector) {
            setUpdatedRows([]);
            return;
            // }
        }
        const poolsForJobSectorWithStatusId = selectedPools.map((el) => ({
            ...el,
            statusId:
                poolApplications?.find((entry) => el.id === entry.participantPoolId)?.statusId || ''
        }));
        setUpdatedRows(
            poolsForJobSectorWithStatusId
                .filter(
                    (el) =>
                        (el.teamIds.some((entry) => entry === currentParticipant.teamId) &&
                            el.statusId === '') ||
                        getNameFromId(poolStatusDetails, el.statusId).toLowerCase() === 'rejected'
                )
                .map((el) => ({
                    ...el,
                    update: false
                }))
        );
    }, [selectedPools]);

    // HELPER FNS
    const getPreSelect = (arr, id) => arr.find((el) => el.id === id) || {};

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // EVENT HANDLERS
    const onJobSectorChange = (chosenId) => {
        clearError('jobSectorId');
        if (!chosenId) chosenId = '';
        setSelectedJobSector(chosenId);
    };

    const onRowChange = (row) => {
        const rows = updatedRows.map((el) => (el.id === row.id ? row : el));
        setUpdatedRows(rows);
        onRowsUpdated(rows);
    };

    // RENDER
    return (
        <>
            <div className={classes.poolsToolBar}>
                <SingleSelect
                    id={'jobSectorId'}
                    key={keys.jobSectorId}
                    label={'Job Sector'}
                    placeholder="Select Job Sector..."
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                    mandatory={true}
                    menuItems={jobSectorDetails}
                    selectedId={selectedJobSector}
                    selected={getPreSelect(jobSectorDetails, selectedJobSector)}
                    error={errors.jobSectorId}
                    onChange={(chosenId) => onJobSectorChange(chosenId)}
                />
            </div>

            {updatedRows.length < 1 ? (
                <LoadingSpinner content="No pools found" />
            ) : (
                <SelectPoolsTable
                    rows={updatedRows}
                    roles={loggedInUser.roles}
                    onRowChange={onRowChange}
                />
            )}
        </>
    );
};

SelectPoolsTableManagement.propTypes = {
    onRowsUpdated: PropTypes.func,
    poolApplications: PropTypes.array
};

export default SelectPoolsTableManagement;
