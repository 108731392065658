import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';

import { loadBankHolidayData } from '../../../store/dataService';
import { POLARIS_GREY, POLARIS_ROYAL_BLUE } from '../../../themes/theme';

import SchedulingAssistantEventContent from './SchedulingAssistantEventContent';

import '../styles/calendarVendorClasses.css';

function createEventId() {
    return String(eventGuid++);
}
let eventGuid = 0;
/**
 *
 * @param events (array of objects) - to display on the calendar as events
 * @param currentEvent (object) - selected event - the id is needed and also the date and times to check for change
 * @param onSelectSlot (function) - a callback on slot select - ie. to open a panel for editing
 * @param onSelectEvent (function) -  a callback on event select - ie. to open a panel
 * @param onNav (function) - for use in the events to navigate to the PT's screen when clicking on their name
 * @param resources (array) - the users rows
 * @param onChangeResource (function) - a callback to reconfigure events as users are added or removed.
 * @param  allowSlotSelect (boolean) - allows a slot to be selected or not
 * @param eventEditPermission (boolean) - allows an event to be edited or not
 * @returns {JSX.Element}
 * @constructor
 */

const SchedulingAssistantCalendar = ({
    events,
    currentEvent,
    onSelectSlot,
    onSelectEvent,
    onNav,
    resources,
    onChangeResource,
    allowSlotSelect,
    eventEditPermission = true
}) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const [holidayEvents, setHolidayEvents] = useState([]);
    const [eventHeight, setEventHeight] = useState(0);

    // STORE STATE
    const bankHolidayData = useSelector((state) => state.entities.dataService.bankHolidayData);

    // USEEFFECTS
    useEffect(() => {
        if (bankHolidayData?.length < 1) dispatch(loadBankHolidayData());
    }, []);

    useEffect(() => {
        // TODO
        // for demo purposes - division is hard-coded - there are 3 from the API
        // 'england-and-wales', 'northern-ireland' & 'scotland'
        // also noted that it's needed - how to determine the division
        const filtered = bankHolidayData
            .filter((el) => el.division === 'england-and-wales')
            .map((el) => ({
                content: el.name,
                date: el.date.split('T')[0],
                display: 'background',
                className: 'bank-holidays'
            }));
        setHolidayEvents(filtered);
    }, [bankHolidayData]);

    useEffect(() => {
        const calendar = document.querySelector('.fc');
        const table = document.querySelector('.fc-timeline-body'); // needs to be connected to window height

        const calendarHeight = calendar.getBoundingClientRect().height;
        const eventHeight = table ? ~~((calendarHeight - 175) / resources.length) : 0;
        setEventHeight(eventHeight);

        let rows = Array.from(document.querySelectorAll('.fc-resource'));
        rows = rows.filter((el) => el.classList.contains('fc-timeline-lane'));
        rows.forEach((el, i) => {
            el.style.minHeight = `${eventHeight}px`;
            el.style.height = `${eventHeight}px`;
            el.style.maxHeight = `${eventHeight}px`;
            resources[i].active === true
                ? el.classList.add('fc-resource-row-active')
                : el.classList.remove('fc-resource-row-active');
        });

        onChangeResource();
    }, [resources]);

    // EVENT HANDLERS
    const onSelectAllow = (selectInfo) => {
        if (!allowSlotSelect) return false;
        if (selectInfo.resource) {
            return !!selectInfo.resource._resource.extendedProps.active;
        }
        return true;
    };
    const onSlotSelect = (newEvent) => {
        if (!onSelectSlot) return;
        const title = 'Click to edit';
        const calendarApi = newEvent?.view.calendar;
        calendarApi.unselect();
        if (title) {
            calendarApi.addEvent({
                id: createEventId(),
                title,
                start: newEvent?.startStr,
                end: newEvent?.endStr,
                allDay: newEvent.allDay,
                backgroundColor: currentEvent?.color || POLARIS_GREY,
                textColor: currentEvent?.textColor || POLARIS_ROYAL_BLUE,
                extendedProps: currentEvent?.detail
            });
        } else {
            calendarApi.unselect();
        }
        onSelectSlot(newEvent.startStr, newEvent.endStr);
    };

    const onEventSelect = (eventInfo) => {
        if (
            'hasPermission' in eventInfo.event.extendedProps &&
            !eventInfo.event.extendedProps?.hasPermission
        )
            return;
        if (eventEditPermission) {
            onSelectEvent(eventInfo.event._def.publicId);
        }
    };

    // RENDER
    return (
        <div className="calendar-container">
            <FullCalendar
                schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives" // https://fullcalendar.io/docs/premium
                plugins={[interactionPlugin, resourceTimelinePlugin]}
                headerToolbar={{
                    left: 'prev title next today',
                    right: ''
                }}
                views={{
                    resourceTimelineDay: {
                        expandRows: true,
                        titleFormat: {
                            weekday: 'long',
                            day: '2-digit',
                            month: 'short'
                        }
                    }
                }}
                locale={'en-GB'} // use "en-GB" for 24hr y-axis labels - but title date will revert to M DD Y
                initialView={'resourceTimelineDay'}
                navLinks={true}
                eventDisplay="block" // month view text style errors without this
                selectable={!!onSelectSlot}
                selectAllow={onSelectAllow}
                select={onSlotSelect}
                selectMirror={true}
                weekends={true}
                slotMinTime="07:00:00"
                slotMaxTime="19:00:00"
                slotDuration="00:15:00"
                slotLabelInterval="01:00:00"
                slotLabelFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: false
                }}
                resourceAreaHeaderContent=""
                resourceAreaWidth="0px"
                resourceOrder="orderId"
                resources={resources}
                resourcesInitiallyExpanded={false}
                events={[...events, ...holidayEvents]}
                eventContent={(eventInfo) => (
                    <SchedulingAssistantEventContent
                        eventInfo={eventInfo}
                        onNav={onNav}
                        eventHeight={eventHeight}
                    />
                )}
                eventClick={onEventSelect}
                editable={true}
            />
        </div>
    );
};

SchedulingAssistantCalendar.propTypes = {
    events: PropTypes.arrayOf(PropTypes.object),
    currentEvent: PropTypes.object,
    onSelectSlot: PropTypes.func,
    onSelectEvent: PropTypes.func,
    onNav: PropTypes.func,
    resources: PropTypes.arrayOf(PropTypes.object),
    onChangeResource: PropTypes.func,
    allowSlotSelect: PropTypes.bool,
    eventEditPermission: PropTypes.bool
};

export default SchedulingAssistantCalendar;
