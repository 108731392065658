import { differenceInYears, parse } from 'date-fns';

export const SECONDS_IN_ONE_DAY = 86400;

/**
 * Function to find age from supplied date (yyyy-mm-dd)
 *
 * Return an integer for age.
 *
 * @param dateOfBirth - Date containing selected date of birth entered by user
 */
export function getAge(dateOfBirth) {
    let date = parse(dateOfBirth, 'yyyy-MM-dd', new Date());
    return differenceInYears(new Date(), date);
}

/**
 * Function to return whether supplied date is today or future date (yyyy-mm-dd)
 * Return boolean.
 * @param date - Date containing selected date entered by user
 */
export function getTodayOrFutureDate(date) {
    return date >= new Date().toISOString().slice(0, 10);
}

/**
 * Function to return whether supplied date is future date (yyyy-mm-dd)
 * Return boolean.
 * @param date - Date containing selected date entered by user
 */
export function getFutureDate(date) {
    if (!date) return true;
    return date > new Date().toISOString().slice(0, 10);
}

export const countDays = (start, end) => {
    if (!start || !end) return;
    return (new Date(end) - new Date(start)) / (1000 * 3600 * 24);
};

export const reverseFormatDate = (dateString) => {
    if (!dateString?.length) return;
    return dateString.substring(0, 10).split('-').reverse().join('/');
};

/**
 * where input is in the format: '2023-10-19T00:00:00.000+00:00'
 * @param dateString
 */
export const formatDateForDateInput = (dateString) => {
    if (!dateString?.length) return;
    return dateString.substring(0, 10);
};

/**
 * where input is in the format: '20/10/2023'
 * @param dateString
 */
export const formatDateToHyphens = (dateString) => {
    if (!dateString?.length) return;
    return dateString.split('/').reverse().join('-');
};

/**
 * is date earlier than other date
 * still can be the same date
 */
export function isDateEarlier(startDate, endDate) {
    if (!startDate && !endDate) return;
    return endDate < startDate;
}

/**
 * is date later than other date
 * still can be the same date
 */
export function isDateAfter(startDate, endDate) {
    if (!startDate && !endDate) return;
    return startDate > endDate;
}

/**
 * is date (day) in past
 * @param date in form 2023-06-22T00:10:00
 */
export function isDatePast(date) {
    if (!date) return;
    return date < new Date().toISOString().slice(0, 10);
}

/**
 * check for date in future (not present or past)
 * @param date
 * @returns {boolean}
 */
export const isDateFuture = (date) => {
    if (!date) return;
    return date > new Date().toISOString().slice(0, 10);
};

/**
 * convert i.e. '2024-05-02T06:29:59.997192015Z' to 1687389000000
 * and i.e. new Date().getTime() to 1687389003970
 * and compare if past/future time
 */
export const isTimePast = (time) => Date.parse(time) < new Date().getTime();

/**
 * time in format '2024-05-02T06:29:59.997192015Z'
 * add minutes and return in same format
 */
export const timeAdd = (time, mins) => {
    const millisecs = time.slice(-6) === '+01:00' ? (mins + 60) * 60 * 1000 : mins * 60 * 1000;
    const updatedTime = new Date(Date.parse(time) + millisecs).toISOString();
    return updatedTime.slice(-5) === '.000Z' ? updatedTime.slice(0, -5) : updatedTime;
};

/**
 * @returns {string} formatted time in correct timezone
 */
export const getCurrentTime = () => new Date().toLocaleTimeString().slice(0, 5);

/**
 * @param date in form (i.e.) '2024-05-02T06:29:59.997192015Z'
 * converts to '30/08/2023, 15:02:25' (correct UK time)
 */
const convertLocale = (date) =>
    new Date(date).toLocaleString('en-GB', {
        timeZone: 'Europe/London'
    });

/**
 * @param date in form (i.e.) '2024-05-02T06:29:59.997192015Z'
 * converts and formats to (i.e.) '30/08/2023 - 15:02'
 */
export const formatDateTime = (date) => {
    if (!date) return;
    return convertLocale(date).replace(',', ' - ').slice(0, -3);
};

/**
 * @param date in form (i.e.) '2024-05-02T06:29:59.997192015Z'
 * converts and formats to (i.e.) '30/08/2023'
 */
export const formatDate = (date) => {
    if (!date) return;
    return convertLocale(date).slice(0, 10);
};

/**
 * @param date in form (i.e.) '2024-05-02T06:29:59.997192015Z'
 * converts and formats to (i.e.) '15:02'
 */
export const formatTime = (date) => {
    if (!date) return;
    return convertLocale(date).slice(-8, -3);
};

/**
 * @param date in form (i.e.) '2024-06-30T07:15:41.293Z'
 * returns '07:15'
 */
export const getTimeFromDate = (date) => date.slice(11, 16);

/**
 * @param time in form (i.e.) '803' or '08:03' or '1410' or '14:00'
 * converts and formats to local timezone
 */
export const formatJustTime = (time) => {
    if (!time) return;
    const date = new Date().toISOString().split('T')[0].slice(0, 11);
    let dateTime;
    if (time.length === 5 && time.includes(':')) {
        dateTime = `${date}T${time}:00.000+00:00`;
    }
    if (time.length === 3 || time.length === 4) {
        let newTime = time.length === 3 ? time.padStart(4, '0') : time;
        newTime = `${newTime.slice(0, 2)}:${newTime.slice(2, 4)}`;
        dateTime = `${date}T${newTime}:00.000+00:00`;
    }
    return convertLocale(dateTime).slice(-8, -3);
};

/**
 *
 * @param numOfDays - the number of days between the date entered and the required start date.
 * @param endDate - in the form '2024-05-21' (from the dateSelect date picker component).
 * @returns {Date} - date a number of days before the end date supplied - in same form as end date supplied
 */
export const calcStartDate = (numOfDays, endDate) => {
    const result = new Date(endDate);
    return formatDateToHyphens(formatDate(result.setDate(result.getDate() - numOfDays)));
};

/**
 * where input is in the format: '01/11/2024 - 09/11/2024'
 * @param dateRange
 * return a start and end date in the format: {start: '2024-11-01', end: '2024-11-09'}
 */
export const formatDateRange = (dateRange) => {
    if (!dateRange?.length) return;
    return {
        startDate: formatDateToHyphens(dateRange.slice(0, 10)),
        endDate: formatDateToHyphens(dateRange.slice(13))
    };
};

/**
 * get the number of seconds for the specified number of days
 * @param numOfDays - the number of days
 * @returns {number} - number of seconds in the number of days supplied
 */
export const daysToSeconds = (numOfDays) => numOfDays * SECONDS_IN_ONE_DAY;

/**
 * get the number of days for the specified number of seconds
 * @param numOfSeconds - the number of seconds
 * @returns {number} - number of days in the number of seconds supplied
 */
export const secondsToDays = (numOfSeconds) => numOfSeconds / SECONDS_IN_ONE_DAY;

/**
 * takes a dateTime string ('0010-10-11T00:35:10.500z') and returns string of...
 * 10 years, 10 months, 11 days, 35 minutes and 10.5 seconds
 * @param dateString - and datetime as string in the format of yyyy-MM-dd'T'HH:mm:ss.SSSz
 * @returns {string} - string of total time
 */
export const getTotalTimeToSignificantValue = (dateString) => {
    // Split the date part and time part
    const [datePart, timePart] = dateString.split('T');
    const [year, month, day] = datePart.split('-').map(Number);

    let [hour = 0, minute = 0, second = 0] = timePart
        ? timePart.split(':').map(parseFloat)
        : [0, 0, 0];

    let result = [];

    if (year > 0) result.push(`${year} ${year === 1 ? 'year' : 'years'}`);
    if (month > 0) result.push(`${month} ${month === 1 ? 'month' : 'months'}`);
    if (day > 0) result.push(`${day} ${day === 1 ? 'day' : 'days'}`);

    if (hour > 0) result.push(`${hour} ${hour === 1 ? 'hour' : 'hours'}`);
    if (minute > 0) result.push(`${minute} ${minute === 1 ? 'minute' : 'minutes'}`);

    if (second > 0) {
        const secondsString = second.toFixed(2).endsWith('.00')
            ? `${Math.floor(second)} ${Math.floor(second) === 1 ? 'second' : 'seconds'}`
            : `${second} seconds`;
        result.push(secondsString);
    }

    if (result.length > 1) {
        const last = result.pop();
        return result.join(', ') + ' and ' + last;
    }

    return result.join(', ') || '0 seconds';
};

/**
 * takes a dateTime string ('0010-10-11T00:35:10.500z') and returns string of...
 * 10 years, 10 months, 11 days, 35 minutes and 10.5 seconds
 * @param baseDate - and datetime
 * @param durationString - a string in the format of yyyy-MM-dd'T'HH:mm:ss.SSSz
 * @returns {Date} - a date in the past of the durationString ago
 */
export const getCutoffDate = (baseDate, durationString) => {
    const durationRegex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})Z?/;
    const match = durationString.match(durationRegex);

    if (!match) {
        throw new Error('Invalid duration format. Use yyyy-MM-ddTHH:mm:ss.sssZ format.');
    }

    // Extract components from the duration string
    const years = parseInt(match[1], 10);
    const months = parseInt(match[2], 10);
    const days = parseInt(match[3], 10);
    const hours = parseInt(match[4], 10);
    const minutes = parseInt(match[5], 10);
    const seconds = parseInt(match[6], 10);
    const milliseconds = parseInt(match[7], 10);

    // Create a new Date object from the base date
    const resultDate = new Date(baseDate);

    // Subtract each component from the date
    resultDate.setFullYear(resultDate.getFullYear() - years);
    resultDate.setMonth(resultDate.getMonth() - months);
    resultDate.setDate(resultDate.getDate() - days);
    resultDate.setHours(resultDate.getHours() - hours);
    resultDate.setMinutes(resultDate.getMinutes() - minutes);
    resultDate.setSeconds(resultDate.getSeconds() - seconds);
    resultDate.setMilliseconds(resultDate.getMilliseconds() - milliseconds);

    return resultDate;
};

// returns date in format used for calendar day date - ie. February 13, 2025
export const formatDayDate = () => {
    const date = new Date();
    const dateOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    return date.toLocaleDateString('en-US', dateOptions);
};

// returns date in format used for schedulingAssistant day date - ie. Tuesday 04 Feb
export const otherFormatDayDate = () => {
    const date = new Date();
    const dateOptions = {
        weekday: 'long',
        month: 'short',
        day: '2-digit'
    };
    return date.toLocaleDateString('en-GB', dateOptions);
};
