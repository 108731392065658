import React from 'react';
import PropTypes from 'prop-types';

import { hasRole } from '../../../utils/userRoles';
import CancelButton from '../../formElements/CancelButton';
import TextAreaField from '../../formElements/TextAreaField';

import local from './styles/appointments.module.css';

const NotesCurrent = ({
    onClose,
    roles,
    acceptedRoles,
    notesNext,
    notesCurrent,
    onChangeNotesCurrent,
    onChangeNotesNext
}) => {
    const NOTES_CHARACTER_LIMIT = 4000;
    const NEXT_NOTES_CHARACTER_LIMIT = 1000;
    const resetFields = () => {
        onChangeNotesCurrent('');
        onChangeNotesNext('');
    };

    const onCancel = () => {
        resetFields();
        onClose('notes');
    };
    return (
        <>
            <div className={local.componentSection}>
                <TextAreaField
                    id="notesCurrent"
                    label="Notes from this Appointment"
                    maxLength={NOTES_CHARACTER_LIMIT}
                    value={notesCurrent || ''}
                    count={`${notesCurrent.length}/${NOTES_CHARACTER_LIMIT}`}
                    placeholder="Enter notes for this appointment"
                    disabled={!hasRole(acceptedRoles, roles)}
                    onChange={(e) => {
                        onChangeNotesCurrent(e.target.value);
                    }}
                />
                <TextAreaField
                    id="notesNext"
                    label="Notes for next Appointment"
                    maxLength={NEXT_NOTES_CHARACTER_LIMIT}
                    value={notesNext || ''}
                    count={`${notesNext.length}/${NEXT_NOTES_CHARACTER_LIMIT}`}
                    placeholder="Enter notes for next appointment"
                    disabled={!hasRole(acceptedRoles, roles)}
                    onChange={(e) => {
                        onChangeNotesNext(e.target.value);
                    }}
                />
            </div>
            <CancelButton onClick={onCancel} />
        </>
    );
};

export default NotesCurrent;

NotesCurrent.propTypes = {
    notesNext: PropTypes.string,
    notesCurrent: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    acceptedRoles: PropTypes.arrayOf(PropTypes.string),
    onChangeNotesCurrent: PropTypes.func,
    onChangeNotesNext: PropTypes.func,
    onClose: PropTypes.func
};
