import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import OnDeleteIcon from '../../../icons/OnDeleteIcon';
import {
    clearAppointmentAuditData,
    clearNewAppointment,
    deleteAppointment,
    searchAppointmentAuditData,
    setCurrentAppointment,
    setOpenAppointmentSchedulerPanel,
    updateAppointment
} from '../../../store/calendarService';
import {
    loadAppointmentTypeDetailsByContractIds,
    loadLocationsByServiceId,
    loadServiceDetailsByService,
    setCurrentServiceId
} from '../../../store/directusService';
import {
    loadParticipantEmployability,
    setCurrentlySelectedParticipant
} from '../../../store/participantService';
import { isTimePast, reverseFormatDate } from '../../../utils/dateFunctions';
import { createHash } from '../../../utils/stringUtils';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import AuditAppointmentModal from '../../auditing/auditAppointments/AuditAppointmentModal';
import Button from '../../formElements/Button';
import DateSelect from '../../formElements/DateSelect';
import StaticField from '../../formElements/StaticField';
import Switch from '../../formElements/Switch';
import Panel from '../../ui/panel/Panel';
import AdviserSelect from '../components/AdviserSelect';
import AppointmentTypeSelect from '../components/AppointmentTypeSelect';
import LocationSelect from '../components/LocationSelect';
import MandatoryActivitiesSwitch from '../components/MandatoryActivitiesSwitch';
import StartAndEndTimesSelect from '../components/StartAndEndTimesSelect';
import StartAppointmentRow from '../components/StartAppointmentRow';
import StaticParticipant from '../components/StaticParticipant';
import { unresolvedStatuses } from '../utils/calendarUtils';
import { validate } from '../validation/validateAppointmentEdit';

import sidePanel from '../styles/schedulePanel.module.css';

const UserAppointmentEdit = ({
    usersCurrentAppointment,
    currentCalendarOwner,
    currentLocation,
    currentParticipantEntry
}) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: '',
        typeId: '',
        userId: '',
        locationId: '',
        startTime: '08:00',
        endTime: '09:00',
        date: String(new Date().toISOString().split('T')[0]),
        serviceId: '',
        participantId: '',
        mandatory: false,
        type: '',
        service: '',
        location: '',
        participant: ''
    };

    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const editRestrictedRoles = [QUALITY, SUPERUSER];
    const deleteRestrictedRoles = [SUPERUSER];
    const [initialStateOnEntry, setInitialStateOnEntry] = useState({});
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isEditable, setIsEditable] = useState(false);
    const [isDisabledStartAppointmentButton, setIsDisabledStartAppointmentButton] = useState(false);
    const [isDisabledSaveButton, setIsDisabledSaveButton] = useState(false);
    const [isAppointmentCompleted, setIsAppointmentCompleted] = useState(false);
    const [isAuditable, setIsAuditable] = useState(false);
    const [showAuditModal, setShowAuditModal] = useState(false);
    const [auditLabel, setAuditLabel] = useState('');
    const [modalHeadingLabel, setModalHeadingLabel] = useState('');
    const [hash, setHash] = useState('01');

    // STORE STATE
    const users = useSelector((state) => state.entities.userService.usersBySearch);
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const serviceDetailsById = useSelector(
        (state) => state.entities.directusService.serviceDetailsById
    );
    const appointmentTypeDetailsForContracts = useSelector(
        (state) => state.entities.directusService.appointmentTypeDetailsForContracts
    );
    const locationsByServiceId = useSelector(
        (state) => state.entities.directusService.locationsByServiceId
    );
    const updatedCurrentAppointment = useSelector(
        (state) => state.entities.calendarService.usersCurrentAppointment
    );
    const openAppointmentSchedulerPanel = useSelector(
        (state) => state.entities.calendarService.openAppointmentSchedulerPanel
    );
    const errorMessage = useSelector((state) => state.entities.formsState.errorMessage);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS
    useEffect(() => {
        if (!currentCalendarOwner || Object.keys(currentCalendarOwner).length < 1) return;
        dispatch(loadAppointmentTypeDetailsByContractIds(currentCalendarOwner?.contractIds));
        if ('location' in usersCurrentAppointment) {
            /* eslint-disable no-unused-vars */
            const { location, ...rest } = usersCurrentAppointment;
            setInitialStateOnEntry(rest);
        }
        if (usersCurrentAppointment?.serviceId) {
            const { serviceId } = usersCurrentAppointment;

            if (!(serviceId in serviceDetailsById))
                dispatch(loadServiceDetailsByService([serviceId]));

            if (!(serviceId in locationsByServiceId)) {
                dispatch(setCurrentServiceId(serviceId));
                dispatch(loadLocationsByServiceId([serviceId]));
            }
        }
        if (currentParticipantEntry && Object.keys(currentParticipantEntry).length > 1) {
            dispatch(loadParticipantEmployability(currentParticipantEntry.id));
            dispatch(setCurrentlySelectedParticipant(currentParticipantEntry));
        }
    }, []);

    useEffect(() => {
        if (newEntry.id || !(usersCurrentAppointment?.serviceId in serviceDetailsById)) return;
        const currentParticipantName = currentParticipantEntry
            ? `${currentParticipantEntry?.firstName} ${currentParticipantEntry?.lastName} ${currentParticipantEntry?.ptCode}`
            : '';

        setNewEntry({
            ...usersCurrentAppointment,
            startTime: usersCurrentAppointment.startTime?.slice(0, 5),
            endTime: usersCurrentAppointment.endTime?.slice(0, 5),
            date: usersCurrentAppointment.date?.split('T')[0] || '',
            mandatory: usersCurrentAppointment?.mandatory || false,
            service: serviceDetailsById[usersCurrentAppointment.serviceId]?.name,
            participant: currentParticipantName,
            location: currentLocation,
            type: usersCurrentAppointment.type
        });
        const status = usersCurrentAppointment?.attendances.find(
            (el) => el.participantId === currentParticipantEntry?.id
        )?.status;
        setIsAppointmentCompleted(!unresolvedStatuses.includes(status));

        const canEdit = hasRole(acceptedRoles, roles);
        setIsEditable(canEdit);
        setIsDisabledStartAppointmentButton(!canEdit);
    }, [serviceDetailsById]);

    useEffect(() => {
        setIsDisabledStartAppointmentButton(false);
    }, [updatedCurrentAppointment]);

    useEffect(() => {
        setIsDisabledSaveButton(Object.values(errors).some((el) => el.error));
    }, [errors]);

    useEffect(() => {
        if (successMessage === `Appointment ${newEntry.type} has been updated (${hash})`) {
            setIsDisabledSaveButton(false);
            !newEntry.participantId && onPanelClose();
        }
        if (successMessage.includes(`Appointment ${newEntry.type} has been deleted`)) {
            setIsDisabledSaveButton(false);
            onPanelClose();
        }
    }, [successMessage]);

    useEffect(() => {
        if (errorMessage) {
            setIsDisabledSaveButton(false);
        }
    }, [errorMessage]);

    // HELPER FNS
    const clearError = (key) => {
        if (key in errors && errors[key].error)
            setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    const addUserEmail = () => users.find((el) => el.id === newEntry.userId)?.emailAddress || '';

    // EVENT HANDLERS
    const onPanelClose = () => {
        setNewEntry(initialState);
        dispatch(setCurrentAppointment({}));
        dispatch(setOpenAppointmentSchedulerPanel(false));
        dispatch(clearNewAppointment());
    };

    const onAuditModalClose = (e, reason) => {
        e.stopPropagation();
        if (reason === 'backdropClick') return;
        setShowAuditModal(false);
        setAuditLabel('');
        setModalHeadingLabel('');
        dispatch(clearAppointmentAuditData());
    };

    const onUpdate = (key, value) => {
        clearError(key);
        setIsDisabledStartAppointmentButton(true);
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onUpdateAppointmentType = (key, value, type) => {
        onUpdate(key, value);
        setNewEntry((prev) => ({ ...prev, type }));
    };

    const onUpdateTime = (key, value) => {
        clearError(key === 'endTime' ? 'startTime' : 'endTime');
        onUpdate(key, value);
    };

    const onDelete = () => {
        dispatch(deleteAppointment(newEntry.userId, newEntry.type, newEntry.id));
    };

    const onSearchAuditItems = (label, modalHeadingLabel) => {
        if (newEntry.id) dispatch(searchAppointmentAuditData(newEntry.id, label));
        setShowAuditModal(true);
        setAuditLabel(label);
        setModalHeadingLabel(modalHeadingLabel ?? label);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setIsDisabledSaveButton(true);
        const {
            /* eslint-disable no-unused-vars */
            type,
            service,
            location,
            participant,
            ...rest
        } = newEntry;
        const payload = { ...rest };
        const newErrors = validate(payload, initialStateOnEntry);
        if (Object.values(newErrors).some((el) => el.error)) {
            setErrors(newErrors);
            return;
        }
        const hash = createHash();
        setHash(hash);
        dispatch(updateAppointment(usersCurrentAppointment.userId, newEntry.type, payload, hash));
        setInitialStateOnEntry(payload);
    };

    // RENDER
    return (
        <>
            <Panel width="500px" open={openAppointmentSchedulerPanel} onToggle={onPanelClose}>
                <div className={sidePanel.panelActions}>
                    <Switch
                        id={'auditDisplayFields'}
                        label="Show Audit Fields"
                        onOff={true}
                        customClass="auditSwitch"
                        checked={isAuditable}
                        onChange={(e) => setIsAuditable(e.target.checked)}
                    />
                    <OnDeleteIcon
                        onDelete={onDelete}
                        roles={roles}
                        acceptedRoles={deleteRestrictedRoles}
                        active={!!newEntry.id}
                    />
                </div>

                <div className={sidePanel.formWrapper}>
                    <form onSubmit={onSubmit} data-testid="user_appointment_edit_form">
                        <StaticField content={newEntry.service} customClass="underlinedContent" />

                        {isAppointmentCompleted && !hasRole(editRestrictedRoles, roles) ? (
                            <>
                                <StaticField label="Adviser" content={addUserEmail()} />
                                <StaticField label="Appointment Type" content={newEntry.type} />
                                <StaticField
                                    label="Location"
                                    content={newEntry.location}
                                    isAuditable={isAuditable}
                                    onSearchAuditItems={onSearchAuditItems}
                                />
                                <StaticField
                                    label="Start Time"
                                    content={newEntry.startTime}
                                    isAuditable={isAuditable}
                                    onSearchAuditItems={() =>
                                        onSearchAuditItems('Start Date', 'Start Time')
                                    }
                                />
                                <StaticField
                                    label="End Time"
                                    content={newEntry.endTime}
                                    isAuditable={isAuditable}
                                    onSearchAuditItems={() =>
                                        onSearchAuditItems('End Date', 'End Time')
                                    }
                                />
                                <StaticField
                                    label="Date"
                                    content={reverseFormatDate(newEntry.date)}
                                    isAuditable={isAuditable}
                                    onSearchAuditItems={() =>
                                        onSearchAuditItems('Start Date', 'Date')
                                    }
                                />
                            </>
                        ) : (
                            <>
                                <AdviserSelect
                                    userId={newEntry.userId || ''}
                                    serviceId={newEntry.serviceId}
                                    initialAdviser={currentCalendarOwner}
                                    onAdviserChange={(id) => onUpdate('userId', id)}
                                    disabled={!newEntry.service || !isEditable}
                                    mandatory={true}
                                    errors={errors}
                                />

                                {isTimePast(`${newEntry.date}T${newEntry.startTime}`) &&
                                !hasRole(editRestrictedRoles, roles) ? (
                                    <StaticField label="Appointment Type" content={newEntry.type} />
                                ) : (
                                    <AppointmentTypeSelect
                                        appointmentTypes={appointmentTypeDetailsForContracts}
                                        appointmentTypeId={newEntry.typeId}
                                        onAppointmentTypeChange={onUpdateAppointmentType}
                                        errors={errors}
                                    />
                                )}

                                <LocationSelect
                                    locationId={newEntry.locationId}
                                    serviceId={newEntry.serviceId}
                                    onLocationChange={onUpdate}
                                    errors={errors}
                                    isAuditable={isAuditable}
                                    onSearchAuditItems={onSearchAuditItems}
                                />

                                <StartAndEndTimesSelect
                                    startTime={newEntry.startTime}
                                    endTime={newEntry.endTime}
                                    onTimeChange={onUpdateTime}
                                    errors={errors}
                                    isAuditable={isAuditable}
                                    onSearchAuditItems={onSearchAuditItems}
                                />
                                {!hasRole(editRestrictedRoles, roles) ? (
                                    <StaticField
                                        label="Date"
                                        content={newEntry.date}
                                        isAuditable={isAuditable}
                                        onSearchAuditItems={() =>
                                            onSearchAuditItems('Start Date', 'Date')
                                        }
                                    />
                                ) : (
                                    <DateSelect
                                        mandatory={true}
                                        value={newEntry.date}
                                        error={errors.date}
                                        onDateChange={(date) => onUpdate('date', date)}
                                        isAuditable={isAuditable}
                                        onSearchAuditItems={() =>
                                            onSearchAuditItems('Start Date', 'Date')
                                        }
                                    />
                                )}
                            </>
                        )}
                        <StaticParticipant
                            participant={currentParticipantEntry}
                            onNav={onPanelClose}
                        />
                        <MandatoryActivitiesSwitch
                            isMandatory={newEntry.mandatory}
                            onSwitch={onUpdate}
                            disabled={true}
                        />

                        <div className={sidePanel.updateButton}>
                            <Button
                                id="appointmentEditButton"
                                content="Update"
                                disabled={
                                    (!hasRole(editRestrictedRoles, roles) &&
                                        isAppointmentCompleted) ||
                                    isDisabledSaveButton
                                }
                                error={errors.button}
                                clearError={() => clearError('button')}
                                customErrorClass="rightSideButtonError"
                            />
                        </div>

                        {newEntry.id && currentParticipantEntry && (
                            <StartAppointmentRow
                                onPanelClose={onPanelClose}
                                disabled={isDisabledStartAppointmentButton}
                                appointment={newEntry}
                                participant={currentParticipantEntry}
                            />
                        )}
                    </form>
                </div>
            </Panel>
            {showAuditModal && (
                <div id="audit">
                    <AuditAppointmentModal
                        isOpen={showAuditModal}
                        label={auditLabel}
                        modalHeadingLabel={modalHeadingLabel}
                        onClose={onAuditModalClose}
                    />
                </div>
            )}
        </>
    );
};

UserAppointmentEdit.propTypes = {
    usersCurrentAppointment: PropTypes.object,
    currentCalendarOwner: PropTypes.object,
    currentLocation: PropTypes.string,
    currentParticipantEntry: PropTypes.object
};

export default UserAppointmentEdit;
