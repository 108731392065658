import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    createQuestionnaireContract,
    loadFormContractByContract,
    loadForms,
    loadQuestionnaireContractByContract,
    loadQuestionnaires,
    updateFormContract
} from '../../../store/participantService';
import { compareUnorderedArrays } from '../../../utils/arrayUtils';
import { NO_UPDATE_ERROR } from '../../../utils/formValidation/commonErrorConstants';
import { clearKeys } from '../../../utils/objectUtils';
import { hasRole, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import MultiSelect from '../../formElements/MultiSelect';
import LoadingSpinner from '../../ui/LoadingSpinner';

import form from '../../../commonStyles/formStyles.module.css';

const initialUpdateButtonErrorState = { error: false, message: '' };

const ParticipantQuestionnairesAndFormsAdmin = ({ contractId }) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [preSelectedQuestionnaireIds, setPreSelectedQuestionnaireIds] = useState([]);
    const [selectedQuestionnaireIds, setSelectedQuestionnaireIds] = useState([]);
    const [preSelectedFormIds, setPreSelectedFormIds] = useState([]);
    const [selectedFormIds, setSelectedFormIds] = useState([]);

    const [arrayQuestionnaires, setArrayQuestionnaires] = useState([]);
    const [arrayForms, setArrayForms] = useState([]);

    const [keys, setKeys] = useState({ questionnaires: '0', forms: '1' });
    const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false);
    const [updateButtonError, setUpdateButtonError] = useState(initialUpdateButtonErrorState);

    // STORE STATE
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const questionnaireContracts = useSelector(
        (state) => state.entities.participantService.questionnaireContracts
    );
    const questionnaires = useSelector((state) => state.entities.participantService.questionnaires);
    const formContracts = useSelector((state) => state.entities.participantService.formContracts);
    const forms = useSelector((state) => state.entities.participantService.forms);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS
    useEffect(() => {
        if (contractId) {
            questionnaires?.length < 1 && dispatch(loadQuestionnaires());
            forms?.length < 1 && dispatch(loadForms());
            dispatch(loadQuestionnaireContractByContract(contractId));
            dispatch(loadFormContractByContract(contractId));
        }
    }, [contractId]);

    useEffect(() => {
        if (questionnaires?.length < 1) return;
        setArrayQuestionnaires(questionnaires.map((el) => ({ ...el, name: el.title })));
    }, [questionnaires]);

    useEffect(() => {
        if (!(questionnaireContracts.questionnaires?.length > 0)) return;
        const preSelectedQuestionnaireIds = questionnaireContracts.questionnaires.map(
            (el) => el.id
        );
        setPreSelectedQuestionnaireIds(preSelectedQuestionnaireIds);
        setSelectedQuestionnaireIds(preSelectedQuestionnaireIds);
    }, [questionnaireContracts]);

    useEffect(() => {
        if (forms?.length < 1) return;
        setArrayForms(forms.filter((el) => !el.inactive));
    }, [forms]);

    useEffect(() => {
        if (!(formContracts.length > 0)) return;
        const preSelectedFormIds = formContracts
            .filter((el) => el.contractIds.some((entry) => entry.includes(contractId)))
            .map((el) => el.id);
        setPreSelectedFormIds(preSelectedFormIds);
        setSelectedFormIds(preSelectedFormIds);
    }, [formContracts]);

    useEffect(() => {
        const questionnairesUpdated =
            successMessage === 'Questionnaire Contracts have been updated';
        const formsUpdated = successMessage === 'Form Contracts have been updated';
        if (questionnairesUpdated || formsUpdated) {
            setKeys(clearKeys(keys));
            setIsUpdateButtonDisabled(false);
        }
        if (questionnairesUpdated) dispatch(loadQuestionnaireContractByContract(contractId));
    }, [successMessage]);

    // HELPER FUNCTIONS
    const loadingError = () => {
        if (!contractId) return 'No Contract Id';
        if (questionnaires?.length < 1) return 'No questionnaires';
    };

    // EVENT HANDLERS
    const onSubmit = (e) => {
        e.preventDefault();
        const changesToQuestionnaires = !compareUnorderedArrays(
            preSelectedQuestionnaireIds,
            selectedQuestionnaireIds
        );
        const changesToForms = !compareUnorderedArrays(preSelectedFormIds, selectedFormIds);
        if (!changesToQuestionnaires && !changesToForms) {
            setUpdateButtonError({ error: true, message: NO_UPDATE_ERROR });
            return;
        }

        setIsUpdateButtonDisabled(true);
        changesToQuestionnaires &&
            dispatch(
                createQuestionnaireContract({
                    questionnaireIds: selectedQuestionnaireIds,
                    contractId
                })
            );
        changesToForms &&
            dispatch(
                updateFormContract({
                    formIds: selectedFormIds,
                    contractId
                })
            );
    };

    // AWAITING CONTENT
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} />;

    // RENDER
    return (
        <form className={form.formWrapper} onSubmit={onSubmit}>
            <div className={form.formSection}>
                <div className={form.formColumn}>
                    <MultiSelect
                        id="questionnaires"
                        key={keys.questionnaires}
                        label="Questionnaire Types"
                        placeholder="Search questionnaire types..."
                        disabled={!hasRole([SUPERUSER], roles)}
                        menuItems={arrayQuestionnaires || []}
                        preSelectedIds={selectedQuestionnaireIds}
                        preSelects={
                            arrayQuestionnaires.filter((el) =>
                                selectedQuestionnaireIds.includes(el.id)
                            ) || []
                        }
                        onChange={(chosenIds) => setSelectedQuestionnaireIds(chosenIds)}
                    />
                    <MultiSelect
                        id="forms"
                        key={keys.forms}
                        label="Forms"
                        placeholder="Search forms..."
                        disabled={!hasRole([SUPERUSER], roles)}
                        menuItems={arrayForms || []}
                        preSelectedIds={selectedFormIds}
                        preSelects={
                            arrayForms.filter((el) => selectedFormIds.includes(el.id)) || []
                        }
                        onChange={(chosenIds) => setSelectedFormIds(chosenIds)}
                    />
                    <Button
                        id="questionairesAndFormsAdminButton"
                        content="UPDATE QUESTIONNAIRES AND FORMS"
                        disabled={isUpdateButtonDisabled || updateButtonError.error}
                        error={updateButtonError}
                        clearError={() => setUpdateButtonError(initialUpdateButtonErrorState)}
                    />
                </div>
            </div>
        </form>
    );
};

ParticipantQuestionnairesAndFormsAdmin.propTypes = {
    contractId: PropTypes.string
};

export default ParticipantQuestionnairesAndFormsAdmin;
