import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { createPool } from '../../../../store/recruitmentService';
import { clearKeys } from '../../../../utils/objectUtils';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import MultiSelect from '../../../formElements/MultiSelect';
import SingleSelect from '../../../formElements/SingleSelect';
import TextAreaField from '../../../formElements/TextAreaField';
import FormActions from '../../../ui/formActions/FormActions';

import { initialErrorState, validate } from './validatePoolEditor';

import form from '../../../../commonStyles/formStyles.module.css';

const CHARACTER_LIMIT = 750;

const PoolEditor = ({
    open,
    onPoolEditor,
    jobSectorDetails,
    selectedPoolOwner,
    poolOwners,
    teamDetails
}) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const initialState = {
        id: null,
        jobSectorId: '',
        ownerId: '',
        teamIds: [],
        requirements: ''
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [keys, setKeys] = useState({ ownerId: '0', jobSectorId: '1', teamIds: '2' });
    const [errors, setErrors] = useState(initialErrorState);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [disableFields, setDisableFields] = useState(false);

    // STORE STATE
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);

    // USE EFFECTS
    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        setIsDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    useEffect(() => {
        if (successMessage === `Pool has been added`) {
            setIsDisabled(false);
            onCreated();
        }
    }, [successMessage]);

    // HELPER FNS
    const getPreSelect = (arr, id) => arr.find((el) => el.id === id) || {};

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    const clearData = () => {
        setNewEntry(initialState);
        setKeys(clearKeys(keys));
    };

    // EVENT HANDLERS
    const onUpdate = (key, value) => {
        errors[key] && clearError(key);
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onModalClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setIsOpen(false);
        }
    };

    const onCancel = () => {
        onPoolEditor(false);
        clearData();
    };

    const onCreated = () => {
        onPoolEditor(false);
        clearData();
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const newErrors = validate(newEntry);

        if (Object.keys(newErrors)?.length > 0) {
            setErrors(newErrors);
            return;
        }

        setIsDisabled(true);
        setDisableFields(true);

        const payload = newEntry;
        payload.ownerId = selectedPoolOwner;
        dispatch(createPool(payload));
    };

    // RENDER
    return (
        <div>
            <Dialog
                className="muiDialogWrapperMedium"
                open={isOpen}
                onClose={onModalClose}
                aria-labelledby="form-dialog-title">
                <form onSubmit={onSubmit} data-testid="form_start" className={form.form}>
                    <DialogTitle id="form-dialog-title">Add Pool</DialogTitle>
                    <DialogContent data-testid="dialog-content">
                        <SingleSelect
                            id={'poolOwnerId'}
                            key={keys.ownerId}
                            label={'Pool Owner'}
                            placeholder="Select Pool Owner..."
                            disabled={true}
                            menuItems={poolOwners}
                            selectedId={selectedPoolOwner}
                            selected={getPreSelect(poolOwners, selectedPoolOwner)}
                        />
                        <SingleSelect
                            id={'jobSectorId'}
                            key={keys.jobSectorId}
                            label={'Job Sector'}
                            placeholder="Select Job Sector..."
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            menuItems={jobSectorDetails}
                            selectedId={newEntry.jobSectorId}
                            selected={getPreSelect(jobSectorDetails, newEntry.jobSectorId)}
                            error={errors.jobSectorId}
                            onChange={(chosenId) => onUpdate('jobSectorId', chosenId)}
                        />
                        <MultiSelect
                            id="teamIds"
                            key={keys.teamIds}
                            label="Teams"
                            placeholder="Select teams"
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            menuItems={teamDetails || []}
                            preSelectedIds={newEntry.teamIds}
                            preSelects={teamDetails.filter((el) =>
                                newEntry.teamIds.includes(el.id)
                            )}
                            error={errors.teamIds}
                            onChange={(chosenIds) => onUpdate('teamIds', chosenIds)}
                        />
                        <TextAreaField
                            id={'requirements'}
                            label={'Pool Requirements'}
                            placeholder={'Enter pool requirements'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            value={newEntry.requirements || ''}
                            count={`${newEntry.requirements.length}/${CHARACTER_LIMIT}`}
                            maxLength={CHARACTER_LIMIT}
                            error={errors.requirements}
                            onChange={(e) => onUpdate('requirements', e.target.value)}
                        />
                    </DialogContent>

                    <DialogActions>
                        <FormActions
                            id="create"
                            btnText="Update"
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || isDisabled}
                            onClose={onSubmit}
                            onCancel={onCancel}
                            customClass="noTopBorder"
                        />
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

PoolEditor.propTypes = {
    onPoolEditor: PropTypes.func,
    open: PropTypes.bool.isRequired,
    jobSectorDetails: PropTypes.array.isRequired,
    selectedPoolOwner: PropTypes.string.isRequired,
    poolOwners: PropTypes.array.isRequired,
    teamDetails: PropTypes.array.isRequired
};

export default PoolEditor;
