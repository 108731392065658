import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../../utils/userRoles';
import DynamicSearch from '../../../../search/DynamicSearch';
import LoadingSpinner from '../../../../ui/LoadingSpinner';

import CurrentVacanciesTable from './CurrentVacanciesTable';

import classes from '../../submissionStyles/vacancy.module.css';

const CurrentVacanciesTableManagement = () => {
    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [rows, setRows] = useState([]);
    const [currentParticipantVacancies, setCurrentParticipantVacancies] = useState([]);
    const [currentVacancies, setCurrentVacancies] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    // STORE STATE
    const vacancyStageDetails = useSelector(
        (state) => state.entities.directusService.vacancyStageDetails
    );
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const businesses = useSelector((state) => state.entities.recruitmentService.businesses);
    const vacanciesForOwners = useSelector(
        (state) => state.entities.recruitmentService.vacanciesForOwners
    );
    const vacancySubmissions = useSelector(
        (state) => state.entities.recruitmentService.vacancySubmissions
    );
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);
    const usersForVacancies = useSelector((state) => state.entities.userService.usersForVacancies);

    // USE EFFECTS
    useEffect(() => {
        if (vacanciesForOwners?.length < 1 || !usersForVacancies || usersForVacancies?.length < 1) {
            setCurrentParticipantVacancies([]);
            setRows([]);
            setCurrentVacancies([]);
            return;
        }

        const currentOwnerVacancies = vacanciesForOwners.map((el) => {
            const user = usersForVacancies.find((entry) => entry.id === el?.ownerId);
            return {
                ...el,
                businessName:
                    businesses?.find((entry) => entry.id === el.businessRecordId)?.name || ' - ',
                adviser: user ? `${user.firstName} ${user.lastName}` : ' - '
            };
        });

        setRows(currentOwnerVacancies);
        setCurrentParticipantVacancies(currentOwnerVacancies);
    }, [vacanciesForOwners, usersForVacancies]);

    useEffect(() => {
        if (currentParticipantVacancies.length < 1 || vacancySubmissions.length < 1) {
            return;
        }
        const rejectedStatusId =
            vacancyStageDetails.find((el) => el.name.toLowerCase() === 'rejected')?.id || '';
        const jobOfferedStatusId =
            vacancyStageDetails.find((el) => el.name.toLowerCase() === 'job offered')?.id || '';

        const notRejectedOrJobOfferedVacancy = currentParticipantVacancies
            .map((el) => {
                const foundVacancySubmission = vacancySubmissions.find(
                    (entry) =>
                        entry.participantId === currentParticipant.id &&
                        entry.vacancyId === el.id &&
                        entry.statusId !== rejectedStatusId &&
                        entry.statusId !== jobOfferedStatusId
                );
                if (foundVacancySubmission) {
                    return {
                        ...el,
                        vacancySubmissionStatusId: foundVacancySubmission.statusId
                    };
                }
            })
            .filter((el) => el);

        if (notRejectedOrJobOfferedVacancy.length > 0) {
            setRows(notRejectedOrJobOfferedVacancy);
            setCurrentVacancies(notRejectedOrJobOfferedVacancy);
        }
    }, [currentParticipantVacancies, vacancySubmissions]);

    useEffect(() => {
        const filteredRows = !searchTerm
            ? rows
            : rows.filter((el) => el.title.toLowerCase().includes(searchTerm.toLowerCase()));
        setCurrentVacancies(filteredRows);
    }, [searchTerm]);

    // EVENT HANDLERS
    const onSearch = (searchRes) => setSearchTerm(searchRes);

    // RENDER
    return (
        <>
            <div className={classes.vacanciesToolBar}>
                <DynamicSearch
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                    search={onSearch}
                    searchTerm={searchTerm}
                    placeholder="Enter Search Term"
                />
            </div>

            {currentVacancies.length < 1 ? (
                <LoadingSpinner content="No current vacancies found" />
            ) : (
                <CurrentVacanciesTable rows={currentVacancies} searchTerm={searchTerm} />
            )}
        </>
    );
};

export default CurrentVacanciesTableManagement;
