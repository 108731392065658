import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../../../api/pagination';
import { loadBusinessSectorDetails } from '../../../../store/directusService';
import { createBusinessRecord, loadBusinessRecords } from '../../../../store/recruitmentService';
import { searchUsersByLoggedInUserServiceIds } from '../../../../store/userService';
import {
    ADDRESS_LINE_1_MAX_LENGTH,
    ADDRESS_LINE_2_MAX_LENGTH,
    ADDRESS_LINE_3_MAX_LENGTH,
    CITY_MAX_LENGTH,
    CREATE
} from '../../../../utils/formValidation/commonConstants';
import { BUSINESS_SECTOR_DETAILS_ERROR } from '../../../../utils/formValidation/loadingErrorMessageConstants';
import { clearKeys } from '../../../../utils/objectUtils';
import { addEmailAddressAsNameToArray } from '../../../../utils/userArrayUtils';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import Button from '../../../formElements/Button';
import SingleSelect from '../../../formElements/SingleSelect';
import TextInputField from '../../../formElements/TextInputField';
import FormActions from '../../../ui/formActions/FormActions';
import LoadingSpinner from '../../../ui/LoadingSpinner';
import BusinessContactCard from '../cards/BusinessContactCard';
import { initialErrorState, validate } from '../shared/validateBusiness';

import CreateBusinessContact from './CreateBusinessContact';

import app from '../../../../app.module.css';
import cards from '../../../../commonStyles/cardContainer.module.css';
import form from '../../../../commonStyles/formStyles.module.css';

const CreateBusiness = () => {
    // HOOKS
    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const initialState = {
        id: null,
        name: '',
        sectorId: '',
        website: '',
        size: '',
        ownerId: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        postcode: '',
        inactive: '',
        contactCards: []
    };
    const acceptedRoles = [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const businessOwnerRoles = [MANAGER, RECRUITMENT_MANAGER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [keys, setKeys] = useState({ ownerId: '0', sectorId: '1' });
    const [businessOwners, setBusinessOwners] = useState([]);
    const [showCreateContactModal, setShowCreateContactModal] = useState(false);
    const [editContactType, setEditContactType] = useState('create');
    const [currentContactCard, setCurrentContactCard] = useState({});
    const [errors, setErrors] = useState(initialErrorState);
    const [isDisabled, setIsDisabled] = useState(false);
    const [disableFields, setDisableFields] = useState(false);

    // STORE STATE
    const errorMessage = useSelector((state) => state.entities.formsState.errorMessage);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const businessSectorDetails = useSelector(
        (state) => state.entities.directusService.businessSectorDetails
    );
    const businesses = useSelector((state) => state.entities.recruitmentService.businesses);
    const businessesMetaData = useSelector(
        (state) => state.entities.recruitmentService.businessesMetaData
    );
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);
    const userMetaData = useSelector(
        (state) => state.entities.userService.usersByLoggedInUserServiceIdsMetaData
    );
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);

    // USE EFFECTS
    useEffect(() => {
        if (businesses?.length < 1) dispatch(loadBusinessRecords(0, DEFAULT_PAGE_LOAD_SIZE));
        if (businessSectorDetails?.length < 1) dispatch(loadBusinessSectorDetails());
        if (loggedInUser.roles.some((el) => businessOwnerRoles.includes(el))) {
            onUpdate('ownerId', loggedInUser.id);
            setBusinessOwnersArray([loggedInUser]);
        }
    }, []);

    useEffect(() => {
        if (!users.length) return;
        let updatedBusinessOwners = users.filter((el) =>
            el.userTypes?.find((entry) => businessOwnerRoles.includes(entry.role))
        );
        if (!updatedBusinessOwners.length && users.length < userMetaData.totalElements) {
            onLoadMoreBusinessOwners();
            return;
        }
        if (newEntry.ownerId && !updatedBusinessOwners.some((el) => el.id === newEntry.ownerId)) {
            // Put selected business owner at the top of dropdown if it's not in the updated business owners array
            const selectedAdvisor = businessOwners.find((el) => el.id === newEntry.ownerId);
            updatedBusinessOwners = [selectedAdvisor, ...updatedBusinessOwners];
        }
        setBusinessOwnersArray(updatedBusinessOwners);
    }, [users]);

    useEffect(() => {
        if (!businessesMetaData.last) {
            dispatch(
                loadBusinessRecords(
                    'number' in businessesMetaData ? businessesMetaData.number + 1 : 0,
                    DEFAULT_PAGE_LOAD_SIZE
                )
            );
        }
    }, [businesses, businessesMetaData]);

    useEffect(() => {
        setIsDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    useEffect(() => {
        if (errorMessage) {
            setDisableFields(false);
            setIsDisabled(false);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (successMessage === `Business record has been added`) onNavigate();
    }, [successMessage]);

    // HELPER FNS
    const setBusinessOwnersArray = (businessOwners) => {
        const updatedBusinessOwners = addEmailAddressAsNameToArray(businessOwners);
        setBusinessOwners(updatedBusinessOwners);
    };

    const getPreSelect = (arr, id) => {
        return arr.find((el) => el.id === id) || {};
    };

    const loadingError = () => {
        if (businessSectorDetails?.length < 1) return BUSINESS_SECTOR_DETAILS_ERROR;
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    const onNavigate = () => {
        clearData();
        navigate('/recruitment_management', {
            state: { accordionPanel: state?.accordionPanel || 'myBusinesses' }
        });
    };

    const clearData = () => {
        setNewEntry(initialState);
        setKeys(clearKeys(keys));
    };

    // EVENT HANDLERS
    const onAddBusinessContact = () => {
        setShowCreateContactModal(true);
        setEditContactType('create');
        setCurrentContactCard({});
    };

    const addBusinessContact = (newCard) => {
        setNewEntry((prev) => ({
            ...prev,
            contactCards: [...newEntry.contactCards, { ...newCard, id: uuid() }]
        }));
        setShowCreateContactModal(false);
    };

    const onEditBusinessContact = (entry) => {
        setShowCreateContactModal(true);
        setEditContactType('edit');
        setCurrentContactCard(entry);
    };

    const editBusinessContact = (entry) => {
        setNewEntry((prev) => ({
            ...prev,
            contactCards: newEntry.contactCards.map((el) => (el.id === entry.id ? entry : el))
        }));
        setShowCreateContactModal(false);
        setCurrentContactCard({});
    };

    const onCancelBusinessContact = () => {
        setShowCreateContactModal(false);
        setCurrentContactCard({});
    };

    const onUpdate = (key, value) => {
        errors[key] && clearError(key);
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onLoadMoreBusinessOwners = () => {
        if ((!users.length || users.length < userMetaData.totalElements) && !userMetaData.last) {
            dispatch(
                searchUsersByLoggedInUserServiceIds(
                    loggedInUser.serviceIds,
                    !users.length ? 0 : userMetaData.number + 1
                )
            );
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const newErrors = validate(newEntry, CREATE, businesses);

        if (Object.keys(newErrors)?.length > 0) {
            setErrors(newErrors);
            return;
        }

        const payload = {
            ...newEntry,
            inactive: false,
            contactCards: newEntry.contactCards.map((el) => ({
                ...el,
                id: null
            }))
        };
        setDisableFields(true);
        setIsDisabled(true);
        dispatch(createBusinessRecord(payload, newEntry.ownerId === loggedInUser.id));
    };

    // AWAITING CONTENT
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} time={3} />;

    // RENDER
    return (
        <div className={form.formWrapper}>
            <form onSubmit={onSubmit} data-testid="form_start" className={form.form}>
                <h1 className={app.mainHeading}>Create Business</h1>
                <h2 className={app.sectionHeading}>Business Details</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'name'}
                            label={'Business Name'}
                            placeholder={'Enter business name'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            value={newEntry.name || ''}
                            error={errors.name}
                            onChange={(e) => onUpdate('name', e.target.value)}
                        />
                        <SingleSelect
                            id={'sectorId'}
                            key={keys.sectorId}
                            label={'Business Sector'}
                            placeholder="Select Business Sector..."
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            menuItems={businessSectorDetails}
                            selectedId={newEntry.sectorId}
                            selected={getPreSelect(businessSectorDetails, newEntry.sectorId)}
                            error={errors.sectorId}
                            onChange={(chosenId) => onUpdate('sectorId', chosenId)}
                        />
                        <TextInputField
                            id={'website'}
                            label={'Business Website'}
                            placeholder={'Enter business website'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            value={newEntry.website || ''}
                            error={errors.website}
                            onChange={(e) => onUpdate('website', e.target.value)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'size'}
                            label={'Business Size'}
                            placeholder={'Enter business size'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            mandatory={true}
                            maxLength={5}
                            value={String(newEntry.size) || ''}
                            error={errors.size}
                            onChange={(e) =>
                                onUpdate('size', e.target.value.replace(/\D/g, '') || '')
                            }
                        />
                        <SingleSelect
                            id={'ownerId'}
                            key={keys.ownerId}
                            label={'Business Record Owner'}
                            placeholder="Select Business Record Owner..."
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            menuItems={businessOwners}
                            selectedId={newEntry.ownerId}
                            selected={getPreSelect(businessOwners, newEntry.ownerId)}
                            error={errors.ownerId}
                            onLoadMoreItems={onLoadMoreBusinessOwners}
                            moreItemsToLoad={
                                !userMetaData.totalElements ||
                                users.length < userMetaData.totalElements
                            }
                            onChange={(chosenId) => onUpdate('ownerId', chosenId)}
                        />
                    </div>
                </div>
                <h2 className={app.sectionHeading}>Contact Details</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'address1'}
                            label={'Address Line 1'}
                            placeholder={'Enter address line 1'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            value={newEntry.addressLine1 || ''}
                            error={errors.addressLine1}
                            maxLength={ADDRESS_LINE_1_MAX_LENGTH}
                            onChange={(e) => onUpdate('addressLine1', e.target.value)}
                        />
                        <TextInputField
                            id={'address2'}
                            label={'Address Line 2'}
                            placeholder={'Enter address line 2'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            value={newEntry.addressLine2 || ''}
                            error={errors.addressLine2}
                            maxLength={ADDRESS_LINE_2_MAX_LENGTH}
                            onChange={(e) => onUpdate('addressLine2', e.target.value)}
                        />
                        <TextInputField
                            id={'address3'}
                            label={'Address Line 3'}
                            placeholder={'Enter address line 3'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            value={newEntry.addressLine3 || ''}
                            error={errors.addressLine3}
                            maxLength={ADDRESS_LINE_3_MAX_LENGTH}
                            onChange={(e) => onUpdate('addressLine3', e.target.value)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'city'}
                            label={'City'}
                            placeholder={'Enter city'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            value={newEntry.city || ''}
                            error={errors.city}
                            maxLength={CITY_MAX_LENGTH}
                            onChange={(e) => onUpdate('city', e.target.value)}
                        />
                        <TextInputField
                            id={'postcode'}
                            label={'Postcode'}
                            placeholder={'Enter postcode'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            value={newEntry.postcode || ''}
                            error={errors.postcode}
                            onChange={(e) => onUpdate('postcode', e.target.value)}
                        />
                    </div>
                </div>
                <h2 className={app.sectionHeading}>Business Contacts</h2>
                <div className={`${cards.cards} ${cards.cardContainer}`}>
                    {newEntry.contactCards.map((el) => (
                        <div key={el.id}>
                            <BusinessContactCard
                                entry={el}
                                roles={loggedInUser.roles}
                                acceptedRoles={acceptedRoles}
                                onEdit={onEditBusinessContact}
                                editable={true}
                            />
                        </div>
                    ))}
                </div>
                {showCreateContactModal && (
                    <CreateBusinessContact
                        type={editContactType}
                        entry={currentContactCard}
                        onAddContact={addBusinessContact}
                        onEditContact={editBusinessContact}
                        onCancel={onCancelBusinessContact}
                        isOpen={showCreateContactModal}
                        roles={loggedInUser.roles}
                        acceptedRoles={acceptedRoles}
                    />
                )}

                <Button
                    id="createBusinessContact"
                    type="button"
                    content="Add a Business Contact"
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                    customClass="buttonMargin"
                    onClick={onAddBusinessContact}
                />

                <FormActions
                    id="create"
                    btnText="Create Business"
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles) || isDisabled}
                    onClose={onSubmit}
                    onCancel={onNavigate}
                />
            </form>
        </div>
    );
};

export default CreateBusiness;
