import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateBusinessRecord } from '../../../../store/recruitmentService';
import { formatDateTime } from '../../../../utils/dateFunctions';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import Button from '../../../formElements/Button';
import TextAreaField from '../../../formElements/TextAreaField';

import app from '../../../../app.module.css';
import form from '../../../../commonStyles/formStyles.module.css';
import local from '../../recruitmentStyles/rowDropDown.module.css';

const CHARACTER_LIMIT = 750;

const BusinessNotes = () => {
    const dispatch = useDispatch();
    // LOCAL STATE
    const acceptedRoles = [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [notes, setNotes] = useState([]);
    const [newNote, setNewNote] = useState('');

    //STORE STATE
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const currentBusiness = useSelector(
        (state) => state.entities.recruitmentService.currentBusiness
    );

    // USE EFFECTS
    useEffect(() => {
        if (Object.keys(currentBusiness).length < 1) return;
        setNotes(currentBusiness.notes);
    }, [currentBusiness]);

    const onAddNote = () => {
        if (!currentBusiness || Object.keys(currentBusiness).length < 1) return;
        const payload = {
            ...currentBusiness,
            notes: [...currentBusiness.notes, { notes: newNote }]
        };
        dispatch(updateBusinessRecord(payload));
        setNewNote('');
    };

    return (
        <div>
            <h2 className={app.sectionHeading}>Notes</h2>
            <div className={local.noteSection}>
                <TextAreaField
                    id="notesCurrent"
                    label="Add Note"
                    placeholder={'Enter notes for this business'}
                    disabled={!hasRole(acceptedRoles, roles)}
                    maxLength={CHARACTER_LIMIT}
                    value={newNote || ''}
                    count={`${newNote.length}/${CHARACTER_LIMIT}`}
                    onChange={(e) => setNewNote(e.target.value)}
                />
                <Button
                    id="AddBusinessNotes"
                    type="button"
                    disabled={newNote.length < 6 || !hasRole(acceptedRoles, roles)}
                    content="Add Note"
                    icon={true}
                    onClick={onAddNote}
                />
            </div>

            {notes?.length > 0 && (
                <div>
                    <h2 className={app.sectionHeading}>Previous Notes</h2>
                    <div className={form.staticContent}>
                        {notes.length > 0 &&
                            notes.map((el, i) => (
                                <div key={`${el.id}-${i}`} className={form.staticText}>
                                    <label> {formatDateTime(el.dateCreated)} </label>
                                    <div> {el.notes} </div>
                                </div>
                            ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default BusinessNotes;
