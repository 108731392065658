import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';

import {
    clearBarrierName,
    loadBarrierNameDetailsByNameAndContract,
    loadDocumentTypes
} from '../../../store/directusService';
import {
    selectCurrentQuestionnaire,
    selectQuestionnaireAnswers
} from '../../../store/participantSelectors';
import {
    loadParticipantBarriers,
    loadQuestionnaireAnswersById,
    loadQuestionnaireById,
    postQuestionnaireAnswersComplete,
    postQuestionnaireAnswersDraft
} from '../../../store/participantService';
import { selectCurrentUser } from '../../../store/userSelectors';
import { loadUser } from '../../../store/userService';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import AddDocument from '../../documents/AddDocument';
import GenerateQuestionnairePDF from '../../pdfGenerator/GenerateQuestionnairePDF';
import FormActions from '../../ui/formActions/FormActions';

import SelectBarriersEditor from './barriers/SelectBarriersEditor';
import { themeJson } from './questionnaireAnswerTheme';

import form from '../../../commonStyles/formStyles.module.css';

const QuestionnaireAnswer = ({ row, onClose, roles }) => {
    //DISPATCH
    const dispatch = useDispatch();

    //LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [surveyModel, setSurveyModel] = useState(new Model('{}'));
    const [isSucceeded, setIsSucceeded] = useState(false);
    const [generatePdf, setGeneratePdf] = useState(false);
    const [showDocumentDownload, setShowDocumentDownload] = useState(false);
    const [docType, setDocType] = useState(false);
    const [barrierEditor, setBarrierEditor] = useState(false);
    const [barriersProcessed, setBarriersProcessed] = useState(false);
    const [barriersLoaded, setBarriersLoaded] = useState(false);
    const [unselectedBarriers, setUnselectedBarriers] = useState([]);
    const [surveyComplete, setSurveyComplete] = useState(false);

    //STORE STATE
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const { currentParticipant, currentParticipantBarriers } = useSelector(
        (state) => state.entities.participantService
    );
    const currentQuestionnaire = useSelector(selectCurrentQuestionnaire);
    const currentUser = useSelector(selectCurrentUser);
    const questionnaireAnswers = useSelector(selectQuestionnaireAnswers);
    const documentTypes = useSelector((state) => state.entities.directusService.documentTypes);
    const barrierNameDetails = useSelector(
        (state) => state.entities.directusService.barrierNameDetails
    );

    // EVENT HANDLERS
    const onCancel = () => {
        onClose();
    };

    const onFormExit = () => {
        onClose();
    };

    const onExit = () => setShowDocumentDownload(false);

    // HELPER FNS
    const createQuestionnairePayload = () => {
        let payload;

        if (questionnaireAnswers && !Array.isArray(questionnaireAnswers)) {
            payload = {
                ...questionnaireAnswers
            };
        } else {
            payload = {
                participantId: currentParticipant.id,
                pdfName: currentQuestionnaire.title,
                questionnaireId: currentQuestionnaire.id
            };
        }
        payload.data = surveyModel.data;
        return payload;
    };

    const onCompleteSurvey = () => {
        loadSuggestedBarriers();
        const payload = createQuestionnairePayload();

        dispatch(postQuestionnaireAnswersComplete(payload));
    };

    const loadSuggestedBarriers = () => {
        const barriers = surveyModel
            .getPlainData()
            .filter((el) => el.title.endsWith('Barrier') && el.value);
        const barrierNames = barriers.map((el) => el.name.replaceAll('_', ' '));

        dispatch(
            loadBarrierNameDetailsByNameAndContract(barrierNames, currentParticipant.contractId)
        );
    };

    const onSaveDraft = () => {
        const payload = createQuestionnairePayload();
        dispatch(postQuestionnaireAnswersDraft(payload));
        onClose();
    };

    const onBarrierEditor = (setOpen) => {
        setBarrierEditor(setOpen);
    };

    // USE EFFECTS
    useEffect(() => {
        dispatch(clearBarrierName());
        dispatch(loadDocumentTypes());
    }, []);

    useEffect(() => {
        if (documentTypes.length < 1) return;
        setDocType(documentTypes.find((el) => el.name === 'Questionnaire PDF'));
    }, [documentTypes]);

    useEffect(() => {
        dispatch(loadQuestionnaireById(row.questionnaireId));
        if (row.id) {
            dispatch(loadQuestionnaireAnswersById(row.id));
        }
    }, [row]);

    useEffect(() => {
        if (currentParticipant.userId) {
            dispatch(loadUser(currentParticipant.userId));
        }
        dispatch(loadParticipantBarriers(currentParticipant.id));
    }, [currentParticipant]);

    useEffect(() => {
        if (Object.keys(currentQuestionnaire).length < 1) return;

        const model = new Model(currentQuestionnaire.data);
        model.clearInvisibleValues = 'none';
        model.applyTheme(themeJson);
        setSurveyModel(model);
    }, [currentQuestionnaire]);

    useEffect(() => {
        if (questionnaireAnswers.data) {
            surveyModel.data = questionnaireAnswers.data;

            // SUPERUSERS can still edit a completed questionnaire
            if (!row.draft && !hasRole([SUPERUSER], roles)) {
                surveyModel.mode = 'display';
            }
        }
    }, [surveyModel, questionnaireAnswers]);

    useEffect(() => {
        if (successMessage === `Participant barriers loaded`) {
            setBarriersLoaded(true);
        } else if (successMessage === `Questionnaire answers have been completed successfully`) {
            setSurveyComplete(true);
            setGeneratePdf(true);
        } else if (
            successMessage.startsWith(`Document`) &&
            successMessage.endsWith(`has been uploaded`)
        ) {
            onFormExit();
        }
    }, [successMessage]);

    useEffect(() => {
        if (barriersProcessed || !isSucceeded) return;
        if (currentParticipantBarriers.length > 0) {
            if (barrierNameDetails.length > 0) {
                let unselectedBarriers = barrierNameDetails;
                currentParticipantBarriers.filter((el) => {
                    const id = barrierNameDetails.find((entry) => entry.id === el.barrierId)?.id;

                    if (id) {
                        unselectedBarriers = unselectedBarriers.filter((el) => el.id !== id);
                    }
                });
                setUnselectedBarriers(unselectedBarriers);
            }
        } else if (currentParticipantBarriers.length === 0 && barriersLoaded) {
            if (barrierNameDetails.length > 0) {
                setUnselectedBarriers(barrierNameDetails);
            }
        }
    }, [currentParticipantBarriers, barrierNameDetails, isSucceeded]);

    useEffect(() => {
        if (unselectedBarriers.length > 0 && !barriersProcessed) {
            setBarriersProcessed(true);
            onBarrierEditor(true);
        }
    }, [unselectedBarriers]);

    // CALLBACKS
    const onPdfGenerated = (isPdfGenerated) => {
        setIsSucceeded(isPdfGenerated);
        setShowDocumentDownload(isPdfGenerated);
        setGeneratePdf(false);
    };

    // RENDER
    return (
        <div>
            <Survey model={surveyModel} onComplete={onCompleteSurvey} />
            <FormActions
                onClose={onSaveDraft}
                onCancel={onCancel}
                disabled={
                    surveyComplete ||
                    !(
                        hasRole([SUPERUSER], roles) ||
                        ((row.draft === undefined || row.draft) && hasRole(acceptedRoles, roles))
                    )
                }
                btnText="Save Draft"
            />
            <SelectBarriersEditor
                open={barrierEditor}
                onBarrierEditor={onBarrierEditor}
                barrierNameDetails={unselectedBarriers}
                currentParticipant={currentParticipant}
            />
            {generatePdf && (
                <GenerateQuestionnairePDF
                    surveyModel={surveyModel}
                    questionnaire={currentQuestionnaire}
                    questionnaireAnswers={questionnaireAnswers}
                    currentParticipant={currentParticipant}
                    currentUser={currentUser}
                    onPdfGenerated={onPdfGenerated}
                />
            )}
            {isSucceeded && (
                <div className={form.successMessage}>
                    PDF {currentQuestionnaire.title} downloaded
                </div>
            )}
            {showDocumentDownload && (
                <div>
                    <AddDocument
                        onClose={onExit}
                        onCancel={onExit}
                        btnText="Close"
                        currentDocumentType={docType ? docType : null}
                    />
                </div>
            )}
        </div>
    );
};

QuestionnaireAnswer.propTypes = {
    row: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string)
};

export default QuestionnaireAnswer;
