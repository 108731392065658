import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';

import {
    selectAssetRequestAsset,
    selectAssetRequestOrderType,
    selectAssetRequestStatus,
    selectAssetRequestType
} from '../../../../store/dataSelectors';
import {
    loadAssetRequestAssetDetails,
    loadAssetRequestOrderTypeDetails,
    loadAssetRequestRequestTypeDetails,
    loadAssetRequestStatusDetails
} from '../../../../store/directusService';
import { setErrorMessage } from '../../../../store/formsState';
import { selectCurrentParticipant } from '../../../../store/participantSelectors';
import { updateAssetIssueRequest, updateAssetRequest } from '../../../../store/participantService';
import { selectLoggedInUser, selectUsers } from '../../../../store/userSelectors';
import { getConfiguredItems, getNameFromId } from '../../../../utils/directusFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import LabelledTextField from '../../../ui/editors/LabelledTextField';
import LoadingSpinner from '../../../ui/LoadingSpinner';
import DDLOptionPicker from '../../../ui/pickers/DDLOptionPicker';

import form from '../../../../commonStyles/formStyles.module.css';
import actions from '../../../ui/formActions/formActions.module.css';

const REASON_DETAILS_LIMIT = 500;
const AUTHORISER_NOTES_LIMIT = 500;
const EditAssetRequest = ({ onClose, roles, row }) => {
    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const dispatch = useDispatch();

    // LOCAL STATE
    const hasRestrictedAcceptedRole = hasRole([MANAGER, QUALITY, SUPERUSER], roles);
    const hasAcceptedRole = hasRole(
        [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
        roles
    );
    const hasBeenIssued = !!row.dateIssued;

    const [newEntry, setNewEntry] = useState({});
    const [advisers, setAdvisers] = useState([]);

    const prePopulatedFields = [
        'approverNote',
        'adviserId',
        'assetId',
        'orderTypeId',
        'quantity',
        'requestDetail',
        'requestTypeId'
    ];

    const [arrayAssetRequestType, setArrayAssetRequestType] = useState([]);
    const [arrayAssetRequestAsset, setArrayAssetRequestAsset] = useState([]);
    const [arrayOrderType, setArrayOrderType] = useState([]);

    const [approveDisable, setApproveDisable] = useState(false);
    const [declineDisable, setDeclineDisable] = useState(false);
    const [withdrawDisable, setWithdrawDisable] = useState(false);

    let payload;

    // STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const assetRequestOrderTypeDetails = useSelector(selectAssetRequestOrderType);
    const assetRequestStatusDetails = useSelector(selectAssetRequestStatus);
    const assetRequestTypeDetails = useSelector(selectAssetRequestType);
    const assetRequestAssetDetails = useSelector(selectAssetRequestAsset);

    const loggedInUser = useSelector(selectLoggedInUser);
    const users = useSelector(selectUsers);

    const handleDDLPickerChange = (field) => (chosenId) => {
        if (chosenId && chosenId !== row[field]) disableButtonsOnEdit();
        if (chosenId !== newEntry[field]) {
            setNewEntry((prev) => ({ ...prev, [field]: chosenId }));
            clearErrors(field);
        }
    };

    const handleTextInputChange =
        (field, enableButtons = false) =>
        (e) => {
            if (!enableButtons) disableButtonsOnEdit();
            setNewEntry((prev) => ({ ...prev, [field]: e.target.value }));
        };

    // HELPER FNS
    function disableButtonsOnEdit() {
        setApproveDisable(true);
        setDeclineDisable(true);
        setWithdrawDisable(true);
    }

    const arraysEqual = (array1, array2) =>
        array1.length === array2.length && array1.every((value, index) => value === array2[index]);

    const getAssetItems = (requestTypeId) =>
        assetRequestAssetDetails?.filter((asset) => {
            return (
                asset.asset_request_request_type[0]?.asset_request_request_type_id?.id ===
                requestTypeId
            );
        });

    const getAdviserName = (id) => {
        if (!id || id === '') {
            return '';
        }
        return advisers.find((item) => item.id === id)?.name || '';
    };

    const getStatusName = (id) => {
        let status = assetRequestStatusDetails.find((item) => item.id === id);
        return status?.name.toLowerCase();
    };

    const getStatusId = (name) => {
        let status = assetRequestStatusDetails.find((item) => item.name.toLowerCase() === name);
        return status.id;
    };

    // USE EFFECTS
    useEffect(() => {
        if (!assetRequestStatusDetails?.length) dispatch(loadAssetRequestStatusDetails());
        if (!assetRequestTypeDetails?.length) dispatch(loadAssetRequestRequestTypeDetails());
        if (!assetRequestAssetDetails?.length) dispatch(loadAssetRequestAssetDetails());
        if (!assetRequestOrderTypeDetails?.length) dispatch(loadAssetRequestOrderTypeDetails());
    }, []);

    useEffect(() => {
        // Force Yup validation on pre-populated
        prePopulatedFields.forEach((field) => {
            setValue(field, row[field], {
                shouldValidate: true
            });
        });

        setNewEntry(row);
    }, [row, row.requestTypeId]);

    useEffect(() => {
        setAdvisers(
            users
                .filter((el) =>
                    el.userTypes?.find(
                        (entry) =>
                            entry.role === ADVISER ||
                            entry.role === MANAGER ||
                            entry.role === RECRUITMENT_MANAGER ||
                            entry.role === SUPERUSER ||
                            entry.role === QUALITY
                    )
                )
                .map(({ id, emailAddress: name }) => ({
                    name,
                    id
                }))
                .sort((a, b) => a.name.localeCompare(b.name))
        );
    }, [users]);

    useEffect(() => {
        if (assetRequestStatusDetails?.length) {
            let status = assetRequestStatusDetails.find(
                (item) => item.name.toLowerCase() === 'requested'
            );
            let id = status.id;
            setNewEntry((prev) => ({ ...prev, statusId: id }));
        }
    }, [assetRequestStatusDetails]);

    useEffect(() => {
        if (assetRequestTypeDetails?.length && arrayAssetRequestType?.length === 0) {
            setArrayAssetRequestType(
                getConfiguredItems(assetRequestTypeDetails, currentParticipant?.contractId)
            );
        }
    }, [assetRequestTypeDetails]);

    useEffect(() => {
        if (assetRequestAssetDetails?.length && arrayAssetRequestAsset?.length === 0) {
            setArrayAssetRequestAsset(
                getConfiguredItems(assetRequestAssetDetails, currentParticipant?.contractId)
            );
        }
    }, [assetRequestAssetDetails]);

    useEffect(() => {
        if (assetRequestOrderTypeDetails?.length && arrayOrderType?.length === 0) {
            setArrayOrderType(
                getConfiguredItems(assetRequestOrderTypeDetails, currentParticipant?.contractId)
            );
        }
    }, [assetRequestOrderTypeDetails]);

    // EVENT HANDLERS
    const onFormExit = () => {
        setApproveDisable(false);
        setDeclineDisable(false);
        setWithdrawDisable(false);
        payload = '';
        clearErrors();
        onClose();
    };

    function onApprove() {
        payload = { ...newEntry, statusId: getStatusId('approved'), approverId: loggedInUser.id };
        onSubmit();
    }

    function onDecline() {
        payload = { ...newEntry, statusId: getStatusId('declined'), approverId: loggedInUser.id };
        onSubmit();
    }

    function onWithdraw() {
        payload = { ...newEntry, statusId: getStatusId('withdrawn') };
        onSubmit();
    }

    function onIssue() {
        payload = {
            ...newEntry,
            statusId: getStatusId('approved')
        };
        dispatch(updateAssetIssueRequest(payload));
        onFormExit();
    }
    const onSubmit = () => {
        if (payload) {
            dispatch(updateAssetRequest(payload));
            onFormExit();
        } else {
            if (
                newEntry.quantity === '0' ||
                newEntry.quantity === '0.0' ||
                newEntry.quantity === '0.00'
            ) {
                dispatch(setErrorMessage('Amount cannot be zero'));
            } else {
                dispatch(updateAssetRequest(newEntry));
                onFormExit();
            }
        }
    };

    let content;
    if (users?.length < 1) content = 'No users found';
    if (Object.keys(currentParticipant)?.length < 1) content = 'No current participant';

    // RENDER
    if (users?.length < 1 || Object.keys(currentParticipant)?.length < 1) {
        return <LoadingSpinner content={content} />;
    }

    const disableInputFurther =
        !hasRestrictedAcceptedRole || getStatusName(row.statusId) !== 'requested';
    const disableInput = !hasAcceptedRole || getStatusName(row.statusId) !== 'requested';

    return (
        <div className={form.formWrapper}>
            <h3>Edit Asset Request - ID {row.code}</h3>
            <form className={form.form} onSubmit={handleSubmit(onSubmit)}>
                <br />
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <DDLOptionPicker
                            label={'Requestor'}
                            id={'adviserId'}
                            disabled={disableInputFurther}
                            mandatory={true}
                            menuItems={advisers || []}
                            chosenName={getAdviserName(row.adviserId)}
                            chosenId={row.adviserId}
                            error={errors.adviserId}
                            {...register('adviserId')}
                            onChange={handleDDLPickerChange('adviserId')}
                        />
                        <DDLOptionPicker
                            label={'Request Type'}
                            id={'requestTypeId'}
                            disabled={disableInput}
                            mandatory={true}
                            menuItems={arrayAssetRequestType}
                            chosenName={
                                newEntry.requestTypeId
                                    ? getNameFromId(arrayAssetRequestType, newEntry.requestTypeId)
                                    : ''
                            }
                            chosenId={newEntry.requestTypeId || ''}
                            error={errors.requestTypeId}
                            {...register('requestTypeId')}
                            onChange={handleDDLPickerChange('requestTypeId')}
                        />
                        {/* The checks on the chosen value prevent a mui warning appearing when the user updates the requestTypeId DDL
                         that controls the values available in this DDL and ensures that it displays the row selected for edit*/}
                        <DDLOptionPicker
                            label={'Asset'}
                            id={'assetId'}
                            disabled={disableInput}
                            mandatory={true}
                            chosenName={
                                !arraysEqual(
                                    getAssetItems(newEntry.requestTypeId),
                                    getAssetItems(row.requestTypeId)
                                )
                                    ? ''
                                    : getNameFromId(
                                          getAssetItems(newEntry.requestTypeId),
                                          newEntry.assetId
                                      )
                            }
                            menuItems={getAssetItems(newEntry.requestTypeId)}
                            error={errors.assetId}
                            chosenId={
                                !arraysEqual(
                                    getAssetItems(newEntry.requestTypeId),
                                    getAssetItems(row.requestTypeId)
                                )
                                    ? ''
                                    : newEntry.assetId || row.assetId
                            }
                            {...register('assetId')}
                            onChange={handleDDLPickerChange('assetId')}
                        />
                        <DDLOptionPicker
                            label={'Order Type'}
                            id={'orderTypeId'}
                            disabled={disableInput}
                            mandatory={true}
                            menuItems={arrayOrderType}
                            chosenName={
                                newEntry.orderTypeId
                                    ? getNameFromId(arrayOrderType, newEntry.orderTypeId)
                                    : ''
                            }
                            chosenId={newEntry.orderTypeId || ''}
                            error={errors.orderTypeId}
                            {...register('orderTypeId')}
                            onChange={handleDDLPickerChange('orderTypeId')}
                        />
                        <LabelledTextField
                            label={'Authoriser Notes'}
                            id={'approverNote'}
                            mandatory={true}
                            disabled={disableInput}
                            multiline
                            rows={5}
                            value={newEntry.approverNote || ''}
                            placeholder={'Enter authoriser notes'}
                            counter={'true'}
                            helperText={
                                `${newEntry.approverNote ? newEntry.approverNote.length : 0}` +
                                '/' +
                                AUTHORISER_NOTES_LIMIT
                            }
                            inputProps={{ maxLength: AUTHORISER_NOTES_LIMIT }}
                            error={errors.approverNote}
                            {...register('approverNote')}
                            onChange={handleTextInputChange('approverNote', true)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Quantity'}
                            id={'quantity'}
                            type="number"
                            disabled={disableInput}
                            mandatory={true}
                            value={newEntry.quantity || ''}
                            placeholder={'Enter quantity'}
                            error={errors.quantity}
                            {...register('quantity')}
                            onChange={handleTextInputChange('quantity')}
                        />
                        <LabelledTextField
                            label={'Batch Number'}
                            id={'batchNumber'}
                            disabled={disableInput}
                            mandatory={false}
                            value={newEntry.batchNumber || ''}
                            placeholder={'Enter batch number'}
                            error={errors.batchNumber}
                            {...register('batchNumber')}
                            onChange={handleTextInputChange('batchNumber')}
                        />
                        <LabelledTextField
                            label={'Voucher Code'}
                            id={'voucherCode'}
                            disabled={disableInput}
                            mandatory={false}
                            value={newEntry.voucherCode || ''}
                            placeholder={'Enter voucher code'}
                            error={errors.voucherCode}
                            {...register('voucherCode')}
                            onChange={handleTextInputChange('voucherCode')}
                        />
                        <LabelledTextField
                            label={'Request Details'}
                            id={'requestDetail'}
                            mandatory={false}
                            disabled={disableInput}
                            multiline
                            rows={5}
                            value={newEntry.requestDetail || ''}
                            placeholder={'Enter request details'}
                            counter={'true'}
                            helperText={`${
                                newEntry.requestDetail?.length ?? 0
                            } / ${REASON_DETAILS_LIMIT}`}
                            inputProps={{ maxLength: REASON_DETAILS_LIMIT }}
                            error={errors.requestDetail}
                            {...register('requestDetail')}
                            onChange={handleTextInputChange('requestDetail')}
                        />
                    </div>
                </div>
                <div className={actions.formActions}>
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit(onApprove)}
                        disabled={
                            disableInputFurther ||
                            approveDisable ||
                            (newEntry.approverNote?.length ?? 0) < 7
                        }>
                        {' '}
                        Approve
                    </Button>
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit(onDecline)}
                        disabled={
                            disableInputFurther ||
                            declineDisable ||
                            (newEntry.approverNote?.length ?? 0) < 7
                        }>
                        {' '}
                        Decline
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit(onWithdraw)}
                        disabled={
                            !hasAcceptedRole ||
                            withdrawDisable ||
                            hasBeenIssued ||
                            (getStatusName(row.statusId) !== 'requested' &&
                                getStatusName(row.statusId) !== 'approved')
                        }>
                        {' '}
                        Withdraw
                    </Button>
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit(onIssue)}
                        disabled={
                            !hasAcceptedRole ||
                            hasBeenIssued ||
                            getStatusName(row.statusId) !== 'approved'
                        }>
                        {' '}
                        Issue
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                        disabled={disableInput}>
                        {' '}
                        Update
                    </Button>
                    <div className={actions.cancelLink} onClick={onFormExit}>
                        Cancel X
                    </div>
                </div>
            </form>
        </div>
    );
};

const validString =
    (min, max, allowEmptyString = true) =>
    (val) =>
        !val ||
        (allowEmptyString ? val.length === 0 : false) ||
        (val.length >= min && val.length <= max);

const validationSchema = Yup.object().shape({
    adviserId: Yup.string()
        .nullable()
        .required()
        .test(
            'adviserId',
            'Please select an adviser',
            (value, originalValue) => originalValue?.from[0]?.value !== '' && value !== ''
        ),
    approverNote: Yup.string()
        .nullable()
        .test(
            'approverNote',
            'An authoriser note when required, must be at least 7 characters and at most 500 characters',
            validString(7, 500)
        ),
    assetId: Yup.string().nullable().required('Please select an asset'),
    batchNumber: Yup.string()
        .nullable()
        .test(
            'batchNumber',
            'A batch number when entered, must be at most 20 characters',
            validString(0, 20)
        ),
    orderTypeId: Yup.string().nullable().required('Please select an order type'),
    quantity: Yup.number()
        .nullable()
        .required('Please enter a quantity')
        .typeError('Quantity must be a whole number')
        .integer('Quantity must be a whole number')
        .min(1, 'Quantity cannot be negative, blank or zero')
        .max(99, 'Quantity must be 99 or less'),
    requestTypeId: Yup.string().nullable().required('Please select an asset request type'),
    requestDetail: Yup.string()
        .nullable()
        .test(
            'requestDetail',
            'A request detail when entered, must be at most 500 characters',
            validString(0, 500)
        ),
    voucherCode: Yup.string()
        .nullable()
        .test('voucherCode', 'A voucher code when entered, must be 9 characters', validString(9, 9))
});

export default EditAssetRequest;

EditAssetRequest.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.string,
        adviserId: PropTypes.string,
        approverNote: PropTypes.string,
        asset: PropTypes.string,
        assetId: PropTypes.string,
        authoriser: PropTypes.string,
        batchNumber: PropTypes.string,
        code: PropTypes.string,
        dateIssued: PropTypes.string,
        orderTypeId: PropTypes.string,
        quantity: PropTypes.number,
        requestDetail: PropTypes.string,
        requestTypeId: PropTypes.string,
        status: PropTypes.string,
        statusId: PropTypes.string,
        statusChangeDate: PropTypes.string,
        voucherCode: PropTypes.string
    }),
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    formType: PropTypes.string
};
