import { compareUnorderedArrays } from '../../../../../utils/arrayUtils';
import { getFutureDate } from '../../../../../utils/dateFunctions';
import {
    CREATE,
    INVALID_POSTCODE,
    UPDATE
} from '../../../../../utils/formValidation/commonConstants';
import { NO_UPDATE_ERROR } from '../../../../../utils/formValidation/commonErrorConstants';
import {
    getNumMaxMessage,
    getNumMinMessage,
    getReqMessage
} from '../../../../../utils/formValidation/errorMessageFunctions';
import { regExps } from '../../../../../utils/formValidation/validationPatterns';

export const EMPTY_STAGING = 'One or more stages is empty';
export const EXPECTED_WEEKLY_HOURS = 'Expected Weekly Hours';
export const HIRING_MANAGER = 'Hiring Manager';
export const MINIMUM_WAGE = 0.01;
export const MAXIMUM_WAGE = 999.99;
export const MINIMUM_HOURS = 1;
export const MAXIMUM_HOURS = 40;
export const MINIMUM_POSITIONS = 1;
export const MAXIMUM_POSITIONS = 999;
export const NOT_FUTURE_DATE = 'Date must be in future.';
export const POSITIONS = 'Number of Positions';
export const PAY_RATE_FROM = 'Pay Rate - From';
export const PAY_RATE_TO = 'Pay Rate - To';
export const STAGE_ID = 'Stage id';
export const VACANCY_TITLE = 'Vacancy Title';
export const VACANCY_OWNER_ID = 'Vacancy Owner';

export const initialErrorState = {};

export const validate = (newEntry, component, currentVacancy) => {
    let newErrors = {};

    if (!newEntry.title) {
        newErrors = {
            ...newErrors,
            title: { error: true, message: getReqMessage(VACANCY_TITLE) }
        };
    }

    if (!newEntry.ownerId) {
        newErrors = {
            ...newErrors,
            ownerId: { error: true, message: getReqMessage(VACANCY_OWNER_ID) }
        };
    }

    if (!newEntry.hiringManagerId) {
        newErrors = {
            ...newErrors,
            hiringManagerId: { error: true, message: getReqMessage(HIRING_MANAGER) }
        };
    }

    if (!newEntry.sectorId) {
        newErrors = {
            ...newErrors,
            sectorId: { error: true, message: getReqMessage('Job Sector') }
        };
    }

    if (!newEntry.numberOfPositions) {
        newErrors = {
            ...newErrors,
            numberOfPositions: { error: true, message: getReqMessage(POSITIONS) }
        };
    } else if (newEntry.numberOfPositions < MINIMUM_POSITIONS) {
        newErrors = {
            ...newErrors,
            numberOfPositions: {
                error: true,
                message: getNumMinMessage(POSITIONS, MINIMUM_POSITIONS)
            }
        };
    } else if (newEntry.numberOfPositions > MAXIMUM_POSITIONS) {
        newErrors = {
            ...newErrors,
            numberOfPositions: {
                error: true,
                message: getNumMaxMessage(POSITIONS, MAXIMUM_POSITIONS)
            }
        };
    }

    if (!newEntry.vacancyClosingDate) {
        newErrors = {
            ...newErrors,
            vacancyClosingDate: { error: true, message: getReqMessage('Date') }
        };
    } else if (!getFutureDate(newEntry.vacancyClosingDate)) {
        newErrors = {
            ...newErrors,
            vacancyClosingDate: { error: true, message: NOT_FUTURE_DATE }
        };
    }

    if (newEntry.anticipatedStartDate) {
        if (!getFutureDate(newEntry.anticipatedStartDate)) {
            newErrors = {
                ...newErrors,
                anticipatedStartDate: { error: true, message: NOT_FUTURE_DATE }
            };
        }
    }

    if (!newEntry.payRateFrom) {
        newErrors = {
            ...newErrors,
            payRateFrom: { error: true, message: getReqMessage(PAY_RATE_FROM) }
        };
    } else if (newEntry.payRateFrom < MINIMUM_WAGE) {
        newErrors = {
            ...newErrors,
            payRateFrom: {
                error: true,
                message: getNumMinMessage(PAY_RATE_FROM, MINIMUM_WAGE)
            }
        };
    } else if (newEntry.payRateFrom > MAXIMUM_WAGE) {
        newErrors = {
            ...newErrors,
            payRateFrom: {
                error: true,
                message: getNumMaxMessage(PAY_RATE_FROM, MAXIMUM_WAGE)
            }
        };
    }

    if (!newEntry.payRateTo) {
        newErrors = {
            ...newErrors,
            payRateTo: { error: true, message: getReqMessage(PAY_RATE_TO) }
        };
    } else if (parseFloat(newEntry.payRateTo) < parseFloat(newEntry.payRateFrom)) {
        newErrors = {
            ...newErrors,
            payRateTo: {
                error: true,
                message: getNumMinMessage(PAY_RATE_TO, newEntry.payRateFrom)
            }
        };
    } else if (newEntry.payRateTo > MAXIMUM_WAGE) {
        newErrors = {
            ...newErrors,
            payRateTo: {
                error: true,
                message: getNumMaxMessage(PAY_RATE_TO, MAXIMUM_WAGE)
            }
        };
    }

    if (!newEntry.expectedWeeklyHours) {
        newErrors = {
            ...newErrors,
            expectedWeeklyHours: { error: true, message: getReqMessage(EXPECTED_WEEKLY_HOURS) }
        };
    } else if (newEntry.expectedWeeklyHours < MINIMUM_HOURS) {
        newErrors = {
            ...newErrors,
            expectedWeeklyHours: {
                error: true,
                message: getNumMinMessage(EXPECTED_WEEKLY_HOURS, MINIMUM_HOURS)
            }
        };
    } else if (newEntry.expectedWeeklyHours > MAXIMUM_HOURS) {
        newErrors = {
            ...newErrors,
            expectedWeeklyHours: {
                error: true,
                message: getNumMaxMessage(EXPECTED_WEEKLY_HOURS, MAXIMUM_HOURS)
            }
        };
    }

    if (!newEntry.remote) {
        if (!newEntry.addressLine1) {
            newErrors = {
                ...newErrors,
                addressLine1: { error: true, message: getReqMessage('Address Line 1') }
            };
        }

        if (!newEntry.city) {
            newErrors = {
                ...newErrors,
                city: { error: true, message: getReqMessage('City') }
            };
        }

        if (!newEntry.postcode) {
            newErrors = {
                ...newErrors,
                postcode: { error: true, message: getReqMessage('Postcode') }
            };
        } else if (!newEntry.postcode?.match(regExps.POSTCODE_R)) {
            newErrors = {
                ...newErrors,
                postcode: { error: true, message: INVALID_POSTCODE }
            };
        }
    }

    if (component === CREATE) {
        if (newEntry.stageIds.length < 1) {
            newErrors = {
                ...newErrors,
                stageId: { error: true, message: getReqMessage('Stage') }
            };
        } else if (newEntry.stageIds.some((el) => el.stageId === '')) {
            newErrors = {
                ...newErrors,
                stageId: { error: true, message: EMPTY_STAGING }
            };
        }
    }

    if (component === UPDATE) {
        if (Object.keys(newErrors).length < 1) {
            const noUpdate = compareUnorderedArrays(
                Object.values(newEntry),
                Object.values(currentVacancy)
            );

            if (noUpdate) {
                newErrors = { button: { error: true, message: NO_UPDATE_ERROR } };
            }
        }
    }

    return newErrors;
};
