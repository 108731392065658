import { getTotalTimeToSignificantValue } from '../dateFunctions';

export const getCharMaxMessage = (subject, maxLength) =>
    `${subject} must be ${maxLength} characters or fewer.`;

export const getCharMinMessage = (subject, minLength = 1) => {
    if (minLength === 1) return `${subject} must be at least ${minLength} character.`;
    return `${subject} must be at least ${minLength} characters.`;
};

export const getDateMaxMessage = (date) => `Must be before ${date}.`;

export const getNowDateOrLaterMessage = () => 'Must be today or later';

export const getNowDateOrEarlierMessage = () => 'Must be today or earlier';

export const getSameDateOrLater = (date) => `Must be the same or later than ${date}`;

export const getDateMinMessage = (date) => `Must be after ${date}.`;

export const getDuplicatedMessage = (subject) => `${subject} must be unique.`;

export const getExceededValueMessage = (value) => `Maximum value of ${value} has been exceeded`;

export const getExistsMessage = (subject) => `${subject} already exists.`;

export const getFormatMessage = (subject) => `${subject} must be in the correct format.`;

export const getIsLengthMessage = (subject, length) => {
    if (length === 1) return `${subject} must be ${length} character.`;
    return `${subject} must be ${length} characters.`;
};

export const getLoadingErrorMessage = (subject) => `No ${subject} found`;

export const getMatchMessage = (subject, matchSubject) =>
    `${subject} does not match ${matchSubject}.`;

export const getNotFoundMessage = (subject) => `${subject} not found`;

export const getNumMaxMessage = (subject, maxLength) =>
    `${subject} cannot be greater than ${maxLength}.`;

export const getNumMinMessage = (subject = 'Value', minLength = 1) =>
    `${subject} cannot be less than ${minLength}.`;

export const getReqMessage = (subject) => `${subject} is required.`;

export const getSameAsMessage = (subject1, subject2) =>
    `${subject2} cannot be the same as ${subject1}.`;

export const getSelectMessage = (subject, indefiniteArticle = 'a') =>
    `Please select ${indefiniteArticle} ${subject}.`;

export const getYoungMessage = (subject, minAge) =>
    `${subject} must be more than ${getTotalTimeToSignificantValue(minAge)} ago.`;
