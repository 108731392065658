import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import PortraitIcon from '@mui/icons-material/Portrait';
import RadarIcon from '@mui/icons-material/Radar';
import TodayIcon from '@mui/icons-material/Today';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { Checkbox } from '@mui/material';

import { selectUsersForNames } from '../../../../store/userSelectors';
import { reverseFormatDate } from '../../../../utils/dateFunctions';

import card from '../../../../commonStyles/card.module.css';

const ParticipantAttendanceRegisterView = ({ module }) => {
    // LOCAL STATE
    const {
        title,
        description,
        locationId,
        date,
        startTime,
        endTime,
        userId,
        capacity,
        smartTarget,
        participants
    } = module;

    const [courseLocation, setCourseLocation] = useState('');
    const [adviser, setAdviser] = useState('');

    // STORE STATE
    const users = useSelector(selectUsersForNames);
    const locations = useSelector((state) => state.entities.directusService.locationIdsAndNames);

    // USE EFFECTS
    useEffect(() => {
        if (users.length > 0) {
            const user = users.find((el) => el.id === userId);
            user && addAdviser(user);
        }
    }, [users]);

    useEffect(() => {
        if (locations.length > 0) {
            const location = locations.find((el) => el.id === locationId);
            location && setCourseLocation(location.name);
        }
    }, [locations]);

    //HELPER FNS
    const addAdviser = (adviser) => {
        const { firstName, lastName } = adviser;
        setAdviser(`${firstName} ${lastName}`);
    };

    // RENDER
    return (
        <div className={`${card.card} ${card.greyCard}`} data-testid="module_register_card_data">
            <div className={`${title?.length > 60 ? card.bannerDoubleRow : card.banner}`}>
                <h6>{title || 'Participant Attendance'}</h6>
            </div>

            <div className={card.content}>
                <div>
                    <div className={card.subHeader}>Module Description:</div>
                    <div className={card.description}>
                        {description && description.length > 100
                            ? description.substring(0, 97) + '...'
                            : description}
                    </div>
                </div>
                <div className={`${card.rows} ${card.compactRows}`}>
                    <div className={card.row}>
                        <LocationOnIcon />
                        {` ${courseLocation ? courseLocation : ' - '}`}
                    </div>
                    <div className={card.row}>
                        <TodayIcon />
                        {!date && !startTime && !endTime
                            ? 'Date and time here'
                            : ` ${date ? reverseFormatDate(date) : ''} ${
                                  startTime ? startTime : ''
                              } ${endTime ? endTime : ''}`}
                    </div>
                    <div className={card.row}>
                        <PortraitIcon />
                        {adviser || ' - '}
                    </div>
                    <div className={card.row}>
                        <RadarIcon />
                        <span className={card.smartTargetName}>{smartTarget?.name || ' - '}</span>
                    </div>
                </div>
                {!(
                    reverseFormatDate(date) + 'T' + startTime >=
                    format(new Date(), "yyyy/MM/dd'T'HH:mm")
                ) && (
                    <label>
                        <Checkbox disabled={true} defaultChecked />
                        Module selected to attend
                    </label>
                )}
                <div className={card.attendanceView}>
                    <span className={card.spaces}>
                        <WorkspacesIcon />
                        {participants &&
                            ` ${participants.length} of ${capacity ? capacity : ''} spaces filled`}
                        {!participants && ` 0 spaces filled`}
                    </span>
                </div>
            </div>
        </div>
    );
};

ParticipantAttendanceRegisterView.propTypes = {
    module: PropTypes.object.isRequired
};

export default ParticipantAttendanceRegisterView;
