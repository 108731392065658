import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import Button from '../../formElements/Button';
import LoadingSpinner from '../../ui/LoadingSpinner';

import AuditEventHistoryManagement from './AuditEventHistoryManagement';

import modal from '../../../commonStyles/modal.module.css';

const AuditTimelineEventHistoryModal = ({ label, isOpen, onClose }) => {
    // LOCAL STATE
    const [timelineSearchLoaded, setTimelineSearchLoaded] = useState(false);

    // STORE STATE
    const participantTimelineSearchData = useSelector(
        (state) => state.entities.participantService.participantTimelineSearchData
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS
    useEffect(() => {
        if (successMessage === `Timeline entry has been searched`) {
            setTimelineSearchLoaded(true);
        }
    }, [successMessage]);

    // HELPER FNS
    const loadingError = () => {
        if (participantTimelineSearchData?.length < 1)
            if (!timelineSearchLoaded) return `No audit event history found`;
    };

    // AWAITING CONTENT
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} />;

    // RENDER
    return (
        <Dialog
            onClose={onClose}
            open={isOpen}
            onClick={(e) => e.stopPropagation()}
            className="muiDialogWrapper"
            id="auditModal">
            <div className={modal.modalHeader}>
                <DialogTitle>{label} Change History</DialogTitle>
            </div>
            <DialogContent>
                <>
                    <AuditEventHistoryManagement
                        auditEventHistory={participantTimelineSearchData}
                        label={label}
                    />
                    <div className={modal.actionButtons}>
                        <div className={modal.cancelButton}>
                            <Button id="auditModalButton" onClick={onClose} content="Close" />
                        </div>
                    </div>
                </>
            </DialogContent>
        </Dialog>
    );
};

export default AuditTimelineEventHistoryModal;

AuditTimelineEventHistoryModal.propTypes = {
    label: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};
