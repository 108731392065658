import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import {
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../store/directusService';
import {
    loadParticipantByPtCode,
    loadRecentParticipants,
    saveCurrentParticipantId
} from '../../store/participantService';

import classes from '../../commonStyles/tables.module.css';

const FinancialRequestsTableRow = ({ row }) => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const [isLoaded, setIsLoaded] = useState(false);

    // STORE STATE
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );

    // USE EFFECTS
    useEffect(() => {
        if (currentParticipant.ptCode === row.ptCode && isLoaded) {
            dispatch(loadContractDetailsForContractId(currentParticipant.contractId));
            dispatch(loadServiceDetailsForContractId(currentParticipant.contractId));
            setIsLoaded(false);
            navigate('/edit_participant');
            saveCurrentParticipantId(currentParticipant.id);
            dispatch(loadRecentParticipants());
        }
    }, [currentParticipant]);

    // HELPER FUNCTIONS
    const onNav = () => {
        dispatch(loadParticipantByPtCode(row.ptCode));
        setIsLoaded(true);
    };

    // RENDER
    return (
        <TableRow hover className={classes.basicRowStripes}>
            <TableCell className={classes.rowLink} onClick={onNav}>
                {row.ptCode}
            </TableCell>
            <TableCell>{row.participantName}</TableCell>
            <TableCell>{row.adviserName}</TableCell>
            <TableCell>{row.requestedByName}</TableCell>
            <TableCell>{row.teamName}</TableCell>
            <TableCell>{row.amount}</TableCell>
            <TableCell>{row.dateIssued}</TableCell>
            <TableCell>{row.dateRequested}</TableCell>
            <TableCell>{row.paymentMethod}</TableCell>
            <TableCell>{row.authorisedByName}</TableCell>
            <TableCell>{row.requestType}</TableCell>
            <TableCell>{row.paymentReason}</TableCell>
            <TableCell>{row.evidenceDocument}</TableCell>
        </TableRow>
    );
};

FinancialRequestsTableRow.propTypes = {
    row: PropTypes.object.isRequired
};

export default FinancialRequestsTableRow;
