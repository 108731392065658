import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { loadCourseTemplate, updateCourse } from '../../../store/courseService';
import { loadServiceDetailsByService } from '../../../store/directusService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import TextAreaField from '../../formElements/TextAreaField';
import HealthInfo from '../../healthInfo/HealthInfo';
import FormActions from '../../ui/formActions/FormActions';
import LoadingSpinner from '../../ui/LoadingSpinner';
import ScheduleModuleAttendanceView from '../modules/scheduleModuleAttendance/ScheduleModuleAttendanceView';

import { initialErrorState, validate } from './validateCourseInstance';

import app from '../../../app.module.css';
import cardClasses from '../../../commonStyles/cardContainer.module.css';
import form from '../../../commonStyles/formStyles.module.css';
import pillClasses from '../../../commonStyles/staticPillList.module.css';
import pageClasses from '../courseStyles/editCourseStyles.module.css';

const CHARACTER_LIMIT = 250;
const EditCourse = () => {
    // HOOKS
    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const initialState = {
        id: null,
        name: '',
        description: '',
        courseProvider: 'internal',
        serviceIds: [],
        courseTemplateId: '',
        code: '',
        modules: []
    };
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const [isDisabled, setIsDisabled] = useState(false);

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const serviceDetails = useSelector((state) => state.entities.directusService.serviceDetails);
    const { currentCourse, currentTemplate } = useSelector((state) => state.entities.courseService);

    // USE EFFECTS
    useEffect(() => {
        if (currentCourse && Object.keys(currentCourse).length !== 0) {
            setNewEntry(currentCourse);
            dispatch(loadCourseTemplate(currentCourse.courseTemplateId));
            dispatch(loadServiceDetailsByService(currentCourse.serviceIds));
        }
    }, []);

    useEffect(() => {
        if (successMessage === `Course details for ${newEntry.name} have been updated`) {
            onNavigate();
        }
    }, [successMessage]);

    // HELPER FNS
    const loadingError = () => {
        if (serviceDetails?.length < 1) return `No service details available`;
        if (!currentTemplate) return `The current template hasn't loaded`;
        else if (Object.keys(currentTemplate)?.length < 1) return `The current template is empty`;
    };

    const onNavigate = () => {
        clearData();
        if (state?.accordionPanel)
            if (state.accordionPanel === 'allTemplates') navigate('/all_active_course_templates');
            else
                navigate('/course_management', { state: { accordionPanel: state.accordionPanel } });
        else navigate('/course_management');
    };

    const onCancel = () => {
        onNavigate();
    };

    const clearData = () => {
        setNewEntry(initialState);
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // EVENT HANDLERS
    const onDescriptionChange = (e) => {
        clearError('description');
        setNewEntry((prev) => ({ ...prev, description: e.target.value }));
    };

    // FORM SUBMIT
    const onSubmit = async (e) => {
        e.preventDefault();

        const newErrors = validate(newEntry, errors);
        if (Object.keys(newErrors)?.length > 0) {
            setErrors(newErrors);
            return;
        }
        setIsDisabled(true);
        dispatch(updateCourse(newEntry));
    };

    // AWAITING CONTENT
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} />;

    // RENDER
    return (
        <div className={form.formWrapper}>
            <form onSubmit={onSubmit} data-testid="form_start" className={form.form}>
                <h1 className={app.mainHeading}>Edit Course</h1>
                <h2 className={app.sectionHeading}>Course Details:</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <p className={pageClasses.courseName}>{newEntry.name}</p>
                        <TextAreaField
                            id={'courseDescription'}
                            label={'Course Description'}
                            placeholder={'Enter course description'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            mandatory={true}
                            value={newEntry.description || ''}
                            count={`${newEntry.description.length}/${CHARACTER_LIMIT}`}
                            maxLength={CHARACTER_LIMIT}
                            rows={6}
                            error={errors.description}
                            onChange={onDescriptionChange}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <div className={pageClasses.editCourseListSection}>
                            <h3>Available to services:</h3>
                            <div className={pillClasses.staticPillList}>
                                {currentCourse && Object.keys(currentCourse).length !== 0
                                    ? currentCourse.serviceIds.map((el) => (
                                          <span className={pillClasses.pill} key={el}>
                                              {serviceDetails.find((ele) => ele.id === el)?.name}
                                          </span>
                                      ))
                                    : ''}
                            </div>
                        </div>

                        {currentTemplate.healthInformationCaptured &&
                            currentTemplate.healthInformationIds.length > 0 && (
                                <HealthInfo
                                    healthInformationCaptured={true}
                                    healthInformationIds={currentTemplate.healthInformationIds}
                                />
                            )}
                    </div>
                </div>
                <h2 className={app.sectionHeading}>Schedule Module:</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={cardClasses.cardContainer}>
                            <div className={cardClasses.cards}>
                                {newEntry.modules.map((el) => (
                                    <div key={el.id}>
                                        <ScheduleModuleAttendanceView
                                            course={currentCourse}
                                            module={el}
                                            prevPage="editCourse"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <FormActions
                            id="publish"
                            btnText="Publish"
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || isDisabled}
                            onClose={onSubmit}
                            onCancel={onCancel}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default EditCourse;
