import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

import OnDeleteIcon from '../../icons/OnDeleteIcon';
import {
    selectAttendedStatusId,
    selectDoubtRaisedStatusId,
    selectFailedToAttendStatusId,
    selectNotKnownStatusId,
    selectSanctionedStatusId
} from '../../store/dataSelectors';
import {
    deleteParticipant,
    loadParticipantSectionHeadings,
    loadRecentParticipants
} from '../../store/participantService';
import { hasRole, SUPERUSER } from '../../utils/userRoles';
import Switch from '../formElements/Switch';
import ParticipantStickyMenu from '../navigation/participant/ParticipantStickyMenu';
import AccordionHeader from '../ui/accordion/AccordionHeader';
import AccordionIcon from '../ui/accordion/AccordionIcon';
import PromptModal from '../ui/modals/PromptModal';

import ActionPlan from './actionPlan/ActionPlan';
import Barriers from './barriers/Barriers';
import Employability from './employability/Employability';
import Form from './form/Form';
import ParticipantInformation from './participantInformation/ParticipantInformation';
import Circumstances from './personal/Circumstances';
import HealthInformation from './personal/HealthInformation';
import PrimaryDetails from './primaryDetails/PrimaryDetails';
import Questionnaire from './questionnaire/Questionnaire';
import Wellbeing from './wellbeing/Wellbeing';

import classes from '../../app.module.css';
import editPt from './ptStyles.module.css';

const deleteMsg = `Participant deleted`;
const EditParticipant = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();

    // LOCAL STATE
    const initialState = {
        participantBarriers: false,
        actionPlanGoals: false,
        serviceStandards: false,
        wellbeing: false,
        workRequirements: false,
        workHistory: false,
        education: false,
        submissions: false,
        healthInformation: false,
        circumstances: false,
        employability: false,
        financialRequests: false,
        assetRequests: false
    };
    const deleteRoles = [SUPERUSER];
    const initialModalData = { promptType: '', content: '', formId: '' };
    const [isSection, setIsSection] = useState(initialState);
    const [showPromptModal, setShowPromptModal] = useState(false);
    const [modalData, setModalData] = useState(initialModalData);
    const [auditDisplayFields, setAuditDisplayFields] = useState(false);

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const { currentParticipant, participantSectionHeadings, eligibilityGroupSearch } = useSelector(
        (state) => state.entities.participantService
    );
    const notKnownAttendanceStatusId = useSelector(selectNotKnownStatusId);
    const attendedAttendanceStatusId = useSelector(selectAttendedStatusId);
    const doubtRaisedStatusId = useSelector(selectDoubtRaisedStatusId);
    const sanctionedStatusId = useSelector(selectSanctionedStatusId);
    const failedToAttendStatusId = useSelector(selectFailedToAttendStatusId);
    const { contractDetailsForContractId, locationsForServices } = useSelector(
        (state) => state.entities.directusService
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS
    useEffect(() => {
        const { firstName, lastName, contractId } = currentParticipant;
        dispatch(loadParticipantSectionHeadings(contractId, `${firstName} ${lastName}`));
    }, [currentParticipant]);

    useEffect(() => {
        if (!state) return;
        if (
            state.attendanceStatusId !== notKnownAttendanceStatusId &&
            state.appointmentTypeName === 'Exit Interview' &&
            state.exitFormTemplateId
        ) {
            setModalData({
                promptType: 'Form',
                content:
                    'Your Participant has completed their Exit Interview, please complete the relevant Exit Form',
                formId: state.exitFormTemplateId
            });
            setShowPromptModal(true);
        } else if (
            state.attendanceStatusId === attendedAttendanceStatusId &&
            [doubtRaisedStatusId, sanctionedStatusId].includes(currentParticipant.statusId) &&
            contractDetailsForContractId?.contract_type.name === 'EMPLOYABILITY' &&
            state.complianceActivityFormQuestionnaireId
        ) {
            const appointmentLocation = locationsForServices?.find(
                (location) => location.id === state.appointmentLocationId
            );
            if (!appointmentLocation?.remote) {
                setModalData({
                    promptType: 'Form',
                    content:
                        'Your Participant has completed their Appointment, please complete the relevant Compliance Activity Form Questionnaire',
                    formId: state.complianceActivityFormQuestionnaireId
                });
                setShowPromptModal(true);
            }
        } else if (
            state.attendanceStatusId === failedToAttendStatusId &&
            state.mandatoryAppointment &&
            state.doubtFormId
        ) {
            const eligibilityGroup = eligibilityGroupSearch?.find(
                (el) => el.id === currentParticipant?.eligibilityGroupId
            );
            if (eligibilityGroup?.mandatoryActivity) {
                setModalData({
                    promptType: 'Form',
                    content:
                        'Your Participant has failed to attend a mandatory Appointment. Please complete the Doubt Form',
                    formId: state.doubtFormId
                });
                setShowPromptModal(true);
            }
        }
    }, [state]);

    // TODO: Populate accordions when they are in the Sprint
    useEffect(() => {
        if (Object.keys(participantSectionHeadings).length !== 0) {
            // eslint-disable-next-line no-unused-vars
            const { id, contractId, ...rest } = participantSectionHeadings;
            setIsSection(rest);
        }
    }, [participantSectionHeadings]);

    useEffect(() => {
        if (successMessage === deleteMsg) {
            dispatch(loadRecentParticipants());
            navigate('/home');
        }
    }, [successMessage]);

    // EVENT HANDLERS
    const onModalClose = (e, reason) => {
        if (reason === 'backdropClick') return;
        setShowPromptModal(false);
        setModalData(initialModalData);
        navigate('/mandatory_form', {
            state: { formId: modalData.formId }
        });
    };

    const onDeleteParticipant = () => {
        dispatch(deleteParticipant(currentParticipant.id, deleteMsg));
    };

    const onSwitchAuditDisplayFields = (e) => {
        setAuditDisplayFields(e.target.checked);
    };

    // RENDER
    return (
        <div>
            <div className={classes.withSideBar}>
                <div className={editPt.content}>
                    <div className={editPt.headerContent}>
                        <div className={editPt.headerContentLeft}>
                            <h1 className={classes.mainHeading}>Edit Participant</h1>
                            {hasRole(deleteRoles, roles) && (
                                <div>
                                    <OnDeleteIcon
                                        onDelete={onDeleteParticipant}
                                        roles={roles}
                                        acceptedRoles={deleteRoles}
                                        confirmTwice={true}
                                    />
                                </div>
                            )}
                        </div>
                        <div className={editPt.headerContentRight}>
                            <Switch
                                id={'auditDisplayFields'}
                                label="Show Audit Fields"
                                onOff={true}
                                customClass="auditSwitch"
                                checked={auditDisplayFields}
                                onChange={onSwitchAuditDisplayFields}
                            />
                        </div>
                    </div>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<AccordionIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <AccordionHeader>Primary Details</AccordionHeader>
                        </AccordionSummary>
                        <AccordionDetails>
                            <PrimaryDetails isAuditable={auditDisplayFields} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                        <AccordionSummary
                            expandIcon={<AccordionIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header">
                            <AccordionHeader>Participant Information</AccordionHeader>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ParticipantInformation />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                        <AccordionSummary
                            expandIcon={<AccordionIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header">
                            <AccordionHeader>Personal Information</AccordionHeader>
                        </AccordionSummary>

                        <div hidden={!isSection.healthInformation}>
                            <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                                <AccordionSummary
                                    expandIcon={<AccordionIcon />}
                                    aria-controls="panel3b-content"
                                    id="panel3b-header">
                                    <AccordionHeader>Health Information</AccordionHeader>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <HealthInformation />
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div hidden={!isSection.circumstances}>
                            <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                                <AccordionSummary
                                    expandIcon={<AccordionIcon />}
                                    aria-controls="panel3c-content"
                                    id="panel3c-header">
                                    <AccordionHeader>Circumstances</AccordionHeader>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Circumstances />
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div hidden={!isSection.employability}>
                            <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                                <AccordionSummary
                                    expandIcon={<AccordionIcon />}
                                    aria-controls="panel3d-content"
                                    id="panel3d-header">
                                    <AccordionHeader>Employability</AccordionHeader>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Employability />
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </Accordion>
                    <div hidden={!isSection.participantBarriers}>
                        <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                            <AccordionSummary
                                expandIcon={<AccordionIcon />}
                                aria-controls="panel4a-content"
                                id="panel4a-header">
                                <AccordionHeader>Participant Barriers</AccordionHeader>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Barriers />
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div hidden={!isSection.actionPlanGoals}>
                        <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                            <AccordionSummary
                                expandIcon={<AccordionIcon />}
                                aria-controls="panel5a-content"
                                id="panel5a-header">
                                <AccordionHeader>Action Plan</AccordionHeader>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ActionPlan />
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div hidden={!isSection.serviceStandards}>
                        <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel6a-content"
                                id="panel6a-header">
                                <Typography>Service Standards</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Panel will be be populated with information from PPL-307: Main
                                    PT - Service Standards.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div hidden={!isSection.wellbeing}>
                        <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                            <AccordionSummary
                                expandIcon={<AccordionIcon />}
                                aria-controls="panel7a-content"
                                id="panel7a-header">
                                <AccordionHeader>Wellbeing</AccordionHeader>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Wellbeing />
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                        <AccordionSummary
                            expandIcon={<AccordionIcon />}
                            aria-controls="panel26a-content"
                            id="panel26a-header">
                            <AccordionHeader>Questionnaire</AccordionHeader>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Questionnaire />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                        <AccordionSummary
                            expandIcon={<AccordionIcon />}
                            aria-controls="panel26a-content"
                            id="panel26a-header">
                            <AccordionHeader>Form</AccordionHeader>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Form />
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className={classes.sideBar}>
                    <ParticipantStickyMenu />
                </div>
            </div>
            {showPromptModal && (
                <PromptModal
                    isOpen={showPromptModal}
                    promptType={modalData.promptType}
                    content={modalData.content}
                    onClose={onModalClose}
                />
            )}
        </div>
    );
};

export default EditParticipant;
