import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import {
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../../store/directusService';
import {
    loadParticipantByPtCode,
    loadRecentParticipants,
    saveCurrentParticipantId
} from '../../../store/participantService';

import classes from '../../../commonStyles/tables.module.css';

const CallsDueTableRow = ({ row, showSensitiveCells }) => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const [loaded, setLoaded] = useState(false);

    // STORE STATE
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );

    // USE EFFECTS
    useEffect(() => {
        if (currentParticipant.ptCode === row.ptCode && loaded) {
            dispatch(loadContractDetailsForContractId(currentParticipant.contractId));
            dispatch(loadServiceDetailsForContractId(currentParticipant.contractId));
            setLoaded(false);
            navigate('/edit_participant');
            saveCurrentParticipantId(currentParticipant.id);
            dispatch(loadRecentParticipants());
        }
    }, [currentParticipant]);

    // EVENT HANDLERS
    const onNav = () => {
        dispatch(loadParticipantByPtCode(row.ptCode));
        setLoaded(true);
    };

    // RENDER
    return (
        <TableRow key={row.id} hover className={`${classes.basicRowStripes}`}>
            <TableCell className={classes.rowLink} onClick={onNav}>
                {row?.ptCode}
            </TableCell>
            {showSensitiveCells && <TableCell>{row?.firstName}</TableCell>}
            {showSensitiveCells && <TableCell>{row?.lastName}</TableCell>}
            {showSensitiveCells && <TableCell>{row?.adviser}</TableCell>}
        </TableRow>
    );
};

export default CallsDueTableRow;

CallsDueTableRow.propTypes = {
    row: PropTypes.object,
    showSensitiveCells: PropTypes.bool.isRequired
};
