import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createCourseTemplate } from '../../../store/courseService';
import {
    clearCourseTemplateHealth,
    loadBarrierNameDetailsByContract,
    loadContractDetails,
    loadCourseTemplateHealthDetails
} from '../../../store/directusService';
import { getExistsMessage } from '../../../utils/formValidation/errorMessageFunctions';
import {
    CONTRACT_DETAILS_ERROR,
    COURSE_TEMPLATE_HEALTH_DETAILS_ERROR
} from '../../../utils/formValidation/loadingErrorMessageConstants';
import { getEmptyErrorState } from '../../../utils/formValidation/validator';
import { clearKeys } from '../../../utils/objectUtils';
import { hasRole, LOCAL_ADMIN, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import MultiSelect from '../../formElements/MultiSelect';
import RadioButtons from '../../formElements/RadioButtons';
import SingleSelect from '../../formElements/SingleSelect';
import TextAreaField from '../../formElements/TextAreaField';
import TextInputField from '../../formElements/TextInputField';
import FormHeader from '../../layout/FormHeader';
import FormActions from '../../ui/formActions/FormActions';
import LoadingSpinner from '../../ui/LoadingSpinner';
import SmartDefs from '../../ui/notices/smartDefs/SmartDefs';
import ModuleTemplateManagement from '../modules/moduleTemplate/ModuleTemplateManagement';

import { courseTemplateValidationFields } from './CourseTemplateValidationFields';
import { checkCourseName, validateCourseTemplate } from './validateCourseTemplate';

import app from '../../../app.module.css';
import form from '../../../commonStyles/formStyles.module.css';

const CHARACTER_LIMIT = 250;
const ACTION_DESCRIPTION_LIMIT = 750;

const CreateCourseTemplate = () => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const validationFields = { ...courseTemplateValidationFields };
    const acceptedRoles = [QUALITY, SUPERUSER, LOCAL_ADMIN];
    const initialState = {
        name: '',
        description: '',
        active: true,
        contractIds: [],
        healthInformationCaptured: false,
        healthInformationIds: [],
        modularCourse: false,
        modulesInSetOrder: false,
        moduleTemplates: []
    };
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(getEmptyErrorState(validationFields));
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [arrayContracts, setArrayContracts] = useState([]);
    const [arrayHealth, setArrayHealth] = useState([]);
    const [singleModule, setSingleModule] = useState({
        name: '',
        description: '',
        smartTarget: null
    });
    const [keys, setKeys] = useState({
        contracts: '0',
        healthInformation: '1',
        smartTargetBarrierName: '2'
    });

    // STORE STATE
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const contractDetails = useSelector((state) => state.entities.directusService.contractDetails);
    const courseTemplateHealthDetails = useSelector(
        (state) => state.entities.directusService.courseTemplateHealthDetails
    );
    const barrierNameDetails = useSelector(
        (state) => state.entities.directusService.barrierNameDetails
    );

    // USE EFFECTS
    useEffect(() => {
        contractDetails?.length < 1 && dispatch(loadContractDetails());
        courseTemplateHealthDetails?.length < 1 && dispatch(loadCourseTemplateHealthDetails());
    }, []);

    useEffect(() => {
        if (contractDetails?.length && arrayContracts?.length === 0) {
            setArrayContracts(contractDetails);
        }
    }, [contractDetails]);

    useEffect(() => {
        if (courseTemplateHealthDetails?.length && arrayHealth?.length === 0) {
            setArrayHealth(courseTemplateHealthDetails);
        }
    }, [courseTemplateHealthDetails]);

    useEffect(() => {
        setSubmitButtonDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    useEffect(() => {
        if (successMessage === `Course template details for ${newEntry.name} have been added`) {
            setSubmitButtonDisabled(false);
            onNavigate();
        }
    }, [successMessage]);

    // HELPER FNS
    const clearData = () => {
        setNewEntry(initialState);
        dispatch(clearCourseTemplateHealth());
        setKeys(clearKeys(keys));
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    const loadingError = () => {
        if (contractDetails?.length < 1) return CONTRACT_DETAILS_ERROR;
        if (courseTemplateHealthDetails?.length < 1) return COURSE_TEMPLATE_HEALTH_DETAILS_ERROR;
    };

    // EVENT HANDLERS

    const onUpdate = (key, value, error) => {
        const errorKey = error ?? key;
        errorKey in errors && errors[errorKey].error && clearError(errorKey);
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onContractChange = (chosenIds) => {
        clearError('contractIds');
        if (!chosenIds) chosenIds = [];
        setNewEntry((prev) => ({ ...prev, contractIds: chosenIds }));
        if (chosenIds.length !== 0) {
            dispatch(loadBarrierNameDetailsByContract(chosenIds));
        }
        if (!newEntry.modularCourse && singleModule.smartTarget) {
            setSingleModule((prev) => ({
                ...prev,
                smartTarget: { ...prev.smartTarget, barrierNameId: '' }
            }));
            setKeys((prev) => ({ ...prev, smartTargetBarrierName: Math.random() }));
        }
    };

    const onHealthInformationCaptured = (option) => {
        clearError('healthInformationIds');
        setNewEntry((prev) => ({
            ...prev,
            healthInformationCaptured: option,
            healthInformationIds: option ? [] : prev.healthInformationIds
        }));
    };

    const onHealthChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        if (chosenIds.length !== 0) clearError('healthInformationIds');
        setNewEntry((prev) => ({ ...prev, healthInformationIds: chosenIds }));
    };

    const onMultipleChange = (option) => {
        if (option) {
            clearError('moduleName');
            clearError('moduleDescription');
        }
        setNewEntry((prev) => ({ ...prev, modularCourse: option }));
    };

    const onSingleModuleChange = (key, value, errorKey) => {
        errorKey && clearError(errorKey);
        setSingleModule((prev) => ({ ...prev, [key]: value }));
    };

    const onSmartTargetFlagChange = (option) => {
        setSingleModule((prev) => ({
            ...prev,
            smartTarget: option ? { name: '', barrierNameId: '', action: '' } : null
        }));
        if (!option) {
            clearError('smartTargetActionName');
            clearError('smartTargetBarrierName');
            clearError('smartTargetActionDescription');
        }
    };

    const onSmartTargetChange = (key, value, errorKey) => {
        clearError(errorKey);
        setSingleModule((prev) => ({
            ...prev,
            smartTarget: { ...prev.smartTarget, [key]: value }
        }));
    };

    const onNavigate = () => {
        clearData();
        navigate('/course_management', { state: { accordionPanel: 'templates' } });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const validation = validateCourseTemplate(newEntry, singleModule);
        setErrors(validation.errors);
        if (!validation.isValid) return;

        const isExistingCourseName = await checkCourseName(newEntry.name);
        if (isExistingCourseName) {
            setErrors({
                ...errors,
                name: { error: true, message: getExistsMessage(newEntry.name) }
            });
            return;
        }
        if (newEntry.modularCourse) {
            const entries = newEntry.moduleTemplates
                .filter((el) => el.id)
                .map((el) => ({ ...el, id: null }));
            dispatch(
                createCourseTemplate(newEntry.name, {
                    ...newEntry,
                    moduleTemplates: entries
                })
            );
        } else {
            dispatch(
                createCourseTemplate(newEntry.name, {
                    ...newEntry,
                    moduleTemplates: [singleModule]
                })
            );
        }
    };

    // RENDER
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} />;

    return (
        <div className={form.formWrapper}>
            <form className={form.form} onSubmit={onSubmit} data-testid="form_start">
                <FormHeader text={'Create Course Template'}></FormHeader>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'name'}
                            label={'Course Name'}
                            placeholder={'Enter course name'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            value={newEntry.name || ''}
                            error={errors.name}
                            onChange={(e) => onUpdate('name', e.target.value)}
                        />
                        <TextAreaField
                            id={'description'}
                            label={'Course Description'}
                            placeholder={'Enter course description'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            maxLength={CHARACTER_LIMIT}
                            value={newEntry.description || ''}
                            count={`${newEntry.description.length}/${CHARACTER_LIMIT}`}
                            error={errors.description}
                            onChange={(e) => onUpdate('description', e.target.value)}
                        />
                        <RadioButtons
                            id={'activeTemplateRadio'}
                            label={'Is Current Template Active?'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={newEntry.active}
                            onChange={(option) => onUpdate('active', option)}
                        />

                        <MultiSelect
                            id="contractIds"
                            key={keys.contracts}
                            label="Available to which Contracts"
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            placeholder="Contracts"
                            menuItems={contractDetails || []}
                            preSelectedIds={newEntry.contractIds}
                            preSelects={contractDetails.filter((el) =>
                                newEntry.contractIds.includes(el.id)
                            )}
                            error={errors.contractIds}
                            onChange={(chosenIds) => onContractChange(chosenIds)}
                        />
                        <RadioButtons
                            id={'healthInformationRadio'}
                            label={'Is Health Information Captured?'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={newEntry.healthInformationCaptured}
                            onChange={onHealthInformationCaptured}
                        />
                        <div hidden={!newEntry.healthInformationCaptured}>
                            <MultiSelect
                                id="healthInformationIds"
                                key={keys.healthInformation}
                                label="Health Details"
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                placeholder="Health"
                                menuItems={courseTemplateHealthDetails || []}
                                preSelectedIds={newEntry.healthInformationIds}
                                preSelects={courseTemplateHealthDetails.filter((el) =>
                                    newEntry.healthInformationIds.includes(el.id)
                                )}
                                error={errors.healthInformationIds}
                                onChange={(chosenIds) => onHealthChange(chosenIds)}
                            />
                        </div>
                    </div>
                    <div className={form.formColumn}>
                        <h2 className={app.sectionHeading}>Module Details:</h2>
                        <RadioButtons
                            id={'multipleModulesRadio'}
                            label={'Does this Course have Multiple Modules?'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={newEntry.modularCourse}
                            onChange={onMultipleChange}
                        />
                        <div hidden={newEntry.modularCourse}>
                            <TextInputField
                                id={'moduleName'}
                                label={'Module Name'}
                                placeholder={'Enter module name'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                value={singleModule.name || ''}
                                error={errors.moduleName}
                                onChange={(e) =>
                                    onSingleModuleChange('name', e.target.value, 'moduleName')
                                }
                            />
                            <TextAreaField
                                id={'moduleDescription'}
                                label={'Module Description'}
                                placeholder={'Enter module description'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                maxLength={CHARACTER_LIMIT}
                                value={singleModule.description}
                                count={`${singleModule.description.length}/${CHARACTER_LIMIT}`}
                                error={errors.moduleDescription}
                                onChange={(e) =>
                                    onSingleModuleChange(
                                        'description',
                                        e.target.value,
                                        'moduleDescription'
                                    )
                                }
                            />
                            <RadioButtons
                                id={'smartTargetRadio'}
                                label={'Add Smart Target?'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                value={!!singleModule.smartTarget}
                                onChange={onSmartTargetFlagChange}
                            />

                            <div hidden={!singleModule.smartTarget}>
                                <h2 className={app.sectionHeading}>Smart Target Details:</h2>
                                <TextInputField
                                    id={'smartTargetActionName'}
                                    label={'What shall we call this action?'}
                                    placeholder={'Enter the name of the action'}
                                    mandatory={true}
                                    value={singleModule.smartTarget?.name || ''}
                                    error={errors.smartTargetActionName}
                                    onChange={(e) =>
                                        onSmartTargetChange(
                                            'name',
                                            e.target.value,
                                            'smartTargetActionName'
                                        )
                                    }
                                />
                                <SingleSelect
                                    id={'smartTargetBarrierName'}
                                    key={keys.smartTargetBarrierName}
                                    label={'Which barrier name aligns to this smart target?'}
                                    placeholder={'Select barrier'}
                                    disabled={newEntry.contractIds.length === 0}
                                    mandatory={true}
                                    menuItems={barrierNameDetails || []}
                                    selectedId={singleModule.smartTarget?.barrierNameId || ''}
                                    selected={
                                        barrierNameDetails.find(
                                            (el) =>
                                                el.id === singleModule.smartTarget?.barrierNameId
                                        ) || {}
                                    }
                                    error={errors.smartTargetBarrierName}
                                    onChange={(chosenId) =>
                                        onSmartTargetChange(
                                            'barrierNameId',
                                            chosenId,
                                            'smartTargetBarrierName'
                                        )
                                    }
                                />
                                <SmartDefs />
                                <TextAreaField
                                    id={'smartTargetActionDescription'}
                                    label={'Action'}
                                    placeholder={'Enter action description'}
                                    mandatory={true}
                                    maxLength={ACTION_DESCRIPTION_LIMIT}
                                    value={singleModule.smartTarget?.action || ''}
                                    count={`${singleModule.smartTarget?.action.length}/${ACTION_DESCRIPTION_LIMIT}`}
                                    error={errors.smartTargetActionDescription}
                                    onChange={(e) =>
                                        onSmartTargetChange(
                                            'action',
                                            e.target.value,
                                            'smartTargetActionDescription'
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div hidden={!newEntry.modularCourse}>
                            <RadioButtons
                                id="modulesInSetOrder"
                                label="Do the Modules Need to be in a Set Order?"
                                disabled={!hasRole(acceptedRoles, roles)}
                                value={newEntry.modulesInSetOrder || false}
                                onChange={(option) => onUpdate('modulesInSetOrder', option)}
                            />

                            <ModuleTemplateManagement
                                moduleTemplates={newEntry.moduleTemplates}
                                barrierNameDetails={barrierNameDetails || []}
                                roles={roles}
                                acceptedRoles={acceptedRoles}
                                onUpdate={onUpdate}
                                error={errors.modules}
                                onClearError={clearError}
                            />
                        </div>
                    </div>
                </div>
                <FormActions
                    id="publish"
                    btnText="Submit Template"
                    disabled={submitButtonDisabled}
                    onClose={onSubmit}
                    onCancel={onNavigate}
                />
            </form>
        </div>
    );
};

export default CreateCourseTemplate;
