import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import { setHeader, setURLs } from '../../api/commonHTTP';
import { loadCalendarServiceVersion } from '../../store/calendarService';
import { loadCommunicationServiceVersion } from '../../store/communicationService';
import { loadCourseServiceVersion } from '../../store/courseService';
import { loadDirectusServiceVersion } from '../../store/directusService';
import { loadDocumentServiceVersion } from '../../store/documentService';
import { loadParticipantServiceVersion } from '../../store/participantService';
import { loadRecruitmentServiceVersion } from '../../store/recruitmentService';
import { loadUserAttributes, loadUserServiceVersion, setIdToken } from '../../store/userService';
import {
    POLARIS_BLUE,
    POLARIS_DOUBLE_DECKER_RED,
    POLARIS_GREEN,
    POLARIS_MIKADO_YELLOW,
    POLARIS_ORANGE,
    POLARIS_TOTTENHAM_TEAL,
    POLARIS_WHITE
} from '../../themes/theme';

import classes from './versionPill.module.css';

/**
 * Shown near top right of Header
 * This shows the current environment
 *
 * An index to the current environment is currently saved in UserService
 *
 * Possible environments are defined in 'environment'
 *
 *
 */

const appVersion = import.meta.env.VITE_APP_VERSION;

const environment = [
    {
        name: 'DEV',
        text: 'Development',
        backgroundColor: POLARIS_TOTTENHAM_TEAL,
        color: POLARIS_WHITE,
        calendarUrl: 'http://localhost:9000/calendar-service/',
        communicationUrl: 'http://localhost:9000/communication-service/',
        courseUrl: 'http://localhost:9000/course-service/',
        directusUrl: 'http://localhost:9000/directus-service/',
        documentUrl: 'http://localhost:9000/document-service/',
        participantUrl: 'http://localhost:9000/participant-service/',
        recruitmentUrl: 'http://localhost:9000/recruitment-service/',
        userUrl: 'http://localhost:9000/user-service/',
        sprint: import.meta.env.VITE_APP_SPRINT_NUMBER
    },
    {
        name: 'PROD',
        text: 'Production',
        backgroundColor: POLARIS_WHITE,
        color: POLARIS_BLUE,
        baseUrl: 'https://prod.polaris.reedinpartnership.co.uk/',
        calendarUrl: 'services/calendar/',
        communicationUrl: 'services/communication/',
        courseUrl: 'services/courses/',
        directusUrl: 'services/directus/',
        documentUrl: 'services/documents/',
        participantUrl: 'services/participants/',
        recruitmentUrl: 'services/recruitment/',
        userUrl: 'services/users/'
    },
    {
        name: 'TEST',
        text: 'Test',
        backgroundColor: POLARIS_ORANGE,
        color: POLARIS_WHITE,
        baseUrl: 'https://test.polaris.reedinpartnership.co.uk/',
        calendarUrl: 'services/calendar/',
        communicationUrl: 'services/communication/',
        courseUrl: 'services/courses/',
        directusUrl: 'services/directus/',
        documentUrl: 'services/documents/',
        participantUrl: 'services/participants/',
        recruitmentUrl: 'services/recruitment/',
        userUrl: 'services/users/',
        sprint: import.meta.env.VITE_APP_SPRINT_NUMBER
    },
    {
        name: 'UAT',
        text: 'UAT',
        backgroundColor: POLARIS_GREEN,
        color: POLARIS_WHITE,
        baseUrl: 'https://uat.polaris.reedinpartnership.co.uk/',
        calendarUrl: 'services/calendar/',
        communicationUrl: 'services/communication/',
        courseUrl: 'services/courses/',
        directusUrl: 'services/directus/',
        documentUrl: 'services/documents/',
        participantUrl: 'services/participants/',
        recruitmentUrl: 'services/recruitment/',
        userUrl: 'services/users/',
        sprint: import.meta.env.VITE_APP_SPRINT_NUMBER
    },
    {
        name: 'SANDBOX',
        text: 'Sandbox',
        backgroundColor: POLARIS_MIKADO_YELLOW,
        color: POLARIS_BLUE,
        calendarUrl: import.meta.env.VITE_APP_CALENDAR_SERVICE_URL,
        communicationUrl: import.meta.env.VITE_APP_COMMUNICATION_SERVICE_URL,
        courseUrl: import.meta.env.VITE_APP_COURSE_SERVICE_URL,
        directusUrl: import.meta.env.VITE_APP_DIRECTUS_SERVICE_URL,
        documentUrl: import.meta.env.VITE_APP_DOCUMENT_SERVICE_URL,
        participantUrl: import.meta.env.VITE_APP_PARTICIPANT_SERVICE_URL,
        recruitmentUrl: import.meta.env.VITE_APP_RECRUITMENT_SERVICE_URL,
        userUrl: import.meta.env.VITE_APP_USER_SERVICE_URL,
        sprint: import.meta.env.VITE_APP_SPRINT_NUMBER
    },
    {
        name: 'TRN',
        text: 'Training',
        backgroundColor: POLARIS_DOUBLE_DECKER_RED,
        color: POLARIS_WHITE,
        baseUrl: 'https://trn.polaris.reedinpartnership.co.uk/',
        calendarUrl: 'services/calendar/',
        communicationUrl: 'services/communication/',
        courseUrl: 'services/courses/',
        directusUrl: 'services/directus/',
        documentUrl: 'services/documents/',
        participantUrl: 'services/participants/',
        recruitmentUrl: 'services/recruitment/',
        userUrl: 'services/users/'
    }
];

const General = () => {
    const { instance } = useMsal();
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [versionText, setVersionText] = useState('Unknown');

    const userVersion = useSelector((state) => state.entities.userService.version);
    const calendarVersion = useSelector((state) => state.entities.calendarService.version);
    const communicationVersion = useSelector(
        (state) => state.entities.communicationService.version
    );
    const courseVersion = useSelector((state) => state.entities.courseService.version);
    const directusVersion = useSelector((state) => state.entities.directusService.version);
    const documentVersion = useSelector((state) => state.entities.documentService.version);
    const participantVersion = useSelector((state) => state.entities.participantService.version);
    const recruitmentVersion = useSelector((state) => state.entities.recruitmentService.version);

    const environmentIndex = useSelector(
        (state) => state.entities.userService.loggedInUser.environment
    );
    const { accessToken, idToken } = useSelector((state) => state.entities.userService);

    setURLs(environment[environmentIndex]);

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadUserServiceVersion());
        dispatch(loadCalendarServiceVersion());
        dispatch(loadCommunicationServiceVersion());
        dispatch(loadCourseServiceVersion());
        dispatch(loadDirectusServiceVersion());
        dispatch(loadDocumentServiceVersion());
        dispatch(loadParticipantServiceVersion());
        dispatch(loadRecruitmentServiceVersion());

        const sprint = environment[environmentIndex].sprint
            ? `r${environment[environmentIndex].sprint}`
            : '';
        setVersionText(`${environment[environmentIndex].text} - ${appVersion}${sprint}`);
    }, []);

    useEffect(() => {
        setHeaderToken(idToken || accessToken);

        if (!idToken) {
            dispatch(setIdToken(accessToken));
            refreshToken();
        }
        !idToken && dispatch(setIdToken(accessToken));
        !idToken && refreshToken();
    }, [idToken, accessToken]);

    useEffect(() => {
        idToken && dispatch(loadUserAttributes());
    }, [idToken]);

    const setHeaderToken = (token) => {
        setHeader({
            Authorization: `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
        });
    };

    const refreshToken = () => {
        const request = {
            scopes: []
        };

        instance
            .acquireTokenSilent(request)
            .then((tokenResponse) => {
                setHeaderToken(tokenResponse.idToken);
            })
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return instance.acquireTokenRedirect(request);
                }
            });
    };

    // RENDER FUNCTION
    const pill = (children) => (
        <div
            className={classes.versionPill}
            style={{
                backgroundColor: environment[environmentIndex].backgroundColor,
                color: environment[environmentIndex].color
            }}>
            {children}
        </div>
    );

    // RENDER
    return pill(
        <>
            <div className={classes.versionText} onClick={() => setShowList((prev) => !prev)}>
                {versionText}
            </div>
            {showList && (
                <div
                    className={classes.pillListContainer}
                    onClick={() => setShowList((prev) => !prev)}>
                    {pill(`User Service - ${userVersion.version || 'Unknown'}`)}
                    {pill(`Calendar Service - ${calendarVersion.version || 'Unknown'}`)}
                    {pill(`Communication Service - ${communicationVersion.version || 'Unknown'}`)}
                    {pill(`Course Service - ${courseVersion.version || 'Unknown'}`)}
                    {pill(`Directus Service - ${directusVersion.version || 'Unknown'}`)}
                    {pill(`Document Service - ${documentVersion.version || 'Unknown'}`)}
                    {pill(`Participant Service - ${participantVersion.version || 'Unknown'}`)}
                    {pill(`Recruitment Service - ${recruitmentVersion.version || 'Unknown'}`)}
                </div>
            )}
        </>
    );
};
export default General;
