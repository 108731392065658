import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';

import { formatDate, formatTime } from '../../../utils/dateFunctions';
import LoadingSpinner from '../../ui/LoadingSpinner';

import AuditAppointmentTable from './AuditAppointmentTable';

const AuditAppointmentManagement = ({ auditEventHistory = [] }) => {
    // LOCAL STATE
    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (auditEventHistory?.length < 1) return;
        const updatedRows = auditEventHistory.map((el) => ({
            ...el,
            id: uuid(),
            user: el.user || 'Polaris System',
            formattedDate: formatDate(el.date),
            formattedTime: formatTime(el.date)
        }));
        setRows(updatedRows);
    }, [auditEventHistory]);

    // RENDER
    return rows.length < 1 ? (
        <LoadingSpinner content="No audit event history found" time={1} />
    ) : (
        <AuditAppointmentTable rows={rows} />
    );
};

AuditAppointmentManagement.propTypes = {
    auditEventHistory: PropTypes.array
};

export default AuditAppointmentManagement;
