import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';

import {
    loadAppointmentAttendanceDetails,
    searchParticipantAppointments
} from '../../../store/calendarService';
import { loadAttendanceDetails, loadServiceDetailsByService } from '../../../store/directusService';
import { selectCurrentForm, selectCurrentParticipant } from '../../../store/participantSelectors';
import {
    createFormParticipant,
    loadForm,
    loadServiceConstants
} from '../../../store/participantService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { selectCurrentUser } from '../../../store/userSelectors';
import { loadUserForForms, loadUsersForForms } from '../../../store/userService';
import GenerateUCD603DoubtFormPDF from '../../pdfGenerator/GenerateUCD603DoubtFormPDF';
import LoadingSpinner from '../../ui/LoadingSpinner';

import { themeJson } from './FormTheme';

import form from '../../../commonStyles/formStyles.module.css';

const ConductUCD603DoubtForm = ({ formId, onClose }) => {
    //HOOKS
    const dispatch = useDispatch();

    //LOCAL STATE
    const [surveyModel, setSurveyModel] = useState(new Model('{}'));
    const [surveyComplete, setSurveyComplete] = useState(false);
    const [userName, setUserName] = useState('');
    const [generatePdf, setGeneratePdf] = useState(false);
    const [isSucceeded, setIsSucceeded] = useState(false);
    const [formComplete, setFormComplete] = useState(false);
    const [populatedUserName, setPopulatedUserName] = useState(false);

    //STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const currentForm = useSelector(selectCurrentForm);
    const currentUser = useSelector(selectCurrentUser);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const participantAppointments = useSelector(
        (state) => state.entities.calendarService.participantAppointments
    );
    const appointmentAttendanceDetails = useSelector(
        (state) => state.entities.calendarService.appointmentAttendanceDetails
    );
    const serviceConstants = useSelector(
        (state) => state.entities.participantService.serviceConstants
    );

    const { attendanceDetails, formStatusDetails, serviceDetails } = useSelector(
        (state) => state.entities.directusService
    );
    const usersForForms = useSelector((state) => state.entities.userService.usersForForms);
    const loggedInUser = useSelector(selectLoggedInUser);

    // USE EFFECTS
    useEffect(() => {
        attendanceDetails.length < 1 && dispatch(loadAttendanceDetails());
    }, []);

    useEffect(() => {
        if (!('serviceIds' in loggedInUser)) return;
        if (loggedInUser.serviceIds.length < 1) return;
        if (!usersForForms || usersForForms?.length < 1)
            dispatch(loadUsersForForms(loggedInUser.serviceIds));
    }, [loggedInUser.id]);

    useEffect(() => {
        if (!currentForm) dispatch(loadForm(formId));
    }, [formId]);

    useEffect(() => {
        if (usersForForms && usersForForms.length) {
            const user = usersForForms.find((el) => el.id === currentParticipant.userId);
            if (user) {
                setPopulatedUserName(true);
                setUserName(`${user.firstName} ${user.lastName}`);
            } else if (currentParticipant.userId)
                dispatch(loadUserForForms(currentParticipant.userId));
        } else if (currentParticipant.userId) dispatch(loadUserForForms(currentParticipant.userId));

        dispatch(loadAppointmentAttendanceDetails(currentParticipant.id));
        dispatch(searchParticipantAppointments(currentParticipant.id));
        dispatch(loadServiceConstants(currentParticipant.serviceId));
        dispatch(loadServiceDetailsByService([currentParticipant.serviceId]));
    }, [currentParticipant]);

    useEffect(() => {
        if (
            Object.keys(currentForm)?.length < 1 ||
            Object.keys(appointmentAttendanceDetails)?.length < 1 ||
            Object.keys(participantAppointments)?.length < 1 ||
            Object.keys(serviceConstants)?.length < 1 ||
            attendanceDetails?.length < 1 ||
            serviceDetails?.length < 1 ||
            formComplete ||
            !populatedUserName
        )
            return;

        const model = new Model(currentForm.data);
        model.applyTheme(themeJson);

        model.setValue('Q_1', `${currentParticipant?.firstName} ${currentParticipant?.lastName}`);
        model.setValue('Q_2', currentParticipant?.dateOfBirth);

        const failedToAttendAppointments = appointmentAttendanceDetails
            .filter((el) => el.status.toLowerCase() === 'failed to attend')
            .sort((a, b) => a.date + a.startTime.trim().localeCompare(b.date + b.startTime.trim()))
            .reverse();

        const mandatoryAppointmentMissed = failedToAttendAppointments.filter((el) =>
            participantAppointments.find(
                (entry) => entry.id === el.appointmentId && entry.mandatory === true
            )
        );
        const nonMandatoryAppointmentMissed = failedToAttendAppointments.filter((el) =>
            participantAppointments.find(
                (entry) =>
                    entry.id === el.appointmentId &&
                    entry.mandatory === false &&
                    entry.date + entry.startTime <
                        mandatoryAppointmentMissed[0].date + mandatoryAppointmentMissed[0].startTime
            )
        );
        if (nonMandatoryAppointmentMissed.length) {
            const q5date = new Date(
                `${nonMandatoryAppointmentMissed[0].date}T${nonMandatoryAppointmentMissed[0].startTime}:00`
            );
            const formattedQ5Date = format(q5date, 'dd/MM/yyyy');
            model.setValue('Q_5', formattedQ5Date);
        }
        const q8date = new Date(
            `${mandatoryAppointmentMissed[0].date}T${mandatoryAppointmentMissed[0].startTime}:00`
        );
        const formattedQ8DateTime = format(q8date, 'dd/MM/yyyy HH:mm');
        model.setValue('Q_8', formattedQ8DateTime);

        const formattedAddressFromCurrentParticipant = [
            currentParticipant.address1,
            currentParticipant.address2,
            currentParticipant.address3,
            currentParticipant.city,
            currentParticipant.postcode
        ].filter((el) => el);

        model.setValue('Q_7', formattedAddressFromCurrentParticipant.join('\n'));
        model.setValue('Q_20', userName);

        const constantsForService = serviceConstants[currentParticipant.serviceId];
        model.setValue('Q_22', constantsForService.sanctionFormFromEmailAddress);

        const formattedAddressFromServiceConstants = [
            constantsForService.serviceAddressLine1,
            constantsForService.serviceAddressLine2,
            constantsForService.serviceAddressLine3,
            constantsForService.serviceAddressLine4,
            constantsForService.serviceCity,
            constantsForService.serviceCounty,
            constantsForService.servicePostcode
        ].filter((el) => el);

        model.setValue('Q_23', formattedAddressFromServiceConstants.join('\n'));
        model.setValue('Q_25', serviceDetails[0].name);

        model.onComplete.add((sender, options) => {
            console.log('options', options);
            console.log(JSON.stringify(sender.data, null, 3));
        });

        setSurveyModel(model);
        setFormComplete(true);
    }, [
        currentForm,
        appointmentAttendanceDetails,
        participantAppointments,
        attendanceDetails,
        populatedUserName,
        serviceConstants,
        serviceDetails
    ]);

    useEffect(() => {
        if (surveyComplete) {
            const payload = {
                participantId: currentParticipant.id,
                statusId: formStatusDetails.find((el) => el.name === 'Open')?.id || '',
                pdfName: currentForm.name,
                formId: currentForm.id,
                data: surveyModel.data
            };
            dispatch(createFormParticipant(payload));
        }
    }, [surveyComplete]);

    useEffect(() => {
        if (successMessage === `Users for forms loaded`) {
            setPopulatedUserName(true);
            const user = usersForForms.find((el) => el.id === currentParticipant.userId);
            user ? setUserName(`${user.firstName} ${user.lastName}`) : setUserName('');
        } else if (successMessage === `Form participant has been created successfully`)
            setGeneratePdf(true);
    }, [successMessage]);

    // HELPER FNS
    const loadingError = () => {
        if (Object.keys(currentForm)?.length < 1) return 'No current form found';
        if (Object.keys(appointmentAttendanceDetails)?.length < 1)
            return 'No appointment attendance details found';
        if (Object.keys(serviceConstants)?.length < 1) return 'No service constants found';
        if (attendanceDetails?.length < 1) return 'No attendance details found';
        if (serviceDetails?.length < 1) return 'No service details found';
    };

    // EVENT HANDLERS
    const onSurveyComplete = () => {
        setSurveyComplete(true);
    };

    const onFormExit = () => {
        setSurveyComplete(false);
        onClose();
    };

    // EVENT HANDLER CALLBACKS
    const onPdfGenerated = (isPdfGenerated) => {
        setIsSucceeded(isPdfGenerated);
        setGeneratePdf(false);
        onFormExit();
    };

    // AWAITING CONTENT
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} />;

    // RENDER
    return (
        <div>
            <div>
                {surveyModel ? (
                    <Survey model={surveyModel} onComplete={onSurveyComplete} />
                ) : (
                    <div>
                        <LoadingSpinner />
                    </div>
                )}
            </div>
            {generatePdf && (
                <GenerateUCD603DoubtFormPDF
                    surveyModel={surveyModel}
                    form={currentForm}
                    row={currentForm}
                    currentParticipant={currentParticipant}
                    currentUser={currentUser}
                    onPdfGenerated={onPdfGenerated}
                />
            )}
            {isSucceeded && (
                <div className={form.successMessage}>PDF {currentForm.name} downloaded</div>
            )}
        </div>
    );
};

ConductUCD603DoubtForm.propTypes = {
    formId: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string)
};

export default ConductUCD603DoubtForm;
