import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import OnDeleteIcon from '../../../../icons/OnDeleteIcon';
import OnEditIcon from '../../../../icons/OnEditIcon';
import {
    clearPoolApplicationsForOwners,
    clearPoolsForOwners,
    deleteVacancy,
    loadBusinessRecord,
    setCurrentVacancy
} from '../../../../store/recruitmentService';
import { POLARIS_ROYAL_BLUE } from '../../../../themes/theme';
import { getNameFromId } from '../../../../utils/directusFunctions';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';

import classes from '../../../../commonStyles/tables.module.css';
import vacancyClasses from '../../recruitmentStyles/vacancy.module.css';

const VacancyTableRow = ({ row, roles, onVacancyDeleted, onVacancyCount }) => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const acceptedRoles = [SUPERUSER, MANAGER, QUALITY, RECRUITMENT_MANAGER];

    // STORE STATE
    const jobSectorDetails = useSelector(
        (state) => state.entities.directusService.jobSectorDetails
    );

    // EVENT HANDLERS
    function onManageVacancy() {
        dispatch(clearPoolsForOwners());
        dispatch(clearPoolApplicationsForOwners());
        dispatch(loadBusinessRecord(row.businessRecordId));
        dispatch(setCurrentVacancy(row));

        navigate('/vacancy_management', {
            state: {
                accordionPanel: 'vacancyDetails',
                accordionPanelFrom: 'vacancies',
                vacancy: row
            }
        });
    }

    const onDeleteVacancy = () => {
        dispatch(deleteVacancy(row.id));
        onVacancyDeleted(false);
        onVacancyCount(0);
    };

    const onEditRow = (row) => {
        dispatch(setCurrentVacancy(row));
        navigate('/edit_vacancy', {
            state: {
                id: row.id,
                accordionPanel: 'vacancies'
            }
        });
    };

    // RENDER
    return (
        <>
            <TableRow key={row.id} hover className={classes.basicRowStripes}>
                <TableCell
                    className={vacancyClasses.vacancyCodeLink}
                    onClick={onManageVacancy}
                    disabled={!hasRole(acceptedRoles, roles)}
                    style={{
                        color: `${POLARIS_ROYAL_BLUE}`,
                        cursor: 'pointer'
                    }}>
                    {row.code}
                </TableCell>
                <TableCell>{row.businessName}</TableCell>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.adviser}</TableCell>
                <TableCell>{row.postcode ? row.postcode : 'Remote'}</TableCell>
                <TableCell>{`${row.numberOfPositions - row.jobsOffered} / ${
                    row.numberOfPositions
                }`}</TableCell>
                <TableCell>{getNameFromId(jobSectorDetails, row.sectorId)}</TableCell>
                <TableCell align="right">
                    <OnEditIcon
                        onEdit={() => onEditRow(row)}
                        roles={roles}
                        acceptedRoles={acceptedRoles}
                    />
                </TableCell>
                <TableCell align="right">
                    <OnDeleteIcon
                        onDelete={onDeleteVacancy}
                        roles={roles}
                        acceptedRoles={acceptedRoles}
                    />
                </TableCell>
            </TableRow>
        </>
    );
};

export default VacancyTableRow;

VacancyTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    onVacancyDeleted: PropTypes.func,
    onVacancyCount: PropTypes.func
};
