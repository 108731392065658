import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../../store/directusService';
import { setCurrentlySelectedParticipant } from '../../../store/participantService';
import StaticField from '../../formElements/StaticField';

const StaticParticipant = ({ participant, onNav }) => {
    // HOOKs
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // STORE STATE
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const contractDetailsById = useSelector(
        (state) => state.entities.directusService.contractDetailsById
    );
    const servicesByContractId = useSelector(
        (state) => state.entities.directusService.servicesByContractId
    );

    // EVENT HANDLERS
    const onParticipantNav = () => {
        if (participant.id !== currentParticipant?.id) {
            dispatch(setCurrentlySelectedParticipant(participant));
            if (!(participant.contractId in contractDetailsById))
                dispatch(loadContractDetailsForContractId(participant.contractId));
            if (!(participant.contractId in servicesByContractId))
                dispatch(loadServiceDetailsForContractId(participant.contractId));
        }
        onNav();
        navigate('/edit_participant');
    };

    //RENDER
    return participant && Object.keys(participant)?.length ? (
        <StaticField
            id={'participantName'}
            label="Participant"
            link={`${participant?.firstName} ${participant?.lastName}`}
            onClick={onParticipantNav}
        />
    ) : (
        <StaticField
            id={'participantName'}
            label="Participant"
            content="No Participant was attached to this Appointment"
        />
    );
};

StaticParticipant.propTypes = {
    participant: PropTypes.object,
    onNav: PropTypes.func
};

export default StaticParticipant;
