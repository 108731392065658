import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { clearGenders, loadGenderDetails } from '../../../store/directusService';
import { loadContractConstants, updateContractConstants } from '../../../store/participantService';
import { compareUnorderedArrays } from '../../../utils/arrayUtils';
import { updatePreSelects, updateSelectedItems } from '../../../utils/directusFunctions';
import { NO_UPDATE_ERROR } from '../../../utils/formValidation/commonErrorConstants';
import { hasRole, LOCAL_ADMIN, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import MultiSelect from '../../formElements/MultiSelect';
import RadioButtons from '../../formElements/RadioButtons';
import NotFound from '../../notFound/NotFound';
import LoadingSpinner from '../../ui/LoadingSpinner';

import { initialErrorState, validate } from './validatePrimaryDetailsAdmin';

const PrimaryDetailsAdmin = ({ contractId }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const viewRoles = [LOCAL_ADMIN, SUPERUSER];
    const acceptedRoles = [SUPERUSER];
    const collectionName = 'gender';
    const urlName = 'gender';
    const [errors, setErrors] = useState(initialErrorState);
    const [selectedGenders, setSelectedGenders] = useState([]);
    const [preSelectedGenderIds, setPreSelectedGenderIds] = useState([]);
    const [preSelectedGenders, setPreSelectedGenders] = useState([]);
    const [isClearSelectedGenders, setIsClearSelectedGenders] = useState('0');
    const [temporaryUniqueIdAvailable, setTemporaryUniqueIdAvailable] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);

    // STORE STATE
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const genderDetails = useSelector((state) => state.entities.directusService.genderDetails);
    const contractConstants = useSelector(
        (state) => state.entities.participantService.contractConstants
    );

    // USE EFFECTS
    useEffect(() => {
        if (!contractId) return;
        contractSetup();
        if (!Object.keys(contractConstants).length || contractConstants.contractId !== contractId) {
            dispatch(loadContractConstants(contractId));
        }
    }, [contractId]);

    useEffect(() => {
        if (genderDetails?.length) {
            const { preSelects, preSelectIds } = updatePreSelects(
                collectionName,
                genderDetails,
                contractId
            );
            setPreSelectedGenders(preSelects);
            setPreSelectedGenderIds(preSelectIds);
        }
    }, [genderDetails]);

    useEffect(() => {
        if (Object.keys(contractConstants).length) {
            setTemporaryUniqueIdAvailable(contractConstants.temporaryUniqueIdAvailable || false);
        }
    }, [contractConstants]);

    useEffect(() => {
        if (
            successMessage.includes('Updating gender collection') ||
            successMessage === 'Contract constants has been updated'
        ) {
            contractSetup();
        }
    }, [successMessage]);

    // HELPER FNS
    const contractSetup = () => {
        setIsDisabled(false);
        setSelectedGenders([]);
        setPreSelectedGenders([]);
        setErrors(initialErrorState);
        setIsClearSelectedGenders(Math.random());
        dispatch(clearGenders());
        dispatch(loadGenderDetails());
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    const loadingError = () => {
        if (!contractId) return 'No contract';
        if (genderDetails?.length < 1) return 'No gender details';
        if (!Object.keys(contractConstants).length) return 'No contract constants';
    };

    // EVENT HANDLERS
    const onGenderChange = (chosenIds) => {
        setIsDisabled(false);
        clearError('genderDetails');
        if (!chosenIds) chosenIds = [];
        setSelectedGenders(chosenIds);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const changesToGender = !compareUnorderedArrays(preSelectedGenderIds, selectedGenders);
        const changeToTemporaryUniqueIdAvailable =
            contractConstants.temporaryUniqueIdAvailable !== temporaryUniqueIdAvailable;
        if (!changesToGender && !changeToTemporaryUniqueIdAvailable) {
            setErrors({ button: { error: true, message: NO_UPDATE_ERROR } });
            return;
        }

        const { isValid, newErrors } = validate(selectedGenders, errors);
        setErrors(newErrors);
        setIsDisabled(true);
        if (!isValid) return;

        changesToGender &&
            updateSelectedItems(
                urlName,
                genderDetails,
                selectedGenders,
                contractId,
                collectionName,
                dispatch
            );
        changeToTemporaryUniqueIdAvailable &&
            dispatch(updateContractConstants({ ...contractConstants, temporaryUniqueIdAvailable }));
    };

    // AWAITING CONTENT
    if (!hasRole(viewRoles, roles)) return <NotFound />;
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} />;

    // RENDER
    return (
        <form onSubmit={onSubmit} data-testid="form_start">
            <p>First Name</p>
            <p>Middle Name</p>
            <p>Last Name</p>
            <p>Preferred Name</p>
            <p>Preferred Pronouns</p>
            <div id="genderDetailsWrapper">
                <MultiSelect
                    id="genderDetails"
                    key={isClearSelectedGenders}
                    label="Gender"
                    placeholder="Gender"
                    disabled={!hasRole(acceptedRoles, roles)}
                    mandatory={true}
                    menuItems={genderDetails || []}
                    preSelectedIds={preSelectedGenderIds}
                    preSelects={preSelectedGenders}
                    error={errors.genderDetails}
                    onChange={(chosenIds) => onGenderChange(chosenIds)}
                />
            </div>
            <RadioButtons
                id="temporaryUniqueIdAvailable"
                label="Temporary Unique ID Available"
                value={temporaryUniqueIdAvailable}
                onChange={(value) => setTemporaryUniqueIdAvailable(value)}
            />
            <p>Date of Birth</p>
            <p>Address line 1</p>
            <p>Address line 2</p>
            <p>Address line 3</p>
            <p>Town/City</p>
            <p>Postcode</p>
            <p>Care of Address</p>
            <p>Primary Phone Number</p>
            <p>Secondary Phone Number</p>
            <p>Email Address</p>
            <p>Unique Identifier</p>
            <p>Eligibility Groups DDL</p>
            <p>Referral Sources DDL</p>
            <p>Referral Date</p>
            <p>Start Date</p>
            <p>Adviser</p>
            <p>Current Status</p>
            <p>Preferred method of contact</p>
            <p>Possible Good News Story?</p>
            <p>&nbsp;</p>
            <Button
                id="primaryDetailsAdmin"
                content="UPDATE PRIMARY DETAILS"
                disabled={!hasRole(acceptedRoles, roles) || isDisabled || errors.button.error}
                error={errors.button}
                clearError={() => clearError('button')}
            />
        </form>
    );
};

PrimaryDetailsAdmin.propTypes = {
    contractId: PropTypes.string
};

export default PrimaryDetailsAdmin;
