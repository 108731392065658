import React from 'react';
import PropTypes from 'prop-types';

import common from './formElementStyles/commonFormElements.module.css';
import local from './formElementStyles/switch.module.css';

const Switch = ({
    id = '',
    label = '',
    leftLabel = '',
    rightLabel = '',
    checked = true,
    onChange,
    disabled = false,
    onOff = false,
    inline = false,
    subLabelClass = '',
    customClass = ''
}) => {
    return (
        <div
            data-testid={`switch-${id}`}
            className={`${common.fieldWrapper} ${inline ? `${common.inline}` : ''} ${customClass ? `${local[customClass]}` : ''}`}>
            <label htmlFor={id} className={`${common.formLabel}`}>
                {label}
            </label>
            <div className={`${local.switchSet} ${inline ? `${local.switchSetInline}` : ''}`}>
                <span className={common[subLabelClass] ?? ''}>{leftLabel}</span>
                <label className={`${local.switch} ${onOff ? `${local.switchOff}` : ''}`}>
                    <input
                        type="checkbox"
                        id={id}
                        data-testid={id}
                        disabled={disabled}
                        checked={checked}
                        onChange={onChange}
                    />
                    <span
                        className={`${local.slider}  ${
                            disabled ? `${local.disabledSwitch}` : ''
                        }`}></span>
                </label>
                <span className={common[subLabelClass] ?? ''}>{rightLabel}</span>
            </div>
        </div>
    );
};

export default Switch;

Switch.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    leftLabel: PropTypes.string,
    rightLabel: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    onOff: PropTypes.bool,
    inline: PropTypes.bool,
    subLabelClass: PropTypes.string,
    customClass: PropTypes.string
};
