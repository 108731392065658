import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Collapse, TableCell, TableRow } from '@mui/material';

import OnFileDownloadIcon from '../../../../icons/OnFileDownloadIcon';
import { downloadParticipantDocument } from '../../../../store/documentService';
import { selectUserName } from '../../../../store/userSelectors';
import { reverseFormatDate } from '../../../../utils/dateFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';

import RejectionDetails from './RejectionDetails';

import classes from '../../../../commonStyles/tables.module.css';

const PoolsTableRow = ({ row, roles, toggleDropdown, openId }) => {
    //HOOKS
    const dispatch = useDispatch();
    const onSelectUserName = useMemo(selectUserName, []);

    // LOCAL STATE
    const acceptedRoles = [ADVISER, SUPERUSER, MANAGER, QUALITY, RECRUITMENT_MANAGER];
    const [open, setOpen] = useState(null);

    // STORE STATE
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const adviser = useSelector((state) => onSelectUserName(state, row.ownerId));
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);

    // USE EFFECTS
    useEffect(() => {
        if (openId !== row.id) setOpen(null);
    }, [openId]);

    // EVENT HANDLERS
    const onDownload = () =>
        dispatch(downloadParticipantDocument(currentParticipant.id, row.filename));

    const onToggleDropdown = () => {
        toggleDropdown(row.id);
        setOpen(open === row.id ? null : row.id);
    };

    // RENDER
    return (
        <>
            <TableRow
                key={row.id}
                hover
                className={classes.basicRowStripes}
                onClick={onToggleDropdown}>
                <TableCell>{row.code}</TableCell>
                <TableCell>{adviser}</TableCell>
                <TableCell>{row.sectorName}</TableCell>
                <TableCell disabled={!hasRole(acceptedRoles, loggedInUser.roles)}>
                    {row.filename}
                </TableCell>
                <TableCell align="center">
                    <OnFileDownloadIcon
                        roles={roles}
                        acceptedRoles={acceptedRoles}
                        active={hasRole(acceptedRoles, loggedInUser.roles) && row?.filename !== ''}
                        onDownload={onDownload}
                        participantId={currentParticipant.id}
                        filename={row.filename}
                    />
                </TableCell>
                <TableCell>{reverseFormatDate(row.startDate)}</TableCell>
                <TableCell>{row.statusName}</TableCell>
            </TableRow>
            {row.statusName.toLowerCase() !== 'rejected' ? (
                ''
            ) : (
                <TableRow>
                    <TableCell
                        style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                            borderTop: 'none',
                            borderBottom: 'none'
                        }}
                        colSpan={7}>
                        <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                            <Box>
                                <RejectionDetails row={row} onClose={() => setOpen(null)} />
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default PoolsTableRow;

PoolsTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    openId: PropTypes.string,
    toggleDropdown: PropTypes.func
};
