import PropTypes from 'prop-types';

import Button from '../../formElements/Button';

import actions from './formActions.module.css';

const FormActions = ({
    onClose,
    onCancel,
    btnText,
    disabled = false,
    customClass = '',
    id = '',
    error = {},
    clearError
}) => {
    return (
        <div className={`${actions.formActions} ${actions[customClass]}`}>
            <Button
                id={id}
                onClick={onClose}
                disabled={disabled}
                content={btnText}
                error={error}
                clearError={clearError}
                customErrorClass={'formActionsError'}
            />

            <div
                data-testid="form_actions_cancel_button"
                className={actions.cancelLink}
                onClick={onCancel}>
                Cancel X
            </div>
        </div>
    );
};

FormActions.propTypes = {
    id: PropTypes.string,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    btnText: PropTypes.string,
    disabled: PropTypes.bool,
    customClass: PropTypes.string,
    error: PropTypes.object,
    clearError: PropTypes.any
};

export default FormActions;
