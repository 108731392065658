import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { clearPreviewCommunication } from '../../store/communicationService';
import { processPlaceholderErrors } from '../../utils/messageUtils';
import Button from '../formElements/Button';
import BusyIndicator from '../ui/BusyIndicator';

import modal from '../../commonStyles/modal.module.css';
import comms from './communication.module.css';

const CommunicationsErrorModal = ({
    isOpen,
    groupId,
    participants,
    payload,
    previewCommunication,
    onAccept,
    onClose,
    onMessageProcessed
}) => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [canSend, setCanSend] = useState(false);

    const errorMessage = useSelector((state) => state.entities.formsState.errorMessage);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const communicationPreview = useSelector(
        (state) => state.entities.communicationService.communicationPreview
    );

    useEffect(() => {
        if (!isOpen) return;
        setCanSend(false);
        if (groupId) dispatch(previewCommunication(payload, groupId));
        else dispatch(previewCommunication(payload, participants[0].id));
    }, [isOpen]);

    useEffect(() => {
        if (successMessage === 'Communication Preview Loaded') {
            const up = communicationPreview['unresolvedPlaceholders'];
            let hasErrors = !!up;
            if (up instanceof Array) hasErrors = !!up.length;
            else if (up && typeof up === 'object')
                hasErrors = !!Object.keys(communicationPreview['unresolvedPlaceholders']).length;
            if (!Object.keys(errors).length && isOpen && !hasErrors) acceptAndSend();
        } else if (errorMessage) {
            if (isOpen) close();
        }
    }, [successMessage, errorMessage]);

    useEffect(() => {
        if (Object.keys(communicationPreview).length === 0) return;
        onMessageProcessed && onMessageProcessed(communicationPreview.body);
        const unresolvedPlaceholders =
            (groupId
                ? processPlaceholderErrors(
                      participants,
                      communicationPreview['unresolvedPlaceholders']
                  )
                : communicationPreview['unresolvedPlaceholders'].length
                  ? processPlaceholderErrors(participants, {
                        [participants[0].id]: communicationPreview['unresolvedPlaceholders']
                    })
                  : {}) || [];

        if (Object.keys(unresolvedPlaceholders).length < participants.length) setCanSend(true);
        if (Object.keys(unresolvedPlaceholders).length > 0) setErrors(unresolvedPlaceholders);
        else {
            setErrors({});
            acceptAndSend();
        }
    }, [communicationPreview]);

    const acceptAndSend = () => {
        onAccept();
        close();
    };

    const close = () => {
        dispatch(clearPreviewCommunication());
        onClose();
    };

    const generateErrorMessages = (errorHeader) => {
        return errors[errorHeader].map((el, i) => (
            <div key={`${errorHeader}-${i}`}>
                <b>{el}</b>
            </div>
        ));
    };

    const generateHeader = () => {
        return Object.keys(errors).map((el, i) => {
            return (
                <div key={el}>
                    <h1 className={comms.errorHeading}>
                        {`${participants.find((entry) => entry.id === el).ptCode} - No message will be sent:`}
                    </h1>
                    <div key={`${el}-${i}`} className={comms.placeholderErrorContainer}>
                        {generateErrorMessages(el)}
                    </div>
                </div>
            );
        });
    };

    return (
        <Dialog
            id="placeholderModal"
            slotProps={{
                paper: {
                    sx: { minWidth: 600 }
                }
            }}
            open={true}
            className={!isOpen && comms.hidden}
            disableScrollLock={!isOpen}
            onClose={onClose}>
            <DialogTitle className={modal.modalHeader}>Placeholder Errors</DialogTitle>
            <DialogContent>
                {Object.keys(communicationPreview).length === 0 ? (
                    <BusyIndicator className={modal.modalLoading} />
                ) : (
                    generateHeader()
                )}
            </DialogContent>
            <DialogActions>
                {onAccept && groupId && (
                    <Button
                        onClick={acceptAndSend}
                        disabled={!!errors.status || !canSend}
                        content="Send if possible"
                    />
                )}
                <Button onClick={close} content="Close" />
            </DialogActions>
        </Dialog>
    );
};

CommunicationsErrorModal.propTypes = {
    isOpen: PropTypes.bool,
    groupId: PropTypes.string,
    participants: PropTypes.array.isRequired,
    payload: PropTypes.object.isRequired,
    previewCommunication: PropTypes.func,
    onAccept: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onMessageProcessed: PropTypes.func
};

export default CommunicationsErrorModal;
