import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import SingleSelect from '../../formElements/SingleSelect';

const LocationSelect = ({
    locationId,
    serviceId,
    onLocationChange,
    isAuditable,
    onSearchAuditItems,
    errors = {},
    disabled = false
}) => {
    // LOCAL STATE
    const [filteredLocations, setFilteredLocations] = useState([]);

    // STORE STATE
    const locationsByServiceId = useSelector(
        (state) => state.entities.directusService.locationsByServiceId
    );

    // USE EFFECTS
    useEffect(() => {
        setFilteredLocations(
            serviceId in locationsByServiceId ? locationsByServiceId[serviceId] : []
        );
    }, [serviceId, locationsByServiceId]);

    // RENDER
    return (
        <SingleSelect
            id="locationId"
            label="Location"
            placeholder="Select location"
            mandatory={true}
            disabled={disabled}
            isAuditable={isAuditable}
            onSearchAuditItems={onSearchAuditItems}
            menuItems={filteredLocations || []}
            selectedId={locationId || ''}
            selected={filteredLocations.find((el) => el.id === locationId)}
            error={errors.locationId}
            onChange={(id) => onLocationChange('locationId', id)}
        />
    );
};

LocationSelect.propTypes = {
    locationId: PropTypes.string,
    serviceId: PropTypes.string,
    onLocationChange: PropTypes.func,
    isAuditable: PropTypes.bool,
    onSearchAuditItems: PropTypes.func,
    errors: PropTypes.object,
    disabled: PropTypes.bool
};

export default LocationSelect;
