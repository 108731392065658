import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardContent } from '@mui/material';

import { searchCurrentCourseProvisions } from '../../../store/courseService';
import { loadAllExternalCourseProviderDetails } from '../../../store/directusService';
import { POLARIS_WHITE } from '../../../themes/theme';
import { DEFAULT_TABLE_ROWS_PER_PAGE } from '../../../utils/formValidation/commonConstants';
import { stableSort } from '../../../utils/sortFunctions';
import FormHeader from '../../layout/FormHeader';
import ResultsTable from '../../table/ResultsTable';
import LoadingSpinner from '../../ui/LoadingSpinner';

import CurrentProvisionTableRow from './CurrentProvisionTableRow';

const headCells = [
    { id: 'id', numeric: false, label: 'ID' },
    { id: 'provisionName', numeric: false, label: 'Provision Name' },
    { id: 'providerName', numeric: false, label: 'Provider Name' },
    { id: 'status', numeric: false, label: 'Status' }
];

const CurrentProvisionManagement = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [formattedCourseProvisions, setFormattedCourseProvisions] = useState([]);
    const [openId, setOpenId] = useState('');
    const [rowMetaData, setRowMetaData] = useState({
        order: 'asc',
        orderBy: 'name',
        page: 0,
        rowsPerPage: DEFAULT_TABLE_ROWS_PER_PAGE
    });

    // STORE STATE
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    const externalCourseProviderDetails = useSelector(
        (state) => state.entities.directusService.externalCourseProviderDetails
    );
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );

    const currentCourseProvisions = useSelector(
        (state) => state.entities.courseService.currentCourseProvisions
    );
    const currentCourseProvisionsMetaData = useSelector(
        (state) => state.entities.courseService.currentCourseProvisionsMetaData
    );
    const loadingCurrentProvisions = useSelector(
        (state) => state.entities.courseService.loadingCurrentProvisions
    );

    const activeCourseProvisionsSearchData = {
        active: true,
        contractIds: [currentParticipant.contractId],
        participantIds: [currentParticipant.id]
    };

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadAllExternalCourseProviderDetails());
        dispatch(searchCurrentCourseProvisions(activeCourseProvisionsSearchData));
    }, []);

    useEffect(() => {
        if (currentCourseProvisions.length < 1 || externalCourseProviderDetails.length < 1) return;
        const formattedCourseProvisions = currentCourseProvisions.map((el) => ({
            ...el,
            provisionName: el.title,
            providerName:
                externalCourseProviderDetails.find((entry) => entry.id === el.nameId)?.name || '',
            statusId:
                el.participants.find((entry) => entry.participantId === currentParticipant.id)
                    ?.statusId || ''
        }));
        setFormattedCourseProvisions(formattedCourseProvisions);
    }, [currentCourseProvisions, externalCourseProviderDetails]);

    useEffect(() => {
        if (successMessage === 'Participant has been submitted to provision') {
            dispatch(searchCurrentCourseProvisions(activeCourseProvisionsSearchData));
        }
    }, [successMessage]);

    // HELPER FNS
    const createRows = () =>
        stableSort(formattedCourseProvisions, rowMetaData.orderBy, rowMetaData.order).map((el) => (
            <CurrentProvisionTableRow
                key={el.id}
                row={el}
                toggleDropdown={(id) => setOpenId(id)}
                openId={openId}
            />
        ));

    // RENDER
    return (
        <Card sx={{ backgroundColor: POLARIS_WHITE }} data-testid="form_start">
            <CardContent>
                <div>
                    <FormHeader text={'Current Provisions'}></FormHeader>
                    {currentCourseProvisionsMetaData.last &&
                    formattedCourseProvisions.length < 1 ? (
                        <LoadingSpinner content="No current provisions found" />
                    ) : (
                        <ResultsTable
                            defaultOrderBy={'name'}
                            defaultRowsPerPage={rowMetaData.rowsPerPage}
                            apiParams={activeCourseProvisionsSearchData}
                            headCells={headCells}
                            loadingValues={loadingCurrentProvisions}
                            loadResults={searchCurrentCourseProvisions}
                            passRowMetaDataUp={setRowMetaData}
                            tableRows={createRows()}
                            totalResults={currentCourseProvisionsMetaData.totalElements}
                            metaData={currentCourseProvisionsMetaData}
                        />
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

export default CurrentProvisionManagement;
