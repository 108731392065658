import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@mui/material';

import {
    clearCurrentTemplate,
    loadCommunicationTemplate,
    previewParticipantSMSCommunication,
    searchCommunicationTemplates,
    sendParticipantSMSCommunication
} from '../../../../store/communicationService';
import { loadCommunicationTypes } from '../../../../store/directusService';
import { selectCurrentParticipant } from '../../../../store/participantSelectors';
import { selectUserRoles } from '../../../../store/userSelectors';
import { POLARIS_DARKER_GREY, POLARIS_ORANGE } from '../../../../themes/theme';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import CommunicationsErrorModal from '../../../communication/CommunicationsErrorModal';
import IconError from '../../../IconError';
import SensitivityNotice from '../../../ui/notices/sensitivity/SensitivityNotice';
import DDLOptionPicker from '../../../ui/pickers/DDLOptionPicker';

import { initialErrorState, validate } from './validateCommunications';

import app from '../../../../app.module.css';
import form from '../../../../commonStyles/formStyles.module.css';
import local from '../communicationStyles.module.css';

const SMSCommunication = () => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const TIME = 30;
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const SMS_CHARACTERS_PER_SMS = 140;
    const SMS_CHARACTER_LIMIT = 560;
    const initialState = {
        contactMode: '',
        contactNumber: '',
        templateId: '',
        templateName: '',
        details: ''
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [numbers, setNumbers] = useState([]);
    const [smsColor, setSmsColor] = useState(POLARIS_DARKER_GREY);
    const [errors, setErrors] = useState(initialErrorState);
    const [showData, setShowData] = useState(false);
    const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
    const [placeholderErrorOpen, setPlaceholderErrorOpen] = useState(false);
    const msg = 'SMS Communication sent to participant';

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const communicationTypes = useSelector(
        (state) => state.entities.directusService.communicationTypes
    );
    const currentParticipant = useSelector(selectCurrentParticipant);
    const templates = useSelector(
        (state) => state.entities.communicationService.communicationTemplates
    );
    const currentTemplate = useSelector(
        (state) => state.entities.communicationService.communicationTemplate
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS
    const clearForm = () => {
        setNewEntry({
            ...initialState,
            contactMode: 'PRIMARY_PHONE_NUMBER',
            contactNumber: currentParticipant.primaryPhoneNumber
        });
        setErrors(initialErrorState);
        dispatch(clearCurrentTemplate());
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // USE EFFECTS

    useEffect(() => {
        if (communicationTypes?.length < 1) dispatch(loadCommunicationTypes());
    }, []);

    useEffect(() => {
        if (!currentParticipant || Object.keys(currentParticipant).length < 1) return;
        const phoneNumbers = [
            {
                id: 'PRIMARY_PHONE_NUMBER',
                name: currentParticipant.primaryPhoneNumber
            },
            {
                id: 'SECONDARY_PHONE_NUMBER',
                name: currentParticipant.secondaryPhoneNumber
            }
        ].filter((el) => el);
        setNumbers(phoneNumbers);
        setNewEntry((prev) => ({
            ...prev,
            contactMode: 'PRIMARY_PHONE_NUMBER',
            contactNumber: currentParticipant.primaryPhoneNumber
        }));
        dispatch(
            searchCommunicationTemplates({
                type: 'SMS',
                contractIds: [currentParticipant.contractId],
                inactive: false
            })
        );
    }, [currentParticipant]);

    useEffect(() => {
        if (newEntry.details.length > 140) setSmsColor(POLARIS_ORANGE);
        else {
            if (smsColor === POLARIS_ORANGE) setSmsColor(POLARIS_DARKER_GREY);
        }
    }, [newEntry.details]);

    useEffect(() => {
        if (
            !newEntry.templateId ||
            Object.keys(currentTemplate).length < 1 ||
            communicationTypes?.length < 1
        )
            return;
        if (communicationTypes.find((el) => el.id === currentTemplate.typeId)?.name !== 'SMS')
            return;
        setNewEntry((prev) => ({
            ...prev,
            details: currentTemplate.details
        }));
    }, [newEntry.templateId, currentTemplate, communicationTypes]);

    useEffect(() => {
        if (successMessage === msg) {
            setIsSendButtonDisabled(false);
            clearForm();
        }
    }, [successMessage]);

    // EVENT HANDLERS
    const processedPlaceholders = (newMessage) => {
        setNewEntry((prev) => ({
            ...prev,
            details: newMessage
        }));
    };

    const onRevealData = () => {
        setShowData(!showData);
        setTimeout(() => setShowData((showData) => !showData), TIME * 1000);
    };

    const onSubmit = () => {
        const { isValid, newErrors } = validate(newEntry, errors, 'SMS');
        setErrors(newErrors);
        if (!isValid) return;
        setIsSendButtonDisabled(true);
        const payload = {
            participantId: currentParticipant.id,
            communicationTemplateId: newEntry.templateId || null,
            contactMode: newEntry.contactMode,
            body: newEntry.details
        };
        dispatch(sendParticipantSMSCommunication(payload, payload.participantId, msg));
    };

    return (
        <div className={app.container} id="smsScreen">
            <CommunicationsErrorModal
                isOpen={placeholderErrorOpen}
                participants={[currentParticipant]}
                payload={{
                    participantId: currentParticipant.id,
                    communicationTemplateId: newEntry.templateId || null,
                    contactMode: newEntry.contactMode,
                    body: newEntry.details
                }}
                previewCommunication={previewParticipantSMSCommunication}
                onAccept={onSubmit}
                onClose={() => setPlaceholderErrorOpen(false)}
                onMessageProcessed={processedPlaceholders}
            />
            <div className={app.mainHeading}>Communications</div>
            <form className={`${form.formWrapper} ${local.formContainer}`}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={form.alignDownMed}>
                            <DDLOptionPicker
                                label={'To Mobile Number'}
                                id={'contactMode'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                menuItems={numbers || []}
                                chosenName={newEntry.contactNumber || ''}
                                chosenId={newEntry.contactMode || ''}
                                onChange={(chosenId) => {
                                    clearError('contactMode');
                                    const contactNumber = numbers.find((el) => el.id === chosenId);
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        contactMode: chosenId || '',
                                        contactNumber: contactNumber?.name || ''
                                    }));
                                }}></DDLOptionPicker>
                            {errors.contactMode.error && (
                                <div className={form.multiOptionPickerErrorWrapper}>
                                    <IconError text={errors.contactMode} />
                                </div>
                            )}
                        </div>
                        {!showData && (
                            <div className={`${form.inputCover} ${form.coverHeightSm}`}>
                                <SensitivityNotice
                                    onClick={onRevealData}
                                    numRows={1}
                                    customClass={form.inputNotice}
                                />
                            </div>
                        )}
                    </div>
                    <div className={form.formColumn}>
                        <div className={form.alignDownMed}>
                            <DDLOptionPicker
                                label={'Template'}
                                id={'templates'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                menuItems={templates || []}
                                chosenName={newEntry.templateName}
                                chosenId={newEntry.templateId}
                                onChange={(chosenId) => {
                                    clearError('templateId');
                                    chosenId && dispatch(loadCommunicationTemplate(chosenId));
                                    const chosenTemplate = templates?.find(
                                        (el) => el.id === chosenId
                                    );
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        templateId: chosenId || '',
                                        templateName: chosenTemplate?.name || ''
                                    }));
                                }}></DDLOptionPicker>
                        </div>
                    </div>
                </div>

                <div className={`${form.formSection} ${form.alignDown}`}>
                    <div className={form.formColumn}>
                        <div className={form.textAreaSet}>
                            <label htmlFor="sms">Message *</label>
                            <textarea
                                className={form.textAreaText}
                                id="details"
                                disabled={!hasRole(acceptedRoles, roles)}
                                maxLength={SMS_CHARACTER_LIMIT}
                                rows={10}
                                placeholder={'Enter template details'}
                                value={newEntry.details || ''}
                                onChange={(e) => {
                                    clearError('details');
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        details: e.target.value
                                    }));
                                }}
                            />
                            <div>
                                <div>
                                    <span>Characters: </span>
                                    <span style={{ color: smsColor }}>
                                        {newEntry.details?.length}
                                    </span>
                                </div>
                                <div>
                                    <span>SMS Count: </span>
                                    <span>
                                        {Math.ceil(
                                            newEntry.details?.length / SMS_CHARACTERS_PER_SMS
                                        )}
                                    </span>
                                </div>
                            </div>

                            {errors.details.error && (
                                <div className={`${form.textInputError} ${form.resetMargin}`}>
                                    <IconError text={errors.details} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className={form.buttonRight}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={(e) => {
                            e.preventDefault();
                            setPlaceholderErrorOpen(true);
                        }}
                        disabled={
                            !hasRole(acceptedRoles, roles) ||
                            newEntry.details.length < 1 ||
                            isSendButtonDisabled
                        }>
                        Send Now
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default SMSCommunication;
