import { compareUnorderedArrays } from '../../../utils/arrayUtils';
import { NO_UPDATE_ERROR } from '../../../utils/formValidation/commonErrorConstants';
import {
    getDateMaxMessage,
    getDateMinMessage,
    getReqMessage,
    getSelectMessage
} from '../../../utils/formValidation/errorMessageFunctions';

export const validate = (newEntry, type, initialStateOnEntry) => {
    let newErrors = {};

    if (!newEntry.barrierId) {
        newErrors = {
            ...newErrors,
            barrierId: { error: true, message: getSelectMessage('barrier') }
        };
    }

    if (!newEntry.startDate) {
        newErrors = {
            ...newErrors,
            startDate: { error: true, message: getReqMessage('Start date') }
        };
    } else if (newEntry.startDate > newEntry.dueDate) {
        newErrors = {
            ...newErrors,
            startDate: { error: true, message: getDateMaxMessage('due date') }
        };
    }

    if (!newEntry.dueDate) {
        newErrors = {
            ...newErrors,
            dueDate: { error: true, message: getReqMessage('Due date') }
        };
    } else if (newEntry.dueDate < newEntry.startDate) {
        newErrors = {
            ...newErrors,
            dueDate: { error: true, message: getDateMinMessage('start date') }
        };
    }

    if (type === 'edit' && Object.keys(newErrors).length < 1)
        newErrors = checkDiff(newEntry, initialStateOnEntry, newErrors);

    return newErrors;
};

const checkDiff = (newEntry, initialStateOnEntry, errors) => {
    if (compareUnorderedArrays(Object.values(newEntry), Object.values(initialStateOnEntry))) {
        return {
            ...errors,
            button: { error: true, message: NO_UPDATE_ERROR }
        };
    }
    return errors;
};
