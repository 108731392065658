import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    clearAppointmentAuditData,
    clearNewAppointment,
    searchAppointmentAuditData,
    setCurrentAppointment,
    setOpenAppointmentSchedulerPanel
} from '../../../store/calendarService';
import { selectUsersForNamesById } from '../../../store/userSelectors';
import { loadUsersMinimalDetails } from '../../../store/userService';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import AuditAppointmentModal from '../../auditing/auditAppointments/AuditAppointmentModal';
import StaticField from '../../formElements/StaticField';
import Switch from '../../formElements/Switch';
import Panel from '../../ui/panel/Panel';
import MandatoryActivitiesSwitch from '../components/MandatoryActivitiesSwitch';
import StartAppointmentRow from '../components/StartAppointmentRow';
import StaticParticipant from '../components/StaticParticipant';

import sidePanel from '../styles/schedulePanel.module.css';

const ParticipantAppointmentView = ({
    usersCurrentAppointment,
    currentLocation,
    currentParticipantEntry
}) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = { id: '', service: '' };
    const [newEntry, setNewEntry] = useState(initialState);
    const [isAuditable, setIsAuditable] = useState(false);
    const [showAuditModal, setShowAuditModal] = useState(false);
    const [auditLabel, setAuditLabel] = useState('');
    const [modalHeadingLabel, setModalHeadingLabel] = useState('');

    // STORE STATE
    const users = useSelector(selectUsersForNamesById);
    const serviceDetailsById = useSelector(
        (state) => state.entities.directusService.serviceDetailsById
    );
    const openAppointmentSchedulerPanel = useSelector(
        (state) => state.entities.calendarService.openAppointmentSchedulerPanel
    );

    // USE EFFECTS
    useEffect(() => {
        if (!currentParticipantEntry?.id) return;
        const { serviceId, contractId } = currentParticipantEntry;
        if (!serviceId || !contractId) return;
        if (!(usersCurrentAppointment.userId in users))
            dispatch(loadUsersMinimalDetails([usersCurrentAppointment.userId]));
    }, []);

    useEffect(() => {
        if (!(usersCurrentAppointment.serviceId in serviceDetailsById)) return;
        setNewEntry({
            id: usersCurrentAppointment.id,
            service: serviceDetailsById[usersCurrentAppointment.serviceId]?.name || ''
        });
    }, [serviceDetailsById]);

    // EVENT HANDLERS
    const onPanelClose = () => {
        setNewEntry(initialState);
        dispatch(setCurrentAppointment({}));
        dispatch(setOpenAppointmentSchedulerPanel(false));
        dispatch(clearNewAppointment());
    };

    const onAuditModalClose = (e, reason) => {
        e.stopPropagation();
        if (reason === 'backdropClick') return;
        setShowAuditModal(false);
        setAuditLabel('');
        setModalHeadingLabel('');
        dispatch(clearAppointmentAuditData());
    };

    const onSearchAuditItems = (label, modalHeadingLabel) => {
        dispatch(searchAppointmentAuditData(newEntry.id, label));
        setShowAuditModal(true);
        setAuditLabel(label);
        setModalHeadingLabel(modalHeadingLabel ?? label);
    };

    // RENDER
    return (
        <>
            <Panel width="500px" open={openAppointmentSchedulerPanel} onToggle={onPanelClose}>
                <div className={sidePanel.panelActions}>
                    <Switch
                        id={'auditDisplayFields'}
                        label="Show Audit Fields"
                        onOff={true}
                        customClass="auditSwitch"
                        checked={isAuditable}
                        onChange={(e) => setIsAuditable(e.target.checked)}
                    />
                </div>
                <div className={sidePanel.formWrapper}>
                    <StaticField content={newEntry.service} customClass="underlinedContent" />
                    <StaticField
                        label="Adviser"
                        content={users[usersCurrentAppointment.userId]?.emailAddress || ''}
                    />
                    <StaticField label="Appointment Type" content={usersCurrentAppointment.type} />
                    <StaticField
                        label="Location"
                        content={currentLocation}
                        isAuditable={isAuditable}
                        onSearchAuditItems={onSearchAuditItems}
                    />
                    <StaticField
                        label="Start Time"
                        content={usersCurrentAppointment.startTime}
                        isAuditable={isAuditable}
                        onSearchAuditItems={() => onSearchAuditItems('Start Date', 'Start Time')}
                    />
                    <StaticField
                        label="End Time"
                        content={usersCurrentAppointment.endTime}
                        isAuditable={isAuditable}
                        onSearchAuditItems={() => onSearchAuditItems('End Date', 'End Time')}
                    />
                    <StaticField
                        label="Date"
                        content={reverseFormatDate(usersCurrentAppointment.date)}
                        isAuditable={isAuditable}
                        onSearchAuditItems={() => onSearchAuditItems('Start Date', 'Date')}
                    />

                    <StaticParticipant participant={currentParticipantEntry} onNav={onPanelClose} />
                    <MandatoryActivitiesSwitch
                        isMandatory={usersCurrentAppointment.mandatory}
                        disabled={true}
                    />

                    <StartAppointmentRow
                        disabled={true}
                        onPanelClose={onPanelClose}
                        appointment={usersCurrentAppointment}
                        participant={currentParticipantEntry}
                        isAuditable={isAuditable}
                        onSearchAuditItems={onSearchAuditItems}
                    />
                </div>
            </Panel>
            {showAuditModal && (
                <div id="audit">
                    <AuditAppointmentModal
                        isOpen={showAuditModal}
                        label={auditLabel}
                        modalHeadingLabel={modalHeadingLabel}
                        onClose={onAuditModalClose}
                    />
                </div>
            )}
        </>
    );
};

ParticipantAppointmentView.propTypes = {
    usersCurrentAppointment: PropTypes.object,
    currentLocation: PropTypes.string,
    currentParticipantEntry: PropTypes.object
};

export default ParticipantAppointmentView;
