import React, { useEffect, useState } from 'react';
import { Upload } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { InboxOutlined } from '@ant-design/icons';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';

import { loadDocumentTypeIdsAndNamesForContract } from '../../store/directusService';
import {
    addParticipantDocuments,
    deleteFileUploadErrorEntries,
    deleteFileUploadErrorEntry
} from '../../store/documentService';
import { setErrorMessage } from '../../store/formsState';
import {
    selectCurrentParticipant,
    selectParticipantDocumentErrors
} from '../../store/participantSelectors';
import { selectLoggedInUser } from '../../store/userSelectors';
import LoadingSpinner from '../ui/LoadingSpinner';
import DDLOptionPicker from '../ui/pickers/DDLOptionPicker';

import '../../commonStyles/antUploaderStyles.css';
import classes from './documents.module.css';

const { Dragger } = Upload;
const MAX_FILE_SIZE = 20;
const fileTypes = '.doc, .docx, .pdf, .csv, .rtf, .jpg, .png, .odt, .ods';
const acceptedFileTypes = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'text/csv',
    'application/rtf',
    'image/jpeg',
    'image/png',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.spreadsheet'
];
const AddDocument = ({
    onClose,
    onCancel,
    btnText = 'Close',
    currentDocumentType,
    hideCancel = false
}) => {
    const dispatch = useDispatch();

    const [documentType, setDocumentType] = useState({});
    const [successMsg, setSuccessMsg] = useState('');
    const [showErrors, setShowErrors] = useState(false);
    const [uploadProps, setUploadProps] = useState({});

    const documentTypes = useSelector(
        (state) => state.entities.directusService.documentTypeIdsAndNamesForContract
    );
    const user = useSelector(selectLoggedInUser);
    const currentParticipant = useSelector(selectCurrentParticipant);
    const participantDocumentErrors = useSelector(selectParticipantDocumentErrors);

    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    useEffect(() => {
        setUploadProps({
            name: 'file',
            beforeUpload(file) {
                const isValidSize = file.size / 1024 / 1024 <= MAX_FILE_SIZE;
                if (!isValidSize) {
                    dispatch(setErrorMessage(`File must be smaller than ${MAX_FILE_SIZE}MB`));
                    return false;
                }
                if (!acceptedFileTypes.includes(file.type)) {
                    dispatch(setErrorMessage(`Please use a valid attachment type`));
                    return false;
                }
            },
            disabled: typeof documentType !== 'object' || Object.keys(documentType).length < 1,
            multiple: true,
            onChange() {},
            customRequest(info) {
                const { status } = info.file;
                const payload = new FormData();
                payload.append('file', info.file);
                payload.append('userId', user.id);
                let getDocumentId = '';

                getDocumentId =
                    documentTypes.find(
                        (item) => item.name.toLowerCase() === documentType.name.toLowerCase()
                    )?.id || '';
                payload.append('typeId', getDocumentId);
                const successMsg = `Document ${info.file.name} has been uploaded`;
                setSuccessMsg(successMsg);
                dispatch(addParticipantDocuments(currentParticipant.id, payload, successMsg));
                if (status === 'error') {
                    dispatch(
                        setErrorMessage(
                            `${info.file.name} upload failed. Please use a valid attachment type. Accepted types are ${fileTypes}`
                        )
                    );
                }
            },
            onDrop(e) {
                console.log('Dropped files', e.dataTransfer.files);
            }
        });
    }, [documentType]);

    useEffect(() => {
        if (documentTypes.length < 0)
            dispatch(loadDocumentTypeIdsAndNamesForContract(currentParticipant.contractId));
    }, []);

    useEffect(() => {
        if (currentDocumentType) {
            const documentType = documentTypes.find((el) => el.name === currentDocumentType.name);
            documentType && setDocumentType(documentType);
        }
    }, [currentDocumentType, documentTypes]);

    useEffect(() => {
        if (
            participantDocumentErrors?.length < 1 &&
            successMessage !== '' &&
            successMessage === successMsg
        ) {
            onFormExit();
            onClose();
        }
        if (participantDocumentErrors?.length) setShowErrors(true);
    }, [successMessage, participantDocumentErrors]);

    // EVENT HANDLERS
    const onTypeChange = (id) => {
        if (documentTypes?.length < 1 || !id) return;
        const type = documentTypes.find((el) => el.id === id) || '';
        setDocumentType(type);
    };

    const onFormExit = async () => {
        setDocumentType({});
        participantDocumentErrors?.length && dispatch(deleteFileUploadErrorEntries());
    };

    const onAdd = () => {
        onFormExit();
        onClose();
    };

    const onExit = () => {
        onFormExit();
        onCancel();
    };

    const onDeleteFileError = (filename) => {
        dispatch(deleteFileUploadErrorEntry(filename));
    };

    return !documentTypes?.length ? (
        <LoadingSpinner content="No document types available" />
    ) : (
        <div className={classes.documentForm}>
            <div className={classes.optionInput}>
                <DDLOptionPicker
                    label={'Select Document Type'}
                    id={'DocumentType'}
                    chosenName={documentType?.name || currentDocumentType?.name || ''}
                    chosenId={documentType?.id || ''}
                    menuItems={documentTypes || []}
                    onChange={(id) => onTypeChange(id)}
                />
            </div>

            <div className={classes.documentUploader}>
                <h3>Choose Attachment</h3>
                <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">{`Support for fileTypes: ${fileTypes}`}</p>
                </Dragger>
            </div>
            {showErrors && (
                <div className={classes.fileErrors}>
                    {participantDocumentErrors?.map((el) => (
                        <div className={classes.errorItem} key={el.filename}>
                            <span>Error uploading {el.filename}: </span>
                            <span>{el.errorCode?.replace(/_/g, ' ')} </span>
                            <span className={classes.errorBin}>
                                <DeleteIcon onClick={() => onDeleteFileError(el.filename)} />
                            </span>
                        </div>
                    ))}
                </div>
            )}

            <div className={classes.formActions}>
                <Button onClick={onAdd} color="primary" variant="contained">
                    {btnText}
                </Button>
                {!hideCancel && (
                    <div
                        className={classes.cancelLink}
                        onClick={onExit}
                        data-testid="add-document-cancel-button">
                        Cancel X
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddDocument;

AddDocument.propTypes = {
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    btnText: PropTypes.string,
    currentDocumentType: PropTypes.object,
    hideCancel: PropTypes.bool
};
