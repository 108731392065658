import { createSlice } from '@reduxjs/toolkit';

import { getCommunicationURL } from '../api/commonHTTP';

import { apiCallBegan, apiVersion } from './api';

const slice = createSlice({
    name: 'communicationService',
    initialState: {
        automatedMessages: [],
        communicationTemplates: [],
        communicationTemplate: {},
        groupCommunications: [],
        groupCommunication: {},
        sentEmails: {},
        sentSms: {},
        communicationPreview: {},
        errorMessage: '',
        successMessage: '',
        version: {}
    },
    reducers: {
        clearCurrentTemplate: (state) => {
            state.communicationTemplate = {};
        },

        automatedMessagesLoading: (state) => {
            state.automatedMessages = [];
        },

        automatedMessagesLoaded: (state, action) => {
            state.automatedMessages = action.payload;
        },

        automatedMessagesUpdated: (state, action) => {
            state.automatedMessages = action.payload;
        },

        communicationTemplateCreated: (state, action) => {
            state.communicationTemplateLoading = false;
            state.communicationTemplates = [...state.communicationTemplates, action.payload];
        },

        communicationTemplatesLoaded: (state, action) => {
            state.communicationTemplates = action.payload || [];
        },

        communicationTemplateLoading: (state) => {
            state.communicationTemplateLoading = true;
            state.communicationTemplate = {};
        },

        communicationTemplateLoaded: (state, action) => {
            state.communicationTemplateLoading = false;
            state.communicationTemplate = action.payload;
        },

        communicationTemplateUpdated: (state, action) => {
            state.communicationTemplateLoading = false;
            state.communicationTemplates = state.communicationTemplates.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },

        groupCommunicationLoading: (state) => {
            state.groupCommunicationLoading = true;
        },

        groupCommunicationsLoading: (state) => {
            state.groupCommunicationsLoading = true;
        },

        groupCommunicationCreated: (state, action) => {
            state.groupCommunicationLoading = false;
            state.groupCommunications = [...state.groupCommunications, action.payload];
            state.groupCommunication = action.payload;
        },

        groupCommunicationDeleted: (state, action) => {
            state.groupCommunications = state.groupCommunications.map((el) =>
                el.id === action.payload ? { ...el, inactive: true } : el
            );
        },

        groupCommunicationsLoaded: (state, action) => {
            state.groupCommunicationsLoading = false;
            state.groupCommunications = action.payload;
        },

        groupCommunicationUpdated: (state, action) => {
            state.groupCommunicationLoading = false;
            state.groupCommunications = state.groupCommunications.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },

        groupEmailSent: (state, action) => {
            state.sentEmails = action.payload.participants;
        },

        groupSmsSent: (state, action) => {
            state.sentSms = action.payload.participants;
        },

        previewCommunicationUpdated: (state, action) => {
            state.communicationPreview = action.payload;
        },

        clearPreviewCommunication: (state) => {
            state.communicationPreview = {};
        },

        setCurrentGroupCommunication: (state, action) => {
            state.groupCommunication = action.payload;
        },

        errorOccurred: (state, action) => {
            state.errorMessage = action.payload;
        },

        errorMessageSet: (state, action) => {
            state.errorMessage = action.payload;
            state.successMessage = '';
        },

        successMessageSet: (state, action) => {
            state.successMessage = action.payload;
            state.errorMessage = '';
        },

        versionLoaded: (state, action) => {
            state.version = action.payload;
        }
    }
});

export const createUpdateAutomatedMessages = (data, msg) =>
    apiCallBegan({
        url:
            getCommunicationURL() +
            `rest/automated-message-configuration/service/${data.serviceId}`,
        method: 'put',
        data,
        onSuccess: automatedMessagesUpdated.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const loadAutomatedMessages = (serviceId) =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/automated-message-configuration/service/${serviceId}`,
        onStart: automatedMessagesLoading.type,
        onSuccess: automatedMessagesLoaded.type,
        successMessage: `Automated messages have been loaded`,
        onError: errorOccurred.type
    });

export const createLetterCommunicationTemplate = (data) =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/communication-template/letter-template`,
        method: 'post',
        data,
        onStart: communicationTemplateLoading.type,
        onSuccess: communicationTemplateCreated.type,
        successMessage: `${data.name} has been created`,
        onError: errorOccurred.type
    });

export const updateLetterCommunicationTemplate = (id, data) =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/communication-template/letter-template/${id}`,
        method: 'put',
        data,
        onStart: communicationTemplateLoading.type,
        onSuccess: communicationTemplateUpdated.type,
        successMessage: `${data.name} has been updated`,
        onError: errorOccurred.type
    });

export const createSMSCommunicationTemplate = (data) =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/communication-template/sms-template`,
        method: 'post',
        data,
        onStart: communicationTemplateLoading.type,
        onSuccess: communicationTemplateCreated.type,
        successMessage: `${data.name} has been created`,
        onError: errorOccurred.type
    });

export const updateSMSCommunicationTemplate = (id, data) =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/communication-template/sms-template/${id}`,
        method: 'put',
        data,
        onStart: communicationTemplateLoading.type,
        onSuccess: communicationTemplateUpdated.type,
        successMessage: `${data.name} has been updated`,
        onError: errorOccurred.type
    });

export const createEmailCommunicationTemplate = (data) =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/communication-template/email-template`,
        method: 'post',
        data,
        onStart: communicationTemplateLoading.type,
        onSuccess: communicationTemplateCreated.type,
        successMessage: `${data.name} has been created`,
        onError: errorOccurred.type
    });

export const loadCommunicationTemplate = (id) =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/communication-template/${id}`,
        onStart: communicationTemplateLoading.type,
        onSuccess: communicationTemplateLoaded.type,
        onError: errorOccurred.type
    });

export const loadCommunicationTemplates = () =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/communication-template`,
        onSuccess: communicationTemplatesLoaded.type,
        successMessage: 'Communication templates loaded',
        onError: errorOccurred.type
    });

export const searchCommunicationTemplates = (data) =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/communication-template/search`,
        method: 'post',
        data,
        onSuccess: communicationTemplatesLoaded.type,
        successMessage: 'Communication templates loaded',
        onError: errorOccurred.type
    });

export const updateEmailCommunicationTemplate = (id, data) =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/communication-template/email-template/${id}`,
        method: 'put',
        data,
        onStart: communicationTemplateLoading.type,
        onSuccess: communicationTemplateUpdated.type,
        successMessage: `${data.name} has been updated`,
        onError: errorOccurred.type
    });

// GROUP COMMS
export const createGroupCommunication = (data, msg) =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/group-communication`,
        method: 'post',
        data,
        onStart: groupCommunicationLoading.type,
        onSuccess: groupCommunicationCreated.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const updateGroupCommunication = (id, data, msg) =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/group-communication/${id}`,
        method: 'put',
        data,
        onStart: groupCommunicationLoading.type,
        onSuccess: groupCommunicationUpdated.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const loadGroupCommunications = () =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/group-communication`,
        onStart: groupCommunicationsLoading.type,
        onSuccess: groupCommunicationsLoaded.type,
        onError: errorOccurred.type
    });

// soft deletes
export const deleteGroupCommunication = (id, msg) =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/group-communication/${id}`,
        method: 'delete',
        onSuccess: communicationTemplatesLoaded.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

// SEND COMMUNICATION
export const sendJobCentreEmailCommunication = (data, id) =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/email/job-centre/${id}`,
        method: 'post',
        data,
        successMessage: 'Job centre email sent successfully',
        onError: errorOccurred.type
    });

export const sendParticipantEmailCommunication = (data, id, msg) =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/email/participant/${id}`,
        method: 'post',
        data,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const sendParticipantSMSCommunication = (data, id, msg) =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/sms/participant/${id}`,
        method: 'post',
        data,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const sendGroupSMSCommunication = (data, id, msg) =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/sms/group-communication/${id}`,
        method: 'post',
        data,
        onSuccess: groupSmsSent.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const sendGroupEmailCommunication = (data, id, msg) =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/email/group-communication/${id}`,
        method: 'post',
        data,
        onSuccess: groupEmailSent.type,
        successMessage: msg,
        onError: errorOccurred.type
    });

export const loadCommunicationServiceVersion = () =>
    apiVersion({
        url: getCommunicationURL() + 'rest/version',
        method: 'get',
        onSuccess: versionLoaded.type,
        onError: errorOccurred.type
    });

export const previewGroupEmailCommunication = (data, id) =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/email/group-communication/preview/${id}`,
        method: 'post',
        data,
        onSuccess: previewCommunicationUpdated.type,
        successMessage: 'Communication Preview Loaded',
        onError: errorOccurred.type
    });

export const previewParticipantEmailCommunication = (data, id) =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/email/participant/preview/${id}`,
        method: 'post',
        data,
        onSuccess: previewCommunicationUpdated.type,
        successMessage: 'Communication Preview Loaded',
        onError: errorOccurred.type
    });

export const previewGroupSMSCommunication = (data, id) =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/sms/group-communication/preview/${id}`,
        method: 'post',
        data,
        onSuccess: previewCommunicationUpdated.type,
        successMessage: 'Communication Preview Loaded',
        onError: errorOccurred.type
    });

export const previewParticipantSMSCommunication = (data, id) =>
    apiCallBegan({
        url: getCommunicationURL() + `rest/sms/participant/preview/${id}`,
        method: 'post',
        data,
        onSuccess: previewCommunicationUpdated.type,
        successMessage: 'Communication Preview Loaded',
        onError: errorOccurred.type
    });

export const onDeleteGroupCommunication = (id) => groupCommunicationDeleted(id);

export const onSetCurrentGroupCommunication = (data) => setCurrentGroupCommunication(data);
export const setErrorMessage = (message) => errorMessageSet(message);
export const { clearCurrentTemplate, clearPreviewCommunication } = slice.actions;

const {
    errorMessageSet,
    errorOccurred,
    automatedMessagesLoading,
    automatedMessagesLoaded,
    automatedMessagesUpdated,
    communicationTemplateLoading,
    communicationTemplateLoaded,
    communicationTemplatesLoaded,
    communicationTemplateCreated,
    communicationTemplateUpdated,
    groupCommunicationLoading,
    groupCommunicationsLoading,
    groupCommunicationsLoaded,
    groupCommunicationCreated,
    groupCommunicationDeleted,
    groupCommunicationUpdated,
    groupEmailSent,
    groupSmsSent,
    previewCommunicationUpdated,
    setCurrentGroupCommunication,
    versionLoaded
} = slice.actions;

export default slice.reducer;
