import {
    INVALID_EMAIL_ADDRESS,
    MAX_EMAIL,
    MAX_PHONE,
    MIN_EMAIL,
    MIN_PHONE
} from '../../../../utils/formValidation/commonConstants';
import {
    getCharMaxMessage,
    getCharMinMessage,
    getFormatMessage,
    getReqMessage
} from '../../../../utils/formValidation/errorMessageFunctions';
import { regExps } from '../../../../utils/formValidation/validationPatterns';

export const EMAIL = 'Email';
export const FIRST_NAME = 'First name';
export const LAST_NAME = 'Last name';
export const PHONE = 'Phone number';

export const validate = (newEntry, errors) => {
    let newErrors = errors;
    if (!newEntry.firstName) {
        newErrors = {
            ...newErrors,
            firstName: { error: true, message: getReqMessage(FIRST_NAME) }
        };
    } else if (!regExps.NAME_R.test(newEntry.firstName)) {
        newErrors = {
            ...newErrors,
            firstName: { error: true, message: getFormatMessage(FIRST_NAME) }
        };
    }

    if (!newEntry.lastName) {
        newErrors = {
            ...newErrors,
            lastName: { error: true, message: getReqMessage(LAST_NAME) }
        };
    } else if (!regExps.LAST_NAME_R.test(newEntry.lastName)) {
        newErrors = {
            ...newErrors,
            lastName: { error: true, message: getFormatMessage(LAST_NAME) }
        };
    }

    if (!newEntry.officeNumber) {
        newErrors = {
            ...newErrors,
            officeNumber: { error: true, message: getReqMessage(PHONE) }
        };
    } else if (newEntry.officeNumber.trim().length < MIN_PHONE) {
        newErrors = {
            ...newErrors,
            officeNumber: {
                error: true,
                message: getCharMinMessage(PHONE, MIN_PHONE)
            }
        };
    } else if (newEntry.officeNumber.trim().length > MAX_PHONE) {
        newErrors = {
            ...newErrors,
            officeNumber: {
                error: true,
                message: getCharMaxMessage(PHONE, MAX_PHONE)
            }
        };
    } else if (!regExps.PHONE_R.test(newEntry.officeNumber)) {
        newErrors = {
            ...newErrors,
            officeNumber: { error: true, message: getFormatMessage(PHONE) }
        };
    }

    if (newEntry.mobileNumber) {
        if (newEntry.mobileNumber.trim().length < MIN_PHONE) {
            newErrors = {
                ...newErrors,
                mobileNumber: {
                    error: true,
                    message: getCharMinMessage(PHONE, MIN_PHONE)
                }
            };
        } else if (newEntry.mobileNumber.trim().length > MAX_PHONE) {
            newErrors = {
                ...newErrors,
                mobileNumber: {
                    error: true,
                    message: getCharMaxMessage(PHONE, MAX_PHONE)
                }
            };
        } else if (!regExps.PHONE_R.test(newEntry.mobileNumber)) {
            newErrors = {
                ...newErrors,
                mobileNumber: { error: true, message: getFormatMessage(PHONE) }
            };
        }
    }

    if (!newEntry.email) {
        newErrors = {
            ...newErrors,
            email: { error: true, message: getReqMessage(EMAIL) }
        };
    } else if (newEntry.email.trim().length < MIN_EMAIL) {
        newErrors = {
            ...newErrors,
            email: { error: true, message: getCharMinMessage(EMAIL, MIN_EMAIL) }
        };
    } else if (newEntry.email.trim().length > MAX_EMAIL) {
        newErrors = {
            ...newErrors,
            email: { error: true, message: getCharMinMessage(EMAIL, MAX_EMAIL) }
        };
    } else if (!regExps.EMAIL_R.test(newEntry.email)) {
        newErrors = {
            ...newErrors,
            email: { error: true, message: getFormatMessage(INVALID_EMAIL_ADDRESS) }
        };
    }

    return newErrors;
};
