import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import {
    loadParticipantsAdvancedSearch,
    searchFinancialRequests
} from '../../../store/participantService';
import { calcStartDate } from '../../../utils/dateFunctions';
import {
    getEmptyErrorState,
    isErrorsValid,
    validate
} from '../../../utils/formValidation/validator';
import { deepEqual } from '../../../utils/objectUtils';
import { convertStringToCamelCase } from '../../../utils/stringUtils';
import {
    ADVISER,
    hasRole,
    MANAGER,
    PRAP,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import SingleSelect from '../../formElements/SingleSelect';

import { dataSetsModalValidationField } from './DataSetsModalValidationField';
import ModalContentAttendanceOutcome, {
    initialAttendanceOutcomeStatus,
    ModalActionAttendanceOutcome
} from './ModalContentAttendanceOutcome';
import { modalContentAttendanceOutcomeValidationField } from './ModalContentAttendanceOutcomeValidationField';
import ModalContentDefault, {
    initialDefaultStatus,
    ModalActionDefault
} from './ModalContentDefault';
import { modalContentDefaultValidationField } from './ModalContentDefaultValidationField';
import ModalContentFinancialRequest, {
    initialFinancialRequestStatus,
    ModalActionFinancialRequest
} from './ModalContentFinancialRequest';
import { modalContentFinancialRequestValidationField } from './ModalContentFinancialRequestValidationField';
import ModalContentReferralsCreated, {
    initialReferralsCreatedStatus,
    ModalActionReferralsCreated
} from './ModalContentReferralsCreated';
import { modalContentReferralsCreatedValidationField } from './ModalContentReferralsCreatedValidationField';

import '../../../commonStyles/muiStyles.css';
import form from '../../../commonStyles/formStyles.module.css';
import modal from '../../../commonStyles/modal.module.css';

const presentRoles = [ADVISER, MANAGER, PRAP, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];

const DataSetsModal = ({
    dataSets,
    contracts,
    attendanceStatuses,
    onGenerate,
    onCancel,
    isOpen
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const [selects, setSelects] = useState({});
    const [errors, setErrors] = useState({});
    const [hasErrors, setHasErrors] = useState(false);
    const [selectedDataSet, setSelectedDataSet] = useState({});
    const [type, setType] = useState('');
    const [validationFields, setValidationFields] = useState({ ...dataSetsModalValidationField });

    // STORE STATE
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);

    const clearFields = () => {
        setSelects({});
        setSelectedDataSet({});
        setType('');
    };

    // USE EFFECTS
    useEffect(() => {
        setHasErrors(!isErrorsValid(errors));
    }, [errors]);

    useEffect(() => {
        if (selectedDataSet && 'name' in selectedDataSet) {
            setType(convertStringToCamelCase(selectedDataSet.name || ''));
        } else {
            setType('');
        }
    }, [selectedDataSet]);

    useEffect(() => {
        if (!type) {
            setErrors({});
            setSelects({});
            return;
        }
        switch (type) {
            case 'attendanceOutcomes':
                setSelects({ ...initialAttendanceOutcomeStatus });
                setValidationFields({
                    ...dataSetsModalValidationField,
                    ...modalContentAttendanceOutcomeValidationField
                });
                setErrors(
                    getEmptyErrorState({
                        ...dataSetsModalValidationField,
                        ...modalContentAttendanceOutcomeValidationField
                    })
                );
                break;
            case 'financialRequest':
                setSelects({ ...initialFinancialRequestStatus });
                setValidationFields({
                    ...dataSetsModalValidationField,
                    ...modalContentFinancialRequestValidationField
                });
                setErrors(
                    getEmptyErrorState({
                        ...dataSetsModalValidationField,
                        ...modalContentFinancialRequestValidationField
                    })
                );
                break;
            case 'referralsCreated':
                setSelects({ ...initialReferralsCreatedStatus });
                setValidationFields({
                    ...dataSetsModalValidationField,
                    ...modalContentReferralsCreatedValidationField
                });
                setErrors(
                    getEmptyErrorState({
                        ...dataSetsModalValidationField,
                        ...modalContentReferralsCreatedValidationField
                    })
                );
                break;
            default:
                setSelects({ ...initialDefaultStatus });
                setValidationFields({
                    ...dataSetsModalValidationField,
                    ...modalContentDefaultValidationField
                });
                setErrors(
                    getEmptyErrorState({
                        ...dataSetsModalValidationField,
                        ...modalContentDefaultValidationField
                    })
                );
                break;
        }
    }, [type]);

    // EVENT HANDLERS
    const onChange = (key, value) => {
        if (deepEqual(selects[key], value)) return;
        setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
        setSelects((prev) => ({
            ...prev,
            [key]: value
        }));
    };

    const onClose = () => {
        clearFields();
        onCancel();
    };

    const onGenerateClick = (e) => {
        const validation = validate(
            { ...selects, dataSet: selectedDataSet?.id || '' },
            validationFields
        );
        setErrors(validation.errors);
        setHasErrors(!validation.isValid);
        if (!validation.isValid) return;
        onGenerate(e, selects, type);
    };

    const onPresentReferrals = () => {
        const validation = validate(
            { ...selects, dataSet: selectedDataSet?.id || '' },
            validationFields
        );
        setErrors(validation.errors);
        setHasErrors(!validation.isValid);
        if (!validation.isValid) return;
        onClose();
        dispatch(loadParticipantsAdvancedSearch({ contractIds: [selects.contract.id] }));
        navigate('/referrals_created');
    };

    const onPresentFinancialRequests = () => {
        const validation = validate(
            { ...selects, dataSet: selectedDataSet?.id || '' },
            validationFields
        );
        setErrors(validation.errors);
        setHasErrors(!validation.isValid);
        if (!validation.isValid) return;
        onClose();
        dispatch(
            searchFinancialRequests({
                contractIds: [selects.contract.id],
                serviceIds: [selects.serviceId],
                teamIds: selects.teamIds,
                dateRequestedFrom: calcStartDate(4, selects.date),
                dateRequestedTo: selects.date
            })
        );
        navigate('/financial_requests');
    };

    // RENDER
    const renderModalContent = () => {
        if (!type) return null;
        switch (type) {
            case 'attendanceOutcomes':
                return (
                    <ModalContentAttendanceOutcome
                        contracts={contracts}
                        errors={errors}
                        selects={selects}
                        attendanceStatuses={attendanceStatuses}
                        onChange={onChange}
                    />
                );
            case 'financialRequest':
                return (
                    <ModalContentFinancialRequest
                        contracts={contracts}
                        errors={errors}
                        selects={selects}
                        onChange={onChange}
                    />
                );
            case 'referralsCreated':
                return (
                    <ModalContentReferralsCreated
                        contracts={contracts}
                        errors={errors}
                        selects={selects}
                        onChange={onChange}
                    />
                );
            default:
                return (
                    <ModalContentDefault
                        contracts={contracts}
                        errors={errors}
                        selects={selects}
                        onChange={onChange}
                    />
                );
        }
    };

    const renderModalActions = () => {
        if (!type) return null;
        switch (type) {
            case 'attendanceOutcomes':
                return (
                    <ModalActionAttendanceOutcome
                        onGenerate={onGenerateClick}
                        disabled={!hasRole(presentRoles, roles) || hasErrors}
                    />
                );
            case 'financialRequest':
                return (
                    <ModalActionFinancialRequest
                        onPresent={onPresentFinancialRequests}
                        disabled={!hasRole(presentRoles, roles) || hasErrors}
                    />
                );
            case 'referralsCreated':
                return (
                    <ModalActionReferralsCreated
                        onGenerate={onGenerateClick}
                        onPresent={onPresentReferrals}
                        disabled={!hasRole(presentRoles, roles) || hasErrors}
                    />
                );
            default:
                return <ModalActionDefault />;
        }
    };

    return (
        <Dialog open={isOpen} fullWidth={true} className="muiDialogWrapper">
            <div className={modal.modalHeader}>
                <DialogTitle>Data Set Modal</DialogTitle>
            </div>
            <DialogContent>
                <form className={form.formWrapper}>
                    <div className={form.formSection}>
                        <SingleSelect
                            id="dataSetId"
                            label="Select Data Set"
                            placeholder="Select Service..."
                            mandatory={true}
                            menuItems={dataSets || []}
                            selectedId={selectedDataSet?.id || ''}
                            selected={dataSets.find((el) => el.id === selectedDataSet?.id) || {}}
                            error={errors.dataSet}
                            onChange={(id) =>
                                setSelectedDataSet(dataSets.find((el) => el.id === id))
                            }
                        />
                    </div>

                    {renderModalContent()}
                </form>

                <div className={modal.actionButtonsContainer}>
                    <div className={modal.modalActions}>{renderModalActions()}</div>

                    <div className={modal.cancelButton}>
                        <Button id="cancelDataSets" content="Cancel" onClick={onClose} />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default DataSetsModal;

DataSetsModal.propTypes = {
    dataSets: PropTypes.arrayOf(PropTypes.object),
    contracts: PropTypes.arrayOf(PropTypes.object),
    attendanceStatuses: PropTypes.arrayOf(PropTypes.object),
    onGenerate: PropTypes.func,
    onCancel: PropTypes.func,
    isOpen: PropTypes.bool
};
