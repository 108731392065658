import { NO_VALUE_SELECTED_ERROR } from '../../../utils/formValidation/commonErrorConstants';
import { POSTCODE_REGEXP } from '../../../validation/validation';

const ERROR_NO_VALUE = 'Please enter a value';
const ERROR_POSTCODE_FORMAT = 'Must be in the correct UK postcode format';

export const initialErrorState = {
    serviceId: { error: false, message: '' },
    maximumPostcodeDistanceId: { error: false, message: '' },
    postcode: { error: false, message: '' }
};

export const validate = (newEntry, errors) => {
    let isValid = true;
    let newErrors = errors;

    if (!newEntry.serviceId) {
        newErrors = {
            ...newErrors,
            serviceId: { error: true, message: NO_VALUE_SELECTED_ERROR }
        };
        isValid = false;
    }

    if (newEntry.postcode && !newEntry.maximumPostcodeDistanceId) {
        newErrors = {
            ...newErrors,
            maximumPostcodeDistanceId: { error: true, message: NO_VALUE_SELECTED_ERROR }
        };
        isValid = false;
    }

    if (newEntry.postcode && !newEntry.postcode.match(POSTCODE_REGEXP)) {
        newErrors = {
            ...newErrors,
            postcode: { error: true, message: ERROR_POSTCODE_FORMAT }
        };
        isValid = false;
    }

    if (newEntry.maximumPostcodeDistanceId && !newEntry.postcode) {
        newErrors = {
            ...newErrors,
            postcode: { error: true, message: ERROR_NO_VALUE }
        };
        isValid = false;
    }

    const firstError = Object.keys(newErrors).find((el) => newErrors[el].error);
    firstError && document.getElementById(firstError).focus();

    return { isValid, newErrors };
};

export const checkForValues = (newEntry) => {
    let isDisabled = false;
    if (
        !newEntry.serviceId ||
        (newEntry.maximumPostcodeDistanceId && !newEntry.postcode) ||
        (newEntry.postcode && !newEntry.maximumPostcodeDistanceId)
    )
        isDisabled = true;
    return isDisabled;
};
