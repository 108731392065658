import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';

import {
    loadJobSectorDetails,
    loadPoolAcceptedRejectionReasonsDetails,
    loadPoolRequestedRejectionReasonsDetails,
    loadPoolStatusDetails
} from '../../../../store/directusService';
import { loadParticipantDocumentDetails } from '../../../../store/documentService';
import { setErrorMessage } from '../../../../store/formsState';
import { loadParticipant } from '../../../../store/participantService';
import {
    loadPoolApplicationsByPoolIds,
    loadPoolsByUser,
    updatePoolApplicationsAccepted
} from '../../../../store/recruitmentService';
import { searchUsersByLoggedInUserServiceIds } from '../../../../store/userService';
import { getNameFromId } from '../../../../utils/directusFunctions';
import {
    JOB_SECTOR_DETAILS_ERROR,
    POOL_ACCEPTED_REJECTION_REASONS_DETAILS_ERROR,
    POOL_REQUESTED_REJECTION_REASONS_DETAILS_ERROR,
    POOL_STATUS_DETAILS_ERROR
} from '../../../../utils/formValidation/loadingErrorMessageConstants';
import { clearKeys } from '../../../../utils/objectUtils';
import { addEmailAddressAsNameToArray } from '../../../../utils/userArrayUtils';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import Button from '../../../formElements/Button';
import MultiSelect from '../../../formElements/MultiSelect';
import SingleSelect from '../../../formElements/SingleSelect';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import PoolApplicationRejectEditor from './PoolApplicationRejectEditor';
import PoolEditor from './PoolEditor';
import PoolTable from './PoolTable';

import classes from '../../recruitmentStyles/pool.module.css';

const PoolTableManagement = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const poolOwnerRoles = [MANAGER, RECRUITMENT_MANAGER];
    const [poolRows, setPoolRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [rejectedRow, setRejectedRow] = useState({});
    const [rowCancelled, setRowCancelled] = useState(false);
    const [poolApplicationsLoaded, setPoolApplicationsLoaded] = useState(false);
    const [poolEditor, setPoolEditor] = useState(false);
    const [poolOwners, setPoolOwners] = useState([]);
    const [selectedPoolOwner, setSelectedPoolOwner] = useState('');
    const [updatedPoolApplications, setUpdatedPoolApplications] = useState([]);
    const [poolApplicationRejectEditor, setPoolApplicationRejectEditor] = useState(false);
    const [teamDetails, setTeamDetails] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    const [arrayJobSectors, setArrayJobSectors] = useState([]);
    const [jobSectorPools, setJobSectorPools] = useState([]);
    const [preSelectedJobSectorIds, setPreSelectedJobSectorIds] = useState([]);
    const [selectedJobSectorIds, setSelectedJobSectorIds] = useState([]);

    const [keys, setKeys] = useState({ sectorIds: '0', ownerId: '1' });

    // STORE STATE
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const jobSectorDetails = useSelector(
        (state) => state.entities.directusService.jobSectorDetails
    );
    const poolAcceptedRejectionReasonsDetails = useSelector(
        (state) => state.entities.directusService.poolAcceptedRejectionReasonsDetails
    );
    const poolRequestedRejectionReasonsDetails = useSelector(
        (state) => state.entities.directusService.poolRequestedRejectionReasonsDetails
    );
    const poolStatusDetails = useSelector(
        (state) => state.entities.directusService.poolStatusDetails
    );
    const participantDocuments = useSelector(
        (state) => state.entities.documentService.participantDocuments
    );
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const poolApplications = useSelector(
        (state) => state.entities.recruitmentService.poolApplications
    );
    const pools = useSelector((state) => state.entities.recruitmentService.pools);
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);
    const userMetaData = useSelector(
        (state) => state.entities.userService.usersByLoggedInUserServiceIdsMetaData
    );
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);

    // USE EFFECTS
    useEffect(() => {
        setKeys(clearKeys(keys));

        jobSectorDetails?.length < 1 && dispatch(loadJobSectorDetails());
        poolAcceptedRejectionReasonsDetails?.length < 1 &&
            dispatch(loadPoolAcceptedRejectionReasonsDetails());
        poolRequestedRejectionReasonsDetails?.length < 1 &&
            dispatch(loadPoolRequestedRejectionReasonsDetails());
        poolStatusDetails?.length < 1 && dispatch(loadPoolStatusDetails());
    }, []);

    useEffect(() => {
        if (!loggedInUser || Object.keys(loggedInUser).length < 1) return;

        if (loggedInUser.roles.some((el) => poolOwnerRoles.includes(el))) {
            setSelectedPoolOwner(loggedInUser.id);
            setPoolOwnersArray([loggedInUser]);
        }
    }, [loggedInUser.id]);

    useEffect(() => {
        if (!users.length) return;

        let updatedPoolOwners = users.filter((el) =>
            el.userTypes?.find((entry) => poolOwnerRoles.includes(entry.role))
        );
        if (!poolOwners.length && users.length < userMetaData.totalElements) {
            onLoadMorePoolOwners();
            return;
        }
        if (selectedPoolOwner && !updatedPoolOwners.some((el) => el.id === selectedPoolOwner)) {
            // Put selected pool owner at the top of dropdown if it's not in the updated pool owners array
            const selectedAdvisor = poolOwners.find((el) => el.id === selectedPoolOwner);
            updatedPoolOwners = [selectedAdvisor, ...updatedPoolOwners];
        }
        setPoolOwnersArray(updatedPoolOwners);
    }, [users]);

    useEffect(() => {
        if (!selectedPoolOwner) return;

        dispatch(loadPoolsByUser(selectedPoolOwner));
        setJobSectorPools([]);
        getTeams(selectedPoolOwner);
    }, [selectedPoolOwner]);

    useEffect(() => {
        if (pools?.length < 1) {
            setPoolRows([]);
            setRows([]);
            setFilteredRows([]);
            setUpdatedPoolApplications([]);
            setPoolApplicationsLoaded(true);
            return;
        }

        setPoolApplicationsLoaded(false);
        const filteredPools = pools.filter((el) => el.ownerId === selectedPoolOwner);

        if (!filteredPools) {
            setPoolRows([]);
            setRows([]);
            setPoolApplicationsLoaded(true);
            setJobSectorPools([]);
            return;
        }

        const filteredPoolIds = filteredPools.map((el) => el.id);
        const filteredPoolsWithId = filteredPools.map((el) => ({
            ...el,
            id: uuid(),
            poolId: el.id
        }));

        const filteredRowsWithSectorName = filteredPoolsWithId.map((el) => ({
            ...el,
            jobSectorName: jobSectorDetails.find((entry) => entry.id === el.jobSectorId)?.name || ''
        }));

        const jobSectors = jobSectorDetails.filter((el) =>
            filteredRowsWithSectorName.find((entry) => el.id === entry.jobSectorId)
        );
        setJobSectorPools(jobSectorDetails.filter((el) => !jobSectors.includes(el)));
        dispatch(loadPoolApplicationsByPoolIds(filteredPoolIds));
        setPoolRows(filteredRowsWithSectorName);
        setRows(filteredRowsWithSectorName);
        setFilteredRows(filteredRowsWithSectorName);
        setUpdatedPoolApplications(filteredRowsWithSectorName);
    }, [pools]);

    useEffect(() => {
        if (
            poolApplications.length < 1 ||
            jobSectorDetails.length < 1 ||
            poolStatusDetails.length < 1 ||
            poolApplicationsLoaded
        )
            return;

        let updatedPoolsWithApplications = poolRows;
        setPoolApplicationsLoaded(true);
        poolApplications.forEach((el) => {
            let newPoolEntryArray = [];
            updatedPoolsWithApplications = updatedPoolsWithApplications.map((entry) => {
                if (entry.poolId === el.participantPoolId) {
                    if (entry.participantId) {
                        const newPoolEntry = {
                            id: uuid(),
                            ownerId: entry.ownerId,
                            jobSectorId: entry.jobSectorId,
                            jobSectorName: entry.jobSectorName,
                            teamIds: entry.teamIds,
                            poolId: entry.poolId,
                            requirements: entry.requirements,
                            ptCode: '',
                            ptName: '',
                            filename: '',
                            participantPoolApplicationId: el.id,
                            participantId: el.participantId,
                            goldStandardCVId: el.goldStandardCVId,
                            startDate: el.startDate || 'z-',
                            statusId: el.statusId,
                            statusName: getNameFromId(poolStatusDetails, el.statusId),
                            reasons: el.rejection?.rejectionIds.map((el) => el) || '',
                            notes: el.rejection?.notes || ''
                        };
                        // Check for duplicate
                        if (newPoolEntryArray.length > 0) {
                            if (
                                newPoolEntryArray.some(
                                    (npe) =>
                                        npe.jobSectorId === newPoolEntry.jobSectorId &&
                                        npe.participantPoolApplicationId ===
                                            newPoolEntry.participantPoolApplicationId
                                )
                            ) {
                                return entry;
                            }
                        }
                        newPoolEntryArray = [...newPoolEntryArray, newPoolEntry];
                        return entry;
                    } else {
                        return {
                            ...entry,
                            ptCode: '',
                            ptName: '',
                            filename: '',
                            participantPoolApplicationId: el.id,
                            participantId: el.participantId,
                            goldStandardCVId: el.goldStandardCVId,
                            startDate: el.startDate || 'z-',
                            statusId: el.statusId,
                            statusName: getNameFromId(poolStatusDetails, el.statusId),
                            reasons: el.rejection?.rejectionIds?.map((el) => el) || '',
                            notes: el.rejection?.notes || ''
                        };
                    }
                } else {
                    if (entry.participantId) {
                        return entry;
                    } else {
                        return {
                            ...entry,
                            ptCode: '',
                            ptName: '',
                            filename: '',
                            participantPoolApplicationId: null,
                            participantId: null,
                            goldStandardCVId: null,
                            startDate: 'z-',
                            statusId: '',
                            statusName: '',
                            reasons: [],
                            notes: ''
                        };
                    }
                }
            });
            if (newPoolEntryArray?.length > 0) {
                updatedPoolsWithApplications = [
                    ...updatedPoolsWithApplications,
                    ...newPoolEntryArray
                ];
            }
        });
        const participantIds = [
            ...new Set(updatedPoolsWithApplications.map((el) => el.participantId))
        ];
        participantIds.map((el) => {
            if (el) {
                dispatch(loadParticipant(el));
                dispatch(loadParticipantDocumentDetails(el));
            }
        });
        setRows(updatedPoolsWithApplications);
        setFilteredRows(updatedPoolsWithApplications);
        setUpdatedPoolApplications(updatedPoolsWithApplications);
    }, [poolApplications, jobSectorDetails, poolStatusDetails]);

    useEffect(() => {
        if (Object.keys(currentParticipant)?.length < 1) return;

        rows.map((el) => {
            if (el.participantId === currentParticipant.id) {
                el.ptCode = currentParticipant.ptCode;
                el.ptName = `${currentParticipant.firstName} ${currentParticipant.lastName}`;
            }
        });
        setFilteredRows(rows);
        setUpdatedPoolApplications(rows);
    }, [currentParticipant]);

    useEffect(() => {
        if (participantDocuments?.length < 1) return;

        rows.map((el) => {
            participantDocuments.map((entry) => {
                if (el.participantId === entry.participantId && el.goldStandardCVId === entry.id)
                    el.filename = entry?.filename || '';
            });
        });
        setFilteredRows(rows);
        setUpdatedPoolApplications(rows);
    }, [participantDocuments]);

    useEffect(() => {
        if (jobSectorDetails?.length && arrayJobSectors?.length === 0) {
            setPreSelectedJobSectorIds(jobSectorDetails.map((el) => el.id));
            setArrayJobSectors(jobSectorDetails);
        }
    }, [jobSectorDetails]);

    useEffect(() => {
        if (rows.length < 1 || selectedJobSectorIds.length < 1) return;

        const filteredRows = rows.filter((el) =>
            selectedJobSectorIds.some((v) => v === el.jobSectorId)
        );
        setFilteredRows(filteredRows);
        setUpdatedPoolApplications(filteredRows);
    }, [selectedJobSectorIds]);

    useEffect(() => {
        if (successMessage === `Pool applications accepted have been updated`) setIsDisabled(false);
        else if (successMessage === `Pool applications have been loaded`)
            setPoolApplicationsLoaded(true);
    }, [successMessage]);

    // HELPER FNS
    const getTeams = (id) => {
        if (!id) return;

        const teamDisplay = users.find((entry) => entry.id === id);
        if (teamDisplay) {
            const { primaryTeam, otherTeams } = teamDisplay;
            if (primaryTeam) {
                setTeamDetails([primaryTeam, ...otherTeams]);
            } else {
                setTeamDetails([]);
            }
        }
    };

    const loadingError = () => {
        if (jobSectorDetails?.length < 1) return JOB_SECTOR_DETAILS_ERROR;
        if (poolAcceptedRejectionReasonsDetails?.length < 1)
            return POOL_ACCEPTED_REJECTION_REASONS_DETAILS_ERROR;
        if (poolRequestedRejectionReasonsDetails?.length < 1)
            return POOL_REQUESTED_REJECTION_REASONS_DETAILS_ERROR;
        if (poolStatusDetails?.length < 1) return POOL_STATUS_DETAILS_ERROR;
        if (!poolApplicationsLoaded) return 'pool applications not loaded';
    };

    const setPoolOwnersArray = (poolOwners) => {
        const updatedPoolOwners = addEmailAddressAsNameToArray(poolOwners);
        setPoolOwners(updatedPoolOwners);
    };

    // EVENT HANDLERS
    const onPoolOwnerChange = (chosenId) => {
        if (!chosenId || Object.keys(chosenId).length === 0) {
            chosenId = '';
            setRows([]);
            setFilteredRows([]);
            setUpdatedPoolApplications([]);
            setJobSectorPools([]);
            setIsDisabled(true);
        } else setIsDisabled(false);
        setSelectedPoolOwner(chosenId);
    };

    const onJobSectorChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setSelectedJobSectorIds(chosenIds);
    };

    const onLoadMorePoolOwners = () => {
        if ((!users.length || users.length < userMetaData.totalElements) && !userMetaData.last) {
            dispatch(
                searchUsersByLoggedInUserServiceIds(
                    loggedInUser.serviceIds,
                    !users.length ? 0 : userMetaData.number + 1
                )
            );
        }
    };

    // EVENT HANDLER CALLBACKS
    const onPoolEditor = (setOpen) => setPoolEditor(setOpen);

    const onUpdateParticipantStatus = (statusId, participantId, participantPoolApplicationId) => {
        const modified = updatedPoolApplications.map((el) =>
            el.participantId === participantId &&
            el.participantPoolApplicationId === participantPoolApplicationId
                ? { ...el, statusId }
                : el
        );
        setUpdatedPoolApplications(modified);
    };

    const onPoolApplicationRejectEditor = (setOpen) => {
        setPoolApplicationRejectEditor(setOpen);
    };

    const onPoolApplicationRejectCancelled = (cancelled, rejectedRow) => {
        if (cancelled) {
            const resetRows = rows.filter((el) => el.id !== rejectedRow.id);
            setRows([...resetRows, rejectedRow]);
            setFilteredRows([...resetRows, rejectedRow]);
            setUpdatedPoolApplications([...resetRows, rejectedRow]);
            setRowCancelled(true);
        } else {
            setRowCancelled(false);
        }
    };

    const onPoolRejectedRow = (row) => {
        setRejectedRow(row);
        setRowCancelled(false);
    };

    const onPoolRejectedUpdate = (update) => {
        setPoolApplicationsLoaded(update);
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const poolStatusId = poolStatusDetails.find(
            (el) => el.name.toLowerCase() === 'accepted'
        )?.id;

        const acceptedPoolApplicationIds = updatedPoolApplications
            .filter((el) => el.statusId === poolStatusId && el.startDate === 'z-')
            .map((el) => el.participantPoolApplicationId);

        if (acceptedPoolApplicationIds && acceptedPoolApplicationIds.length > 0) {
            setPoolApplicationsLoaded(false);
            setRows([]);
            setFilteredRows([]);
            setIsDisabled(true);
            dispatch(updatePoolApplicationsAccepted({ ids: acceptedPoolApplicationIds }));
        } else {
            dispatch(setErrorMessage(`There is nothing to submit.`));
        }
    };

    // AWAITING CONTENT
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} time={3} />;

    // RENDER
    return (
        <div className={classes.poolToolBar}>
            <form onSubmit={onSubmit} data-testid="form_start">
                <div className={classes.poolToolBar}>
                    <SingleSelect
                        id={'poolOwnerId'}
                        key={keys.ownerId}
                        label={'Pool Owner'}
                        placeholder="Select Pool Owner..."
                        disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                        customClass="widthControl"
                        menuItems={poolOwners}
                        selectedId={selectedPoolOwner}
                        selected={poolOwners?.find((el) => el.id === selectedPoolOwner) || {}}
                        onLoadMoreItems={onLoadMorePoolOwners}
                        moreItemsToLoad={
                            !userMetaData.totalElements || users.length < userMetaData.totalElements
                        }
                        onChange={(chosenId) => onPoolOwnerChange(chosenId)}
                    />
                    <MultiSelect
                        id="jobSectors"
                        key={keys.sectorIds}
                        label="Job Sector"
                        placeholder="Job Sector"
                        disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                        menuItems={jobSectorDetails || []}
                        preSelectedIds={preSelectedJobSectorIds}
                        preSelects={jobSectorDetails}
                        onChange={(chosenIds) => onJobSectorChange(chosenIds)}
                    />
                    <div>
                        <Button
                            id="CreatePoolButton"
                            type="button"
                            customClass="toolBarButton"
                            disabled={
                                !hasRole(acceptedRoles, loggedInUser.roles) || !selectedPoolOwner
                            }
                            content="Create Pool"
                            icon={true}
                            onClick={() => onPoolEditor(true)}
                        />
                    </div>
                </div>
                <PoolEditor
                    open={poolEditor}
                    onPoolEditor={onPoolEditor}
                    jobSectorDetails={jobSectorPools.length > 0 ? jobSectorPools : jobSectorDetails}
                    selectedPoolOwner={selectedPoolOwner}
                    poolOwners={poolOwners}
                    teamDetails={teamDetails}
                />
                <PoolApplicationRejectEditor
                    open={poolApplicationRejectEditor}
                    onPoolApplicationRejectEditor={onPoolApplicationRejectEditor}
                    onPoolApplicationRejectCancelled={onPoolApplicationRejectCancelled}
                    updatedPoolApplications={updatedPoolApplications}
                    rejectedRow={rejectedRow}
                    onPoolRejectedUpdate={onPoolRejectedUpdate}
                />

                {filteredRows.length < 1 ? (
                    <LoadingSpinner content="No pools found" />
                ) : (
                    <PoolTable
                        rows={filteredRows}
                        roles={loggedInUser.roles}
                        onUpdateParticipantStatus={onUpdateParticipantStatus}
                        onPoolApplicationRejectEditor={onPoolApplicationRejectEditor}
                        onPoolRejectedRow={onPoolRejectedRow}
                        rowCancelled={rowCancelled}
                    />
                )}

                <Button
                    id="submitButton"
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles) || isDisabled}
                    content="Update"
                />
            </form>
        </div>
    );
};

export default PoolTableManagement;
