import React from 'react';

import classes from './initialAppointmenStyles.module.css';

const InitialAppointmentPONumberNotice = () => {
    return (
        <div className={classes.warningNotice}>
            Participant Record missing PO number, Initial Appointment cannot be marked as Attended
        </div>
    )
};

export default InitialAppointmentPONumberNotice;