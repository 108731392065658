import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../../api/pagination';
import { selectUsersAppointmentsAndSlots } from '../../../store/calendarSelectors';
import {
    searchUserAppointmentsAndSlots,
    setCurrentAppointment,
    setOpenAppointmentSchedulerPanel,
    updateAppointment,
    updateAppointmentSlot
} from '../../../store/calendarService';
import { selectCourseIds } from '../../../store/courseSelectors';
import { loadCourseModulesByUserId, searchCoursesByCourseIds } from '../../../store/courseService';
import {
    loadAppointmentTypeDetails,
    loadContractDetailsForContractId,
    loadLocationIdsAndNames,
    loadServiceDetailsForContractId
} from '../../../store/directusService';
import {
    searchParticipantsByLoggedInUserServiceIds,
    setCurrentlySelectedParticipant
} from '../../../store/participantService';
import {
    ADVISER,
    AUDIT,
    hasRole,
    LOCAL_ADMIN,
    MANAGER,
    PRAP,
    QUALITY,
    READ_ONLY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import Calendar from '../calendar/Calendar';
import AdviserSelect from '../components/AdviserSelect';
import {
    configCourseEventContent,
    configParticipantEventContent,
    fetchLoggedInUserData,
    formatFullCalendarTimes,
    getBusinessHours,
    getServiceIds,
    mapColors,
    mapCommonParams
} from '../utils/calendarUtils';

import UserAppointmentCreate from './UserAppointmentCreate';
import UserAppointmentEdit from './UserAppointmentEdit';
import UserAppointmentSlotsEdit from './UserAppointmentSlotsEdit';
import UserAppointmentSlotsView from './UserAppointmentSlotsView';
import UserAppointmentView from './UserAppointmentView';

import classes from '../../../app.module.css';

const PT_LOAD_SIZE = DEFAULT_PAGE_LOAD_SIZE;
let userClickCount = 0;

const UserCalendar = () => {
    //HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onSelectUsersAppointmentsAndSlots = useMemo(selectUsersAppointmentsAndSlots, []);

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const viewOnlyRoles = [AUDIT, PRAP, READ_ONLY, LOCAL_ADMIN];
    const [courseEvents, setCourseEvents] = useState([]);
    const [events, setEvents] = useState([]);
    const [newEvent, setNewEvent] = useState({});
    const [currentEvent, setCurrentEvent] = useState({});
    const [appointments, setAppointments] = useState([]);
    const [currentCalendarOwner, setCurrentCalendarOwner] = useState({});
    const [eventOwnerId, setEventOwnerId] = useState('');
    const [participants, setParticipants] = useState([]);

    // STORE STATE
    const usersAppointmentsAndSlots = useSelector((state) =>
        onSelectUsersAppointmentsAndSlots(state, currentCalendarOwner?.id)
    );
    const users = useSelector((state) => state.entities.userService.usersBySearch);
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);
    const { roles } = loggedInUser;
    const contractDetailsById = useSelector(
        (state) => state.entities.directusService.contractDetailsById
    );
    const servicesByContractId = useSelector(
        (state) => state.entities.directusService.servicesByContractId
    );
    const locations = useSelector((state) => state.entities.directusService.locationIdsAndNames);
    const appointmentTypes = useSelector(
        (state) => state.entities.directusService.appointmentTypeDetails
    );
    const courses = useSelector((state) => state.entities.courseService.coursesByCourseIds);
    const coursesByCourseIdsMetaData = useSelector(
        (state) => state.entities.courseService.coursesByCourseIdsMetaData
    );
    const courseModules = useSelector((state) => state.entities.courseService.courseModules);
    const courseIds = useSelector(selectCourseIds);
    const participantsByLoggedInUserServiceIds = useSelector(
        (state) => state.entities.participantService.participantsByLoggedInUserServiceIds
    );
    const ptMetaData = useSelector(
        (state) => state.entities.participantService.participantsByLoggedInUserServiceIdsMetaData
    );
    const usersCurrentAppointment = useSelector(
        (state) => state.entities.calendarService.usersCurrentAppointment
    );
    const openAppointmentSchedulerPanel = useSelector(
        (state) => state.entities.calendarService.openAppointmentSchedulerPanel
    );

    // CONFIG FNS
    const configEvents = () => {
        const businessHours = getBusinessHours();
        let entries;
        if (usersAppointmentsAndSlots?.length < 1) entries = [{}];
        else
            entries = usersAppointmentsAndSlots?.map((el) => {
                const { date, start, end, location } = mapCommonParams(el, locations);
                const { appointmentType, participantEntry, participant, slots, filledSlots } =
                    configParticipantEventContent(el, appointmentTypes, participants);
                const { color, textColor } = mapColors(el.id, usersCurrentAppointment?.id);
                const editable = el.id === usersCurrentAppointment?.id;
                return {
                    id: el.id,
                    eventType: 'user',
                    hasLink: true,
                    className:
                        !hasRole(acceptedRoles, roles) &&
                        loggedInUser.id !== currentCalendarOwner.id
                            ? 'non-click-event'
                            : '',
                    timeContent: `${start.slice(-5)} - ${end.slice(-5)}`,
                    slots,
                    filledSlots,
                    location,
                    participantEntry,
                    participant,
                    appointmentType,
                    color,
                    textColor,
                    date,
                    start,
                    end,
                    editable,
                    constraint: businessHours
                };
            });
        setEvents(entries);
        const cur = entries.find((el) => el.id === usersCurrentAppointment?.id);
        if (cur) setCurrentEvent(cur);
    };

    const configBackgroundEvents = () => {
        let events;
        if (courseModules?.length < 1) events = [];
        else
            events = courseModules?.map((el) => {
                const { date, start, end, location } = mapCommonParams(el, locations);
                const content = configCourseEventContent(el, courses, location);
                return {
                    eventType: 'courseModules',
                    className: 'non-interactive-events',
                    content,
                    timeContent: `${start.slice(-5)} - ${end.slice(-5)}`,
                    date,
                    start,
                    end,
                    editable: false
                };
            });
        setCourseEvents(events);
    };

    // USE EFFECTS
    useEffect(() => {
        appointmentTypes?.length < 1 && dispatch(loadAppointmentTypeDetails());
        locations?.length < 1 && dispatch(loadLocationIdsAndNames());
    }, []);

    useEffect(() => {
        if (!loggedInUser || !loggedInUser?.id) return;
        setCurrentCalendarOwner(loggedInUser);
        fetchLoggedInUserData(loggedInUser, dispatch);
        dispatch(
            searchParticipantsByLoggedInUserServiceIds(loggedInUser.serviceIds, 0, PT_LOAD_SIZE)
        );
    }, [loggedInUser]);

    useEffect(() => {
        if (!ptMetaData.last && 'number' in ptMetaData) {
            dispatch(
                searchParticipantsByLoggedInUserServiceIds(
                    loggedInUser.serviceIds,
                    ptMetaData.number + 1,
                    PT_LOAD_SIZE
                )
            );
        } else setParticipants(participantsByLoggedInUserServiceIds);
    }, [participantsByLoggedInUserServiceIds, ptMetaData]);

    useEffect(() => {
        setAppointments(usersAppointmentsAndSlots);
        if (locations?.length > 0 && participants && appointmentTypes?.length > 0) {
            configEvents();
        }
    }, [usersAppointmentsAndSlots, locations, participants, appointmentTypes]);

    useEffect(() => {
        if (!usersCurrentAppointment?.id) setCurrentEvent({});
        configEvents();
    }, [usersCurrentAppointment]);

    useEffect(() => {
        if (!courseModules?.length || coursesByCourseIdsMetaData.last) return;
        courseIds?.length > 0 &&
            dispatch(
                searchCoursesByCourseIds(
                    courseIds,
                    'number' in coursesByCourseIdsMetaData
                        ? coursesByCourseIdsMetaData.number + 1
                        : 0
                )
            );
    }, [courseModules, courseIds, coursesByCourseIdsMetaData]);

    useEffect(() => {
        configBackgroundEvents();
    }, [courses, courseModules]);

    useEffect(() => {
        if (!currentCalendarOwner?.id) return;
        fetchAdviserData(currentCalendarOwner.id);
    }, [currentCalendarOwner.id]);

    // HELPER FNS
    const fetchAdviserData = (adviserId) => {
        dispatch(searchUserAppointmentsAndSlots(adviserId));
        dispatch(loadCourseModulesByUserId(adviserId));
    };

    // EVENT HANDLERS
    const onDragEditTimes = (time, update) => {
        if (update) {
            const { date, startTime, endTime } = formatFullCalendarTimes(time);
            const updatedAppointment = { ...usersCurrentAppointment, date, startTime, endTime };
            currentEvent.slots
                ? dispatch(
                      updateAppointmentSlot(
                          eventOwnerId,
                          currentEvent.appointmentType,
                          updatedAppointment
                      )
                  )
                : dispatch(
                      updateAppointment(
                          eventOwnerId,
                          currentEvent.appointmentType,
                          updatedAppointment
                      )
                  );
        }
        dispatch(setCurrentAppointment({}));
    };

    const onTogglePanel = (open) => {
        dispatch(setOpenAppointmentSchedulerPanel(open));
    };

    const onSelectEvent = (id) => {
        setNewEvent({});
        if (id !== usersCurrentAppointment?.id) {
            const selectedAppointment = appointments.find((el) => el.id === id);
            selectedAppointment && dispatch(setCurrentAppointment(selectedAppointment));
            setEventOwnerId(currentCalendarOwner.id);
        }
        if (id === currentEvent.id) {
            userClickCount++;
            setTimeout(() => (userClickCount = 0), 300);
            if (userClickCount > 1) dispatch(setOpenAppointmentSchedulerPanel(true));
        }
    };

    const onSelectSlot = (start, end) => {
        dispatch(setCurrentAppointment({}));
        const { date, startTime, endTime } = formatFullCalendarTimes({ start, end });
        setNewEvent({ date, startTime, endTime });
        onTogglePanel(true);
    };

    const onAdviserChange = (chosenId) => {
        if (!chosenId) {
            if (currentCalendarOwner.id !== loggedInUser.id) {
                setCurrentCalendarOwner(loggedInUser);
            }
        } else if (chosenId && chosenId !== currentCalendarOwner.id) {
            const chosenAdviser = [...users, loggedInUser].find((el) => el.id === chosenId);
            setCurrentCalendarOwner({
                ...chosenAdviser,
                contractIds: chosenAdviser?.contracts || chosenAdviser?.contractIds,
                serviceIds: getServiceIds(chosenAdviser)
            });
        }
    };

    const onNav = (selectedEvent) => {
        dispatch(setCurrentlySelectedParticipant(selectedEvent.participantEntry));
        const { contractId } = selectedEvent.participantEntry;
        if (!(contractId in contractDetailsById))
            dispatch(loadContractDetailsForContractId(contractId));
        if (!(contractId in servicesByContractId))
            dispatch(loadServiceDetailsForContractId(contractId));
        navigate('/edit_participant');
    };

    // RENDER
    return (
        <div className={classes.container}>
            <div className="calendar-wrapper">
                <AdviserSelect
                    userId={currentCalendarOwner.id}
                    serviceIds={loggedInUser.serviceIds}
                    initialAdviser={loggedInUser}
                    onAdviserChange={onAdviserChange}
                    disabled={!hasRole(acceptedRoles, roles)}
                    customClass="calendarToolbarAdviserSelect"
                />

                <Calendar
                    events={[...events, ...courseEvents]}
                    currentEvent={currentEvent}
                    onDragEditTimes={onDragEditTimes}
                    onSelectSlot={onSelectSlot}
                    onSelectEvent={onSelectEvent}
                    allowSlotSelect={true}
                    initialView="timeGridDay"
                    onNav={onNav}
                    eventEditPermission={
                        hasRole(acceptedRoles, roles) ||
                        loggedInUser?.id === currentCalendarOwner?.id
                    }
                />

                {openAppointmentSchedulerPanel &&
                    currentCalendarOwner &&
                    (Object.keys(newEvent).length ? (
                        <UserAppointmentCreate
                            newAppointment={newEvent}
                            currentCalendarOwner={currentCalendarOwner}
                        />
                    ) : !hasRole(acceptedRoles, roles) && hasRole(viewOnlyRoles, roles) ? (
                        currentEvent.slots ? (
                            <UserAppointmentSlotsView
                                usersCurrentAppointment={usersCurrentAppointment}
                                currentCalendarOwner={currentCalendarOwner}
                                currentLocation={currentEvent.location}
                            />
                        ) : (
                            <UserAppointmentView
                                usersCurrentAppointment={usersCurrentAppointment}
                                currentCalendarOwner={currentCalendarOwner}
                                currentLocation={currentEvent.location}
                                currentParticipantEntry={currentEvent.participantEntry}
                            />
                        )
                    ) : currentEvent.slots ? (
                        <UserAppointmentSlotsEdit
                            usersCurrentAppointment={usersCurrentAppointment}
                            currentCalendarOwner={currentCalendarOwner}
                            currentLocation={currentEvent.location}
                        />
                    ) : (
                        <UserAppointmentEdit
                            usersCurrentAppointment={usersCurrentAppointment}
                            currentCalendarOwner={currentCalendarOwner}
                            currentLocation={currentEvent.location}
                            currentParticipantEntry={currentEvent.participantEntry}
                        />
                    ))}
            </div>
        </div>
    );
};

export default UserCalendar;
