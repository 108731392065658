import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import FormHeader from '../layout/FormHeader';
import AccordionHeader from '../ui/accordion/AccordionHeader';

import SubmissionManagement from './vacanciesAndPooling/SubmissionManagement';
import VacancyManagement from './vacanciesAndPooling/VacancyManagement';

import classes from './recruitmentStyles/vacancy.module.css';

const VacancySubmissionManagement = () => {
    // LOCAL STATE
    const { state } = useLocation();
    const [expanded, setExpanded] = useState(false);

    // STORE STATE
    const currentBusiness = useSelector(
        (state) => state.entities.recruitmentService.currentBusiness
    );

    // USE EFFECTS
    useEffect(() => {
        if (!state?.accordionPanel) {
            setExpanded('vacancyDetails');
        } else {
            setExpanded(state.accordionPanel);
        }
    }, []);

    // EVENT HANDLERS
    const onChange = (panel) => (_, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    // RENDER
    return (
        <div data-testid="form_start" className={classes.formHeader}>
            <FormHeader text={'Manage Vacancy'}></FormHeader>
            <FormHeader
                text={`${state.vacancy.title || ''} ${currentBusiness.code || ''}`}></FormHeader>
            <Accordion
                slotProps={{ transition: { mountOnEnter: true } }}
                expanded={expanded === 'vacancyDetails'}
                onChange={onChange('vacancyDetails')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="vacancyDetails-content"
                    id="vacancyDetails-header">
                    <AccordionHeader>Vacancy Details</AccordionHeader>
                </AccordionSummary>
                <AccordionDetails>
                    <VacancyManagement
                        vacancy={state.vacancy}
                        accordionPanelFrom={
                            !state?.accordionPanelFrom ? 'myBusinesses' : state.accordionPanelFrom
                        }
                        jobsOfferedForVacancies={state.jobsOfferedForVacancies}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion
                slotProps={{ transition: { mountOnEnter: true } }}
                expanded={expanded === 'submissions'}
                onChange={onChange('submissions')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="submissions-content"
                    id="submissions-header">
                    <AccordionHeader>Submissions</AccordionHeader>
                </AccordionSummary>
                <AccordionDetails>
                    <SubmissionManagement
                        vacancy={state.vacancy}
                        accordionPanelFrom={
                            !state?.accordionPanelFrom ? 'pooling' : state.accordionPanelFrom
                        }
                        jobsOfferedForVacancies={state.jobsOfferedForVacancies}
                    />
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default VacancySubmissionManagement;
