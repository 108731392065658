import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearBarrierName, loadBarrierNameDetailsByContract } from '../../../store/directusService';
import { loadParticipantBarriers } from '../../../store/participantService';
import { stableSort } from '../../../utils/sortFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import ResultsTable from '../../table/ResultsTable';
import LoadingSpinner from '../../ui/LoadingSpinner';

import BarrierTableRow from './BarrierTableRow';
import CreateBarrier from './CreateBarrier';
import EditBarrier from './EditBarrier';

import app from '../../../app.module.css';
import local from './barriers.module.css';

const headCells = [
    { id: 'barrier', numeric: false, label: 'Barrier Name', sortable: true },
    { id: 'startDate', numeric: true, label: 'Start Date', sortable: true },
    { id: 'dueDate', numeric: true, label: 'Due Date', sortable: true },
    { id: 'completeDate', numeric: true, label: 'Completed', sortable: true },
    { id: 'priority', numeric: false, label: 'Priority', sortable: true },
    { id: 'done', numeric: false, label: 'Done' },
    { id: 'edit', numeric: false, label: 'Edit' }
];

const initialRowMetaData = {
    order: 'desc',
    orderBy: 'startDate',
    page: 0,
    rowsPerPage: 100
};

const Barriers = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [rows, setRows] = useState([]);
    const [editType, setEditType] = useState('create');
    const [showBarrierEditor, setShowBarrierEditor] = useState(false);
    const [rowMetaData, setRowMetaData] = useState(initialRowMetaData);
    const [currentRow, setCurrentRow] = useState({});

    // STORE STATE
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const currentParticipantBarriers = useSelector(
        (state) => state.entities.participantService.currentParticipantBarriers
    );

    // USEEFFECTS
    useEffect(() => {
        if ('id' in currentParticipant) {
            dispatch(loadParticipantBarriers(currentParticipant.id));
            setShowBarrierEditor(false);
            dispatch(clearBarrierName());
            dispatch(loadBarrierNameDetailsByContract(currentParticipant.contractId));
        }
    }, [currentParticipant.id]);

    useEffect(() => {
        if (!currentParticipantBarriers) return;
        const updatedRows = currentParticipantBarriers.map((el) => ({
            ...el,
            startDate: !el.startDate ? '1960-01-01' : el.startDate,
            dueDate: !el.dueDate ? '1960-01-01' : el.dueDate,
            completeDate: !el.completeDate ? '1960-01-01' : el.completeDate
        }));
        setRows(updatedRows);
    }, [currentParticipantBarriers]);

    // HELPER fNS
    const createRows = () =>
        stableSort(rows, rowMetaData.orderBy, rowMetaData.order).map((el) => (
            <BarrierTableRow
                key={el.id}
                row={el}
                onEditBarrier={onEditBarrier}
                roles={roles}
                acceptedRoles={acceptedRoles}
                disabled={!hasRole(acceptedRoles, roles) || showBarrierEditor}
            />
        ));

    // EVENT HANDLERS
    const onCloseEdit = () => {
        setShowBarrierEditor(false);
        setCurrentRow({});
    };

    const onAddBarrier = () => {
        setShowBarrierEditor(true);
        setEditType('create');
    };

    const onEditBarrier = (row) => {
        setCurrentRow(row);
        setShowBarrierEditor(true);
        setEditType('edit');
    };

    return (
        <div className={local.barriersWrapper}>
            <div className={local.tableWrapper}>
                <h2 className={app.sectionHeading}>Your barrier details</h2>
                {rows.length < 1 ? (
                    <LoadingSpinner content="No barrier details found" />
                ) : (
                    <ResultsTable
                        defaultOrderBy={'startDate'}
                        sortOrder={rowMetaData.order}
                        headCells={headCells}
                        loadResults={loadParticipantBarriers}
                        passRowMetaDataUp={setRowMetaData}
                        apiParams={currentParticipant.id}
                        tableRows={createRows()}
                        totalResults={rows.length}
                    />
                )}
            </div>

            {showBarrierEditor ? (
                editType === 'edit' ? (
                    <EditBarrier
                        barrier={currentRow}
                        existingBarriers={currentParticipantBarriers}
                        closeEdit={onCloseEdit}
                        cancelEdit={onCloseEdit}
                        roles={roles}
                        acceptedRoles={acceptedRoles}
                    />
                ) : (
                    <CreateBarrier
                        existingBarriers={currentParticipantBarriers}
                        closeEdit={onCloseEdit}
                        cancelEdit={onCloseEdit}
                        roles={roles}
                        acceptedRoles={acceptedRoles}
                    />
                )
            ) : (
                <Button
                    disabled={!hasRole(acceptedRoles, roles)}
                    onClick={onAddBarrier}
                    content="Add a New Barrier"
                />
            )}
        </div>
    );
};

export default Barriers;
