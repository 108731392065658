import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ActionPlanTable from './pdfContent/ActionPlanTable';
import AppointmentDetails from './pdfContent/AppointmentDetails';
import AssetRequestTable from './pdfContent/AssetRequestTable';
import BarrierTable from './pdfContent/BarrierTable';
import CourseTable from './pdfContent/CourseTable';
import FinancialRequestTable from './pdfContent/FinancialRequestTable';
import JobGoalTable from './pdfContent/JobGoalTable';
import QuestionnaireTable from './pdfContent/QuestionnaireTable';
import SignDetail from './pdfContent/SignDetail';
import VacancyTable from './pdfContent/VacancyTable';

import classes from '../../../../commonStyles/pdf.module.css';

const PDFContentManagement = ({
    adviserName = '-',
    adviserSignature = '-',
    participantSignature = '-',
    signatureDateTime = '-',
    currentParticipant,
    currentAppointment,
    appointment,
    nextAppointment,
    onIsLoaded,
    participantsActiveAccordions
}) => {
    // LOCAL STATE
    const [dataLoaded, setDataLoaded] = useState({});

    // USE EFFECTS
    useEffect(() => {
        let activeAccordions = Object.entries(participantsActiveAccordions)
            // eslint-disable-next-line
            .filter(([_, v]) => v)
            .reduce((acc, cur) => ({ ...acc, [cur[0]]: false }), {});
        if ('request' in activeAccordions) {
            // eslint-disable-next-line
            const { request, ...rest } = activeAccordions;
            activeAccordions = { ...rest, assetRequest: false, financialRequest: false };
        }

        setDataLoaded(activeAccordions);
    }, []);

    useEffect(() => {
        if (Object.keys(dataLoaded).length > 0 && Object.values(dataLoaded).every((el) => el))
            onIsLoaded();
    }, [dataLoaded]);

    // CALLBACKS
    const onDataLoaded = (key) => {
        setDataLoaded((prev) => ({ ...prev, [key]: true }));
    };

    // RENDER
    return (
        <>
            <div className={classes.section}>
                <AppointmentDetails
                    appointment={appointment}
                    currentAppointment={currentAppointment}
                    nextAppointment={nextAppointment}
                />
            </div>

            {participantsActiveAccordions.questionnaire && (
                <div className={classes.section}>
                    <QuestionnaireTable onLoaded={onDataLoaded} />
                </div>
            )}

            {participantsActiveAccordions.barriers && (
                <div className={classes.section}>
                    <BarrierTable onLoaded={onDataLoaded} />
                </div>
            )}

            {participantsActiveAccordions.actionPlan && (
                <div className={classes.section}>
                    <ActionPlanTable onLoaded={onDataLoaded} />
                </div>
            )}

            {participantsActiveAccordions.courses && (
                <div className={classes.section}>
                    <CourseTable onLoaded={onDataLoaded} currentParticipant={currentParticipant} />
                </div>
            )}

            {participantsActiveAccordions.jobReadiness && (
                <div className={classes.section}>
                    <JobGoalTable onLoaded={onDataLoaded} currentParticipant={currentParticipant} />
                </div>
            )}

            {participantsActiveAccordions.request && (
                <div className={classes.section}>
                    <FinancialRequestTable onLoaded={onDataLoaded} />
                </div>
            )}

            {participantsActiveAccordions.request && (
                <div className={classes.section}>
                    <AssetRequestTable onLoaded={onDataLoaded} />
                </div>
            )}

            {participantsActiveAccordions.vacancy && (
                <div className={classes.section}>
                    <VacancyTable onLoaded={onDataLoaded} currentParticipant={currentParticipant} />
                </div>
            )}

            <div className={classes.section}>
                <h3>Appointment Notes</h3>
                <h4>Notes from this appointment</h4>
                <p className={classes.appointmentNotes}>
                    {currentAppointment.currentNotes || 'None'}
                </p>
            </div>

            <div className={classes.section}>
                <SignDetail
                    adviserName={adviserName}
                    signatureDateTime={signatureDateTime}
                    currentParticipant={currentParticipant}
                    currentAppointment={currentAppointment}
                    participantSignature={participantSignature}
                    adviserSignature={adviserSignature}
                />
            </div>
        </>
    );
};

PDFContentManagement.propTypes = {
    adviserName: PropTypes.string,
    currentParticipant: PropTypes.object,
    adviserSignature: PropTypes.string,
    participantSignature: PropTypes.string,
    currentAppointment: PropTypes.object,
    appointment: PropTypes.object,
    nextAppointment: PropTypes.object,
    signatureDateTime: PropTypes.string,
    onIsLoaded: PropTypes.func,
    participantsActiveAccordions: PropTypes.object
};

export default PDFContentManagement;
