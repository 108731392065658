import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../../store/directusService';
import { loadContractConstants } from '../../../store/participantService';

import AttendanceRow from './AttendanceRow';

import styles from '../styles/startAppointmentButton.module.css';

// TODO - THESE COMMENTS ARE INTENTIONALLY LEFT IN AS WORK IS DONE FOR UPCOMING REQUIREMENT
const StartAppointmentRow = ({
    onPanelClose,
    disabled,
    appointment,
    participant
    // isAuditable = false,
    // onSearchAuditItems
}) => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // LOCAL STATE
    const [attendanceStatus, setAttendanceStatus] = useState('Not known');
    const [attendanceAppointmentId, setAttendanceAppointmentId] = useState('');
    const [isPONumberRequired, setIsPONumberRequired] = useState(false);

    // STORE STATE
    const contractDetailsById = useSelector(
        (state) => state.entities.directusService.contractDetailsById
    );
    const servicesByContractId = useSelector(
        (state) => state.entities.directusService.servicesByContractId
    );
    const contractConstants = useSelector(
        (state) => state.entities.participantService.contractConstants
    );

    useEffect(() => {
        if (!appointment || !('attendances' in appointment) || !participant) return;
        if (!contractConstants || contractConstants.contractId !== participant.contractId)
            dispatch(loadContractConstants(participant.contractId));
        const attendanceRecord = appointment.attendances.find(
            (el) => el.participantId === participant.id
        );
        if (!attendanceRecord) return;
        setAttendanceAppointmentId(attendanceRecord?.id);
        setAttendanceStatus(attendanceRecord?.status || 'Not known');
    }, [appointment]);

    useEffect(() => {
        if (!contractConstants || contractConstants.contractId !== participant?.contractId) return;
        setIsPONumberRequired(contractConstants.requirePoNumberBeforeAppointmentInitialAttended);
    }, [contractConstants, participant]);

    // EVENT HANDLERS
    const onAction = () => {
        if (!(participant.contractId in contractDetailsById)) {
            dispatch(loadContractDetailsForContractId(participant.contractId));
        }
        if (!(participant.contractId in servicesByContractId)) {
            dispatch(loadServiceDetailsForContractId(participant.contractId));
        }
    };

    const onStartAppointment = (showWarning) => {
        if (disabled || !attendanceAppointmentId) return;
        onPanelClose();
        navigate('/appointments', {
            state: {
                appointment,
                attendanceAppointmentId,
                showWarning,
                returnPath: location.pathname
            }
        });
    };

    const onNav = async () => {
        await onAction();
        onPanelClose();
        navigate('/edit_participant');
    };

    // RENDER
    return participant && Object.keys(participant).length < 1 ? null : (
        <div
            className={`${styles.startAppointmentButton} ${styles.showAppointments}`}
            data-testid="startAppointmentRow">
            <AttendanceRow
                isDisabled={
                    disabled ||
                    (attendanceStatus && attendanceStatus !== 'Not known') ||
                    !attendanceAppointmentId
                }
                // isAuditable={isAuditable}
                // onSearchAuditItems={() => onSearchAuditItems('Attendance')}
                attendanceStatus={attendanceStatus}
                participant={participant}
                appointmentType={appointment?.type.toLowerCase()}
                isPONumberRequired={isPONumberRequired}
                onNav={onNav}
                onStartAppointment={onStartAppointment}
            />
        </div>
    );
};

StartAppointmentRow.propTypes = {
    onPanelClose: PropTypes.func,
    disabled: PropTypes.bool,
    appointment: PropTypes.object,
    participant: PropTypes.object
    // isAuditable: PropTypes.bool,
    // onSearchAuditItems: PropTypes.func
};

export default StartAppointmentRow;
