import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { loadJobSectorDetails, loadVacancyStageDetails } from '../../../../store/directusService';
import { loadBusinessRecord, updateVacancy } from '../../../../store/recruitmentService';
import { loadUserById, searchUsersByLoggedInUserServiceIds } from '../../../../store/userService';
import { getNameFromId } from '../../../../utils/directusFunctions';
import {
    ADDRESS_LINE_1_MAX_LENGTH,
    ADDRESS_LINE_2_MAX_LENGTH,
    ADDRESS_LINE_3_MAX_LENGTH,
    CITY_MAX_LENGTH,
    UPDATE
} from '../../../../utils/formValidation/commonConstants';
import {
    JOB_SECTOR_DETAILS_ERROR,
    VACANCY_STAGE_DETAILS_ERROR
} from '../../../../utils/formValidation/loadingErrorMessageConstants';
import { clearKeys } from '../../../../utils/objectUtils';
import { addEmailAddressAsNameToArray } from '../../../../utils/userArrayUtils';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import DateSelect from '../../../formElements/DateSelect';
import RadioButtons from '../../../formElements/RadioButtons';
import SingleSelect from '../../../formElements/SingleSelect';
import TextAreaField from '../../../formElements/TextAreaField';
import TextInputField from '../../../formElements/TextInputField';
import FormActions from '../../../ui/formActions/FormActions';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import { initialErrorState, validate } from './shared/validateVacancy';

import app from '../../../../app.module.css';
import form from '../../../../commonStyles/formStyles.module.css';

const SHIFT_PATTERN_LENGTH = 250;
const MAX_DESCRIPTION_LENGTH = 1000;
const MAX_REQUIREMENTS_LENGTH = 250;

const EditVacancy = () => {
    // HOOKS
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        title: '',
        ownerId: '',
        sectorId: '',
        hiringManagerId: '',
        numberOfPositions: '',
        payRateFrom: '',
        payRateTo: '',
        shiftPatternWorkingHours: '',
        expectedWeeklyHours: '',
        vacancyClosingDate: '',
        anticipatedStartDate: '',
        remote: false,
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        postcode: '',
        description: '',
        requirement: '',
        inactive: null,
        stageIds: []
    };
    const acceptedRoles = [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const businessOwnerRoles = [MANAGER, RECRUITMENT_MANAGER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [keys, setKeys] = useState({ ownerId: '0', sectorId: '1', hiringManagerId: '2' });
    const [errors, setErrors] = useState(initialErrorState);
    const [isDisabled, setIsDisabled] = useState(false);
    const [disableFields, setDisableFields] = useState(false);
    const [businessOwners, setBusinessOwners] = useState([]);
    const [hiringManagers, setHiringManagers] = useState([]);

    // STORE STATE
    const errorMessage = useSelector((state) => state.entities.formsState.errorMessage);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const jobSectorDetails = useSelector(
        (state) => state.entities.directusService.jobSectorDetails
    );
    const vacancyStageDetails = useSelector(
        (state) => state.entities.directusService.vacancyStageDetails
    );
    const currentBusiness = useSelector(
        (state) => state.entities.recruitmentService.currentBusiness
    );
    const currentVacancy = useSelector((state) => state.entities.recruitmentService.currentVacancy);
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);
    const userMetaData = useSelector(
        (state) => state.entities.userService.usersByLoggedInUserServiceIdsMetaData
    );
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);
    const usersById = useSelector((state) => state.entities.userService.usersById);

    // USE EFFECTS
    useEffect(() => {
        if (jobSectorDetails?.length < 1) dispatch(loadJobSectorDetails());
        if (vacancyStageDetails?.length < 1) dispatch(loadVacancyStageDetails());
    }, []);

    useEffect(() => {
        if (!currentVacancy) return;

        dispatch(loadBusinessRecord(currentVacancy.businessRecordId));
        setNewEntry(currentVacancy);
        setNewEntry((prev) => ({
            ...prev,
            vacancyClosingDate: currentVacancy.vacancyClosingDate.substring(0, 10)
        }));
        if (currentVacancy?.anticipatedStartDate) {
            setNewEntry((prev) => ({
                ...prev,
                anticipatedStartDate: currentVacancy.anticipatedStartDate.substring(0, 10)
            }));
        }
        if (currentVacancy?.ownerId) {
            const initialBusinessOwner =
                usersById[currentVacancy.ownerId] ||
                users.find((el) => el.id === currentVacancy.ownerId);
            if (!initialBusinessOwner) {
                dispatch(loadUserById(currentVacancy.ownerId));
            } else {
                setBusinessOwnersArray([initialBusinessOwner]);
            }
        }
    }, [currentVacancy]);

    useEffect(() => {
        if (!currentBusiness?.ownerId) return;

        setHiringManagers(
            currentBusiness?.contactCards?.map((el) => ({
                id: el.id,
                name: `${el.firstName} ${el.lastName} ( ${el.officeNumber} )`
            })) || []
        );
    }, [currentBusiness]);

    useEffect(() => {
        setIsDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    useEffect(() => {
        if (!isDisabled) setDisableFields(false);
    }, [isDisabled]);

    useEffect(() => {
        if (!users.length) return;
        let updatedBusinessOwners = users.filter((el) =>
            el.userTypes?.find((entry) => businessOwnerRoles.includes(entry.role))
        );
        if (!updatedBusinessOwners.length && users.length < userMetaData.totalElements) {
            onLoadMoreBusinessOwners();
            return;
        }
        if (newEntry.ownerId && !updatedBusinessOwners.some((el) => el.id === newEntry.ownerId)) {
            // Put selected business owner at the top of dropdown if it's not in the updated business owners array
            const selectedAdvisor = businessOwners.find((el) => el.id === newEntry.ownerId);
            updatedBusinessOwners = [selectedAdvisor, ...updatedBusinessOwners];
        }
        setBusinessOwnersArray(updatedBusinessOwners);
    }, [users]);

    useEffect(() => {
        if (newEntry.ownerId && usersById[newEntry.ownerId]) {
            setBusinessOwnersArray([usersById[newEntry.ownerId]]);
        }
    }, [usersById]);

    useEffect(() => {
        if (errorMessage) {
            setDisableFields(false);
            setIsDisabled(false);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (successMessage.startsWith('Vacancy has been updated')) onNavigate();
    }, [successMessage]);

    // HELPER FNS
    const setBusinessOwnersArray = (businessOwners) => {
        const updatedBusinessOwners = addEmailAddressAsNameToArray(businessOwners);
        setBusinessOwners(updatedBusinessOwners);
    };

    const getPreSelect = (arr, id) => {
        return arr.find((el) => el.id === id) || {};
    };

    const loadingError = () => {
        if (jobSectorDetails?.length < 1) return JOB_SECTOR_DETAILS_ERROR;
        if (vacancyStageDetails?.length < 1) return VACANCY_STAGE_DETAILS_ERROR;
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    const onNavigate = () => {
        clearData();
        navigate('/recruitment_management', {
            state: { accordionPanel: state?.accordionPanel || 'vacancies' }
        });
    };

    const clearData = () => {
        setNewEntry(initialState);
        setKeys(clearKeys(keys));
    };

    // EVENT HANDLERS
    const onPayRateFromChange = (payRateFrom) => {
        clearError('payRateFrom');
        if (payRateFrom && newEntry.payRateTo >= payRateFrom) {
            clearError('payRateTo');
        }
        setNewEntry((prev) => ({ ...prev, payRateFrom }));
    };

    const onPayRateToChange = (payRateTo) => {
        clearError('payRateTo');
        if (payRateTo && newEntry.payRateFrom <= payRateTo) {
            clearError('payRateFrom');
        }
        setNewEntry((prev) => ({ ...prev, payRateTo }));
    };

    const onRemoteChange = (option) => {
        setNewEntry((prev) => ({
            ...prev,
            remote: option
        }));
        if (option) {
            clearError('addressLine1');
            clearError('city');
            clearError('postcode');
        }
    };

    const onUpdate = (key, value) => {
        errors[key] && clearError(key);
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onLoadMoreBusinessOwners = () => {
        if ((!users.length || users.length < userMetaData.totalElements) && !userMetaData.last) {
            dispatch(
                searchUsersByLoggedInUserServiceIds(
                    loggedInUser.serviceIds,
                    !users.length ? 0 : userMetaData.number + 1
                )
            );
        }
    };

    // FORM SUBMIT
    const onSubmit = (e) => {
        e.preventDefault();

        const newErrors = validate(newEntry, UPDATE, currentVacancy);

        if (Object.keys(newErrors)?.length > 0) {
            setErrors(newErrors);
            return;
        }

        setDisableFields(true);
        setIsDisabled(true);
        dispatch(updateVacancy(newEntry));
    };

    // AWAITING CONTENT
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} time={3} />;

    // RENDER
    return (
        <div className={form.formWrapper}>
            <form onSubmit={onSubmit} data-testid="form_start" className={form.form}>
                <h1 className={app.mainHeading}>Edit Vacancy</h1>
                <h2 className={app.sectionHeading}>Vacancy Overview:</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'title'}
                            label={'Vacancy Title'}
                            placeholder={'Enter vacancy title'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            maxLength={100}
                            value={newEntry.title || ''}
                            error={errors.title}
                            onChange={(e) => onUpdate('title', e.target.value)}
                        />
                        <SingleSelect
                            id={'ownerId'}
                            key={keys.ownerId}
                            label={'Vacancy Owner'}
                            placeholder="Select Vacancy Owner..."
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            menuItems={businessOwners}
                            selectedId={newEntry.ownerId}
                            selected={getPreSelect(businessOwners, newEntry.ownerId)}
                            error={errors.ownerId}
                            onLoadMoreItems={onLoadMoreBusinessOwners}
                            moreItemsToLoad={
                                !userMetaData.totalElements ||
                                users.length < userMetaData.totalElements
                            }
                            onChange={(chosenId) => onUpdate('ownerId', chosenId)}
                        />
                        <TextInputField
                            id={'businessName'}
                            label={'Business Record'}
                            disabled={true}
                            value={currentBusiness.name}
                        />
                        <SingleSelect
                            id={'hiringManagerId'}
                            key={keys.hiringManagerId}
                            label={'Hiring Manager'}
                            placeholder="Select Hiring Manager..."
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            menuItems={hiringManagers}
                            selectedId={newEntry.hiringManagerId}
                            selected={getPreSelect(hiringManagers, newEntry.hiringManagerId)}
                            error={errors.hiringManagerId}
                            onChange={(chosenId) => onUpdate('hiringManagerId', chosenId)}
                        />
                        <SingleSelect
                            label={'Job Sector'}
                            key={keys.sectorId}
                            id={'sectorId'}
                            placeholder="Select Job Sector..."
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            menuItems={jobSectorDetails}
                            selectedId={newEntry.sectorId}
                            selected={getPreSelect(jobSectorDetails, newEntry.sectorId)}
                            error={errors.sectorId}
                            onChange={(chosenId) => onUpdate('sectorId', chosenId)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'numberOfPositions'}
                            label={'Number of Positions'}
                            placeholder={'Enter number of Positions'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            maxLength={3}
                            value={newEntry.numberOfPositions || ''}
                            error={errors.numberOfPositions}
                            onChange={(e) =>
                                onUpdate(
                                    'numberOfPositions',
                                    e.target.value.replace(/\D/g, '') || ''
                                )
                            }
                        />
                        <TextAreaField
                            id={'description'}
                            label={'Description'}
                            placeholder={'Enter job description'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            value={newEntry.description || ''}
                            count={`${newEntry.description?.length}/${MAX_DESCRIPTION_LENGTH}`}
                            maxLength={MAX_DESCRIPTION_LENGTH}
                            error={errors.description}
                            onChange={(e) => onUpdate('description', e.target.value)}
                        />
                        <TextAreaField
                            id={'requirement'}
                            label={'Requirements'}
                            placeholder={'Enter job requirements'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            value={newEntry.requirement || ''}
                            count={`${newEntry.requirement?.length}/${MAX_REQUIREMENTS_LENGTH}`}
                            maxLength={MAX_REQUIREMENTS_LENGTH}
                            error={errors.requirement}
                            onChange={(e) => onUpdate('requirement', e.target.value)}
                        />
                    </div>
                </div>
                <h2 className={app.sectionHeading}>Vacancy Detail:</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'payRateFrom'}
                            label={'Hourly Pay Rate - From'}
                            placeholder={'Enter hourly pay rate from'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            maxLength={6}
                            value={newEntry.payRateFrom || ''}
                            error={errors.payRateFrom}
                            onChange={(e) =>
                                onPayRateFromChange(e.target.value.replace(/[^\d*.?]/g, '') || '')
                            }
                        />
                        <TextInputField
                            id={'payRateTo'}
                            label={'Hourly Pay Rate - To'}
                            placeholder={'Enter hourly pay rate to'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            maxLength={6}
                            value={newEntry.payRateTo || ''}
                            error={errors.payRateTo}
                            onChange={(e) =>
                                onPayRateToChange(e.target.value.replace(/[^\d*.?]/g, '') || '')
                            }
                        />
                        <div className={`${form.formColumnSplit}`}>
                            <DateSelect
                                id="vacancyClosingDate"
                                label="Vacancy Closing Date"
                                value={newEntry.vacancyClosingDate || ''}
                                disabled={
                                    !hasRole(acceptedRoles, loggedInUser.roles) || disableFields
                                }
                                mandatory={true}
                                error={errors.vacancyClosingDate}
                                onDateChange={(value) => onUpdate('vacancyClosingDate', value)}
                            />
                            <DateSelect
                                id="anticipatedStartDate"
                                label="Anticipated Start Date"
                                value={newEntry.anticipatedStartDate || ''}
                                disabled={
                                    !hasRole(acceptedRoles, loggedInUser.roles) || disableFields
                                }
                                error={errors.anticipatedStartDate}
                                onDateChange={(value) => onUpdate('anticipatedStartDate', value)}
                            />
                        </div>
                    </div>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'expectedWeeklyHours'}
                            label={'Expected Weekly Hours'}
                            placeholder={'Enter expected weekly hours'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            maxLength={4}
                            value={newEntry.expectedWeeklyHours || ''}
                            error={errors.expectedWeeklyHours}
                            onChange={(e) =>
                                onUpdate(
                                    'expectedWeeklyHours',
                                    e.target.value.replace(/[^\d*.?]/g, '') || ''
                                )
                            }
                        />
                        <TextAreaField
                            id={'shiftPatternWorkingHours'}
                            label={'Shift Patterns/Working Hours'}
                            placeholder={'Enter shift patterns/working hours description'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            value={newEntry.shiftPatternWorkingHours || ''}
                            count={`${newEntry.shiftPatternWorkingHours?.length}/${SHIFT_PATTERN_LENGTH}`}
                            maxLength={SHIFT_PATTERN_LENGTH}
                            error={errors.shiftPatternWorkingHours}
                            onChange={(e) => onUpdate('shiftPatternWorkingHours', e.target.value)}
                        />
                    </div>
                </div>
                <br />
                <br />
                <h2 className={app.sectionHeading}>Address:</h2>
                <RadioButtons
                    id="remote"
                    label="Remote"
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                    value={newEntry.remote}
                    onChange={(option) => onRemoteChange(option)}
                />
                <div hidden={newEntry.remote}>
                    <div className={form.formSection}>
                        <div className={form.formColumn}>
                            <TextInputField
                                id={'address1'}
                                label={'Address Line 1'}
                                placeholder={'Enter address line 1'}
                                disabled={
                                    !hasRole(acceptedRoles, loggedInUser.roles) || disableFields
                                }
                                mandatory={true}
                                value={newEntry.addressLine1 || ''}
                                error={errors.addressLine1}
                                maxLength={ADDRESS_LINE_1_MAX_LENGTH}
                                onChange={(e) => onUpdate('addressLine1', e.target.value)}
                            />
                            <TextInputField
                                id={'address2'}
                                label={'Address Line 2'}
                                placeholder={'Enter address line 2'}
                                disabled={
                                    !hasRole(acceptedRoles, loggedInUser.roles) || disableFields
                                }
                                value={newEntry.addressLine2 || ''}
                                error={errors.addressLine2}
                                maxLength={ADDRESS_LINE_2_MAX_LENGTH}
                                onChange={(e) => onUpdate('addressLine2', e.target.value)}
                            />
                            <TextInputField
                                id={'address3'}
                                label={'Address Line 3'}
                                placeholder={'Enter address line 3'}
                                disabled={
                                    !hasRole(acceptedRoles, loggedInUser.roles) || disableFields
                                }
                                value={newEntry.addressLine3 || ''}
                                error={errors.addressLine3}
                                maxLength={ADDRESS_LINE_3_MAX_LENGTH}
                                onChange={(e) => onUpdate('addressLine3', e.target.value)}
                            />
                        </div>
                        <div className={form.formColumn}>
                            <TextInputField
                                id={'city'}
                                label={'City'}
                                placeholder={'Enter city'}
                                disabled={
                                    !hasRole(acceptedRoles, loggedInUser.roles) || disableFields
                                }
                                mandatory={true}
                                value={newEntry.city || ''}
                                error={errors.city}
                                maxLength={CITY_MAX_LENGTH}
                                onChange={(e) => onUpdate('city', e.target.value)}
                            />
                            <TextInputField
                                id={'postcode'}
                                label={'Postcode'}
                                placeholder={'Enter postcode'}
                                disabled={
                                    !hasRole(acceptedRoles, loggedInUser.roles) || disableFields
                                }
                                mandatory={true}
                                value={newEntry.postcode || ''}
                                error={errors.postcode}
                                onChange={(e) => onUpdate('postcode', e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <h2 className={app.sectionHeading}>Application Process:</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        {currentVacancy?.stageIds?.map((el, i) => (
                            <div key={i}>
                                <div className={form.inputSet}>
                                    <div className={form.inputSetInput}>
                                        <SingleSelect
                                            id={'stageId'}
                                            label={
                                                currentVacancy.stageIds.length === i + 1
                                                    ? 'Final Stage'
                                                    : 'Stage ' + `${i + 1}`
                                            }
                                            disabled={true}
                                            menuItems={vacancyStageDetails}
                                            chosenName={getNameFromId(vacancyStageDetails, el)}
                                            selectedId={el || ''}
                                            selected={getPreSelect(vacancyStageDetails, el)}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={form.formColumn}></div>
                </div>
                <FormActions
                    id="edit"
                    btnText="Update Vacancy"
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles) || isDisabled}
                    error={errors?.button}
                    clearError={() => clearError('button')}
                    onClose={onSubmit}
                    onCancel={onNavigate}
                />
            </form>
        </div>
    );
};

export default EditVacancy;
