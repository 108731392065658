import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardContent, Divider } from '@mui/material';

import {
    loadCourseProvisions,
    searchActiveCourses,
    searchCourseProvisions
} from '../../../store/courseService';
import { selectLocationIdsAndNamesLength } from '../../../store/dataSelectors';
import {
    loadAttendanceDetails,
    loadLocationIdsAndNames,
    loadProvisionStatusDetails
} from '../../../store/directusService';
import { POLARIS_WHITE } from '../../../themes/theme';
import { DEFAULT_TABLE_ROWS_PER_PAGE } from '../../../utils/formValidation/commonConstants';
import { stableSort } from '../../../utils/sortFunctions';
import FormHeader from '../../layout/FormHeader';
import ResultsTable from '../../table/ResultsTable';
import LoadingSpinner from '../../ui/LoadingSpinner';
import CurrentCourseManagement from '../currentCourse/CurrentCourseManagement';
import CurrentProvisionManagement from '../currentProvision/CurrentProvisionManagement';

import AllCourseAndProvisionTableRow from './AllCourseAndProvisionTableRow';

import classes from '../../../commonStyles/tables.module.css';

const headCells = [
    { id: 'code', label: 'ID', sortable: true },
    { id: 'name', label: 'Name', sortable: true },
    { id: 'startDate', label: 'Start Date', sortable: true },
    { id: 'endDate', label: 'End Date', sortable: true },
    { id: 'numberOfModules', label: 'Modules', sortable: true }
];

const AllActiveCourseAndProvisionManagement = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [formattedActiveProvisions, setFormattedActiveProvisions] = useState([]);
    const [openActiveCourseId, setOpenActiveCourseId] = useState('');
    const [openActiveProvisionId, setOpenActiveProvisionId] = useState('');
    const [activeCoursesRowMetaData, setActiveCoursesRowMetaData] = useState({
        order: 'asc',
        orderBy: 'name',
        page: 0,
        rowsPerPage: DEFAULT_TABLE_ROWS_PER_PAGE
    });
    const [activeProvisionsRowMetaData, setActiveProvisionsRowMetaData] = useState({
        order: 'asc',
        orderBy: 'name',
        page: 0,
        rowsPerPage: DEFAULT_TABLE_ROWS_PER_PAGE
    });

    // STORE STATE
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const locationIdsAndNamesLength = useSelector(selectLocationIdsAndNamesLength);
    const provisionStatusDetails = useSelector(
        (state) => state.entities.directusService.provisionStatusDetails
    );
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);

    const activeCourses = useSelector((state) => state.entities.courseService.activeCourses);
    const activeCoursesMetaData = useSelector(
        (state) => state.entities.courseService.activeCoursesMetaData
    );
    const loadingActiveCourses = useSelector(
        (state) => state.entities.courseService.loadingActiveCourses
    );

    const courseProvisions = useSelector((state) => state.entities.courseService.courseProvisions);
    const courseProvisionsMetaData = useSelector(
        (state) => state.entities.courseService.courseProvisionsMetaData
    );
    const loadingProvisions = useSelector(
        (state) => state.entities.courseService.loadingProvisions
    );

    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );

    const activeCoursesSearchData = {
        active: true,
        serviceIds: [currentParticipant.serviceId],
        excludingParticipantId: currentParticipant.id,
        dateFrom: new Date().toISOString().substring(0, 10),
        withAvailableCapacity: 1
    };
    const activeCourseProvisionsSearchData = {
        active: true,
        contractIds: [currentParticipant.contractId],
        excludingParticipantId: currentParticipant.id
    };

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadAttendanceDetails());
        dispatch(loadProvisionStatusDetails());
        dispatch(searchActiveCourses(activeCoursesSearchData, 0));
        dispatch(searchCourseProvisions(activeCourseProvisionsSearchData));
        locationIdsAndNamesLength < 1 && dispatch(loadLocationIdsAndNames());
    }, []);

    useEffect(() => {
        setFormattedActiveProvisions(
            courseProvisions.map((el) => ({
                ...el,
                name: el.title
            }))
        );
    }, [courseProvisions]);

    useEffect(() => {
        if (
            successMessage.startsWith('Module details for course') &&
            successMessage.endsWith('have been updated')
        ) {
            dispatch(searchActiveCourses(activeCoursesSearchData, 0));
        } else if (successMessage === 'Participant has been submitted to provision') {
            dispatch(searchCourseProvisions(activeCourseProvisionsSearchData));
        }
    }, [successMessage]);

    // HELPER FNS
    const createRows = (rows, rowMetaData, onToggleDropdown, openRowId, rowClassName = '') =>
        stableSort(rows, rowMetaData.orderBy, rowMetaData.order).map((el) => (
            <AllCourseAndProvisionTableRow
                key={el.id}
                row={el}
                roles={roles}
                toggleDropdown={onToggleDropdown}
                openId={openRowId}
                rowClassName={rowClassName}
            />
        ));

    // RENDER
    return provisionStatusDetails.length < 1 ? (
        <LoadingSpinner content="No current provision status found" />
    ) : (
        <Card sx={{ backgroundColor: POLARIS_WHITE }} data-testid="form_start">
            <CardContent>
                <Card sx={{ backgroundColor: POLARIS_WHITE }} data-testid="form_start">
                    <CardContent>
                        <div>
                            <FormHeader text={'All Active Courses'}></FormHeader>
                            {activeCoursesMetaData.last && activeCourses.length < 1 ? (
                                <LoadingSpinner content="No active course found" />
                            ) : (
                                <ResultsTable
                                    defaultOrderBy={'name'}
                                    defaultRowsPerPage={activeCoursesRowMetaData.rowsPerPage}
                                    apiParams={activeCoursesSearchData}
                                    headCells={headCells}
                                    loadingValues={loadingActiveCourses}
                                    loadResults={searchActiveCourses}
                                    passRowMetaDataUp={setActiveCoursesRowMetaData}
                                    tableRows={createRows(
                                        activeCourses,
                                        activeCoursesRowMetaData,
                                        (id) => setOpenActiveCourseId(id),
                                        openActiveCourseId
                                    )}
                                    totalResults={activeCoursesMetaData.totalElements}
                                    metaData={activeCoursesMetaData}
                                />
                            )}
                        </div>
                    </CardContent>
                </Card>
                <Divider />
                <Card sx={{ backgroundColor: POLARIS_WHITE }} data-testid="form_start">
                    <CardContent>
                        <div>
                            <FormHeader text={'All Active Provisions'}></FormHeader>
                            {courseProvisionsMetaData.last &&
                            formattedActiveProvisions.length < 1 ? (
                                <LoadingSpinner content="No active provision found" />
                            ) : (
                                <ResultsTable
                                    defaultOrderBy={'name'}
                                    defaultRowsPerPage={activeProvisionsRowMetaData.rowsPerPage}
                                    apiParams={activeCourseProvisionsSearchData}
                                    headCells={headCells}
                                    loadingValues={loadingProvisions}
                                    loadResults={loadCourseProvisions}
                                    passRowMetaDataUp={setActiveProvisionsRowMetaData}
                                    tableRows={createRows(
                                        formattedActiveProvisions,
                                        activeProvisionsRowMetaData,
                                        (id) => setOpenActiveProvisionId(id),
                                        openActiveProvisionId,
                                        classes.lavendar
                                    )}
                                    totalResults={courseProvisionsMetaData.totalElements}
                                    metaData={courseProvisionsMetaData}
                                />
                            )}
                        </div>
                    </CardContent>
                </Card>
                <Divider />
                <CurrentCourseManagement />
                <Divider sx={{ marginTop: '-30px', marginBottom: '10px' }} />
                <CurrentProvisionManagement />
            </CardContent>
        </Card>
    );
};

AllActiveCourseAndProvisionManagement.propTypes = {
    participantId: PropTypes.string
};

export default AllActiveCourseAndProvisionManagement;
