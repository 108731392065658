import React from 'react';
import PropTypes from 'prop-types';

import cancel from './formElementStyles/cancelButton.module.css';

const CancelButton = ({ onClick }) => (
    <div className={cancel.cancelButton} onClick={onClick} data-testid="cancel_btn">
        Cancel X
    </div>
);

CancelButton.propTypes = {
    onClick: PropTypes.func
};

export default CancelButton;
