import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import {
    loadJobSectorDetails,
    loadVacancyRejectionReasonsDetails,
    loadVacancyStageDetails
} from '../../../store/directusService';
import {
    loadVacancySubmissionsByVacancyId,
    setCurrentVacancy
} from '../../../store/recruitmentService';
import { getNameFromId } from '../../../utils/directusFunctions';
import {
    JOB_SECTOR_DETAILS_ERROR,
    VACANCY_REJECTION_REASONS_DETAILS_ERROR,
    VACANCY_STAGE_DETAILS_ERROR
} from '../../../utils/formValidation/loadingErrorMessageConstants';
import LoadingSpinner from '../../ui/LoadingSpinner';

import SubmissionPoolTableManagement from './submissions/SubmissionPoolTableManagement';
import SubmissionsTableManagement from './submissions/SubmissionsTableManagement';

import classes from '../recruitmentStyles/submission.module.css';

const SubmissionManagement = ({ vacancy, accordionPanelFrom, jobsOfferedForVacancies }) => {
    // HOOKS
    const dispatch = useDispatch();
    const [vacancyStageIdsWithReject, setVacancyStageIdsWithReject] = useState([]);
    const [vacancySubmissionsLoaded, setVacancySubmissionsLoaded] = useState(false);

    // LOCAL STATE
    const [jobsOfferedForVacancy, setJobsOfferedForVacancy] = useState(0);

    // STORE STATE
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const jobSectorDetails = useSelector(
        (state) => state.entities.directusService.jobSectorDetails
    );
    const vacancyRejectionReasonsDetails = useSelector(
        (state) => state.entities.directusService.vacancyRejectionReasonsDetails
    );
    const vacancyStageDetails = useSelector(
        (state) => state.entities.directusService.vacancyStageDetails
    );

    // USE EFFECTS
    useEffect(() => {
        if (!jobsOfferedForVacancies) {
            setJobsOfferedForVacancy(vacancy.jobsOffered);
        } else {
            setJobsOfferedForVacancy(
                jobsOfferedForVacancies?.find((entry) => entry.vacancyId === vacancy.id)
                    ?.jobsOffered || 0
            );
        }
        const vacancyStageIds = vacancy.stageIds.map((el) => ({
            id: el,
            name: getNameFromId(vacancyStageDetails, el)
        }));
        const rejectedStatusId = vacancyStageDetails.find(
            (entry) => entry.name.toLowerCase() === 'rejected'
        )?.id;

        const obj = { id: rejectedStatusId, name: 'Rejected' };
        const vacancyStageIdsWithReject = [...vacancyStageIds, obj];
        setVacancyStageIdsWithReject(vacancyStageIdsWithReject);

        dispatch(setCurrentVacancy(vacancy));
        dispatch(loadVacancySubmissionsByVacancyId(vacancy.id));
        jobSectorDetails?.length < 1 && dispatch(loadJobSectorDetails());
        vacancyRejectionReasonsDetails?.length < 1 &&
            dispatch(loadVacancyRejectionReasonsDetails());
        vacancyStageDetails?.length < 1 && dispatch(loadVacancyStageDetails());
    }, []);

    // USE EFFECTS
    useEffect(() => {
        if (
            successMessage === `Vacancy submissions have been loaded for vacancy id: ${vacancy.id}`
        ) {
            setVacancySubmissionsLoaded(true);
        }
    }, [successMessage]);

    // HELPER FNS
    const loadingError = () => {
        if (jobSectorDetails?.length < 1) return JOB_SECTOR_DETAILS_ERROR;
        if (vacancyRejectionReasonsDetails?.length < 1)
            return VACANCY_REJECTION_REASONS_DETAILS_ERROR;
        if (vacancyStageDetails?.length < 1) return VACANCY_STAGE_DETAILS_ERROR;
        if (vacancyStageIdsWithReject?.length < 1) return 'No vacancy stage reject ids';
        if (!vacancySubmissionsLoaded) return 'No vacancy submissions loaded';
    };

    // AWAITING CONTENT
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} />;

    // RENDER
    return (
        <>
            <SubmissionPoolTableManagement />
            <h3 className={`${classes.divider}  ${classes.formHeader}`}>Submissions</h3>
            <SubmissionsTableManagement
                accordionPanelFrom={accordionPanelFrom}
                vacancyStageIdsWithReject={vacancyStageIdsWithReject}
                jobsRemaining={vacancy.numberOfPositions - jobsOfferedForVacancy}
            />
        </>
    );
};

SubmissionManagement.propTypes = {
    vacancy: PropTypes.object,
    accordionPanelFrom: PropTypes.string,
    jobsOfferedForVacancies: PropTypes.array
};

export default SubmissionManagement;
