import { compareUnorderedArrays } from '../../../utils/arrayUtils';
import { NO_UPDATE_ERROR } from '../../../utils/formValidation/commonErrorConstants';
import { getSelectMessage } from '../../../utils/formValidation/errorMessageFunctions';

export const ERROR_NO_CONTRACT = getSelectMessage('contract');

export const initialErrorState = {
    contract: { error: false, message: '' },
    button: { error: false, message: '' }
};

export const validate = (contractId, initialHeadings, newHeadings) => {
    let newErrors = {};

    newErrors = checkForContract(contractId, newErrors);

    if (compareUnorderedArrays(Object.values(initialHeadings), Object.values(newHeadings))) {
        newErrors = {
            ...newErrors,
            button: { error: true, message: NO_UPDATE_ERROR }
        };
    }

    return { newErrors };
};

export const checkForContract = (contractId, errors) => {
    return !contractId
        ? {
              ...errors,
              contract: { error: true, message: ERROR_NO_CONTRACT }
          }
        : errors;
};
