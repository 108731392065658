import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../../api/pagination';
import { loadCourseProvisions } from '../../../store/courseService';
import { loadCallOutcomes } from '../../../store/directusService';
import {
    clearIwsRecordsSearch,
    searchIWSRecords,
    searchParticipants
} from '../../../store/participantService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { DEFAULT_TABLE_ROWS_PER_PAGE } from '../../../utils/formValidation/commonConstants';
import { CALLS_ERROR } from '../../../utils/formValidation/loadingErrorMessageConstants';
import { stableSort } from '../../../utils/sortFunctions';
import { addFullNameToArray } from '../../../utils/userArrayUtils';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import MultiSelect from '../../formElements/MultiSelect';
import ResultsTable from '../../table/ResultsTable';
import LoadingSpinner from '../../ui/LoadingSpinner';

import CallsDueTableRow from './CallsDueTableRow';

import app from '../../../app.module.css';
import form from '../../../commonStyles/formStyles.module.css';
import local from '../homeStyles/calls.module.css';

const PT_LOAD_SIZE = DEFAULT_PAGE_LOAD_SIZE;
const TIME = 30;
const headCells = [
    { id: 'ptCode', label: 'Participant ID', sortable: true },
    { id: 'firstName', label: 'First Name', sortable: true },
    { id: 'lastName', label: 'Last Name', sortable: true },
    { id: 'adviser', label: 'Adviser', sortable: true }
];

const CallsDueManagement = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const acceptedUserTypes = [ADVISER, MANAGER, RECRUITMENT_MANAGER];
    const [adviserIds, setAdviserIds] = useState([]);
    const [advisers, setAdvisers] = useState([]);
    const [preSelectedAdviserIds, setPreSelectedAdviserIds] = useState([]);
    const [preSelectedAdvisers, setPreSelectedAdvisers] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [participantIds, setParticipantIds] = useState([]);
    const [rows, setRows] = useState([]);
    const [showSensitiveCells, setShowSensitiveCells] = useState(false);
    const [rowMetaData, setRowMetaData] = useState({
        order: 'asc',
        orderBy: 'adviser',
        page: 0,
        rowsPerPage: DEFAULT_TABLE_ROWS_PER_PAGE
    });

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);
    const callOutcomes = useSelector((state) => state.entities.directusService.callOutcomes);
    const {
        iwsRecordSearch: iwsRecords,
        iwsRecordSearchMetaData,
        loadingIwsRecords,
        participantsSearch,
        participantsSearchMetaData
    } = useSelector((state) => state.entities.participantService);

    // USE EFFECTS
    useEffect(() => {
        dispatch(clearIwsRecordsSearch());
        callOutcomes?.length < 1 && dispatch(loadCallOutcomes());
    }, []);

    useEffect(() => {
        if (
            advisers.some((el) => el.id === loggedInUser.id) &&
            loggedInUser.roles.some((el) => acceptedUserTypes.includes(el))
        ) {
            if (!preSelectedAdviserIds.includes(loggedInUser.id)) {
                setPreSelectedAdviserIds((prev) => [...prev, loggedInUser.id]);
            }
            if (!preSelectedAdvisers.some((el) => el.id === loggedInUser.id)) {
                setPreSelectedAdvisers((prev) => addFullNameToArray([...prev, loggedInUser]));
            }
        }
    }, [loggedInUser.id, advisers]);

    useEffect(() => {
        const filteredAdvisers = users.filter((el) =>
            el.userTypes?.find((entry) => acceptedUserTypes.includes(entry.role))
        );
        setAdvisers(addFullNameToArray(filteredAdvisers));
    }, [users]);

    useEffect(() => {
        if (iwsRecords?.length < 1) {
            setRows([]);
        } else {
            const ids = [...new Set(iwsRecords.map((el) => el.participantId))];
            setParticipantIds(ids);
            ids.length > 0 && dispatch(searchParticipants({ ids }, 0, PT_LOAD_SIZE));
        }
    }, [iwsRecords]);

    useEffect(() => {
        if (participantsSearch?.length < 1) {
            configResults();
        } else if (!participantsSearchMetaData.last) {
            dispatch(
                searchParticipants(
                    { ids: participantIds },
                    participantsSearchMetaData.number + 1,
                    PT_LOAD_SIZE
                )
            );
        } else {
            setParticipants(participantsSearch);
        }
    }, [participantsSearch, participantsSearchMetaData]);

    useEffect(() => {
        if (participants?.length < 1 || iwsRecords?.length < 1 || !participantsSearchMetaData.last)
            return;
        configResults();
    }, [participants]);

    useEffect(() => {
        searchRecords(adviserIds);
    }, [adviserIds]);

    // HELPER FUNCTIONS
    const configResults = () => {
        const updatedRecords = iwsRecords.map((el) => {
            const pt = participants.find((entry) => entry.id === el.participantId);
            return {
                ...el,
                ptCode: pt?.ptCode || '',
                firstName: pt?.firstName || '',
                lastName: pt?.lastName || '',
                adviser: advisers.find((entry) => entry.id === el.iwsEventOwnerId)?.name || ''
            };
        });
        setRows(updatedRecords);
    };

    const searchRecords = (userIds) => {
        if (adviserIds?.length < 1) {
            setRows([]);
        } else {
            dispatch(
                searchIWSRecords({
                    iwsEventOwnerIds: userIds,
                    dueDate: new Date().toISOString().slice(0, 10),
                    callOutcomeId: callOutcomes.find((el) => el.name === 'Due')?.id || ''
                })
            );
        }
    };

    const createRows = () =>
        stableSort(rows, rowMetaData.orderBy, rowMetaData.order).map((el) => (
            <CallsDueTableRow key={el.id} row={el} showSensitiveCells={showSensitiveCells} />
        ));

    // EVENT HANDLERS
    const onRevealSensitiveData = () => {
        setShowSensitiveCells(!showSensitiveCells);
        setTimeout(
            () => setShowSensitiveCells((showSensitiveCells) => !showSensitiveCells),
            TIME * 1000
        );
    };

    // RENDER
    return (
        <div className={app.accordionContainer}>
            <div className={local.topBar}>
                <div className={local.iconContainer}>
                    <HeadsetMicIcon />
                </div>
                <div className={form.selectWidth}>
                    <MultiSelect
                        id="adviserIds"
                        label="Advisers"
                        placeholder="Advisers"
                        disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                        menuItems={advisers || []}
                        preSelectedIds={preSelectedAdviserIds}
                        preSelects={preSelectedAdvisers}
                        onChange={(chosenIds) => setAdviserIds(chosenIds)}
                    />
                </div>
            </div>
            <div>
                {Object.keys(iwsRecordSearchMetaData).length === 0 ||
                (iwsRecordSearchMetaData.last && rows.length < 1) ? (
                    <LoadingSpinner content={CALLS_ERROR} />
                ) : (
                    <ResultsTable
                        defaultOrderBy={'name'}
                        defaultRowsPerPage={rowMetaData.rowsPerPage}
                        headCells={headCells}
                        loadingValues={loadingIwsRecords}
                        loadResults={loadCourseProvisions}
                        onRevealSensitiveData={onRevealSensitiveData}
                        showSensitiveCells={showSensitiveCells}
                        passRowMetaDataUp={setRowMetaData}
                        tableRows={createRows()}
                        totalResults={iwsRecordSearchMetaData.totalElements}
                        metaData={iwsRecordSearchMetaData}
                    />
                )}
            </div>
        </div>
    );
};

export default CallsDueManagement;
