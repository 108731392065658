import React from 'react';
import PropTypes from 'prop-types';

import TimeSelect from '../../formElements/TimeSelect';

import time from '../styles/schedulePanel.module.css';

const StartAndEndTimesSelect = ({
    startTime,
    endTime,
    onTimeChange,
    isAuditable,
    onSearchAuditItems,
    errors = {},
    disabled = false
}) => (
    <div className={time.timeInputs}>
        <TimeSelect
            id="startTime"
            data-testid="startTime"
            label="Start Time"
            disabled={disabled}
            mandatory={true}
            isAuditable={isAuditable}
            onSearchAuditItems={() => onSearchAuditItems('Start Date', 'Start Time')}
            value={startTime || '08:00'}
            min={'08:00'}
            max={'17:55'}
            error={errors.startTime}
            onTimeChange={(e) => onTimeChange('startTime', e.target.value)}
        />
        <TimeSelect
            id="endTime"
            data-testid="endTime"
            label="End Time"
            disabled={disabled}
            mandatory={true}
            isAuditable={isAuditable}
            onSearchAuditItems={() => onSearchAuditItems('End Date', 'End Time')}
            value={endTime || '09:00'}
            min={'08:05'}
            max={'18:00'}
            error={errors.endTime}
            onTimeChange={(e) => onTimeChange('endTime', e.target.value)}
        />
    </div>
);

StartAndEndTimesSelect.propTypes = {
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    onTimeChange: PropTypes.func,
    isAuditable: PropTypes.bool,
    onSearchAuditItems: PropTypes.func,
    errors: PropTypes.object,
    disabled: PropTypes.bool
};

export default StartAndEndTimesSelect;
