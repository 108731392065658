import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { checkExists, getCommunicationURL } from '../../../../api/commonHTTP';
import { updateGroupCommunication } from '../../../../store/communicationService';
import {
    selectCommunicationOptOutTypes,
    selectEmailCommunicationOptOutTypeId,
    selectSmsCommunicationOptOutTypeId
} from '../../../../store/dataSelectors';
import { loadCommunicationOptOutTypes } from '../../../../store/directusService';
import { searchParticipants, searchParticipantsByIds } from '../../../../store/participantService';
import { selectLoggedInUser, selectUserRoles } from '../../../../store/userSelectors';
import { searchUsersByLoggedInUserServiceIds } from '../../../../store/userService';
import { getNameFromId } from '../../../../utils/directusFunctions';
import { addFullNameToArray } from '../../../../utils/userArrayUtils';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import SingleSelect from '../../../formElements/SingleSelect';
import IconError from '../../../IconError';
import DynamicSearch from '../../../search/DynamicSearch';
import LabelledTextField from '../../../ui/editors/LabelledTextField';
import FormActions from '../../../ui/formActions/FormActions';
import PolarisSwitchSet from '../../../ui/pickers/PolarisSwitchSet';

import AddParticipantTable from './AddParticipantTable';
import RemoveParticipantTable from './RemoveParticipantTable';
import { ERROR_EXISTS, initialErrorState, validateName } from './validateGroupCommunications';

import app from '../../../../app.module.css';
import form from '../../../../commonStyles/formStyles.module.css';
import classes from '../../../courses/courseStyles/courseToolbar.module.css';
import local from '../communicationStyles.module.css';

const EditCommunicationGroups = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const initialState = {
        id: null,
        type: 'Email',
        name: '',
        originalName: '',
        participants: [],
        adviser: {}
    };

    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const adviserRoles = [ADVISER, MANAGER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [typeSwitch, setTypeSwitch] = useState(false);
    const [errors, setErrors] = useState(initialErrorState);
    let msg;

    const [searchTerm, setSearchTerm] = useState('');
    const [participants, setParticipants] = useState([]);
    const [advisers, setAdvisers] = useState([]);
    const [addRows, setAddRows] = useState([]);
    const [newRows, setNewRows] = useState([]);

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const loggedInUser = useSelector(selectLoggedInUser);
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);
    const usersMetaData = useSelector(
        (state) => state.entities.userService.usersByLoggedInUserServiceIdsMetaData
    );
    const participantsSearch = useSelector(
        (state) => state.entities.participantService.participantsSearch
    );
    const participantsSearchMetaData = useSelector(
        (state) => state.entities.participantService.participantsSearchMetaData
    );
    const participantsSearchById = useSelector(
        (state) => state.entities.participantService.participantsSearchById
    );
    const participantsSearchByIdMetaData = useSelector(
        (state) => state.entities.participantService.participantsSearchByIdMetaData
    );
    const communicationOptOutTypes = useSelector(selectCommunicationOptOutTypes);
    const smsCommunicationOptOutTypeId = useSelector(selectSmsCommunicationOptOutTypeId);
    const emailCommunicationOptOutTypeId = useSelector(selectEmailCommunicationOptOutTypeId);
    const participantStatusDetails = useSelector(
        (state) => state.entities.directusService.participantStatusDetails
    );
    const group = useSelector((state) => state.entities.communicationService.groupCommunication);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS
    const setAdvisersArray = (advisers) => {
        const updatedAdvisers = addFullNameToArray(advisers);
        setAdvisers(updatedAdvisers);
    };

    const clearForm = () => {
        setNewEntry(initialState);
        setErrors(initialErrorState);
        setAddRows([]);
        setNewRows([]);
        setParticipants([]);
    };

    // USE EFFECTS
    useEffect(() => {
        if (!communicationOptOutTypes.length) dispatch(loadCommunicationOptOutTypes());
    }, []);

    useEffect(() => {
        if (!loggedInUser?.firstName) return;
        if (!hasRole(adviserRoles, roles)) return;
        setNewEntry((prev) => ({ ...prev, adviser: loggedInUser }));
        setAdvisersArray([loggedInUser]);
    }, [loggedInUser.id]);

    useEffect(() => {
        if (!users.length) return;
        let updatedAdvisers = users.filter((el) =>
            el.userTypes?.find((entry) => adviserRoles.includes(entry.role))
        );
        if (newEntry.adviser?.id && !updatedAdvisers.some((el) => el.id === newEntry.adviser.id)) {
            // Put selected adviser at the top of dropdown if it's not in the updated advisers array
            const selectedAdviser = advisers.find((el) => el.id === newEntry.adviser.id);
            updatedAdvisers = [selectedAdviser, ...updatedAdvisers];
        }
        setAdvisersArray(updatedAdvisers);
    }, [users]);

    useEffect(() => {
        if (
            !group ||
            Object.keys(group).length < 1 ||
            advisers.length < 1 ||
            participantStatusDetails?.length < 1
        )
            return;
        setNewEntry((prev) => ({ ...prev, ...group, originalName: group.name }));
        if (group.participants.length)
            dispatch(
                searchParticipantsByIds({ ids: group.participants.map((el) => el.participantId) })
            );
        setTypeSwitch(group.type === 'SMS');
    }, [group, advisers]);

    useEffect(() => {
        if (!participantsSearchById.length) return;
        if (!participantsSearchByIdMetaData.last) {
            dispatch(
                searchParticipantsByIds(
                    { ids: group.participants.map((el) => el.participantId) },
                    participantsSearchByIdMetaData.number + 1
                )
            );
        } else
            setNewRows(
                group.participants
                    .filter((el) =>
                        participantsSearchById.find((entry) => entry.id === el.participantId)
                    )
                    .map((el) => {
                        const pt = participantsSearchById.find(
                            (entry) => entry.id === el.participantId
                        );
                        return {
                            ...pt,
                            selectedContact: {
                                contactMode: el.contactMode,
                                contactNumber:
                                    el.contactMode === 'SECONDARY_PHONE_NUMBER'
                                        ? pt.secondaryPhoneNumber
                                        : pt.primaryPhoneNumber
                            }
                        };
                    })
            );
    }, [participantsSearchById, participantsSearchByIdMetaData]);

    useEffect(() => {
        newEntry.adviser?.id && dispatch(searchParticipants({ userId: newEntry.adviser.id }));
    }, [newEntry.adviser?.id]);

    useEffect(() => {
        if (participantsSearch?.length < 1 || communicationOptOutTypes?.length < 1) return;
        else if (!participantsSearchMetaData.last) {
            dispatch(
                searchParticipants(
                    { userId: newEntry.adviser.id },
                    participantsSearchMetaData.number + 1
                )
            );
        } else {
            const activeParticipants = participantsSearch.map((el) => ({
                ...el,
                emailAddress: el.emailAddress === '' ? 'z' : el.emailAddress,
                currentStatus: getNameFromId(participantStatusDetails, el.statusId) || 'zz'
            }));
            const newActiveParticipants =
                group.type === 'SMS'
                    ? activeParticipants.filter(
                          (el) =>
                              !el.communicationTypeOptOutIds.includes(
                                  smsCommunicationOptOutTypeId
                              ) && el.primaryPhoneNumber
                      )
                    : activeParticipants.filter(
                          (el) =>
                              !el.communicationTypeOptOutIds.includes(
                                  emailCommunicationOptOutTypeId
                              )
                      );
            setParticipants(newActiveParticipants);
        }
    }, [
        participantsSearch,
        participantsSearchMetaData,
        participantStatusDetails,
        communicationOptOutTypes
    ]);

    useEffect(() => {
        if (successMessage === msg) onFormExit();
    }, [successMessage]);

    useEffect(() => {
        let newAddRows = participants.filter((el) => !newRows.find((entry) => entry.id === el.id));
        if (searchTerm)
            newAddRows = newAddRows.filter(
                (el) =>
                    el.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    el.lastName.toLowerCase().includes(searchTerm.toLowerCase())
            );
        setAddRows(newAddRows);
    }, [participants, newRows, searchTerm]);

    // EVENT HANDLERS

    const onSearch = (searchRes) => setSearchTerm(searchRes);
    const onSwitchType = (e) => {
        const { checked } = e.target;
        setTypeSwitch(checked);
        const type = checked ? 'SMS' : 'Email';
        setNewEntry((prev) => ({ ...prev, type }));
    };

    const onAddRow = (row) => {
        setNewRows((prev) => [...prev, row]);
    };

    const onRemoveRow = (row) => {
        setNewRows(newRows.filter((el) => el.id !== row.id));
    };

    const onAdviserChange = (chosenId) => {
        if (advisers.length < 1) return;
        setNewEntry((prev) => ({
            ...prev,
            adviser: advisers.find((el) => el.id === chosenId)
        }));
        setAddRows([]);
    };

    const onFormExit = () => {
        clearForm();
        navigate('/manage_groups');
    };

    const onLoadMoreAdvisers = () => {
        if (usersMetaData?.last) return;
        dispatch(
            searchUsersByLoggedInUserServiceIds(
                loggedInUser.serviceIds,
                !users.length ? 0 : usersMetaData.number + 1
            )
        );
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validateName(newEntry, errors);
        setErrors(newErrors);
        if (!isValid) return;
        if (newEntry.name !== newEntry.originalName) {
            if (
                await checkExists(
                    getCommunicationURL() + `rest/group-communication/exists/${newEntry.name}`
                )
            ) {
                setErrors((errors) => ({
                    ...errors,
                    name: { error: true, message: ERROR_EXISTS }
                }));
                return;
            }
        }
        msg = `${newEntry.name} has been updated`;
        const participants = newRows.map((el) => ({
            participantId: el.id,
            contactMode: el.selectedContact.contactMode
        }));
        const { id, name, type } = newEntry;
        const payload = { id, name, type, participants };

        dispatch(updateGroupCommunication(payload.id, payload, msg));
        onFormExit();
    };

    return (
        <div className={app.container}>
            <div className={app.mainHeading}>Edit Group</div>
            <div className={app.blueContainer}>
                <form className={form.formWrapper} onSubmit={onSubmit}>
                    <div className={form.formSection}>
                        <div className={form.formColumn}>
                            <LabelledTextField
                                label="Group Name"
                                id="groupName"
                                mandatory={true}
                                disabled={!hasRole(acceptedRoles, roles)}
                                value={newEntry.name}
                                placeholder="Enter value"
                                onChange={(e) =>
                                    setNewEntry((prev) => ({ ...prev, name: e.target.value }))
                                }
                            />
                            {errors.name.error && (
                                <div className={form.textInputErrors}>
                                    <IconError text={errors.name} />
                                </div>
                            )}

                            <div>
                                <PolarisSwitchSet
                                    id="groupContactType"
                                    label="Group Contact Type"
                                    leftLabel="Email"
                                    rightLabel="SMS"
                                    checked={typeSwitch}
                                    onSwitch={onSwitchType}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className={form.formColumn}></div>
                    </div>

                    <div className={form.formSection}>
                        <div className={form.formColumn}>
                            <div className={form.formLabel}>Add Participants</div>
                            <div className={classes.coursesToolBar}>
                                <DynamicSearch
                                    search={onSearch}
                                    searchTerm={searchTerm}
                                    placeholder="Enter Search Term"
                                />
                            </div>
                        </div>
                        <div className={form.formColumn}>
                            <SingleSelect
                                id={'adviser'}
                                label={'Adviser'}
                                placeholder="Select adviser..."
                                disabled={!hasRole(acceptedRoles, roles)}
                                menuItems={advisers || []}
                                selectedId={newEntry.adviser?.id || ''}
                                selected={
                                    advisers?.find((el) => el.id === newEntry.adviser?.id) || {}
                                }
                                onChange={(chosenId) => onAdviserChange(chosenId)}
                                onLoadMoreItems={onLoadMoreAdvisers}
                                moreItemsToLoad={
                                    !usersMetaData?.totalElements ||
                                    users.length < usersMetaData?.totalElements
                                }
                            />
                        </div>
                    </div>

                    <div>
                        <AddParticipantTable
                            rows={addRows}
                            roles={roles}
                            type={newEntry.type}
                            onAddRow={onAddRow}
                        />
                    </div>

                    <div className={local.tableGroup}>
                        <RemoveParticipantTable
                            rows={newRows}
                            roles={roles}
                            type={newEntry.type}
                            onRemoveRow={onRemoveRow}
                        />
                    </div>

                    <FormActions
                        onClose={onSubmit}
                        onCancel={onFormExit}
                        btnText="Update"
                        disabled={!hasRole(acceptedRoles, roles)}
                    />
                </form>
            </div>
        </div>
    );
};

export default EditCommunicationGroups;
