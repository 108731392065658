import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { hasRole, PRAP, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import SingleSelect from '../../formElements/SingleSelect';

import form from '../../../commonStyles/formStyles.module.css';

export const initialReferralsCreatedStatus = {
    contract: {}
};

const ModalContentReferralsCreated = ({ contracts = [], errors, selects, onChange }) => {
    return (
        <div className={form.formSection}>
            <SingleSelect
                id="contractId"
                label="Contract"
                placeholder="Select Contract..."
                mandatory={true}
                menuItems={contracts}
                selectedId={selects.contract?.id || ''}
                selected={
                    (selects.contract?.id &&
                        contracts.find((el) => el.id === selects.contract.id)) ||
                    {}
                }
                error={errors.contract}
                onChange={(id) =>
                    onChange(
                        'contract',
                        contracts.find((el) => el.id === id)
                    )
                }
            />
        </div>
    );
};

export const ModalActionReferralsCreated = ({ onGenerate, onPresent, disabled }) => {
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);

    return (
        <>
            <div>
                <Button
                    id="presentDataSet"
                    disabled={disabled}
                    content="Present"
                    onClick={onPresent}
                />
            </div>
            <div>
                <Button
                    id="generateDataSets"
                    disabled={!hasRole([PRAP, QUALITY, SUPERUSER], roles) || disabled}
                    content="Generate"
                    onClick={onGenerate}
                />
            </div>
        </>
    );
};

export default ModalContentReferralsCreated;

ModalContentReferralsCreated.propTypes = {
    contracts: PropTypes.arrayOf(PropTypes.object),
    errors: PropTypes.object,
    selects: PropTypes.object,
    onChange: PropTypes.func
};

ModalActionReferralsCreated.propTypes = {
    onGenerate: PropTypes.func,
    onPresent: PropTypes.func,
    disabled: PropTypes.bool
};
