import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Switch from '../../formElements/Switch';

const MandatoryActivitiesSwitch = ({ isMandatory, onSwitch, disabled = false }) => {
    // LOCAL STATE
    const [allowParticipantMandatoryActivities, setAllowParticipantMandatoryActivities] =
        useState(true);

    // STORE STATE
    const currentParticipantEmployability = useSelector(
        (state) => state.entities.participantService.currentParticipantEmployability
    );

    // USE EFFECTS
    useEffect(() => {
        if (Object.keys(currentParticipantEmployability).length > 1) {
            setAllowParticipantMandatoryActivities(
                currentParticipantEmployability?.allowMandatoryActivities
            );
        }
    }, [currentParticipantEmployability]);

    // RENDER
    return (
        <div>
            <Switch
                id="isAppointmentMandatory"
                label="Mandatory"
                leftLabel="No"
                rightLabel="Yes"
                disabled={(!allowParticipantMandatoryActivities && !isMandatory) || disabled}
                checked={isMandatory}
                onChange={(e) => onSwitch('mandatory', e.target.checked)}
            />
        </div>
    );
};

MandatoryActivitiesSwitch.propTypes = {
    isMandatory: PropTypes.bool,
    onSwitch: PropTypes.func,
    disabled: PropTypes.bool
};

export default MandatoryActivitiesSwitch;
