import { createSelector } from '@reduxjs/toolkit';

const selectAppointmentAndSlotsByUserEntries = (state) =>
    state.entities.calendarService.appointmentsAndSlotsByUser;

export const selectUsersAppointmentsAndSlots = () =>
    createSelector(
        selectAppointmentAndSlotsByUserEntries,
        (_, id) => id,
        (appointmentsAndSlotsByUser, id) => appointmentsAndSlotsByUser[id] || []
    );

export const selectAllUserAppointmentsAndSlots = createSelector(
    selectAppointmentAndSlotsByUserEntries,
    (appointmentsAndSlotsByUser) => Object.values(appointmentsAndSlotsByUser).flat()
);
