import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getNameFromId } from '../../../../../utils/directusFunctions';

import classes from '../../../../../commonStyles/pdf.module.css';

const VacancyTable = ({ onLoaded, currentParticipant }) => {
    // LOCAL STATE
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState('true');

    // STORE STATE
    const jobSectors = useSelector((state) => state.entities.directusService.jobSectorDetails);
    const users = useSelector((state) => state.entities.userService.users);
    const vacancyStageDetails = useSelector(
        (state) => state.entities.directusService.vacancyStageDetails
    );
    const vacanciesForOwners = useSelector(
        (state) => state.entities.recruitmentService.vacanciesForOwners
    );
    const loadingVacanciesForOwners = useSelector(
        (state) => state.entities.recruitmentService.loadingVacanciesForOwners
    );
    const vacancySubmissions = useSelector(
        (state) => state.entities.recruitmentService.vacancySubmissions
    );
    const loadingVacancySubmissions = useSelector(
        (state) => state.entities.recruitmentService.loadingvacancySubmissions
    );

    // USE EFFECTS
    useEffect(() => {
        if (!loadingVacancySubmissions && !loadingVacanciesForOwners && loading === 'true')
            setLoading('startLoading');
        if (!loadingVacancySubmissions && !loadingVacanciesForOwners && loading === 'startLoading')
            setLoading('false');
    }, [loadingVacancySubmissions, loadingVacanciesForOwners]);

    useEffect(() => {
        if (
            (vacanciesForOwners?.length < 1 || vacancySubmissions?.length < 1) &&
            loading === 'false'
        ) {
            onLoaded('vacancy');
            return;
        }
        if (users?.length < 1 || jobSectors?.length < 1 || vacancyStageDetails?.length < 1) {
            return;
        }

        const participantSubmissions = vacancySubmissions.filter(
            (el) => el.participantId === currentParticipant.id
        );
        const excludedStatuses = ['rejected', 'job offered'];
        const data = vacanciesForOwners
            .filter((el) => participantSubmissions.some((entry) => entry.vacancyId === el.id))
            .map((el) => {
                const user = users.find((entry) => entry.id === el.ownerId);
                const statusId = participantSubmissions.find(
                    (entry) => entry?.vacancyId === el.id
                )?.statusId;

                return {
                    code: el.code,
                    title: el?.title,

                    owner:
                        user?.firstName && user?.lastName
                            ? `${user.firstName} ${user.lastName}`
                            : ' - ',
                    jobSector: getNameFromId(jobSectors, el?.sectorId) || ' - ',
                    payRate: `${el?.payRateFrom || ' - '} - ${el?.payRateTo || ' - '}`,
                    location: el?.remote ? 'Remote' : el?.postcode,
                    status: getNameFromId(vacancyStageDetails, statusId)
                };
            })
            .filter((el) => !excludedStatuses.includes(el.status.toLowerCase()));
        setRows(data);
    }, [vacanciesForOwners, vacancyStageDetails, vacancySubmissions, users, jobSectors, loading]);

    useEffect(() => {
        rows.length > 0 && onLoaded('vacancy');
    }, [rows]);

    // RENDER
    return (
        <div>
            <h3>Current Vacancy</h3>
            {rows.length < 1 ? (
                'No current vacancies'
            ) : (
                <div className={classes.pdfTable}>
                    <div className={classes.pdfTableHeaders}>
                        <span>ID</span>
                        <span>Title</span>
                        <span>Owner</span>
                        <span>Job Sector</span>
                        <span>Pay Rate</span>
                        <span>Location</span>
                        <span>Status</span>
                    </div>

                    <div className={classes.pdfTableRows}>
                        {rows.map((el, i) => (
                            <div
                                key={el.code}
                                id={`${el.code}--${i}`}
                                className={`${classes.pdfTableRow} ${
                                    i % 2 === 0 ? classes.rowPlain : classes.rowFilled
                                }`}>
                                <span>{el.code}</span>
                                <span>{el.title}</span>
                                <span>{el.owner}</span>
                                <span>{el.jobSector}</span>
                                <span>{el.payRate}</span>
                                <span>{el.location}</span>
                                <span>{el.status}</span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

VacancyTable.propTypes = {
    onLoaded: PropTypes.func,
    currentParticipant: PropTypes.object
};

export default VacancyTable;
