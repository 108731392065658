import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '@mui/material';

const SchedulingAssistantEventContent = ({ eventInfo, onNav, eventHeight }) => {
    const {
        participant,
        location,
        appointmentType,
        content,
        timeContent,
        eventType,
        slots,
        filledSlots,
        hasLink,
        displayStart,
        displayEnd,
        hasOverlap
    } = eventInfo.event.extendedProps;

    const renderContent = () => {
        const copy = (
            <>
                {eventInfo.timeText && eventType !== 'hidden' && (
                    <div className="fc-event-time" data-testid="time-content">
                        {timeContent}
                    </div>
                )}
                <div className="fc-event-title-container">
                    <div className="fc-event-title">
                        <div data-testid="display-time-content">
                            {displayStart} - {displayEnd}
                        </div>
                        {eventType !== 'user' && <span className="content-text">{content}</span>}
                        {appointmentType && <span>{appointmentType}</span>}
                        {slots && <span> - {filledSlots} </span>}
                        {participant && !hasLink && <span> - {participant}</span>}
                        {participant && hasLink && (
                            <>
                                <span> - </span>
                                <span
                                    className="event-link"
                                    onClick={() => onNav(eventInfo.event.extendedProps)}>
                                    {participant}
                                </span>
                            </>
                        )}
                        {eventType === 'user' && location && (
                            <span data-testid="event-location"> - {location} </span>
                        )}
                    </div>
                </div>
            </>
        );
        const eventContent = (
            <div
                className="fc-event-main-frame"
                style={{
                    minHeight: hasOverlap ? `${eventHeight / 2}px` : `${eventHeight}px`,
                    height: hasOverlap ? `${eventHeight / 2}px` : `${eventHeight}px`,
                    maxHeight: hasOverlap ? `${eventHeight / 2}px` : `${eventHeight}px`
                }}>
                {copy}
            </div>
        );
        const toolTipContent = <div className="fc-event-main-frame">{copy}</div>;
        return [eventContent, toolTipContent];
    };

    const renderEventContent = () => {
        const [eventContent, toolTipContent] = renderContent(eventInfo);
        return (
            <Tooltip title={toolTipContent} placement="top">
                {eventContent}
            </Tooltip>
        );
    };

    return <>{renderEventContent()}</>;
};

SchedulingAssistantEventContent.propTypes = {
    eventInfo: PropTypes.object,
    onNav: PropTypes.func,
    eventHeight: PropTypes.number
};

export default SchedulingAssistantEventContent;
