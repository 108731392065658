import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../../store/directusService';
import {
    loadContractConstants,
    setCurrentlySelectedParticipant
} from '../../../store/participantService';

import AttendanceRow from './AttendanceRow';

import app from '../../../app.module.css';
import styles from '../styles/startAppointmentButton.module.css';

const StartAppointmentSlotsRows = ({
    participants,
    appointment,
    disabled,
    onPanelClose,
    statuses,
    isAuditable,
    onSearchAuditItems
}) => {
    // HOOKS
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    // LOCAL STATE
    const [rows, setRows] = useState([]);
    const [isPONumberRequired, setIsPONumberRequired] = useState(false);

    // STORE STATE
    const contractDetailsById = useSelector(
        (state) => state.entities.directusService.contractDetailsById
    );
    const servicesByContractId = useSelector(
        (state) => state.entities.directusService.servicesByContractId
    );
    const contractConstants = useSelector(
        (state) => state.entities.participantService.contractConstants
    );

    // USE EFFECTS
    useEffect(() => {
        setRows(participants);
        if (participants.length < 1) return;
        if (!contractConstants || contractConstants.contractId !== participants[0].contractId)
            dispatch(loadContractConstants(participants[0].contractId));
    }, [participants]);

    useEffect(() => {
        if (!contractConstants || contractConstants.contractId !== participants[0]?.contractId)
            return;
        setIsPONumberRequired(contractConstants.requirePoNumberBeforeAppointmentInitialAttended);
    }, [contractConstants, participants]);

    // EVENT HANDLERS
    const onAction = (participant) => {
        dispatch(setCurrentlySelectedParticipant(participant));
        if (!(participant.contractId in contractDetailsById)) {
            dispatch(loadContractDetailsForContractId(participant.contractId));
        }
        if (!(participant.contractId in servicesByContractId)) {
            dispatch(loadServiceDetailsForContractId(participant.contractId));
        }
    };

    const onStartAppointment = async (showWarning, participant) => {
        await onAction(participant);
        const attendanceAppointmentId = appointment.attendances.find(
            (el) => el.participantId === participant.id
        )?.id;
        if (disabled || !attendanceAppointmentId) return;
        onPanelClose();
        navigate('/appointments', {
            state: {
                appointment,
                attendanceAppointmentId,
                showWarning,
                returnPath: location.pathname
            }
        });
    };

    const onNav = async (participant) => {
        if (participant.hidden) return;
        await onAction(participant);
        onPanelClose();
        navigate('/edit_participant');
    };

    // RENDER
    return rows.length < 1 ? null : (
        <div
            className={`${styles.startAppointmentButtons} ${app.fadein}`}
            data-testid={'startSlotAppointmentRows'}>
            {participants.map((el) => (
                <AttendanceRow
                    key={el.id}
                    isDisabled={
                        disabled ||
                        el.hidden ||
                        (statuses[el.id] && statuses[el.id] !== 'Not known')
                    }
                    isAuditable={isAuditable}
                    onSearchAuditItems={() => onSearchAuditItems('Attendance', el.id)}
                    attendanceStatus={statuses[el.id]}
                    appointmentType={appointment?.type.toLowerCase()}
                    isPONumberRequired={isPONumberRequired}
                    participant={el}
                    onNav={() => onNav(el)}
                    onStartAppointment={onStartAppointment}
                />
            ))}
        </div>
    );
};

StartAppointmentSlotsRows.propTypes = {
    participants: PropTypes.arrayOf(PropTypes.object),
    appointment: PropTypes.object,
    disabled: PropTypes.bool,
    onPanelClose: PropTypes.func,
    statuses: PropTypes.object,
    isAuditable: PropTypes.bool,
    onSearchAuditItems: PropTypes.func
};

export default StartAppointmentSlotsRows;
