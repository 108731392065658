import { checkExists, getCourseURL } from '../../../api/commonHTTP';
import { validate } from '../../../utils/formValidation/validator';

import { courseTemplateValidationFields } from './CourseTemplateValidationFields';

const UNIQUE_MODULE_NAME_ERROR =
    'Cannot save. Module names must be unique within a course template.';

export const validateCourseTemplate = (newEntry, singleModule) => {
    let validations;
    validations = validate(
        {
            ...newEntry,
            moduleName: singleModule.name,
            moduleDescription: singleModule.description,
            smartTargetFlag: !!singleModule.smartTarget,
            smartTargetActionName: singleModule.smartTarget?.name,
            smartTargetBarrierName: singleModule.smartTarget?.barrierNameId,
            smartTargetActionDescription: singleModule.smartTarget?.action
        },
        courseTemplateValidationFields
    );

    if (newEntry.modularCourse) {
        if (newEntry.moduleTemplates.length < 2) {
            validations = {
                ...validations,
                isValid: false,
                errors: {
                    ...validations.errors,
                    modules: {
                        error: true,
                        message: 'Modular courses must contain at least 2 modules.'
                    }
                }
            };
        } else if (duplicateModuleNames(newEntry.moduleTemplates)) {
            validations = {
                ...validations,
                isValid: false,
                errors: {
                    ...validations.errors,
                    modules: {
                        error: true,
                        message: UNIQUE_MODULE_NAME_ERROR
                    }
                }
            };
        }
    }

    return validations;
};

const duplicateModuleNames = (modules) => {
    const moduleNames = [...new Set(modules.map((item) => item.name.toLowerCase()))];
    return moduleNames.length < modules.length;
};

export const checkCourseName = async (courseName) => {
    return await checkExists(getCourseURL() + `rest/course-template/exists/${courseName}`).then(
        (ret) => ret
    );
};
