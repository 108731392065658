import React from 'react';
import PropTypes from 'prop-types';

import NorthEastOutlinedIcon from '@mui/icons-material/NorthEastOutlined';
import { Tooltip } from '@mui/material';

import { POLARIS_DARKER_GREY, POLARIS_ROYAL_BLUE } from '../themes/theme';
import { hasRole } from '../utils/userRoles';

import icon from './iconStyles.module.css';

const OnCreateIcon = ({
    onCreate,
    titleString,
    roles,
    acceptedRoles,
    active = true,
    inactiveMessage = 'Not Active'
}) => {
    acceptedRoles = acceptedRoles?.length > 0 ? acceptedRoles : [];

    if (hasRole(roles, acceptedRoles) && active) {
        return (
            <div className={icon.tableIcon}>
                <Tooltip title={'Create ' + titleString} placement="top">
                    <NorthEastOutlinedIcon
                        sx={{ color: POLARIS_ROYAL_BLUE, width: '100%', cursor: 'pointer' }}
                        aria-label="course-shortcut-icon"
                        onClick={onCreate}></NorthEastOutlinedIcon>
                </Tooltip>
            </div>
        );
    }
    return (
        <div className={icon.tableIcon}>
            <Tooltip title={inactiveMessage} placement="top">
                <NorthEastOutlinedIcon
                    sx={{ color: POLARIS_DARKER_GREY, width: '100%', cursor: 'not-allowed' }}
                    aria-label="course-shortcut-icon"></NorthEastOutlinedIcon>
            </Tooltip>
        </div>
    );
};

export default OnCreateIcon;

OnCreateIcon.propTypes = {
    onCreate: PropTypes.func,
    titleString: PropTypes.string,
    data: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    acceptedRoles: PropTypes.arrayOf(PropTypes.string),
    active: PropTypes.bool,
    inactiveMessage: PropTypes.string
};
