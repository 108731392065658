import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    loadParticipantsAdvancedSearch,
    searchFinancialRequests
} from '../../store/participantService';
import { reverseFormatDate } from '../../utils/dateFunctions';
import { stableSort } from '../../utils/sortFunctions';
import ResultsTable from '../table/ResultsTable';

import FinancialRequestsTableRow from './FinancialRequestsTableRow';

import app from '../../app.module.css';

const headCells = [
    { id: 'ptCode', label: 'PT ID', sortable: true },
    { id: 'participantName', label: 'PT Name', sortable: true },
    { id: 'adviserName', label: 'Adviser', sortable: true },
    { id: 'requestedByName', label: 'Requester', sortable: true },
    { id: 'teamName', label: 'Team', sortable: true },
    { id: 'amount', label: 'Amount', sortable: true },
    { id: 'dateIssued', label: 'Payment Date', sortable: true },
    { id: 'dateRequested', label: 'Created Date', sortable: true },
    { id: 'paymentMethod', label: 'Payment Method', sortable: true },
    { id: 'authorisedByName', label: 'Authorised By', sortable: true },
    { id: 'requestType', label: 'Expenditure Type', sortable: true },
    { id: 'paymentReason', label: 'Reason', sortable: true },
    { id: 'evidenceDocument', label: 'Document Attached', sortable: true }
];

const initialRowMetaData = {
    order: 'asc',
    orderBy: 'dateRequested',
    page: 0,
    rowsPerPage: 100
};

const FinancialRequests = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [rows, setRows] = useState([]);
    const [participantIdsForRows, setParticipantIdsForRows] = useState([]);
    const [rowMetaData, setRowMetaData] = useState(initialRowMetaData);

    // STORE STATE
    const participants = useSelector(
        (state) => state.entities.participantService.participantsSearch
    );
    const participantsSearchMetaData = useSelector(
        (state) => state.entities.participantService.participantsSearchMetaData
    );
    const loadingParticipants = useSelector(
        (state) => state.entities.participantService.loadingParticipantsSearch
    );

    const financialRequests = useSelector(
        (state) => state.entities.participantService.financialRequestsSearch
    );
    const financialRequestsMetaData = useSelector(
        (state) => state.entities.participantService.financialRequestsSearchMetaData
    );
    const financialRequestsLoading = useSelector(
        (state) => state.entities.participantService.financialRequestsSearchLoading
    );
    const financialRequestsSearchData = useSelector(
        (state) => state.entities.participantService.financialRequestsSearchData
    );

    const teamsForService = useSelector((state) => state.entities.userService.teamsForService);
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);

    // USE EFFECTS
    useEffect(() => {
        if (financialRequests?.length < 1) {
            setRows([]);
            setRowMetaData(initialRowMetaData);
            return;
        }
        const newParticipantIdsForRows = [
            ...new Set(financialRequests.map((el) => el.participantId))
        ];
        if (newParticipantIdsForRows.length > 0) {
            setParticipantIdsForRows(newParticipantIdsForRows);
            dispatch(loadParticipantsAdvancedSearch({ ids: newParticipantIdsForRows }));
        }
    }, [financialRequests]);

    useEffect(() => {
        if (participants?.length > 0 && !participantsSearchMetaData.last) {
            dispatch(
                loadParticipantsAdvancedSearch(
                    { ids: participantIdsForRows },
                    participantsSearchMetaData.number + 1
                )
            );
        }
    }, [participants, participantsSearchMetaData.last]);

    useEffect(() => {
        if (
            !(participants.length > 0) ||
            loadingParticipants ||
            financialRequestsLoading ||
            (participantIdsForRows.length > 0 && !participantsSearchMetaData.last)
        )
            return;
        const newRows = financialRequests.map((el) => {
            const participant = participants.find((entry) => entry.id === el.participantId);
            const adviser = users.find((entry) => entry.id === participant.userId);
            const team = teamsForService.find((entry) => entry.id === participant.teamId);
            const requestedBy = users.find((entry) => entry.id === el.adviserId);
            const authorisedBy = users.find((entry) => entry.id === el.approverId);
            return {
                ...el,
                dateIssued: el.dateIssued ? reverseFormatDate(el.dateIssued) : '',
                dateRequested: el.dateRequested ? reverseFormatDate(el.dateRequested) : '',
                paymentMethod: el.paymentMethod ?? '',
                paymentReason: el.paymentReason ?? '',
                evidenceDocument: el.evidenceDocumentId ? 'Yes' : 'No',
                ptCode: participant.ptCode,
                participantName: participant
                    ? `${participant.firstName} ${participant.lastName}`
                    : '',
                adviserName: adviser ? `${adviser.firstName} ${adviser.lastName}` : '',
                requestedByName: requestedBy
                    ? `${requestedBy.firstName} ${requestedBy.lastName}`
                    : '',
                teamName: team.name,
                authorisedByName: authorisedBy
                    ? `${authorisedBy.firstName} ${authorisedBy.lastName}`
                    : ''
            };
        });
        setRows(newRows);
    }, [
        financialRequests,
        financialRequestsLoading,
        loadingParticipants,
        participantIdsForRows,
        participantsSearchMetaData.last
    ]);

    // HELPER FNS
    const createRows = () =>
        stableSort(rows, rowMetaData.orderBy, rowMetaData.order).map((el) => (
            <FinancialRequestsTableRow key={el.id} row={el} />
        ));

    // RENDER
    return (
        <div className={app.container}>
            <h2 className={app.mainHeading}>Data Sets Results Screen</h2>
            {rows?.length < 1 ? (
                <div>No financial requests found</div>
            ) : (
                <ResultsTable
                    defaultOrderBy={'dateRequested'}
                    headCells={headCells}
                    loadingValues={financialRequestsLoading || loadingParticipants}
                    loadResults={searchFinancialRequests}
                    passRowMetaDataUp={setRowMetaData}
                    apiParams={financialRequestsSearchData}
                    tableRows={createRows()}
                    totalResults={financialRequestsMetaData.totalElements}
                    metaData={financialRequestsMetaData}
                />
            )}
        </div>
    );
};

export default FinancialRequests;
