import React, { useEffect, useRef } from 'react';
import { format } from 'date-fns';
import { jsPDF } from 'jspdf';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';

import AddDocument from '../../../documents/AddDocument';

import classes from '../../../../commonStyles/pdf.module.css';

const GenerateAppointmentPDF = ({
    children,
    onSavePDF,
    onCancelPDF,
    fileName,
    appointmentType,
    mandatoryAppointment,
    appointmentLocationId,
    attendanceStatusId,
    exitFormTemplateId,
    doubtFormId,
    complianceActivityFormQuestionnaireId,
    isLoaded,
    currentAppointment
}) => {
    const navigate = useNavigate();

    const contentTemplateContainerTemplateRef = useRef(null);
    const documentId =
        currentAppointment?.calendarAppointmentId?.slice(-9) || uuid().slice(-9) || '-';

    // USE EFFECTS
    useEffect(() => {
        if (!contentTemplateContainerTemplateRef?.current) return;
        isLoaded && generatePdf();
    }, [isLoaded]);

    // HELPER FNS
    const createFooter = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        const pageSize = doc.internal.pageSize;
        doc.setFontSize(8);
        Array.from({ length: pageCount }, (_, i) => i + 1).forEach((pageNumber) => {
            doc.setPage(pageNumber);
            const footerText = `    Page ${pageNumber} of ${pageCount} ${' '.repeat(110)}Printed on ${format(new Date(), 'EEEE, MMMM dd, yyyy HH:mm')} Document ID : ${documentId}`; //+
            doc.text(footerText, 1, pageSize.height - 3, { align: 'left' });
        });
    };

    const generatePdf = () => {
        const doc = new jsPDF();
        doc.setFontSize(12);
        doc.html(contentTemplateContainerTemplateRef.current, {
            async callback(doc) {
                createFooter(doc);
                doc.save(fileName);
            },
            autoPaging: 'text',
            margin: [10, 0, 10, 0],
            width: 210,
            windowWidth: 1050
        });
    };

    // EVENT HANDLERS
    const onExit = () => {
        onSavePDF();
        navigate('/edit_participant', {
            state: {
                attendanceStatusId,
                appointmentTypeName: appointmentType.name,
                mandatoryAppointment,
                appointmentLocationId,
                exitFormTemplateId,
                complianceActivityFormQuestionnaireId,
                doubtFormId
            }
        });
    };

    // RENDER
    return (
        <>
            <div
                className={classes.pdfWrapper}
                id="contentTemplateContainer"
                data-testid="contentTemplateContainer"
                ref={contentTemplateContainerTemplateRef}>
                {children}
            </div>

            <div className={classes.addDocumentWrapper}>
                <AddDocument
                    onClose={onExit}
                    onCancel={onCancelPDF}
                    btnText="Complete"
                    currentDocumentType={appointmentType}
                    hideCancel={true}
                />
            </div>
        </>
    );
};
GenerateAppointmentPDF.propTypes = {
    children: PropTypes.element,
    onSavePDF: PropTypes.func,
    onCancelPDF: PropTypes.func,
    fileName: PropTypes.string,
    appointmentType: PropTypes.object,
    mandatoryAppointment: PropTypes.bool,
    appointmentLocationId: PropTypes.string,
    attendanceStatusId: PropTypes.string,
    exitFormTemplateId: PropTypes.string,
    doubtFormId: PropTypes.string,
    complianceActivityFormQuestionnaireId: PropTypes.string,
    isLoaded: PropTypes.bool,
    currentAppointment: PropTypes.object
};
export default GenerateAppointmentPDF;
