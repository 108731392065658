import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@mui/material';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../api/pagination';
import { selectAttachmentClaimTypeId } from '../../store/dataSelectors';
import {
    loadClaimEventStatuses,
    loadClaimEventTypes,
    loadDocumentTypeIdsAndNames,
    loadDocumentTypeIdsAndNamesForContract
} from '../../store/directusService';
import { loadParticipantDocumentDetails } from '../../store/documentService';
import { deleteFileUploadErrorEntries } from '../../store/documentService';
import { selectCurrentParticipant } from '../../store/participantSelectors';
import { loadContractConstants, searchClaimQueue } from '../../store/participantService';
import { selectLoggedInUser, selectUserRoles, selectUsers } from '../../store/userSelectors';
import { loadUsersByUserIds } from '../../store/userService';
import { formatDate, formatDateTime, formatTime } from '../../utils/dateFunctions';
import { stableSort } from '../../utils/sortFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    PRAP,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../utils/userRoles';
import ParticipantStickyMenu from '../navigation/participant/ParticipantStickyMenu';
import DynamicSearch from '../search/DynamicSearch';
import ResultsTable from '../table/ResultsTable';
import LoadingSpinner from '../ui/LoadingSpinner';

import AddDocument from './AddDocument';
import DocumentsTableRow from './DocumentsTableRow';
import EditDocument from './EditDocument';

import '../../commonStyles/antUploaderStyles.css';
import base from '../../app.module.css';
import classes from './documents.module.css';

const headCells = [
    { id: 'filename', numeric: false, label: 'Name', sortable: true },
    { id: 'typeName', numeric: false, label: 'Document Type', sortable: true },
    { id: 'dateCreated', numeric: false, label: 'Date Added', sortable: true },
    { id: 'time', numeric: true, label: 'Time Added', sortable: true },
    { id: 'userName', numeric: false, label: 'Uploaded by', sortable: true },
    { id: 'dateModified', numeric: false, label: 'Updated', sortable: true },
    { id: 'status', numeric: false, label: 'Uploaded Status', sortable: true }
];

const loadPageSize = DEFAULT_PAGE_LOAD_SIZE;
const defaultRowsPerPage = 5;

const Documents = () => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const deleteRoles = [PRAP, QUALITY, SUPERUSER];
    const [searchTerm, setSearchTerm] = useState('');
    const [currentParticipantId, setCurrentParticipantId] = useState('');
    const [docs, setDocs] = useState([]);
    const [rows, setRows] = useState([]);
    const [row, setRow] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [formType, setFormType] = useState('');
    const [stateCount, setStateCount] = useState(0);
    const [rowMetaData, setRowMetaData] = useState({
        order: 'asc',
        orderBy: 'dateCreated',
        page: 0,
        rowsPerPage: defaultRowsPerPage
    });

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const loggedInUser = useSelector(selectLoggedInUser);
    const currentParticipant = useSelector(selectCurrentParticipant);
    const attachmentClaimTypeId = useSelector(selectAttachmentClaimTypeId);
    const users = useSelector(selectUsers);
    const {
        participantDocuments,
        participantDocumentsMetaData,
        loadingParticipantDocumentDetails
    } = useSelector((state) => state.entities.documentService);
    const {
        documentTypeIdsAndNames: documentTypes,
        claimEventTypes,
        claimEventStatuses
    } = useSelector((state) => state.entities.directusService);

    // USE EFFECTS

    useEffect(() => {
        if (claimEventTypes?.length < 1) dispatch(loadClaimEventTypes());
        if (claimEventStatuses?.length < 1) dispatch(loadClaimEventStatuses());
        if (documentTypes?.length < 1) dispatch(loadDocumentTypeIdsAndNames());
        setDocs([]);
        setRows([]);
    }, []);

    useEffect(() => {
        if (!currentParticipant?.id || claimEventStatuses?.length < 1 || !attachmentClaimTypeId)
            return;
        dispatch(loadParticipantDocumentDetails(currentParticipant.id));
        dispatch(loadDocumentTypeIdsAndNamesForContract(currentParticipant.contractId));
        dispatch(loadContractConstants(currentParticipant.contractId));
        const resolutionStatusIds = claimEventStatuses
            .filter(
                (el) =>
                    el.name.toLowerCase() === 'verified' ||
                    el.name.toLowerCase() === 'paid' ||
                    el.name.toLowerCase() === 'claimed' ||
                    el.name.toLowerCase() === 'unclaimable'
            )
            .map((el) => el.id);
        dispatch(
            searchClaimQueue(
                {
                    eventTypeId: attachmentClaimTypeId,
                    statusIds: resolutionStatusIds,
                    ptCodes: [currentParticipant.ptCode]
                },
                0
            )
        );
    }, [currentParticipant, claimEventStatuses, attachmentClaimTypeId]);

    useEffect(() => {
        if (currentParticipant?.id !== currentParticipantId) {
            setCurrentParticipantId(currentParticipant?.id);
            dispatch(deleteFileUploadErrorEntries());
        }
        if (Array.isArray(participantDocuments) && participantDocuments?.length) {
            const allUserIds = [...participantDocuments.map((el) => el.userId), loggedInUser.id];
            const userIds = [...new Set(allUserIds)];
            const pendingState = participantDocuments?.some(
                (el) => el.status.toUpperCase() === 'PENDING'
            );
            if (!pendingState && userIds.length > 0 && users?.length < 1)
                dispatch(loadUsersByUserIds(userIds));
            if (stateCount < 18 && pendingState) {
                setTimeout(() => {
                    dispatch(loadParticipantDocumentDetails(currentParticipant.id));
                    setStateCount(stateCount + 1);
                }, 10000);
            }
        }
    }, [participantDocuments]);

    useEffect(() => {
        if (!Array.isArray(participantDocuments) || documentTypes?.length < 1 || users?.length < 1)
            return;
        const docs = participantDocuments?.filter(
            (el) => el.participantId === currentParticipant.id
        );
        const rows = docs.map((el) => {
            const user = users.find((entry) => entry.id === el.userId);
            return {
                ...el,
                userName: user ? `${user.firstName} ${user.lastName}` : ' - ',
                typeName: documentTypes?.find((entry) => entry.id === el.typeId)?.name || ' - ',
                dateCreated: formatDate(el.dateCreated) || ' - ',
                time: formatTime(el.dateCreated) || ' - ',
                dateModified: formatDateTime(el.dateModified) || ' - '
            };
        });
        setRows(rows);
        setDocs(rows);
    }, [documentTypes, users, participantDocuments]);

    useEffect(() => {
        if (!docs.length) return;
        const rows = !searchTerm
            ? docs
            : docs.filter(
                  (el) =>
                      el.filename?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                      el.typeName?.toLowerCase().includes(searchTerm.toLowerCase())
              );
        setRows(rows);
    }, [searchTerm]);

    // HELPER FNS

    const createRows = () => {
        return stableSort(rows, rowMetaData.orderBy, rowMetaData.order).map((el) => (
            <DocumentsTableRow
                key={el.id}
                row={el}
                roles={roles}
                onEdit={onEdit}
                acceptedRoles={acceptedRoles}
                deleteRoles={deleteRoles}
            />
        ));
    };

    // EVENT HANDLERS
    const onSearch = (searchRes) => setSearchTerm(searchRes);

    const onAdd = () => {
        setFormType('create');
        !showForm && setShowForm(true);
    };
    const onEdit = (row) => {
        setFormType('edit');
        !showForm && setShowForm(true);
        setRow(row);
    };

    const onClose = () => setShowForm(false);

    return (
        <div className={classes.documentsWrapper}>
            <div className={base.withSideBar}>
                <div className={classes.content}>
                    <div className={base.mainHeading}>Documents</div>
                    <div className={classes.toolbar}>
                        <DynamicSearch
                            search={onSearch}
                            searchTerm={searchTerm}
                            placeholder="Enter Search Term"
                            disabled={!hasRole([SUPERUSER, ADVISER, MANAGER, QUALITY], roles)}
                        />
                    </div>
                    <div>
                        {participantDocumentsMetaData.last && rows.length < 1 ? (
                            <div className={classes.noContent}>
                                <LoadingSpinner content="No documents found" />
                            </div>
                        ) : (
                            <ResultsTable
                                defaultOrderBy={rowMetaData.orderBy}
                                defaultRowsPerPage={rowMetaData.rowsPerPage}
                                loadingValues={loadingParticipantDocumentDetails}
                                headCells={headCells}
                                loadResults={loadParticipantDocumentDetails}
                                passRowMetaDataUp={setRowMetaData}
                                apiParams={currentParticipant.id}
                                tableRows={createRows()}
                                totalResults={participantDocumentsMetaData.totalElements}
                                metaData={participantDocumentsMetaData}
                                loadPageSize={loadPageSize}
                            />
                        )}
                    </div>
                    <div
                        className={`${classes.formContent} ${
                            showForm ? classes.showForm : classes.hideForm
                        }`}>
                        {showForm ? (
                            formType === 'create' ? (
                                <AddDocument onClose={onClose} onCancel={onClose} />
                            ) : (
                                <EditDocument row={row} onClose={() => setShowForm(false)} />
                            )
                        ) : (
                            <Button
                                disabled={!hasRole([SUPERUSER, ADVISER, MANAGER, QUALITY], roles)}
                                color="primary"
                                variant="contained"
                                size={'small'}
                                onClick={onAdd}>
                                Add
                            </Button>
                        )}
                    </div>
                </div>
                <div className={base.sideBar}>
                    <ParticipantStickyMenu />
                </div>
            </div>
        </div>
    );
};

export default Documents;
