// COMMON FIELDS TO BUILD VALIDATIONS
import {
    ADDRESS_LINE_1_MAX_LENGTH,
    ADDRESS_LINE_2_MAX_LENGTH,
    ADDRESS_LINE_3_MAX_LENGTH,
    ADDRESS_LINE_4_MAX_LENGTH,
    CITY_MAX_LENGTH,
    COUNTY_MAX_LENGTH,
    MAX_EMAIL,
    MAX_PHONE,
    MIN_EMAIL,
    MIN_PHONE
} from './commonConstants';
import { charVal, names, regExps } from './validationPatterns';

export const commonFieldValidations = {
    address1: {
        subject: `${names.ADDRESS} 1`,
        charMax: ADDRESS_LINE_1_MAX_LENGTH
    },
    address2: {
        subject: `${names.ADDRESS} 2`,
        charMax: ADDRESS_LINE_2_MAX_LENGTH
    },
    address3: {
        subject: `${names.ADDRESS} 3`,
        charMax: ADDRESS_LINE_3_MAX_LENGTH
    },
    address4: {
        subject: `${names.ADDRESS} 4`,
        charMax: ADDRESS_LINE_4_MAX_LENGTH
    },
    businessOwnerId: {
        subject: names.BUSINESS_OWNER_ID,
        indefiniteArticle: 'a',
        required: 'select'
    },
    city: {
        subject: names.CITY,
        charMax: CITY_MAX_LENGTH
    },
    contractId: {
        subject: names.CONTRACT_ID,
        indefiniteArticle: 'a',
        required: 'select'
    },
    county: {
        subject: names.COUNTY,
        charMax: COUNTY_MAX_LENGTH
    },
    emailAddress: {
        subject: names.EMAIL,
        charMin: MIN_EMAIL,
        charMax: MAX_EMAIL,
        format: regExps.EMAIL_R
    },
    firstName: {
        subject: names.FIRST_NAME,
        charMin: charVal.MIN_NAME,
        charMax: charVal.MAX_NAME,
        format: regExps.NAME_R
    },
    lastName: {
        subject: names.LAST_NAME,
        charMin: charVal.MIN_NAME,
        charMax: charVal.MAX_NAME,
        format: regExps.LAST_NAME_R
    },
    middleName: {
        subject: names.MIDDLE_NAME,
        charMin: charVal.MIN_NAME,
        charMax: charVal.MAX_NAME,
        format: regExps.NAME_R
    },
    postcode: {
        subject: names.POSTCODE,
        format: regExps.POSTCODE_R
    },
    preferredName: {
        subject: names.PREFERRED_NAME,
        charMin: charVal.MIN_NAME,
        charMax: charVal.MAX_NAME,
        format: regExps.NAME_R
    },
    primaryPhoneNumber: {
        subject: names.PHONE,
        charMin: MIN_PHONE,
        charMax: MAX_PHONE,
        format: regExps.PHONE_R
    },
    repeatUniqueIdentifier: {
        subject: 'Repeat Unique Identifier',
        conditional: [
            {
                condition: (entry) => entry.uniqueIdentifierType === 'NINO',
                subject: `Repeat ${names.NINO}`,
                matches: { sub: 'uniqueIdentifier', name: names.NINO }
            },
            {
                condition: (entry) => entry.uniqueIdentifierType === 'NHS_NUMBER',
                subject: `Repeat ${names.NHS_NUMBER}`,
                matches: { sub: 'uniqueIdentifier', name: names.NHS_NUMBER }
            }
        ],
        required: 'text'
    },
    serviceId: {
        subject: names.SERVICE_ID,
        indefiniteArticle: 'a',
        required: 'select'
    },
    teamId: {
        subject: names.TEAM_ID,
        indefiniteArticle: 'a',
        required: 'select'
    },
    uniqueIdentifier: {
        subject: 'Unique Identifier',
        required: 'text',
        conditional: [
            {
                condition: (entry) => entry.uniqueIdentifierType === 'NINO',
                subject: names.NINO,
                format: regExps.NINO_R
            },
            {
                condition: (entry) => entry.uniqueIdentifierType === 'NHS_NUMBER',
                subject: names.NHS_NUMBER,
                format: regExps.NUMBERS_R,
                length: charVal.NHS_LENGTH,
                nhsChecksum: true
            }
        ]
    },
    website: {
        subject: names.WEBSITE_URL,
        format: regExps.WEBSITE_R
    }
};
