import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectCurrentParticipant } from '../../../store/participantSelectors';
import { updateParticipant } from '../../../store/participantService';
import { selectUserRoles } from '../../../store/userSelectors';
import {
    ADDRESS_LINE_1_MAX_LENGTH,
    ADDRESS_LINE_2_MAX_LENGTH,
    ADDRESS_LINE_3_MAX_LENGTH,
    CITY_MAX_LENGTH,
    MAX_EMAIL,
    MAX_PHONE,
    MAX_POSTCODE
} from '../../../utils/formValidation/commonConstants';
import { hasRole } from '../../../utils/userRoles';
import TextInputField from '../../formElements/TextInputField';
import FormActions from '../../ui/formActions/FormActions';

import { validate } from './validation/validateGeneral';

import app from '../../../app.module.css';
import form from '../../../commonStyles/formStyles.module.css';
import local from './styles/appointments.module.css';

const General = ({ onClose, acceptedRoles }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        address1: '',
        address2: '',
        address3: '',
        city: '',
        postcode: '',
        emailAddress: '',
        primaryPhoneNumber: '',
        secondaryPhoneNumber: ''
    };

    const [errors, setErrors] = useState({});
    const [initialStateOnEntry, setInitialStateOnEntry] = useState({});
    const [newEntry, setNewEntry] = useState(initialState);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const currentParticipant = useSelector(selectCurrentParticipant);

    // USE EFFECTS
    useEffect(() => {
        if (currentParticipant?.id) {
            setNewEntry(currentParticipant);
            setInitialStateOnEntry(currentParticipant);
        }
    }, [currentParticipant]);

    useEffect(() => {
        if (!acceptedRoles) return;
        setIsDisabled(!hasRole(acceptedRoles, roles));
    }, [roles]);

    useEffect(() => {
        setIsButtonDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    // HELPER FNS
    const clearError = (key) => {
        if (key in errors && errors[key].error)
            setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    const resetFields = () => {
        setNewEntry(currentParticipant);
        setErrors({});
    };

    // EVENT HANDLERS
    const onUpdate = (key, value) => {
        clearError(key);
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onCancel = () => {
        resetFields();
        onClose('general');
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const newErrors = validate(newEntry, initialStateOnEntry);
        if (Object.values(newErrors).some((el) => el.error)) {
            setErrors(newErrors);
            return;
        }
        dispatch(updateParticipant(newEntry));
        resetFields();
        onClose('general');
    };

    return Object.keys(currentParticipant).length < 1 ? null : (
        <div className={local.generalSection}>
            <form
                className={form.form}
                onSubmit={onSubmit}
                data-testid={'appointments-general-form'}>
                <h2 className={app.sectionHeading}>Participant Details</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            id="address1"
                            label="Address 1"
                            placeholder="Address 1"
                            mandatory={true}
                            disabled={isDisabled}
                            maxLength={ADDRESS_LINE_1_MAX_LENGTH}
                            value={newEntry.address1 || ''}
                            error={errors.address1}
                            onChange={(e) => onUpdate('address1', e.target.value)}
                        />
                        <TextInputField
                            id="address2"
                            label="Address 2"
                            placeholder="Address 2"
                            disabled={isDisabled}
                            maxLength={ADDRESS_LINE_2_MAX_LENGTH}
                            value={newEntry.address2 || ''}
                            onChange={(e) => onUpdate('address2', e.target.value)}
                        />
                        <TextInputField
                            id="address3"
                            label="Address 3"
                            placeholder="Address 3"
                            disabled={isDisabled}
                            maxLength={ADDRESS_LINE_3_MAX_LENGTH}
                            value={newEntry.address3 || ''}
                            onChange={(e) => onUpdate('address3', e.target.value)}
                        />
                        <TextInputField
                            id="city"
                            label="Town/City"
                            placeholder="Town/City"
                            disabled={isDisabled}
                            maxLength={CITY_MAX_LENGTH}
                            value={newEntry.city || ''}
                            onChange={(e) => onUpdate('city', e.target.value)}
                        />
                        <TextInputField
                            id="postcode"
                            label="Postcode"
                            placeholder="Postcode"
                            mandatory={true}
                            disabled={isDisabled}
                            maxLength={MAX_POSTCODE}
                            value={newEntry.postcode || ''}
                            error={errors.postcode}
                            onChange={(e) => onUpdate('postcode', e.target.value)}
                        />
                    </div>

                    <div className={form.formColumn}>
                        <TextInputField
                            id="emailAddress"
                            label="Email Address"
                            placeholder="Email address"
                            disabled={isDisabled}
                            maxLength={MAX_EMAIL}
                            value={newEntry.emailAddress || ''}
                            error={errors.emailAddress}
                            onChange={(e) => onUpdate('emailAddress', e.target.value)}
                        />
                        <TextInputField
                            id="primaryPhoneNumber"
                            label="Primary Phone Number"
                            placeholder="Primary phone number"
                            disabled={isDisabled}
                            maxLength={MAX_PHONE}
                            value={newEntry.primaryPhoneNumber || ''}
                            error={errors.primaryPhoneNumber}
                            onChange={(e) => onUpdate('primaryPhoneNumber', e.target.value)}
                        />
                        <TextInputField
                            id="secondaryPhoneNumber"
                            label="Secondary Phone Number"
                            placeholder="Secondary phone number"
                            disabled={isDisabled}
                            maxLength={MAX_PHONE}
                            value={newEntry.secondaryPhoneNumber || ''}
                            error={errors.secondaryPhoneNumber}
                            onChange={(e) => onUpdate('secondaryPhoneNumber', e.target.value)}
                        />
                    </div>
                </div>
                <div className={local.generalFormActions}>
                    <FormActions
                        onClose={onSubmit}
                        onCancel={onCancel}
                        btnText="Update"
                        disabled={isButtonDisabled}
                        error={errors.button}
                        clearError={() => clearError('button')}
                    />
                </div>
            </form>
        </div>
    );
};

export default General;

General.propTypes = {
    onClose: PropTypes.func,
    acceptedRoles: PropTypes.arrayOf(PropTypes.string)
};
