import { loadServiceDetailsByService } from '../../../store/directusService';
import { POLARIS_GREY, POLARIS_ROYAL_BLUE, POLARIS_WHITE } from '../../../themes/theme';

export const getBusinessHours = () => {
    return {
        startTime: '08:00',
        endTime: '18:00',
        daysOfWeek: [1, 2, 3, 4, 5]
    };
};

export const getServiceIds = (user) => {
    return 'primaryService' in user && 'otherServices' in user
        ? [user.primaryService, ...user.otherServices]
        : user.serviceIds;
};

export const fetchLoggedInUserData = (loggedInUser, dispatch) => {
    const serviceIds = getServiceIds(loggedInUser);
    serviceIds?.length > 0 && dispatch(loadServiceDetailsByService(serviceIds));
};

export const mapCommonParams = (el, locations = []) => {
    const date = el.date?.slice(0, 10) || '';
    const start = date ? `${date}T${el.startTime}` : '';
    const end = date ? `${date}T${el.endTime}` : '';
    const location = locations?.find((entry) => entry.id === el.locationId)?.name || '';
    return { date, start, end, location };
};

export const mapColors = (id, currentId) => {
    let color, textColor;
    if (id === currentId) {
        color = POLARIS_GREY;
        textColor = POLARIS_ROYAL_BLUE;
    } else {
        color = POLARIS_ROYAL_BLUE;
        textColor = POLARIS_WHITE;
    }
    return { color, textColor };
};

export const configCourseEventContent = (el, courses, location) => {
    const courseName = courses.find((entry) => entry.id === el.courseId)?.name || '';
    return `${courseName} - ${el.title}`.concat(`${location ? ` - ${location}` : ''}`);
};

export const configParticipantEventContent = (el, appointmentTypes, participants = []) => {
    const appointmentType = appointmentTypes.find((entry) => entry.id === el.typeId)?.name || '';
    let participantEntry = null;
    let participant = '';
    if (participants.length && el.participantId) {
        participantEntry = participants?.find((entry) => entry.id === el.participantId);
        participant = participantEntry
            ? `${participantEntry?.firstName} ${participantEntry?.lastName}`
            : '';
    }
    const slots = el?.numberOfSlots;
    const filled = el?.participantIds?.length || 0;
    const filledSlots = `${filled}/${slots}`;
    return { appointmentType, participantEntry, participant, slots, filledSlots };
};

export const formatFullCalendarTimes = (time) => {
    const { start, end } = time;
    const date = start?.slice(0, 10);
    const startTime = start?.split('T')[1]?.slice(0, 5);
    const endTime = end?.split('T')[1]?.slice(0, 5);
    return { date, startTime, endTime };
};

export const unresolvedStatuses = ['Not known', 'Cancelled - Adviser', 'Cancelled - Participant'];
