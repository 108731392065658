import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';

import { POLARIS_DOUBLE_DECKER_RED, SCREEN_SETTINGS } from '../../../themes/theme';
import { NO_SELECTION } from '../../../utils/formValidation/commonConstants';
import IconError from '../../IconError';

import form from '../../../commonStyles/formStyles.module.css';

/**
 * Let user select an option from a list with a fuzzy search
 * The selected item is shown within the select box.
 *
 * @param heading shown as heading. E.g. 'Housing'
 * @param id Used to create id and data-testid
 * @param disabled Disables control if true. Default = false
 * @param mandatory Shows Mandatory component if true. Default = false;
 * @param error  This has error to display in IconError
 * @param chosenName name of selected item to show as selected. Empty string by default
 * @param menuItems array of names of items to show as selected. Empty array by default
 * @param chosenId id of selected menu item. Empty string by default
 * @param onChange Called when selection changes. This is a passed in as a callback
 * @param showError (default = true) If true then show error message
 * and returns an array of the selected ids * */

// eslint-disable-next-line react/display-name
const DDLOptionPicker = forwardRef(
    (
        {
            label,
            id = '',
            disabled = false,
            mandatory = false,
            error,
            chosenName = '',
            menuItems = [],
            chosenId = '',
            onChange,
            showError = true,
            parentMessage = '',
            ...props
        },
        ref
    ) => {
        const [selectedName, setSelectedName] = useState('');

        useEffect(() => {
            // Callback to parent component
            if (!disabled) {
                onChange(chosenId);
            }
        }, [menuItems]);

        // Set name from props on change
        useEffect(() => {
            if (menuItems.length < 1) return;
            if (menuItems.some((el) => el.name === chosenName)) setSelectedName(chosenName);
            else setSelectedName('');
        }, [chosenName, menuItems]);

        const handleChange = (e) => {
            const { value } = e.target;
            setSelectedName(value);
            const chosenId = value === '' ? '' : menuItems.find((el) => el.name === value)?.id;
            onChange(chosenId);
        };

        return (
            <div className={form.optionInputWrapper}>
                <FormControl fullWidth>
                    <label id={id} className={form.formLabel}>
                        {label} {mandatory && <sup>*</sup>}
                    </label>
                    <Select
                        id={id}
                        data-testid={id}
                        {...props}
                        ref={ref}
                        size="small"
                        value={selectedName}
                        disabled={disabled}
                        onChange={handleChange}>
                        <MenuItem value={''} key={NO_SELECTION}></MenuItem>
                        {menuItems.map((mi) => (
                            <MenuItem value={mi.name} key={mi.id} sx={{ whiteSpace: 'wrap' }}>
                                {mi.name}{' '}
                            </MenuItem>
                        ))}
                    </Select>
                    {parentMessage && (
                        <Typography variant="body1" sx={{ color: POLARIS_DOUBLE_DECKER_RED }}>
                            {parentMessage}
                        </Typography>
                    )}
                    <Box sx={{ height: SCREEN_SETTINGS.gap.tiny }} />
                </FormControl>
                {showError && mandatory && (
                    <IconError text={error || null} data-testid={id + 'Error'} />
                )}
            </div>
        );
    }
);

export default DDLOptionPicker;

DDLOptionPicker.propTypes = {
    label: PropTypes.string,
    disabled: PropTypes.bool,
    mandatory: PropTypes.bool,
    id: PropTypes.string,
    error: PropTypes.any,
    onChange: PropTypes.func,
    chosenName: PropTypes.string,
    menuItems: PropTypes.arrayOf(PropTypes.object),
    chosenId: PropTypes.string,
    showError: PropTypes.bool,
    parentMessage: PropTypes.string
};
