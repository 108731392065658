import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import OnCreateIcon from '../../../icons/OnCreateIcon';
import OnDeleteIcon from '../../../icons/OnDeleteIcon';
import OnEditIcon from '../../../icons/OnEditIcon';
import { setCurrentTemplate, updateCourseTemplate } from '../../../store/courseService';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import { ADVISER, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';

import classes from '../../../commonStyles/tables.module.css';

const CourseTemplateTableRow = ({ row, roles }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [active, setActive] = useState(false);

    // STORE STATE
    const usersCourses = useSelector((state) => state.entities.courseService.usersCourses);

    // USE EFFECT
    useEffect(() => {
        const courseFound = usersCourses.find((el) => el.courseTemplateId === row.id);
        if (courseFound) setActive(false);
        else setActive(true);
    }, [usersCourses]);

    // CALLBACKS
    const onCreateCourse = (row) => {
        dispatch(setCurrentTemplate(row));
        navigate('/create_course', { state: { id: row.id, accordionPanel: 'templates' } });
    };

    const onDeleteTemplate = () => {
        const templateCopy = { ...row, active: false };
        dispatch(updateCourseTemplate(templateCopy));
    };

    const onEditTemplate = (row) => {
        dispatch(setCurrentTemplate(row));
        navigate('/edit_course_template', {
            state: {
                id: row.id,
                accordionPanel: 'templates'
            }
        });
    };

    // RENDER
    return (
        <TableRow key={row.id} hover className={classes.basicRowStripes}>
            <TableCell>{row.name}</TableCell>
            <TableCell>{reverseFormatDate(row.dateCreated)}</TableCell>
            <TableCell>{reverseFormatDate(row.reviewDate)}</TableCell>
            <TableCell align="right">
                <OnCreateIcon
                    onCreate={() => onCreateCourse(row)}
                    titleString={'Course'}
                    roles={roles}
                    acceptedRoles={acceptedRoles}
                />
            </TableCell>
            <TableCell align="right">
                <OnEditIcon
                    onEdit={() => onEditTemplate(row)}
                    roles={roles}
                    acceptedRoles={acceptedRoles}
                    active={active}
                    inactiveMessage="Course already created"
                />
            </TableCell>
            <TableCell align="right">
                <OnDeleteIcon
                    onDelete={onDeleteTemplate}
                    roles={roles}
                    acceptedRoles={acceptedRoles}
                />
            </TableCell>
        </TableRow>
    );
};

export default CourseTemplateTableRow;

CourseTemplateTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string)
};
