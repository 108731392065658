import { getReqMessage } from '../../../../../utils/formValidation/errorMessageFunctions';

export const initialErrorState = {};

export const validate = (newEntry) => {
    let newErrors = {};

    if (!newEntry.goldStandardCVId) {
        newErrors = {
            ...newErrors,
            goldStandardCVId: { error: true, message: getReqMessage('Gold Standard CV') }
        };
    }
    8;
    return newErrors;
};
