import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { updateParticipantBarrier } from '../../../store/participantService';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import { clearKeys } from '../../../utils/objectUtils';
import { hasRole, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import DateSelect from '../../formElements/DateSelect';
import RadioButtons from '../../formElements/RadioButtons';
import SingleSelect from '../../formElements/SingleSelect';
import StaticField from '../../formElements/StaticField';
import FormActions from '../../ui/formActions/FormActions';

import { validate } from './validateBarriers';

import app from '../../../app.module.css';
import form from '../../../commonStyles/formStyles.module.css';

const EditBarrier = ({
    barrier,
    existingBarriers,
    closeEdit,
    cancelEdit,
    acceptedRoles,
    roles
}) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        participantId: '',
        id: '',
        barrierId: null,
        startDate: String(new Date().toISOString().split('T')[0]),
        dueDate: String(new Date().toISOString().split('T')[0]),
        priority: 'Medium'
    };
    const fullEditRoles = [QUALITY, SUPERUSER];
    const message = 'Participant barrier details have been updated';
    const [newEntry, setNewEntry] = useState(initialState);
    const [initialStateOnEntry, setInitialStateOnEntry] = useState({});
    const [newBarriers, setNewBarriers] = useState([]);
    const [keys, setKeys] = useState({ barrierId: '0' });
    const [errors, setErrors] = useState({});
    const [isDisabled, setIsDisabled] = useState(false);

    // STORE STATE
    const barrierNameDetails = useSelector(
        (state) => state.entities.directusService.barrierNameDetails
    );
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );

    // USEEFFECTS
    useEffect(() => {
        if (currentParticipant?.id) {
            setNewEntry((prev) => ({ ...prev, participantId: currentParticipant.id }));
        }
    }, [currentParticipant]);

    useEffect(() => {
        setNewEntry(barrier);
        setInitialStateOnEntry(barrier);
    }, [barrier]);

    useEffect(() => {
        if (barrierNameDetails?.length < 1 || existingBarriers?.length < 1) return;
        const updatedBarriers = barrierNameDetails.filter(
            (el) =>
                !existingBarriers.find(
                    (entry) =>
                        entry.barrierId === el.id &&
                        entry.barrierId !== barrier.barrierId &&
                        !entry.completeDate
                )
        );
        setNewBarriers(updatedBarriers);
    }, [barrierNameDetails, existingBarriers]);

    useEffect(() => {
        setIsDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    // HELPER FNS
    const clearData = () => {
        setNewEntry(initialState);
        setKeys(clearKeys(keys));
        setErrors({});
    };

    const clearError = (key) => {
        if (key in errors) setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    // EVENT HANDLERS
    const onUpdate = (key, value) => {
        clearError(key);
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onStartDateUpdate = (key, value) => {
        newEntry.dueDate && clearError('dueDate');
        onUpdate(key, value);
    };

    const onDueDateUpdate = (key, value) => {
        newEntry.startDate && clearError('startDate');
        onUpdate(key, value);
    };

    const onClose = () => {
        clearData();
        closeEdit();
    };

    const onCancel = () => {
        clearData();
        cancelEdit();
    };

    // FORM SUBMIT
    const onSubmit = (e) => {
        e.preventDefault();
        setIsDisabled(true);
        const newErrors = validate(newEntry, 'edit', initialStateOnEntry);
        if (Object.values(newErrors).some((el) => el.error)) {
            setErrors(newErrors);
            return;
        }
        dispatch(updateParticipantBarrier(newEntry, message));
        onClose();
    };

    // RENDER
    return (
        <form onSubmit={onSubmit} data-testid="form_start_edit_barrier">
            <h2 className={app.sectionHeading}>Edit a Barrier</h2>
            <div className={form.formSection}>
                <div className={form.formColumn}>
                    {!hasRole(fullEditRoles, roles) ? (
                        <StaticField label="Barrier Name" content={barrier.barrier} />
                    ) : (
                        <SingleSelect
                            id="barrierId"
                            key={keys.barrierId}
                            label="Barrier Name"
                            placeholder="Select a barrier name"
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={newBarriers || []}
                            selectedId={newEntry?.barrierId}
                            selected={newBarriers?.find((el) => el.id === newEntry.barrierId) || {}}
                            error={errors.barrierId}
                            onChange={(chosenId) => onUpdate('barrierId', chosenId)}
                        />
                    )}
                </div>
                <div className={form.formColumn}>
                    <RadioButtons
                        id="priority"
                        label="What is the barrier priority?"
                        disabled={!hasRole(acceptedRoles, roles)}
                        radioButtons={[
                            { id: 'Low', label: 'Low', onChangeVal: 'Low' },
                            { id: 'Medium', label: 'Medium', onChangeVal: 'Medium' },
                            { id: 'High', label: 'High', onChangeVal: 'High' }
                        ]}
                        value={newEntry.priority || ''}
                        onChange={(value) => onUpdate('priority', value)}
                    />
                    <div className={form.formColumnSplit}>
                        {!hasRole(fullEditRoles, roles) ? (
                            <StaticField
                                label="Start Date"
                                content={reverseFormatDate(newEntry.startDate)}
                            />
                        ) : (
                            <DateSelect
                                id="startDate"
                                label="Start Date"
                                disabled={!hasRole(acceptedRoles, roles)}
                                mandatory={true}
                                value={newEntry.startDate}
                                error={errors.startDate}
                                onDateChange={(date) => onStartDateUpdate('startDate', date)}
                            />
                        )}

                        <DateSelect
                            id="dueDate"
                            label="Due Date"
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            value={newEntry.dueDate}
                            error={errors.dueDate}
                            onDateChange={(date) => onDueDateUpdate('dueDate', date)}
                        />
                    </div>
                </div>
            </div>
            <FormActions
                onClose={onSubmit}
                onCancel={onCancel}
                disabled={isDisabled}
                btnText="Update Barrier"
                error={errors.button}
                clearError={() => clearError('button')}
            />
        </form>
    );
};
EditBarrier.propTypes = {
    barrier: PropTypes.object,
    existingBarriers: PropTypes.arrayOf(PropTypes.object),
    closeEdit: PropTypes.func,
    cancelEdit: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    acceptedRoles: PropTypes.arrayOf(PropTypes.string)
};

export default EditBarrier;
