import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import {
    loadVacancyRejectionReasonsDetails,
    loadVacancyStageDetails
} from '../../../../store/directusService';
import { updateVacancySubmissionRejected } from '../../../../store/recruitmentService';
import {
    VACANCY_REJECTION_REASONS_DETAILS_ERROR,
    VACANCY_STAGE_DETAILS_ERROR
} from '../../../../utils/formValidation/loadingErrorMessageConstants';
import { clearKeys } from '../../../../utils/objectUtils';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import MultiSelect from '../../../formElements/MultiSelect';
import TextAreaField from '../../../formElements/TextAreaField';
import FormActions from '../../../ui/formActions/FormActions';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import { initialErrorState, validate } from './validateSubmissionsRejectEditor';

import form from '../../../../commonStyles/formStyles.module.css';

const CHARACTER_LIMIT = 1000;

const SubmissionsRejectEditor = ({
    open,
    onSubmissionsRejectEditor,
    onSubmissionsRejectCancelled,
    rejectedRow,
    onPoolRejectedUpdate
}) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const initialState = {
        id: null,
        rejectionReasonIds: [],
        feedback: ''
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [keys, setKeys] = useState({ rejectionReasonIds: '0' });
    const [errors, setErrors] = useState(initialErrorState);
    const [isOpen, setIsOpen] = React.useState(false);

    const submissionsRejectCancelled = () => {
        onSubmissionsRejectCancelled(true, rejectedRow);
    };

    // STORE STATE
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const vacancyRejectionReasonsDetails = useSelector(
        (state) => state.entities.directusService.vacancyRejectionReasonsDetails
    );
    const vacancyStageDetails = useSelector(
        (state) => state.entities.directusService.vacancyStageDetails
    );
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);

    // USE EFFECTS
    useEffect(() => {
        if (vacancyStageDetails?.length < 1) dispatch(loadVacancyStageDetails());
        if (vacancyRejectionReasonsDetails?.length < 1)
            dispatch(loadVacancyRejectionReasonsDetails());
    }, []);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        if (successMessage === `Vacancy submission has been rejected`) {
            onCreated();
        }
    }, [successMessage]);

    // HELPER FNS
    const clearData = () => {
        setNewEntry(initialState);
        setKeys(clearKeys(keys));
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    const loadingError = () => {
        if (vacancyRejectionReasonsDetails?.length < 1)
            return VACANCY_REJECTION_REASONS_DETAILS_ERROR;
        if (vacancyStageDetails?.length < 1) return VACANCY_STAGE_DETAILS_ERROR;
    };

    // EVENT HANDLERS
    const onRejectReasonsChange = (chosenIds) => {
        clearError('rejectionReasonIds');
        setNewEntry((prev) => ({ ...prev, rejectionReasonIds: chosenIds }));
    };

    const onNotesChange = (e) => {
        setNewEntry((prev) => ({ ...prev, feedback: e.target.value }));
    };

    const onModalClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setIsOpen(false);
        }
    };

    const onCancel = () => {
        onSubmissionsRejectEditor(false);
        submissionsRejectCancelled(rejectedRow);
        clearData();
    };

    const onCreated = () => {
        onSubmissionsRejectEditor(false);
        clearData();
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const newErrors = validate(newEntry);

        if (Object.keys(newErrors)?.length > 0) {
            setErrors(newErrors);
            return;
        }

        onPoolRejectedUpdate(false);
        const rejectedStatusId = vacancyStageDetails.find(
            (entry) => entry.name.toLowerCase() === 'rejected'
        )?.id;

        dispatch(
            updateVacancySubmissionRejected({
                ...newEntry,
                vacancySubmissionId: rejectedRow.id,
                statusId: rejectedStatusId
            })
        );
    };

    // AWAITING CONTENT
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} />;

    // RENDER
    return (
        <Dialog open={isOpen} onClose={onModalClose} aria-labelledby="form-dialog-title">
            <form onSubmit={onSubmit} data-testid="form_start" className={form.form}>
                <DialogTitle id="form-dialog-title">Pool Application Rejection</DialogTitle>
                <DialogContent data-testid="dialog-content">
                    <MultiSelect
                        id="rejectionReasonIds"
                        key={keys.rejectionReasonIds}
                        label="Rejection Reason"
                        placeholder="Rejection Reason"
                        disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                        mandatory={true}
                        menuItems={vacancyRejectionReasonsDetails || []}
                        preSelectedIds={newEntry.rejectionReasonIds}
                        preSelects={vacancyRejectionReasonsDetails?.filter((el) =>
                            newEntry.rejectionReasonIds.includes(el.id)
                        )}
                        error={errors.rejectionReasonIds}
                        onChange={(chosenIds) => onRejectReasonsChange(chosenIds)}
                    />
                    <TextAreaField
                        id={'notes'}
                        label={'Rejection Feedback'}
                        placeholder={'Enter rejection feedback'}
                        disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                        value={newEntry.feedback || ''}
                        count={`${newEntry.feedback.length}/${CHARACTER_LIMIT}`}
                        maxLength={CHARACTER_LIMIT}
                        onChange={onNotesChange}
                    />
                </DialogContent>

                <DialogActions>
                    <FormActions
                        id="create"
                        btnText="Update"
                        disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                        onClose={onSubmit}
                        onCancel={onCancel}
                        customClass="noTopBorder"
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
};

SubmissionsRejectEditor.propTypes = {
    open: PropTypes.bool.isRequired,
    onSubmissionsRejectEditor: PropTypes.func,
    onSubmissionsRejectCancelled: PropTypes.func,
    rejectedRow: PropTypes.object,
    onPoolRejectedUpdate: PropTypes.func
};

export default SubmissionsRejectEditor;
