import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { loadActionPlanStatusDetails } from '../../../store/directusService';
import { updateActionPlan } from '../../../store/participantService';
import { getNameFromId } from '../../../utils/directusFunctions';
import { clearKeys } from '../../../utils/objectUtils';
import { hasRole, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import DateSelect from '../../formElements/DateSelect';
import RadioButtons from '../../formElements/RadioButtons';
import SingleSelect from '../../formElements/SingleSelect';
import StaticField from '../../formElements/StaticField';
import TextAreaField from '../../formElements/TextAreaField';
import TextInputField from '../../formElements/TextInputField';
import FormActions from '../../ui/formActions/FormActions';
import SmartDefs from '../../ui/notices/smartDefs/SmartDefs';

import { calcDays, configDisabled, reConfigDisabled } from './utils';
import {
    MAX_ACTION_COMMENTS,
    MAX_ACTION_DESCRIPTION,
    MAX_ACTION_TITLE,
    validate
} from './validateActionPlan';

import app from '../../../app.module.css';
import form from '../../../commonStyles/formStyles.module.css';
import classes from './actionPlan.module.css';

const ActionForm = ({ row, onClose, roles, acceptedRoles }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const restrictedRoles = [QUALITY, SUPERUSER];
    const initialState = {
        id: '',
        participantId: '',
        mandatory: false,
        actionTitle: '',
        action: '',
        barrierId: '',
        actionStartDate: '',
        actionDueDate: '',
        actionOutcomeDate: '',
        totalDays: null,
        outcomeId: '',
        outcomeName: '',
        moduleId: null,
        comments: ''
    };

    const initialDisabledState = {
        barrierId: false,
        mandatory: false,
        actionTitle: false,
        action: false,
        actionStartDate: true,
        actionDueDate: false,
        actionOutcomeDate: false,
        outcomeId: false,
        comments: false
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [initialRowState, setInitialRowState] = useState({});
    const [allowMandatoryActivities, setAllowMandatoryActivities] = useState(true);
    const [keys, setKeys] = useState({ barrierId: '0', outcome: '1' });
    const [outcomes, setOutcomes] = useState([]);
    const [errors, setErrors] = useState({});
    const [isDisabled, setIsDisabled] = useState(initialDisabledState);
    const [currentParticipantBarriersWithNames, setCurrentParticipantBarriersWithNames] = useState(
        []
    );
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    // STORE STATE
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const currentParticipantEmployability = useSelector(
        (state) => state.entities.participantService.currentParticipantEmployability
    );
    const currentParticipantBarriers = useSelector(
        (state) => state.entities.participantService.currentParticipantBarriers
    );
    const actionPlanStatuses = useSelector(
        (state) => state.entities.directusService.actionPlanStatusDetails
    );
    const contractConstants = useSelector(
        (state) => state.entities.participantService.contractConstants
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const barrierNameDetails = useSelector(
        (state) => state.entities.directusService.barrierNameDetails
    );

    // USE EFFECTS
    useEffect(() => {
        if (!actionPlanStatuses?.length) dispatch(loadActionPlanStatusDetails());
    }, []);

    useEffect(() => {
        if (currentParticipantBarriers?.length < 1) return;

        setCurrentParticipantBarriersWithNames(
            currentParticipantBarriers.map((el) => ({
                ...el,
                name: getNameFromId(barrierNameDetails, el.barrierId)
            }))
        );
    }, [currentParticipantBarriers]);

    useEffect(() => {
        setIsDisabled(configDisabled(newEntry, roles, allowMandatoryActivities));
    }, [roles, newEntry]);

    useEffect(() => {
        if (!currentParticipant?.id || !row) return;

        const formattedRow = {
            ...row,
            participantId: currentParticipant.id,
            barrierId: row.barrierId === null ? '' : row.barrierId,
            action: row.action === null ? '' : row.action,
            outcomeId: row.outcomeId === null ? '' : row.outcomeId,
            comments: row.comments === null ? '' : row.comments,
            moduleId: row.moduleId === null ? '' : row.moduleId,
            actionStartDate:
                row.actionStartDate === '1960-01-01' || null
                    ? ''
                    : row.actionStartDate.slice(0, 10),
            actionDueDate:
                row.actionDueDate === '1960-01-01' || null ? '' : row.actionDueDate.slice(0, 10),
            actionOutcomeDate:
                row.actionOutcomeDate === '1960-01-01' || null
                    ? ''
                    : row.actionOutcomeDate.slice(0, 10)
        };
        setNewEntry(formattedRow);
        setInitialRowState(formattedRow);
    }, [currentParticipant, row]);

    useEffect(() => {
        if (Object.keys(currentParticipantEmployability).length > 1) {
            setAllowMandatoryActivities(currentParticipantEmployability?.allowMandatoryActivities);
        }
    }, [currentParticipantEmployability]);

    useEffect(() => {
        if (!actionPlanStatuses?.length) return;
        setOutcomes(
            actionPlanStatuses.filter((el) =>
                el.contract.some((entry) => entry.contract_id.id === currentParticipant.contractId)
            )
        );
    }, [actionPlanStatuses]);

    useEffect(() => {
        if (newEntry.actionOutcomeDate) return;
        if (newEntry.outcomeId) {
            setNewEntry((prev) => ({
                ...prev,
                actionOutcomeDate: new Date().toISOString().slice(0, 10)
            }));
        }
    }, [newEntry.outcomeId]);

    useEffect(() => {
        setIsButtonDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    useEffect(() => {
        if (successMessage === 'Participant action plan has been updated') clearForm();
    }, [successMessage]);

    // HELPER FNS
    const clearForm = () => {
        setNewEntry(initialState);
        setKeys(clearKeys(keys));
        setIsButtonDisabled(false);
    };

    const clearError = (key) => {
        if (key in errors) setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    const configData = () => {
        // eslint-disable-next-line
        const { outcomeName, totalDays, barrierName, ...payload } = newEntry;
        // eslint-disable-next-line
        const { barrierName: barrier, outcomeName: outcome, ...rowState } = initialRowState;
        return { payload, rowState };
    };

    // EVENT HANDLERS
    const onUpdate = (key, value) => {
        clearError(key);
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onFormExit = () => {
        clearForm();
        onClose();
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const updateIsDisabled = reConfigDisabled(isDisabled, row, newEntry);
        const { payload, rowState } = configData();
        const newErrors = validate(
            payload,
            updateIsDisabled,
            contractConstants.endDate,
            'edit',
            rowState
        );
        if (Object.values(newErrors).some((el) => el.error)) {
            setErrors(newErrors);
            return;
        }
        dispatch(updateActionPlan(payload));
        onFormExit();
    };

    // RENDER
    return (
        <form onSubmit={onSubmit} data-testid="form_start_edit_action_plan">
            <h2 className={app.sectionHeading}>Edit an action</h2>
            <div className={form.formSection}>
                <div className={form.formColumn}>
                    {row?.moduleId ? (
                        <>
                            <StaticField
                                id="mandatory"
                                label="Is this a mandatory action?"
                                content={newEntry.mandatory ? 'Yes' : 'No'}
                            />
                            <StaticField
                                id="actionTitle"
                                label="What shall we call this action?"
                                content={newEntry.actionTitle}
                            />
                        </>
                    ) : (
                        <>
                            <RadioButtons
                                id="mandatory"
                                label="Is this a mandatory action?"
                                disabled={
                                    !hasRole(acceptedRoles, roles) || !allowMandatoryActivities
                                }
                                value={newEntry.mandatory ?? false}
                                onChange={(option) => onUpdate('mandatory', option)}
                            />
                            <TextInputField
                                id="actionTitle"
                                label="What shall we call this action?"
                                placeholder="Enter an action name"
                                value={newEntry.actionTitle || ''}
                                mandatory={true}
                                disabled={
                                    newEntry.outcomeId
                                        ? !hasRole(restrictedRoles, roles)
                                        : !hasRole(acceptedRoles, roles)
                                }
                                maxLength={MAX_ACTION_TITLE}
                                error={errors.actionTitle}
                                onChange={(e) => onUpdate('actionTitle', e.target.value)}
                            />
                        </>
                    )}

                    {row?.moduleId ? (
                        <StaticField id="action" label="Action" content={newEntry.action} />
                    ) : (
                        <>
                            <SingleSelect
                                id="barrierId"
                                key={keys.barrierId}
                                label="Which barrier does this action align to?"
                                placeholder="Select a barrier"
                                disabled={
                                    newEntry.outcomeId
                                        ? !hasRole(restrictedRoles, roles)
                                        : !hasRole(acceptedRoles, roles)
                                }
                                menuItems={currentParticipantBarriersWithNames || []}
                                selectedId={newEntry.barrierId || ''}
                                selected={currentParticipantBarriersWithNames.find(
                                    (el) => el.id === newEntry.barrierId
                                )}
                                onChange={(chosenId) => onUpdate('barrierId', chosenId)}
                            />

                            <div className={classes.smartDefsWrapper}>
                                <div className={classes.heading}> What is the action? </div>
                                <SmartDefs />
                            </div>
                            <TextAreaField
                                id="action"
                                label="Action"
                                placeholder={'Enter action description'}
                                disabled={
                                    newEntry.outcomeId
                                        ? !hasRole(restrictedRoles, roles)
                                        : !hasRole(acceptedRoles, roles)
                                }
                                mandatory={true}
                                maxLength={MAX_ACTION_DESCRIPTION}
                                value={newEntry.action || ''}
                                count={`${newEntry.action.length}/${MAX_ACTION_DESCRIPTION}`}
                                error={errors.action}
                                onChange={(e) => onUpdate('action', e.target.value)}
                            />
                        </>
                    )}
                </div>
                <div className={form.formColumn}>
                    <div className={form.formColumnSplit}>
                        <DateSelect
                            id="actionStartDate"
                            label="Action Start Date"
                            disabled={true}
                            value={newEntry.actionStartDate}
                        />
                        <DateSelect
                            id="actionDueDate"
                            label="Action Due Date"
                            disabled={
                                newEntry.outcomeId
                                    ? !hasRole(restrictedRoles, roles)
                                    : !hasRole(acceptedRoles, roles)
                            }
                            value={newEntry.actionDueDate}
                            error={errors.actionDueDate}
                            onDateChange={(date) => onUpdate('actionDueDate', date)}
                        />
                    </div>

                    <h2 className={app.sectionHeading}>Action completion information</h2>
                    <div className={form.formColumnSplit}>
                        <DateSelect
                            id="actionOutcomeDate"
                            label="Action Outcome Date"
                            disabled={!hasRole(restrictedRoles, roles) || !newEntry.outcomeId}
                            value={newEntry.actionOutcomeDate}
                            isDefault={false}
                            error={errors.actionOutcomeDate}
                            onDateChange={(date) => onUpdate('actionOutcomeDate', date)}
                        />

                        <div className={classes.inputSet} data-testid="days_to_complete">
                            <label className={form.formLabel}>Total Days to Complete</label>
                            <div className={classes.calculatedInputBox}>
                                {calcDays(actionPlanStatuses, newEntry)}
                            </div>
                        </div>
                    </div>

                    <SingleSelect
                        id="outcome"
                        key={keys.outcome}
                        label="Outcome"
                        placeholder="Select an outcome"
                        disabled={
                            row.outcomeId
                                ? !hasRole(restrictedRoles, roles)
                                : !hasRole(acceptedRoles, roles)
                        }
                        menuItems={outcomes || []}
                        selectedId={newEntry.outcomeId}
                        selected={outcomes.find((el) => el.id === newEntry.outcomeId)}
                        onChange={(chosenId) => onUpdate('outcomeId', chosenId)}
                    />
                    <TextAreaField
                        id="comments"
                        label="Comments"
                        placeholder="Enter comments here"
                        subLabel="Use this area for comments surrounding this action"
                        disabled={!hasRole(acceptedRoles, roles)}
                        maxLength={MAX_ACTION_COMMENTS}
                        value={newEntry.comments}
                        counter={'true'}
                        count={`${newEntry.comments.length}/${MAX_ACTION_COMMENTS}`}
                        error={errors.comments}
                        onChange={(e) => onUpdate('comments', e.target.value)}
                    />
                </div>
            </div>
            <FormActions
                onClose={onSubmit}
                onCancel={onFormExit}
                btnText="Update Action"
                disabled={isButtonDisabled}
                error={errors.button}
                clearError={() => clearError('button')}
            />
        </form>
    );
};

ActionForm.propTypes = {
    row: PropTypes.object,
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    acceptedRoles: PropTypes.arrayOf(PropTypes.string)
};

export default ActionForm;
