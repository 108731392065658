import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import classes from '../../../../commonStyles/pdf.module.css';

const PDFContent = ({
    participant,
    orderDate,
    reason,
    orderAmount,
    requestedById,
    authorisedById,
    comments,
    paymentMethodType,
    paymentMethod
}) => {
    // LOCAL STATE
    const [requestedBy, setRequestedBy] = useState(null);
    const [authorisedBy, setAuthorisedBy] = useState(null);

    // STORE STATE
    const eligibilityGroupsForContractId = useSelector(
        (state) => state.entities.participantService.eligibilityGroupSearch
    );
    const teamsForService = useSelector((state) => state.entities.userService.teamsForService);
    const services = useSelector(
        (state) => state.entities.directusService.serviceDetailsForContractId
    );
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);

    // USE EFFECTS
    useEffect(() => {
        if (users.length < 1) return;
        setRequestedBy(users.find((el) => el.id === requestedById));
        setAuthorisedBy(users.find((el) => el.id === authorisedById));
    }, [users]);

    // RENDER
    return (
        <>
            <div className={classes.section}>
                <h3>Participant Details</h3>
                <div className={classes.dualColumnSet}>
                    <div className={classes.column}>
                        <div className={classes.row}>
                            <span>Participant ID</span>
                            <span>{participant.ptCode || '-'}</span>
                        </div>
                        <div className={classes.row}>
                            <span>Participant status</span>
                            <span>{participant.status || '-'}</span>
                        </div>
                        <div className={classes.row}>
                            <span>Service</span>
                            <span>
                                {services.find((el) => el.id === participant.serviceId)?.name ||
                                    '-'}
                            </span>
                        </div>
                    </div>
                    <div className={classes.column}>
                        <div className={classes.row}>
                            <span>Participant Name</span>
                            <span>{`${participant.firstName} ${participant.lastName}`}</span>
                        </div>
                        <div className={classes.row}>
                            <span>Eligibility Group</span>
                            <span>
                                {eligibilityGroupsForContractId.find(
                                    (el) => el.id === participant.eligibilityGroupId
                                )?.name || '-'}
                            </span>
                        </div>
                        <div className={classes.row}>
                            <span>Team</span>
                            <span>
                                {teamsForService.find((el) => el.id === participant.teamId)?.name ||
                                    '-'}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.section}>
                <h3>Financial Request Details</h3>
                <div className={classes.dualColumnSet}>
                    <div className={classes.column}>
                        <div className={classes.row}>
                            <span>Order Date</span>
                            <span>{format(new Date(orderDate), `dd - MMM - yyyy`) || '-'}</span>
                        </div>
                        <div className={classes.row}>
                            <span>Reason</span>
                            <span>{reason || '-'}</span>
                        </div>
                    </div>
                    <div className={classes.column}>
                        <div className={classes.row}>
                            <span>Order Amount</span>
                            <span>{orderAmount || '-'}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.section}>
                <h3>Financial Request Authorisation Details</h3>
                <div className={classes.dualColumnSet}>
                    <div className={classes.column}>
                        <div className={classes.row}>
                            <span>Request By</span>
                            <span>
                                {requestedBy
                                    ? `${requestedBy.firstName} ${requestedBy.lastName}`
                                    : '-'}
                            </span>
                        </div>
                        <div className={classes.row}>
                            <span>Authorised By</span>
                            <span>
                                {authorisedBy
                                    ? `${authorisedBy.firstName} ${authorisedBy.lastName}`
                                    : '-'}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.section}>
                <h3>Financial Request Payment Details</h3>
                <div className={classes.dualColumnSet}>
                    <div className={classes.column}>
                        <div className={classes.row}>
                            <span>Comments</span>
                            <span>{comments || '-'}</span>
                        </div>
                        <div className={classes.row}>
                            <span>Payment Method Type</span>
                            <span>{paymentMethodType || '-'}</span>
                        </div>
                        <div className={classes.row}>
                            <span>Payment Method</span>
                            <span>{paymentMethod || '-'}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.section}>
                <h3>Financial Request Disclaimer</h3>
            </div>
            <div className={classes.section}>
                <h3>Financial Request Signatures</h3>
                <div className={classes.dualColumnSet}>
                    <div className={classes.column}>
                        <div className={`${classes.row} ${classes.signatureBoxRow}`}>
                            <span className={classes.signatureBox}>Participant Signature</span>
                            <p className={classes.signatureBox}></p>
                        </div>
                        <div className={`${classes.row} ${classes.signatureBoxRow}`}>
                            <span className={classes.signatureBox}>Dated</span>
                            <p className={classes.signatureBox}></p>
                        </div>
                    </div>
                    <div className={classes.column}>
                        <div className={`${classes.row} ${classes.signatureBoxRow}`}>
                            <span className={classes.signatureBox}>Co-member Signature</span>
                            <p className={classes.signatureBox}></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

PDFContent.propTypes = {
    participant: PropTypes.shape({
        contractId: PropTypes.string,
        eligibilityGroupId: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        ptCode: PropTypes.string,
        serviceId: PropTypes.string,
        status: PropTypes.string,
        teamId: PropTypes.string
    }),
    orderDate: PropTypes.string,
    reason: PropTypes.string,
    orderAmount: PropTypes.string,
    requestedById: PropTypes.string,
    authorisedById: PropTypes.string,
    comments: PropTypes.string,
    paymentMethodType: PropTypes.string,
    paymentMethod: PropTypes.string
};

export default PDFContent;
