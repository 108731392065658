import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Card, Link, Typography } from '@mui/material';

import {
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../store/directusService';
import { loadParticipant } from '../../store/participantService';
import { POLARIS_BLUE, POLARIS_WHITE, SCREEN_SETTINGS } from '../../themes/theme';

const RecordedIdentifier = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [contractName, setContractName] = useState('');
    const [serviceName, setServiceName] = useState('');

    // STORE STATE
    const currentParticipantId = useSelector(
        (state) => state.entities.participantService.currentParticipantId
    );
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const contractDetailsById = useSelector(
        (state) => state.entities.directusService.contractDetailsById
    );
    const servicesByContractId = useSelector(
        (state) => state.entities.directusService.servicesByContractId
    );

    // FNs
    const renderTitles = `${currentParticipant.firstName} ${currentParticipant.lastName} : ${
        currentParticipant.ptCode
    } : ${contractName} : ${serviceName}`;

    // USE EFFECTS
    useEffect(() => {
        if (Object.keys(currentParticipant).length === 0 && currentParticipantId) {
            dispatch(loadParticipant(currentParticipantId));
        }
    }, [currentParticipantId]);

    useEffect(() => {
        if (!('contractId' in currentParticipant)) {
            setContractName('');
            setServiceName('');
            return;
        }
    }, [currentParticipant]);

    useEffect(() => {
        if (
            currentParticipant &&
            'contractId' in currentParticipant &&
            'serviceId' in currentParticipant &&
            contractDetailsById &&
            servicesByContractId
        ) {
            const { contractId, serviceId } = currentParticipant;
            if (!(contractId in contractDetailsById)) {
                dispatch(loadContractDetailsForContractId(contractId));
            }
            if (!(contractId in servicesByContractId)) {
                dispatch(loadServiceDetailsForContractId(contractId));
            }
            if (contractId in contractDetailsById) {
                if ('name' in contractDetailsById[contractId])
                    setContractName(contractDetailsById[contractId].name);
            }
            if (contractId in servicesByContractId) {
                setServiceName(
                    servicesByContractId[contractId].find((el) => el.id === serviceId)?.name || ''
                );
            }
        }
    }, [contractDetailsById, servicesByContractId]);

    // RENDER

    return (
        <Card
            sx={{
                backgroundColor: POLARIS_WHITE,
                paddingBottom: SCREEN_SETTINGS.padding.small,
                marginLeft: SCREEN_SETTINGS.margin.large
            }}
            elevation={0}>
            <Link onClick={() => navigate('/edit_participant')} style={{ cursor: 'pointer' }}>
                <Typography variant="h5" component="div" sx={{ flexGrow: 1, color: POLARIS_BLUE }}>
                    {Object.keys(currentParticipant)?.length < 1 ? (
                        <div>No Participant selected</div>
                    ) : (
                        <div>{renderTitles}</div>
                    )}
                </Typography>
            </Link>
        </Card>
    );
};
export default RecordedIdentifier;
