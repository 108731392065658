import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Box, Button } from '@mui/material';

import { setErrorMessage } from '../../../store/formsState';
import { SCREEN_SETTINGS } from '../../../themes/theme';

import ContactDetailsViewer from './contactDetails/ContactDetailsViewer';
import JobGoalsViewer from './jobGoals/JobGoalsViewer';
import PastEducationViewer from './pastEducation/PastEducationViewer';
import WorkHistoryViewer from './workHistory/WorkHistoryViewer';
import { CONTACT_DETAILS_VIEWER } from './ViewerTypes';
import { PAST_EDUCATION_VIEWER } from './ViewerTypes';
import { JOB_GOALS_VIEWER } from './ViewerTypes';
import { WORK_HISTORY_VIEWER } from './ViewerTypes';

const CardHandler = ({
    viewerType = CONTACT_DETAILS_VIEWER,
    label = '',
    itemName = '',
    cards = [],
    disabled = false,
    sendAdd = () => {},
    sendDelete = () => {},
    sendUpdate = () => {},
    data = {},
    moreData = {}
}) => {
    // Throw error if no viewer type  specified.
    if (viewerType === undefined) {
        throw new Error('CardHandler: No viewer type specified');
    }

    const dispatch = useDispatch();

    const deleteItem = (item) => {
        sendDelete(item);
    };

    const updateItem = (item) => {
        sendUpdate(item);
    };

    const handleAdd = () => {
        sendAdd();
    };

    const getViewer = (item) => {
        switch (viewerType) {
            case CONTACT_DETAILS_VIEWER:
                return (
                    <ContactDetailsViewer
                        cardData={item}
                        disabled={disabled}
                        sendDelete={deleteItem}
                        sendUpdate={updateItem}></ContactDetailsViewer>
                );
            case JOB_GOALS_VIEWER:
                return (
                    <JobGoalsViewer
                        cardData={item}
                        arrayJobSectors={data}
                        disabled={disabled}
                        sendDelete={deleteItem}
                        sendUpdate={updateItem}></JobGoalsViewer>
                );
            case PAST_EDUCATION_VIEWER:
                return (
                    <PastEducationViewer
                        cardData={item}
                        cardsLength={cards.length}
                        qualificationTypeDetails={data}
                        disabled={disabled}
                        sendDelete={deleteItem}
                        sendUpdate={updateItem}></PastEducationViewer>
                );
            case WORK_HISTORY_VIEWER:
                return (
                    <WorkHistoryViewer
                        cardData={item}
                        cardsLength={cards.length}
                        employmentTypeDetails={data}
                        reasonForLeavingDetails={moreData}
                        disabled={disabled}
                        sendDelete={deleteItem}
                        sendUpdate={updateItem}></WorkHistoryViewer>
                );
            default:
                dispatch(setErrorMessage(`No suitable viewer for ${itemName}`));
        }
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    m: 0
                }}>
                {cards.map((item) => (
                    <Box
                        key={item.id}
                        sx={{
                            display: 'flex',
                            m: 0,
                            paddingRight: SCREEN_SETTINGS.padding.medium
                        }}>
                        {getViewer(item)}
                    </Box>
                ))}
            </Box>
            <Button variant={'contained'} onClick={handleAdd} disabled={disabled}>
                {label}
            </Button>
        </>
    );
};

CardHandler.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    itemName: PropTypes.string,
    cards: PropTypes.arrayOf(PropTypes.object),
    viewerType: PropTypes.string,
    id: PropTypes.string,
    sendAdd: PropTypes.func, // Call parent when user clicks on [Add] button
    sendDelete: PropTypes.func, // Call parent when user clicks on [Delete] icon in viewer
    sendUpdate: PropTypes.func, // Call parent when user clicks on [Edit] icon in Viewer
    data: PropTypes.any, // Optional data which viewer may need
    moreData: PropTypes.any // Optional data which viewer may need
};

export default CardHandler;
