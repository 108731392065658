import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    loadAppointmentTypeDetails,
    loadLocationIdsAndNames,
    loadServiceDetailsByService
} from '../../../store/directusService';
import { loadTeamsForService } from '../../../store/userService';
import { addEmailAddressAsNameToArray } from '../../../utils/userArrayUtils';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import MultiSelect from '../../formElements/MultiSelect';
import SingleSelect from '../../formElements/SingleSelect';
import Switch from '../../formElements/Switch';

import SchedulingAssistantAdviserPanel from './SchedulingAssistantAdviserPanel';
import SchedulingAssistantCalendarManagement from './SchedulingAssistantCalendarManagement';

import screen from '../styles/schedulingAssistant.module.css';

const SchedulingAssistant = () => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const allowedAdviserRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [service, setService] = useState({});
    const [teamsList, setTeamsList] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [isAppointmentSlots, setIsAppointmentSlots] = useState(false);
    const [advisers, setAdvisers] = useState([]);
    const [selectedAdvisers, setSelectedAdvisers] = useState([]);
    const [activeAdviser, setActiveAdviser] = useState('');

    // STORE STATE
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);
    const { roles } = loggedInUser;
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);
    const teamsForService = useSelector((state) => state.entities.userService.teamsForService);
    const appointmentTypes = useSelector(
        (state) => state.entities.directusService.appointmentTypeDetails
    );
    const services = useSelector((state) => state.entities.directusService.serviceDetails);
    const locations = useSelector((state) => state.entities.directusService.locationIdsAndNames);

    // USE EFFECTS
    useEffect(() => {
        if (!appointmentTypes?.length) dispatch(loadAppointmentTypeDetails());
        if (locations?.length < 1) dispatch(loadLocationIdsAndNames());
    }, []);

    useEffect(() => {
        if (!('serviceIds' in loggedInUser)) return;
        dispatch(loadServiceDetailsByService(loggedInUser.serviceIds));
    }, [loggedInUser.id]);

    useEffect(() => {
        setTeamsList(teamsForService);
    }, [teamsForService]);

    useEffect(() => {
        if (!users.length) return;
        let advisers;
        if (selectedTeams.length) {
            const selectedTeamIds = selectedTeams.map((el) => el.id);
            advisers = users.filter((el) =>
                [el.primaryTeam?.id, ...el.otherTeams.map((entry) => entry.id)]
                    .filter((entry) => entry)
                    .some((entry) => selectedTeamIds.includes(entry))
            );
        } else {
            advisers = users.filter((el) =>
                [el.primaryService, ...el.otherServices].includes(service.id)
            );
        }
        setAdvisers(addEmailAddressAsNameToArray(advisers));
        setSelectedAdvisers([]);
    }, [selectedTeams, service.id, users]);

    // EVENT HANDLERS
    const onServiceChange = (chosenId) => {
        if (!chosenId || chosenId === service?.id) return;
        setService(services.find((el) => el.id === chosenId) || {});
        const teamPayload = { serviceIds: [chosenId] };
        setTeamsList([]);
        setSelectedTeams([]);
        dispatch(loadTeamsForService(teamPayload));
    };

    const onTeamsChange = (chosenIds) => {
        const teams = teamsList.filter((el) => chosenIds.includes(el.id));
        teams && setSelectedTeams(teams);
    };

    // RENDER
    return (
        <div className={screen.scheduler}>
            <div className={screen.schedulerToolbar}>
                <SingleSelect
                    id="ServiceId"
                    label="Service"
                    placeholder="Select a service"
                    disabled={!hasRole(allowedAdviserRoles, roles)}
                    mandatory={true}
                    customClass="absolutePosition"
                    menuItems={services || []}
                    selectedId={service?.id || ''}
                    selected={service}
                    onChange={(id) => onServiceChange(id)}
                />

                <MultiSelect
                    id="teamIds"
                    label="Teams"
                    placeholder="Select teams"
                    disabled={!service?.id || !hasRole(allowedAdviserRoles, roles)}
                    customClass="absolutePosition"
                    menuItems={teamsList || []}
                    preSelectedIds={selectedTeams.map((el) => el.id) || []}
                    preSelects={selectedTeams || []}
                    onChange={(ids) => onTeamsChange(ids)}
                />
                <Switch
                    id="appointmentSlotsSwitch"
                    leftLabel="Appointment"
                    rightLabel="Appointment Slots"
                    subLabelClass="formLabel"
                    disabled={!hasRole(allowedAdviserRoles, roles)}
                    checked={isAppointmentSlots}
                    customClass="verticalAlignCenter"
                    onChange={(e) => setIsAppointmentSlots(e.target.checked)}
                />
            </div>

            <div className={screen.timeviewContentContainer}>
                <div className={screen.schedulingSidebar}>
                    <SchedulingAssistantAdviserPanel
                        advisers={advisers}
                        selectedAdvisers={selectedAdvisers}
                        activeAdviser={activeAdviser}
                        onChangeSelectedAdvisers={setSelectedAdvisers}
                        onChangeActiveAdviser={setActiveAdviser}
                        disabled={!service?.id}
                    />
                </div>
                <div className={screen.timeviewCalendarContent}>
                    <SchedulingAssistantCalendarManagement
                        selectedAdvisers={selectedAdvisers}
                        selectedAdviser={activeAdviser}
                        isAppointmentSlots={isAppointmentSlots}
                    />
                </div>
            </div>
        </div>
    );
};

export default SchedulingAssistant;
