import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';

import { loadBankHolidayData } from '../../../store/dataService';
import { POLARIS_GREY, POLARIS_ROYAL_BLUE } from '../../../themes/theme';
import { hasRole, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import ConfirmPrompt from '../../ui/notices/confirmPrompt/ConfirmPrompt';
import { unresolvedStatuses } from '../utils/calendarUtils';

import EventContent from './EventContent';

import '../styles/calendarVendorClasses.css';

function createEventId() {
    return String(eventGuid++);
}
let eventGuid = 0;
/**
 *
 * @param events (array of objects) - to display on the calendar as events
 * @param currentEvent (object) - selected event - the id is needed and also the date and times to check for change
 * @param onSelectSlot (function) - a callback on slot select - ie. to open a panel for editing
 * @param onSelectEvent (function) -  a callback on event select - ie. to open a panel
 * @param onDragEditTimes (function) - a callback on a direct time/date drag edit in the calendar
 * @param onReturn (function) - to return to last page - used for course module scheduling
 * @param initialView (string) - the calendar's initial view required (day, week etc)
 * @param onNav (function) - for use in render for the PT's name
 * @param allowSelectSlot (boolean) - allows a slot to be selected or not
 * @param eventEditPermission (boolean) - allows an event to be edited or not
 * @returns {JSX.Element}
 * @constructor
 */

const Calendar = ({
    events,
    currentEvent,
    onSelectSlot,
    onSelectEvent,
    onDragEditTimes,
    onReturn,
    initialView,
    onNav,
    allowSlotSelect,
    eventEditPermission = true
}) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const editRestrictedRoles = [QUALITY, SUPERUSER];
    const initialTimeState = { date: '', start: '', end: '' };
    const [showVerifyModal, setShowVerifyModal] = useState(false);
    const [time, setTime] = useState(initialTimeState);
    const [currentId, setCurrentId] = useState('');
    const [holidayEvents, setHolidayEvents] = useState([]);

    // STORE STATE
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const bankHolidayData = useSelector((state) => state.entities.dataService.bankHolidayData);
    const usersCurrentAppointment = useSelector(
        (state) => state.entities.calendarService.usersCurrentAppointment
    );

    // USEEFFECTS
    useEffect(() => {
        if (bankHolidayData?.length < 1) dispatch(loadBankHolidayData());
    }, []);

    useEffect(() => {
        // TODO
        // for demo purposes - division is hard-coded - there are 3 from the API
        // 'england-and-wales', 'northern-ireland' & 'scotland'
        // also noted that it's needed - how to determine the division
        const filtered = bankHolidayData
            .filter((el) => el.division === 'england-and-wales')
            .map((el) => ({
                content: el.name,
                date: el.date.split('T')[0],
                display: 'background',
                className: 'bank-holidays'
            }));
        setHolidayEvents(filtered);
    }, [bankHolidayData]);

    // EVENT HANDLERS
    const onSelectAllow = () => allowSlotSelect;
    const onSlotSelect = (newEvent) => {
        if (!onSelectSlot) return;
        const title = 'Click to edit';
        const calendarApi = newEvent?.view.calendar;
        calendarApi.unselect();
        if (title) {
            calendarApi.addEvent({
                id: createEventId(),
                title,
                start: newEvent?.startStr,
                end: newEvent?.endStr,
                allDay: newEvent.allDay,
                backgroundColor: currentEvent?.color || POLARIS_GREY,
                textColor: currentEvent?.textColor || POLARIS_ROYAL_BLUE,
                extendedProps: currentEvent?.detail
            });
        } else {
            calendarApi.unselect();
        }
        newEvent.view.type === 'dayGridMonth'
            ? onSelectSlot(`${newEvent.startStr}T08:00:00`, `${newEvent.startStr}T08:30:00`)
            : onSelectSlot(newEvent.startStr, newEvent.endStr);
    };

    const onEventSelect = (eventInfo) => {
        if (
            'hasPermission' in eventInfo.event.extendedProps &&
            !eventInfo.event.extendedProps?.hasPermission
        )
            return;
        if (eventEditPermission) {
            onSelectEvent(eventInfo.event._def.publicId);
        }
    };

    // when an event is dragged
    const onEventEdit = (changeInfo) => {
        const onNoEdit = () => {
            onDragEditTimes({ date, start, end });
            setTime(initialTimeState);
            setCurrentId('');
        };
        const id = changeInfo.event._def.publicId;
        const { startStr, endStr } = changeInfo.event;
        const start = startStr;
        const end = endStr;
        const date = start.split('T')[0];
        setTime({ date, start, end });
        setCurrentId(id);
        if (Object.keys(usersCurrentAppointment).length < 1) return;
        const isAppointmentStarted = usersCurrentAppointment.attendances.some(
            (el) => !unresolvedStatuses.includes(el.status)
        );
        if (
            !hasRole(editRestrictedRoles, roles) &&
            (date !== usersCurrentAppointment?.date || isAppointmentStarted)
        ) {
            onNoEdit();
            return;
        }

        if (
            date !== currentEvent.date ||
            start !== currentEvent.start ||
            end !== currentEvent.end
        ) {
            setShowVerifyModal(true);
        } else onNoEdit();
    };

    const onVerifyModalClose = (_, verify) => {
        let update = true;
        if (!verify || verify === 'backdropClick' || typeof verify === 'object') update = false;
        onDragEditTimes(time, update, currentId);
        setShowVerifyModal(false);
        setTime(initialTimeState);
        setCurrentId('');
    };

    // RENDER
    return (
        <div className="calendar-container">
            {showVerifyModal && (
                <ConfirmPrompt
                    isOpen={showVerifyModal}
                    onCancel={(e) => onVerifyModalClose(e, false)}
                    onConfirm={(e) => onVerifyModalClose(e, true)}
                    content="Do you want to keep this change?"
                />
            )}
            <div className={`${onReturn ? 'show-return-btn' : 'hide-return-btn'}`}>
                <Button id="calendarReturnButton" content="Return" onClick={onReturn} />
            </div>
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                    left: 'prev title next today',
                    right: 'dayGridMonth timeGridWeek timeGridDay'
                }}
                views={{
                    timeGridWeek: {
                        weekends: false,
                        dayHeaderFormat: {
                            weekday: 'short',
                            day: 'numeric'
                        },
                        titleFormat: {
                            month: 'short',
                            day: '2-digit',
                            year: 'numeric'
                        }
                    },
                    dayGridMonth: {
                        eventTimeFormat: {
                            hour: '2-digit',
                            minute: '2-digit'
                        },
                        displayEventEnd: true
                    }
                }}
                locale={''} // use "en-GB" for 24hr y-axis labels - but title date will revert to DD MM YYYY
                initialView={initialView}
                navLinks={true}
                eventDisplay="block" // month view text style errors without this
                selectable={!!onSelectSlot}
                selectAllow={onSelectAllow}
                select={onSlotSelect}
                selectMirror={true}
                weekends={true}
                allDaySlot={false}
                slotMinTime="08:00:00"
                slotMaxTime="18:00:00"
                slotDuration="00:15:00"
                slotLabelInterval="01:00:00"
                slotLabelFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: false
                }}
                events={[...events, ...holidayEvents]}
                eventContent={(eventInfo) => <EventContent eventInfo={eventInfo} onNav={onNav} />}
                eventClick={onEventSelect}
                eventChange={onEventEdit}
                editable={true}
            />
        </div>
    );
};

export default Calendar;

Calendar.propTypes = {
    events: PropTypes.arrayOf(PropTypes.object),
    currentEvent: PropTypes.object,
    onDragEditTimes: PropTypes.func,
    onSelectSlot: PropTypes.func,
    onSelectEvent: PropTypes.func,
    onReturn: PropTypes.func,
    initialView: PropTypes.string,
    onNav: PropTypes.func,
    allowSlotSelect: PropTypes.bool,
    eventEditPermission: PropTypes.bool
};
