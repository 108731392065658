import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';

import Confirmation from '../../Confirmation';

import classes from '../../../../../commonStyles/pdf.module.css';

const SignDetail = ({
    adviserName = '-',
    currentParticipant,
    adviserSignature = '',
    participantSignature = '',
    signatureDateTime = '',
    currentAppointment
}) => {
    const adviserSignatureData =
        adviserSignature && adviserSignature.slice(1 + adviserSignature.indexOf(','));
    const participantSignatureData =
        participantSignature && participantSignature.slice(1 + participantSignature.indexOf(','));
    const documentId =
        currentAppointment?.calendarAppointmentId?.slice(-9) || uuid().slice(-9) || '-';

    // RENDER
    return (
        <>
            <div className={classes.statement}>
                <h3>Statement</h3>
                <p>
                    {currentParticipant ? <Confirmation participant={currentParticipant} /> : ' - '}
                </p>
            </div>

            <div className={classes.signatureRow}>
                <div className={classes.signatureEntry} data-testid="adviser-signatureArea">
                    <h5>Signed:</h5>
                    <div className={classes.signatureSpace}>
                        {adviserSignatureData && (
                            <img
                                alt="adviser signature"
                                src={`data:image/png;base64,${adviserSignatureData}`}
                            />
                        )}
                    </div>
                    <p className={classes.signatureName}>{adviserName}</p>
                    <p className={classes.recordIdEntry}>
                        {adviserSignatureData ? signatureDateTime : 'Date: '}
                    </p>
                </div>

                <div className={classes.signatureEntry} data-testid="participant-signatureArea">
                    <h5>Signed:</h5>
                    <div className={classes.signatureSpace}>
                        {participantSignatureData && (
                            <img
                                alt="participant signature"
                                src={`data:image/png;base64,${participantSignatureData}`}
                            />
                        )}
                    </div>
                    <p className={classes.signatureName}>
                        {`${currentParticipant.firstName} ${currentParticipant.lastName}`}
                    </p>
                    <p className={classes.recordIdEntry}>
                        {participantSignatureData ? signatureDateTime : 'Date: '}
                    </p>
                </div>

                <div className={classes.recordIdEntry}>Document ID {documentId}</div>
            </div>
        </>
    );
};

SignDetail.propTypes = {
    currentParticipant: PropTypes.object,
    currentAppointment: PropTypes.object,
    participantSignature: PropTypes.string,
    adviserSignature: PropTypes.string,
    confirmationText: PropTypes.string,
    adviserName: PropTypes.string,
    signatureDateTime: PropTypes.string
};

export default SignDetail;
