import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';

import { loadVacancyRejectionReasonsDetails } from '../../../../../store/directusService';
import { loadBusinessRecord } from '../../../../../store/recruitmentService';
import { reverseFormatDate } from '../../../../../utils/dateFunctions';
import { getNameFromId } from '../../../../../utils/directusFunctions';
import {
    CURRENT_VACANCY_ERROR,
    VACANCY_REJECTION_REASONS_DETAILS_ERROR
} from '../../../../../utils/formValidation/loadingErrorMessageConstants';
import LabelledTextField from '../../../../ui/editors/LabelledTextField';
import LoadingSpinner from '../../../../ui/LoadingSpinner';

import dropdownClasses from '../../../../courses/courseStyles/rowDropDown.module.css';

const RejectedVacancyDetails = ({ row, onClose }) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [reasonNames, setReasonNames] = useState([]);

    // STORE STATE
    const currentVacancy = useSelector((state) => state.entities.recruitmentService.currentVacancy);
    const vacancyRejectionReasonsDetails = useSelector(
        (state) => state.entities.directusService.vacancyRejectionReasonsDetails
    );

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadBusinessRecord(row.businessRecordId));
        if (vacancyRejectionReasonsDetails?.length < 1)
            dispatch(loadVacancyRejectionReasonsDetails());
    }, []);

    useEffect(() => {
        if (vacancyRejectionReasonsDetails?.length < 1) return;
        dispatch(loadBusinessRecord(row.businessRecordId));

        if (!row.rejectionReasonIds) return;

        setReasonNames(
            row.rejectionReasonIds.map((el) => getNameFromId(vacancyRejectionReasonsDetails, el))
        );
    }, [vacancyRejectionReasonsDetails]);

    // RENDER
    let content;
    if (Object.keys(currentVacancy).length < 1) content = CURRENT_VACANCY_ERROR;
    if (vacancyRejectionReasonsDetails?.length < 1) return VACANCY_REJECTION_REASONS_DETAILS_ERROR;

    return !currentVacancy.length < 1 || vacancyRejectionReasonsDetails?.length < 1 ? (
        <LoadingSpinner content={content} />
    ) : (
        <div className={dropdownClasses.rowDropdown}>
            <form>
                <LabelledTextField
                    label={'Date of rejection'}
                    id={'rejectionDate'}
                    disabled={true}
                    value={reverseFormatDate(row.rejectionDate) || ''}
                />
                <LabelledTextField
                    label={'Rejection Reasons'}
                    id={'rejectionReasons'}
                    disabled={true}
                    value={reasonNames.join(', ')}
                />
                <LabelledTextField
                    label={'Feedback'}
                    id={'feedback'}
                    disabled={true}
                    multiline
                    rows={3}
                    value={row.feedback || ''}
                />
                <div className={dropdownClasses.exit} onClick={onClose}>
                    Close details
                    <CloseIcon />
                </div>
            </form>
        </div>
    );
};

RejectedVacancyDetails.propTypes = {
    row: PropTypes.object,
    onClose: PropTypes.func.isRequired
};

export default RejectedVacancyDetails;
