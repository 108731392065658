import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from '@mui/material';

import { setErrorMessage } from '../../../../../store/formsState';
import { createPlacement } from '../../../../../store/recruitmentService';
import { selectLoggedInUser, selectUsers } from '../../../../../store/userSelectors';
import { getFutureDate } from '../../../../../utils/dateFunctions';
import { getConfiguredItems, getNameFromId } from '../../../../../utils/directusFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../../utils/userRoles';
import * as validate from '../../../../../validation/validation';
import IconError from '../../../../IconError';
import LabelledEmailText from '../../../../ui/editors/LabelledEmailText';
import LabelledPhoneText from '../../../../ui/editors/LabelledPhoneText';
import LabelledPostcodeText from '../../../../ui/editors/LabelledPostcodeText';
import LabelledTextField from '../../../../ui/editors/LabelledTextField';
import DDLOptionPicker from '../../../../ui/pickers/DDLOptionPicker';
import NoYesRadioPicker from '../../../../ui/pickers/NoYesRadioPicker';
import PolarisSwitchSet from '../../../../ui/pickers/PolarisSwitchSet';

import '../../submissionStyles/muiStyles.css';
import form from '../../../../../commonStyles/formStyles.module.css';
import classes from '../../submissionStyles/joboffer.module.css';

const EXPECTED_HOURS_GREATER_THAN_FORTY_EIGHT = 'Expected weekly hours must be 48 or less';
const START_DATE_INVALID = 'Start date must not be a future date';

const AddOwnPlacementEditor = ({
    open,
    roles,
    onOwnPlacementEditor,
    placementEmploymentTypeDetails,
    hoursPerWeekDetails,
    jobSectorDetails,
    ownPlacementDetails
}) => {
    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    // State for create own placement newEntry
    // This matches the payload sent to the backend
    const initialState = {
        id: null,
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        businessName: null,
        city: null,
        claimableEvent: true,
        employmentTypeId: null,
        fixedFlexibleHour: 'fixed',
        fullServiceDeclined: false,
        hiringManagerEmail: null,
        hiringManagerId: null,
        hiringManagerName: null,
        hiringManagerOfficeNumber: null,
        hiringManagerMobileNumber: null,
        hourlyPay: null,
        hoursPerWeek: null,
        hoursPerWeekId: null,
        jobSectorId: null,
        jobStartEvidence: false,
        leadAdviserId: null,
        leadGeneratedById: null,
        participantId: null,
        postcode: null,
        selfEmploymentClaimCalculationDate: '',
        startDate: '',
        vacancyId: null,
        vacancyTitle: null
    };
    const [newEntry, setNewEntry] = useState(initialState);
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [isOpen, setIsOpen] = React.useState(false);
    const [notFixedHours, setNotFixedHours] = useState(false);
    const [leadAdviser, setLeadAdviser] = useState(false);
    const [selfEmploymentDate, setSelfEmploymentDate] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [arrayPlacementEmploymentType, setArrayPlacementEmploymentType] = useState([]);
    const [advisers, setAdvisers] = useState([]);
    const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false);

    const [isClearSelectedEmploymentType, setIsClearSelectedEmploymentType] = useState('0');
    const [isClearSelectedJobSector, setIsClearSelectedJobSector] = useState('1');

    // STORE STATE
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const loggedInUser = useSelector(selectLoggedInUser);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const users = useSelector(selectUsers);

    // EVENT HANDLERS
    const handleEmploymentTypeChange = (chosenId) => {
        if (chosenId) {
            setNewEntry((prev) => ({ ...prev, employmentTypeId: chosenId }));
            clearErrors('employmentTypeId');
        }
        if (getNameFromId(placementEmploymentTypeDetails, chosenId) !== 'Self Employed') {
            setSelfEmploymentDate(false);
            setNewEntry((prev) => ({
                ...prev,
                selfEmploymentClaimCalculationDate: ''
            }));
        } else {
            setSelfEmploymentDate(true);
        }
    };

    const handleSelfEmployedDateChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            selfEmploymentClaimCalculationDate: e.target.value === '' ? null : e.target.value
        }));
    };

    const handleStartDateChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            startDate: e.target.value === '' ? null : e.target.value
        }));
    };

    const handleVacancyTitleChange = (e) => {
        setNewEntry((prev) => ({ ...prev, vacancyTitle: e.target.value }));
    };

    const handlePayRateChange = (e) => {
        setNewEntry((prev) => ({ ...prev, hourlyPay: e.target.value }));
    };

    const handleHoursPerWeekChange = (e) => {
        setNewEntry((prev) => ({ ...prev, hoursPerWeek: e.target.value }));
    };

    const handleHoursPerWeekChangeDDL = (chosenId) => {
        if (chosenId) {
            setNewEntry((prev) => ({ ...prev, hoursPerWeekId: chosenId }));
        }
    };

    const handleClaimableChange = (option) => {
        setNewEntry((prev) => ({ ...prev, claimableEvent: option }));
    };

    const handleJobSectorChange = (chosenId) => {
        if (chosenId) {
            setNewEntry((prev) => ({ ...prev, jobSectorId: chosenId }));
            clearErrors('employmentTypeId');
        }
    };

    const handleBusinessNameChange = (e) => {
        setNewEntry((prev) => ({ ...prev, businessName: e.target.value }));
    };

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleOfficePhoneNumberChange = (e) => {
        setNewEntry((prev) => ({ ...prev, hiringManagerOfficeNumber: e.target.value }));
    };

    const handleMobilePhoneNumberChange = (e) => {
        setNewEntry((prev) => ({ ...prev, hiringManagerMobileNumber: e.target.value }));
    };

    const handleEmailAddressChange = (e) => {
        setNewEntry((prev) => ({ ...prev, hiringManagerEmail: e.target.value }));
    };

    const handleAddress1Change = (e) => {
        setNewEntry((prev) => ({ ...prev, addressLine1: e.target.value }));
    };

    const handleAddress2Change = (e) => {
        setNewEntry((prev) => ({ ...prev, addressLine2: e.target.value }));
    };

    const handleAddress3Change = (e) => {
        setNewEntry((prev) => ({ ...prev, addressLine3: e.target.value }));
    };

    const handleCityChange = (e) => {
        setNewEntry((prev) => ({ ...prev, city: e.target.value }));
    };

    const handlePostcodeChange = (e) => {
        setNewEntry((prev) => ({ ...prev, postcode: e.target.value }));
    };

    const handleLeadGeneratedByChange = (chosenId) => {
        if (chosenId) {
            setNewEntry((prev) => ({ ...prev, leadGeneratedById: chosenId }));
            clearErrors('leadGeneratedById');
        }
        if (getNameFromId(ownPlacementDetails, chosenId) !== 'Adviser') {
            setLeadAdviser(false);
            setNewEntry((prev) => ({
                ...prev,
                leadAdviserId: ''
            }));
        } else {
            setLeadAdviser(true);
        }
    };

    const handleAdviserChange = (chosenId) => {
        if (chosenId) {
            setNewEntry((prev) => ({ ...prev, leadAdviserId: chosenId }));
        } else setNewEntry((prev) => ({ ...prev, leadAdviserId: '' }));
    };

    // HELPER FNS
    const handleClickOutside = () => {
        // Do nothing. User cannot exit by clicking outside dialog.
    };

    const handleCancel = () => {
        clearData();
    };

    const onSwitchFixedOrFlexible = (e) => {
        const { checked } = e.target;
        setNotFixedHours(checked);

        if (checked) {
            setNewEntry((prev) => ({ ...prev, hoursPerWeek: null }));
            setNewEntry((prev) => ({ ...prev, fixedFlexibleHour: 'flexible' }));
        } else {
            setNewEntry((prev) => ({ ...prev, hoursPerWeekId: '' }));
            setNewEntry((prev) => ({ ...prev, fixedFlexibleHour: 'fixed' }));
        }
    };

    // HELPER FNS
    const clearData = () => {
        setIsClearSelectedEmploymentType(Math.random());
        setIsClearSelectedJobSector(Math.random());
        setNewEntry(initialState);
        setFirstName('');
        setLastName('');
        setNotFixedHours(false);
        onOwnPlacementEditor(false);
        reset();
        clearErrors();
    };

    const onCreated = () => {
        clearData();
    };

    // USE EFFECTS
    useEffect(() => {
        setArrayPlacementEmploymentType(
            getConfiguredItems(placementEmploymentTypeDetails, currentParticipant?.contractId)
        );
    }, []);

    useEffect(() => {
        setAdvisers(
            users
                .filter((el) =>
                    el.userTypes?.find(
                        (entry) =>
                            entry.role === ADVISER ||
                            entry.role === MANAGER ||
                            entry.role === QUALITY ||
                            entry.role === RECRUITMENT_MANAGER ||
                            entry.role === SUPERUSER
                    )
                )
                .map(({ id, emailAddress: name }) => ({
                    id,
                    name
                }))
                .sort((a, b) => a.name.localeCompare(b.name))
        );
    }, [users]);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        if (successMessage.startsWith(`Job placement has been added`)) {
            setIsUpdateButtonDisabled(false);
            onCreated();
        }
    }, [successMessage]);

    const onSubmit = () => {
        setValue('employmentTypeId', newEntry.employmentTypeId, { shouldValidate: true });
        setValue('startDate', newEntry.startDate, { shouldValidate: true });
        let hoursPerWeek;
        if (newEntry.hoursPerWeek > '') hoursPerWeek = parseFloat(newEntry.hoursPerWeek);
        if (
            newEntry.hourlyPay === null ||
            newEntry.hourlyPay === '0' ||
            newEntry.hourlyPay === '0.0' ||
            newEntry.hourlyPay === '0.00'
        ) {
            dispatch(setErrorMessage('Hourly pay cannot be zero or blank'));
        } else if (hoursPerWeek > 48) {
            dispatch(setErrorMessage(EXPECTED_HOURS_GREATER_THAN_FORTY_EIGHT));
        } else if (
            (!notFixedHours && newEntry.hoursPerWeek === null) ||
            (!notFixedHours && newEntry.hoursPerWeek === '0') ||
            (!notFixedHours && newEntry.hoursPerWeek === '0.0') ||
            (!notFixedHours && newEntry.hoursPerWeek === '0.00')
        ) {
            dispatch(setErrorMessage('Hours per week cannot be zero or blank'));
        } else if (
            (notFixedHours && newEntry.hoursPerWeekId === '') ||
            (notFixedHours && newEntry.hoursPerWeekId === null)
        ) {
            dispatch(setErrorMessage('Please select hours per week'));
        } else if (leadAdviser && !newEntry.leadAdviserId) {
            dispatch(setErrorMessage('Adviser must be entered when lead generated by adviser'));
        } else if (selfEmploymentDate && !newEntry.selfEmploymentClaimCalculationDate) {
            dispatch(
                setErrorMessage(
                    'Self employed claim calculation date must be entered when placement employment type is self employed'
                )
            );
        } else if (
            selfEmploymentDate &&
            newEntry.selfEmploymentClaimCalculationDate < newEntry.startDate
        ) {
            dispatch(
                setErrorMessage(
                    'Self employed claim calculation date must not be before start date'
                )
            );
        } else {
            setIsUpdateButtonDisabled(true);
            const payload = {
                ...newEntry,
                participantId: currentParticipant.id,
                hiringManagerName: `${firstName?.toLowerCase()} ${lastName?.toLowerCase()}`
            };
            dispatch(createPlacement(payload));
        }
    };

    // RENDER
    return (
        <div
            onSubmit={(e) => {
                //  This stops the Submit event from bubbling up to the form enclosing this component
                e.preventDefault();
                e.stopPropagation();
            }}>
            <div>
                <Dialog
                    className="muiDialogWrapper"
                    open={isOpen}
                    onClose={handleClickOutside}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Create Own Placement</DialogTitle>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <DialogContent data-testid="dialog-content">
                            <div className={form.formSection}>
                                <div className={form.formColumn}>
                                    <DDLOptionPicker
                                        label={'Placement Employment Type'}
                                        id="employmentTypeId"
                                        key={isClearSelectedEmploymentType}
                                        disabled={!hasRole(acceptedRoles, roles)}
                                        mandatory={true}
                                        menuItems={arrayPlacementEmploymentType}
                                        error={errors.employmentTypeId}
                                        {...register('employmentTypeId')}
                                        onChange={(e) => {
                                            if (e !== null && e !== undefined) {
                                                handleEmploymentTypeChange(e);
                                            }
                                        }}
                                    />
                                    <div className={classes.inputField}>
                                        <TextField
                                            label="Start Date *"
                                            id="startDate"
                                            disabled={!hasRole(acceptedRoles, roles)}
                                            type="date"
                                            value={newEntry.startDate || ''}
                                            sx={{ width: '100%' }}
                                            InputLabelProps={{ shrink: true }}
                                            {...register('startDate')}
                                            onChange={handleStartDateChange}
                                        />
                                        <IconError text={errors.startDate || null} />
                                    </div>
                                    <div hidden={!selfEmploymentDate}>
                                        <div className={classes.inputField}>
                                            <TextField
                                                label="Self Employed Claim Calculation Date *"
                                                id="selfEmploymentClaimCalculationDate"
                                                disabled={!hasRole(acceptedRoles, roles)}
                                                type="date"
                                                value={
                                                    newEntry.selfEmploymentClaimCalculationDate ||
                                                    ''
                                                }
                                                sx={{ width: '100%' }}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={handleSelfEmployedDateChange}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.inputField}>
                                        <LabelledTextField
                                            label={'Vacancy Title'}
                                            id={'title'}
                                            disabled={!hasRole(acceptedRoles, roles)}
                                            mandatory={true}
                                            value={newEntry.vacancyTitle || ''}
                                            placeholder={'Enter vacancy title'}
                                            error={errors.title}
                                            {...register('title')}
                                            onChange={handleVacancyTitleChange}
                                        />
                                    </div>
                                    <div className={classes.inputField}>
                                        <LabelledTextField
                                            label={'Hourly pay'}
                                            id={'hourlyPay'}
                                            disabled={!hasRole(acceptedRoles, roles)}
                                            mandatory={true}
                                            value={newEntry.hourlyPay || ''}
                                            placeholder={'Enter hourly pay'}
                                            error={errors.hourlyPay}
                                            {...register('hourlyPay')}
                                            onChange={handlePayRateChange}
                                        />
                                    </div>
                                    <div className={classes.inputField}>
                                        <PolarisSwitchSet
                                            id="fixedOrFlexible"
                                            disabled={!hasRole(acceptedRoles, roles)}
                                            label="Fixed or Flexible"
                                            leftLabel="Fixed"
                                            rightLabel="Flexible"
                                            checked={notFixedHours}
                                            onSwitch={onSwitchFixedOrFlexible}
                                        />
                                    </div>
                                    {!notFixedHours ? (
                                        <div className={classes.inputField}>
                                            <LabelledTextField
                                                label={'Hours per Week'}
                                                id={'hoursPerWeek'}
                                                disabled={!hasRole(acceptedRoles, roles)}
                                                mandatory={!notFixedHours && true}
                                                value={newEntry.hoursPerWeek || ''}
                                                placeholder={'Enter hours'}
                                                onChange={handleHoursPerWeekChange}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <DDLOptionPicker
                                                label={'Hours Per Week'}
                                                id="hoursPerWeekId"
                                                key={isClearSelectedEmploymentType}
                                                disabled={!hasRole(acceptedRoles, roles)}
                                                mandatory={notFixedHours && true}
                                                menuItems={hoursPerWeekDetails}
                                                onChange={(e) => {
                                                    if (e !== null && e !== undefined) {
                                                        handleHoursPerWeekChangeDDL(e);
                                                    }
                                                }}
                                            />
                                        </>
                                    )}
                                    <NoYesRadioPicker
                                        id="claimable"
                                        disabled={!hasRole(acceptedRoles, roles)}
                                        radioButtonPick={newEntry.claimableEvent}
                                        text={'Claimable Event?'}
                                        onChange={handleClaimableChange}></NoYesRadioPicker>
                                    <DDLOptionPicker
                                        label={'Job Sector'}
                                        id={'sectorId'}
                                        key={isClearSelectedJobSector}
                                        disabled={!hasRole(acceptedRoles, roles)}
                                        mandatory={true}
                                        menuItems={jobSectorDetails}
                                        error={errors.sectorId}
                                        {...register('sectorId')}
                                        onChange={(e) => {
                                            if (e !== null && e !== undefined) {
                                                handleJobSectorChange(e);
                                            }
                                        }}
                                    />
                                    <div className={classes.inputField}>
                                        <LabelledTextField
                                            label={'Business Name'}
                                            id={'businessName'}
                                            disabled={!hasRole(acceptedRoles, roles)}
                                            mandatory={true}
                                            value={newEntry.businessName || ''}
                                            placeholder={'Enter business name'}
                                            error={errors.businessName}
                                            {...register('businessName')}
                                            onChange={handleBusinessNameChange}
                                        />
                                    </div>
                                    <DDLOptionPicker
                                        label={'Lead Generated By'}
                                        id={'leadGeneratedById'}
                                        disabled={!hasRole(acceptedRoles, roles)}
                                        mandatory={true}
                                        menuItems={ownPlacementDetails}
                                        error={errors.leadGeneratedById}
                                        {...register('leadGeneratedById')}
                                        onChange={(e) => {
                                            if (e !== null && e !== undefined) {
                                                handleLeadGeneratedByChange(e);
                                            }
                                        }}
                                    />
                                    <div hidden={!leadAdviser}>
                                        <DDLOptionPicker
                                            label={'Adviser'}
                                            id={'leadAdviserId'}
                                            disabled={
                                                !(!hasRole(acceptedRoles, roles) || leadAdviser)
                                            }
                                            mandatory={leadAdviser}
                                            menuItems={advisers}
                                            onChange={(e) => {
                                                if (e !== null && e !== undefined) {
                                                    handleAdviserChange(e);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={form.formColumn}>
                                    <div className={classes.inputField}>
                                        <LabelledTextField
                                            label={'First Name'}
                                            id={'firstName'}
                                            disabled={!hasRole(acceptedRoles, roles)}
                                            mandatory={true}
                                            value={firstName || ''}
                                            placeholder={'Enter hiring manager first name'}
                                            error={errors.firstName}
                                            {...register('firstName')}
                                            onChange={handleFirstNameChange}
                                        />
                                    </div>
                                    <div className={classes.inputField}>
                                        <LabelledTextField
                                            label={'Last Name'}
                                            id={'lastName'}
                                            disabled={!hasRole(acceptedRoles, roles)}
                                            mandatory={true}
                                            value={lastName || ''}
                                            placeholder={'Enter hiring manager last name'}
                                            error={errors.lastName}
                                            {...register('lastName')}
                                            onChange={handleLastNameChange}
                                        />
                                    </div>
                                    <div className={classes.inputField}>
                                        <LabelledPhoneText
                                            label="Office Number"
                                            id="officeNumber"
                                            disabled={!hasRole(acceptedRoles, roles)}
                                            mandatory={true}
                                            name="officeNumber"
                                            placeholder={'Enter hiring manager office phone number'}
                                            value={newEntry.hiringManagerOfficeNumber || ''}
                                            error={errors.officeNumber}
                                            {...register('officeNumber')}
                                            onChange={handleOfficePhoneNumberChange}
                                        />
                                    </div>
                                    <div className={classes.inputField}>
                                        <LabelledPhoneText
                                            label="Mobile Number"
                                            id="mobileNumber"
                                            disabled={!hasRole(acceptedRoles, roles)}
                                            mandatory={false}
                                            name="mobileNumber"
                                            placeholder={'Enter hiring manager mobile phone number'}
                                            value={newEntry.hiringManagerMobileNumber || ''}
                                            error={errors.mobileNumber}
                                            {...register('mobileNumber')}
                                            onChange={handleMobilePhoneNumberChange}
                                        />
                                    </div>
                                    <div className={classes.inputField}>
                                        <LabelledEmailText
                                            label={'Email'}
                                            id={'email'}
                                            disabled={!hasRole(acceptedRoles, roles)}
                                            mandatory={false}
                                            value={newEntry.hiringManagerEmail || ''}
                                            placeholder={'Enter hiring manager email address'}
                                            error={errors.email}
                                            name="email"
                                            {...register('email')}
                                            onChange={handleEmailAddressChange}
                                        />
                                    </div>
                                    <div className={classes.inputField}>
                                        <LabelledTextField
                                            label={'Address 1'}
                                            id={'address1'}
                                            disabled={!hasRole(acceptedRoles, roles)}
                                            mandatory={true}
                                            value={newEntry.addressLine1 || ''}
                                            placeholder={'Enter address 1'}
                                            error={errors.address1}
                                            {...register('address1')}
                                            onChange={handleAddress1Change}
                                        />
                                    </div>
                                    <div className={classes.inputField}>
                                        <LabelledTextField
                                            label={'Address 2'}
                                            id={'address2'}
                                            disabled={!hasRole(acceptedRoles, roles)}
                                            value={newEntry.addressLine2 || ''}
                                            placeholder={'Enter address 2'}
                                            error={errors.address2}
                                            {...register('address2')}
                                            onChange={handleAddress2Change}
                                        />
                                    </div>
                                    <div className={classes.inputField}>
                                        <LabelledTextField
                                            label={'Address 3'}
                                            id={'address3'}
                                            disabled={!hasRole(acceptedRoles, roles)}
                                            value={newEntry.addressLine3 || ''}
                                            placeholder={'Enter address 3'}
                                            error={errors.address3}
                                            {...register('address3')}
                                            onChange={handleAddress3Change}
                                        />
                                    </div>
                                    <div className={classes.inputField}>
                                        <LabelledTextField
                                            label={'Town/City'}
                                            id={'city'}
                                            disabled={!hasRole(acceptedRoles, roles)}
                                            mandatory={true}
                                            value={newEntry.city || ''}
                                            placeholder={'Enter town/city'}
                                            error={errors.city}
                                            {...register('city')}
                                            onChange={handleCityChange}
                                        />
                                    </div>
                                    <div className={classes.inputField}>
                                        <LabelledPostcodeText
                                            label={'Postcode'}
                                            id={'postcode'}
                                            disabled={!hasRole(acceptedRoles, roles)}
                                            mandatory={true}
                                            placeholder={'Enter Postcode'}
                                            value={newEntry.postcode || ''}
                                            name="postcode"
                                            error={errors.postcode}
                                            {...register('postcode')}
                                            onChange={handlePostcodeChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </DialogContent>

                        <DialogActions>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={
                                    !hasRole(acceptedRoles, loggedInUser.roles) ||
                                    isUpdateButtonDisabled
                                }>
                                Update
                            </Button>
                            <Button onClick={handleCancel} color="primary" variant="contained">
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    employmentTypeId: Yup.string().nullable().required('Please select an employment type.'),
    startDate: Yup.string()
        .required('Please select a start date')
        .nullable()
        .test('startDate', START_DATE_INVALID, function (startDate) {
            return getFutureDate(startDate) === false;
        }),
    title: Yup.string()
        .min(1, 'Vacancy title must be at least one character')
        .max(100, 'Vacancy title must be 100 characters or less'),
    hourlyPay: Yup.string()
        .required('Please enter a valid hourly pay')
        .min(0, 'Hourly pay rate limit cannot be negative')
        .max(999.99, 'Hourly pay rate limit must be 999.99 or less')
        .matches(validate.PAY_REGEXP, 'Invalid hourly pay'),
    sectorId: Yup.string().nullable().required('Please select a job sector.'),
    leadGeneratedById: Yup.string().nullable().required('Please select a participant / adviser.'),
    businessName: Yup.string()
        .min(1, 'Business name must be at least one character')
        .max(100, 'Business name must be 50 characters or less'),
    firstName: Yup.string()
        .min(1, 'First name must be at least one character')
        .max(50, 'First name must be 50 characters or less'),
    lastName: Yup.string()
        .min(1, 'Last name must be at least one character')
        .max(50, 'last name must be 50 characters or less'),
    officeNumber: Yup.string()
        .matches(/.{11,}/, {
            excludeEmptyString: false,
            message: 'Office number must be 11 characters or more'
        })
        .min(11, 'Office number is mandatory')
        .max(15, 'Office number must be 15 characters or less')
        .matches(validate.PHONE_REGEXP, {
            excludeEmptyString: true,
            message: 'Invalid Office number'
        }),
    mobileNumber: Yup.string()
        .matches(/.{11,}/, {
            excludeEmptyString: true,
            message: 'Mobile number must be 11 characters or more'
        })
        .max(15, 'Mobile number must be 15 characters or less')
        .matches(validate.PHONE_REGEXP, {
            excludeEmptyString: true,
            message: 'Invalid Mobile number'
        }),
    email: Yup.string()
        .email('Please enter a valid email address')
        .max(254, 'Email Address must be 254 characters or less'),
    address1: Yup.string()
        .min(1, 'Address 1 must be at least one character')
        .max(100, 'Address 1 must be 100 characters or less'),
    address2: Yup.string().max(100, 'Address 2 must be 100 characters or less'),
    address3: Yup.string().max(100, 'Address 3 must be 100 characters or less'),
    city: Yup.string()
        .min(1, 'City must be at least one character')
        .max(30, 'City must be 30 characters or less'),
    postcode: Yup.string().matches(validate.POSTCODE_REGEXP, {
        excludeEmptyString: false,
        message: 'Please enter a valid postcode'
    })
});

AddOwnPlacementEditor.propTypes = {
    open: PropTypes.bool.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string),
    onOwnPlacementEditor: PropTypes.func,
    placementEmploymentTypeDetails: PropTypes.array.isRequired,
    hoursPerWeekDetails: PropTypes.array.isRequired,
    jobSectorDetails: PropTypes.array.isRequired,
    ownPlacementDetails: PropTypes.array.isRequired
};

export default AddOwnPlacementEditor;
