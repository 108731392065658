import { getReqMessage } from '../../../../../utils/formValidation/errorMessageFunctions';

export const initialErrorState = {};

export const validate = (selectedJobSector) => {
    let newErrors = {};

    if (!selectedJobSector) {
        newErrors = {
            ...newErrors,
            jobSectorId: { error: true, message: getReqMessage('Job Sector') }
        };
    }

    return newErrors;
};
