import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from '@mui/material';

import { setErrorMessage } from '../../../../../store/formsState';
import { createPlacement, loadBusinessRecord } from '../../../../../store/recruitmentService';
import { selectLoggedInUser } from '../../../../../store/userSelectors';
import { getFutureDate } from '../../../../../utils/dateFunctions';
import { getConfiguredItems } from '../../../../../utils/directusFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../../utils/userRoles';
import * as validate from '../../../../../validation/validation';
import IconError from '../../../../IconError';
import LabelledTextField from '../../../../ui/editors/LabelledTextField';
import DDLOptionPicker from '../../../../ui/pickers/DDLOptionPicker';
import NoYesRadioPicker from '../../../../ui/pickers/NoYesRadioPicker';
import PolarisSwitchSet from '../../../../ui/pickers/PolarisSwitchSet';

import '../../submissionStyles/muiStyles.css';
import form from '../../../../../commonStyles/formStyles.module.css';
import classes from '../../submissionStyles/joboffer.module.css';

const EXPECTED_HOURS_GREATER_THAN_FORTY_EIGHT =
    'Over 48 hours does not conform to the working time regulations';
const START_DATE_INVALID = 'Start date must not be a future date';

const AddPlacementEditor = ({
    open,
    roles,
    onPlacementEditor,
    placementEmploymentTypeDetails,
    hoursPerWeekDetails,
    onPlacementCreated
}) => {
    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    // State for create placement newEntry
    // This matches the payload sent to the backend
    const initialState = {
        id: null,
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        businessName: '',
        city: null,
        claimableEvent: true,
        employmentTypeId: null,
        fixedFlexibleHour: 'fixed',
        fullServiceDeclined: false,
        hiringManagerEmail: null,
        hiringManagerId: null,
        hiringManagerName: null,
        hiringManagerOfficeNumber: null,
        hiringManagerMobileNumber: null,
        hourlyPay: null,
        hoursPerWeek: null,
        hoursPerWeekId: null,
        jobSectorId: null,
        jobStartEvidence: false,
        participantId: null,
        postcode: null,
        startDate: '',
        vacancyId: null,
        vacancyTitle: ''
    };
    const [newEntry, setNewEntry] = useState(initialState);
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [isOpen, setIsOpen] = React.useState(false);
    const [notFixedHours, setNotFixedHours] = useState(false);
    const [arrayPlacementEmploymentType, setArrayPlacementEmploymentType] = useState([]);
    const [
        placementEmploymentTypeWithoutSelfEmployed,
        setPlacementEmploymentTypeWithoutSelfEmployed
    ] = useState([]);

    const [isClearSelectedEmploymentType, setIsClearSelectedEmploymentType] = useState('0');

    // STORE STATE
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const loggedInUser = useSelector(selectLoggedInUser);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const { currentBusiness, currentVacancy } = useSelector(
        (state) => state.entities.recruitmentService
    );

    // EVENT HANDLERS
    const handlePayRateChange = (e) => {
        setNewEntry((prev) => ({ ...prev, hourlyPay: e.target.value }));
    };

    const handleHoursPerWeekChange = (e) => {
        setNewEntry((prev) => ({ ...prev, hoursPerWeek: e.target.value }));
    };

    const handleHoursPerWeekChangeDDL = (chosenId) => {
        if (chosenId) {
            setNewEntry((prev) => ({ ...prev, hoursPerWeekId: chosenId }));
        }
    };

    const handleEmploymentTypeChange = (chosenId) => {
        if (chosenId) {
            setNewEntry((prev) => ({ ...prev, employmentTypeId: chosenId }));
            clearErrors('employmentTypeId');
        }
    };

    const handleClaimableChange = (option) => {
        setNewEntry((prev) => ({ ...prev, claimableEvent: option }));
    };

    const handleStartDateChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            startDate: e.target.value === '' ? null : e.target.value
        }));
    };

    const handleClickOutside = () => {
        // Do nothing. User cannot exit by clicking outside dialog.
    };

    const handleCancel = () => {
        clearData();
    };

    const onSwitchFixedOrFlexible = (e) => {
        const { checked } = e.target;
        setNotFixedHours(checked);

        if (checked) {
            setNewEntry((prev) => ({ ...prev, hoursPerWeek: null }));
            setNewEntry((prev) => ({ ...prev, fixedFlexibleHour: 'flexible' }));
        } else {
            setNewEntry((prev) => ({ ...prev, hoursPerWeekId: '' }));
            setNewEntry((prev) => ({ ...prev, fixedFlexibleHour: 'fixed' }));
        }
    };

    // HELPER FNS
    const clearData = () => {
        setIsClearSelectedEmploymentType(Math.random());
        setNewEntry(initialState);
        setNotFixedHours(false);
        onPlacementEditor(false);
        reset();
        clearErrors();
    };

    const onCreated = () => {
        clearData();
        onPlacementCreated();
    };

    // USE EFFECTS
    useEffect(() => {
        setPlacementEmploymentTypeWithoutSelfEmployed(
            placementEmploymentTypeDetails.filter((el) => el.name !== 'Self Employed')
        );
    }, []);

    useEffect(() => {
        if (placementEmploymentTypeWithoutSelfEmployed?.length < 1) return;

        setArrayPlacementEmploymentType(
            getConfiguredItems(
                placementEmploymentTypeWithoutSelfEmployed,
                currentParticipant?.contractId
            )
        );
    }, [placementEmploymentTypeWithoutSelfEmployed]);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        if (Object.keys(currentVacancy).length < 1) return;

        dispatch(loadBusinessRecord(currentVacancy.businessRecordId));

        setNewEntry((prev) => ({
            ...prev,
            businessName: currentVacancy.businessName,
            hiringManagerId: currentVacancy.hiringManagerId,
            jobSectorId: currentVacancy.sectorId,
            vacancyId: currentVacancy.id,
            vacancyTitle: currentVacancy.title
        }));
    }, [currentVacancy]);

    useEffect(() => {
        if (Object.keys(currentBusiness).length < 1) return;

        setNewEntry((prev) => ({
            ...prev,
            addressLine1: currentBusiness.addressLine1,
            addressLine2: currentBusiness.addressLine2,
            addressLine3: currentBusiness.addressLine3,
            city: currentBusiness.city,
            postcode: currentBusiness.postcode
        }));

        const contactDetails = currentBusiness.contactCards.find(
            (entry) => entry.id === currentVacancy.hiringManagerId
        );

        setNewEntry((prev) => ({
            ...prev,
            hiringManagerEmail: contactDetails?.email || '',
            hiringManagerName: `${contactDetails?.firstName || ''} ${
                contactDetails?.lastName || ''
            }`,
            hiringManagerOfficeNumber: contactDetails?.officeNumber || '',
            hiringManagerMobileNumber: contactDetails?.mobileNumber || ''
        }));
    }, [currentBusiness]);

    useEffect(() => {
        if (successMessage.startsWith(`Job placement has been added`)) {
            onCreated();
        }
    }, [successMessage]);

    const onSubmit = () => {
        setValue('employmentTypeId', newEntry.employmentTypeId, { shouldValidate: true });
        setValue('startDate', newEntry.startDate, { shouldValidate: true });
        let hoursPerWeek;
        if (newEntry.hoursPerWeek > '') hoursPerWeek = parseFloat(newEntry.hoursPerWeek);
        if (
            newEntry.hourlyPay === null ||
            newEntry.hourlyPay === '0' ||
            newEntry.hourlyPay === '0.0' ||
            newEntry.hourlyPay === '0.00'
        ) {
            dispatch(setErrorMessage('Hourly pay cannot be zero or blank'));
        } else if (hoursPerWeek > 48) {
            dispatch(setErrorMessage(EXPECTED_HOURS_GREATER_THAN_FORTY_EIGHT));
        } else if (
            (!notFixedHours && newEntry.hoursPerWeek === null) ||
            (!notFixedHours && newEntry.hoursPerWeek === '0') ||
            (!notFixedHours && newEntry.hoursPerWeek === '0.0') ||
            (!notFixedHours && newEntry.hoursPerWeek === '0.00')
        ) {
            dispatch(setErrorMessage('Hours per week cannot be zero or blank'));
        } else if (
            (notFixedHours && newEntry.hoursPerWeekId === '') ||
            (notFixedHours && newEntry.hoursPerWeekId === null)
        ) {
            dispatch(setErrorMessage('Please select hours per week'));
        } else {
            const payload = {
                ...newEntry,
                participantId: currentParticipant.id
            };
            dispatch(createPlacement(payload));
        }
    };

    // RENDER
    return (
        <div
            onSubmit={(e) => {
                //  This stops the Submit event from bubbling up to the form enclosing this component
                e.preventDefault();
                e.stopPropagation();
            }}>
            <div>
                <Dialog
                    className="muiDialogWrapper"
                    open={isOpen}
                    onClose={handleClickOutside}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Create Placement</DialogTitle>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <DialogContent data-testid="dialog-content">
                            <div className={form.formSection}>
                                <div className={form.formColumn}>
                                    <div className={classes.inputField}>
                                        <LabelledTextField
                                            label={'Hourly Pay'}
                                            id={'hourlyPay'}
                                            disabled={!hasRole(acceptedRoles, roles)}
                                            mandatory={true}
                                            value={newEntry.hourlyPay || ''}
                                            placeholder={'Enter hourly pay'}
                                            error={errors.hourlyPay}
                                            {...register('hourlyPay')}
                                            onChange={handlePayRateChange}
                                        />
                                    </div>
                                    <div>
                                        <PolarisSwitchSet
                                            id="fixedOrFlexible"
                                            disabled={!hasRole(acceptedRoles, roles)}
                                            label="Fixed or Flexible"
                                            leftLabel="Fixed"
                                            rightLabel="Flexible"
                                            checked={notFixedHours}
                                            onSwitch={onSwitchFixedOrFlexible}
                                        />
                                    </div>
                                    {!notFixedHours ? (
                                        <div className={classes.inputField}>
                                            <LabelledTextField
                                                label={'Hours per Week'}
                                                id={'hoursPerWeek'}
                                                disabled={!hasRole(acceptedRoles, roles)}
                                                mandatory={!notFixedHours && true}
                                                value={newEntry.hoursPerWeek || ''}
                                                placeholder={'Enter hours'}
                                                onChange={handleHoursPerWeekChange}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <DDLOptionPicker
                                                label={'Hours Per Week'}
                                                id="hoursPerWeekId"
                                                key={isClearSelectedEmploymentType}
                                                disabled={!hasRole(acceptedRoles, roles)}
                                                mandatory={notFixedHours && true}
                                                menuItems={hoursPerWeekDetails}
                                                onChange={(e) => {
                                                    if (e !== null && e !== undefined) {
                                                        handleHoursPerWeekChangeDDL(e);
                                                    }
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className={form.formColumn}>
                                    <NoYesRadioPicker
                                        id="claimable"
                                        disabled={!hasRole(acceptedRoles, roles)}
                                        radioButtonPick={newEntry.claimableEvent}
                                        text={'Claimable Event?'}
                                        onChange={handleClaimableChange}></NoYesRadioPicker>
                                    <DDLOptionPicker
                                        label={'Placement Employment Type'}
                                        id="employmentTypeId"
                                        key={isClearSelectedEmploymentType}
                                        disabled={!hasRole(acceptedRoles, roles)}
                                        mandatory={true}
                                        menuItems={arrayPlacementEmploymentType}
                                        error={errors.employmentTypeId}
                                        {...register('employmentTypeId')}
                                        onChange={(e) => {
                                            if (e !== null && e !== undefined) {
                                                handleEmploymentTypeChange(e);
                                            }
                                        }}
                                    />
                                    <TextField
                                        label="Start Date *"
                                        id="startDate"
                                        disabled={!hasRole(acceptedRoles, roles)}
                                        type="date"
                                        value={newEntry.startDate || ''}
                                        sx={{ width: '100%' }}
                                        InputLabelProps={{ shrink: true }}
                                        {...register('startDate')}
                                        onChange={handleStartDateChange}
                                    />
                                    <IconError text={errors.startDate || null} />
                                </div>
                            </div>
                        </DialogContent>

                        <DialogActions>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={!hasRole(acceptedRoles, loggedInUser.roles)}>
                                Update
                            </Button>
                            <Button onClick={handleCancel} color="primary" variant="contained">
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    hourlyPay: Yup.string()
        .required('Please enter a valid hourly pay')
        .min(0, 'Hourly pay rate limit cannot be negative')
        .max(999.99, 'Hourly pay rate limit must be 999.99 or less')
        .matches(validate.PAY_REGEXP, 'Invalid hourly pay'),
    employmentTypeId: Yup.string()
        .nullable()
        .required('Please select a placement employment type.'),
    startDate: Yup.string()
        .required('Please select a start date')
        .nullable()
        .test('startDate', START_DATE_INVALID, function (startDate) {
            return getFutureDate(startDate) === false;
        })
});

AddPlacementEditor.propTypes = {
    open: PropTypes.bool.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string),
    onPlacementEditor: PropTypes.func,
    placementEmploymentTypeDetails: PropTypes.array.isRequired,
    hoursPerWeekDetails: PropTypes.array.isRequired,
    onPlacementCreated: PropTypes.func.isRequired
};

export default AddPlacementEditor;
