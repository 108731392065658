import { compareUnorderedArrays } from '../../../utils/arrayUtils';
import { SECONDS_IN_ONE_DAY } from '../../../utils/dateFunctions';
import { NO_UPDATE_ERROR } from '../../../utils/formValidation/commonErrorConstants';
import {
    getExceededValueMessage,
    getNumMinMessage,
    getSelectMessage
} from '../../../utils/formValidation/errorMessageFunctions';
import { scrollToElement } from '../../../utils/scrollToElement';

const MIN_NUM_DAYS = 1;
const VOICE_GRACE_PERIOD_MAX_SECONDS_DIGIT = 3000000;
export const VOICE_GRACE_PERIOD_MAX_DAYS_DIGIT = Math.floor(
    VOICE_GRACE_PERIOD_MAX_SECONDS_DIGIT / SECONDS_IN_ONE_DAY
);

const ERROR_MIN_DAYS = getNumMinMessage();
const ERROR_NO_TEMPLATE = getSelectMessage('template');
const ERROR_NO_LOCATION = getSelectMessage('location', 'at least one');
const ERROR_MAX_GRACE_PERIOD_VALUE = getExceededValueMessage(VOICE_GRACE_PERIOD_MAX_DAYS_DIGIT);
export const BUTTON_ERROR = NO_UPDATE_ERROR;
export const initialErrorState = {};

const messageTypes = [
    'SMS_APPOINTMENT_REMINDER',
    'EMAIL_APPOINTMENT_REMINDER',
    'SMS_MANDATORY_APPOINTMENT_REMINDER',
    'EMAIL_MANDATORY_APPOINTMENT_REMINDER',
    'SMS_FTA_APPOINTMENT_FOLLOW_UP',
    'EMAIL_FTA_APPOINTMENT_FOLLOW_UP',
    'SMS_MANDATORY_FTA_APPOINTMENT_FOLLOW_UP',
    'EMAIL_MANDATORY_FTA_APPOINTMENT_FOLLOW_UP',
    'SMS_REMOTE_APPOINTMENT_REMINDER',
    'EMAIL_REMOTE_APPOINTMENT_REMINDER',
    'SMS_COURSE_MODULE_REMINDER',
    'EMAIL_COURSE_MODULE_REMINDER',
    'SMS_FTA_COURSE_MODULE_FOLLOW_UP',
    'EMAIL_FTA_COURSE_MODULE_FOLLOW_UP'
];
export const initialMessageErrorState = () => {
    let init = {};
    messageTypes.forEach(
        (el) =>
            (init = {
                ...init,
                [`communicationTemplateId-${el}`]: { error: false, message: '' },
                [`numDays-${el}`]: { error: false, message: '' }
            })
    );
    return init;
};

export const validate = (
    serviceId,
    locationIds,
    constants,
    automatedMessages,
    existingAutomatedMessages,
    existingConstants
) => {
    let newErrors = {};

    newErrors = validateLocations(serviceId, locationIds, newErrors);

    newErrors = validateParticipantVoiceGracePeriod(
        serviceId,
        constants.participantVoiceGracePeriod,
        newErrors
    );

    Object.values(automatedMessages).forEach((el) => {
        if (el.enabled) {
            if (!el.communicationTemplateId) {
                newErrors = {
                    ...newErrors,
                    [`communicationTemplateId-${el.type}`]: {
                        error: true,
                        message: ERROR_NO_TEMPLATE
                    }
                };
            }
            if (!el.numDays || el.numDays < MIN_NUM_DAYS) {
                newErrors = {
                    ...newErrors,
                    [`numDays-${el.type}`]: {
                        error: true,
                        message: ERROR_MIN_DAYS
                    }
                };
            }
        }
    });

    if (Object.keys(newErrors).length > 0) {
        scrollToElement(newErrors);
        return { newErrors };
    } else {
        const isExistingResults =
            Object.keys(existingAutomatedMessages).length < 1 ||
            Object.keys(automatedMessages).length < 1
                ? [false]
                : Object.values(automatedMessages)
                      .map((el) => ({ ...el, numDays: el.numDays === '' ? null : el.numDays }))
                      .map((el, i) => checkDiff(el, Object.values(existingAutomatedMessages)[i]));

        const isAutomatedMessagesSame = isExistingResults.every((el) => el);

        const isConstantsSame =
            Object.keys(existingConstants).length > 0
                ? checkDiff(constants, existingConstants)
                : false;

        return { newErrors, isAutomatedMessagesSame, isConstantsSame };
    }
};

const validateLocations = (serviceId, locationIds, errors) => {
    let newErrors = errors;
    if (locationIds?.length < 1) {
        newErrors = {
            ...newErrors,
            [`${serviceId}-location`]: {
                error: true,
                message: ERROR_NO_LOCATION
            }
        };
    }

    return newErrors;
};

const validateParticipantVoiceGracePeriod = (serviceId, number, errors) => {
    if (number === '') return errors;
    let newErrors = errors;

    if (+number > VOICE_GRACE_PERIOD_MAX_SECONDS_DIGIT) {
        newErrors = {
            ...newErrors,
            [`${serviceId}-participantVoiceGracePeriod`]: {
                error: true,
                message: ERROR_MAX_GRACE_PERIOD_VALUE
            }
        };
    }

    return newErrors;
};

export const checkDiff = (newValues, existingValues) => {
    return compareUnorderedArrays(Object.values(newValues), Object.values(existingValues));
};
