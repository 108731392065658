import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import {
    clearLocationsForServices,
    loadCommunicationTypes,
    loadLocationIdsAndNames,
    loadLocationsForServices,
    loadServiceDetailsForContractId
} from '../../../store/directusService';
import { NO_CONFIGURED_SERVICES_ERROR } from '../../../utils/formValidation/commonErrorConstants';
import { getSelectMessage } from '../../../utils/formValidation/errorMessageFunctions';
import AccordionHeader from '../../ui/accordion/AccordionHeader';
import AccordionIcon from '../../ui/accordion/AccordionIcon';
import LoadingSpinner from '../../ui/LoadingSpinner';

import ConfigureService from './ConfigureService';

import local from './configureServiceStyles.module.css';

const ConfigureServiceAdmin = ({ contractId, onCheckForContract }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const [services, setServices] = useState([]);
    const [serviceIds, setServiceIds] = useState([]);
    const [clear, setClear] = useState(true);

    // STORE STATE
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const servicesForContract = useSelector(
        (state) => state.entities.directusService.serviceDetailsForContractId
    );
    const allLocations = useSelector((state) => state.entities.directusService.locationIdsAndNames);
    const locationsForServices = useSelector(
        (state) => state.entities.directusService.locationsForServices
    );
    const communicationTypes = useSelector(
        (state) => state.entities.directusService.communicationTypes
    );

    // USEEFFECTS

    useEffect(() => {
        contractId ? contractSetup() : onCheckForContract();
    }, [contractId]);

    useEffect(() => {
        setServices(servicesForContract);
    }, [servicesForContract]);

    useEffect(() => {
        if (services.length < 1) return;
        const serviceIds = services.map((el) => el.id);
        dispatch(loadLocationsForServices(serviceIds));
        setServiceIds(serviceIds);
    }, [services]);

    // HELPER FNS
    const contractSetup = () => {
        setServices([]);
        setServiceIds([]);
        setClear(false);
        dispatch(loadServiceDetailsForContractId(contractId));
        dispatch(clearLocationsForServices());
        allLocations?.length < 1 && dispatch(loadLocationIdsAndNames());
        communicationTypes?.length < 1 && dispatch(loadCommunicationTypes());
    };

    // RENDER

    let content = '';
    if (!contractId || services?.length < 1 || services[0]?.contract?.id !== contractId) {
        if (services?.length < 1) content = NO_CONFIGURED_SERVICES_ERROR;
        if (!contractId) content = getSelectMessage('contract');
        return <LoadingSpinner content={content} />;
    }

    return (
        <div className={local.serviceFormWrapper}>
            {services.map((el, i) => (
                <div key={el.id} className={local.serviceAccordion}>
                    <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                        <AccordionSummary
                            expandIcon={<AccordionIcon />}
                            aria-controls={`panel${i}a-content`}
                            id={`panel${i}a-header`}>
                            <AccordionHeader>{el.name}</AccordionHeader>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ConfigureService
                                key={el.id}
                                index={i}
                                contractId={contractId}
                                service={el}
                                serviceIds={serviceIds}
                                allLocations={allLocations}
                                locationsForServices={locationsForServices}
                                roles={roles}
                                clear={clear}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>
            ))}
        </div>
    );
};

export default ConfigureServiceAdmin;

ConfigureServiceAdmin.propTypes = {
    contractId: PropTypes.string.isRequired,
    onCheckForContract: PropTypes.func
};
