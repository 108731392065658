import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { hasRole } from '../../../../utils/userRoles';
import Button from '../../../formElements/Button';
import IconError from '../../../IconError';

import ModuleTemplate from './ModuleTemplate';
import ModuleTemplateEditor from './ModuleTemplateEditor';

import app from '../../../../app.module.css';
import cards from '../../../../commonStyles/cardContainer.module.css';
import styles from '../../courseStyles/moduleTemplateManagementStyle.module.css';

const ModuleTemplateManagement = ({
    moduleTemplates,
    barrierNameDetails,
    roles,
    acceptedRoles,
    onUpdate,
    error = {},
    onClearError
}) => {
    // LOCAL STATE
    const [showCreateModuleModal, setShowCreateModuleModal] = useState(false);
    const [editModuleType, setEditModuleType] = useState('create');
    const [currentModule, setCurrentModule] = useState({});

    // EVENT HANDLERS
    const onAddModule = () => {
        setShowCreateModuleModal(true);
        setEditModuleType('create');
        setCurrentModule({});
    };

    const onEditModule = (entry) => {
        setShowCreateModuleModal(true);
        setEditModuleType('edit');
        setCurrentModule(entry);
    };

    const addModule = (entry) => {
        error && onClearError('modules');
        onUpdate('moduleTemplates', [...moduleTemplates, entry]);
        setShowCreateModuleModal(false);
        setCurrentModule({});
    };

    const updateModule = (entry) => {
        error && onClearError('modules');
        onUpdate(
            'moduleTemplates',
            moduleTemplates.map((el) => (el.id === entry.id ? entry : el))
        );
        setShowCreateModuleModal(false);
        setCurrentModule({});
    };

    const onCancelModule = () => {
        setShowCreateModuleModal(false);
        setCurrentModule({});
    };

    // RENDER
    return (
        <div className={styles.moduleManagementWrapper}>
            <h2 className={app.sectionHeading}>Module Templates:</h2>
            <div className={`${cards.cards} ${cards.cardContainer}`}>
                {moduleTemplates.length > 0 &&
                    moduleTemplates.map((el) => (
                        <div key={el.id}>
                            <ModuleTemplate
                                entry={el}
                                barrierNameDetails={barrierNameDetails || []}
                                disabled={!hasRole(acceptedRoles, roles)}
                                onEdit={onEditModule}
                                roles={roles}
                                acceptedRoles={acceptedRoles}
                            />
                        </div>
                    ))}
            </div>
            {showCreateModuleModal && (
                <ModuleTemplateEditor
                    type={editModuleType}
                    entry={currentModule}
                    barrierNameDetails={barrierNameDetails || []}
                    disabled={!hasRole(acceptedRoles, roles)}
                    onAdd={addModule}
                    onUpdate={updateModule}
                    isOpen={showCreateModuleModal}
                    onClose={onCancelModule}
                />
            )}

            <Button
                id="addModuleTemplate"
                type="button"
                content="Add a Module"
                onClick={onAddModule}
            />

            {error?.error && (
                <div>
                    <IconError text={error} />
                </div>
            )}
        </div>
    );
};

ModuleTemplateManagement.propTypes = {
    moduleTemplates: PropTypes.arrayOf(PropTypes.object),
    roles: PropTypes.arrayOf(PropTypes.string),
    acceptedRoles: PropTypes.arrayOf(PropTypes.string),
    barrierNameDetails: PropTypes.array.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onClearError: PropTypes.func.isRequired,
    error: PropTypes.object
};

export default ModuleTemplateManagement;
