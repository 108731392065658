import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../../api/pagination';
import {
    clearNewAppointment,
    createAppointment,
    createAppointmentSlot,
    setCurrentAppointment,
    setOpenAppointmentSchedulerPanel
} from '../../../store/calendarService';
import {
    loadAppointmentTypeDetailsByContractIds,
    loadLocationsByServiceId,
    loadServiceDetailsByService,
    setCurrentServiceId
} from '../../../store/directusService';
import {
    loadParticipantEmployability,
    searchParticipants,
    setCurrentlySelectedParticipant
} from '../../../store/participantService';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import DateSelect from '../../formElements/DateSelect';
import SingleSelect from '../../formElements/SingleSelect';
import StaticField from '../../formElements/StaticField';
import Switch from '../../formElements/Switch';
import TextInputField from '../../formElements/TextInputField';
import Panel from '../../ui/panel/Panel';
import AppointmentTypeSelect from '../components/AppointmentTypeSelect';
import LocationSelect from '../components/LocationSelect';
import MandatoryActivitiesSwitch from '../components/MandatoryActivitiesSwitch';
import StartAndEndTimesSelect from '../components/StartAndEndTimesSelect';
import StartAppointmentRow from '../components/StartAppointmentRow';
import { getServiceIds } from '../utils/calendarUtils';
import { validate } from '../validation/validateUserAppointmentCreate';

import form from '../../formElements/formElementStyles/commonFormElements.module.css';
import sidePanel from '../styles/schedulePanel.module.css';

const PT_LOAD_SIZE = DEFAULT_PAGE_LOAD_SIZE;

const UserAppointmentCreate = ({ newAppointment, currentCalendarOwner }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: '',
        typeId: '',
        userId: '',
        locationId: '',
        startTime: '08:00',
        endTime: '09:00',
        date: String(new Date().toISOString().split('T')[0]),
        serviceId: '',
        participantId: '',
        participantIds: [],
        mandatory: false,
        numberOfSlots: null,
        appointmentSlots: false,
        type: '',
        service: '',
        participant: ''
    };

    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isEditable, setIsEditable] = useState(false);
    const [participants, setParticipants] = useState([]);
    const [filteredServices, setFilteredServices] = useState([]);
    const [filteredParticipants, setFilteredParticipants] = useState([]);
    const [serviceIds, setServiceIds] = useState([]);
    const [selectedParticipants] = useState([]);
    const [isDisabledStartAppointmentButton, setIsDisabledStartAppointmentButton] = useState(false);
    const [currentParticipant, setCurrentParticipant] = useState({});
    const [isDisabledSaveButton, setIsDisabledSaveButton] = useState(false);
    const [isCreated, setIsCreated] = useState(false);

    // STORE STATE
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const participantsSearch = useSelector(
        (state) => state.entities.participantService.participantsSearch
    );
    const participantsSearchMetaData = useSelector(
        (state) => state.entities.participantService.participantsSearchMetaData
    );
    const serviceDetailsById = useSelector(
        (state) => state.entities.directusService.serviceDetailsById
    );
    const locationsByServiceId = useSelector(
        (state) => state.entities.directusService.locationsByServiceId
    );
    const appointmentTypeDetailsForContracts = useSelector(
        (state) => state.entities.directusService.appointmentTypeDetailsForContracts
    );

    const openAppointmentSchedulerPanel = useSelector(
        (state) => state.entities.calendarService.openAppointmentSchedulerPanel
    );
    const newCreatedAppointment = useSelector(
        (state) => state.entities.calendarService.newAppointment
    );
    const errorMessage = useSelector((state) => state.entities.formsState.errorMessage);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS
    useEffect(() => {
        if (!currentCalendarOwner || Object.keys(currentCalendarOwner).length < 1) return;

        const serviceIds = getServiceIds(currentCalendarOwner);
        if (serviceIds?.length < 1) return;
        setServiceIds(serviceIds);

        if (currentCalendarOwner?.contractIds?.length > 0)
            dispatch(loadAppointmentTypeDetailsByContractIds(currentCalendarOwner?.contractIds));
        const missingServiceDetailIds = serviceIds.filter((el) => !(el in serviceDetailsById));
        if (missingServiceDetailIds.length)
            dispatch(loadServiceDetailsByService(missingServiceDetailIds));
        participants?.length < 1
            ? dispatch(searchParticipants({ serviceIds }, 0, PT_LOAD_SIZE))
            : setParticipants(participantsSearch);
        setIsEditable(hasRole(acceptedRoles, roles));
    }, []);

    useEffect(() => {
        if (!participantsSearchMetaData.last) {
            dispatch(
                searchParticipants(
                    { serviceIds },
                    participantsSearchMetaData.number + 1,
                    PT_LOAD_SIZE
                )
            );
        } else setParticipants(participantsSearch);
    }, [participantsSearch, participantsSearchMetaData]);

    useEffect(() => {
        if (!currentCalendarOwner?.id) return;
        setNewEntry({
            ...newEntry,
            serviceId: currentCalendarOwner.primaryService,
            userId: currentCalendarOwner.id,
            startTime: newAppointment.startTime,
            endTime: newAppointment.endTime,
            date: newAppointment.date,
            service: serviceDetailsById[currentCalendarOwner?.primaryService]?.name || ''
        });
    }, [serviceDetailsById]);

    useEffect(() => {
        if (serviceIds.length < 1 || Object.values(serviceDetailsById).length < 1) return;
        setFilteredServices(
            serviceIds
                .map((el) => (el in serviceDetailsById ? serviceDetailsById[el] : null))
                .filter((el) => el)
        );
    }, [serviceDetailsById, serviceIds]);

    useEffect(() => {
        if (!newEntry.serviceId) return;
        if (!(newEntry.serviceId in locationsByServiceId)) {
            dispatch(setCurrentServiceId(newEntry.serviceId));
            dispatch(loadLocationsByServiceId([newEntry.serviceId]));
        }
    }, [newEntry.serviceId]);

    useEffect(() => {
        if (!newEntry.serviceId || participants?.length < 1) return;
        setFilteredParticipants(
            participants
                .filter(
                    (el) =>
                        el.serviceId === newEntry.serviceId &&
                        new Date(newEntry.date) >= new Date(el.referralDate)
                )
                .map((el) => ({ ...el, name: `${el.firstName} ${el.lastName} - ${el.ptCode}` }))
        );
    }, [newEntry.serviceId, newEntry.date, participants]);

    useEffect(() => {
        setIsDisabledSaveButton(Object.values(errors).some((el) => el.error));
    }, [errors]);

    useEffect(() => {
        if (
            successMessage === `Appointment ${newEntry.type} has been created` ||
            successMessage === `Appointment slot ${newEntry.type} has been created`
        ) {
            setIsCreated(true);
            if (newEntry.participantId && !newEntry.appointmentSlots) {
                setIsDisabledStartAppointmentButton(false);
            } else onPanelClose();
        }
    }, [successMessage]);

    useEffect(() => {
        if (errorMessage) {
            setIsDisabledSaveButton(false);
        }
    }, [errorMessage]);

    // HELPER FNS
    const clearError = (key) => {
        if (key in errors && errors[key].error)
            setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    // EVENT HANDLERS
    const onPanelClose = () => {
        setNewEntry(initialState);
        dispatch(setCurrentAppointment({}));
        dispatch(setOpenAppointmentSchedulerPanel(false));
        dispatch(clearNewAppointment());
    };

    const onServiceChange = (id) => {
        clearError('serviceId');
        if (id === newEntry.serviceId) return;
        setIsDisabledStartAppointmentButton(true);
        setNewEntry((prev) => ({
            ...prev,
            serviceId: id,
            service: serviceDetailsById[id]?.name || '',
            locationId: '',
            participantId: '',
            participant: ''
        }));
    };

    const onUpdate = (key, value) => {
        clearError(key);
        setIsDisabledStartAppointmentButton(true);
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onUpdateTime = (key, value) => {
        clearError(key === 'endTime' ? 'startTime' : 'endTime');
        onUpdate(key, value);
    };

    const onUpdateAppointmentType = (key, value, type) => {
        onUpdate(key, value);
        setNewEntry((prev) => ({ ...prev, type }));
    };

    const onSwitchType = (key, value) => {
        onUpdate(key, value);
        setNewEntry((prev) => ({ ...prev, numberOfSlots: value ? 1 : null }));
    };

    const onUpdateParticipant = (participantId) => {
        const participant = participantId
            ? filteredParticipants.find((el) => el.id === participantId)
            : '';
        if (!participant) return;
        onUpdate('participantId', participantId);
        setNewEntry((prev) => ({ ...prev, participant: participant.name }));
        setCurrentParticipant(participant);
        dispatch(loadParticipantEmployability(participantId));
        dispatch(setCurrentlySelectedParticipant(participant));
    };

    const onUpdateCapacity = (e) => {
        const value = e.target.value.slice(0, 2);
        onUpdate('numberOfSlots', value < 1 ? 1 : value);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setIsDisabledSaveButton(true);
        const newErrors = validate(newEntry);
        if (Object.values(newErrors).some((el) => el.error)) {
            setErrors(newErrors);
            return;
        }
        const {
            /* eslint-disable no-unused-vars */
            id,
            type,
            appointmentSlots,
            service,
            participant,
            participantId,
            participantIds,
            numberOfSlots,
            mandatory,
            ...rest
        } = newEntry;

        if (newEntry.appointmentSlots) {
            const participantIds = selectedParticipants.map((el) => el.id);
            const payload = { ...rest, numberOfSlots, participantIds };
            dispatch(createAppointmentSlot(newEntry.type, payload));
        }
        if (!newEntry.appointmentSlots) {
            const payload = { ...rest, participantId, mandatory };
            dispatch(createAppointment(newEntry.type, payload));
        }
    };

    // RENDER
    return (
        <Panel width="500px" open={openAppointmentSchedulerPanel} onToggle={onPanelClose}>
            <div className={sidePanel.formWrapper}>
                <form onSubmit={onSubmit} data-testid="user_appointment_create_form">
                    <span className={form.noLabel}>
                        <sup>*</sup>
                    </span>
                    <SingleSelect
                        id="serviceId"
                        placeholder="Select a service"
                        disabled={!isEditable || isCreated}
                        customClass="underlineOnly"
                        customErrorClass="underLinedInputError"
                        menuItems={filteredServices || []}
                        selectedId={newEntry.serviceId || ''}
                        selected={serviceDetailsById[newEntry.serviceId]}
                        error={errors.serviceId}
                        onChange={(id) => onServiceChange(id)}
                    />

                    <Switch
                        id="appointmentSlotsSwitch"
                        leftLabel="Appointment"
                        rightLabel="Appointment Slots"
                        subLabelClass="formLabel"
                        disabled={isCreated}
                        customClass="minHeightWrapper"
                        checked={newEntry.appointmentSlots}
                        onChange={(e) => onSwitchType('appointmentSlots', e.target.checked)}
                    />

                    <StaticField label="Adviser" content={currentCalendarOwner.emailAddress} />

                    <AppointmentTypeSelect
                        appointmentTypes={appointmentTypeDetailsForContracts}
                        appointmentTypeId={newEntry.typeId}
                        disabled={!newEntry.serviceId || isCreated}
                        onAppointmentTypeChange={onUpdateAppointmentType}
                        errors={errors}
                    />

                    <LocationSelect
                        locationId={newEntry.locationId}
                        serviceId={newEntry.serviceId}
                        errors={errors}
                        disabled={!newEntry.serviceId || isCreated}
                        onLocationChange={onUpdate}
                        isDisabled={!newEntry.serviceId}
                    />

                    <StartAndEndTimesSelect
                        startTime={newEntry.startTime}
                        endTime={newEntry.endTime}
                        disabled={isCreated}
                        errors={errors}
                        onTimeChange={onUpdateTime}
                    />
                    <DateSelect
                        mandatory={true}
                        value={newEntry.date}
                        disabled={isCreated}
                        error={errors.date}
                        onDateChange={(date) => onUpdate('date', date)}
                    />

                    {!newEntry.appointmentSlots ? (
                        <>
                            <SingleSelect
                                id="participantId"
                                label="Participant"
                                placeholder="Select participant"
                                disabled={!newEntry.serviceId || isCreated}
                                mandatory={true}
                                menuItems={filteredParticipants || []}
                                selectedId={newEntry.participantId || ''}
                                selected={
                                    filteredParticipants.find(
                                        (el) => el.id === newEntry.participantId
                                    ) || {}
                                }
                                error={errors.participantId}
                                onChange={onUpdateParticipant}
                            />

                            <MandatoryActivitiesSwitch
                                isMandatory={newEntry.mandatory}
                                disabled={isCreated}
                                onSwitch={onUpdate}
                            />
                        </>
                    ) : (
                        <TextInputField
                            id="numberOfSlots"
                            data-testid="numberOfSlots"
                            type="number"
                            label="Number of slots"
                            min={1}
                            max={99}
                            disabled={isCreated}
                            value={newEntry.numberOfSlots || 1}
                            customClass="capacityInput"
                            onChange={onUpdateCapacity}
                        />
                    )}
                    <div className={sidePanel.updateButton}>
                        <Button
                            id="appointmentCreateButton"
                            content="Save"
                            disabled={isDisabledSaveButton}
                        />
                    </div>

                    {newCreatedAppointment?.id && currentParticipant && (
                        <StartAppointmentRow
                            onPanelClose={onPanelClose}
                            disabled={isDisabledStartAppointmentButton}
                            appointment={newCreatedAppointment}
                            participant={currentParticipant}
                        />
                    )}
                </form>
            </div>
        </Panel>
    );
};

UserAppointmentCreate.propTypes = {
    newAppointment: PropTypes.object,
    currentCalendarOwner: PropTypes.object
};

export default UserAppointmentCreate;
