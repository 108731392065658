import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DeleteIcon from '@mui/icons-material/Delete';
import { Card, CardContent, CardHeader, Icon, Stack, Typography } from '@mui/material';

import {
    POLARIS_DARKER_GREY,
    POLARIS_DOUBLE_DECKER_RED,
    POLARIS_GREY,
    POLARIS_ROYAL_BLUE,
    POLARIS_WHITE,
    SCREEN_SETTINGS
} from '../../../../themes/theme';
import { hasRole } from '../../../../utils/userRoles';
import ConfirmPrompt from '../../notices/confirmPrompt/ConfirmPrompt';

import JobGoalsEditor from './JobGoalsEditor';

import card from '../cardStyles/cardView.module.css';

const JobGoalsViewer = ({
    cardData,
    sendDelete,
    sendUpdate,
    disabled = false,
    arrayJobSectors,
    editAndDeleteRoles,
    roles,
    editType
}) => {
    const [showConfirmPrompt, setShowConfirmPrompt] = useState(false);

    const getName = (id) => {
        if (!id || id === '') {
            return '';
        }
        return arrayJobSectors.find((item) => item.id === id)?.name || '';
    };

    const iconColor = disabled ? POLARIS_DARKER_GREY : POLARIS_WHITE;

    const backgroundColor = disabled ? POLARIS_GREY : POLARIS_ROYAL_BLUE;

    const confirmDelete = (_, doDelete) => {
        if (!doDelete) {
            setShowConfirmPrompt(false);
            return;
        }
        sendDelete(cardData);
    };

    /*
     * This is the callback when there is a change in the editor.
     */
    const handleChange = (data) => {
        // Update handler with new data.
        // This will cause a render of this component
        // which will show the new data.
        sendUpdate(data);
    };

    /**
     * Get a summary of card information.
     *
     * @returns {JSX.Element}
     */
    const getCardContent = () => {
        // Return incomplete in no name or nor sector
        if (
            cardData.sectorId === undefined ||
            cardData.sectorId === null ||
            cardData.sectorId.trim === ''
        ) {
            return (
                <Typography
                    variant="body1"
                    data-testid="job_goals_viewer_incomplete"
                    sx={{ color: POLARIS_DOUBLE_DECKER_RED }}>
                    Incomplete Job Goal
                </Typography>
            );
        }

        return (
            <>
                <Typography variant="body1" data-testid="job_goals_viewer_sector">
                    <strong>Job Sector: </strong>
                    {getName(cardData.sectorId)}
                </Typography>
                <Typography variant="body1" data-testid="job_goals_viewer_title">
                    <strong>Job Title: </strong>
                    {cardData.title || ' - '}
                </Typography>
            </>
        );
    };

    return (
        <>
            {showConfirmPrompt && (
                <ConfirmPrompt
                    isOpen={showConfirmPrompt}
                    onCancel={(e) => confirmDelete(e, false)}
                    onConfirm={(e) => confirmDelete(e, true)}
                    content="Are you sure you want to delete this job goal?"
                />
            )}
            <div className={card.cardView}>
                <Card
                    elevation={3}
                    sx={{
                        backgroundColor: POLARIS_WHITE,
                        borderRadius: SCREEN_SETTINGS.radius.small,
                        height: '100%'
                    }}>
                    <CardHeader
                        title={<Typography variant="h6">Job Goals</Typography>}
                        sx={{
                            p: 0.8,
                            m: 0.8,
                            // background color is gray if disabled
                            backgroundColor: { backgroundColor },
                            color: POLARIS_WHITE
                        }}
                        action={
                            <Stack direction="row">
                                <JobGoalsEditor
                                    arrayJobSectors={arrayJobSectors}
                                    disabled={disabled}
                                    roles={roles}
                                    editAndDeleteRoles={editAndDeleteRoles}
                                    editType={editType}
                                    sendChange={handleChange}
                                    cardData={cardData}></JobGoalsEditor>{' '}
                                {hasRole(editAndDeleteRoles, roles) && (
                                    <Icon
                                        data-testid="job_goals_viewer_delete_icon"
                                        component={DeleteIcon}
                                        disabled={disabled}
                                        sx={{ color: iconColor }}
                                        onClick={() => {
                                            if (disabled === false) setShowConfirmPrompt(true);
                                        }}
                                    />
                                )}
                            </Stack>
                        }></CardHeader>

                    <CardContent sx={{ padding: 1 }}>{getCardContent()}</CardContent>
                </Card>
            </div>
        </>
    );
};

JobGoalsViewer.propTypes = {
    cardData: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    sendDelete: PropTypes.func.isRequired,
    sendUpdate: PropTypes.func.isRequired,
    arrayJobSectors: PropTypes.array.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string),
    editAndDeleteRoles: PropTypes.arrayOf(PropTypes.string),
    editType: PropTypes.string
};

export default JobGoalsViewer;
