import { getNameFromId } from '../../../utils/directusFunctions';
import { NO_VALUE_SELECTED_ERROR } from '../../../utils/formValidation/commonErrorConstants';
import { getCharMinMessage } from '../../../utils/formValidation/errorMessageFunctions';

export const NOTE_MIN_CHAR = 5;
export const NOTE_CHAR_LIMIT = 750;

export const initialErrorState = {};

export const validate = (newEntry, claimEventStatuses) => {
    let newErrors = {};

    if (newEntry.notes.trim().length < NOTE_MIN_CHAR) {
        newErrors = {
            ...newErrors,
            notes: { error: true, message: getCharMinMessage('Notes', 5) }
        };
    }

    if (!newEntry.statusId) {
        newErrors = {
            ...newErrors,
            statusId: { error: true, message: NO_VALUE_SELECTED_ERROR }
        };
    }

    if (
        getNameFromId(claimEventStatuses, newEntry.statusId).toLowerCase() === 'fail' &&
        !newEntry.reasonId
    ) {
        newErrors = {
            ...newErrors,
            reasonId: { error: true, message: `Reason must be entered for Fail status.` }
        };
    }

    return newErrors;
};
