import React, { useEffect } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { loadParticipantStatusReasonDetails } from '../../store/directusService';
import { selectParticipantsSearchResults } from '../../store/participantSelectors';
import { searchEligibilityGroups } from '../../store/participantService';
import { countDays, reverseFormatDate } from '../../utils/dateFunctions';
import { getNameFromId } from '../../utils/directusFunctions';
import { downloadCsv } from '../../utils/downloadCSV';

import DataSetsResultsModal from './DataSetsResultsModal';

const fileName = 'Referral Created';
const fields =
    'Contract,PT_ID,Current_Status,Current_Reason,Eligibility_Group,Referral_Date,Participant_Created_Date,Days_Between_Referral_Date_and_Created_Date,PO_Number';

const CreateReferralCsv = ({ contract, isOpen, onClose }) => {
    const dispatch = useDispatch();

    // STORE STATE

    const participantStatusDetails = useSelector(
        (state) => state.entities.directusService.participantStatusDetails
    );
    const participantStatusReasons = useSelector(
        (state) => state.entities.directusService.participantStatusReasonDetails
    );
    const eligibilityGroupSearch = useSelector(
        (state) => state.entities.participantService.eligibilityGroupSearch
    );
    const participantsByContract = useSelector(selectParticipantsSearchResults);

    // USE EFFECTS
    useEffect(() => {
        participantStatusReasons?.length < 1 && dispatch(loadParticipantStatusReasonDetails());
    }, []);

    useEffect(() => {
        if (participantsByContract?.length < 1) return;

        const eligibilityGroupIds = [
            ...new Set(participantsByContract.map((el) => el.eligibilityGroupId).filter((el) => el))
        ];

        dispatch(searchEligibilityGroups({ ids: eligibilityGroupIds }));
    }, [participantsByContract]);

    useEffect(() => {
        if (
            participantsByContract?.length < 1 ||
            participantStatusDetails?.length < 1 ||
            participantStatusReasons?.length < 1
        )
            return;
        let values = [];
        if (eligibilityGroupSearch?.length < 1) return;
        participantsByContract.forEach((el) => {
            const participantStatusReason = getNameFromId(
                participantStatusReasons,
                el.statusReasonId
            );

            const eligibilityGroup = getNameFromId(eligibilityGroupSearch, el.eligibilityGroupId);
            const participantStatus = getNameFromId(participantStatusDetails, el.statusId);
            values = [
                ...values,
                [
                    contract.name,
                    el.ptCode,
                    participantStatus,
                    participantStatusReason,
                    eligibilityGroup,
                    reverseFormatDate(el.referralDate) || '',
                    reverseFormatDate(el.dateCreated) || '',
                    Math.floor(countDays(el.referralDate, el.dateCreated)),
                    el.poNumber || ''
                ]
            ];
        });

        const name = `${fileName}_${format(new Date(), `dd_MMM_yyyy_HH_mm`)}`;
        downloadCsv(fields, values, name);
        onClose();
    }, [
        eligibilityGroupSearch,
        participantsByContract,
        participantStatusDetails,
        participantStatusReasons
    ]);

    // RENDER

    return (
        <DataSetsResultsModal
            isOpen={isOpen}
            onCancel={onClose}
            resultRowsLength={participantsByContract?.length || 0}
        />
    );
};

export default CreateReferralCsv;

CreateReferralCsv.propTypes = {
    contract: PropTypes.object,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func
};
