import React from 'react';
import PropTypes from 'prop-types';

import RestoreIcon from '@mui/icons-material/Restore';

import IconError from '../IconError';

import common from './formElementStyles/commonFormElements.module.css';
import time from './formElementStyles/timeSelect.module.css';

const TimeSelect = ({
    id,
    value,
    onTimeChange,
    label = '',
    disabled = false,
    mandatory = false,
    min = '08:00',
    max = '18:00',
    step = '00:05',
    error = {},
    onSearchAuditItems,
    isAuditable = false
}) => {
    // LOCAL STATE

    // HELPER FNS
    return (
        <div className={time.timeWrapper}>
            <label htmlFor={id} className={common.formLabel}>
                <span>
                    {label} {mandatory && <sup>*</sup>}
                </span>
                {isAuditable && (
                    <div>
                        <RestoreIcon onClick={() => onSearchAuditItems(label)} />
                    </div>
                )}
            </label>
            <input
                type="time"
                id={id}
                data-testid={id}
                disabled={disabled}
                value={value}
                name="time"
                min={min}
                max={max}
                step={step}
                onChange={onTimeChange}
            />
            {error?.error && (
                <div className={time.errorWrapper}>
                    <IconError text={error} />
                </div>
            )}
        </div>
    );
};

TimeSelect.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    onTimeChange: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    mandatory: PropTypes.bool,
    min: PropTypes.string,
    max: PropTypes.string,
    step: PropTypes.string,
    error: PropTypes.object,
    onSearchAuditItems: PropTypes.func,
    isAuditable: PropTypes.bool
};

export default TimeSelect;
