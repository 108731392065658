import {
    getCharMinMessage,
    getReqMessage
} from '../../../../utils/formValidation/errorMessageFunctions';

export const initialErrorState = {};
export const REQUIREMENTS_MIN_CHAR = 15;

export const validate = (newEntry) => {
    let newErrors = {};

    if (!newEntry.jobSectorId) {
        newErrors = {
            ...newErrors,
            jobSectorId: { error: true, message: getReqMessage('Job Sector') }
        };
    }

    if (newEntry.teamIds.length < 1) {
        newErrors = {
            ...newErrors,
            teamIds: { error: true, message: getReqMessage('Team') }
        };
    }

    if (!newEntry.requirements) {
        newErrors = {
            ...newErrors,
            requirements: { error: true, message: getReqMessage('Pool Requirements') }
        };
    } else if (newEntry.requirements.length < REQUIREMENTS_MIN_CHAR) {
        newErrors = {
            ...newErrors,
            requirements: {
                error: true,
                message: getCharMinMessage('Pool Requirements', REQUIREMENTS_MIN_CHAR)
            }
        };
    }

    return newErrors;
};
