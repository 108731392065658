import React from 'react';
import PropTypes from 'prop-types';

import IconError from '../IconError';

import common from './formElementStyles/commonFormElements.module.css';
import textArea from './formElementStyles/textArea.module.css';

const TextAreaField = ({
    label = '',
    subLabel = '',
    id = '',
    disabled = false,
    placeholder = '',
    autoComplete = 'off',
    value,
    mandatory = false,
    rows = 5,
    maxLength = 300000,
    count = '',
    readOnly = false,
    customClass = '',
    customLabelClass = '',
    error = {},
    onChange
}) => {
    return (
        <div
            className={`${common.fieldWrapper} ${textArea.textAreaWrapper} ${common[customClass]}`}>
            <label htmlFor={id} className={`${common.formLabel} ${common[customLabelClass]}`}>
                <span>
                    {label} {mandatory && <sup>*</sup>}
                </span>
            </label>
            {subLabel && <div>{subLabel}</div>}
            <textarea
                id={id}
                role="textbox"
                data-testid={`textField${id}`}
                disabled={disabled}
                placeholder={placeholder}
                autoComplete={autoComplete}
                value={value}
                rows={rows}
                maxLength={maxLength}
                readOnly={readOnly}
                className={textArea.textarea}
                onChange={onChange}
            />
            {count && <div data-testid={`${id}-count`}>{count}</div>}
            {error?.error && (
                <div className={textArea.error}>
                    <IconError text={error} />
                </div>
            )}
        </div>
    );
};

TextAreaField.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    subLabel: PropTypes.string,
    mandatory: PropTypes.bool,
    type: PropTypes.string,
    id: PropTypes.string,
    customClass: PropTypes.string,
    customLabelClass: PropTypes.string,
    placeholder: PropTypes.string,
    autoComplete: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.object,
    onChange: PropTypes.func,
    rows: PropTypes.number,
    count: PropTypes.string,
    readOnly: PropTypes.bool,
    maxLength: PropTypes.number
};

export default TextAreaField;
