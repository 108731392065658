import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { loadCoursesByParticipantId } from '../../../../store/courseService';
import {
    loadDocumentTypeIdsAndNamesForContract,
    loadDocumentTypesForContractId,
    loadLocationsByServices
} from '../../../../store/directusService';
import {
    loadActionPlans,
    loadAssetRequests,
    loadFinancialRequests,
    loadParticipantBarriers,
    loadParticipantJobGoals,
    loadQuestionnaireAnswersByParticipant,
    loadQuestionnaireContractByContract,
    loadQuestionnaires
} from '../../../../store/participantService';
import {
    loadVacanciesByOwners,
    loadVacancySubmissionsByParticipantId
} from '../../../../store/recruitmentService';
import { loadUsersByServiceIds } from '../../../../store/userService';

import GenerateAppointmentPDF from './GenerateAppointmentPDF';
import PDFContentManagement from './PDFContentManagement';

const AppointmentPDFManagement = ({
    onSavePDF,
    onCancelPDF,
    fileName,
    appointmentType,
    participantsActiveAccordions,
    adviserName,
    currentParticipant,
    participantSignature,
    adviserSignature,
    currentAppointment,
    appointment,
    nextAppointment,
    signatureDateTime,
    exitFormTemplateId,
    doubtFormId,
    complianceActivityFormQuestionnaireId
}) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const [isLoaded, setIsLoaded] = useState(false);

    // STORE STATE
    const vacanciesForOwners = useSelector(
        (state) => state.entities.recruitmentService.vacanciesForOwners
    );

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadDocumentTypesForContractId(currentParticipant.contractId));
        dispatch(loadDocumentTypeIdsAndNamesForContract(currentParticipant.contractId));
        currentParticipant?.serviceId &&
            dispatch(loadUsersByServiceIds([currentParticipant.serviceId]));

        if (participantsActiveAccordions.actionPlan || participantsActiveAccordions.barriers) {
            dispatch(loadParticipantBarriers(currentParticipant.id));
        }
        //questionnaires
        if (participantsActiveAccordions.questionnaire) {
            dispatch(loadQuestionnaires());
            dispatch(loadQuestionnaireContractByContract(currentParticipant.contractId));
            dispatch(loadQuestionnaireAnswersByParticipant(currentParticipant.id));
        }
        //action plans
        if (participantsActiveAccordions.actionPlan) {
            dispatch(loadActionPlans(currentParticipant.id));
        }
        //courses
        if (participantsActiveAccordions.courses) {
            dispatch(loadLocationsByServices([currentParticipant.serviceId]));
            dispatch(loadCoursesByParticipantId(currentParticipant.id));
        }
        //job goals
        if (participantsActiveAccordions.jobReadiness)
            dispatch(loadParticipantJobGoals(currentParticipant.id));

        if (participantsActiveAccordions.request) {
            //financial requests
            dispatch(loadFinancialRequests(currentParticipant.id));
            //asset requests
            dispatch(loadAssetRequests(currentParticipant.id));
        }
        //vacancies
        if (participantsActiveAccordions.vacancy) {
            dispatch(loadVacancySubmissionsByParticipantId(currentParticipant.id));
            vacanciesForOwners?.length < 1 && dispatch(loadVacanciesByOwners());
        }
    }, []);

    // CALLBACKS
    const onIsLoaded = () => setIsLoaded(true);

    // RENDER
    return (
        <div>
            <GenerateAppointmentPDF
                onSavePDF={onSavePDF}
                onCancelPDF={onCancelPDF}
                fileName={fileName}
                isLoaded={isLoaded}
                appointmentType={appointmentType}
                mandatoryAppointment={appointment.mandatory}
                appointmentLocationId={appointment.locationId}
                exitFormTemplateId={exitFormTemplateId}
                doubtFormId={doubtFormId}
                complianceActivityFormQuestionnaireId={complianceActivityFormQuestionnaireId}
                attendanceStatusId={currentAppointment.attendanceId}
                currentAppointment={currentAppointment}>
                <PDFContentManagement
                    adviserName={adviserName}
                    currentParticipant={currentParticipant}
                    participantSignature={participantSignature}
                    adviserSignature={adviserSignature}
                    currentAppointment={currentAppointment}
                    appointment={appointment}
                    nextAppointment={nextAppointment}
                    signatureDateTime={signatureDateTime}
                    onIsLoaded={onIsLoaded}
                    participantsActiveAccordions={participantsActiveAccordions}
                />
            </GenerateAppointmentPDF>
        </div>
    );
};

AppointmentPDFManagement.propTypes = {
    adviserName: PropTypes.string,
    currentParticipant: PropTypes.object,
    adviserSignature: PropTypes.string,
    participantSignature: PropTypes.string,
    currentAppointment: PropTypes.object,
    signatureDateTime: PropTypes.string,
    appointment: PropTypes.object,
    nextAppointment: PropTypes.object,
    onSavePDF: PropTypes.func,
    onCancelPDF: PropTypes.func,
    fileName: PropTypes.string,
    appointmentType: PropTypes.object,
    participantsActiveAccordions: PropTypes.object,
    exitFormTemplateId: PropTypes.string,
    doubtFormId: PropTypes.string,
    complianceActivityFormQuestionnaireId: PropTypes.string
};

export default AppointmentPDFManagement;
