import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardContent } from '@mui/material';

import { searchCurrentCourses } from '../../../store/courseService';
import { selectBarrierDetails } from '../../../store/dataSelectors';
import { loadBarrierNameDetails } from '../../../store/directusService';
import { selectCurrentParticipantBarriers } from '../../../store/participantSelectors';
import { loadParticipantBarriers } from '../../../store/participantService';
import { POLARIS_WHITE } from '../../../themes/theme';
import { DEFAULT_TABLE_ROWS_PER_PAGE } from '../../../utils/formValidation/commonConstants';
import { stableSort } from '../../../utils/sortFunctions';
import FormHeader from '../../layout/FormHeader';
import ResultsTable from '../../table/ResultsTable';
import LoadingSpinner from '../../ui/LoadingSpinner';

import CurrentCourseTableRow from './CurrentCourseTableRow';

const headCells = [
    { id: 'id', numeric: false, label: 'ID' },
    { id: 'name', numeric: false, label: 'Name' },
    { id: 'startDate', numeric: false, label: 'Start Date' },
    { id: 'endDate', numeric: false, label: 'End Date' },
    { id: 'modules', numeric: false, label: 'Modules' }
];

const CurrentCourseManagement = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [openId, setOpenId] = useState('');
    const [rowMetaData, setRowMetaData] = useState({
        order: 'asc',
        orderBy: 'name',
        page: 0,
        rowsPerPage: DEFAULT_TABLE_ROWS_PER_PAGE
    });

    // STORE STATE
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const currentCourses = useSelector((state) => state.entities.courseService.currentCourses);
    const currentCoursesMetaData = useSelector(
        (state) => state.entities.courseService.currentCoursesMetaData
    );
    const loadingCurrentCourses = useSelector(
        (state) => state.entities.courseService.loadingCurrentCourses
    );
    const currentParticipantBarriers = useSelector(selectCurrentParticipantBarriers);
    const barrierNameDetails = useSelector(selectBarrierDetails);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );

    const activeCoursesSearchData = {
        active: true,
        serviceIds: [currentParticipant.serviceId],
        participantIds: [currentParticipant.id]
    };

    // USE EFFECTS
    useEffect(() => {
        if (
            !currentParticipantBarriers?.length ||
            currentParticipantBarriers?.participantId !== currentParticipant.id
        )
            dispatch(loadParticipantBarriers(currentParticipant.id));

        barrierNameDetails?.length < 1 && dispatch(loadBarrierNameDetails());

        loadCurrentCourses();
    }, []);

    useEffect(() => {
        if (
            successMessage.startsWith('Module details for course') &&
            successMessage.endsWith('have been updated')
        ) {
            loadCurrentCourses();
        }
    }, [successMessage]);

    // HELPER FNS
    const createRows = () =>
        stableSort(currentCourses, rowMetaData.orderBy, rowMetaData.order).map((el) => (
            <CurrentCourseTableRow
                key={el.id}
                row={el}
                toggleDropdown={(id) => setOpenId(id)}
                openId={openId}
            />
        ));

    const loadCurrentCourses = () => {
        dispatch(searchCurrentCourses(activeCoursesSearchData, 0));
    };

    // RENDER
    return (
        <Card sx={{ backgroundColor: POLARIS_WHITE }} data-testid="form_start">
            <CardContent>
                <div>
                    <FormHeader text={'Current Courses'}></FormHeader>
                    {currentCoursesMetaData.last && currentCourses.length < 1 ? (
                        <LoadingSpinner content="No current courses found" />
                    ) : (
                        <ResultsTable
                            defaultOrderBy={'name'}
                            defaultRowsPerPage={rowMetaData.rowsPerPage}
                            apiParams={activeCoursesSearchData}
                            headCells={headCells}
                            loadingValues={loadingCurrentCourses}
                            loadResults={searchCurrentCourses}
                            passRowMetaDataUp={setRowMetaData}
                            tableRows={createRows()}
                            totalResults={currentCoursesMetaData.totalElements}
                            metaData={currentCoursesMetaData}
                        />
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

export default CurrentCourseManagement;
