// This is a slice of store for data that is not from Directus
import { createSlice } from '@reduxjs/toolkit';

import { getCalendarURL } from '../api/commonHTTP';

import { apiCallBegan } from './api';

const slice = createSlice({
    name: 'dataService',
    initialState: {
        bankHolidayData: [],
        errorMessage: '',
        successMessage: ''
    },
    reducers: {
        bankHolidayDataLoaded: (store, action) => {
            store.bankHolidayData = action.payload;
        },

        errorMessageSet: (store, action) => {
            store.errorMessage = action.payload;
            store.successMessage = '';
        },

        errorOccurred: (store, action) => {
            store.errorMessage = action.payload;
            store.successMessage = '';
        },

        successMessageSet: (store, action) => {
            store.successMessage = action.payload;
            store.errorMessage = '';
        }
    }
});

export const loadBankHolidayData = () =>
    apiCallBegan({
        url: getCalendarURL() + `rest/bank-holiday`,
        onSuccess: bankHolidayDataLoaded.type,
        onError: errorOccurred.type
    });

export const setSuccessMessage = (message) => successMessageSet(message);
export const setErrorMessage = (message) => errorMessageSet(message);

const { bankHolidayDataLoaded, successMessageSet, errorMessageSet, errorOccurred } = slice.actions;

export default slice.reducer;
