import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import OnDeleteIcon from '../../../icons/OnDeleteIcon';
import {
    clearAppointmentAuditData,
    clearNewAppointment,
    deleteParticipantAppointment,
    searchAppointmentAuditData,
    setCurrentAppointment,
    setOpenAppointmentSchedulerPanel,
    updateParticipantAppointment
} from '../../../store/calendarService';
import { selectUsersForNamesById } from '../../../store/userSelectors';
import { loadUsersMinimalDetails } from '../../../store/userService';
import { isTimePast, reverseFormatDate } from '../../../utils/dateFunctions';
import { createHash } from '../../../utils/stringUtils';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import AuditAppointmentModal from '../../auditing/auditAppointments/AuditAppointmentModal';
import Button from '../../formElements/Button';
import DateSelect from '../../formElements/DateSelect';
import StaticField from '../../formElements/StaticField';
import Switch from '../../formElements/Switch';
import Panel from '../../ui/panel/Panel';
import AdviserSelect from '../components/AdviserSelect';
import AppointmentTypeSelect from '../components/AppointmentTypeSelect';
import LocationSelect from '../components/LocationSelect';
import MandatoryActivitiesSwitch from '../components/MandatoryActivitiesSwitch';
import StartAndEndTimesSelect from '../components/StartAndEndTimesSelect';
import StartAppointmentRow from '../components/StartAppointmentRow';
import StaticParticipant from '../components/StaticParticipant';
import { unresolvedStatuses } from '../utils/calendarUtils';
import { validate } from '../validation/validateAppointmentEdit';

import sidePanel from '../styles/schedulePanel.module.css';

const ParticipantAppointmentEdit = ({
    usersCurrentAppointment,
    currentLocation,
    currentParticipantEntry
}) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: '',
        typeId: '',
        userId: '',
        locationId: '',
        startTime: '08:00',
        endTime: '09:00',
        date: String(new Date().toISOString().split('T')[0]),
        serviceId: '',
        participantId: '',
        mandatory: false,
        type: '',
        service: '',
        location: '',
        participant: ''
    };

    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const editRestrictedRoles = [QUALITY, SUPERUSER];
    const deleteRestrictedRoles = [SUPERUSER];
    const [initialStateOnEntry, setInitialStateOnEntry] = useState({});
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [appointmentAdviser, setAppointmentAdviser] = useState({});
    const [isDisabledStartAppointmentButton, setIsDisabledStartAppointmentButton] = useState(false);
    const [isAppointmentCompleted, setIsAppointmentCompleted] = useState(false);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
    const [isAuditable, setIsAuditable] = useState(false);
    const [showAuditModal, setShowAuditModal] = useState(false);
    const [auditLabel, setAuditLabel] = useState('');
    const [modalHeadingLabel, setModalHeadingLabel] = useState('');
    const [hash, setHash] = useState('01');

    // STORE STATE
    const users = useSelector(selectUsersForNamesById);
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);

    const serviceDetailsById = useSelector(
        (state) => state.entities.directusService.serviceDetailsById
    );
    const appointmentTypesByContractId = useSelector(
        (state) => state.entities.directusService.appointmentTypeDetailsByContractId
    );
    const updatedCurrentAppointment = useSelector(
        (state) => state.entities.calendarService.usersCurrentAppointment
    );
    const openAppointmentSchedulerPanel = useSelector(
        (state) => state.entities.calendarService.openAppointmentSchedulerPanel
    );
    const errorMessage = useSelector((state) => state.entities.formsState.errorMessage);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS
    useEffect(() => {
        if ('location' in usersCurrentAppointment) {
            /* eslint-disable no-unused-vars */
            const { location, ...rest } = usersCurrentAppointment;
            setInitialStateOnEntry(rest);
        }
    }, []);

    useEffect(() => {
        if (!(usersCurrentAppointment.userId in users))
            dispatch(loadUsersMinimalDetails([usersCurrentAppointment.userId]));
        else setAppointmentAdviser(users[usersCurrentAppointment.userId]);
    }, [users]);

    useEffect(() => {
        if (
            !usersCurrentAppointment ||
            !currentParticipantEntry ||
            !(usersCurrentAppointment.serviceId in serviceDetailsById)
        )
            return;

        const currentParticipant = currentParticipantEntry
            ? `${currentParticipantEntry?.firstName} ${currentParticipantEntry?.lastName} ${currentParticipantEntry?.id}`
            : '';

        setNewEntry({
            ...usersCurrentAppointment,
            userId: usersCurrentAppointment.userId,
            startTime: usersCurrentAppointment.startTime?.slice(0, 5),
            endTime: usersCurrentAppointment.endTime?.slice(0, 5),
            date: usersCurrentAppointment.date?.split('T')[0] || '',
            mandatory: usersCurrentAppointment?.mandatory || false,
            service: serviceDetailsById[usersCurrentAppointment.serviceId]?.name || '',
            participant: currentParticipant,
            location: currentLocation,
            type: usersCurrentAppointment.type
        });

        const status = usersCurrentAppointment?.attendances.find(
            (el) => el.participantId === currentParticipantEntry?.id
        );
        setIsAppointmentCompleted(!unresolvedStatuses.includes(status));

        setIsDisabledStartAppointmentButton(!hasRole(acceptedRoles, roles));
    }, [serviceDetailsById]);

    useEffect(() => {
        setIsDisabledStartAppointmentButton(false);
    }, [updatedCurrentAppointment]);

    useEffect(() => {
        setIsSaveButtonDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    useEffect(() => {
        if (successMessage === `Appointment ${newEntry.type} has been updated (${hash})`) {
            setIsSaveButtonDisabled(false);
            !newEntry.participantId && onPanelClose();
        }
        if (successMessage.includes(`Appointment ${newEntry.type} has been deleted`)) {
            setIsSaveButtonDisabled(false);
            onPanelClose();
        }
    }, [successMessage]);

    useEffect(() => {
        if (errorMessage) {
            setIsSaveButtonDisabled(false);
        }
    }, [errorMessage]);

    // HELPER FNS
    const clearError = (key) => {
        if (key in errors && errors[key].error)
            setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    const addUserEmail = () => users[usersCurrentAppointment.userId]?.emailAddress || '';

    // EVENT HANDLERS
    const onPanelClose = () => {
        setNewEntry(initialState);
        dispatch(setCurrentAppointment({}));
        dispatch(setOpenAppointmentSchedulerPanel(false));
        dispatch(clearNewAppointment());
    };

    const onAuditModalClose = (e, reason) => {
        e.stopPropagation();
        if (reason === 'backdropClick') return;
        setShowAuditModal(false);
        setAuditLabel('');
        setModalHeadingLabel('');
        dispatch(clearAppointmentAuditData());
    };

    const onUpdate = (key, value) => {
        clearError(key);
        setIsDisabledStartAppointmentButton(true);
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onUpdateAppointmentType = (key, value, type) => {
        onUpdate(key, value);
        setNewEntry((prev) => ({ ...prev, type }));
    };

    const onUpdateTime = (key, value) => {
        clearError(key === 'endTime' ? 'startTime' : 'endTime');
        onUpdate(key, value);
    };

    const onDelete = () =>
        dispatch(deleteParticipantAppointment(newEntry.userId, newEntry.type, newEntry.id));

    const onSearchAuditItems = (label, modalHeadingLabel) => {
        dispatch(searchAppointmentAuditData(newEntry.id, label));
        setShowAuditModal(true);
        setAuditLabel(label);
        setModalHeadingLabel(modalHeadingLabel ?? label);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setIsSaveButtonDisabled(true);
        const {
            /* eslint-disable no-unused-vars */
            type,
            service,
            location,
            participant,
            ...payload
        } = newEntry;
        const newErrors = validate(payload, initialStateOnEntry);
        if (Object.values(newErrors).some((el) => el.error)) {
            setErrors(newErrors);
            return;
        }
        const hash = createHash();
        setHash(hash);
        dispatch(updateParticipantAppointment(newEntry.userId, newEntry.type, payload, hash));
        setInitialStateOnEntry(payload);
    };

    // RENDER
    return (
        <>
            <Panel width="500px" open={openAppointmentSchedulerPanel} onToggle={onPanelClose}>
                <div className={sidePanel.panelActions}>
                    <Switch
                        id={'auditDisplayFields'}
                        label="Show Audit Fields"
                        onOff={true}
                        customClass="auditSwitch"
                        checked={isAuditable}
                        onChange={(e) => setIsAuditable(e.target.checked)}
                    />
                    <OnDeleteIcon
                        onDelete={onDelete}
                        roles={roles}
                        acceptedRoles={deleteRestrictedRoles}
                        active={!!newEntry.id}
                    />
                </div>
                <div className={sidePanel.formWrapper}>
                    <form onSubmit={onSubmit} data-testid="participant_appointment_edit_form">
                        <StaticField content={newEntry.service} customClass="underlinedContent" />
                        {isAppointmentCompleted && !hasRole(editRestrictedRoles, roles) ? (
                            <>
                                <StaticField label="Adviser" content={addUserEmail()} />
                                <StaticField label="Appointment Type" content={newEntry.type} />
                                <StaticField
                                    label="Location"
                                    content={newEntry.location}
                                    isAuditable={isAuditable}
                                    onSearchAuditItems={onSearchAuditItems}
                                />
                                <StaticField
                                    label="Start Time"
                                    content={newEntry.startTime}
                                    isAuditable={isAuditable}
                                    onSearchAuditItems={() =>
                                        onSearchAuditItems('Start Date', 'Start Time')
                                    }
                                />
                                <StaticField
                                    label="End Time"
                                    content={newEntry.endTime}
                                    isAuditable={isAuditable}
                                    onSearchAuditItems={() =>
                                        onSearchAuditItems('End Date', 'End Time')
                                    }
                                />
                                <StaticField
                                    label="Date"
                                    content={reverseFormatDate(newEntry.date)}
                                    isAuditable={isAuditable}
                                    onSearchAuditItems={() =>
                                        onSearchAuditItems('Start Date', 'Date')
                                    }
                                />
                            </>
                        ) : (
                            <>
                                <AdviserSelect
                                    userId={newEntry.userId || ''}
                                    serviceId={newEntry.serviceId}
                                    initialAdviser={appointmentAdviser}
                                    onAdviserChange={(id) => onUpdate('userId', id)}
                                    disabled={!newEntry.service || !hasRole(acceptedRoles, roles)}
                                    mandatory={true}
                                    errors={errors}
                                />
                                {!hasRole(editRestrictedRoles, roles) &&
                                isTimePast(`${newEntry.date}T${newEntry.startTime}`) ? (
                                    <StaticField label="Appointment Type" content={newEntry.type} />
                                ) : (
                                    <AppointmentTypeSelect
                                        appointmentTypes={
                                            appointmentTypesByContractId[
                                                currentParticipantEntry?.contractId
                                            ] || []
                                        }
                                        appointmentTypeId={newEntry.typeId}
                                        onAppointmentTypeChange={onUpdateAppointmentType}
                                        errors={errors}
                                    />
                                )}

                                <LocationSelect
                                    locationId={newEntry.locationId}
                                    serviceId={newEntry.serviceId}
                                    onLocationChange={onUpdate}
                                    errors={errors}
                                    isAuditable={isAuditable}
                                    onSearchAuditItems={onSearchAuditItems}
                                />

                                <StartAndEndTimesSelect
                                    startTime={newEntry.startTime}
                                    endTime={newEntry.endTime}
                                    onTimeChange={onUpdateTime}
                                    errors={errors}
                                    isAuditable={isAuditable}
                                    onSearchAuditItems={onSearchAuditItems}
                                />
                                {!hasRole(editRestrictedRoles, roles) ? (
                                    <StaticField
                                        label="Date"
                                        content={reverseFormatDate(newEntry.date)}
                                        isAuditable={isAuditable}
                                        onSearchAuditItems={() =>
                                            onSearchAuditItems('Start Date', 'Date')
                                        }
                                    />
                                ) : (
                                    <DateSelect
                                        mandatory={true}
                                        value={newEntry.date}
                                        error={errors.date}
                                        onDateChange={(date) => onUpdate('date', date)}
                                        isAuditable={isAuditable}
                                        onSearchAuditItems={() =>
                                            onSearchAuditItems('Start Date', 'Date')
                                        }
                                    />
                                )}
                            </>
                        )}

                        <>
                            <StaticParticipant
                                participant={currentParticipantEntry}
                                onNav={onPanelClose}
                            />

                            <MandatoryActivitiesSwitch
                                isMandatory={newEntry.mandatory}
                                onSwitch={onUpdate}
                                disabled={true}
                            />
                        </>

                        <div className={sidePanel.updateButton}>
                            <Button
                                id="participantAppointmentEditButton"
                                content="Update"
                                disabled={
                                    (!hasRole(editRestrictedRoles, roles) &&
                                        isAppointmentCompleted) ||
                                    isSaveButtonDisabled
                                }
                                error={errors.button}
                                clearError={() => clearError('button')}
                                customErrorClass="rightSideButtonError"
                            />
                        </div>

                        <StartAppointmentRow
                            onPanelClose={onPanelClose}
                            disabled={isDisabledStartAppointmentButton}
                            appointment={newEntry}
                            participant={currentParticipantEntry}
                            isAuditable={isAuditable}
                            onSearchAuditItems={onSearchAuditItems}
                        />
                    </form>
                </div>
            </Panel>
            {showAuditModal && (
                <div id="audit">
                    <AuditAppointmentModal
                        isOpen={showAuditModal}
                        label={auditLabel}
                        modalHeadingLabel={modalHeadingLabel}
                        onClose={onAuditModalClose}
                    />
                </div>
            )}
        </>
    );
};

ParticipantAppointmentEdit.propTypes = {
    usersCurrentAppointment: PropTypes.object,
    currentLocation: PropTypes.string,
    currentParticipantEntry: PropTypes.object
};

export default ParticipantAppointmentEdit;
