import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { reverseFormatDate } from '../../../../../utils/dateFunctions';
import { getNameFromId } from '../../../../../utils/directusFunctions';

import classes from '../../../../../commonStyles/pdf.module.css';

const ActionPlanTable = ({ onLoaded }) => {
    // LOCAL STATE
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState('true');
    const [loadingBarriers, setLoadingBarriers] = useState('true');

    // STORE STATE
    const participantBarriers = useSelector(
        (state) => state.entities.participantService.currentParticipantBarriers
    );
    const loadingParticipantBarriers = useSelector(
        (state) => state.entities.participantService.loadingParticipantBarriers
    );
    const actionPlans = useSelector(
        (state) => state.entities.participantService.participantActionPlans
    );
    const loadingActionPlans = useSelector(
        (state) => state.entities.participantService.loadingActionPlans
    );
    const barrierNameDetails = useSelector(
        (state) => state.entities.directusService.barrierNameDetails
    );
    const actionPlanStatuses = useSelector(
        (state) => state.entities.directusService.actionPlanStatusDetails
    );

    // USE EFFECTS
    useEffect(() => {
        if (!loadingActionPlans && loading === 'true') setLoading('startLoading');
        if (!loadingActionPlans && loading === 'startLoading') setLoading('false');
    }, [loadingActionPlans]);

    useEffect(() => {
        if (!loadingParticipantBarriers && loadingBarriers === 'true')
            setLoadingBarriers('startLoading');
        if (!loadingParticipantBarriers && loadingBarriers === 'startLoading')
            setLoadingBarriers('false');
    }, [loadingParticipantBarriers]);

    useEffect(() => {
        if (actionPlans?.length < 1 && loading === 'false') {
            onLoaded('actionPlan');
            return;
        }
        if (barrierNameDetails?.length < 1 || actionPlanStatuses?.length < 1) return;
        if (participantBarriers?.length < 1 && loadingBarriers !== 'false') return;
        const data = actionPlans.map((el) => ({
            id: el.id,
            actionTitle: el?.actionTitle,
            barrierName:
                getNameFromId(
                    barrierNameDetails,
                    participantBarriers.find((entry) => entry.id === el.barrierId)?.barrierId
                ) || '',
            startDate: el?.actionStartDate ? reverseFormatDate(el.actionStartDate) : ' - ',
            dueDate: el?.actionDueDate ? reverseFormatDate(el.actionDueDate) : ' - ',
            outcomeDate: el?.actionOutcomeDate ? reverseFormatDate(el.actionOutcomeDate) : ' - ',
            outcome: getNameFromId(actionPlanStatuses, el.outcomeId) || ' - ',
            action: el?.action,
            comments: el?.comments
        }));
        setRows(data);
    }, [actionPlans, barrierNameDetails, actionPlanStatuses, loading]);

    useEffect(() => {
        rows.length > 0 && onLoaded('actionPlan');
    }, [rows]);

    // RENDER
    return (
        <div>
            <h3>Action Plan</h3>
            {rows.length < 1 ? (
                'No action plans'
            ) : (
                <div className={classes.pdfTable}>
                    <div className={classes.pdfTableHeaders}>
                        <span>Action Name</span>
                        <span>Barrier</span>
                        <span>Start Date</span>
                        <span>Due Date</span>
                        <span>Outcome Date</span>
                        <span>Outcome</span>
                    </div>

                    <div className={classes.pdfTableRows}>
                        {rows.map((el, i) => (
                            <div
                                key={el.id}
                                id={`${el.id}--${i}`}
                                className={`${i % 2 === 0 ? classes.rowPlain : classes.rowFilled} ${
                                    classes.borderedSet
                                }`}>
                                <div className={classes.pdfTableRow}>
                                    <span>{el.actionTitle}</span>
                                    <span
                                        className={`${!el.barrierName ? classes.alignColInMed : ''}`}>
                                        {el.barrierName || ' - '}
                                    </span>
                                    <span>{el.startDate}</span>
                                    <span
                                        className={`${
                                            el.dueDate === ' - ' ? classes.alignCenter : ''
                                        }`}>
                                        {el.dueDate}
                                    </span>
                                    <span
                                        className={`${
                                            el.outcomeDate === ' - ' ? classes.alignCenter : ''
                                        }`}>
                                        {el.outcomeDate}
                                    </span>
                                    <span
                                        className={`${
                                            el.outcome === ' - ' ? classes.alignCenter : ''
                                        }`}>
                                        {el.outcome}
                                    </span>
                                </div>
                                <div>
                                    <div className={classes.textBoxSet}>
                                        <div className={classes.textBoxHeader}>Action</div>
                                        <div className={classes.textBox}>{el?.action}</div>
                                    </div>
                                    <div className={classes.textBoxSet}>
                                        <div className={classes.textBoxHeader}>Comments</div>
                                        {el.comments ? (
                                            <div className={classes.textBox}>{el?.comments}</div>
                                        ) : (
                                            ' n/a '
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

ActionPlanTable.propTypes = {
    onLoaded: PropTypes.func
};

export default ActionPlanTable;
