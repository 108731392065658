import { createSlice } from '@reduxjs/toolkit';

import { getCalendarURL } from '../api/commonHTTP';

import { apiCallBegan, apiVersion } from './api';

const slice = createSlice({
    name: 'calendarService',
    initialState: {
        appointmentAuditData: [],
        appointmentAttendanceDetails: [],
        appointmentAttendanceDetailsMetaData: {},
        mostRecentAppointment: {},
        appointmentsAndSlotsByUser: {},
        appointmentsBaseSearch: [],
        participantAppointments: [],
        participantAppointmentsAndSlots: [],
        newAppointment: {},
        usersCurrentAppointment: {},
        currentUserId: '',
        loadingAppointmentAuditData: false,
        loadedAppointmentAuditData: false,
        loadingAppointmentAttendanceDetails: false,
        loadingAppointmentsBaseSearch: false,
        loadingParticipantAppointments: false,
        loadingParticipantAppointmentsAndSlots: false,
        loadingUsersAppointmentsAndSlots: false,
        loadingUserAppointments: false,
        loadingUserAppointmentSlots: false,
        openAppointmentSchedulerPanel: false,
        successMessage: '',
        errorMessage: '',
        version: {}
    },
    reducers: {
        appointmentAuditDataLoading: (state) => {
            state.loadingAppointmentAuditData = true;
            state.appointmentAuditData = [];
        },

        appointmentAuditDataLoadingFailure: (state, action) => {
            state.loadingAppointmentAuditData = false;
            state.appointmentAuditData = [];
            state.errorMessage = action.payload;
        },

        appointmentAuditDataLoaded: (state, action) => {
            state.loadingAppointmentAuditData = false;
            state.loadedAppointmentAuditData = true;
            state.appointmentAuditData = action.payload;
        },

        clearAppointmentAuditData: (state) => {
            state.appointmentAuditData = [];
        },

        appointmentAttendanceDetailsLoading: (state) => {
            state.loadingAppointmentAttendanceDetails = true;
            state.appointmentAttendanceDetails = [];
        },

        appointmentAttendanceDetailsLoaded: (state, action) => {
            state.loadingAppointmentAttendanceDetails = false;
            state.appointmentAttendanceDetails = action.payload;
        },

        mostRecentAppointmentAttendanceDetailsLoaded: (state, action) => {
            state.mostRecentAppointment = action.payload;
        },

        appointmentAttendanceDetailUpdated: (state, action) => {
            state.appointmentAttendanceDetails = state.appointmentAttendanceDetails.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },

        clearAppointmentAttendanceDetails: (state) => {
            state.appointmentAttendanceDetails = [];
        },

        clearNewAppointment: (state) => {
            state.newAppointment = {};
        },

        appointmentsBaseSearchLoading: (state) => {
            state.loadingAppointmentsBaseSearch = true;
        },

        appointmentsBaseSearchLoaded: (state, action) => {
            state.loadingAppointmentsBaseSearch = false;
            state.appointmentsBaseSearch = action.payload;
        },

        participantAppointmentsLoading: (state) => {
            state.loadingParticipantAppointments = true;
            state.participantAppointments = [];
        },

        participantAppointmentsLoadingFailure: (state, action) => {
            state.loadingParticipantAppointments = false;
            state.errorMessage = action.payload;
        },

        participantAppointmentsLoaded: (state, action) => {
            state.loadingParticipantAppointments = false;
            state.participantAppointments = action.payload.filter((el) => !el.inactive);
        },

        participantAppointmentsAndSlotsLoading: (state) => {
            state.loadingParticipantAppointmentsAndSlots = true;
            state.participantAppointmentsAndSlots = [];
        },

        participantAppointmentsAndSlotsLoadingFailure: (state, action) => {
            state.loadingParticipantAppointmentsAndSlots = false;
            state.errorMessage = action.payload;
        },

        participantAppointmentsAndSlotsLoaded: (state, action) => {
            state.loadingParticipantAppointmentsAndSlots = false;
            state.participantAppointmentsAndSlots = action.payload.filter((el) => !el.inactive);
        },

        userAppointmentsAndSlotsLoading: (state, action) => {
            state.loadingUsersAppointmentsAndSlots = true;
            state.currentUserId = action.payload.userId;
        },

        userAppointmentsAndSlotsLoadingFailure: (state, action) => {
            state.loadingUsersAppointmentsAndSlots = false;
            state.errorMessage = action.payload;
        },

        userAppointmentsAndSlotsLoaded: (state, action) => {
            state.loadingUsersAppointmentsAndSlots = false;
            const userId = action.payload.length > 0 ? action.payload[0]?.userId : '';
            if (!userId) return;
            state.appointmentsAndSlotsByUser[userId] = action.payload.filter((el) => !el.inactive);
        },

        appointmentCreating: (state) => {
            state.newAppointment = {};
        },

        appointmentCreated: (state, action) => {
            state.newAppointment = action.payload;
            state.appointmentsAndSlotsByUser = {
                ...state.appointmentsAndSlotsByUser,
                [action.payload?.userId]:
                    state.appointmentsAndSlotsByUser[action.payload.userId] &&
                    Array.isArray(state.appointmentsAndSlotsByUser[action.payload.userId])
                        ? [
                              ...state.appointmentsAndSlotsByUser[action.payload.userId],
                              action.payload
                          ]
                        : [action.payload]
            };
            state.participantAppointments = [...state.participantAppointments, action.payload];
            state.participantAppointmentsAndSlots = [
                ...state.participantAppointments,
                action.payload
            ];
        },

        appointmentUpdated: (state, action) => {
            const userId = action.payload?.userId || '';
            state.appointmentsAndSlotsByUser = {
                ...state.appointmentsAndSlotsByUser,
                [userId]:
                    state.appointmentsAndSlotsByUser[userId] &&
                    Array.isArray(state.appointmentsAndSlotsByUser[userId])
                        ? state.appointmentsAndSlotsByUser[userId].map((el) =>
                              el.id === action.payload.id ? action.payload : el
                          )
                        : [action.payload]
            };
            state.participantAppointmentsAndSlots = state.participantAppointmentsAndSlots.map(
                (el) => (el.id === action.payload.id ? action.payload : el)
            );
            state.participantAppointments = state.participantAppointments.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
            state.usersCurrentAppointment = action.payload;
        },

        appointmentDeleted: (state, action) => {
            state.appointmentsAndSlotsByUser = {
                ...state.appointmentsAndSlotsByUser,
                [action.payload.userId]: state.appointmentsAndSlotsByUser[
                    action.payload.userId
                ].filter((el) => el.id !== action.payload.id)
            };
            state.participantAppointments = state.participantAppointments.filter(
                (el) => el.id !== action.payload.id
            );
            state.participantAppointmentsAndSlots = state.participantAppointmentsAndSlots.filter(
                (el) => el.id !== action.payload.id
            );
        },

        appointmentLoadingFailure: (state, action) => {
            state.errorMessage = action.payload;
        },

        setCurrentAppointment: (state, action) => {
            state.usersCurrentAppointment = action.payload;
        },

        setOpenAppointmentSchedulerPanel: (state, action) => {
            state.openAppointmentSchedulerPanel = action.payload;
        },

        successMessageSet: (state, action) => {
            state.successMessage = action.payload;
            state.errorMessage = '';
        },

        errorOccurred: (state, action) => {
            state.errorMessage = action.payload;
        },

        versionLoaded: (state, action) => {
            state.version = action.payload;
        }
    }
});

export const searchAppointmentAuditData = (appointmentId, field) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment/audit/${appointmentId}/${field}`,
        onStart: appointmentAuditDataLoading.type,
        onSuccess: appointmentAuditDataLoaded.type,
        successMessage: `Appointment entry has been searched`,
        onError: appointmentAuditDataLoadingFailure.type
    });

export const searchAppointmentSlotAuditData = (appointmentId, field) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment-slot/audit/${appointmentId}/${field}`,
        onStart: appointmentAuditDataLoading.type,
        onSuccess: appointmentAuditDataLoaded.type,
        successMessage: `Appointment entry has been searched`,
        onError: appointmentAuditDataLoadingFailure.type
    });

export const createAppointment = (appointmentType, data) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment`,
        data,
        method: 'post',
        onStart: appointmentCreating.type,
        onSuccess: appointmentCreated.type,
        successMessage: `Appointment ${appointmentType} has been created`,
        onError: appointmentLoadingFailure.type
    });

export const createAppointmentSlot = (slotType, data) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment-slot`,
        data,
        method: 'post',
        onStart: appointmentCreating.type,
        onSuccess: appointmentCreated.type,
        successMessage: `Appointment slot ${slotType} has been created`,
        onError: appointmentLoadingFailure.type
    });

export const searchUserAppointmentsAndSlots = (userId) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/base-appointment/search`,
        method: 'post',
        data: { userIds: [userId] },
        onStart: userAppointmentsAndSlotsLoading.type,
        onSuccess: userAppointmentsAndSlotsLoaded.type,
        successMessage: `Appointments and slots loaded`,
        onError: userAppointmentsAndSlotsLoadingFailure.type
    });

export const searchParticipantAppointments = (participantId) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment/search`,
        method: 'post',
        data: { participantIds: [participantId] },
        onStart: participantAppointmentsLoading.type,
        onSuccess: participantAppointmentsLoaded.type,
        onError: participantAppointmentsLoadingFailure.type
    });

export const searchParticipantAppointmentsAndSlots = (participantId) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/base-appointment/search`,
        method: 'post',
        data: { participantIds: [participantId] },
        onStart: participantAppointmentsAndSlotsLoading.type,
        onSuccess: participantAppointmentsAndSlotsLoaded.type,
        onError: participantAppointmentsAndSlotsLoadingFailure.type
    });

export const searchBaseAppointments = (data) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/base-appointment/search`,
        method: 'post',
        data,
        onStart: appointmentsBaseSearchLoading.type,
        onSuccess: appointmentsBaseSearchLoaded.type,
        onError: errorOccurred.type
    });

export const updateAppointment = (userId, appointmentType, data, hash = '01') =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment/${data.id}`,
        data,
        onStartData: { id: data.id, userId },
        method: 'put',
        onSuccess: appointmentUpdated.type,
        successMessage: `Appointment ${appointmentType} has been updated (${hash})`,
        onError: appointmentLoadingFailure.type
    });

export const updateAppointmentSlot = (userId, slotType, data, hash = '01') =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment-slot/${data.id}`,
        data,
        method: 'put',
        onStartData: { id: data.id, userId },
        onSuccess: appointmentUpdated.type,
        successMessage: `Appointment slot ${slotType} has been updated (${hash})`,
        onError: appointmentLoadingFailure.type
    });

export const updateParticipantAppointment = (userId, appointmentType, data, hash) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment/${data.id}`,
        data,
        onStartData: { id: data.id, userId },
        method: 'put',
        onSuccess: appointmentUpdated.type,
        successMessage: `Appointment ${appointmentType} has been updated (${hash})`,
        onError: appointmentLoadingFailure.type
    });

export const deleteAppointment = (userId, name, id) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment/${id}`,
        method: 'delete',
        data: { userId },
        onSuccess: appointmentDeleted.type,
        successMessage: `Appointment ${name} has been deleted`,
        onError: appointmentLoadingFailure.type
    });

export const deleteAppointmentSlot = (userId, name, id) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment-slot/${id}`,
        method: 'delete',
        data: { userId },
        onSuccess: appointmentDeleted.type,
        successMessage: `Appointment slot ${name} has been deleted`,
        onError: appointmentLoadingFailure.type
    });

export const deleteParticipantAppointment = (userId, name, id) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/appointment/${id}`,
        method: 'delete',
        data: { userId },
        onSuccess: appointmentDeleted.type,
        successMessage: `Appointment ${name} has been deleted`,
        onError: appointmentLoadingFailure.type
    });

// Attendance
export const loadAppointmentAttendanceDetails = (participantId) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/attendance/participant/${participantId}`,
        onStart: appointmentAttendanceDetailsLoading.type,
        onSuccess: appointmentAttendanceDetailsLoaded.type,
        successMessage: `Appointments loaded`,
        onError: errorOccurred.type
    });

export const searchAppointmentAttendanceDetails = (data) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/attendance/search`,
        method: 'post',
        data,
        onStart: appointmentAttendanceDetailsLoading.type,
        onSuccess: appointmentAttendanceDetailsLoaded.type,
        successMessage: `Appointments loaded`,
        onError: errorOccurred.type
    });

export const loadMostRecentAppointmentAttendanceDetails = (participantId) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/attendance/participant/${participantId}/recent`,
        onSuccess: mostRecentAppointmentAttendanceDetailsLoaded.type,
        successMessage: `Last appointment loaded`,
        onError: errorOccurred.type
    });

export const updateAppointmentAttendanceDetail = (data, id) =>
    apiCallBegan({
        url: getCalendarURL() + `rest/attendance/${id}`,
        method: 'put',
        data,
        onSuccess: appointmentAttendanceDetailUpdated.type,
        successMessage: `Appointment updated`,
        onError: appointmentLoadingFailure.type
    });

export const loadCalendarServiceVersion = () =>
    apiVersion({
        url: getCalendarURL() + 'rest/version',
        method: 'get',
        onSuccess: versionLoaded.type,
        onError: errorOccurred.type
    });

export const {
    clearAppointmentAttendanceDetails,
    clearAppointmentAuditData,
    clearNewAppointment,
    setCurrentAppointment,
    setOpenAppointmentSchedulerPanel
} = slice.actions;

export default slice.reducer;
const {
    errorOccurred,
    appointmentAuditDataLoading,
    appointmentAuditDataLoaded,
    appointmentAuditDataLoadingFailure,
    appointmentAttendanceDetailsLoading,
    appointmentAttendanceDetailsLoaded,
    mostRecentAppointmentAttendanceDetailsLoaded,
    appointmentAttendanceDetailUpdated,
    appointmentCreating,
    appointmentsBaseSearchLoading,
    appointmentsBaseSearchLoaded,
    participantAppointmentsLoading,
    participantAppointmentsLoadingFailure,
    participantAppointmentsLoaded,
    participantAppointmentsAndSlotsLoading,
    participantAppointmentsAndSlotsLoadingFailure,
    participantAppointmentsAndSlotsLoaded,
    appointmentCreated,
    appointmentUpdated,
    appointmentDeleted,
    appointmentLoadingFailure,
    userAppointmentsAndSlotsLoading,
    userAppointmentsAndSlotsLoaded,
    userAppointmentsAndSlotsLoadingFailure,
    versionLoaded
} = slice.actions;
