import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import PersonIcon from '@mui/icons-material/Person';
import ScheduleIcon from '@mui/icons-material/Schedule';

import { selectUserRoles } from '../../../../store/userSelectors';
import { isDatePast, reverseFormatDate } from '../../../../utils/dateFunctions';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';

import card from '../../../../commonStyles/card.module.css';

const VacancyCard = ({
    entry,
    accordionPanelFrom,
    jobsOfferedForVacancies,
    isDisabled = false
}) => {
    const navigate = useNavigate();

    // LOCAL STATE
    const {
        title,
        description,
        numberOfPositions,
        hiringManagerId,
        anticipatedStartDate,
        payRateFrom,
        payRateTo,
        expectedWeeklyHours,
        vacancyClosingDate,
        inactive
    } = entry;
    const acceptedRoles = [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [isLinkDisabled, setIsLinkDisabled] = useState(false);

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const currentBusiness = useSelector(
        (state) => state.entities.recruitmentService.currentBusiness
    );

    // USE EFFECTS
    useEffect(() => {
        setIsLinkDisabled(
            inactive ||
                isDatePast(vacancyClosingDate.substring(0, 10)) ||
                !hasRole(acceptedRoles, roles)
        );
    }, []);

    // HELPER FNS
    const getHiringManager = () =>
        currentBusiness.contactCards?.map((el) =>
            el.id === hiringManagerId ? `${el.firstName} ${el.lastName} ( ${el.officeNumber}` : '-'
        );

    // EVENT HANDLERS
    const onManageVacancy = () => {
        if (isLinkDisabled) return;
        navigate('/vacancy_management', {
            state: {
                accordionPanel: 'vacancyDetails',
                accordionPanelFrom: accordionPanelFrom,
                vacancy: entry,
                jobsOfferedForVacancies: jobsOfferedForVacancies
            }
        });
    };

    return (
        <div className={card.card}>
            <div
                className={`${
                    title?.length > 50 ? card.bannerDoubleRow : card.banner
                } ${isDisabled ? card.disabledBanner : ''}`}>
                <h6>{title}</h6>
            </div>

            <div className={card.content}>
                <div>
                    <div className={card.subHeader}>Vacancy Notes:</div>
                    <div className={card.description}>
                        {description?.length > 100
                            ? `${description.substring(0, 97)}...`
                            : description}
                    </div>

                    <div className={card.section}>
                        <span className={card.subHeader}>Positions:</span>
                        <span>{` ${numberOfPositions ? numberOfPositions : ' - '}`}</span>
                    </div>
                </div>
                <div className={card.rows}>
                    <div className={card.row}>
                        <PersonIcon />
                        {`Contact: ${getHiringManager()}`}
                    </div>
                    <div className={card.row}>
                        <CalendarMonthIcon />
                        {`Start Date: ${
                            anticipatedStartDate ? reverseFormatDate(anticipatedStartDate) : ' - '
                        }`}
                    </div>
                    <div className={card.row}>
                        <CurrencyPoundIcon />
                        {`Pay: £${payRateFrom} - £${payRateTo}`}
                    </div>
                    <div className={card.row}>
                        <ScheduleIcon />
                        {`Hours: ${
                            expectedWeeklyHours ? `${expectedWeeklyHours} Per Week` : ' - '
                        }`}
                    </div>
                    <div className={card.row}>
                        <CalendarMonthIcon />
                        {`Closing Date: ${
                            vacancyClosingDate ? reverseFormatDate(vacancyClosingDate) : ' - '
                        }`}
                    </div>
                </div>

                <p
                    className={`${isLinkDisabled ? `${card.linkDisabled}` : `${card.link}`}`}
                    onClick={onManageVacancy}>
                    Manage Vacancy
                </p>
            </div>
        </div>
    );
};

export default VacancyCard;

VacancyCard.propTypes = {
    entry: PropTypes.object,
    accordionPanelFrom: PropTypes.string,
    jobsOfferedForVacancies: PropTypes.array,
    isDisabled: PropTypes.bool
};
