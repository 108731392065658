import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    loadActionPlanStatusDetails,
    loadBarrierNameDetails
} from '../../../store/directusService';
import {
    loadActionPlans,
    loadContractConstants,
    loadParticipantBarriers,
    loadParticipantEmployability
} from '../../../store/participantService';
import { getNameFromId } from '../../../utils/directusFunctions';
import { stableSort } from '../../../utils/sortFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import ResultsTable from '../../table/ResultsTable';
import LoadingSpinner from '../../ui/LoadingSpinner';

import ActionPlanTableRow from './ActionPlanTableRow';
import CreateActionPlan from './CreateActionPlan';
import EditActionPlan from './EditActionPlan';

import app from '../../../app.module.css';
import classes from './actionPlan.module.css';

const headCells = [
    { id: 'actionTitle', numeric: false, label: 'Action Name', sortable: true },
    { id: 'barrierName', numeric: false, label: 'Barrier', sortable: true },
    { id: 'actionStartDate', numeric: false, label: 'Start Date', sortable: true },
    { id: 'actionDueDate', numeric: true, label: 'Review Date', sortable: true },
    { id: 'actionOutcomeDate', numeric: true, label: 'Outcome Date', sortable: true },
    { id: 'outcomeName', numeric: true, label: 'Outcome', sortable: true }
];

const initialRowMetaData = {
    order: 'desc',
    orderBy: 'actionStartDate',
    page: 0,
    rowsPerPage: 100
};

const ActionPlan = () => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [openEditor, setOpenEditor] = useState(false);
    const [formType, setFormType] = useState('');
    const [rowMetaData, setRowMetaData] = useState(initialRowMetaData);

    // STORE STATE
    const barrierNameDetails = useSelector(
        (state) => state.entities.directusService.barrierNameDetails
    );
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const participantActionPlans = useSelector(
        (state) => state.entities.participantService.participantActionPlans
    );
    const currentParticipantBarriers = useSelector(
        (state) => state.entities.participantService.currentParticipantBarriers
    );
    const currentParticipantEmployability = useSelector(
        (state) => state.entities.participantService.currentParticipantEmployability
    );
    const actionPlanStatuses = useSelector(
        (state) => state.entities.directusService.actionPlanStatusDetails
    );
    const contractConstants = useSelector(
        (state) => state.entities.participantService.contractConstants
    );

    // USE EFFECTS
    useEffect(() => {
        setRows([]);
        barrierNameDetails.length < 1 && dispatch(loadBarrierNameDetails());
        actionPlanStatuses?.length < 1 && dispatch(loadActionPlanStatusDetails());
    }, []);

    useEffect(() => {
        if (!('id' in currentParticipant)) return;
        dispatch(loadActionPlans(currentParticipant.id));
        if (contractConstants?.contractId !== currentParticipant.contractId)
            dispatch(loadContractConstants(currentParticipant.contractId));
        if (
            !currentParticipantEmployability?.participantId ||
            currentParticipantEmployability?.participantId !== currentParticipant.id
        ) {
            dispatch(loadParticipantEmployability(currentParticipant.id));
        }
        if (
            !currentParticipantBarriers?.length ||
            currentParticipantBarriers?.participantId !== currentParticipant.id
        )
            dispatch(loadParticipantBarriers(currentParticipant.id));
    }, [currentParticipant]);

    useEffect(() => {
        if (actionPlanStatuses?.length < 1 || barrierNameDetails?.length < 1) return;
        const rows = participantActionPlans?.map((el) => ({
            ...el,
            actionStartDate: !el.actionStartDate ? '1960-01-01' : el.actionStartDate,
            actionDueDate: !el.actionDueDate ? '1960-01-01' : el.actionDueDate,
            actionOutcomeDate: !el.actionOutcomeDate ? '1960-01-01' : el.actionOutcomeDate,
            outcomeName: getNameFromId(actionPlanStatuses, el.outcomeId) || 'zz',
            barrierName:
                getNameFromId(
                    barrierNameDetails,
                    currentParticipantBarriers?.find((entry) => entry.id === el.barrierId)
                        ?.barrierId
                ) || 'zz'
        }));
        setRows(rows);
    }, [
        participantActionPlans,
        actionPlanStatuses,
        currentParticipantBarriers,
        barrierNameDetails
    ]);

    // HELPER fNS
    const createRows = () =>
        stableSort(rows, rowMetaData.orderBy, rowMetaData.order).map((el) => (
            <ActionPlanTableRow
                key={el.id}
                row={el}
                roles={roles}
                acceptedRoles={acceptedRoles}
                onEdit={onEditAction}
            />
        ));

    // EVENT HANDLERS
    const onCreateAction = () => {
        setFormType('create');
        setOpenEditor(true);
    };

    const onEditAction = (row) => {
        setSelectedRow(row);
        setFormType('edit');
        setOpenEditor(true);
    };

    const onEditorClose = () => setOpenEditor(false);

    // RENDER
    return (
        <div className={classes.actionPlanWrapper}>
            <h2 className={app.sectionHeading}>Your action details</h2>
            {rows.length < 1 ? (
                <LoadingSpinner content="No actions found" />
            ) : (
                <ResultsTable
                    defaultOrderBy={rowMetaData.orderBy}
                    sortOrder={rowMetaData.order}
                    headCells={headCells}
                    loadResults={loadActionPlans}
                    passRowMetaDataUp={setRowMetaData}
                    apiParams={currentParticipant.id}
                    tableRows={createRows()}
                    totalResults={rows.length}
                />
            )}

            <div>
                {!openEditor && (
                    <Button
                        id="actionPlanCreateButton"
                        disabled={!hasRole(acceptedRoles, roles)}
                        content="Add an Action"
                        onClick={onCreateAction}
                        icon={true}
                    />
                )}
                {openEditor && formType === 'create' && (
                    <CreateActionPlan
                        onClose={onEditorClose}
                        roles={roles}
                        acceptedRoles={acceptedRoles}
                    />
                )}
                {openEditor && formType === 'edit' && (
                    <EditActionPlan
                        onClose={onEditorClose}
                        roles={roles}
                        acceptedRoles={acceptedRoles}
                        row={selectedRow}
                    />
                )}
            </div>
        </div>
    );
};

export default ActionPlan;
