export const ADDRESS_LINE_1_MAX_LENGTH = 100;
export const ADDRESS_LINE_2_MAX_LENGTH = 100;
export const ADDRESS_LINE_3_MAX_LENGTH = 100;
export const ADDRESS_LINE_4_MAX_LENGTH = 100;
export const CITY_MAX_LENGTH = 30;
export const COUNTY_MAX_LENGTH = 30;
export const CREATE = 'Create';
export const DEFAULT_TABLE_ROW_OPTIONS = [5, 10, 25, 50, 100];
export const DEFAULT_TABLE_ROWS_PER_PAGE = 10;
export const INVALID_POSTCODE = 'Invalid postcode';
export const INVALID_PHONE_NUMBER = 'Invalid phone number';
export const INVALID_EMAIL_ADDRESS = 'Invalid email address';
export const MAX_TABLE_ROWS_PER_PAGE = 100;
export const MIN_EMAIL = 7;
export const MAX_EMAIL = 254;
export const MIN_PHONE = 11;
export const MAX_PHONE = 15;
export const MAX_POSTCODE = 10;
export const NO_SELECTION = '  no selection';
export const UPDATE = 'Update';
