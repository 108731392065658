import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill-new';
import { useDispatch, useSelector } from 'react-redux';

import {
    clearCurrentTemplate,
    loadCommunicationTemplate,
    updateEmailCommunicationTemplate,
    updateLetterCommunicationTemplate,
    updateSMSCommunicationTemplate
} from '../../store/communicationService';
import { selectContracts, selectServices } from '../../store/dataSelectors';
import { clearServiceDetails, loadServiceDetailsByContract } from '../../store/directusService';
import { POLARIS_DARKER_GREY, POLARIS_ORANGE } from '../../themes/theme';
import { hasRole, LOCAL_ADMIN, SUPERUSER } from '../../utils/userRoles';
import IconError from '../IconError';
import FormActions from '../ui/formActions/FormActions';
import MultiSelect from '../ui/pickers/MultiSelect';
import PolarisSwitchSet from '../ui/pickers/PolarisSwitchSet';

import { initialErrorState, validate } from './validateCommunicationTemplates';

import 'react-quill-new/dist/quill.snow.css';
import '../../commonStyles/reactQuillRTE.css';
import form from '../../commonStyles/formStyles.module.css';

const EditTemplate = ({ onClose, roles, row }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const SMS_CHARACTERS_PER_SMS = 140;
    const SMS_CHARACTER_LIMIT = 560;
    const initialState = {
        id: null,
        typeId: '',
        inactive: false,
        name: '',
        subject: '',
        details: '',
        serviceIds: [],
        contractIds: []
    };
    const acceptedRoles = [SUPERUSER, LOCAL_ADMIN];
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const [type, setType] = useState('');
    const [smsColor, setSmsColor] = useState(POLARIS_DARKER_GREY);
    const [arrayServices, setArrayServices] = useState([]);
    const [selectedContracts, setSelectedContracts] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);

    // STORE STATE
    const contracts = useSelector(selectContracts);
    const services = useSelector(selectServices);
    const currentTemplate = useSelector(
        (state) => state.entities.communicationService.communicationTemplate
    );

    // HELPER FNS

    const clearForm = () => {
        setNewEntry(initialState);
        setArrayServices([]);
        dispatch(clearServiceDetails());
        dispatch(clearCurrentTemplate());
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // USE EFFECTS

    useEffect(() => {
        dispatch(loadCommunicationTemplate(row.id));
        const formattedRow = {
            ...row,
            dateCreated: row.dateCreated.split('/').reverse().join('-'),
            dateUpdated: row.dateUpdated.split('/').reverse().join('-'),
            details: '',
            subject: '',
            serviceIds: [],
            contractIds: []
        };
        setNewEntry(formattedRow);
        setType(row.type);
    }, [row]);

    useEffect(() => {
        if (Object.keys(currentTemplate).length < 1) return;
        dispatch(loadServiceDetailsByContract(currentTemplate.contractIds));
        setNewEntry((prev) => ({
            ...prev,
            details: currentTemplate.details,
            subject: currentTemplate?.subject || '',
            serviceIds: currentTemplate.serviceIds,
            contractIds: currentTemplate.contractIds
        }));

        setSelectedContracts(contracts.filter((el) => currentTemplate.contractIds.includes(el.id)));
    }, [currentTemplate]);

    useEffect(() => {
        setArrayServices(services);
        const selectedServices = services.filter((el) =>
            currentTemplate?.serviceIds?.includes(el.id)
        );
        setSelectedServices(selectedServices);
        setNewEntry((prev) => ({ ...prev, serviceIds: selectedServices.map((el) => el.id) }));
    }, [services]);

    useEffect(() => {
        if (newEntry.details.length > 140) setSmsColor(POLARIS_ORANGE);
        else {
            if (smsColor === POLARIS_ORANGE) setSmsColor(POLARIS_DARKER_GREY);
        }
    }, [newEntry.details]);

    // EVENT HANDLERS

    const onDetailsChange = (details) => {
        if (newEntry.inactive) return;
        setNewEntry((prev) => ({ ...prev, details }));
    };

    const onFormExit = () => {
        clearForm();
        onClose();
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validate(newEntry, errors, type);
        setErrors(newErrors);
        if (!isValid) return;
        const { subject, contractIds, ...rest } = newEntry;
        const updatedContractIds = contractIds.filter((el) =>
            selectedServices.find((entry) => entry.contract?.id === el)
        );
        const payload = { ...rest, contractIds: updatedContractIds };
        if (type === 'SMS') dispatch(updateSMSCommunicationTemplate(payload.id, payload));
        if (type === 'Letter') dispatch(updateLetterCommunicationTemplate(payload.id, payload));
        if (type === 'Email')
            dispatch(updateEmailCommunicationTemplate(payload.id, { ...payload, subject }));
        onFormExit();
    };

    // RENDER

    return arrayServices.length < 1 ? null : (
        <div className={form.formWrapper}>
            <h3>Edit Template</h3>
            <form className={form.formWrapper} onSubmit={onSubmit}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div>
                            <div className={form.staticField}>
                                <label className={form.formLabel}>Form of communication</label>
                                <div className={form.staticText}>{newEntry.type}</div>
                            </div>

                            <div className={form.staticField}>
                                <label className={form.formLabel}>Template Name</label>
                                <div className={form.staticText}>{newEntry.name}</div>
                            </div>

                            {type === 'Email' && (
                                <div className={form.staticField}>
                                    <label className={form.formLabel}>Email Subject</label>
                                    <div className={form.staticText}>{newEntry.subject}</div>
                                </div>
                            )}

                            <div className={form.switchWrapper}>
                                <PolarisSwitchSet
                                    id="inactive"
                                    disabled={false}
                                    label=""
                                    leftLabel="Active"
                                    rightLabel="Inactive"
                                    checked={newEntry.inactive}
                                    onSwitch={(e) =>
                                        setNewEntry((prev) => ({
                                            ...prev,
                                            inactive: e.target.checked
                                        }))
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className={form.formColumn}>
                        <div className={form.formErrorSet}>
                            <MultiSelect
                                id="contractIds"
                                label={'Contracts *'}
                                key={0}
                                placeholder="Contract"
                                disabled={!hasRole(acceptedRoles, roles)}
                                removable={false}
                                menuItems={contracts || []}
                                preSelectedIds={newEntry.contractIds}
                                preSelects={selectedContracts}
                                onChange={(ids) => {
                                    clearError('contractIds');
                                    if (ids.length === 0) return;
                                    dispatch(loadServiceDetailsByContract(ids));
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        contractIds: ids,
                                        serviceIds: ids?.length === 0 ? [] : newEntry.serviceIds
                                    }));
                                    setSelectedContracts(
                                        contracts.filter((el) => ids.includes(el.id))
                                    );
                                }}
                            />
                            {errors.contractIds.error && (
                                <div className={form.textInputError}>
                                    <IconError text={errors.contractIds} />
                                </div>
                            )}
                        </div>

                        <div className={form.alignUpMed}>
                            <MultiSelect
                                id="serviceIds"
                                key={1}
                                label="Services *"
                                placeholder="Service"
                                disabled={
                                    !hasRole(acceptedRoles, roles) ||
                                    newEntry.contractIds.length < 1
                                }
                                removable={false}
                                menuItems={arrayServices}
                                preSelectedIds={newEntry.serviceIds}
                                preSelects={selectedServices}
                                onChange={(ids) => {
                                    clearError('serviceIds');
                                    if (ids.length === 0) return;
                                    setNewEntry((prev) => ({ ...prev, serviceIds: ids }));
                                    setSelectedServices(
                                        services.filter((el) => ids.includes(el.id))
                                    );
                                }}
                            />
                        </div>
                        {errors.serviceIds.error && (
                            <div className={form.textInputError}>
                                <IconError text={errors.serviceIds} />
                            </div>
                        )}
                    </div>
                </div>

                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={form.textAreaSet}>
                            {type === 'SMS' && (
                                <>
                                    <label htmlFor="sms">Message *</label>
                                    <textarea
                                        className={`${form.textAreaText} ${form.inactiveInput}`}
                                        id="details"
                                        disabled={true}
                                        maxLength={SMS_CHARACTER_LIMIT}
                                        rows={10}
                                        placeholder={'Enter template details'}
                                        value={newEntry.details || ''}
                                        onChange={(e) =>
                                            setNewEntry((prev) => ({
                                                ...prev,
                                                details: e.target.value
                                            }))
                                        }
                                    />
                                    <div>
                                        <div>
                                            <span>Characters: </span>
                                            <span style={{ color: smsColor }}>
                                                {newEntry.details?.length}
                                            </span>
                                        </div>
                                        <div>
                                            <span>SMS Count: </span>
                                            <span>
                                                {Math.ceil(
                                                    newEntry.details?.length /
                                                        SMS_CHARACTERS_PER_SMS
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </>
                            )}
                            {(type === 'Letter' || type === 'Email') && (
                                <ReactQuill
                                    value={newEntry.details}
                                    modules={EditTemplate.modules}
                                    placeholder="Enter template details"
                                    onChange={onDetailsChange}
                                    style={{ color: '#b2b2b2' }}
                                    readOnly={true}
                                />
                            )}
                            {errors.details.error && (
                                <div className={`${form.textInputError} ${form.resetMargin}`}>
                                    <IconError text={errors.details} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <FormActions
                    onClose={onSubmit}
                    onCancel={onFormExit}
                    btnText="Update"
                    customClass="noTopBorder"
                />
            </form>
        </div>
    );
};

export default EditTemplate;

EditTemplate.propTypes = {
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    row: PropTypes.object
};

EditTemplate.modules = {
    toolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }],
        ['clean']
    ]
};
