import { compareUnorderedArrays } from '../../../utils/arrayUtils';
import { isDatePast, isTimePast } from '../../../utils/dateFunctions';
import {
    NO_UPDATE_ERROR,
    PAST_DATE_ERROR
} from '../../../utils/formValidation/commonErrorConstants';
import {
    getDateMaxMessage,
    getDateMinMessage,
    getReqMessage,
    getSelectMessage
} from '../../../utils/formValidation/errorMessageFunctions';

export const validateLocation = (locationId, errors) => {
    if (!locationId)
        return {
            ...errors,
            locationId: { error: true, message: getSelectMessage('location') }
        };
    return errors;
};

export const validateAppointmentType = (typeId, errors) => {
    if (!typeId)
        return {
            ...errors,
            typeId: { error: true, message: getSelectMessage('appointment type') }
        };
    return errors;
};

export const validateService = (serviceId, errors) => {
    if (!serviceId)
        return {
            ...errors,
            serviceId: { error: true, message: getSelectMessage('service') }
        };
    return errors;
};

export const validateAdviser = (userId, errors) => {
    if (!userId)
        return {
            ...errors,
            userId: { error: true, message: getSelectMessage('adviser') }
        };
    return errors;
};

export const validateParticipant = (participantId, errors) => {
    if (!participantId)
        return {
            ...errors,
            participantId: { error: true, message: getSelectMessage('participant') }
        };
    return errors;
};

export const validateStartTime = (startTime, endTime, errors) => {
    if (startTime >= endTime)
        return {
            ...errors,
            startTime: { error: true, message: getDateMaxMessage(endTime) }
        };
    return errors;
};

export const validateEndTime = (endTime, startTime, errors) => {
    if (endTime <= startTime)
        return {
            ...errors,
            endTime: { error: true, message: getDateMinMessage(startTime) }
        };
    return errors;
};

export const validateDate = (date, errors) => {
    if (!date) {
        return {
            ...errors,
            date: { error: true, message: getReqMessage('Date') }
        };
    }
    return errors;
};

export const checkForPastDate = (date, errors) => {
    if (isDatePast(date)) {
        return {
            ...errors,
            date: { error: true, message: PAST_DATE_ERROR }
        };
    }
    return errors;
};

export const checkForPastTime = (date, startTime, errors) => {
    if (isTimePast(`${date}T${startTime}`)) {
        return {
            ...errors,
            startTime: { error: true, message: getDateMinMessage('the current time') }
        };
    }
    return errors;
};

export const checkDiff = (newEntry, initialStateOnEntry, errors) => {
    if (compareUnorderedArrays(Object.values(newEntry), Object.values(initialStateOnEntry))) {
        return {
            ...errors,
            button: { error: true, message: NO_UPDATE_ERROR }
        };
    }
    return errors;
};
