import {
    checkDiff,
    validateAddress1,
    validateEmailAddress,
    validatePostcode,
    validatePrimaryPhoneNumber,
    validateSecondaryPhoneNumber
} from './validationFunctions';

export const validate = (newEntry, initialStateOnEntry) => {
    let newErrors = {};
    newErrors = validateAddress1(newEntry.address1, newErrors);
    newErrors = validatePostcode(newEntry.postcode, newErrors);
    newErrors = validatePrimaryPhoneNumber(newEntry.primaryPhoneNumber, newErrors);
    newErrors = validateSecondaryPhoneNumber(
        newEntry.secondaryPhoneNumber,
        newEntry.primaryPhoneNumber,
        newErrors
    );
    newErrors = validateEmailAddress(newEntry.emailAddress, newErrors);
    if (Object.keys(newErrors).length < 1)
        newErrors = checkDiff(newEntry, initialStateOnEntry, newErrors);

    return newErrors;
};
