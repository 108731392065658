import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectAllUserAppointmentsAndSlots } from '../../../store/calendarSelectors';
import {
    setCurrentAppointment,
    setOpenAppointmentSchedulerPanel
} from '../../../store/calendarService';
import { selectAllCourseIds, selectAllCourseModules } from '../../../store/courseSelectors';
import { searchCoursesByCourseIds } from '../../../store/courseService';
import {
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../../store/directusService';
import { setCurrentlySelectedParticipant } from '../../../store/participantService';
import { hasRole, MANAGER, SUPERUSER } from '../../../utils/userRoles';
import UserAppointmentCreate from '../calendarForUsers/UserAppointmentCreate';
import UserAppointmentEdit from '../calendarForUsers/UserAppointmentEdit';
import UserAppointmentSlotsEdit from '../calendarForUsers/UserAppointmentSlotsEdit';
import {
    configCourseEventContent,
    configParticipantEventContent,
    formatFullCalendarTimes,
    getBusinessHours,
    mapColors,
    mapCommonParams
} from '../utils/calendarUtils';

import SchedulingAssistantCalendar from './SchedulingAssistantCalendar';

import app from '../../../app.module.css';

let count = 0;

const SchedulingAssistantCalendarManagement = ({
    selectedAdvisers,
    selectedAdviser,
    isAppointmentSlots
}) => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const [courseEvents, setCourseEvents] = useState([]);
    const [events, setEvents] = useState([]);
    const [newEvent, setNewEvent] = useState({});
    const [currentEvent, setCurrentEvent] = useState({});
    const [currentAdviser, setCurrentAdviser] = useState({});
    const [currentResource, setCurrentResource] = useState('');
    const [advisers, setAdvisers] = useState([]);
    const [resources, setResources] = useState([]);

    // STORE STATE
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);
    const { roles } = loggedInUser;
    const contractDetailsById = useSelector(
        (state) => state.entities.directusService.contractDetailsById
    );
    const servicesByContractId = useSelector(
        (state) => state.entities.directusService.servicesByContractId
    );
    const locations = useSelector((state) => state.entities.directusService.locationIdsAndNames);
    const appointmentTypes = useSelector(
        (state) => state.entities.directusService.appointmentTypeDetails
    );
    const participants = useSelector((state) => state.entities.participantService.participants);
    const appointmentsAndSlotsByUser = useSelector(
        (state) => state.entities.calendarService.appointmentsAndSlotsByUser
    );
    const usersCurrentAppointment = useSelector(
        (state) => state.entities.calendarService.usersCurrentAppointment
    );
    const allUserAppointmentsAndSlots = useSelector(selectAllUserAppointmentsAndSlots);
    const courseModules = useSelector(selectAllCourseModules);
    const courseIds = useSelector(selectAllCourseIds);
    const courses = useSelector((state) => state.entities.courseService.coursesByCourseIds);
    const coursesByCourseIdsMetaData = useSelector(
        (state) => state.entities.courseService.coursesByCourseIdsMetaData
    );
    const openAppointmentSchedulerPanel = useSelector(
        (state) => state.entities.calendarService.openAppointmentSchedulerPanel
    );

    // CONFIG FNS
    const convertTimeToNumber = (time) => {
        if (!time) return;
        const [hr, min] = time.split(':');
        return +hr + +min / 60;
    };

    const sortEvents = (events) => {
        const resourceIds = [...new Set(events.map((el) => el.resourceId))];
        const resourceSorted = resourceIds.map((el) =>
            events.filter((entry) => entry.resourceId === el)
        );
        return resourceSorted
            .map((el) => {
                const uniqueDates = [...new Set(el.map((entry) => entry.date))];
                const dateSorted = uniqueDates.map((entry) =>
                    el.filter((item) => item.date === entry)
                );
                const timeToNumbers = dateSorted.map((dArr) => {
                    return dArr
                        .map((entry) => ({
                            ...entry,
                            startNumber: convertTimeToNumber(entry.displayStart),
                            endNumber: convertTimeToNumber(entry.displayEnd)
                        }))
                        .sort((a, b) => (a.startNumber > b.startNumber ? 1 : -1));
                });
                return timeToNumbers
                    .map((ele) =>
                        ele.map((entry, i, arr) => {
                            let hasOverlap = false;
                            if (arr[i].endNumber > arr[i + 1]?.startNumber) hasOverlap = true;
                            if (arr[i - 1]?.endNumber > arr[i].startNumber) hasOverlap = true;
                            return { ...entry, hasOverlap };
                        })
                    )
                    .flat();
            })
            .flat();
    };

    const configEvents = () => {
        const businessHours = getBusinessHours();
        let userEvents;
        if (!allUserAppointmentsAndSlots || allUserAppointmentsAndSlots.length < 1)
            userEvents = [{}];
        else
            userEvents = allUserAppointmentsAndSlots?.map((el) => {
                const { date, start, end, location } = mapCommonParams(el, locations);
                const { appointmentType, participantEntry, participant, slots, filledSlots } =
                    configParticipantEventContent(el, appointmentTypes, participants);
                const { color, textColor } = mapColors(el.id, usersCurrentAppointment?.id);
                const editable = false;
                const resourceEditable = el.userId === currentResource;
                const orderId = resources.find((entry) => entry.id === el.userId)?.orderId;
                const classes = `timeview-event ${
                    !isAppointmentSlots ? 'non-click-event' : !slots ? 'hide-event' : ''
                }`;
                return {
                    id: el.id,
                    eventType: 'user',
                    viewType: 'timeView',
                    className: classes,
                    hasLink: true,
                    slots,
                    filledSlots,
                    location,
                    participantEntry,
                    participant,
                    appointmentType,
                    displayStart: el.startTime,
                    displayEnd: el.endTime,
                    orderId,
                    color,
                    textColor,
                    date,
                    start,
                    end,
                    constraint: businessHours,
                    resourceId: el.userId,
                    resourceEditable,
                    editable,
                    draggable: false
                };
            });
        const updatedEvents = events?.filter((el) => el.resourceId !== currentResource);
        const newEvents = sortEvents(userEvents);
        setEvents([...updatedEvents, ...newEvents]);

        if (userEvents.length > 0) {
            const cur = events?.find((el) => el.id === usersCurrentAppointment?.id);
            if (cur && cur.resourceId === currentResource) setCurrentEvent(cur);
        }
    };

    const configModuleEvents = () => {
        const modules = courseModules?.filter((el) => el.date)?.filter((el) => el.userId);
        let events;
        if (modules?.length < 1) events = [];
        else
            events = modules?.map((el) => {
                const { date, start, end, location } = mapCommonParams(el, locations);
                const content = configCourseEventContent(el, courses, location);
                const orderId = resources?.find((entry) => entry.id === el.userId)?.orderId;
                const classes = `timeview-event non-click-event ${
                    isAppointmentSlots ? 'hide-event' : ''
                }`;
                return {
                    id: el.id,
                    eventType: 'courseModules',
                    className: classes,
                    viewType: 'timeView',
                    content,
                    date,
                    start,
                    end,
                    displayStart: el.startTime,
                    displayEnd: el.endTime,
                    orderId,
                    editable: false,
                    draggable: false,
                    resourceId: el.userId
                };
            });
        setCourseEvents([...courseEvents, ...events]);
    };

    const configResources = () => {
        const businessHours = getBusinessHours();
        let resources;
        if (!advisers.length) resources = [];
        else
            resources = advisers
                .map((el, i) => {
                    return el
                        ? {
                              id: el.id,
                              title: `${el.firstName} ${el.lastName}`,
                              orderId: i,
                              active: el.id === selectedAdviser.id,
                              businessHours: businessHours
                          }
                        : null;
                })
                .filter((el) => el);
        setResources(resources);
    };

    const sidePanel = document.querySelector('.scheduling-sidebar .adviser-panel .added-advisers');
    const table = document.querySelector('.fc .fc-timeline .fc-scroller-liquid-absolute');
    sidePanel?.addEventListener('scroll', () => {
        table.scrollTop = sidePanel.scrollTop;
        table.style.top = `${sidePanel.scrollTop}px`;
    });

    // USEEFFECTS
    useEffect(() => {
        setAdvisers(selectedAdvisers);
        setCurrentAdviser(selectedAdvisers[selectedAdvisers.length - 1]);
        setCurrentResource(selectedAdvisers[selectedAdvisers.length - 1]?.id);
        configResources();
    }, [selectedAdvisers]);

    useEffect(() => {
        if (Object.keys(selectedAdviser).length < 1) return;
        setCurrentResource(selectedAdviser.id);
        setCurrentAdviser(
            !('contractIds' in selectedAdviser)
                ? { ...selectedAdviser, contractIds: selectedAdviser.contracts || [] }
                : selectedAdviser
        );
        configResources();
    }, [selectedAdviser]);

    useEffect(() => {
        if (
            allUserAppointmentsAndSlots.length &&
            currentResource &&
            locations?.length &&
            participants?.length &&
            appointmentTypes?.length
        ) {
            configEvents();
        }
    }, [currentResource, allUserAppointmentsAndSlots, locations, participants, appointmentTypes]);

    useEffect(() => {
        const calendarTable = document.querySelector('.fc-timeline-body');
        if (!calendarTable) return;
        calendarTable && !advisers.length
            ? (calendarTable.style.visibility = 'hidden')
            : (calendarTable.style.visibility = 'visible');
        configResources();
    }, [advisers]);

    useEffect(() => {
        configEvents();
    }, [usersCurrentAppointment]);

    useEffect(() => {
        if (!courseModules || coursesByCourseIdsMetaData.last) return;
        courseIds.length > 0 &&
            dispatch(
                searchCoursesByCourseIds(
                    courseIds,
                    'number' in coursesByCourseIdsMetaData
                        ? coursesByCourseIdsMetaData.number + 1
                        : 0
                )
            );
    }, [courseModules, courseIds, coursesByCourseIdsMetaData]);

    useEffect(() => {
        if (courseModules?.length && courses?.length && locations?.length) configModuleEvents();
    }, [courses]);

    useEffect(() => {
        configEvents();
        configModuleEvents();
    }, [isAppointmentSlots]);

    // EVENT HANDLERS
    const onTogglePanel = (open) => {
        dispatch(setOpenAppointmentSchedulerPanel(open));
    };

    const onSelectEvent = (id) => {
        if (!currentResource || !isAppointmentSlots) return;

        setNewEvent({});
        if (id !== usersCurrentAppointment?.id) {
            const selectedAppointment = appointmentsAndSlotsByUser[currentResource]?.find(
                (el) => el.id === id
            );
            selectedAppointment && dispatch(setCurrentAppointment(selectedAppointment));
        }
        if (id === currentEvent.id) {
            count++;
            setTimeout(() => (count = 0), 300);
            if (count > 1) dispatch(setOpenAppointmentSchedulerPanel(true));
        }
    };

    const onSelectSlot = (start, end) => {
        const { date, startTime, endTime } = formatFullCalendarTimes({ start, end });
        setNewEvent({ date, startTime, endTime });
        onTogglePanel(true);
    };

    const onNav = (selectedEvent) => {
        dispatch(setCurrentlySelectedParticipant(selectedEvent.participantEntry));
        const { contractId } = selectedEvent.participantEntry;
        if (!(contractId in contractDetailsById))
            dispatch(loadContractDetailsForContractId(contractId));
        if (!(contractId in servicesByContractId))
            dispatch(loadServiceDetailsForContractId(contractId));
        navigate('/edit_participant');
    };

    // RENDER
    return (
        <div className={app.container}>
            <div className="calendar-wrapper">
                <SchedulingAssistantCalendar
                    resources={resources}
                    events={[...events, ...courseEvents]}
                    currentEvent={currentEvent}
                    onSelectSlot={onSelectSlot}
                    allowSlotSelect={!isAppointmentSlots}
                    onSelectEvent={onSelectEvent}
                    onChangeResource={configEvents}
                    onNav={onNav}
                    eventEditPermission={
                        hasRole([SUPERUSER, MANAGER], roles) ||
                        loggedInUser?.id === currentAdviser?.id
                    }
                />

                {openAppointmentSchedulerPanel &&
                    currentAdviser &&
                    (Object.keys(newEvent).length ? (
                        <UserAppointmentCreate
                            newAppointment={newEvent}
                            currentCalendarOwner={currentAdviser}
                        />
                    ) : currentEvent.slots ? (
                        <UserAppointmentSlotsEdit
                            usersCurrentAppointment={usersCurrentAppointment}
                            currentCalendarOwner={currentAdviser}
                            currentLocation={currentEvent.location}
                        />
                    ) : (
                        <UserAppointmentEdit
                            usersCurrentAppointment={usersCurrentAppointment}
                            currentCalendarOwner={currentAdviser}
                            currentLocation={currentEvent.location}
                            currentAppointmentType={currentEvent.appointmentType}
                            currentParticipantEntry={currentEvent.participantEntry}
                        />
                    ))}
            </div>
        </div>
    );
};

SchedulingAssistantCalendarManagement.propTypes = {
    selectedAdvisers: PropTypes.arrayOf(PropTypes.object),
    selectedAdviser: PropTypes.object,
    isAppointmentSlots: PropTypes.bool
};

export default SchedulingAssistantCalendarManagement;
