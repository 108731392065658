import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button } from '@mui/material';

import {
    loadCourseTemplatesByUserId,
    searchCoursesByLoggedInUser
} from '../../../store/courseService';
import { SCREEN_SETTINGS } from '../../../themes/theme';
import { hasRole, LOCAL_ADMIN, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import DynamicSearch from '../../search/DynamicSearch';
import LoadingSpinner from '../../ui/LoadingSpinner';

import CourseTemplateTable from './CourseTemplateTable';

import classes from '../courseStyles/courseToolbar.module.css';

const CourseTemplateManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const [activeTemplates, setActiveTemplates] = useState([]);
    const [rows, setRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    // STORE STATE
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);
    const courseTemplates = useSelector(
        (state) => state.entities.courseService.usersCourseTemplates
    );

    // USE EFFECTS
    useEffect(() => {
        if (!activeTemplates.length) {
            dispatch(loadCourseTemplatesByUserId(loggedInUser.id));
            dispatch(searchCoursesByLoggedInUser({ userId: loggedInUser.id }));
        }
    }, []);

    useEffect(() => {
        if (courseTemplates?.length) {
            const activeTemplates = courseTemplates.filter((el) => el.active);
            setActiveTemplates(activeTemplates);
            setRows(activeTemplates);
        }
    }, [courseTemplates]);

    useEffect(() => {
        const rows = !searchTerm
            ? activeTemplates
            : activeTemplates.filter((el) =>
                  el.name.toLowerCase().includes(searchTerm.toLowerCase())
              );
        setRows(rows);
    }, [searchTerm]);

    // EVENT HANDLERS
    const onSearch = (searchRes) => setSearchTerm(searchRes);

    const onCreateNewTemplate = () => navigate('/create_course_template');

    // RENDER
    return (
        <>
            <div className={classes.coursesToolBar}>
                <DynamicSearch
                    search={onSearch}
                    searchTerm={searchTerm}
                    placeholder="Enter Search Term"
                />
                <Button
                    disabled={!hasRole([QUALITY, SUPERUSER, LOCAL_ADMIN], loggedInUser.roles)}
                    color="primary"
                    variant="contained"
                    size={'small'}
                    onClick={onCreateNewTemplate}>
                    Add a new course template
                    <Box width={SCREEN_SETTINGS.gap.medium} />
                    <AddCircleOutlineIcon />
                </Button>
            </div>

            {rows.length < 1 ? (
                <LoadingSpinner content="No course templates found" />
            ) : (
                <CourseTemplateTable
                    rows={rows}
                    roles={loggedInUser.roles}
                    searchTerm={searchTerm}
                />
            )}
        </>
    );
};

export default CourseTemplateManagement;
