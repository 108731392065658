import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';

import {
    selectAssetRequestAsset,
    selectAssetRequestOrderType,
    selectAssetRequestStatus,
    selectAssetRequestType
} from '../../../../store/dataSelectors';
import {
    loadAssetRequestAssetDetails,
    loadAssetRequestOrderTypeDetails,
    loadAssetRequestRequestTypeDetails,
    loadAssetRequestStatusDetails
} from '../../../../store/directusService';
import { setErrorMessage } from '../../../../store/formsState';
import { selectCurrentParticipant } from '../../../../store/participantSelectors';
import { createAssetRequest, loadContractConstants } from '../../../../store/participantService';
import { getConfiguredItems } from '../../../../utils/directusFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import StaticField from '../../../formElements/StaticField';
import LabelledTextField from '../../../ui/editors/LabelledTextField';
import DDLOptionPicker from '../../../ui/pickers/DDLOptionPicker';

import form from '../../../../commonStyles/formStyles.module.css';
import actions from '../../../ui/formActions/formActions.module.css';

const REASON_DETAILS_LIMIT = 500;
const CreateAssetRequest = ({ onClose, roles }) => {
    const {
        register,
        handleSubmit,
        clearErrors,
        setFocus,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        code: null,
        adviserId: null,
        batchNumber: null,
        quantity: null,
        approverId: null,
        requestDetail: '',
        approverNote: null,
        statusId: null,
        participantId: null,
        assetId: null,
        orderTypeId: null,
        requestTypeId: null,
        voucherCode: null
    };

    const [newEntry, setNewEntry] = useState(initialState);

    const [arrayAssetRequestType, setArrayAssetRequestType] = useState([]);
    const [arrayAssetRequestAsset, setArrayAssetRequestAsset] = useState([]);
    const [arrayOrderType, setArrayOrderType] = useState([]);

    const [isClearSelectedAssetRequestType, setIsClearSelectedAssetRequestType] = useState('1');
    const [isClearSelectedAssetRequestAsset, setIsClearSelectedAssetRequestAsset] = useState('2');
    const [isClearSelectedAssetRequestOrderType, setIsClearSelectedAssetRequestOrderType] =
        useState('3');

    // STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const assetRequestOrderTypeDetails = useSelector(selectAssetRequestOrderType);
    const assetRequestStatusDetails = useSelector(selectAssetRequestStatus);
    const assetRequestTypeDetails = useSelector(selectAssetRequestType);
    const assetRequestAssetDetails = useSelector(selectAssetRequestAsset);

    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);

    const acceptedRoles = !hasRole(
        [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
        roles
    );

    // EVENT HANDLERS
    const handleBatchNumber = (e) => {
        setNewEntry((prev) => ({ ...prev, batchNumber: e.target.value }));
    };

    const handleQuantity = (e) => {
        setNewEntry((prev) => ({ ...prev, quantity: e.target.value }));
    };

    const handleVoucherCode = (e) => {
        setNewEntry((prev) => ({ ...prev, voucherCode: e.target.value }));
    };

    const handleAssetRequestRequestChange = (chosenId) => {
        if (chosenId) {
            setNewEntry((prev) => ({ ...prev, requestTypeId: chosenId }));
            clearErrors('requestTypeId');
        }
    };
    const handleAssetRequestAssetChange = (chosenId) => {
        if (chosenId) {
            setNewEntry((prev) => ({ ...prev, assetId: chosenId }));
            if (
                'Testing Approval Asset' !==
                arrayAssetRequestAsset.find((entry) => entry.id === chosenId)?.name
            ) {
                setNewEntry((prev) => ({ ...prev, approverNote: 'As automatically approved' }));
            }
            clearErrors('assetId');
        }
    };
    const handleAssetRequestOrderTypeChange = (chosenId) => {
        if (chosenId) {
            setNewEntry((prev) => ({ ...prev, orderTypeId: chosenId }));
            clearErrors('orderTypeId');
        }
    };

    const handleRequestDetailChange = (e) => {
        setNewEntry((prev) => ({ ...prev, requestDetail: e.target.value }));
        clearErrors('requestDetail');
    };

    // HELPER FNS
    const clearForm = () => {
        setNewEntry(initialState);
        setIsClearSelectedAssetRequestType(Math.random());
        setIsClearSelectedAssetRequestAsset(Math.random());
        setIsClearSelectedAssetRequestOrderType(Math.random());
    };

    // USE EFFECTS
    useEffect(() => {
        if (!currentParticipant?.id) return;
        setFocus('quantity');
        setNewEntry((prev) => ({ ...prev, participantId: currentParticipant.id }));

        if (!assetRequestStatusDetails?.length) dispatch(loadAssetRequestStatusDetails());
        if (!assetRequestTypeDetails?.length) dispatch(loadAssetRequestRequestTypeDetails());
        if (!assetRequestAssetDetails?.length) dispatch(loadAssetRequestAssetDetails());
        if (!assetRequestOrderTypeDetails?.length) dispatch(loadAssetRequestOrderTypeDetails());
        dispatch(loadContractConstants(currentParticipant.contractId));
    }, [currentParticipant]);

    useEffect(() => {
        setNewEntry((prev) => ({ ...prev, adviserId: loggedInUser.id }));
    }, [loggedInUser.id]);

    useEffect(() => {
        if (assetRequestStatusDetails?.length) {
            let status = assetRequestStatusDetails.find(
                (item) => item.name.toLowerCase() === 'requested'
            );
            let id = status.id;
            setNewEntry((prev) => ({ ...prev, statusId: id }));
        }
    }, [assetRequestStatusDetails]);

    useEffect(() => {
        if (assetRequestTypeDetails?.length && arrayAssetRequestType?.length === 0) {
            setArrayAssetRequestType(
                getConfiguredItems(assetRequestTypeDetails, currentParticipant?.contractId)
            );
        }
    }, [assetRequestTypeDetails]);

    useEffect(() => {
        if (assetRequestAssetDetails?.length > 0 && arrayAssetRequestAsset?.length === 0) {
            setArrayAssetRequestAsset(
                getConfiguredItems(assetRequestAssetDetails, currentParticipant?.contractId)
            );
        }
    }, [assetRequestAssetDetails]);

    useEffect(() => {
        if (assetRequestOrderTypeDetails?.length && arrayOrderType?.length === 0) {
            setArrayOrderType(
                getConfiguredItems(assetRequestOrderTypeDetails, currentParticipant?.contractId)
            );
        }
    }, [assetRequestOrderTypeDetails]);

    useEffect(() => {
        const updatedAssets = assetRequestAssetDetails?.filter((asset) => {
            return (
                asset.asset_request_request_type[0]?.asset_request_request_type_id?.id ===
                newEntry.requestTypeId
            );
        });
        setArrayAssetRequestAsset(updatedAssets);
    }, [newEntry.requestTypeId]);

    useEffect(() => {
        if (successMessage === 'Asset request created') clearForm();
    }, [successMessage]);

    // EVENT HANDLERS
    const onFormExit = () => {
        clearForm();
        clearErrors();
        onClose();
    };

    const onSubmit = () => {
        if (
            newEntry.quantity === '0' ||
            newEntry.quantity === '0.0' ||
            newEntry.quantity === '0.00'
        ) {
            dispatch(setErrorMessage('Quantity cannot be zero'));
        } else {
            dispatch(createAssetRequest(newEntry));
            onFormExit();
        }
    };

    // RENDER

    return (
        <div className={form.formWrapper}>
            <h3>Add New Asset Request</h3>
            <form className={form.form} onSubmit={handleSubmit(onSubmit)}>
                <br />
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <StaticField
                            id={'adviserId'}
                            label={'Requestor'}
                            content={loggedInUser?.emailAddress}
                        />
                        <DDLOptionPicker
                            label={'Request Type'}
                            id={'requestTypeId'}
                            key={isClearSelectedAssetRequestType}
                            disabled={acceptedRoles}
                            mandatory={true}
                            menuItems={arrayAssetRequestType || []}
                            error={errors.requestTypeId}
                            {...register('requestTypeId')}
                            onChange={handleAssetRequestRequestChange}
                        />
                        <DDLOptionPicker
                            label={'Asset'}
                            id={'assetId'}
                            key={isClearSelectedAssetRequestAsset}
                            disabled={!newEntry.requestTypeId || acceptedRoles}
                            mandatory={true}
                            menuItems={arrayAssetRequestAsset || []}
                            error={errors.assetId}
                            {...register('assetId')}
                            onChange={handleAssetRequestAssetChange}
                        />
                        <DDLOptionPicker
                            label={'Order Type'}
                            id={'orderTypeId'}
                            key={isClearSelectedAssetRequestOrderType}
                            disabled={acceptedRoles}
                            mandatory={true}
                            menuItems={arrayOrderType || []}
                            error={errors.orderTypeId}
                            {...register('orderTypeId')}
                            onChange={handleAssetRequestOrderTypeChange}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Quantity'}
                            id={'quantity'}
                            type="number"
                            InputProps={{
                                inputProps: {
                                    min: 1,
                                    step: 1,
                                    max: 99
                                }
                            }}
                            mandatory={true}
                            value={newEntry.quantity || ''}
                            placeholder={'Enter quantity'}
                            error={errors.quantity}
                            {...register('quantity')}
                            onChange={handleQuantity}
                        />
                        <LabelledTextField
                            label={'Batch Number'}
                            id={'batchNumber'}
                            mandatory={false}
                            value={newEntry.batchNumber || ''}
                            placeholder={'Enter batch number'}
                            error={errors.batchNumber}
                            {...register('batchNumber')}
                            onChange={handleBatchNumber}
                        />
                        <LabelledTextField
                            label={'Voucher Code'}
                            id={'voucherCode'}
                            mandatory={false}
                            value={newEntry.voucherCode || ''}
                            placeholder={'Enter voucher code'}
                            error={errors.voucherCode}
                            {...register('voucherCode')}
                            onChange={handleVoucherCode}
                        />
                        <LabelledTextField
                            label={'Request Details'}
                            id={'requestDetail'}
                            mandatory={false}
                            disabled={acceptedRoles}
                            multiline
                            rows={10}
                            value={newEntry.requestDetail}
                            placeholder={'Enter request details'}
                            counter={'true'}
                            helperText={`${
                                newEntry.requestDetail?.length ?? 0
                            } / ${REASON_DETAILS_LIMIT}`}
                            inputProps={{ maxLength: REASON_DETAILS_LIMIT }}
                            error={errors.requestDetail}
                            {...register('requestDetail')}
                            onChange={handleRequestDetailChange}
                        />
                    </div>
                </div>
                <div className={actions.formActions}>
                    <Button type="submit" color="primary" variant="contained">
                        Add Asset Request
                    </Button>
                    <div className={actions.cancelLink} onClick={onFormExit}>
                        Cancel X
                    </div>
                </div>
            </form>
        </div>
    );
};

const validString =
    (min, max, allowEmptyString = true) =>
    (val) =>
        !val ||
        (allowEmptyString ? val.length === 0 : false) ||
        (val.length >= min && val.length <= max);

const validationSchema = Yup.object().shape({
    assetId: Yup.string().nullable().required('Please select an asset'),
    batchNumber: Yup.string()
        .nullable()
        .test(
            'batchNumber',
            'A batch number when entered, must be at most 20 characters',
            validString(0, 20)
        ),
    orderTypeId: Yup.string().nullable().required('Please select an order type'),
    quantity: Yup.number()
        .nullable()
        .required('Please enter a quantity')
        .typeError('Quantity must be a whole number')
        .integer('Quantity must be a whole number')
        .min(1, 'Quantity cannot be negative, blank or zero')
        .max(99, 'Quantity must be 99 or less'),
    requestTypeId: Yup.string().nullable().required('Please select an asset request type'),
    requestDetail: Yup.string()
        .nullable()
        .test(
            'requestDetail',
            'A request detail when entered, must be at most 500 characters',
            validString(0, 500)
        ),
    voucherCode: Yup.string()
        .nullable()
        .test('voucherCode', 'A voucher code when entered, must be 9 characters', validString(9, 9))
});

export default CreateAssetRequest;

CreateAssetRequest.propTypes = {
    row: PropTypes.object,
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    formType: PropTypes.string
};
