import { checkExists, getUserURL } from '../../../api/commonHTTP';
import { MIN_EMAIL, MIN_PHONE } from '../../../utils/formValidation/commonConstants';
import {
    getCharMinMessage,
    getExistsMessage,
    getFormatMessage
} from '../../../utils/formValidation/errorMessageFunctions';
import { scrollToElement } from '../../../utils/scrollToElement';
import { READ_ONLY, SUPERUSER } from '../../../utils/userRoles';
import {
    EMAIL_REGEXP,
    FIRST_NAME_REGEXP,
    LAST_NAME_REGEXP,
    PHONE_REGEXP
} from '../../../validation/validation';

const MIN_NAME_CHAR = 1;
const MAX_NAME_CHAR = 100;

export const PAYROLL_MAX = `Payroll must be ${MAX_NAME_CHAR} characters or less`;
export const FIRST_NAME_MIN = `First name must be at least ${MIN_NAME_CHAR} character`;
export const FIRST_NAME_MAX = `First name must be ${MAX_NAME_CHAR} characters or less`;
export const FIRST_NAME_FORMAT = `First name must be in the correct format`;
export const LAST_NAME_MIN = `Last name must be at least ${MIN_NAME_CHAR} character`;
export const LAST_NAME_MAX = `Last name must be ${MAX_NAME_CHAR} characters or less`;

export const LAST_NAME_FORMAT = `Last name must be in the correct format`;
export const EMAIL_MIN = getCharMinMessage('Email address', MIN_EMAIL);
export const EMAIL_FORMAT = getFormatMessage('Email address');
export const EMAIL_EXISTS = getExistsMessage('A user with that email address');
export const JOB_TITLE_MAX = `Last name must be ${MAX_NAME_CHAR} characters or less`;
export const NO_CONTRACT_ID = `Please select at least one contract`;
export const NO_SERVICE_ID = 'Please select a primary service';
export const NO_TEAM_ID = 'Please select a primary team';
export const PHONE_MIN = getCharMinMessage('Phone number', MIN_PHONE);
export const PHONE_FORMAT = getFormatMessage('Phone number');
export const NO_USER_TYPE_IDS = 'Please select at least one user type';
export const NO_ACTIVE = 'Please set the active switch';

export const initialErrorState = {
    emailAddress: { error: false, message: '' },
    payroll: { error: false, message: '' },
    firstName: { error: false, message: '' },
    lastName: { error: false, message: '' },
    jobTitle: { error: false, message: '' },
    phoneNumber: { error: false, message: '' },
    primaryServiceId: { error: false, message: '' },
    primaryTeamId: { error: false, message: '' },
    contractIds: { error: false, message: '' },
    userTypeIds: { error: false, message: '' },
    active: { error: false, message: '' }
};

export const validate = (newEntry, errors, userTypes) => {
    let isValid = true;
    let newErrors = errors;

    if (newEntry.payroll?.trim().length > MAX_NAME_CHAR) {
        newErrors = {
            ...newErrors,
            payroll: { error: true, message: PAYROLL_MAX }
        };
        isValid = false;
    }

    if (newEntry.firstName?.trim().length < MIN_NAME_CHAR) {
        newErrors = {
            ...newErrors,
            firstName: { error: true, message: FIRST_NAME_MIN }
        };
        isValid = false;
    } else if (newEntry.firstName?.trim().length > MAX_NAME_CHAR) {
        newErrors = {
            ...newErrors,
            firstName: { error: true, message: FIRST_NAME_MAX }
        };
        isValid = false;
    } else if (!FIRST_NAME_REGEXP.test(newEntry.firstName)) {
        newErrors = {
            ...newErrors,
            firstName: { error: true, message: FIRST_NAME_FORMAT }
        };
        isValid = false;
    }

    if (newEntry.lastName?.trim().length < MIN_NAME_CHAR) {
        newErrors = {
            ...newErrors,
            lastName: { error: true, message: LAST_NAME_MIN }
        };
        isValid = false;
    } else if (newEntry.lastName?.trim().length > MAX_NAME_CHAR) {
        newErrors = {
            ...newErrors,
            lastName: { error: true, message: LAST_NAME_MAX }
        };
        isValid = false;
    } else if (!LAST_NAME_REGEXP.test(newEntry.lastName)) {
        newErrors = {
            ...newErrors,
            lastName: { error: true, message: LAST_NAME_FORMAT }
        };
        isValid = false;
    }

    if (newEntry.jobTitle?.trim().length > MAX_NAME_CHAR) {
        newErrors = {
            ...newErrors,
            jobTitle: { error: true, message: JOB_TITLE_MAX }
        };
        isValid = false;
    }

    if (newEntry.phoneNumber) {
        if (newEntry.phoneNumber?.trim().length < MIN_PHONE) {
            newErrors = {
                ...newErrors,
                phoneNumber: { error: true, message: PHONE_MIN }
            };
            isValid = false;
        } else if (!PHONE_REGEXP.test(newEntry.phoneNumber)) {
            newErrors = {
                ...newErrors,
                phoneNumber: { error: true, message: PHONE_FORMAT }
            };
            isValid = false;
        }
    }

    if (newEntry.contractIds.length < 1) {
        newErrors = {
            ...newErrors,
            contractIds: { error: true, message: NO_CONTRACT_ID }
        };
        isValid = false;
    }

    if (!newEntry.primaryServiceId) {
        newErrors = {
            ...newErrors,
            primaryServiceId: { error: true, message: NO_SERVICE_ID }
        };
        isValid = false;
    }

    if (!newEntry.primaryTeamId) {
        newErrors = {
            ...newErrors,
            primaryTeamId: { error: true, message: NO_TEAM_ID }
        };
        isValid = false;
    }
    if (newEntry.userTypeIds?.length < 1) {
        newErrors = {
            ...newErrors,
            userTypeIds: { error: true, message: NO_USER_TYPE_IDS }
        };
        isValid = false;
    }

    if (newEntry.userTypeIds?.length > 1) {
        const superUserId = userTypes.find((entry) => entry.role === SUPERUSER)?.id;
        const readOnlyId = userTypes.find((entry) => entry.role === READ_ONLY)?.id;
        if (newEntry.userTypeIds.includes(superUserId)) {
            newErrors = {
                ...newErrors,
                userTypeIds: {
                    error: true,
                    message: `SuperUser cannot be mixed with other types`
                }
            };
            isValid = false;
        }
        if (newEntry.userTypeIds.includes(readOnlyId)) {
            newErrors = {
                ...newErrors,
                userTypeIds: {
                    error: true,
                    message: `Read Only user cannot be mixed with other types`
                }
            };
            isValid = false;
        }
    }

    if (newEntry.active == null) {
        newErrors = {
            ...newErrors,
            active: { error: true, message: NO_ACTIVE }
        };
        isValid = false;
    }

    if (!newEntry.emailAddress || newEntry.emailAddress?.trim().length < MIN_EMAIL) {
        newErrors = {
            ...newErrors,
            emailAddress: { error: true, message: EMAIL_MIN }
        };
        isValid = false;
    } else if (!EMAIL_REGEXP.test(newEntry.emailAddress)) {
        newErrors = {
            ...newErrors,
            emailAddress: { error: true, message: EMAIL_FORMAT }
        };
        isValid = false;
    }

    scrollToElement(newErrors);
    return { isValid, newErrors };
};

export const checkEmailAddress = async (emailAddress) => {
    return await checkExists(getUserURL() + `rest/user/exists/email-address/${emailAddress}`).then(
        (ret) => ret
    );
};
