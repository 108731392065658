import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getNameFromId } from '../../../../utils/directusFunctions';

import card from '../../../../commonStyles/card.module.css';

const ModuleTemplateView = ({ module: { name, description, smartTarget } }) => {
    // STORE STATE
    const barrierNameDetails = useSelector(
        (state) => state.entities.directusService.barrierNameDetails
    );

    // RENDER
    return (
        <div data-testid="module_template_view" className={`${card.card} ${card.blueCard}`}>
            <div className={`${name?.length > 60 ? card.bannerDoubleRow : card.banner}`}>
                <h6>{name || 'Module Template'}</h6>
            </div>

            <div className={card.content}>
                <div>
                    <div className={card.subHeader}>Module Description:</div>
                    <div className={card.description}>
                        {description.length > 100
                            ? description.substring(0, 97) + '...'
                            : description}
                    </div>
                </div>
                {smartTarget && (
                    <div className={card.section}>
                        <p>
                            <span>SMART Target Name:</span>
                            {smartTarget.name}
                        </p>
                        <p>
                            <span>SMART Target Barrier: </span>
                            {smartTarget.barrierNameId &&
                                getNameFromId(barrierNameDetails, smartTarget.barrierNameId)}
                        </p>
                        <p>
                            <span>SMART Target Action: </span>
                            {smartTarget.action.length > 200
                                ? smartTarget.action.substring(0, 197) + '...'
                                : smartTarget.action}
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

ModuleTemplateView.propTypes = {
    module: PropTypes.object.isRequired
};

export default ModuleTemplateView;
