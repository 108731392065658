import { hasRole } from '../../../utils/userRoles';

import {
    checkDiff,
    checkForPastDate,
    checkForPastTime,
    validateDate,
    validateEndTime,
    validateLocation,
    validateStartTime
} from './validationFunctions';

export const validate = (newEntry, initialStateOnEntry, roles, canEditPastDateRoles) => {
    let newErrors = {};

    newErrors = validateLocation(newEntry.locationId, newErrors);
    newErrors = validateStartTime(newEntry.startTime, newEntry.endTime, newErrors);
    newErrors = validateEndTime(newEntry.endTime, newEntry.startTime, newErrors);
    newErrors = validateDate(newEntry.date, newErrors);
    if (!hasRole(canEditPastDateRoles, roles)) {
        newErrors = checkForPastDate(newEntry.date, newErrors);
        if (!newErrors?.date?.error)
            newErrors = checkForPastTime(newEntry.date, newEntry.startTime, newErrors);
    }

    let initialState = { ...initialStateOnEntry };
    if ('participants' in initialStateOnEntry) {
        // eslint-disable-next-line
        const { participants, ...rest } = initialStateOnEntry;
        initialState = rest;
    }
    newErrors = checkDiff(newEntry, initialState, newErrors);

    return newErrors;
};
