import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import classes from '../../../../../commonStyles/pdf.module.css';

const JobGoalTable = ({ onLoaded }) => {
    // LOCAL STATE
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState('true');

    // STORE STATE
    const jobSectors = useSelector((state) => state.entities.directusService.jobSectorDetails);
    const jobGoals = useSelector(
        (state) => state.entities.participantService.currentParticipantJobGoals
    );
    const loadingJobGoals = useSelector(
        (state) => state.entities.participantService.loadingParticipantJobGoals
    );

    // USE EFFECTS
    useEffect(() => {
        if (!loadingJobGoals && loading === 'true') setLoading('startLoading');
        if (!loadingJobGoals && loading === 'startLoading') setLoading('false');
    }, [loadingJobGoals]);

    useEffect(() => {
        if (jobGoals?.length < 1 && loading === 'false') {
            onLoaded('jobReadiness');
            return;
        }

        if (jobSectors?.length < 1) return;
        const data = jobGoals.map((el) => ({
            id: el.id,
            jobSector: jobSectors.find((entry) => entry.id === el.sectorId)?.name || '-',
            jobTitle: el.title
        }));
        setRows(data);
    }, [jobGoals, jobSectors, loading]);

    useEffect(() => {
        rows.length > 0 && onLoaded('jobReadiness');
    }, [rows]);

    // RENDER
    return (
        <div>
            <h3>Job Goals</h3>
            {rows.length < 1 ? (
                'No job goals'
            ) : (
                <div className={classes.pdfTable}>
                    <div className={classes.pdfTableHeaders}>
                        <span>Job Sector</span>
                        <span>Job Title</span>
                    </div>

                    <div className={classes.pdfTableRows}>
                        {rows.map((el, i) => (
                            <div
                                key={el.id}
                                id={`${el.id}--${i}`}
                                className={`${classes.pdfTableRow} ${
                                    i % 2 === 0 ? classes.rowPlain : classes.rowFilled
                                }`}>
                                <span>{el.jobSector}</span>
                                <span className={`${!el.jobTitle ? classes.alignColInMed : ''}`}>
                                    {el.jobTitle || ' - '}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

JobGoalTable.propTypes = {
    onLoaded: PropTypes.func
};

export default JobGoalTable;
