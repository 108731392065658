import { compareUnorderedArrays } from '../../../../utils/arrayUtils';
import { CREATE, INVALID_POSTCODE, UPDATE } from '../../../../utils/formValidation/commonConstants';
import { NO_UPDATE_ERROR } from '../../../../utils/formValidation/commonErrorConstants';
import {
    getDuplicatedMessage,
    getNumMinMessage,
    getReqMessage
} from '../../../../utils/formValidation/errorMessageFunctions';
import { regExps } from '../../../../utils/formValidation/validationPatterns';

export const MINIMUM_BUSINESS_SIZE = 1;
export const INVALID_WEBSITE = 'Website must be in the correct format.';
export const BUSINESS_NAME = 'Business Name';
export const BUSINESS_RECORD_OWNER = 'Business Record Owner';
export const BUSINESS_SECTOR = 'Business Sector';
export const BUSINESS_SIZE = 'Business Size';
export const ADDRESS_LINE_1 = 'Address Line 1';
export const CITY = 'City';
export const POSTCODE = 'Postcode';

export const initialErrorState = {};

export const validate = (newEntry, component, businesses, currentBusiness) => {
    let newErrors = {};

    if (!newEntry.name) {
        newErrors = {
            ...newErrors,
            name: { error: true, message: getReqMessage(BUSINESS_NAME) }
        };
    } else {
        const businessNames = businesses.filter((el) => el.id !== newEntry.id).map((el) => el.name);

        const duplicateBusinessName = businessNames.filter(
            (el) => el.toLowerCase() === newEntry.name.toLowerCase()
        );

        if (duplicateBusinessName.length > 0) {
            if (
                component === CREATE ||
                (component === UPDATE &&
                    duplicateBusinessName[0] !== currentBusiness.name.toLowerCase())
            ) {
                newErrors = {
                    ...newErrors,
                    button: { error: true, message: getDuplicatedMessage(BUSINESS_NAME) }
                };
            }
        }
    }

    if (!newEntry.sectorId) {
        newErrors = {
            ...newErrors,
            sectorId: { error: true, message: getReqMessage(BUSINESS_SECTOR) }
        };
    }

    if (newEntry.website) {
        if (!newEntry.website?.match(regExps.WEBSITE_R)) {
            newErrors = {
                ...newErrors,
                website: { error: true, message: INVALID_WEBSITE }
            };
        }
    }

    if (!newEntry.size) {
        newErrors = {
            ...newErrors,
            size: { error: true, message: getReqMessage(BUSINESS_SIZE) }
        };
    } else if (newEntry.size < MINIMUM_BUSINESS_SIZE) {
        newErrors = {
            ...newErrors,
            size: {
                error: true,
                message: getNumMinMessage(BUSINESS_SIZE, MINIMUM_BUSINESS_SIZE)
            }
        };
    }

    if (!newEntry.ownerId) {
        newErrors = {
            ...newErrors,
            ownerId: { error: true, message: getReqMessage(BUSINESS_RECORD_OWNER) }
        };
    }

    if (!newEntry.addressLine1) {
        newErrors = {
            ...newErrors,
            addressLine1: { error: true, message: getReqMessage(ADDRESS_LINE_1) }
        };
    }

    if (!newEntry.city) {
        newErrors = {
            ...newErrors,
            city: { error: true, message: getReqMessage(CITY) }
        };
    }

    if (!newEntry.postcode) {
        newErrors = {
            ...newErrors,
            postcode: { error: true, message: getReqMessage(POSTCODE) }
        };
    } else if (!newEntry.postcode?.match(regExps.POSTCODE_R)) {
        newErrors = {
            ...newErrors,
            postcode: { error: true, message: INVALID_POSTCODE }
        };
    }

    if (component === UPDATE) {
        if (Object.keys(newErrors).length < 1) {
            const noUpdate = compareUnorderedArrays(
                Object.values(newEntry),
                Object.values(currentBusiness)
            );

            if (noUpdate) {
                newErrors = { button: { error: true, message: NO_UPDATE_ERROR } };
            }
        }
    }

    return newErrors;
};
