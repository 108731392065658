import { scrollToElement } from '../../../utils/scrollToElement';

export const NO_GENDER = 'No gender details';

export const initialErrorState = {
    genderDetails: { error: false, message: '' },
    button: { error: false, message: '' }
};

export const validate = (selectedGenders, errors) => {
    let isValid = true;
    let newErrors = errors;

    if (selectedGenders.length < 1) {
        newErrors = {
            ...newErrors,
            genderDetails: { error: true, message: NO_GENDER }
        };
        isValid = false;
    }

    // TODO This isn't working.
    scrollToElement(newErrors);

    return { isValid, newErrors };
};
