import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { loadUsersBySearch } from '../../../store/userService';
import { addEmailAddressAsNameToArray } from '../../../utils/userArrayUtils';
import { ADVISER, MANAGER, RECRUITMENT_MANAGER } from '../../../utils/userRoles';
import SingleSelect from '../../formElements/SingleSelect';

const AdviserSelect = ({
    userId = '',
    serviceId = '',
    serviceIds = [],
    initialAdviser = {},
    onAdviserChange,
    disabled = false,
    customClass = '',
    mandatory = false,
    errors = {}
}) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const allowedAdviserRoles = [ADVISER, MANAGER, RECRUITMENT_MANAGER];
    const [advisers, setAdvisers] = useState([]);

    // STORE STATE
    const users = useSelector((state) => state.entities.userService.usersBySearch);
    const usersMetaData = useSelector((state) => state.entities.userService.usersBySearchMetaData);

    // USE EFFECTS
    useEffect(() => {
        if (Object.keys(initialAdviser)?.length > 1) updateAdvisers([initialAdviser]);
        if (users.length < 1) return;
        const filteredAdvisers = serviceId
            ? filterAdvisers()
            : users.filter(
                  (el) =>
                      el.active &&
                      el.userTypes.find((entry) => allowedAdviserRoles.includes(entry.role))
              );
        if (!filteredAdvisers.length && !usersMetaData?.last) {
            onLoadMoreAdvisers();
            return;
        }
        updateAdvisers(filteredAdvisers);
    }, [initialAdviser, users]);

    useEffect(() => {
        if (!serviceId) return;
        const updatedAdvisers = filterAdvisers();
        updateAdvisers(updatedAdvisers);
    }, [serviceId]);

    // HELPER FNS
    const updateAdvisers = (advisers) => {
        let newAdvisers = advisers;
        if (
            Object.keys(initialAdviser)?.length > 1 &&
            !advisers.some((el) => el.id === initialAdviser.id)
        ) {
            newAdvisers = [initialAdviser, ...advisers];
        }
        const updatedAdvisers = addEmailAddressAsNameToArray(newAdvisers).filter((el) => el.id);
        updatedAdvisers && setAdvisers(updatedAdvisers);
    };

    const filterAdvisers = () => {
        return users.length < 1
            ? []
            : users.filter(
                  (el) =>
                      el.active &&
                      el.userTypes.find((entry) => allowedAdviserRoles.includes(entry.role)) &&
                      [el.primaryService, ...el.otherServices].includes(serviceId)
              );
    };

    // EVENT HANDLERS
    const onChange = (id) => {
        if (id === userId) return;
        onAdviserChange(id);
    };

    const onLoadMoreAdvisers = () => {
        if (usersMetaData?.last) return;
        const ids = serviceIds.length > 0 ? serviceIds : [serviceId];
        dispatch(
            loadUsersBySearch({ serviceIds: ids }, !users.length ? 0 : usersMetaData.number + 1)
        );
    };

    // RENDER
    return (
        <SingleSelect
            id="adviserId"
            label="Adviser/Coach"
            placeholder="Select adviser"
            disabled={disabled}
            mandatory={mandatory}
            customClass={customClass}
            capitalizeList={false}
            menuItems={advisers || []}
            selectedId={userId}
            selected={userId ? advisers?.find((el) => el.id === userId) || {} : {}}
            moreItemsToLoad={!usersMetaData?.last}
            onLoadMoreItems={onLoadMoreAdvisers}
            error={errors.userId}
            onChange={onChange}
        />
    );
};

AdviserSelect.propTypes = {
    serviceId: PropTypes.string,
    serviceIds: PropTypes.arrayOf(PropTypes.string),
    initialAdviser: PropTypes.object,
    userId: PropTypes.string,
    onAdviserChange: PropTypes.func,
    disabled: PropTypes.bool,
    customClass: PropTypes.string,
    mandatory: PropTypes.bool,
    errors: PropTypes.object
};

export default AdviserSelect;
