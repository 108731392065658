import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    clearNewAppointment,
    createAppointment,
    setCurrentAppointment,
    setOpenAppointmentSchedulerPanel
} from '../../../store/calendarService';
import { selectCurrentParticipantsName } from '../../../store/participantSelectors';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import DateSelect from '../../formElements/DateSelect';
import StaticField from '../../formElements/StaticField';
import Panel from '../../ui/panel/Panel';
import AppointmentTypeSelect from '../components/AppointmentTypeSelect';
import LocationSelect from '../components/LocationSelect';
import MandatoryActivitiesSwitch from '../components/MandatoryActivitiesSwitch';
import StartAndEndTimesSelect from '../components/StartAndEndTimesSelect';
import StartAppointmentRow from '../components/StartAppointmentRow';
import StaticParticipant from '../components/StaticParticipant';
import { validate } from '../validation/validateParticipantAppointmentCreate';

import sidePanel from '../styles/schedulePanel.module.css';

const ParticipantAppointmentCreate = ({ newAppointment, currentCalendarOwner }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: '',
        typeId: '',
        userId: '',
        locationId: '',
        startTime: '08:00',
        endTime: '09:00',
        date: String(new Date().toISOString().split('T')[0]),
        serviceId: '',
        participantId: '',
        mandatory: false,
        type: '',
        service: '',
        participant: ''
    };

    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isEditable, setIsEditable] = useState(false);
    const [isCreated, setIsCreated] = useState(false);
    const [isDisabledStartAppointmentButton, setIsDisabledStartAppointmentButton] = useState(false);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

    // STORE STATE
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const currentParticipantsName = useSelector(selectCurrentParticipantsName);
    const serviceDetailsById = useSelector(
        (state) => state.entities.directusService.serviceDetailsById
    );
    const appointmentTypesByContractId = useSelector(
        (state) => state.entities.directusService.appointmentTypeDetailsByContractId
    );
    const openAppointmentSchedulerPanel = useSelector(
        (state) => state.entities.calendarService.openAppointmentSchedulerPanel
    );
    const newCreatedAppointment = useSelector(
        (state) => state.entities.calendarService.newAppointment
    );
    const errorMessage = useSelector((state) => state.entities.formsState.errorMessage);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS
    useEffect(() => {
        setIsEditable(hasRole(acceptedRoles, roles));
    }, []);

    useEffect(() => {
        if (Object.values(serviceDetailsById)?.length < 1) return;
        const { serviceId } = currentParticipant;
        setNewEntry({
            ...newEntry,
            serviceId,
            userId: currentCalendarOwner.id,
            startTime: newAppointment.startTime,
            endTime: newAppointment.endTime,
            date: newAppointment.date,
            service: serviceDetailsById[serviceId]?.name || '',
            participant: currentParticipantsName,
            participantId: currentParticipant.id
        });
    }, [serviceDetailsById]);

    useEffect(() => {
        setIsSaveButtonDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    useEffect(() => {
        if (
            successMessage === `Appointment ${newEntry.type} has been created` ||
            successMessage === `Appointment slot ${newEntry.type} has been created`
        ) {
            setIsCreated(true);
            if (newEntry.participantId && !newEntry.appointmentSlots) {
                setIsDisabledStartAppointmentButton(false);
            } else onPanelClose();
        }
    }, [successMessage]);

    useEffect(() => {
        if (errorMessage) {
            setIsSaveButtonDisabled(false);
        }
    }, [errorMessage]);

    // HELPER FNS
    const clearError = (key) => {
        if (key in errors && errors[key].error)
            setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    // EVENT HANDLERS
    const onPanelClose = () => {
        setNewEntry(initialState);
        dispatch(setCurrentAppointment({}));
        dispatch(setOpenAppointmentSchedulerPanel(false));
        dispatch(clearNewAppointment());
    };

    const onUpdate = (key, value) => {
        clearError(key);
        setIsDisabledStartAppointmentButton(true);
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onUpdateTime = (key, value) => {
        clearError(key === 'endTime' ? 'startTime' : 'endTime');
        onUpdate(key, value);
    };

    const onUpdateAppointmentType = (key, value, type) => {
        onUpdate(key, value);
        setNewEntry((prev) => ({ ...prev, type }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setIsSaveButtonDisabled(true);
        const newErrors = validate(newEntry);
        if (Object.values(newErrors).some((el) => el.error)) {
            setErrors(newErrors);
            return;
        }
        const {
            /* eslint-disable no-unused-vars */
            id,
            type,
            service,
            participant,
            ...rest
        } = newEntry;
        dispatch(createAppointment(newEntry.type, rest));
    };

    // RENDER
    return (
        <>
            <Panel width="500px" open={openAppointmentSchedulerPanel} onToggle={onPanelClose}>
                <div className={sidePanel.formWrapper}>
                    <form onSubmit={onSubmit} data-testid="participant_appointment_create_form">
                        <StaticField content={newEntry.service} customClass="underlinedContent" />

                        <StaticField label="Adviser" content={currentCalendarOwner.emailAddress} />

                        <AppointmentTypeSelect
                            appointmentTypes={
                                appointmentTypesByContractId[currentParticipant.contractId] || []
                            }
                            appointmentTypeId={newEntry.typeId}
                            disabled={isCreated}
                            onAppointmentTypeChange={onUpdateAppointmentType}
                            errors={errors}
                        />

                        <LocationSelect
                            locationId={newEntry.locationId}
                            serviceId={newEntry.serviceId}
                            errors={errors}
                            disabled={isCreated}
                            onLocationChange={onUpdate}
                        />

                        <StartAndEndTimesSelect
                            startTime={newEntry.startTime}
                            endTime={newEntry.endTime}
                            disabled={isCreated}
                            errors={errors}
                            onTimeChange={onUpdateTime}
                        />

                        <DateSelect
                            mandatory={true}
                            value={newEntry.date}
                            disabled={isCreated}
                            error={errors.date}
                            onDateChange={(date) => onUpdate('date', date)}
                        />

                        <StaticParticipant participant={currentParticipant} onNav={onPanelClose} />

                        <MandatoryActivitiesSwitch
                            isMandatory={newEntry.mandatory}
                            disabled={isCreated}
                            onSwitch={onUpdate}
                        />

                        <div className={sidePanel.updateButton}>
                            <Button
                                id="participantAppointmentCreateButton"
                                content="Save"
                                disabled={isSaveButtonDisabled}
                            />
                        </div>

                        {newCreatedAppointment?.id && currentParticipant && (
                            <StartAppointmentRow
                                onPanelClose={onPanelClose}
                                disabled={!isEditable || isDisabledStartAppointmentButton}
                                appointment={newCreatedAppointment}
                                participant={currentParticipant}
                            />
                        )}
                    </form>
                </div>
            </Panel>
        </>
    );
};

ParticipantAppointmentCreate.propTypes = {
    newAppointment: PropTypes.object,
    currentCalendarOwner: PropTypes.object
};

export default ParticipantAppointmentCreate;
