import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    clearAppointmentAttendanceDetails,
    searchAppointmentAttendanceDetails,
    searchBaseAppointments} from '../../store/calendarService';
import {
    selectAppointmentTypes,
    selectAttendanceStatus,
    selectNotKnownStatusId
} from '../../store/dataSelectors';
import { loadAppointmentTypeDetails } from '../../store/directusService';
import { selectParticipantsSearchResults } from '../../store/participantSelectors';
import {
    clearTimeLineSearchData,
    searchParticipantTimelineEvent
} from '../../store/participantService';
import { clearUsersMinimalDetails, loadUsersMinimalDetails } from '../../store/userService';
import {
    calcStartDate,
    countDays,
    formatJustTime,
    reverseFormatDate
} from '../../utils/dateFunctions';
import { getNameFromId } from '../../utils/directusFunctions';
import { downloadCsv } from '../../utils/downloadCSV';

import DataSetsResultsModal from './DataSetsResultsModal';

const DATE_RANGE = 60;
const fields =
    'Contract,Adviser,PT_ID,First_Name,Last_Name,Referral_Date,Current_Status,Appointment_Date,Appointment_Time,Appointment_Type,Appointment_Attendance,PO_Number';
const range = {
    TIMELINEONLYSEARCH: 'TimeLineOnlySearch',
    COMBINEDSEARCH: 'CombinedSearch',
    NOTKNOWNONLYSEARCH: 'NotKnownOnlySearch'
};

const CreateAttendanceOutcomesCsv = ({ selectedOptions, isOpen, onClose }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const [timeLineSearchData, setTimeLineSearchData] = useState([]);
    const [notKnownSearchData, setNotKnownSearchData] = useState([]);
    const [searchRange, setSearchRange] = useState('');
    const [isAdvisers, setIsAdvisers] = useState(null);

    // STORE STATE
    const attendanceStatusDetails = useSelector(selectAttendanceStatus);
    const notKnownStatusId = useSelector(selectNotKnownStatusId);
    const participantStatusDetails = useSelector(
        (state) => state.entities.directusService.participantStatusDetails
    );
    const participantsByContract = useSelector(selectParticipantsSearchResults);
    const timeLineSearch = useSelector(
        (state) => state.entities.participantService.participantTimelineSearchData
    );
    const timelineDataHasLoaded = useSelector(
        (state) => state.entities.participantService.participantTimelineSearchDataLoaded
    );
    const appointmentAttendanceDetails = useSelector(
        (state) => state.entities.calendarService.appointmentAttendanceDetails
    );
    const loadingAppointmentAttendanceDetails = useSelector(
        (state) => state.entities.calendarService.loadingAppointmentAttendanceDetails
    );
    const appointmentTypes = useSelector(selectAppointmentTypes);
    const userDetails = useSelector((state) => state.entities.userService.usersMinimalDetails);
    const calendarAppointments = useSelector(
        (state) => state.entities.calendarService.appointmentsBaseSearch
    );

    // USE EFFECTS

    useEffect(() => {
        if (participantsByContract?.length < 1 || Object.keys(selectedOptions).length < 1) return;

        const notKnownEntry = selectedOptions?.attendanceStatuses.find(
            (el) => el.name === 'Not known'
        );
        const participantIds = participantsByContract.map((el) => el.id);
        let statusIds;
        let searchType;
        if (notKnownEntry) {
            statusIds = selectedOptions?.attendanceStatuses
                .map((el) => (el.id !== notKnownEntry.id ? el.id : null))
                .filter((el) => el);
            dispatch(loadAppointmentTypeDetails());
            dispatch(searchAppointmentAttendanceDetails({ participantIds }));
            searchType = statusIds.length > 0 ? range.COMBINEDSEARCH : range.NOTKNOWNONLYSEARCH;
        } else {
            statusIds = selectedOptions?.attendanceStatuses.map((el) => el.id);
            searchType = range.TIMELINEONLYSEARCH;
        }
        setSearchRange(searchType);
        if (searchType === range.COMBINEDSEARCH || searchType === range.TIMELINEONLYSEARCH) {
            const loggedDateFrom = calcStartDate(DATE_RANGE, selectedOptions?.date);
            const loggedDateTo = selectedOptions?.date;
            dispatch(
                searchParticipantTimelineEvent({
                    participantIds,
                    newValues: statusIds,
                    loggedDateFrom,
                    loggedDateTo
                })
            );
        }
    }, [participantsByContract, selectedOptions]);

    useEffect(() => {
        if (!searchRange) return;
        if (searchRange === range.TIMELINEONLYSEARCH) {
            setTimeLineSearchData(timeLineSearch);
        }
        if (searchRange === range.COMBINEDSEARCH) {
            if (
                timeLineSearch?.length < 1 &&
                !loadingAppointmentAttendanceDetails &&
                appointmentAttendanceDetails?.length < 1
            ) {
                return;
            }
            timeLineSearch?.length > 0 && setTimeLineSearchData(timeLineSearch);
            configUnknownStatuses();
        }
        if (searchRange === range.NOTKNOWNONLYSEARCH) {
            if (!loadingAppointmentAttendanceDetails && appointmentAttendanceDetails?.length < 1) {
                return;
            }
            configUnknownStatuses();
        }
    }, [
        searchRange,
        timeLineSearch,
        appointmentAttendanceDetails,
        loadingAppointmentAttendanceDetails
    ]);

    useEffect(() => {
        if (timeLineSearchData.length < 1 && notKnownSearchData.length < 1) return;
        const numAdviserIds = configAdvisers();
        setIsAdvisers(numAdviserIds);
    }, [timeLineSearchData, notKnownSearchData]);

    useEffect(() => {
        if (
            !searchRange ||
            participantsByContract?.length < 1 ||
            participantStatusDetails?.length < 1
        )
            return;
        if (timeLineSearchData.length < 1 && notKnownSearchData.length < 1) return;
        if (isAdvisers === null) return;
        if (isAdvisers.length > 0 && userDetails?.length < 1) return;
        let values = [];
        if (searchRange === range.COMBINEDSEARCH || searchRange === range.TIMELINEONLYSEARCH) {
            if (timelineDataHasLoaded && timeLineSearchData.length > 0) {
                timeLineSearchData.forEach((el) => {
                    const participant = participantsByContract.find(
                        (entry) => entry.id === el.participantId
                    );
                    const adviser = userDetails.find((entry) => entry.id === participant.userId);
                    const participantStatus = getNameFromId(
                        participantStatusDetails,
                        participant.statusId
                    );

                    const attendanceName = getNameFromId(
                        attendanceStatusDetails,
                        el.history[0].newValue
                    );
                    const formattedAttendanceName = attendanceName.replace('–', 'by');
                    values = [
                        ...values,
                        [
                            selectedOptions.contract.name,
                            `${adviser?.firstName || ''} ${adviser?.lastName || ''}`,
                            participant.ptCode,
                            participant.firstName,
                            participant.lastName,
                            reverseFormatDate(participant.referralDate) || '',
                            participantStatus,
                            reverseFormatDate(el.loggedDate) || '',
                            formatJustTime(el.loggedTime),
                            el.eventType,
                            formattedAttendanceName,
                            participant.poNumber || ''
                        ]
                    ];
                });
            }
        }

        if (searchRange === range.COMBINEDSEARCH || searchRange === range.NOTKNOWNONLYSEARCH) {
            if (
                notKnownSearchData.length < 1 ||
                calendarAppointments?.length < 1 ||
                appointmentTypes?.length < 1
            )
                return;
            if (
                notKnownSearchData.length > 0 &&
                calendarAppointments?.length > 0 &&
                appointmentTypes?.length > 0
            ) {
                notKnownSearchData.forEach((el) => {
                    const participant = participantsByContract.find(
                        (entry) => entry.id === el.participantId
                    );
                    const adviser = userDetails.find((entry) => entry.id === participant.userId);
                    const participantStatus = getNameFromId(
                        participantStatusDetails,
                        participant.statusId
                    );

                    const attendanceName = getNameFromId(attendanceStatusDetails, el.statusId);
                    const formattedAttendanceName = attendanceName.replace('–', 'by');
                    const appointmentTypeId = calendarAppointments.find(
                        (entry) => entry.id === el.appointmentId
                    )?.typeId;

                    values = [
                        ...values,
                        [
                            selectedOptions.contract.name,
                            `${adviser?.firstName || ''} ${adviser?.lastName || ''}`,
                            participant.ptCode,
                            participant.firstName,
                            participant.lastName,
                            reverseFormatDate(participant.referralDate) || '',
                            participantStatus,
                            reverseFormatDate(el.date) || '',
                            formatJustTime(el.startTime) || '',
                            getNameFromId(appointmentTypes, appointmentTypeId),
                            formattedAttendanceName,
                            participant.poNumber || ''
                        ]
                    ];
                });
            }
        }

        const name = `${selectedOptions.attendanceStatuses.map((el) => el.name).join('/')}_${format(
            new Date(),
            `dd_MMM_yyyy_HH_mm`
        )}`;

        if (
            (isAdvisers > 0 && userDetails?.length > 0) ||
            (isAdvisers === 0 && userDetails?.length === 0)
        ) {
            downloadCsv(fields, values, name);
            onResultsModalClose();
        }
    }, [
        searchRange,
        isAdvisers,
        userDetails,
        calendarAppointments,
        appointmentTypes,
        participantsByContract,
        participantStatusDetails,
        timeLineSearchData,
        notKnownSearchData
    ]);

    // EVENT HANDLERS && HELPER FNS

    const configAdvisers = () => {
        let adviserIds = [];
        if (timeLineSearchData.length > 0) {
            adviserIds = [
                ...adviserIds,
                ...timeLineSearchData.map(
                    (el) =>
                        participantsByContract.find((entry) => entry.id === el.participantId)
                            ?.userId
                )
            ];
        }
        if (notKnownSearchData.length > 0) {
            adviserIds = [
                ...adviserIds,
                ...notKnownSearchData.map(
                    (el) =>
                        participantsByContract.find((entry) => entry.id === el.participantId)
                            ?.userId
                )
            ];
        }

        adviserIds = [...new Set(adviserIds.filter((el) => el))];
        if (adviserIds?.length > 0) dispatch(loadUsersMinimalDetails(adviserIds));
        return adviserIds ? adviserIds.length : null;
    };

    const configUnknownStatuses = () => {
        if (appointmentAttendanceDetails?.length > 0) {
            const filtered = appointmentAttendanceDetails.filter((el) => {
                const dayCount = countDays(el.date, selectedOptions.date);
                return (
                    el.statusId === notKnownStatusId &&
                    !el.inactive &&
                    el.date <= selectedOptions.date &&
                    dayCount <= 60
                );
            });
            const calendarIds = filtered.map((el) => el.appointmentId);
            dispatch(searchBaseAppointments({ ids: calendarIds }));
            setNotKnownSearchData(filtered);
        }
    };

    const onResultsModalClose = () => {
        dispatch(clearTimeLineSearchData());
        dispatch(clearAppointmentAttendanceDetails());
        dispatch(clearUsersMinimalDetails());
        setTimeLineSearchData([]);
        setNotKnownSearchData([]);
        setSearchRange('');
        setIsAdvisers(null);
        onClose();
    };

    // RENDER

    return (
        <DataSetsResultsModal
            isOpen={isOpen}
            onCancel={onResultsModalClose}
            resultRowsLength={timeLineSearchData.length + notKnownSearchData.length}
        />
    );
};

export default CreateAttendanceOutcomesCsv;

CreateAttendanceOutcomesCsv.propTypes = {
    selectedOptions: PropTypes.object,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func
};
