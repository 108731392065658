import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';

import { loadVacancyStageDetails } from '../../../../../store/directusService';
import { loadBusinessSectorDetails } from '../../../../../store/directusService';
import { loadBusinessRecord } from '../../../../../store/recruitmentService';
import {
    BUSINESS_SECTOR_DETAILS_ERROR,
    CURRENT_BUSINESS_ERROR,
    VACANCY_STAGE_DETAILS_ERROR
} from '../../../../../utils/formValidation/loadingErrorMessageConstants';
import RadioButtons from '../../../../formElements/RadioButtons';
import SingleSelect from '../../../../formElements/SingleSelect';
import TextAreaField from '../../../../formElements/TextAreaField';
import TextInputField from '../../../../formElements/TextInputField';
import LoadingSpinner from '../../../../ui/LoadingSpinner';

import app from '../../../../../app.module.css';
import form from '../../../../../commonStyles/formStyles.module.css';
import dropdownClasses from '../../../../courses/courseStyles/rowDropDown.module.css';

const CurrentVacancyDetails = ({ row, onClose }) => {
    //HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [hiringManagers, setHiringManagers] = useState([]);

    // STORE STATE
    const businessSectorDetails = useSelector(
        (state) => state.entities.directusService.businessSectorDetails
    );
    const vacancyStageDetails = useSelector(
        (state) => state.entities.directusService.vacancyStageDetails
    );
    const currentBusiness = useSelector(
        (state) => state.entities.recruitmentService.currentBusiness
    );

    // USE EFFECTS
    useEffect(() => {
        vacancyStageDetails?.length < 1 && dispatch(loadVacancyStageDetails());
        businessSectorDetails?.length < 1 && dispatch(loadBusinessSectorDetails());
    }, []);

    useEffect(() => {
        if (row && row.length !== 0) {
            dispatch(loadBusinessRecord(row.businessRecordId));
        }
    }, [row]);

    useEffect(() => {
        if (!currentBusiness?.ownerId) return;

        setHiringManagers(
            currentBusiness?.contactCards?.map((el) => ({
                id: el.id,
                name: `${el.firstName} ${el.lastName} ( ${el.officeNumber} )`
            }))
        );
    }, [currentBusiness]);

    // HELPER FNS
    const getHiringManagerName = (id) => {
        if (!id || id === '') {
            return '';
        }
        return hiringManagers.find((item) => item.id === id)?.name || '';
    };

    const loadingError = () => {
        if (businessSectorDetails?.length < 1) return BUSINESS_SECTOR_DETAILS_ERROR;
        if (Object.keys(currentBusiness)?.length < 1) return CURRENT_BUSINESS_ERROR;
        if (vacancyStageDetails?.length < 1) return VACANCY_STAGE_DETAILS_ERROR;
    };

    const getPreSelect = (arr, id) => {
        return arr.find((el) => el.id === id) || {};
    };

    // AWAITING CONTENT
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} time={3} />;

    // RENDER
    return (
        <div className={dropdownClasses.rowDropdown}>
            <form data-testid="form_start">
                <h2 className={app.sectionHeading}>Vacancy Details:</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'businessName'}
                            label={'Business Record'}
                            disabled={true}
                            value={currentBusiness.name}
                        />
                        <TextInputField
                            id={'managerId'}
                            label={'Hiring Manager'}
                            disabled={true}
                            value={
                                row.hiringManagerId ? getHiringManagerName(row.hiringManagerId) : ''
                            }
                        />
                    </div>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'positions'}
                            label={'Number of Positions'}
                            type="number"
                            disabled={true}
                            value={row.numberOfPositions || ''}
                        />
                        <TextInputField
                            label={'Expected Weekly Hours'}
                            id={'expectedWeeklyHours'}
                            type="number"
                            disabled={true}
                            value={row.expectedWeeklyHours || ''}
                        />
                    </div>
                </div>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextAreaField
                            id={'description'}
                            label={'Description'}
                            disabled={true}
                            value={row.description}
                        />
                    </div>
                </div>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextAreaField
                            id={'requirements'}
                            label={'Requirements'}
                            disabled={true}
                            rows={3}
                            value={row.description}
                        />
                        <TextAreaField
                            id={'shiftPattern'}
                            label={'Shift Patterns/Working Hours'}
                            disabled={true}
                            rows={3}
                            value={row.description}
                        />
                    </div>
                </div>
                <h2 className={app.sectionHeading}>Address:</h2>
                <RadioButtons
                    id="remote"
                    label="Remote"
                    disabled={true}
                    value={row.remote || false}
                />
                <div hidden={row.remote}>
                    <div className={form.formSection}>
                        <div className={form.formColumn}>
                            <TextInputField
                                id={'address1'}
                                label={'Address Line 1'}
                                disabled={true}
                                value={row.addressLine1 || ''}
                            />
                            <TextInputField
                                id={'address2'}
                                label={'Address Line 2'}
                                disabled={true}
                                value={row.addressLine2 || ''}
                            />
                            <TextInputField
                                id={'address3'}
                                label={'Address Line 3'}
                                disabled={true}
                                value={row.addressLine3 || ''}
                            />
                        </div>
                        <div className={form.formColumn}>
                            <TextInputField
                                id={'city'}
                                label={'City'}
                                disabled={true}
                                value={row.city || ''}
                            />
                            <TextInputField
                                id={'postcode'}
                                label={'Postcode'}
                                disabled={true}
                                value={row.postcode || ''}
                            />
                        </div>
                    </div>
                </div>
                <h2 className={app.sectionHeading}>Application Process:</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        {row.stageIds.map((el, i) => (
                            <div key={i}>
                                <div className={form.inputSet}>
                                    <SingleSelect
                                        id={'stageId'}
                                        // name={'stageId'}
                                        label={
                                            row.stageIds.length === i + 1
                                                ? 'Final Stage'
                                                : 'Stage ' + `${i + 1}`
                                        }
                                        disabled={true}
                                        menuItems={vacancyStageDetails}
                                        selectedId={el || ''}
                                        selected={getPreSelect(vacancyStageDetails, el)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={form.formColumn}></div>
                </div>
                <div className={dropdownClasses.exit} onClick={onClose}>
                    Close details
                    <CloseIcon />
                </div>
            </form>
        </div>
    );
};

CurrentVacancyDetails.propTypes = {
    row: PropTypes.object,
    onClose: PropTypes.func.isRequired
};

export default CurrentVacancyDetails;
