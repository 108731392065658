import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SellIcon from '@mui/icons-material/Sell';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';

import {
    loadCourseProvision,
    updateCourseProvisionParticipants
} from '../../../store/courseService';
import { POLARIS_BLUE, POLARIS_VICTORIA_BLUE } from '../../../themes/theme';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import LabelledTextField from '../../ui/editors/LabelledTextField';
import LoadingSpinner from '../../ui/LoadingSpinner';

import dropdownClasses from '../courseStyles/rowDropDown.module.css';

const AllActiveProvisionDetails = ({ row, roles, onClose }) => {
    const { handleSubmit } = useForm({});

    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [canBeSubmittedToProvision, setCanBeSubmittedToProvision] = useState('');
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];

    // STORE STATE
    const { currentProvision } = useSelector((state) => state.entities.courseService);
    const { currentParticipant } = useSelector((state) => state.entities.participantService);
    const { provisionStatusDetails } = useSelector((state) => state.entities.directusService);
    const { successMessage } = useSelector((state) => state.entities.formsState);

    // USE EFFECTS
    useEffect(() => {
        if (row && Object.keys(row)?.length !== 0) {
            dispatch(loadCourseProvision(row.id));
        }
    }, [row]);

    useEffect(() => {
        if (currentProvision && Object.keys(currentProvision).length !== 0) {
            setCanBeSubmittedToProvision(true);
            if (currentProvision.participants && !currentProvision.participants.length < 1) {
                const index = currentProvision.participants.findIndex(
                    (item) => item.participantId === currentParticipant.id
                );
                if (index >= 0) {
                    const selectedProvisionStatus = provisionStatusDetails.find(
                        (item) => item.name.toLowerCase() === 'cancelled'
                    );
                    if (
                        currentProvision.participants[index].statusId !==
                        selectedProvisionStatus?.id // Cancelled
                    ) {
                        setCanBeSubmittedToProvision(false);
                    }
                }
            }
        }
    }, [currentProvision.id]);

    useEffect(() => {
        if (successMessage === 'Participant has been submitted to provision') {
            setIsSubmitButtonDisabled(false);
            setCanBeSubmittedToProvision(false);
        }
    }, [successMessage]);

    //  FORM SUBMIT
    const onSubmit = () => {
        setIsSubmitButtonDisabled(true);
        let payload;
        let selectedProvisionStatus;
        if (currentProvision.participants?.length < 1) {
            selectedProvisionStatus = provisionStatusDetails.find(
                (item) => item.name.toLowerCase() === 'submitted'
            );
            payload = {
                participants: [
                    {
                        participantId: currentParticipant.id,
                        statusId: selectedProvisionStatus.id, // submitted
                        dateSubmitted: new Date().toISOString().slice(0, 10)
                    }
                ]
            };
        } else {
            const index = currentProvision.participants.findIndex(
                (item) => item.participantId === currentParticipant.id
            );
            const entries = [...currentProvision.participants];
            if (index >= 0) {
                selectedProvisionStatus = provisionStatusDetails.find(
                    (item) => item.name.toLowerCase() === 'cancelled'
                );
                if (
                    currentProvision.participants[index].statusId === selectedProvisionStatus?.id // cancelled
                ) {
                    entries.splice(index, 1);
                }
            }
            selectedProvisionStatus = provisionStatusDetails.find(
                (item) => item.name.toLowerCase() === 'submitted'
            );
            entries.push({
                participantId: currentParticipant.id,
                statusId: selectedProvisionStatus.id, // submitted
                dateSubmitted: new Date().toISOString().slice(0, 10)
            });
            payload = { participants: entries };
        }
        const msg = 'Participant has been submitted to provision';
        dispatch(updateCourseProvisionParticipants(currentProvision.id, payload, msg));
    };

    return !currentProvision ||
        currentProvision.length < 1 ||
        provisionStatusDetails.length < 1 ||
        canBeSubmittedToProvision === '' ? (
        <LoadingSpinner content="No provision information found" />
    ) : (
        <div
            onSubmit={(e) => {
                //  This stops the Submit event from bubbling up to the form enclosing this component
                e.preventDefault();
                e.stopPropagation();
            }}>
            <form
                onSubmit={handleSubmit(onSubmit)}
                data-testid="form_start_AllActiveProvisionDetails">
                <div className={dropdownClasses.rowDropdown}>
                    <Typography variant="h5">{row.name}</Typography>
                    <p className={dropdownClasses.description}>{row.description}</p>
                    <p>
                        <VerifiedIcon sx={{ color: POLARIS_VICTORIA_BLUE }} />
                        <sup>{row.ofqualNumber}</sup>
                    </p>
                    <p>
                        <SellIcon sx={{ color: POLARIS_BLUE }} />{' '}
                        <sup>
                            {'£' +
                                row.costPerParticipant +
                                ' per participant - Payment Method is ' +
                                row.paymentMethod}
                        </sup>
                    </p>
                    <Typography variant="h6">Delivery Information</Typography>
                    <LabelledTextField
                        sx={{ marginBottom: '-20px' }}
                        disabled={true}
                        rows={3}
                        value={row.deliveryInformation}
                    />
                    <Typography variant="h6">Qualification Required</Typography>
                    <LabelledTextField
                        sx={{ marginBottom: '-20px' }}
                        disabled={true}
                        rows={3}
                        value={row.qualificationRequired}
                    />
                    <Typography variant="h6">Registration Methods</Typography>
                    <LabelledTextField disabled={true} rows={3} value={row.registrationMethods} />
                    <Button
                        sx={{ marginTop: '-20px', marginBottom: '20px' }}
                        disabled={
                            !hasRole(acceptedRoles, roles) ||
                            !canBeSubmittedToProvision ||
                            isSubmitButtonDisabled
                        }
                        type="submit"
                        color="primary"
                        variant="contained"
                        data-testid="testIdSubmitButton1"
                        endIcon={<KeyboardArrowRightIcon />}>
                        {'Submit Participant To This Provision'}
                    </Button>
                    <div className={dropdownClasses.exit} onClick={onClose}>
                        Close details
                        <CloseIcon />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AllActiveProvisionDetails;

AllActiveProvisionDetails.propTypes = {
    row: PropTypes.object.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string),
    onClose: PropTypes.func.isRequired
};
