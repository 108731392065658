import React from 'react';
import PropTypes from 'prop-types';

import { TableCell, TableRow } from '@mui/material';

import OnEditIcon from '../../../icons/OnEditIcon';
import { reverseFormatDate } from '../../../utils/dateFunctions';

import classes from '../../../commonStyles/tables.module.css';

const ActionPlanTableRow = ({ row, roles, acceptedRoles, onEdit }) => {
    return (
        <>
            <TableRow hover className={`${classes.basicRowStripes}`}>
                <TableCell>{row.actionTitle}</TableCell>
                <TableCell>{row.barrierName === 'zz' ? ' - ' : row.barrierName}</TableCell>
                <TableCell>
                    {row.actionStartDate === '1960-01-01'
                        ? ' - '
                        : reverseFormatDate(row.actionStartDate)}
                </TableCell>
                <TableCell>
                    {row.actionDueDate === '1960-01-01'
                        ? ' - '
                        : reverseFormatDate(row.actionDueDate)}
                </TableCell>
                <TableCell>
                    {row.actionOutcomeDate === '1960-01-01'
                        ? ' - '
                        : reverseFormatDate(row.actionOutcomeDate)}
                </TableCell>
                <TableCell>{row.outcomeName === 'zz' ? ' - ' : row.outcomeName}</TableCell>
                <TableCell align="right">
                    <OnEditIcon
                        onEdit={() => onEdit(row)}
                        roles={roles}
                        acceptedRoles={acceptedRoles}
                    />
                </TableCell>
            </TableRow>
        </>
    );
};

export default ActionPlanTableRow;

ActionPlanTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    acceptedRoles: PropTypes.arrayOf(PropTypes.string),
    onEdit: PropTypes.func
};
