import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PortraitIcon from '@mui/icons-material/Portrait';
import RadarIcon from '@mui/icons-material/Radar';
import TodayIcon from '@mui/icons-material/Today';
import WarningIcon from '@mui/icons-material/Warning';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { Checkbox } from '@mui/material';

import { selectAttendedStatusId } from '../../../../store/dataSelectors';
import { selectUsersForNames } from '../../../../store/userSelectors';
import { reverseFormatDate } from '../../../../utils/dateFunctions';
import SingleSelect from '../../../formElements/SingleSelect';

import card from '../../../../commonStyles/card.module.css';

const ParticipantAttendanceStatusView = ({
    module,
    currentParticipant,
    information,
    attendanceDetails,
    onModuleChange,
    disabled = false
}) => {
    // LOCAL STATE
    const {
        title,
        description,
        locationId,
        date,
        startTime,
        endTime,
        userId,
        capacity,
        smartTarget,
        participants
    } = module;

    const [courseLocation, setCourseLocation] = useState('');
    const [adviser, setAdviser] = useState('');
    const [status, setStatus] = useState({});
    const [newModule, setNewModule] = useState({});

    // STORE STATE
    const users = useSelector(selectUsersForNames);
    const locations = useSelector((state) => state.entities.directusService.locationIdsAndNames);
    const attendedStatusId = useSelector(selectAttendedStatusId);

    // USE EFFECTS
    useEffect(() => {
        if (users.length) {
            const user = users.find((el) => el.id === userId);
            user && addAdviser(user);
        }
    }, [users]);

    useEffect(() => {
        if (locations?.length > 0) {
            const location = locations.find((el) => el.id === locationId);
            location && setCourseLocation(location.name);
        }
    }, [locations]);

    useEffect(() => {
        setNewModule(module);
        const status = attendanceDetails.find(
            (entry) =>
                entry.id ===
                (participants.find((el) => el.participantId === currentParticipant.id)?.statusId ||
                    '')
        );
        if (status?.name?.toLowerCase() === 'not known') {
            const updatedParticipants = updateParticipants(true, status.id);
            const updatedModule = {
                ...module,
                checked: true,
                participants: updatedParticipants
            };
            setNewModule(updatedModule);
        }
        setStatus(status);
    }, [module]);

    // HELPER FNS
    const addAdviser = (adviser) => {
        const { firstName, lastName } = adviser;
        setAdviser(`${firstName} ${lastName}`);
    };

    const updateParticipants = (checked, status) => {
        if (checked) {
            return !participants.some((el) => el.participantId === currentParticipant.id)
                ? [
                      ...participants,
                      {
                          participantId: currentParticipant.id,
                          statusId: status?.id,
                          notes: information
                      }
                  ]
                : participants;
        } else {
            return date + 'T' + startTime > format(new Date(), "yyyy-MM-dd'T'HH:mm")
                ? participants.filter((el) => el.participantId !== currentParticipant.id)
                : participants;
        }
    };

    // EVENT HANDLERS
    const onCheck = (e) => {
        const { checked } = e.target;
        let getStatus = status;
        if (!getStatus) {
            getStatus = attendanceDetails.find((item) => item.name.toLowerCase() === 'not known');
            setStatus(getStatus);
        } else {
            getStatus = status;
        }
        const updatedParticipants = updateParticipants(checked, getStatus);
        const updatedModule = {
            ...module,
            checked,
            participants: updatedParticipants
        };
        setNewModule(updatedModule);
        onModuleChange(updatedModule, null);
    };

    const onStatusIdChange = (chosenId) => {
        if (chosenId !== '') {
            const updatedParticipants = participants.map((el) =>
                el.participantId === currentParticipant.id ? { ...el, statusId: chosenId } : el
            );
            const updatedModule = { ...newModule, participants: updatedParticipants };
            setNewModule(updatedModule);
            let actionPlanData = null;
            if (chosenId === attendedStatusId && module.smartTarget) {
                actionPlanData = {
                    participantId: currentParticipant.id,
                    mandatory: '',
                    actionTitle: smartTarget.name,
                    action: smartTarget.action,
                    actionStartDate: '',
                    actionDueDate: '',
                    actionOutcomeDate: '',
                    outcomeId: '',
                    moduleId: module.id,
                    comments: ''
                };
            }
            onModuleChange(updatedModule, actionPlanData);
        }
    };

    // RENDER
    return (
        <div
            className={`${card.card} ${card.blueCard} ${card.attendanceStatusCard}`}
            data-testid="module_status_card_data">
            <div className={`${title?.length > 60 ? card.bannerDoubleRow : card.banner}`}>
                <h6>{title || 'Participant Status'}</h6>
            </div>

            <div className={card.content}>
                <div>
                    <div className={card.subHeader}>Module Description: </div>
                    <div className={card.description}>
                        {description && description.length > 100
                            ? description.substring(0, 97) + '...'
                            : description}
                    </div>
                </div>
                <div className={`${card.rows} ${card.compactRows}`}>
                    <div className={card.row}>
                        <LocationOnIcon />
                        {` ${courseLocation ? courseLocation : ' - '}`}
                    </div>
                    <div className={card.row}>
                        <TodayIcon />
                        {!date && !startTime && !endTime
                            ? ' - '
                            : ` ${date ? reverseFormatDate(date) : ''} ${
                                  startTime ? startTime : ''
                              } ${endTime ? endTime : ''}`}
                    </div>
                    <div className={card.row}>
                        <PortraitIcon />
                        {adviser || ' - '}
                    </div>
                    <div className={card.row}>
                        <RadarIcon />
                        <span className={card.smartTargetName}>{smartTarget?.name || ' - '}</span>
                    </div>
                </div>
                <div className={card.checkIcon}>
                    {!(date + 'T' + startTime <= format(new Date(), "yyyy-MM-dd'T'HH:mm")) && (
                        <label>
                            <Checkbox
                                key={module.id}
                                type="checkbox"
                                disabled={disabled}
                                checked={newModule.checked || false}
                                onClick={onCheck}
                            />
                            Module selected to attend
                        </label>
                    )}
                </div>

                <div className={card.actions}>
                    <div className={card.attendanceIcons}>
                        {date + 'T' + startTime <= format(new Date(), "yyyy-MM-dd'T'HH:mm") ? (
                            status?.name?.toLowerCase() === 'not known' ? (
                                <SingleSelect
                                    id={'selectedAttendanceStatus'}
                                    placeholder="Select status"
                                    customClass="compactFieldSelector"
                                    menuItems={attendanceDetails || []}
                                    selectedId={''}
                                    selected={{}}
                                    onChange={(chosenId) => onStatusIdChange(chosenId)}
                                />
                            ) : status?.name?.toLowerCase() === 'attended' ? (
                                <span>
                                    <CheckCircleIcon className={`${card.icon} ${card.green}`} />{' '}
                                    Attended Module
                                </span>
                            ) : status?.name?.toLowerCase() === 'failed to attend' ? (
                                <span>
                                    <CancelIcon className={`${card.icon} ${card.red}`} /> Failed To
                                    Attend
                                </span>
                            ) : status?.name?.toLowerCase() === 'cancelled - adviser' ? (
                                <span>
                                    <CancelIcon className={`${card.icon} ${card.red}`} /> Adviser
                                    Cancelled
                                </span>
                            ) : status?.name?.toLowerCase() === 'cancelled - participant' ? (
                                <span>
                                    <CancelIcon className={`${card.icon} ${card.red}`} />{' '}
                                    Participant Cancelled
                                </span>
                            ) : (
                                <span>
                                    <WarningIcon className={`${card.icon} ${card.yellow}`} /> Status
                                    Not Set
                                </span>
                            )
                        ) : (
                            ''
                        )}
                    </div>

                    <div className={card.attendanceView}>
                        <span className={card.spaces}>
                            <WorkspacesIcon />
                            {participants &&
                                ` ${participants.length} of ${capacity ? capacity : ''} spaces filled`}
                            {!participants && ` 0 spaces filled`}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

ParticipantAttendanceStatusView.propTypes = {
    module: PropTypes.object.isRequired,
    attendanceDetails: PropTypes.array,
    currentParticipant: PropTypes.object,
    information: PropTypes.string,
    onModuleChange: PropTypes.func,
    disabled: PropTypes.bool
};

export default ParticipantAttendanceStatusView;
