import PropTypes from 'prop-types';

const Confirmation = ({ participant }) => {
    const CONFIRMATION =
        'confirm that the information above is correct and that my Adviser has explained to me, and I understand, the service commitments and complaints procedure. I confirm that I have been issued a copy of the Privacy Notice/s and the contents of the Notice/s explained to me. I agree to maintain on-going contact with my Adviser and will let my Adviser know if I am to start work, or if I am ill and unable to participate on the programme. I confirm that I have been inducted to the programme and that during my induction, health and safety procedures were explained to me by my back to work programme Adviser.';

    return `I, ${participant.firstName} ${participant.lastName}, ${CONFIRMATION}`;
};

Confirmation.propTypes = {
    participant: PropTypes.object
};

export default Confirmation;
