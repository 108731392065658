import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { DateRange } from 'react-date-range';

import ClearIcon from '@mui/icons-material/Clear';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { InputAdornment, Popover, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { POLARIS_BLUE } from '../../themes/theme';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import form from '../../commonStyles/formStyles.module.css';
import common from './formElementStyles/commonFormElements.module.css';
import classes from './formElementStyles/dateRange.module.css';

const DateRangePicker = ({ id, label, mandatory = false, onSearch }) => {
    const dateFormat = 'dd/MM/yyyy';
    const aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

    const [displayCalendar, setDisplayCalendar] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    useEffect(() => {
        onSearch(inputValue);
    }, [inputValue]);

    // EVENT HANDLERS
    const onAdornmentClick = (e) => {
        setDisplayCalendar(true);
        setAnchorEl(e.currentTarget);
    };

    const onPopoverClose = () => {
        setDisplayCalendar(false);
        setAnchorEl(null);
    };

    const onSelectDateRanges = ({ selection }) => {
        let { startDate, endDate } = selection;
        let inputValue = '';
        if (startDate) inputValue += format(startDate, dateFormat);
        if (endDate) inputValue += ' - ' + format(endDate, dateFormat);

        setInputValue(inputValue);
        setFromDate(startDate);
        setToDate(endDate);
    };

    return (
        <div className={common.fieldWrapper}>
            <div className={classes.dateRangeSearch}>
                <div className={classes.inputContainer}>
                    <label htmlFor={id} className={form.formLabel}>
                        <span>
                            {label} {mandatory && <sup>*</sup>}
                        </span>
                    </label>
                    <TextField
                        sx={{ width: 250 }}
                        placeholder="select a Date Range"
                        value={inputValue}
                        disabled={true}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={onAdornmentClick}>
                                        <div className={classes.icons}>
                                            {!inputValue ? (
                                                <DateRangeIcon sx={{ color: POLARIS_BLUE }} />
                                            ) : (
                                                <ClearIcon
                                                    onClick={() => {
                                                        setInputValue('');
                                                        setDisplayCalendar(false);
                                                        setAnchorEl(null);
                                                    }}
                                                    className={classes.clear}
                                                />
                                            )}
                                        </div>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
                <Popover
                    open={displayCalendar}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    onClose={onPopoverClose}>
                    <div>
                        <DateRange
                            ranges={[
                                {
                                    startDate: fromDate,
                                    endDate: toDate,
                                    key: 'selection'
                                }
                            ]}
                            onChange={onSelectDateRanges}
                            staticRanges={undefined}
                            inputRanges={undefined}
                            maxDate={aYearFromNow}
                            showMonthAndYearPickers={true}
                            moveRangeOnFirstSelection={false}
                            showDateDisplay={false}
                            scroll={{ enabled: true }}
                        />
                    </div>
                </Popover>
            </div>
        </div>
    );
};

DateRangePicker.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    mandatory: PropTypes.bool,
    onSearch: PropTypes.func
};

export default DateRangePicker;
