import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectContracts, selectServices } from '../../../store/dataSelectors';
import { loadContractDetails, loadServiceDetailsByContract } from '../../../store/directusService';
import { selectUser, selectUserRoles, selectUserTypes } from '../../../store/userSelectors';
import {
    loadOtherTeamsForService,
    loadTeamsForService,
    loadUserTypes,
    updateUser
} from '../../../store/userService';
import { compareUnorderedArrays } from '../../../utils/arrayUtils';
import { MAX_EMAIL } from '../../../utils/formValidation/commonConstants';
import { clearKeys } from '../../../utils/objectUtils';
import { hasRole, READ_ONLY, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import MultiSelect from '../../formElements/MultiSelect';
import SingleSelect from '../../formElements/SingleSelect';
import Switch from '../../formElements/Switch';
import TextInputField from '../../formElements/TextInputField';
import LoadingSpinner from '../../ui/LoadingSpinner';

import {
    checkEmailAddress,
    EMAIL_EXISTS,
    MAX_JOB_TITLE_CHAR,
    MAX_NAME_CHAR,
    MAX_PAYROLL_CHAR,
    MAX_PHONE_CHAR,
    onValidate
} from './validateManageUsers';

import '../userStyles/editUserDetails.css';
import form from '../../../commonStyles/formStyles.module.css';
import pillClasses from '../../../commonStyles/staticPillList.module.css';
import local from './manageUsers.module.css';

const EditUsersDetails = ({ row, onClose, onCancel }) => {
    const dispatch = useDispatch();
    const onSelectUser = useMemo(selectUser, []);

    // LOCAL STATE
    const INITIAL_STATE = {
        locked: false,
        active: true,
        id: '',
        payroll: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        jobTitle: '',
        phoneNumber: '',
        primaryTeamId: '',
        otherTeamIds: null,
        primaryServiceId: null,
        otherServiceIds: null,
        contractIds: [],
        userTypeIds: [],
        code: null
    };

    const acceptedRoles = [SUPERUSER, READ_ONLY];
    const [newEntry, setNewEntry] = useState(INITIAL_STATE);

    const [usersContracts, setUsersContracts] = useState([]);
    const [newContracts, setNewContracts] = useState([]);
    const [selectedContractIds, setSelectedContractIds] = useState([]);

    const [usersOtherServices, setUsersOtherServices] = useState([]);
    const [servicesForPrimary, setServicesForPrimary] = useState([]);
    const [newServices, setNewServices] = useState([]);
    const [selectedServiceIds, setSelectedServiceIds] = useState([]);

    const [usersOtherTeams, setUsersOtherTeams] = useState([]);
    const [teamsForPrimary, setTeamsForPrimary] = useState([]);
    const [newTeams, setNewTeams] = useState([]);
    const [selectedTeamIds, setSelectedTeamIds] = useState([]);

    const [selectedUserTypeIds, setSelectedUserTypeIds] = useState([]);

    const [keys, setKeys] = useState({
        userTypeIds: '0',
        newServices: '1',
        newTeams: '2',
        newContracts: '3',
        primaryService: '4',
        primaryTeam: '5'
    });

    const [errors, setErrors] = useState({});

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const user = useSelector((state) => onSelectUser(state, row.id));
    const userTypes = useSelector(selectUserTypes);
    const contracts = useSelector(selectContracts);
    const services = useSelector(selectServices);
    const teamsForServices = useSelector((state) => state.entities.userService.teamsForService);
    const otherTeamsForService = useSelector(
        (state) => state.entities.userService.otherTeamsForService
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS
    useEffect(() => {
        if (!userTypes.length) dispatch(loadUserTypes());
        dispatch(loadContractDetails());
        setKeys((prev) => ({
            ...prev,
            newServices: Math.random(),
            newTeams: Math.random()
        }));
    }, []);

    useEffect(() => {
        if (!user?.id) return;
        dispatch(loadServiceDetailsByContract(user.contracts));
        const userTypeIds = user.userTypes?.map((el) => el.id);
        setNewEntry((prev) => ({
            ...prev,
            id: user.id,
            active: user.active,
            code: user.code,
            payroll: user.payroll,
            firstName: user.firstName,
            lastName: user.lastName,
            emailAddress: user.emailAddress,
            jobTitle: user.jobTitle,
            phoneNumber: user.phoneNumber,
            primaryTeamId: user.primaryTeam?.id,
            otherTeamIds: user.otherTeams.map((el) => el.id) || [],
            primaryServiceId: user.primaryService,
            otherServiceIds: user.otherServices,
            contractIds: user.contracts,
            userTypeIds
        }));
        setSelectedUserTypeIds(userTypeIds);
    }, [user]);

    useEffect(() => {
        if (!newEntry.primaryServiceId) return;
        dispatch(
            loadTeamsForService({
                serviceIds: [newEntry.primaryServiceId, ...newEntry.otherServiceIds]
            })
        );
    }, [newEntry.primaryServiceId, newEntry.otherServiceIds]);

    useEffect(() => {
        if (!user?.id) return;
        const existingContracts = user.contracts
            .map((el) => contracts.find((entry) => entry.id === el))
            .filter((el) => el);

        setUsersContracts(existingContracts);
        const unSelectedContracts = contracts.filter(
            (el) => !user.contracts.find((entry) => entry === el.id)
        );
        setNewContracts(unSelectedContracts);
    }, [contracts]);

    useEffect(() => {
        if (!user?.id) return;
        const userServiceIds = user.id ? [user.primaryService, ...user.otherServices] : [];
        const otherServices = user.otherServices
            .map((el) => services.find((entry) => entry.id === el))
            .filter((el) => el);

        setUsersOtherServices(otherServices);
        const unSelectedServices = services.filter(
            (el) => !userServiceIds.find((entry) => entry === el.id)
        );
        setNewServices(unSelectedServices);
        let primaryService;
        if (user.primaryService)
            primaryService = services.find((el) => el.id === user.primaryService);
        setServicesForPrimary(
            primaryService ? [...unSelectedServices, primaryService] : unSelectedServices
        );
    }, [services]);

    useEffect(() => {
        if (!teamsForServices || !newEntry.otherTeamIds) return;
        const primaryTeamsList = teamsForServices.filter(
            (el) => !newEntry.otherTeamIds.includes(el.id)
        );
        setTeamsForPrimary(primaryTeamsList);
        const otherTeams = user?.id ? user.otherTeams : [];
        setUsersOtherTeams(otherTeams);
        configNewTeams(teamsForServices);
    }, [teamsForServices, newEntry.otherTeamIds]);

    useEffect(() => {
        if (selectedServiceIds.length > 0) {
            dispatch(loadOtherTeamsForService({ serviceIds: selectedServiceIds }));
        }
    }, [selectedServiceIds]);

    useEffect(() => {
        configNewTeams([...otherTeamsForService, ...teamsForServices]);
    }, [otherTeamsForService]);

    useEffect(() => {
        if (successMessage === `Updated user ${newEntry.emailAddress}`) {
            clearUser();
        }
    }, [successMessage]);

    // HELPER FNS

    const configNewTeams = (teams) => {
        if (!teams) return;
        const allUsersServices = [
            user.primaryService,
            ...user.otherServices,
            ...selectedServiceIds
        ];
        const userTeam = teams.find((el) => el.id === newEntry.primaryTeamId);
        const usersExistingTeams = [...usersOtherTeams, userTeam].filter((el) => el);
        const allowedTeams = teams.filter(
            (el) =>
                allUsersServices.includes(el.serviceId) &&
                !usersExistingTeams.find((entry) => entry.id === el.id)
        );
        const selected = selectedTeamIds.filter((el) =>
            allowedTeams.find((entry) => entry.id === el)
        );
        setSelectedTeamIds(selected);
        setNewTeams(allowedTeams);
    };

    const clearUser = () => {
        setKeys(clearKeys(keys));
        setNewEntry(INITIAL_STATE);
        onClose();
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // EVENT HANDLERS

    const onLockedChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            locked: e.target.checked
        }));
    };

    const onActiveChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            active: e.target.checked
        }));
    };

    const onPayrollChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            payroll: e.target.value.trim().slice(0, MAX_PAYROLL_CHAR)
        }));
    };

    const onFirstNameChange = (e) => {
        clearError('firstName');
        setNewEntry((prev) => ({
            ...prev,
            firstName: e.target.value.trim().slice(0, MAX_NAME_CHAR)
        }));
    };

    const onLastNameChange = (e) => {
        clearError('lastName');
        setNewEntry((prev) => ({
            ...prev,
            lastName: e.target.value.trim().slice(0, MAX_NAME_CHAR)
        }));
    };

    const onEmailChange = (e) => {
        clearError('emailAddress');
        setNewEntry((prev) => ({
            ...prev,
            emailAddress: e.target.value.trim()
        }));
    };

    const onJobTitleChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            jobTitle: e.target.value.trim().slice(0, MAX_JOB_TITLE_CHAR)
        }));
    };

    const onPhoneChange = (e) => {
        clearError('phoneNumber');
        setNewEntry((prev) => ({
            ...prev,
            phoneNumber: e.target.value.trim().slice(0, MAX_PHONE_CHAR)
        }));
    };

    const onContractsChange = (ids) => {
        if (compareUnorderedArrays(ids, selectedContractIds)) return;
        setSelectedContractIds(ids);
        setSelectedServiceIds([]);
        setSelectedTeamIds([]);
        setKeys((prev) => ({
            ...prev,
            newServices: Math.random(),
            newTeams: Math.random()
        }));
        ids.length && dispatch(loadServiceDetailsByContract([...user.contracts, ...ids]));
    };

    const onPrimaryServiceChange = (chosenId) => {
        clearError('primaryServiceId');
        clearError('primaryTeamId');
        let updatedNewServices = newServices.filter((el) => el.id !== chosenId);
        if (
            newEntry.primaryServiceId &&
            !newServices.find((el) => el.id === newEntry.primaryServiceId)
        ) {
            const exPrimaryService = services.find((el) => el.id === newEntry.primaryServiceId);
            updatedNewServices = [...updatedNewServices, exPrimaryService];
        }
        setNewEntry((prev) => ({ ...prev, primaryServiceId: chosenId, primaryTeamId: '' }));
        setNewServices(updatedNewServices);
    };

    const onServicesChange = (ids) => {
        if (compareUnorderedArrays(ids, selectedServiceIds)) return;
        setSelectedServiceIds(ids);
        setSelectedTeamIds([]);
        setKeys((prev) => ({
            ...prev,
            newTeams: Math.random()
        }));
    };

    const onPrimaryTeamChange = (chosenId) => {
        clearError('primaryTeamId');
        let updatedListTeams = chosenId ? newTeams.filter((el) => el.id !== chosenId) : newTeams;
        if (newEntry.primaryTeamId && !newTeams.find((el) => el.id === newEntry.primaryTeamId)) {
            const exPrimaryTeam = teamsForServices.find((el) => el.id === newEntry.primaryTeamId);
            updatedListTeams = [...updatedListTeams, exPrimaryTeam];
        }
        setNewEntry((prev) => ({ ...prev, primaryTeamId: chosenId }));
        setNewTeams(updatedListTeams);
    };

    const onUserTypesChange = (chosenIds) => {
        if (compareUnorderedArrays(chosenIds, newEntry.userTypeIds)) return;
        clearError('userTypeIds');
        if (!chosenIds) chosenIds = [];
        newEntry?.userTypeIds.length > 0 &&
            setNewEntry((prev) => ({ ...prev, userTypeIds: chosenIds }));
        setSelectedUserTypeIds(chosenIds);
    };

    const onExit = () => {
        setKeys(clearKeys(keys));
        setNewEntry(INITIAL_STATE);
        onCancel();
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            ...newEntry,
            contractIds: [...newEntry.contractIds, ...selectedContractIds],
            otherServiceIds: [...newEntry.otherServiceIds, ...selectedServiceIds],
            otherTeamIds: [...newEntry.otherTeamIds, ...selectedTeamIds],
            userTypeIds: selectedUserTypeIds
        };
        const { newErrors } = onValidate(payload, userTypes);
        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) return;
        if (newEntry.emailAddress !== user.emailAddress) {
            const isExisting = await checkEmailAddress(newEntry.emailAddress);
            if (isExisting) {
                setErrors({
                    ...errors,
                    emailAddress: { error: true, message: EMAIL_EXISTS }
                });
                return;
            }
        }
        dispatch(updateUser(payload));
    };

    let content = '';
    if (userTypes?.length < 1) content = 'No user types';
    if (services?.length < 1) content = 'No services';
    if (teamsForServices?.length < 1) content = 'No teams';

    if (userTypes?.length < 1 || services?.length < 1 || teamsForServices?.length < 1)
        return <LoadingSpinner content={content} />;

    return (
        <div className={local.rowDropdownWrapper}>
            <form className={form.form} onSubmit={onSubmit} data-testid="form_start">
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={local.switchFormRow}>
                            <Switch
                                id="locked"
                                label="Locked"
                                checked={newEntry.locked}
                                onOff={true}
                                inline={true}
                                onChange={onLockedChange}
                            />
                            <Switch
                                id="active"
                                label="Active"
                                checked={newEntry.active}
                                onOff={true}
                                inline={true}
                                onChange={onActiveChange}
                            />
                        </div>
                    </div>
                </div>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            id="payroll"
                            label="Payroll"
                            placeholder="Enter payroll number"
                            value={newEntry.payroll || ''}
                            error={errors.payroll}
                            onChange={onPayrollChange}
                        />
                        <TextInputField
                            id="firstName"
                            label="First Name"
                            placeholder="Enter first name"
                            mandatory={true}
                            value={newEntry.firstName || ''}
                            error={errors.firstName}
                            onChange={onFirstNameChange}
                        />
                        <TextInputField
                            id="lastName"
                            label="Last Name"
                            placeholder="Enter last name"
                            mandatory={true}
                            value={newEntry.lastName || ''}
                            error={errors.lastName}
                            onChange={onLastNameChange}
                        />
                    </div>

                    <div className={form.formColumn}>
                        <TextInputField
                            id="emailAddress"
                            label="Email Address"
                            placeholder="Enter email address"
                            mandatory={true}
                            maxLength={MAX_EMAIL}
                            value={newEntry.emailAddress || ''}
                            error={errors.emailAddress}
                            onChange={onEmailChange}
                        />

                        <TextInputField
                            id="jobTitle"
                            label="Job Title"
                            placeholder="Enter job title"
                            value={newEntry.jobTitle || ''}
                            onChange={onJobTitleChange}
                        />

                        <TextInputField
                            id="phoneNumber"
                            name="phoneNumber"
                            label="Phone Number"
                            placeholder={'Enter phone number'}
                            value={newEntry.phoneNumber || ''}
                            error={errors.phoneNumber}
                            onChange={onPhoneChange}
                        />
                    </div>
                </div>
                {newEntry.code && (
                    <div className={form.formSection}>
                        <div className={form.formColumn}>
                            <div className={form.staticContent}>
                                <label>User Code</label>
                                {newEntry.code}
                            </div>
                        </div>
                    </div>
                )}
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={form.staticContent}>
                            <label>Contracts</label>
                            <div className={pillClasses.staticPillList}>
                                {usersContracts.map((el) => (
                                    <span className={pillClasses.pill} key={el.id}>
                                        {el.name}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className={form.formColumn}>
                        <div className="col2">
                            <MultiSelect
                                id="contracts"
                                key={keys.newContracts}
                                label="Add New Contracts"
                                placeholder="Add New Contracts"
                                menuItems={newContracts || []}
                                preSelectedIds={selectedContractIds || []}
                                preSelects={
                                    newContracts.filter((el) =>
                                        selectedContractIds.includes(el.id)
                                    ) || []
                                }
                                onChange={(ids) => onContractsChange(ids)}
                            />
                        </div>
                    </div>
                </div>

                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className="">
                            <SingleSelect
                                id={'primaryServiceId'}
                                key={keys.primaryService}
                                label={'Primary Service'}
                                placeholder="Select a primary service..."
                                mandatory={true}
                                disabled={!hasRole(acceptedRoles, roles)}
                                menuItems={servicesForPrimary || []}
                                selectedId={newEntry.primaryServiceId || ''}
                                selected={
                                    services.find((el) => el.id === newEntry.primaryServiceId) || {}
                                }
                                error={errors.primaryServiceId}
                                onChange={(chosenId) => onPrimaryServiceChange(chosenId)}
                            />
                        </div>

                        <div className={form.staticContent}>
                            <label>Other Services</label>
                            <div className={pillClasses.staticPillList}>
                                {usersOtherServices.map((el) => (
                                    <span className={pillClasses.pill} key={el.id}>
                                        {el.name}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className={form.formColumn}>
                        <MultiSelect
                            id="services"
                            key={keys.newServices}
                            label="Add New Services"
                            placeholder="Add New Services"
                            menuItems={newServices || []}
                            preSelectedIds={selectedServiceIds || []}
                            preSelects={
                                newServices.filter((el) => selectedServiceIds.includes(el.id)) || []
                            }
                            onChange={(ids) => onServicesChange(ids)}
                        />
                    </div>
                </div>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <SingleSelect
                            id={'primaryTeamId'}
                            key={keys.primaryTeam}
                            label={'Primary Team'}
                            placeholder="Select a primary team..."
                            mandatory={true}
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={teamsForPrimary || []}
                            selectedId={newEntry.primaryTeamId || ''}
                            selected={
                                teamsForPrimary.find((el) => el.id === newEntry.primaryTeamId) || {}
                            }
                            error={errors.primaryTeamId}
                            onChange={(chosenId) => onPrimaryTeamChange(chosenId)}
                        />

                        <div className={form.staticContent}>
                            <label>Other Teams</label>
                            <div className={pillClasses.staticPillList}>
                                {usersOtherTeams?.map((el) => (
                                    <span className={pillClasses.pill} key={el.id}>
                                        {el.name}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={form.formColumn}>
                        <MultiSelect
                            id="teams"
                            key={keys.newTeams}
                            label="Add New Teams"
                            placeholder="Add New Teams"
                            menuItems={newTeams || []}
                            preSelectedIds={selectedTeamIds || []}
                            preSelects={
                                newTeams.filter((el) => selectedTeamIds.includes(el.id)) || []
                            }
                            onChange={(ids) => setSelectedTeamIds(ids)}
                        />
                    </div>
                </div>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <MultiSelect
                            id="userTypeIds"
                            key={keys.userTypeIds}
                            label="User Types"
                            placeholder="Add New User Types"
                            mandatory={true}
                            selectAllDisabled={true}
                            menuItems={userTypes || []}
                            preSelectedIds={selectedUserTypeIds}
                            preSelects={
                                userTypes.filter((el) => selectedUserTypeIds.includes(el.id)) || []
                            }
                            error={errors.userTypeIds}
                            onChange={(chosenIds) => onUserTypesChange(chosenIds)}
                        />
                    </div>

                    <div className={form.formColumn}></div>
                </div>

                <div className={form.formActions}>
                    <Button id="editUserDetailsButton" content="Update User Details" />
                    <div className={form.cancelLink} onClick={onExit}>
                        Cancel X
                    </div>
                </div>
            </form>
        </div>
    );
};

export default EditUsersDetails;

EditUsersDetails.propTypes = {
    row: PropTypes.object,
    onClose: PropTypes.func,
    onCancel: PropTypes.func
};
