import React from 'react';
import PropTypes from 'prop-types';

import OnEditIcon from '../../../../icons/OnEditIcon';
import { getNameFromId } from '../../../../utils/directusFunctions';

import card from '../../../../commonStyles/card.module.css';

const ModuleTemplate = ({
    entry,
    disabled = false,
    barrierNameDetails,
    roles,
    acceptedRoles,
    onEdit
}) => {
    return (
        <div data-testid="module_template" className={card.card}>
            <div
                className={`${
                    entry.name?.length > 60 ? card.bannerDoubleRow : card.banner
                } ${disabled ? card.disabledBanner : ''}`}>
                {!disabled && (
                    <span className={card.editIcon} data-testid="module_template_edit_icon">
                        <OnEditIcon
                            onEdit={() => onEdit(entry)}
                            roles={roles}
                            acceptedRoles={acceptedRoles}
                        />
                    </span>
                )}
                <h6>{entry.name || 'Module Template'}</h6>
            </div>

            <div className={card.content}>
                <div>
                    <div className={card.subHeader}>Module Description:</div>
                    <div className={card.description}>
                        {entry.description?.length > 100
                            ? entry.description.substring(0, 97) + '...'
                            : entry.description}
                    </div>
                </div>
                {entry.smartTarget && (
                    <div className={card.section}>
                        <p>
                            <span>Action Name:</span>
                            {entry.smartTarget.name}
                        </p>
                        <p>
                            <span>Action Barrier: </span>
                            {entry.smartTarget.barrierNameId &&
                                getNameFromId(barrierNameDetails, entry.smartTarget.barrierNameId)}
                        </p>
                        <p>
                            <span>Action Description: </span>
                            {entry.smartTarget.action.length > 200
                                ? entry.smartTarget.action.substring(0, 197) + '...'
                                : entry.smartTarget.action}
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

ModuleTemplate.propTypes = {
    roles: PropTypes.arrayOf(PropTypes.string),
    acceptedRoles: PropTypes.arrayOf(PropTypes.string),
    entry: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    barrierNameDetails: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired
};

export default ModuleTemplate;
