import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';

import { selectCurrentForm, selectCurrentParticipant } from '../../../store/participantSelectors';
import { createFormParticipant, loadForm } from '../../../store/participantService';
import { loadUser } from '../../../store/userService';

import { themeJson } from './FormTheme';

const ConductForm = ({ formId, onClose }) => {
    //HOOKS
    const dispatch = useDispatch();

    //LOCAL STATE
    const [surveyModel, setSurveyModel] = useState(new Model('{}'));
    const [surveyComplete, setSurveyComplete] = useState(false);

    //STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const currentForm = useSelector(selectCurrentForm);
    const formStatusDetails = useSelector(
        (state) => state.entities.directusService.formStatusDetails
    );

    // EVENT HANDLERS
    const onSurveyComplete = () => {
        setSurveyComplete(true);
    };

    const onFormExit = () => {
        setSurveyComplete(false);
        onClose();
    };

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadForm(formId));
    }, [formId]);

    useEffect(() => {
        if (currentParticipant.userId) {
            dispatch(loadUser(currentParticipant.userId));
        }
    }, [currentParticipant]);

    useEffect(() => {
        if (Object.keys(currentForm).length < 1) return;

        const model = new Model(currentForm.data);
        model.applyTheme(themeJson);
        setSurveyModel(model);
    }, [currentForm]);

    useEffect(() => {
        if (surveyComplete) {
            const payload = {
                participantId: currentParticipant.id,
                statusId: formStatusDetails.find((el) => el.name === 'Open')?.id || '',
                pdfName: currentForm.name,
                formId: currentForm.id,
                data: surveyModel.data
            };
            dispatch(createFormParticipant(payload));
            onFormExit();
        }
    }, [surveyComplete]);

    // RENDER
    return (
        <div>
            <Survey model={surveyModel} onComplete={onSurveyComplete} />
        </div>
    );
};

ConductForm.propTypes = {
    formId: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string)
};

export default ConductForm;
