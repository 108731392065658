import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { reverseFormatDate } from '../../../../../utils/dateFunctions';
import { getNameFromId } from '../../../../../utils/directusFunctions';

import classes from '../../../../../commonStyles/pdf.module.css';

const AssetRequestTable = ({ onLoaded }) => {
    // LOCAL STATE
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState('true');

    // STORE STATE
    const assetRequests = useSelector(
        (state) => state.entities.participantService.participantAssetRequests
    );
    const loadingAssetRequests = useSelector(
        (state) => state.entities.participantService.loadingAssetRequests
    );
    const assetRequestStatusDetails = useSelector(
        (state) => state.entities.directusService.assetRequestStatusDetails
    );
    const assets = useSelector((state) => state.entities.directusService.assetRequestAssetDetails);
    const users = useSelector((state) => state.entities.userService.users);

    // USE EFFECTS
    useEffect(() => {
        if (!loadingAssetRequests && loading === 'true') setLoading('startLoading');
        if (!loadingAssetRequests && loading === 'startLoading') setLoading('false');
    }, [loadingAssetRequests]);

    useEffect(() => {
        if (assetRequests?.length < 1 && loading === 'false') {
            onLoaded('assetRequest');
            return;
        }
        if (assets?.length < 1 || assetRequestStatusDetails?.length < 1 || users?.length < 1)
            return;
        const data = assetRequests.map((el) => {
            const user = users.find((entry) => entry.id === el.authoriserId);
            return {
                code: el.code,
                asset: getNameFromId(assets, el?.assetId) || ' - ',
                status: getNameFromId(assetRequestStatusDetails, el?.statusId) || ' - ',
                statusChangeDate: el?.statusChangeDate
                    ? reverseFormatDate(el?.statusChangeDate)
                    : ' - ',
                quantity: el?.quantity,
                dateIssued: el?.dateIssued ? reverseFormatDate(el?.dateIssued) : ' - ',
                authoriser:
                    user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : ' - '
            };
        });
        setRows(data);
    }, [assets, assetRequests, assetRequestStatusDetails, users, loading]);

    useEffect(() => {
        rows.length > 0 && onLoaded('assetRequest');
    }, [rows]);

    // RENDER
    return (
        <div>
            <h3>Asset Request</h3>
            {rows.length < 1 ? (
                'No asset requests'
            ) : (
                <div className={classes.pdfTable}>
                    <div className={classes.pdfTableHeaders}>
                        <span>ID</span>
                        <span>Asset</span>
                        <span>Status</span>
                        <span className={classes.alignLeftMinusMin}>Status Change Date</span>
                        <span className={classes.alignCenter}>Quantity</span>
                        <span>Date Issued</span>
                        <span>Authoriser</span>
                    </div>

                    <div className={classes.pdfTableRows}>
                        {rows.map((el, i) => (
                            <div
                                key={el.code}
                                id={`${el.code}--${i}`}
                                className={`${classes.pdfTableRow} ${
                                    i % 2 === 0 ? classes.rowPlain : classes.rowFilled
                                }`}>
                                <span>{el.code}</span>
                                <span>{el.asset}</span>
                                <span>{el.status}</span>
                                <span>{el.statusChangeDate}</span>
                                <span className={classes.alignCellCenter}>{el.quantity}</span>
                                <span
                                    className={`${
                                        el.dateIssued === ' - ' ? classes.alignCenter : ''
                                    }`}>
                                    {el.dateIssued}
                                </span>

                                <span
                                    className={`${
                                        el.authoriser === ' - ' ? classes.alignCenter : ''
                                    }`}>
                                    {el.authoriser}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

AssetRequestTable.propTypes = {
    onLoaded: PropTypes.func
};

export default AssetRequestTable;
