import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../../api/pagination';
import {
    loadClaimEventStatuses,
    loadClaimEventTypes,
    loadContractDetails
} from '../../../store/directusService';
import {
    clearClaimQueues,
    searchClaimQueue,
    updateClaimQueueProgress
} from '../../../store/participantService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { formatDateRange } from '../../../utils/dateFunctions';
import { getNameFromId } from '../../../utils/directusFunctions';
import { NO_UPDATE_ERROR } from '../../../utils/formValidation/commonErrorConstants';
import { clearKeys, deepEqual, trimEntries } from '../../../utils/objectUtils';
import { stableSort } from '../../../utils/sortFunctions';
import { hasRole, PRAP, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import DateRangePicker from '../../formElements/DateRangePicker';
import SingleSelect from '../../formElements/SingleSelect';
import SearchOnEnter from '../../search/SearchOnEnter';
import ResultsTable from '../../table/ResultsTable';
import LoadingSpinner from '../../ui/LoadingSpinner';

import ClaimTableRow from './ClaimTableRow';
import StatusRevertEditor from './StatusRevertEditor';

import app from '../../../app.module.css';
import form from '../../../commonStyles/formStyles.module.css';
import local from '../claimStyles/validations.module.css';

const loadPageSize = DEFAULT_PAGE_LOAD_SIZE;
const sortOrder = 'asc';
const headCells = [
    { id: 'ptCode', label: 'ID', sortable: true },
    { id: 'poNumber', label: 'PO Number', sortable: true },
    { id: 'eventName', label: 'Event Type' },
    { id: 'eventDate', label: 'Event Date', sortable: true },
    { id: 'lastStatusUpdated', label: 'Last Updated', sortable: true },
    { id: 'statusName', label: 'Status' },
    { id: 'selection', label: 'Proceed / Revert / Cannot Claim / Skip' }
];

const ClaimTableManagement = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [PRAP, QUALITY, SUPERUSER];
    const [rows, setRows] = useState([]);
    const [rowMetaData, setRowMetaData] = useState({
        order: sortOrder,
        orderBy: 'lastStatusUpdated',
        page: 0,
        rowsPerPage: 100
    });
    const [filters, setFilters] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [claimEventStatusesTrimmed, setClaimEventStatusesTrimmed] = useState([]);
    const [selectedStatusId, setSelectedStatusId] = useState('');
    const [isStatusRevertEditorOpen, setIsStatusRevertEditorOpen] = useState(false);
    const [revertedRow, setRevertedRow] = useState({});
    const [openId, setOpenId] = useState('');
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [keys, setKeys] = useState({
        claimEventStatus: '0',
        claimEventType: '1',
        contract: '2'
    });
    const [errors, setErrors] = useState({});

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const { claimEventStatuses, claimEventTypes, contractDetails } = useSelector(
        (state) => state.entities.directusService
    );
    const { claimQueues, claimQueuesMetaData, loadingClaimQueuesSearch } = useSelector(
        (state) => state.entities.participantService
    );

    // USE EFFECTS
    useEffect(() => {
        dispatch(clearClaimQueues());
        setKeys(clearKeys(keys));
        setErrors({});
        claimEventTypes?.length < 1 && dispatch(loadClaimEventTypes());
        claimEventStatuses?.length < 1 && dispatch(loadClaimEventStatuses());
        contractDetails?.length < 1 && dispatch(loadContractDetails());
    }, []);

    useEffect(() => {
        if (
            !deepEqual(filters, {
                statusIds: claimEventStatusesTrimmed.map((el) => el.id)
            })
        ) {
            Object.keys(filters).length !== 0 &&
                dispatch(
                    searchClaimQueue(
                        filters,
                        0,
                        loadPageSize,
                        rowMetaData.orderBy,
                        rowMetaData.order
                    )
                );
            setRows([]);
        } else dispatch(clearClaimQueues());
    }, [filters]);

    useEffect(() => {
        if (claimEventStatuses?.length < 1) return;
        const newClaimEventStatusesTrimmed = claimEventStatuses.filter(
            (el) => el.name === 'Verified' || el.name === 'Claimed' || el.name === 'Paid'
        );
        setFilters((prev) => ({
            ...prev,
            statusIds: newClaimEventStatusesTrimmed.map((el) => el.id)
        }));
        setClaimEventStatusesTrimmed(newClaimEventStatusesTrimmed);
    }, [claimEventStatuses]);

    useEffect(() => {
        setSubmitDisabled(false);
        if (successMessage === 'Claim queues have been updated') {
            Object.keys(filters).length !== 0 &&
                dispatch(
                    searchClaimQueue(
                        filters,
                        rowMetaData.page,
                        loadPageSize,
                        rowMetaData.orderBy,
                        rowMetaData.order
                    )
                );
        }
    }, [successMessage]);

    useEffect(() => {
        const newRows = claimQueues.map((el) => mapClaimQueue(el));
        setRows(newRows);
    }, [claimQueues]);

    useEffect(() => {
        setSubmitDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    // HELPER FUNCTIONS

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };
    const mapClaimQueue = (el) => ({
        ...el,
        selection:
            getNameFromId(claimEventStatuses, el.statusId).toLowerCase() === 'paid'
                ? 'Skip'
                : 'Proceed',
        eventName: getNameFromId(claimEventTypes, el.eventTypeId),
        statusName: getNameFromId(claimEventStatuses, el.statusId)
    });

    const loadingError = () => {
        if (claimEventStatuses?.length < 1) return 'No claim event statuses';
        if (claimEventTypes?.length < 1) return 'No claim event types';
        if (contractDetails?.length < 1) return 'No contract details';
    };

    // EVENT HANDLERS
    const onUpdateFilters = (key, value) => {
        if (value === filters[key]) return;
        if (!value) setFilters(trimEntries([key], filters));
        else setFilters((prev) => ({ ...prev, [key]: value }));
    };

    const onUpdateStatusIds = (chosenId) => {
        setSelectedStatusId(chosenId ?? '');
        onUpdateFilters(
            'statusIds',
            !chosenId ? claimEventStatusesTrimmed.map((el) => el.id) : [chosenId]
        );
    };

    const onSearchDateRange = (searchRange) => {
        if (!searchRange) {
            if ('lastStatusUpdatedFrom' in filters && 'lastStatusUpdatedTo' in filters) {
                setFilters(trimEntries(['lastStatusUpdatedFrom', 'lastStatusUpdatedTo'], filters));
            }
        } else {
            const { startDate, endDate } = formatDateRange(searchRange);
            setFilters((prev) => ({
                ...prev,
                lastStatusUpdatedFrom: startDate,
                lastStatusUpdatedTo: endDate
            }));
        }
    };

    const onTermChange = (searchRes) => {
        if (searchRes === '' && filters.code) onUpdateFilters('code', searchRes);
        setSearchTerm(searchRes);
    };

    const onSearch = () => onUpdateFilters('code', searchTerm);

    const onRowChange = (row) => {
        setSubmitDisabled(false);
        if (row.selection === 'Revert') {
            setIsStatusRevertEditorOpen(true);
            setRevertedRow(row);
        } else {
            setRows(rows.map((el) => (el.id === row.id ? row : el)));
        }
    };

    const onStatusRevertUpdated = (revertedRow) => {
        setRows(rows.map((el) => (el.id === revertedRow.id ? revertedRow : el)));
    };

    const onToggleDropdown = (id) => setOpenId(id);

    const onStatusRevertEditorChange = (isOpen) => setIsStatusRevertEditorOpen(isOpen);

    const onSubmit = (e) => {
        e.preventDefault();
        let proceedIds = [];
        let reverts = [];
        let unclaimableIds = [];
        rows.forEach((el) => {
            if (el.selection === 'Proceed') proceedIds = [...proceedIds, el.id];
            else if (el.selection === 'Revert')
                reverts = [...reverts, { id: el.id, notes: el.notes }];
            else if (el.selection === 'CannotClaim') unclaimableIds = [...unclaimableIds, el.id];
        });
        if (proceedIds.length > 0 || reverts.length > 0 || unclaimableIds.length > 0) {
            setSubmitDisabled(true);
            dispatch(
                updateClaimQueueProgress({
                    proceedIds,
                    reverts,
                    unclaimableIds
                })
            );
        } else {
            setErrors({ button: { error: true, message: NO_UPDATE_ERROR } });
        }
    };

    // RENDER
    const createRows = () => {
        return stableSort(rows, rowMetaData.orderBy, rowMetaData.order).map((el) => (
            <ClaimTableRow
                key={el.id}
                row={el}
                roles={acceptedRoles}
                openId={openId}
                toggleDropdown={onToggleDropdown}
                onRowChange={onRowChange}
            />
        ));
    };

    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} />;

    return (
        <>
            <div className={app.pageContainer}>
                <div className={`${app.sectionHeading} ${local.headerBar}`}>Claim Queue</div>
                <form
                    onSubmit={onSubmit}
                    className={form.form}
                    data-testid="form_start_claim_table_management">
                    <div className={form.formSection}>
                        <div className={form.formColumn}>
                            <SingleSelect
                                id={'eventType'}
                                key={keys.claimEventType}
                                label={'Event Type'}
                                placeholder="Select Event Type"
                                disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                menuItems={claimEventTypes || []}
                                selectedId={filters?.eventTypeId || ''}
                                selected={
                                    claimEventTypes.find((el) => el.id === filters?.eventTypeId) ||
                                    {}
                                }
                                onChange={(chosenId) => onUpdateFilters('eventTypeId', chosenId)}
                            />

                            <DateRangePicker
                                id="lastStatusUpdated"
                                label="Last Updated"
                                onSearch={onSearchDateRange}
                            />
                        </div>
                        <div className={form.formColumn}>
                            <div>
                                <SingleSelect
                                    id={'contractId'}
                                    key={keys.contract}
                                    label={'Contract'}
                                    placeholder="Select Contract"
                                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                    menuItems={contractDetails || []}
                                    selectedId={filters?.contractId || ''}
                                    selected={
                                        contractDetails.find(
                                            (el) => el.id === filters?.contractId
                                        ) || {}
                                    }
                                    onChange={(chosenId) => onUpdateFilters('contractId', chosenId)}
                                />
                                <SingleSelect
                                    id={'statusIds'}
                                    key={keys.claimEventStatus}
                                    label={'Status'}
                                    placeholder="Select Status"
                                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                    menuItems={claimEventStatusesTrimmed || []}
                                    selectedId={selectedStatusId || ''}
                                    selected={
                                        claimEventStatusesTrimmed.find(
                                            (el) => el.id === selectedStatusId
                                        ) || {}
                                    }
                                    onChange={(chosenId) => onUpdateStatusIds(chosenId)}
                                />
                            </div>
                        </div>
                    </div>
                </form>
                <div>
                    <div className={local.claimsToolBar} data-testid="search_field">
                        <label className={form.formLabel}>Search PT ID & PO Number</label>
                        <SearchOnEnter
                            searchTerm={searchTerm}
                            placeholder="Search ID and PO..."
                            autocomplete="off"
                            search={onSearch}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            onChange={onTermChange}
                        />
                    </div>
                    <StatusRevertEditor
                        isOpen={isStatusRevertEditorOpen}
                        onStatusRevertEditorChange={onStatusRevertEditorChange}
                        revertedRow={revertedRow}
                        onStatusRevertUpdated={onStatusRevertUpdated}
                    />
                </div>
                {rows?.length < 1 ? (
                    <div>No claims found</div>
                ) : (
                    <div>
                        <ResultsTable
                            defaultOrderBy={rowMetaData.orderBy}
                            defaultRowsPerPage={rowMetaData.rowsPerPage}
                            headCells={headCells}
                            loadResults={searchClaimQueue}
                            passRowMetaDataUp={setRowMetaData}
                            apiParams={filters}
                            tableRows={createRows()}
                            totalResults={claimQueuesMetaData.totalElements}
                            metaData={claimQueuesMetaData}
                            loadingValues={loadingClaimQueuesSearch}
                            loadPageSize={loadPageSize}
                        />
                        <Button
                            id="submitButtonClaimQueues"
                            content="Submit Checked Events"
                            disabled={submitDisabled}
                            error={errors.button}
                            clearError={() => clearError('button')}
                            onClick={onSubmit}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default ClaimTableManagement;
