import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import OnExitIcon from '../../../icons/OnExitIcon';

import './panel.css';

const Panel = ({ width, children, open, onToggle }) => {
    // LOCAL REFS
    const panelRef = useRef(null);
    const panelContainerRef = useRef(null);

    //USEEFFECTS
    useEffect(() => {
        if (open) {
            if (panelRef?.current) {
                panelContainerRef?.current?.classList.add('sidePanelContainerActive');
                panelRef.current.classList.add('showPanel');
                document.body.style.overflowX = 'hidden';
                document.addEventListener('click', onClose);
            }
        }
        return () => document.removeEventListener('click', onClose);
    }, [open]);

    // HELPER FNs
    const closePanel = () => {
        document.querySelector('.sidePanelContainerActive').style.opacity = 0;
        if (panelRef.current) {
            panelRef.current.classList.remove('showPanel');
            setTimeout(() => {
                panelContainerRef?.current?.classList.remove('sidePanelContainerActive');
                onToggle(false);
            }, 300);
        }
    };

    // EVENT HANDLERS
    const onClose = (e) => {
        const panelEl = document.querySelector('.panelContent');
        const eventEl = document.querySelector('.fc');
        const actionsEl = document.querySelector('.panelActions');
        if (
            e &&
            typeof e.composedPath === 'function' &&
            !e.composedPath().includes(panelEl) &&
            !e.composedPath().includes(eventEl) &&
            !e.composedPath().includes(actionsEl)
        ) {
            closePanel();
        }
    };

    //RENDER
    return (
        <div className="sidePanelContainer" ref={panelContainerRef}>
            <div className="sidePanel" ref={panelRef}>
                <div className="panel">
                    <div className="panelExit">
                        <OnExitIcon onClick={closePanel} title="Cancel" />
                    </div>
                    <div className="panelContent" style={{ width: width }}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

Panel.propTypes = {
    width: PropTypes.string,
    children: PropTypes.any,
    togglePanel: PropTypes.bool,
    open: PropTypes.bool,
    onToggle: PropTypes.func
};

export default Panel;
