import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, Divider, Link, Stack } from '@mui/material';

import { loadParticipantDocumentDetails } from '../../../../store/documentService';
import { setErrorMessage } from '../../../../store/formsState';
import { loadParticipant } from '../../../../store/participantService';
import { createVacancySubmissions } from '../../../../store/recruitmentService';
import { loadUsersByServiceIds } from '../../../../store/userService';
import { SCREEN_SETTINGS } from '../../../../themes/theme';
import { getNameFromId } from '../../../../utils/directusFunctions';
import { NO_UPDATE_ERROR } from '../../../../utils/formValidation/commonErrorConstants';
import { JOB_SECTOR_DETAILS_ERROR } from '../../../../utils/formValidation/loadingErrorMessageConstants';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import SubmissionsRejectEditor from './SubmissionsRejectEditor';
import SubmissionsTable from './SubmissionsTable';

import form from '../../../../commonStyles/formStyles.module.css';

const MORE_JOBS_OFFERED_THAN_VACANCIES = 'More jobs offered than current vacancies.' + '';
const SubmissionsTableManagement = ({
    accordionPanelFrom,
    vacancyStageIdsWithReject,
    jobsRemaining
}) => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const acceptedRoles = [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const message = `Vacancy submissions have been re-added`;
    const [updatedRows, setUpdatedRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [rejectedRow, setRejectedRow] = useState({});
    const [rowCancelled, setRowCancelled] = useState(false);
    const [poolSubmissionsRejectEditor, setPoolSubmissionsRejectEditor] = useState(false);
    const [vacancySubmissionsLoaded, setVacancySubmissionsLoaded] = useState(false);

    // STORE STATE
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const jobSectorDetails = useSelector(
        (state) => state.entities.directusService.jobSectorDetails
    );
    const vacancyStageDetails = useSelector(
        (state) => state.entities.directusService.vacancyStageDetails
    );
    const participantDocuments = useSelector(
        (state) => state.entities.documentService.participantDocuments
    );
    const currentVacancy = useSelector((state) => state.entities.recruitmentService.currentVacancy);
    const vacancySubmissions = useSelector(
        (state) => state.entities.recruitmentService.vacancySubmissions
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS
    useEffect(() => {
        if (!('serviceIds' in loggedInUser)) return;
        loggedInUser.serviceIds.length > 0 &&
            dispatch(loadUsersByServiceIds(loggedInUser.serviceIds));
    }, [loggedInUser.id]);

    useEffect(() => {
        if (vacancySubmissions?.length < 1) {
            setRows([]);
            setFilteredRows([]);
            setVacancySubmissionsLoaded(true);
            return;
        }
        setVacancySubmissionsLoaded(true);

        const participantIds = [...new Set(vacancySubmissions.map((el) => el.participantId))];
        participantIds.map((el) => {
            if (el) {
                dispatch(loadParticipant(el));
                dispatch(loadParticipantDocumentDetails(el));
            }
        });
        const updatedVacancySubmissions = vacancySubmissions.map((el) => ({
            ...el,
            filename: '',
            ptCode: '',
            ptName: '',
            statusName: getNameFromId(vacancyStageDetails, el.statusId),
            stageIds: vacancyStageIdsWithReject
        }));
        setRows(updatedVacancySubmissions);
        setFilteredRows(updatedVacancySubmissions);
        setUpdatedRows(updatedVacancySubmissions);
    }, [vacancySubmissions]);

    useEffect(() => {
        if (Object.keys(currentParticipant)?.length < 1 || rows.length < 1) return;

        rows.map((el) => {
            if (el.participantId === currentParticipant.id) {
                el.ptCode = currentParticipant.ptCode;
                el.ptName = `${currentParticipant.firstName} ${currentParticipant.lastName}`;
            }
        });
        setFilteredRows(rows);
        setUpdatedRows(rows);
    }, [currentParticipant]);

    useEffect(() => {
        if (participantDocuments?.length < 1) return;

        rows.map((el) => {
            participantDocuments.map((entry) => {
                if (el.participantId === entry.participantId && el.goldStandardCvId === entry.id)
                    el.filename = entry.filename;
            });
        });
        setFilteredRows(rows);
        setUpdatedRows(rows);
    }, [participantDocuments]);

    useEffect(() => {
        if (successMessage === `Pool applications have been loaded`) {
            setVacancySubmissionsLoaded(true);
        } else if (successMessage === `Vacancy submissions have been re-added`) {
            onNavigate();
        }
    }, [successMessage]);

    // HELPER FNS
    const loadingError = () => {
        if (jobSectorDetails?.length < 1) return JOB_SECTOR_DETAILS_ERROR;
        if (!vacancySubmissionsLoaded) return 'vacancy submissions not loaded';
    };

    // EVENT HANDLERS
    const onNavigate = () => {
        if (!accordionPanelFrom) {
            navigate('/recruitment_management', {
                state: { accordionPanel: 'vacancies' }
            });
        } else {
            navigate('/recruitment_management', {
                state: { accordionPanel: accordionPanelFrom }
            });
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const changedRows = updatedRows.filter((el) =>
            filteredRows.find((entry) => entry.id === el.id && entry.statusId !== el.statusId)
        );
        const vacancySubmissionIds = changedRows.map((el) => ({
            participantId: el.participantId,
            statusId: el.statusId,
            goldStandardCvId: el.goldStandardCvId
        }));
        const statusId =
            vacancyStageDetails.find((el) => el.name.toLowerCase() === 'job offered')?.id || '';

        if (vacancySubmissionIds?.length < 1) {
            dispatch(setErrorMessage(NO_UPDATE_ERROR));
        } else if (
            vacancySubmissionIds.filter((el) => el.statusId === statusId).length > jobsRemaining
        ) {
            dispatch(setErrorMessage(MORE_JOBS_OFFERED_THAN_VACANCIES));
        } else {
            const payload = { submissions: vacancySubmissionIds };
            dispatch(createVacancySubmissions(payload, currentVacancy.id, message));
            setFilteredRows(updatedRows);
        }
    };

    // EVENT HANDLER CALLBACKS
    const onUpdateParticipantStatus = (statusId, participantId, goldStandardCvId) => {
        const modified = updatedRows.map((el) =>
            el.participantId === participantId && el.goldStandardCvId === goldStandardCvId
                ? { ...el, statusId }
                : el
        );
        setUpdatedRows(modified);
    };

    const onSubmissionsRejectEditor = (setOpen) => {
        setPoolSubmissionsRejectEditor(setOpen);
    };

    const onSubmissionsRejectCancelled = (cancelled, rejectedRow) => {
        if (cancelled) {
            const resetRows = rows.filter((el) => el.id !== rejectedRow.id);
            setRows([...resetRows, rejectedRow]);
            setFilteredRows([...resetRows, rejectedRow]);
            setUpdatedRows([...resetRows, rejectedRow]);
            setRowCancelled(true);
        } else {
            setRowCancelled(false);
        }
    };

    const onSubmissionsRejectedRow = (row) => {
        setRejectedRow(row);
        setRowCancelled(false);
    };

    const onPoolRejectedUpdate = (update) => {
        setVacancySubmissionsLoaded(update);
    };

    // AWAITING CONTENT
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} />;

    // RENDER
    return (
        <>
            <form onSubmit={onSubmit} data-testid="form_start" className={form.form}>
                <div>
                    <SubmissionsRejectEditor
                        open={poolSubmissionsRejectEditor}
                        onSubmissionsRejectEditor={onSubmissionsRejectEditor}
                        onSubmissionsRejectCancelled={onSubmissionsRejectCancelled}
                        rejectedRow={rejectedRow}
                        onPoolRejectedUpdate={onPoolRejectedUpdate}
                    />
                </div>

                {filteredRows.length < 1 ? (
                    <LoadingSpinner content="No vacancy submissions found" />
                ) : (
                    <SubmissionsTable
                        rows={filteredRows}
                        roles={loggedInUser.roles}
                        onUpdateParticipantStatus={onUpdateParticipantStatus}
                        onSubmissionsRejectEditor={onSubmissionsRejectEditor}
                        onSubmissionsRejectedRow={onSubmissionsRejectedRow}
                        rowCancelled={rowCancelled}
                    />
                )}
                <Divider sx={{ marginTop: '40px', marginBottom: '20px' }} />
                <Stack direction="row" spacing={4}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={
                            !hasRole(acceptedRoles, loggedInUser.roles) || jobsRemaining === 0
                        }
                        data-testid="testIdSubmitButton1"
                        endIcon={<KeyboardArrowRightIcon />}>
                        {'Update Submissions'}
                    </Button>
                    <Link
                        label="Cancel X"
                        underline="always"
                        variant="body1"
                        color="primary"
                        onClick={() => onNavigate()}
                        sx={{
                            paddingTop: SCREEN_SETTINGS.margin.large,
                            cursor: 'pointer'
                        }}>
                        Cancel X
                    </Link>
                </Stack>
            </form>
        </>
    );
};

SubmissionsTableManagement.propTypes = {
    accordionPanelFrom: PropTypes.string,
    vacancyStageIdsWithReject: PropTypes.array.isRequired,
    jobsRemaining: PropTypes.number
};

export default SubmissionsTableManagement;
