import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { loadJobSectorDetails, loadVacancyStageDetails } from '../../../store/directusService';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import { getNameFromId } from '../../../utils/directusFunctions';
import {
    CURRENT_BUSINESS_ERROR,
    JOB_SECTOR_DETAILS_ERROR,
    VACANCY_STAGE_DETAILS_ERROR
} from '../../../utils/formValidation/loadingErrorMessageConstants';
import RadioButtons from '../../formElements/RadioButtons';
import TextAreaField from '../../formElements/TextAreaField';
import TextInputField from '../../formElements/TextInputField';
import FormActionsCancel from '../../ui/formActions/FormActionsCancel';
import LoadingSpinner from '../../ui/LoadingSpinner';
import BusinessContactCard from '../businesses/cards/BusinessContactCard';

import app from '../../../app.module.css';
import cardClasses from '../../../commonStyles/cardContainer.module.css';
import form from '../../../commonStyles/formStyles.module.css';

const VacancyManagement = ({ vacancy, accordionPanelFrom, jobsOfferedForVacancies }) => {
    // HOOKS
    const dispatch = useDispatch();
    const { state } = useLocation();
    const navigate = useNavigate();

    // LOCAL STATE
    const [jobsOfferedForVacancy, setJobsOfferedForVacancy] = useState(0);

    // STORE STATE
    const jobSectorDetails = useSelector(
        (state) => state.entities.directusService.jobSectorDetails
    );
    const vacancyStageDetails = useSelector(
        (state) => state.entities.directusService.vacancyStageDetails
    );
    const currentBusiness = useSelector(
        (state) => state.entities.recruitmentService.currentBusiness
    );

    // USE EFFECTS
    useEffect(() => {
        jobSectorDetails?.length < 1 && dispatch(loadJobSectorDetails());
        vacancyStageDetails?.length < 1 && dispatch(loadVacancyStageDetails());

        if (!jobsOfferedForVacancies) setJobsOfferedForVacancy(vacancy.jobsOffered);
        else
            setJobsOfferedForVacancy(
                jobsOfferedForVacancies?.find((entry) => entry.vacancyId === vacancy.id)
                    ?.jobsOffered || 0
            );
    }, []);

    // HELPER FNS
    const loadingError = () => {
        if (jobSectorDetails?.length < 1) return JOB_SECTOR_DETAILS_ERROR;
        if (vacancyStageDetails?.length < 1) return VACANCY_STAGE_DETAILS_ERROR;
        if (Object.keys(currentBusiness).length < 1) return CURRENT_BUSINESS_ERROR;
    };

    const onNavigate = () => {
        if (!state?.accordionPanelFrom) {
            navigate('/recruitment_management', {
                state: { accordionPanel: 'myBusinesses' }
            });
        } else {
            navigate('/recruitment_management', {
                state: { accordionPanel: accordionPanelFrom }
            });
        }
    };

    // AWAITING CONTENT
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} />;

    // RENDER
    return (
        <div className={form.formWrapper}>
            <form data-testid="form_start" className={form.form}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'businessRecord'}
                            label={'Business Record'}
                            disabled={true}
                            value={currentBusiness.name}
                        />
                        <TextInputField
                            id={'title'}
                            label={'Pay Rate (£)'}
                            disabled={true}
                            value={`${vacancy.payRateFrom} - ${vacancy.payRateTo}`}
                        />
                        <TextInputField
                            label={'Expected Weekly Hours'}
                            id={'title'}
                            disabled={true}
                            value={vacancy.expectedWeeklyHours}
                        />
                        <TextInputField
                            id={'title'}
                            label={'Anticipated Start Date'}
                            disabled={true}
                            value={reverseFormatDate(vacancy.anticipatedStartDate)}
                        />
                        <TextInputField
                            id="vacancyClosingDate"
                            label="Vacancy Closing Date"
                            disabled={true}
                            value={reverseFormatDate(vacancy.vacancyClosingDate)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <h2 className={app.sectionHeading}>Hiring Manager</h2>
                        <div className={cardClasses.cards}>
                            <BusinessContactCard
                                entry={currentBusiness.contactCards.find(
                                    (entry) => entry.id === vacancy.hiringManagerId
                                )}
                                roles={[]}
                                acceptedRoles={[]}
                            />
                        </div>
                    </div>
                </div>
                <TextAreaField
                    id={'description'}
                    label={'Description'}
                    disabled={true}
                    multiline
                    value={vacancy.description}
                />
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextAreaField
                            id={'requirements'}
                            label={'Requirements'}
                            disabled={true}
                            multiline
                            value={vacancy.requirement}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <TextAreaField
                            id={'shiftPattern'}
                            label={'Shift Patterns/Working Hours'}
                            disabled={true}
                            multiline
                            value={vacancy.shiftPatternWorkingHours}
                        />
                    </div>
                </div>
                <RadioButtons label={'Remote?'} disabled={true} value={vacancy.remote} />
                <div hidden={vacancy.remote}>
                    <div className={form.formSection}>
                        <div className={form.formColumn}>
                            <TextInputField
                                id={'address1'}
                                label={'Address Line 1'}
                                disabled={true}
                                value={vacancy.addressLine1}
                            />
                            <TextInputField
                                id={'address2'}
                                label={'Address Line 2'}
                                disabled={true}
                                value={vacancy.addressLine2 || ''}
                            />
                            <TextInputField
                                id={'address3'}
                                label={'Address Line 3'}
                                disabled={true}
                                value={vacancy.addressLine3 || ''}
                            />
                        </div>
                        <div className={form.formColumn}>
                            <TextInputField
                                id={'city'}
                                label={'City'}
                                disabled={true}
                                value={vacancy.city}
                            />
                            <TextInputField
                                id={'postcode'}
                                label={'Postcode'}
                                disabled={true}
                                value={vacancy.postcode}
                            />
                        </div>
                    </div>
                </div>
                <h2 className={app.sectionHeading}>Application Process</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        {vacancy.stageIds.map((el, i) => (
                            <div key={i}>
                                <div className={form.inputSet}>
                                    <div className={form.inputSetInput}>
                                        <TextInputField
                                            id={'stageId'}
                                            label={
                                                i < vacancy.stageIds.length - 1
                                                    ? `Stage ${i + 1}`
                                                    : 'Final Stage'
                                            }
                                            name={'stageId'}
                                            disabled={true}
                                            value={getNameFromId(vacancyStageDetails, el)}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'jobSector'}
                            label={'Job Sector'}
                            disabled={true}
                            value={getNameFromId(jobSectorDetails, vacancy.sectorId)}
                        />
                        <TextInputField
                            id={'positions'}
                            label={'Number of Available Positions'}
                            disabled={true}
                            value={`${vacancy.numberOfPositions - jobsOfferedForVacancy} / ${
                                vacancy.numberOfPositions
                            }`}
                        />
                    </div>
                </div>

                <FormActionsCancel onCancel={onNavigate} />
            </form>
        </div>
    );
};

VacancyManagement.propTypes = {
    vacancy: PropTypes.object,
    accordionPanelFrom: PropTypes.string,
    jobsOfferedForVacancies: PropTypes.array,
    jobsOffered: PropTypes.number
};

export default VacancyManagement;
