import { compareUnorderedArrays } from '../../../utils/arrayUtils';
import { isDateAfter, isDateEarlier, isDatePast, isTimePast } from '../../../utils/dateFunctions';
import { NO_UPDATE_ERROR } from '../../../utils/formValidation/commonErrorConstants';
import {
    getCharMinMessage,
    getDateMaxMessage,
    getNowDateOrEarlierMessage,
    getNowDateOrLaterMessage,
    getSameDateOrLater
} from '../../../utils/formValidation/errorMessageFunctions';

export const MIN_ACTION_TITLE = 5;
export const MIN_ACTION = 50;
export const MAX_ACTION_DESCRIPTION = 750;
export const MAX_ACTION_COMMENTS = 500;
export const MAX_ACTION_TITLE = 100;

export const validate = (newEntry, disabled, contractEndDate, type, initialStateOnEntry) => {
    let newErrors = {};

    if (!disabled.actionTitle && newEntry.actionTitle.trim().length < MIN_ACTION_TITLE) {
        newErrors = {
            ...newErrors,
            actionTitle: {
                error: true,
                message: getCharMinMessage('Action title', MIN_ACTION_TITLE)
            }
        };
    }

    if (!disabled.action && newEntry.action.trim().length < 50) {
        newErrors = {
            ...newErrors,
            action: {
                error: true,
                message: getCharMinMessage('Action description', MIN_ACTION)
            }
        };
    }

    if (!disabled.actionDueDate && newEntry.actionDueDate) {
        if (isDatePast(newEntry.actionDueDate)) {
            newErrors = {
                ...newErrors,
                actionDueDate: { error: true, message: getNowDateOrLaterMessage() }
            };
        } else if (isDateEarlier(newEntry.actionStartDate, newEntry.actionDueDate)) {
            newErrors = {
                ...newErrors,
                actionDueDate: {
                    error: true,
                    message: getSameDateOrLater('start date')
                }
            };
        } else if (isDateAfter(newEntry.actionDueDate, contractEndDate)) {
            newErrors = {
                ...newErrors,
                actionDueDate: {
                    error: true,
                    message: getDateMaxMessage('contract end date')
                }
            };
        }
    }

    if (!disabled.actionOutcomeDate && newEntry.actionOutcomeDate) {
        if (!isTimePast(newEntry.actionOutcomeDate)) {
            newErrors = {
                ...newErrors,
                actionOutcomeDate: {
                    error: true,
                    message: getNowDateOrEarlierMessage()
                }
            };
        } else if (isDateEarlier(newEntry.actionStartDate, newEntry.actionOutcomeDate)) {
            newErrors = {
                ...newErrors,
                actionOutcomeDate: {
                    error: true,
                    message: getSameDateOrLater('start date')
                }
            };
        }
    }

    if (type === 'edit' && Object.keys(newErrors).length < 1)
        newErrors = checkDiff(newEntry, initialStateOnEntry, newErrors);

    return newErrors;
};

const checkDiff = (newEntry, initialStateOnEntry, errors) => {
    if (compareUnorderedArrays(Object.values(newEntry), Object.values(initialStateOnEntry))) {
        return {
            ...errors,
            button: { error: true, message: NO_UPDATE_ERROR }
        };
    }
    return errors;
};
