import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import { TableCell, TableRow } from '@mui/material';

import OnEditIcon from '../../../icons/OnEditIcon';
import { updateParticipantBarrier } from '../../../store/participantService';
import { reverseFormatDate } from '../../../utils/dateFunctions';

import table from '../../../commonStyles/tables.module.css';
import local from './barriers.module.css';

const BarrierTableRow = ({ row, onEditBarrier, roles, acceptedRoles, disabled }) => {
    const dispatch = useDispatch();

    const currentParticipantBarriers = useSelector(
        (state) => state.entities.participantService.currentParticipantBarriers
    );

    const onCompleted = (row) => {
        if (disabled) return;
        const currentRow = currentParticipantBarriers.find((el) => el.id === row.id);
        const updatedRow = {
            ...currentRow,
            completeDate: currentRow.completeDate ? '' : new Date().toISOString()
        };
        dispatch(updateParticipantBarrier(updatedRow));
    };

    return (
        <>
            <TableRow hover className={table.basicRowStripes}>
                <TableCell>{row.barrier}</TableCell>
                <TableCell>
                    {row.startDate === '1960-01-01' ? ' - ' : reverseFormatDate(row.startDate)}
                </TableCell>
                <TableCell>
                    {' '}
                    {row.dueDate === '1960-01-01' ? ' - ' : reverseFormatDate(row.dueDate)}{' '}
                </TableCell>
                <TableCell>
                    {' '}
                    {row.completeDate === '1960-01-01'
                        ? ' - '
                        : reverseFormatDate(row.completeDate)}{' '}
                </TableCell>
                <TableCell>
                    <span
                        className={`${row.priority === 'High' ? `${local.highPriority}` : ''} ${disabled ? local.disabledRow : ''}`}>
                        {row.priority || ' - '}{' '}
                        {row.priority === 'High' && <ErrorIcon titleAccess={'error-icon'} />}
                    </span>
                </TableCell>
                <TableCell onClick={() => onCompleted(row)}>
                    <span className={`${disabled ? local.disabledRow : table.checkBox}`}>
                        {row.completeDate !== '1960-01-01' ? (
                            <CheckBoxOutlinedIcon titleAccess={'checkbox-ticked'} />
                        ) : (
                            <CheckBoxOutlineBlankOutlinedIcon titleAccess={'checkbox-unticked'} />
                        )}
                    </span>
                </TableCell>
                <TableCell>
                    <OnEditIcon
                        onEdit={() => onEditBarrier(row)}
                        roles={roles}
                        acceptedRoles={acceptedRoles}
                        active={!disabled}
                    />
                </TableCell>
            </TableRow>
        </>
    );
};

BarrierTableRow.propTypes = {
    row: PropTypes.object,
    onEditBarrier: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    acceptedRoles: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool
};

export default BarrierTableRow;
