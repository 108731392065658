import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

//import RestoreIcon from '@mui/icons-material/Restore';
import Button from '../../formElements/Button';
import InitialAppointmentPONumberNotice from '../../ui/notices/initialAppointmentPONumber/InitialAppointmentPONumberNotice';

import app from '../../../app.module.css';
import styles from '../styles/startAppointmentButton.module.css';

// TODO - THESE COMMENTS ARE INTENTIONALLY LEFT IN AS WORK IS DONE FOR UPCOMING REQUIREMENT
const AttendanceRow = ({
    isDisabled = false,
    // isAuditable,
    // onSearchAuditItems,
    attendanceStatus,
    appointmentType,
    isPONumberRequired,
    participant,
    onNav,
    onStartAppointment
}) => {
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        setShowWarning(
            appointmentType === 'initial' &&
                !participant.poNumber &&
                isPONumberRequired &&
                !isDisabled
        );
    }, [isPONumberRequired, participant, appointmentType, isDisabled]);

    return (
        <>
            <div
                className={`${styles.appointmentButtonRow} ${styles.startBtnAlign} ${app.fadein}`}
                data-testid={`attendance-row-${participant.id}`}>
                <span
                    className={`${styles.rowName} ${!participant?.hidden ? styles.rowNameLink : ''}`}
                    onClick={onNav}>
                    {`${participant?.firstName ?? participant?.hidden} ${participant?.lastName ?? ''}`}
                </span>
                <span className={styles.rowButton}>
                    <Button
                        id="startAppointmentButton"
                        testId={`start_appointment_button_${participant?.id}`}
                        disabled={isDisabled}
                        content="Start Appointment"
                        onClick={() => onStartAppointment(showWarning, participant)}
                    />
                </span>
                <span className={styles.rowStatus}>{attendanceStatus}</span>
                {/*{isAuditable && (*/}
                {/*    <div className={styles.auditIcon}>*/}
                {/*        <RestoreIcon onClick={onSearchAuditItems} />*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>
            {showWarning && <div>{InitialAppointmentPONumberNotice()}</div>}
        </>
    );
};

AttendanceRow.propTypes = {
    isDisabled: PropTypes.bool,
    // isAuditable: PropTypes.bool,
    // onSearchAuditItems: PropTypes.func,
    attendanceStatus: PropTypes.string,
    appointmentType: PropTypes.string,
    isPONumberRequired: PropTypes.bool,
    participant: PropTypes.object,
    participantAppointmentId: PropTypes.string,
    onNav: PropTypes.func,
    onStartAppointment: PropTypes.func
};

export default AttendanceRow;
