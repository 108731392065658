import React from 'react';
import PropTypes from 'prop-types';

import EditIcon from '@mui/icons-material/Edit';
import { Tooltip } from '@mui/material';

import { POLARIS_DARKER_GREY, POLARIS_ROYAL_BLUE } from '../themes/theme';
import { hasRole } from '../utils/userRoles';

const OnEditIcon = ({
    onEdit,
    roles = [],
    acceptedRoles = [],
    active = true,
    tooltip = 'Edit',
    inactiveMessage = 'Not Active'
}) => {
    if (
        ((acceptedRoles.length === 0 && roles.length === 0) || hasRole(roles, acceptedRoles)) &&
        active
    ) {
        return (
            <Tooltip title={tooltip} placement="top">
                <EditIcon
                    sx={{ color: POLARIS_ROYAL_BLUE, cursor: 'pointer' }}
                    aria-label="edit-icon"
                    data-testid="edit-icon"
                    onClick={onEdit}
                />
            </Tooltip>
        );
    }
    return (
        <Tooltip title={inactiveMessage} placement="top">
            <EditIcon
                sx={{ color: POLARIS_DARKER_GREY, cursor: 'not-allowed' }}
                aria-label="edit-icon"
            />
        </Tooltip>
    );
};

export default OnEditIcon;

OnEditIcon.propTypes = {
    onEdit: PropTypes.func,
    active: PropTypes.bool,
    tooltip: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    acceptedRoles: PropTypes.arrayOf(PropTypes.string),
    activeColor: PropTypes.string,
    inactiveMessage: PropTypes.string
};
