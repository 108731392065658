import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { reverseFormatDate } from '../../../../../utils/dateFunctions';
import { getNameFromId } from '../../../../../utils/directusFunctions';

import classes from '../../../../../commonStyles/pdf.module.css';

const FinancialRequestTable = ({ onLoaded }) => {
    // LOCAL STATE
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState('true');

    // STORE STATE
    const financialRequests = useSelector(
        (state) => state.entities.participantService.participantFinancialRequests
    );
    const loadingFinancialRequests = useSelector(
        (state) => state.entities.participantService.loadingFinancialRequests
    );
    const financialRequestTypeDetails = useSelector(
        (state) => state.entities.directusService.financialRequestTypeDetails
    );
    const financialRequestStatusDetails = useSelector(
        (state) => state.entities.directusService.financialRequestStatusDetails
    );
    const users = useSelector((state) => state.entities.userService.users);

    // USE EFFECTS
    useEffect(() => {
        if (!loadingFinancialRequests && loading === 'true') setLoading('startLoading');
        if (!loadingFinancialRequests && loading === 'startLoading') setLoading('false');
    }, [loadingFinancialRequests]);

    useEffect(() => {
        if (financialRequests?.length < 1 && loading === 'false') {
            onLoaded('financialRequest');
            return;
        }
        if (
            financialRequestTypeDetails?.length < 1 ||
            financialRequestStatusDetails?.length < 1 ||
            users?.length < 1
        )
            return;
        const data = financialRequests.map((el) => {
            const user = users.find((entry) => entry.id === el.approverId);
            return {
                code: el.code,
                type: getNameFromId(financialRequestTypeDetails, el?.requestTypeId) || ' - ',
                amount: el?.amount || ' - ',
                status: getNameFromId(financialRequestStatusDetails, el?.statusId) || ' - ',
                statusChangeDate: el?.statusChangeDate
                    ? reverseFormatDate(el?.statusChangeDate)
                    : '',
                dateIssued: el?.dateIssued ? reverseFormatDate(el?.dateIssued) : '',
                approver:
                    user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : ' - '
            };
        });
        setRows(data);
    }, [
        financialRequests,
        financialRequestTypeDetails,
        financialRequestStatusDetails,
        users,
        loading
    ]);

    useEffect(() => {
        rows.length > 0 && onLoaded('financialRequest');
    }, [rows]);

    // RENDER
    return (
        <div>
            <h3>Financial Request</h3>
            {rows.length < 1 ? (
                'No financial requests'
            ) : (
                <div className={classes.pdfTable}>
                    <div className={classes.pdfTableHeaders}>
                        <span>ID</span>
                        <span>Type</span>
                        <span>Amount</span>
                        <span className={classes.alignLeftMinusMin}>Status Change Date</span>
                        <span>Status</span>
                        <span>Date Issued</span>
                        <span>Authoriser</span>
                    </div>

                    <div className={classes.pdfTableRows}>
                        {rows.map((el, i) => (
                            <div
                                key={el.code}
                                id={`${el.code}--${i}`}
                                className={`${classes.pdfTableRow} ${
                                    i % 2 === 0 ? classes.rowPlain : classes.rowFilled
                                }`}>
                                <span>{el.code}</span>
                                <span>{el.type}</span>
                                <span>{el.amount}</span>
                                <span
                                    className={`${
                                        !el.statusChangeDate ? classes.alignCenter : ''
                                    }`}>
                                    {el.statusChangeDate || ' - '}
                                </span>
                                <span>{el.status}</span>
                                <span className={`${!el.dateIssued ? classes.alignCenter : ''}`}>
                                    {el.dateIssued || ' - '}
                                </span>
                                <span>{el.approver}</span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

FinancialRequestTable.propTypes = {
    onLoaded: PropTypes.func
};

export default FinancialRequestTable;
