import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../../../api/pagination';
import { loadJobSectorDetails, loadVacancyStageDetails } from '../../../../store/directusService';
import {
    loadBusinessRecords,
    loadVacanciesByOwners,
    loadVacancySubmissionsByVacancyIdForVacancy
} from '../../../../store/recruitmentService';
import { searchUsersByLoggedInUserServiceIds } from '../../../../store/userService';
import { getNameFromId } from '../../../../utils/directusFunctions';
import {
    JOB_SECTOR_DETAILS_ERROR,
    VACANCY_STAGE_DETAILS_ERROR
} from '../../../../utils/formValidation/loadingErrorMessageConstants';
import { clearKeys } from '../../../../utils/objectUtils';
import { addEmailAddressAsNameToArray } from '../../../../utils/userArrayUtils';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import MultiSelect from '../../../formElements/MultiSelect';
import DynamicSearch from '../../../search/DynamicSearch';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import VacancyTable from './VacancyTable';

import classes from '../../recruitmentStyles/vacancy.module.css';

const VacancyTableManagement = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [activeVacancies, setActiveVacancies] = useState([]);
    const [rows, setRows] = useState([]);
    const [vacancyOwners, setVacancyOwners] = useState([]);
    const [vacancyOwnersWithJobsOffered, setVacancyOwnersWithJobsOffered] = useState([]);
    const [arrayJobSectors, setArrayJobSectors] = useState([]);
    const [preSelectedJobSectorIds, setPreSelectedJobSectorIds] = useState([]);
    const [preSelectedVacancyOwnerIds, setPreSelectedVacancyOwnerIds] = useState([]);
    const [selectedJobSectors, setSelectedJobSectors] = useState([]);
    const [selectedVacancyOwnerIds, setSelectedVacancyOwnerIds] = useState([]);
    const [preSelectedVacancyOwners, setPreSelectedVacancyOwners] = useState([]);
    const [businessesForVacancies, setBusinessesForVacancies] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const [vacancyCount, setVacancyCount] = useState(0);
    const [vacancyProcessedCount, setVacancyProcessedCount] = useState(0);
    const [vacancyProcessed, setVacancyProcessed] = useState(false);

    const [keys, setKeys] = useState({ sectorIds: '0', ownerIds: '1' });

    // STORE STATE
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const businesses = useSelector((state) => state.entities.recruitmentService.businesses);
    const jobSectorDetails = useSelector(
        (state) => state.entities.directusService.jobSectorDetails
    );
    const vacancyStageDetails = useSelector(
        (state) => state.entities.directusService.vacancyStageDetails
    );
    const businessesMetaData = useSelector(
        (state) => state.entities.recruitmentService.businessesMetaData
    );
    const vacanciesForOwners = useSelector(
        (state) => state.entities.recruitmentService.vacanciesForOwners
    );
    const vacancySubmissionsForVacancy = useSelector(
        (state) => state.entities.recruitmentService.vacancySubmissionsForVacancy
    );
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);
    const userMetaData = useSelector(
        (state) => state.entities.userService.usersByLoggedInUserServiceIdsMetaData
    );
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);

    // USE EFFECTS
    useEffect(() => {
        setKeys(clearKeys(keys));
        businesses?.length < 1
            ? dispatch(loadBusinessRecords(0, DEFAULT_PAGE_LOAD_SIZE))
            : setBusinessesForVacancies(businesses);
        jobSectorDetails?.length < 1 && dispatch(loadJobSectorDetails());
        vacancyStageDetails?.length < 1 && dispatch(loadVacancyStageDetails());
        vacanciesForOwners?.length < 1 && dispatch(loadVacanciesByOwners());
    }, []);

    useEffect(() => {
        if (!loggedInUser.roles.includes(QUALITY) && !loggedInUser.roles.includes(SUPERUSER)) {
            setPreSelectedVacancyOwnerIds([loggedInUser.id]);
            const newVacancyOwnersArray = addEmailAddressAsNameToArray([loggedInUser]);
            setPreSelectedVacancyOwners(newVacancyOwnersArray);
            setVacancyOwners(newVacancyOwnersArray);
        }
    }, [loggedInUser.id]);

    useEffect(() => {
        if (businesses?.length < 1) return;

        if (!businessesMetaData.last) {
            dispatch(loadBusinessRecords(businessesMetaData.number + 1, DEFAULT_PAGE_LOAD_SIZE));
        } else setBusinessesForVacancies(businesses);
    }, [businesses, businessesMetaData]);

    useEffect(() => {
        if (vacanciesForOwners?.length < 1) return;

        vacanciesForOwners.map((el) => {
            dispatch(loadVacancySubmissionsByVacancyIdForVacancy(el.id));
            setVacancyCount((vacancyCount) => vacancyCount + 1);
        });
    }, [vacanciesForOwners]);

    useEffect(() => {
        if (!users.length) return;
        let updatedVacancyOwners = users.filter((el) =>
            el.userTypes?.find(
                (entry) => entry.role === MANAGER || entry.role === RECRUITMENT_MANAGER
            )
        );
        if (!updatedVacancyOwners.length && users.length < userMetaData.totalElements) {
            onLoadMoreVacancyOwners();
            return;
        }
        if (
            selectedVacancyOwnerIds.length &&
            !updatedVacancyOwners.some((el) => selectedVacancyOwnerIds.includes(el.id))
        ) {
            // Put selected vacancy owners at the top of dropdown if all of them are not in the updated vacancy owners array
            const selectedVacancyOwners = vacancyOwners.filter((el) =>
                selectedVacancyOwnerIds.includes(el.id)
            );
            const remainingVacancyOwners = updatedVacancyOwners.filter(
                (el) => !selectedVacancyOwnerIds.includes(el.id)
            );
            updatedVacancyOwners = [...selectedVacancyOwners, ...remainingVacancyOwners];
        }
        setVacancyOwners(addEmailAddressAsNameToArray(updatedVacancyOwners));
    }, [users]);

    useEffect(() => {
        if (businessesForVacancies.length < 1) return;
        if (vacanciesForOwners && users.length) {
            if (vacanciesForOwners?.length < 1 || !vacancyProcessed) {
                setActiveVacancies([]);
                setRows([]);
                return;
            }
            const uniqueVacancies = [...new Set(vacancyOwnersWithJobsOffered.map((el) => el))];
            const activeOwnerVacancies = uniqueVacancies
                .filter(
                    (el) =>
                        selectedVacancyOwnerIds.some((v) => v === el.ownerId) &&
                        selectedJobSectors.some((v) => v === el.sectorId)
                )
                .map((el) => ({
                    ...el,
                    businessName: businessesForVacancies.find(
                        (entry) => entry.id === el.businessRecordId
                    )?.name,
                    adviser:
                        users.find((entry) => entry.id === el?.ownerId)?.firstName +
                        ' ' +
                        users.find((entry) => entry.id === el?.ownerId)?.lastName
                }));
            setActiveVacancies(activeOwnerVacancies);
            setRows(activeOwnerVacancies);
        }
    }, [
        vacanciesForOwners,
        users,
        selectedVacancyOwnerIds,
        selectedJobSectors,
        vacancyProcessed,
        businessesForVacancies
    ]);

    useEffect(() => {
        if (jobSectorDetails?.length && arrayJobSectors?.length === 0) {
            setPreSelectedJobSectorIds(jobSectorDetails.map((el) => el.id));
            setArrayJobSectors(jobSectorDetails);
        }
    }, [jobSectorDetails]);

    useEffect(() => {
        if (successMessage.includes('Vacancy record has been deleted')) {
            setVacancyProcessedCount(0);
            setVacancyOwnersWithJobsOffered([]);
        } else if (
            successMessage.includes('Vacancy submissions for vacancy have been loaded vacancy id')
        ) {
            const vacancyId = successMessage.substring(61, 103);
            const jobsOffered = Array.isArray(vacancySubmissionsForVacancy)
                ? vacancySubmissionsForVacancy.reduce(
                      (acc, cur) =>
                          acc +
                          (getNameFromId(vacancyStageDetails, cur.statusId) === 'Job offered'
                              ? 1
                              : 0),
                      0
                  )
                : 0;
            const updatedRow = vacanciesForOwners.find((el) => el.id === vacancyId);
            setVacancyOwnersWithJobsOffered((prev) => [...prev, { ...updatedRow, jobsOffered }]);
            setVacancyProcessedCount((vacancyProcessedCount) => vacancyProcessedCount + 1);
        }
    }, [successMessage]);

    useEffect(() => {
        if (vacancyCount === 0 || vacancyCount !== vacancyProcessedCount || vacancyProcessed)
            return;

        setVacancyProcessed(true);
    }, [vacancyProcessedCount]);

    useEffect(() => {
        const rows = !searchTerm
            ? activeVacancies
            : activeVacancies.filter((el) =>
                  el.title.toLowerCase().includes(searchTerm.toLowerCase())
              );
        setRows(rows);
    }, [searchTerm]);

    // HELPER FNS
    const loadingError = () => {
        if (jobSectorDetails?.length < 1) return JOB_SECTOR_DETAILS_ERROR;
        if (vacancyStageDetails?.length < 1) return VACANCY_STAGE_DETAILS_ERROR;
    };

    // EVENT HANDLERS
    const onJobSectorChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setSelectedJobSectors(chosenIds);
    };

    const onOwnerChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setSelectedVacancyOwnerIds(chosenIds);
    };

    const onSearch = (searchRes) => setSearchTerm(searchRes);

    const onLoadMoreVacancyOwners = () => {
        if ((!users.length || users.length < userMetaData.totalElements) && !userMetaData.last) {
            dispatch(
                searchUsersByLoggedInUserServiceIds(
                    loggedInUser.serviceIds,
                    !users.length ? 0 : userMetaData.number + 1
                )
            );
        }
    };

    // AWAITING CONTENT
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} time={3} />;

    // RENDER
    return (
        <>
            <div className={classes.vacanciesToolBar}>
                <div className={classes.searchBar}>
                    <DynamicSearch
                        searchTerm={searchTerm}
                        placeholder="Enter Search Term"
                        search={onSearch}
                    />
                </div>
                <MultiSelect
                    id="jobSectors"
                    key={keys.sectorIds}
                    label="Job Sector"
                    placeholder="Job Sector"
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                    menuItems={jobSectorDetails || []}
                    preSelectedIds={preSelectedJobSectorIds}
                    preSelects={jobSectorDetails}
                    onChange={(chosenIds) => onJobSectorChange(chosenIds)}
                />
                <MultiSelect
                    id="vacancyOwners"
                    key={keys.ownerIds}
                    label="Vacancy Owner"
                    placeholder="Vacancy Owner"
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                    menuItems={vacancyOwners || []}
                    preSelectedIds={preSelectedVacancyOwnerIds}
                    preSelects={preSelectedVacancyOwners}
                    onLoadMoreItems={onLoadMoreVacancyOwners}
                    moreItemsToLoad={
                        !userMetaData.totalElements || users.length < userMetaData.totalElements
                    }
                    onChange={(chosenIds) => onOwnerChange(chosenIds)}
                />
            </div>

            {rows.length < 1 ? (
                <LoadingSpinner content="No vacancies found" />
            ) : (
                <VacancyTable
                    rows={rows}
                    roles={loggedInUser.roles}
                    searchTerm={searchTerm}
                    onVacancyDeleted={setVacancyProcessed}
                    onVacancyCount={setVacancyCount}
                />
            )}
        </>
    );
};

export default VacancyTableManagement;
