import { createSelector } from '@reduxjs/toolkit';

import { selectAttendedStatusId } from './dataSelectors';
import { selectCurrentParticipant } from './participantSelectors';
const selectParticipantsCourseEntries = (state) => state.entities.courseService.participantsCourses;

const selectCourseModuleEntries = (state) => state.entities.courseService.courseModules;

const selectCourseModulesByUserEntries = (state) =>
    state.entities.courseService.courseModulesByUser;

export const selectParticipantAttendedModules = createSelector(
    selectParticipantsCourseEntries,
    selectAttendedStatusId,
    selectCurrentParticipant,
    (participantsCourses, attendedId, currentParticipant) => {
        const participantsModules = participantsCourses.map((el) => el.modules).flat();
        return participantsModules.filter((el) =>
            el.participants.some(
                (entry) =>
                    entry.participantId === currentParticipant.id && entry.statusId === attendedId
            )
        );
    }
);

export const selectCourseIds = createSelector(selectCourseModuleEntries, (courseModules) => [
    ...new Set(courseModules.map((el) => el.courseId))
]);

// This is for aggregated course modules - ie. for TimeView
export const selectAllCourseModules = createSelector(
    selectCourseModulesByUserEntries,
    (courseModulesByUser) => Object.values(courseModulesByUser).flat()
);

// This is for aggregated course ids - ie. for TimeView and Course Calendar
export const selectAllCourseIds = createSelector(selectAllCourseModules, (allCourseModules) => [
    ...new Set(allCourseModules.map((el) => el.courseId))
]);
