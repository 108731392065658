import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    clearAppointmentAuditData,
    clearNewAppointment,
    searchAppointmentSlotAuditData,
    setCurrentAppointment,
    setOpenAppointmentSchedulerPanel
} from '../../../store/calendarService';
import { selectUsersForNamesById } from '../../../store/userSelectors';
import { loadUsersMinimalDetails } from '../../../store/userService';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import AuditAppointmentModal from '../../auditing/auditAppointments/AuditAppointmentModal';
import StaticField from '../../formElements/StaticField';
import Switch from '../../formElements/Switch';
import Panel from '../../ui/panel/Panel';
import ParticipantSelectedPills from '../components/ParticipantSelectedPills';
import StartAppointmentSlotsRows from '../components/StartAppointmentSlotsRows';

import form from '../../formElements/formElementStyles/commonFormElements.module.css';
import sidePanel from '../styles/schedulePanel.module.css';

const ParticipantAppointmentSlotsView = ({ usersCurrentAppointment, currentLocation }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = { id: '', service: '' };
    const [newEntry, setNewEntry] = useState(initialState);
    const [appointmentAdviser, setAppointmentAdviser] = useState({});
    const [selectedParticipants, setSelectedParticipants] = useState([]);
    const [statuses, setStatuses] = useState({});
    const [isAuditable, setIsAuditable] = useState(false);
    const [showAuditModal, setShowAuditModal] = useState(false);
    const [auditLabel, setAuditLabel] = useState('');
    const [modalHeadingLabel, setModalHeadingLabel] = useState('');

    // STORE STATE
    const users = useSelector(selectUsersForNamesById);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const serviceDetailsById = useSelector(
        (state) => state.entities.directusService.serviceDetailsById
    );
    const openAppointmentSchedulerPanel = useSelector(
        (state) => state.entities.calendarService.openAppointmentSchedulerPanel
    );

    // USE EFFECTS
    useEffect(() => {
        if (usersCurrentAppointment.participantIds?.length > 0) {
            const selected = usersCurrentAppointment.participantIds.map((el) =>
                currentParticipant.id === el
                    ? currentParticipant
                    : { id: el, hidden: 'slot filled' }
            );
            setSelectedParticipants(selected);
        }
    }, []);

    useEffect(() => {
        if (!(usersCurrentAppointment.userId in users))
            dispatch(loadUsersMinimalDetails([usersCurrentAppointment.userId]));
        else setAppointmentAdviser(users[usersCurrentAppointment.userId]);
    }, [users]);

    useEffect(() => {
        if (!usersCurrentAppointment || !(usersCurrentAppointment?.serviceId in serviceDetailsById))
            return;
        setNewEntry({
            id: usersCurrentAppointment.id,
            service: serviceDetailsById[usersCurrentAppointment.serviceId].name
        });
    }, [serviceDetailsById]);

    useEffect(() => {
        if (selectedParticipants.length < 1) return;
        const statuses = selectedParticipants.reduce(
            (acc, cur) => ({
                ...acc,
                [cur.id]:
                    usersCurrentAppointment?.attendances.find(
                        (entry) => entry.participantId === cur.id
                    )?.status || 'Not known'
            }),
            {}
        );
        setStatuses(statuses);
    }, [selectedParticipants]);

    // HELPER FNS
    const configTitleRow = () => {
        return (
            <>
                {newEntry.service}
                <span>
                    {selectedParticipants.length} / {usersCurrentAppointment.numberOfSlots}
                </span>
            </>
        );
    };

    // EVENT HANDLERS
    const onPanelClose = () => {
        setNewEntry(initialState);
        dispatch(setCurrentAppointment({}));
        dispatch(setOpenAppointmentSchedulerPanel(false));
        dispatch(clearNewAppointment());
    };

    const onAuditModalClose = (e, reason) => {
        e.stopPropagation();
        if (reason === 'backdropClick') return;
        setShowAuditModal(false);
        setAuditLabel('');
        setModalHeadingLabel('');
        dispatch(clearAppointmentAuditData());
    };

    const onSearchAuditItems = (label, modalHeadingLabel) => {
        if (newEntry.id) dispatch(searchAppointmentSlotAuditData(newEntry.id, label));
        setShowAuditModal(true);
        setAuditLabel(label);
        setModalHeadingLabel(modalHeadingLabel ?? label);
    };

    // RENDER
    return (
        <>
            <Panel width="500px" open={openAppointmentSchedulerPanel} onToggle={onPanelClose}>
                <div className={sidePanel.panelActions}>
                    <Switch
                        id={'auditDisplayFields'}
                        label="Show Audit Fields"
                        onOff={true}
                        customClass="auditSwitch"
                        checked={isAuditable}
                        onChange={(e) => setIsAuditable(e.target.checked)}
                    />
                </div>
                <div className={sidePanel.formWrapper}>
                    <StaticField content={configTitleRow()} customClass="underlinedContent" />
                    <StaticField label="Adviser" content={appointmentAdviser?.emailAddress || ''} />
                    <StaticField label="Appointment Type" content={usersCurrentAppointment.type} />

                    <StaticField
                        label="Location"
                        content={currentLocation}
                        isAuditable={isAuditable}
                        onSearchAuditItems={onSearchAuditItems}
                    />
                    <StaticField
                        label="Start Time"
                        content={usersCurrentAppointment.startTime}
                        isAuditable={isAuditable}
                        onSearchAuditItems={() => onSearchAuditItems('Start Date', 'Start Time')}
                    />
                    <StaticField
                        label="End Time"
                        content={usersCurrentAppointment.endTime}
                        isAuditable={isAuditable}
                        onSearchAuditItems={() => onSearchAuditItems('End Date', 'End Time')}
                    />

                    <StaticField
                        label="Date"
                        content={reverseFormatDate(usersCurrentAppointment.date)}
                        isAuditable={isAuditable}
                        onSearchAuditItems={() => onSearchAuditItems('Start Date', 'Date')}
                    />

                    <div className={`${form.formLabel} ${sidePanel.pillsView}`}>
                        {selectedParticipants.length ? 'Participants' : ''}
                    </div>
                    <ParticipantSelectedPills
                        participants={selectedParticipants}
                        statuses={statuses}
                        isDisabled={true}
                    />

                    <div className={sidePanel.slotRowsView}>
                        <StartAppointmentSlotsRows
                            participants={selectedParticipants}
                            appointment={usersCurrentAppointment}
                            disabled={true}
                            onPanelClose={onPanelClose}
                            statuses={statuses}
                            isAuditable={isAuditable}
                            onSearchAuditItems={onSearchAuditItems}
                        />
                    </div>
                </div>
            </Panel>
            {showAuditModal && (
                <AuditAppointmentModal
                    isOpen={showAuditModal}
                    label={auditLabel}
                    modalHeadingLabel={modalHeadingLabel}
                    onClose={onAuditModalClose}
                />
            )}
        </>
    );
};

ParticipantAppointmentSlotsView.propTypes = {
    usersCurrentAppointment: PropTypes.object,
    currentLocation: PropTypes.string
};

export default ParticipantAppointmentSlotsView;
