import axios from 'axios';

import { getHeader, setHeader } from '../../api/commonHTTP';
import { msalConfig, msalInstance } from '../../masl/authConfig';
import { trimEntries } from '../../utils/objectUtils';
import * as actions from '../api';
import { setErrorMessage, setSuccessMessage } from '../formsState';
import { clearLocalStorage, getAccessToken, saveAccessToken, setIdToken } from '../userService';

const api =
    ({ dispatch }) =>
    (next) =>
    async (action) => {
        if (
            action.type !== actions.apiUpload.type &&
            action.type !== actions.apiDownload.type &&
            action.type !== actions.apiCallBegan.type &&
            action.type !== actions.apiCallDirectusBegan.type &&
            action.type !== actions.apiSeqDelete.type &&
            action.type !== actions.apiSeqAdd.type &&
            action.type !== actions.apiVersion.type
        )
            return next(action);

        const {
            url,
            method,
            data,
            filename,
            onStart,
            onSuccess,
            onError,
            successMessage,
            origin,
            onStartData
        } = action.payload;

        const startData = onStartData || data || '';
        if (onStart) dispatch({ type: onStart, payload: startData });

        next(action);

        if (action.type === actions.apiCallBegan.type || action.type === actions.apiVersion.type) {
            try {
                const headers =
                    action.type === actions.apiCallBegan.type
                        ? getHeader()
                        : trimEntries(['Authorization'], getHeader());
                const response = await axios.request({
                    url,
                    headers,
                    method,
                    data
                });
                // General success dispatch
                dispatch(actions.apiCallSuccess(response.data));

                if (successMessage) dispatch(setSuccessMessage(successMessage));

                // Specific success dispatch if present
                if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
            } catch (error) {
                // General error action
                dispatch(actions.apiCallFailed(error.message));

                // Tell formsState slice that error has occurred.
                if (error?.response?.data?.validationErrors)
                    dispatch(
                        setErrorMessage(
                            `${Object.keys(
                                error?.response?.data?.validationErrors
                            ).toString()} ${Object.values(
                                error?.response?.data?.validationErrors
                            ).toString()}`
                        )
                    );
                else if (error?.response?.data) {
                    dispatch(
                        setErrorMessage(
                            `${Object.keys(error?.response?.data).toString()} ${Object.values(
                                error?.response?.data
                            ).toString()}`
                        )
                    );
                    if (onError) dispatch({ type: onError, error: error?.response?.data });
                }
                // Specific error - dispatch if present
                else if (onError) dispatch({ type: onError, payload: error.message });
                else dispatch(setErrorMessage(error.message));
            }
        } else if (action.type === actions.apiUpload.type) {
            try {
                const response = await axios.request({
                    url,
                    headers: { ...getHeader(), 'Content-Type': 'multipart/form-data' },
                    method,
                    data
                });
                // General success dispatch
                dispatch(actions.apiCallSuccess(response.data));

                if (successMessage) dispatch(setSuccessMessage(successMessage));

                // Specific success dispatch if present
                if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
            } catch (error) {
                // General error action
                dispatch(actions.apiCallFailed(error.message));

                // Tell formsState slice that error has occurred.
                if (error?.response?.data?.validationErrors)
                    dispatch(setErrorMessage(error?.response?.data?.validationErrors));
                else if (error?.response?.data) {
                    if (onError) dispatch({ type: onError, error: error?.response?.data });
                    else setErrorMessage(error?.response?.data);
                }
                // Specific error - dispatch if present
                else if (onError) dispatch({ type: onError, payload: error.message });
                else dispatch(setErrorMessage(error.message));
            }
        } else if (action.type === actions.apiDownload.type) {
            try {
                const response = await axios.request({
                    url,
                    headers: getHeader(),
                    method,
                    responseType: 'blob'
                });
                const href = window.URL.createObjectURL(response.data);
                const anchorElement = document.createElement('a');
                anchorElement.href = href;
                anchorElement.download = filename;
                document.body.appendChild(anchorElement);
                anchorElement.click();
                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
            } catch (error) {
                let message;
                // General error action
                if (error?.response?.data?.errors[0]?.message)
                    message = error.response.data.errors[0].message;
                else message = 'An error occurred during download';

                dispatch(actions.apiCallFailed(message));
            }
        } else if (action.type === actions.apiSeqDelete.type) {
            try {
                const BATCH_SIZE = 500;
                if (data.keys.length <= BATCH_SIZE) {
                    await axios.request({
                        url,
                        headers: getHeader(),
                        method,
                        data
                    });
                } else {
                    const entries = data.keys;
                    let count = 0;
                    const numBatches = Math.ceil(entries.length / BATCH_SIZE);
                    while (count < numBatches) {
                        const keys = entries.splice(0, BATCH_SIZE);
                        if (keys.length) {
                            const data = { keys };
                            await axios.request({
                                url,
                                headers: getHeader(),
                                method,
                                data
                            });
                        }
                        count++;
                    }
                }
                if (successMessage) dispatch(setSuccessMessage(successMessage));
                if (onSuccess) dispatch({ type: onSuccess });
            } catch (error) {
                let message;
                if (error?.response?.data?.errors && error?.response?.data?.errors[0]?.message)
                    message = error.response.data.errors[0].message;
                else message = 'An error has occurred';
                dispatch(actions.apiCallFailed(message));
                dispatch(setErrorMessage(message));
                if (onError) dispatch({ type: onError, payload: { origin, message } });
            }
        } else if (action.type === actions.apiSeqAdd.type) {
            try {
                const BATCH_SIZE = 500;
                let response;
                if (data.length <= BATCH_SIZE) {
                    response = await axios.request({
                        url,
                        headers: getHeader(),
                        method,
                        data
                    });
                } else {
                    let count = 0;
                    const numBatches = Math.ceil(data.length / BATCH_SIZE);
                    while (count < numBatches) {
                        const entries = data.splice(0, BATCH_SIZE);
                        if (entries.length) {
                            response = await axios.request({
                                url,
                                headers: getHeader(),
                                method,
                                data: entries
                            });
                        }
                        count++;
                    }
                }
                dispatch(actions.apiCallSuccess(response.data));
                if (successMessage) dispatch(setSuccessMessage(successMessage));
                if (onSuccess)
                    dispatch({
                        type: onSuccess,
                        payload: response.data,
                        status: response.status
                    });
            } catch (error) {
                let message;
                if (error?.response?.data?.errors && error?.response?.data?.errors[0]?.message)
                    message = error.response.data.errors[0].message;
                else message = 'An error has occurred';
                dispatch(actions.apiCallFailed(message));
                dispatch(setErrorMessage(message));
                if (onError) dispatch({ type: onError, payload: { origin, message } });
            }
        } else {
            // Must be a Directus call
            try {
                const response = await axios.request({
                    url,
                    headers: getHeader(),
                    method,
                    data
                });

                // General success dispatch
                dispatch(actions.apiCallSuccess(response.data));
                if (successMessage) dispatch(setSuccessMessage(successMessage));
                // Specific success dispatch if present
                if (onSuccess)
                    dispatch({
                        type: onSuccess,
                        payload: response.data,
                        status: response.status
                    });
            } catch (error) {
                let message;
                // General error action
                if (error?.response?.data?.errors && error?.response?.data?.errors[0]?.message)
                    message = error.response.data.errors[0].message;
                else if (error?.response?.data?.validationErrors?.title)
                    message = error?.response?.data?.validationErrors?.title;
                else message = 'An error has occurred';

                dispatch(actions.apiCallFailed(message));

                // Tell formsState slice that error has occurred.
                dispatch(setErrorMessage(message));
                // Specific error to the fn that called the req - dispatch if present
                if (onError) dispatch({ type: onError, payload: { origin, message } });
            }
        }
    };

let isRefreshing = false;
let refreshSubscribers = [];

const getTokenExpired = (token) => {
    const bufferTime = 30; // Buffer seconds before actual expiration, to make room for pending requests
    try {
        const payloadBase64 = token.split('.')[1];
        const decodedPayload = JSON.parse(atob(payloadBase64));
        const expiresOn = new Date((decodedPayload.exp - bufferTime) * 1000);
        return expiresOn < new Date();
    } catch {
        return true;
    }
};

const ensureMsalInitialized = async () => {
    if (!msalInstance || !msalInstance.initialize) {
        console.error('msalInstance is not available!');
        return false;
    }

    try {
        await msalInstance.initialize(); // Ensure it's initialized
        return true;
    } catch (error) {
        console.error('Error initializing msalInstance:', error);
        return false;
    }
};

const refreshToken = async (token) => {
    if (isRefreshing) {
        return new Promise((resolve) => {
            refreshSubscribers.push(resolve);
        });
    }

    isRefreshing = true;
    const isInitialized = await ensureMsalInitialized();
    if (!isInitialized) {
        isRefreshing = false;
        return null;
    }
    try {
        const request = {
            scopes: []
        };
        const tokenResponse = await msalInstance.acquireTokenSilent(request);
        const newIdToken = tokenResponse.idToken;

        if (newIdToken) {
            saveAccessToken(newIdToken);
            setIdToken(newIdToken);
            setHeader({
                Authorization: 'Bearer ' + newIdToken + '',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            });
            refreshSubscribers.forEach((callback) => callback(newIdToken));
            refreshSubscribers = [];
            return newIdToken;
        } else {
            return token;
        }
    } catch (error) {
        console.error('Error refreshing token:', error);
        return null;
    } finally {
        isRefreshing = false;
    }
};

axios.interceptors.request.use(
    async (config) => {
        if (config.headers['Authorization']) {
            let token = getAccessToken();
            if (config.headers['Authorization'].replace(/^Bearer\s+/i, '') !== token) {
                setHeader({
                    Authorization: 'Bearer ' + token + '',
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json'
                });
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            if (token && getTokenExpired(token)) {
                token = await refreshToken(token);
                config.headers['Authorization'] = `Bearer ${token}`;
            }
        }
        return config;
    },
    (error) => error
);

axios.interceptors.response.use(
    async (response) => response,
    async (error) => {
        if (error.response && error.response.status === 401) {
            clearLocalStorage();
            await msalInstance.logoutRedirect({
                account: msalInstance.getActiveAccount(),
                postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri
            });
        } else throw error;
    }
);

export default api;
