import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';

import { Button } from '@mui/material';

import { loadJobSectorDetails } from '../../../store/directusService';
import { setErrorMessage } from '../../../store/formsState';
import {
    createParticipantJobGoal,
    deleteJobGoal,
    deleteParticipantJobGoal,
    loadParticipantJobGoals,
    updateParticipantJobGoal
} from '../../../store/participantService';
import { getConfiguredItems } from '../../../utils/directusFunctions';
import { hasRole, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import JobGoalsViewer from '../../ui/cards/jobGoals/JobGoalsViewer';
import LoadingSpinner from '../../ui/LoadingSpinner';

import app from '../../../app.module.css';
import cardSet from '../../../commonStyles/cardContainer.module.css';
import local from './jobGoals.module.css';

const JobGoals = ({
    roles,
    acceptedRoles,
    cards,
    addGoal,
    deleteGoal,
    updateGoal,
    currentParticipant,
    externalSource = false
}) => {
    const dispatch = useDispatch();

    // LOCAL STATE

    const editAndDeleteRoles = [QUALITY, SUPERUSER];
    const [editType, setEditType] = useState('Add');
    const [arrayJobSectors, setArrayJobSectors] = useState([]);
    const [goals, setGoals] = useState([]);
    const [currentGoal, setCurrentGoal] = useState({});
    const [newGoal, setNewGoal] = useState({});

    // STORE STATE
    const jobSectorDetails = useSelector(
        (state) => state.entities.directusService.jobSectorDetails
    );
    const currentParticipantJobGoals = useSelector(
        (state) => state.entities.participantService.currentParticipantJobGoals
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS

    useEffect(() => {
        setArrayJobSectors([]);
        dispatch(loadJobSectorDetails());
        if (!externalSource && currentParticipant?.id)
            dispatch(loadParticipantJobGoals(currentParticipant.id));
    }, [currentParticipant]);

    useEffect(() => {
        if (jobSectorDetails?.length && arrayJobSectors?.length < 1) {
            setArrayJobSectors(
                getConfiguredItems(jobSectorDetails, currentParticipant?.contractId)
            );
        }
    }, [jobSectorDetails]);

    useEffect(() => {
        cards?.length && setGoals(cards);
    }, [cards]);

    useEffect(() => {
        if (!externalSource && currentParticipantJobGoals) setGoals(currentParticipantJobGoals);
    }, [currentParticipantJobGoals]);

    useEffect(() => {
        if (successMessage === `Job goal ${currentGoal?.title} has been deleted`) {
            dispatch(deleteJobGoal(currentGoal.id));
            setCurrentGoal({});
        }
    }, [successMessage]);

    if (!hasRole(roles, acceptedRoles)) return;

    // EVENT HANDLERS
    const onAddGoal = () => {
        setEditType('Add');
        if (addGoal) addGoal();
        else {
            if (goals.some((el) => !el.sectorId)) {
                dispatch(
                    setErrorMessage(`Cannot add another blank. Please complete existing card.`)
                );
                return;
            }
            const newId = uuid();
            setGoals((prev) => [
                ...prev,
                {
                    participantId: currentParticipant?.id,
                    id: newId
                }
            ]);
            setNewGoal({ id: newId });
        }
    };

    const onDeleteGoal = (goal) => {
        if (deleteGoal) deleteGoal(goal);
        else {
            setGoals(goals.filter((el) => el.id !== goal.id));
            setCurrentGoal(goal);
            dispatch(deleteParticipantJobGoal(goal.id, `Job goal ${goal?.title} has been deleted`));
        }
    };

    const onUpdateGoal = (goal) => {
        setEditType('Update');
        if (updateGoal) updateGoal(goal);
        else {
            if (goal.id === newGoal.id) {
                // eslint-disable-next-line
                const { id, ...payload } = goal;
                dispatch(
                    createParticipantJobGoal(payload, `Job goal ${goal?.title} has been created`)
                );
            } else {
                if (!hasRole(editAndDeleteRoles, roles)) return;
                dispatch(
                    updateParticipantJobGoal(goal, `Job goal ${goal?.title} has been updated`)
                );
            }
        }
    };

    // RENDER

    let content;
    if (arrayJobSectors?.length < 1) content = 'No job sector options';
    if (jobSectorDetails?.length < 1) content = 'No job sector details';

    if (arrayJobSectors?.length < 1 || jobSectorDetails?.length < 1)
        return <LoadingSpinner content={content} />;

    return (
        <div className={cardSet.cardContainer}>
            <h2 className={app.sectionHeading}>Job Goals</h2>

            <div className={cardSet.cards}>
                {externalSource &&
                    cards.map((el) => (
                        <div key={el.id}>
                            <JobGoalsViewer
                                cardData={el}
                                arrayJobSectors={arrayJobSectors}
                                disabled={!hasRole(acceptedRoles, roles)}
                                roles={roles}
                                editAndDeleteRoles={editAndDeleteRoles}
                                editType={editType}
                                sendDelete={onDeleteGoal}
                                sendUpdate={onUpdateGoal}></JobGoalsViewer>
                        </div>
                    ))}
                {!externalSource &&
                    goals.map((el) => (
                        <div key={el.id}>
                            <JobGoalsViewer
                                cardData={el}
                                arrayJobSectors={arrayJobSectors}
                                disabled={!hasRole(acceptedRoles, roles)}
                                roles={roles}
                                editAndDeleteRoles={editAndDeleteRoles}
                                editType={editType}
                                sendDelete={onDeleteGoal}
                                sendUpdate={onUpdateGoal}></JobGoalsViewer>
                        </div>
                    ))}
            </div>
            <div className={local.jobgoalsActions}>
                <Button
                    variant={'contained'}
                    onClick={onAddGoal}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    Add a Job Goal Card
                </Button>
            </div>
        </div>
    );
};

export default JobGoals;

JobGoals.propTypes = {
    roles: PropTypes.arrayOf(PropTypes.string),
    acceptedRoles: PropTypes.arrayOf(PropTypes.string),
    cards: PropTypes.arrayOf(PropTypes.object),
    addGoal: PropTypes.func,
    deleteGoal: PropTypes.func,
    updateGoal: PropTypes.func,
    currentParticipant: PropTypes.object,
    externalSource: PropTypes.bool
};
