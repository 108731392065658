import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { jsPDF } from 'jspdf';
import ReactQuill from 'react-quill-new';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@mui/material';

import {
    clearCurrentTemplate,
    loadCommunicationTemplate,
    searchCommunicationTemplates
} from '../../../../store/communicationService';
import { loadCommunicationTypes, loadTimelineTypeDetails } from '../../../../store/directusService';
import { selectCurrentParticipant } from '../../../../store/participantSelectors';
import { createParticipantTimelineCommunicationEvent } from '../../../../store/participantService';
import { selectUserRoles } from '../../../../store/userSelectors';
import { getCurrentTime, reverseFormatDate } from '../../../../utils/dateFunctions';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import IconError from '../../../IconError';
import DDLOptionPicker from '../../../ui/pickers/DDLOptionPicker';

import { initialErrorState, validate } from './validateCommunications';

import app from '../../../../app.module.css';
import form from '../../../../commonStyles/formStyles.module.css';

const LetterCommunication = () => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const initialState = {
        date: reverseFormatDate(new Date().toISOString().slice(0, 10)),
        templateId: '',
        templateName: '',
        details: ''
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const [isPdfGenerated, setIsPdfGenerated] = useState(false);
    const [fileTitle, setFileTitle] = useState('');
    const [isSucceeded, setIsSucceeded] = useState(false);
    const [timelineTypeId, setTimelineTypeId] = useState(false);
    const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const communicationTypes = useSelector(
        (state) => state.entities.directusService.communicationTypes
    );
    const timelineTypeDetails = useSelector(
        (state) => state.entities.directusService.timelineTypeDetails
    );
    const currentParticipant = useSelector(selectCurrentParticipant);
    const templates = useSelector(
        (state) => state.entities.communicationService.communicationTemplates
    );
    const currentTemplate = useSelector(
        (state) => state.entities.communicationService.communicationTemplate
    );
    const loggedInUserId = useSelector((state) => state.entities.userService.loggedInUser.id);

    // HELPER FNS
    const clearForm = () => {
        setNewEntry(initialState);
        setErrors(initialErrorState);
        setIsPdfGenerated(false);
        setIsSucceeded(false);
        dispatch(clearCurrentTemplate());
    };

    // USE EFFECTS

    useEffect(() => {
        setNewEntry(initialState);
        if (communicationTypes?.length < 1) dispatch(loadCommunicationTypes());
        dispatch(loadTimelineTypeDetails());
    }, []);

    useEffect(() => {
        if (!currentParticipant || Object.keys(currentParticipant).length < 1) return;
        dispatch(
            searchCommunicationTemplates({
                type: 'Letter',
                contractIds: [currentParticipant.contractId],
                inactive: false
            })
        );
    }, [currentParticipant]);

    useEffect(() => {
        if (
            !newEntry.templateId ||
            Object.keys(currentTemplate).length < 1 ||
            communicationTypes?.length < 1
        )
            return;
        if (communicationTypes.find((el) => el.id === currentTemplate.typeId)?.name !== 'Letter')
            return;
        setNewEntry((prev) => ({
            ...prev,
            details: currentTemplate.details
        }));
    }, [newEntry.templateId, currentTemplate, communicationTypes]);

    useEffect(() => {
        setTimelineTypeId(
            timelineTypeDetails.find((el) => el.name === 'Letter Generated')?.id || ''
        );
    }, [timelineTypeDetails]);

    useEffect(() => {
        if (isPdfGenerated) {
            setIsSucceeded(true);
            createParticipantTimelineCommunicationEntry();
        }
    }, [isPdfGenerated]);

    useEffect(() => {
        if (isSucceeded) {
            setIsSendButtonDisabled(false);
            setTimeout(() => clearForm(), 6000);
        }
    }, [isSucceeded]);

    // EVENT HANDLERS

    const onGeneratePdf = () => {
        const fileName = `${newEntry.templateName || 'Letter'} - ${format(
            new Date(),
            'dd/MM/yyyy'
        )} ${getCurrentTime()}`;

        setFileTitle(fileName);
        const doc = new jsPDF();
        doc.setFontSize(12);
        doc.html(newEntry.details, {
            async callback(doc) {
                doc.save(fileName);
                setIsPdfGenerated(true);
            },
            autoPaging: 'text',
            margin: [10, 5, 10, 5],
            width: 210,
            windowWidth: 1050
        });
    };

    const createParticipantTimelineCommunicationEntry = () => {
        const currentDate = format(new Date(), 'yyyy-MM-dd');
        const currentTime = new Date().toLocaleTimeString().slice(0, 5).replace(':', '');

        const payload = {
            participantId: currentParticipant.id,
            eventStatusId: '00000000-0000-0000-0000-000000000000',
            eventDate: currentDate,
            eventTime: currentTime,
            itemTypeId: timelineTypeId,
            eventType: 'Outbound Communication',
            loggedDate: currentDate,
            loggedTime: currentTime,
            userId: loggedInUserId,
            subject: newEntry.templateName || 'Ad Hoc Letter',
            details: newEntry.details
        };
        dispatch(createParticipantTimelineCommunicationEvent(payload));
    };

    const onDetailsChange = (details) => setNewEntry((prev) => ({ ...prev, details }));

    const onSubmit = (e) => {
        e.preventDefault();
        const { isValid, newErrors } = validate(newEntry, errors, 'Letter');
        setErrors(newErrors);
        if (!isValid) return;
        setIsSendButtonDisabled(true);
        onGeneratePdf();
    };

    return (
        <div className={app.container} id="letterScreen">
            <div className={app.mainHeading}>Communications</div>
            <form className={form.formWrapper} onSubmit={onSubmit}>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={form.textAreaSet}>
                            <label>Date</label>
                            <div className={form.textAreaText}>{newEntry.date}</div>
                        </div>
                    </div>
                    <div className={form.formColumn}>
                        <div className={form.alignDownMed} id="letterTemplateInput">
                            <DDLOptionPicker
                                label={'Template'}
                                id={'templates'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                menuItems={templates || []}
                                chosenName={newEntry.templateName}
                                chosenId={newEntry.templateId}
                                onChange={(chosenId) => {
                                    chosenId && dispatch(loadCommunicationTemplate(chosenId));
                                    const chosenTemplate = templates?.find(
                                        (el) => el.id === chosenId
                                    );
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        templateId: chosenId || '',
                                        templateName: chosenTemplate?.name || ''
                                    }));
                                }}></DDLOptionPicker>
                        </div>
                    </div>
                </div>

                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={form.textAreaSet}>
                            <ReactQuill
                                value={newEntry.details}
                                modules={LetterCommunication.modules}
                                placeholder="Enter details"
                                onChange={onDetailsChange}
                            />
                            {errors.details.error && (
                                <div className={`${form.textInputError} ${form.resetMargin}`}>
                                    <IconError text={errors.details} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className={form.buttonRight}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={onSubmit}
                        disabled={!hasRole(acceptedRoles, roles) || isSendButtonDisabled}>
                        Send Now
                    </Button>
                </div>
                {isSucceeded && (
                    <div className={form.successMessage}>PDF {fileTitle} downloaded</div>
                )}
            </form>
        </div>
    );
};

export default LetterCommunication;

LetterCommunication.modules = {
    toolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }],
        ['clean']
    ]
};
