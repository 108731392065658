import { compareUnorderedArrays } from '../../../../utils/arrayUtils';
import {
    INVALID_EMAIL_ADDRESS,
    INVALID_PHONE_NUMBER,
    INVALID_POSTCODE
} from '../../../../utils/formValidation/commonConstants';
import { NO_UPDATE_ERROR } from '../../../../utils/formValidation/commonErrorConstants';
import {
    getReqMessage,
    getSameAsMessage
} from '../../../../utils/formValidation/errorMessageFunctions';
import { regExps } from '../../../../utils/formValidation/validationPatterns';

export const validateAddress1 = (address1, errors) => {
    if (!address1) {
        return {
            ...errors,
            address1: { error: true, message: getReqMessage('Address1') }
        };
    }
    return errors;
};

export const validatePostcode = (postcode, errors) => {
    if (!postcode) {
        return {
            ...errors,
            postcode: { error: true, message: getReqMessage('Postcode') }
        };
    } else if (!postcode?.match(regExps.POSTCODE_R)) {
        return {
            ...errors,
            postcode: { error: true, message: INVALID_POSTCODE }
        };
    }
    return errors;
};

export const validatePrimaryPhoneNumber = (primaryPhoneNumber, errors) => {
    if (primaryPhoneNumber && !primaryPhoneNumber?.match(regExps.PHONE_R)) {
        return {
            ...errors,
            primaryPhoneNumber: {
                error: true,
                message: INVALID_PHONE_NUMBER
            }
        };
    }
    return errors;
};

export const validateSecondaryPhoneNumber = (secondaryPhoneNumber, primaryPhoneNumber, errors) => {
    if (secondaryPhoneNumber) {
        if (secondaryPhoneNumber === primaryPhoneNumber) {
            return {
                ...errors,
                secondaryPhoneNumber: {
                    error: true,
                    message: getSameAsMessage('Primary phone number', 'Secondary phone number')
                }
            };
        } else if (!secondaryPhoneNumber?.match(regExps.PHONE_R)) {
            return {
                ...errors,
                secondaryPhoneNumber: {
                    error: true,
                    message: INVALID_PHONE_NUMBER
                }
            };
        }
    }
    return errors;
};

export const validateEmailAddress = (emailAddress, errors) => {
    if (emailAddress && !emailAddress?.match(regExps.EMAIL_R)) {
        return {
            ...errors,
            emailAddress: {
                error: true,
                message: INVALID_EMAIL_ADDRESS
            }
        };
    }
    return errors;
};

export const checkDiff = (newEntry, initialStateOnEntry, errors) => {
    if (compareUnorderedArrays(Object.values(newEntry), Object.values(initialStateOnEntry))) {
        return {
            ...errors,
            button: { error: true, message: NO_UPDATE_ERROR }
        };
    }
    return errors;
};
