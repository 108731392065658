import React, { useEffect, useState } from 'react';
import { format, parse } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button, Typography } from '@mui/material';

import {
    loadDarPriorities,
    loadDarRequestTypes,
    loadDarStatuses,
    loadServiceDetailsByService
} from '../../../store/directusService';
import { loadParticipant, searchDarQueue } from '../../../store/participantService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { loadUsersByServiceIds } from '../../../store/userService';
import { SCREEN_SETTINGS } from '../../../themes/theme';
import { getNameFromId } from '../../../utils/directusFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import LoadingSpinner from '../../ui/LoadingSpinner';
import DateRangePicker from '../../ui/pickers/DateRangePicker';
import DDLOptionPicker from '../../ui/pickers/DDLOptionPicker';

import CreateDar from './CreateDar';
import DarChangeEditor from './DarChangeEditor';
import DarTable from './DarTable';

import form from '../../../commonStyles/formStyles.module.css';
import classes from '../homeStyles/dar.module.css';

const DarTableManagement = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [searchTermDateRange, setSearchTermDateRange] = useState('');

    const [statusId, setStatusId] = useState('');
    const [priorityId, setPriorityId] = useState('');
    const [serviceId, setServiceId] = useState('');
    const [requestTypeId, setRequestTypeId] = useState('');

    const [darChangeEditor, setDarChangeEditor] = useState(false);
    const [darRow, setDarRow] = useState({});

    const [showForm, setShowForm] = useState(false);
    const [formType, setFormType] = useState('');

    const [clearSelectedPriority, setClearSelectedPriority] = useState('0');
    const [clearSelectedDarStatus, setClearSelectedDarStatus] = useState('1');
    const [clearSelectedService, setClearSelectedService] = useState('2');
    const [clearSelectedRequestType, setClearSelectedRequestType] = useState('3');

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const { darPriorities, darRequestTypes, darStatuses, serviceDetails } = useSelector(
        (state) => state.entities.directusService
    );
    const { currentParticipant, darQueuesSearch } = useSelector(
        (state) => state.entities.participantService
    );

    // EVENT HANDLERS
    const handlePriorityChange = (chosenId) => {
        if (!chosenId) chosenId = '';

        if (
            chosenId !== '' ||
            statusId !== '' ||
            serviceId !== '' ||
            requestTypeId !== '' ||
            searchTermDateRange !== ''
        ) {
            dispatch(
                searchDarQueue({
                    priorityId: chosenId,
                    statusId: statusId,
                    serviceId: serviceId,
                    requestTypeId: requestTypeId,
                    dateCreatedFrom: searchTermDateRange.substring(0, 10) || '',
                    dateCreatedTo: searchTermDateRange.substring(10, 20) || ''
                })
            );
        } else {
            setRows([]);
            setFilteredRows([]);
        }

        setPriorityId(chosenId);
    };

    const handleDarStatusChange = (chosenId) => {
        if (!chosenId) chosenId = '';

        if (
            priorityId !== '' ||
            chosenId !== '' ||
            serviceId !== '' ||
            requestTypeId !== '' ||
            searchTermDateRange !== ''
        ) {
            dispatch(
                searchDarQueue({
                    priorityId: priorityId,
                    statusId: chosenId,
                    serviceId: serviceId,
                    requestTypeId: requestTypeId,
                    dateCreatedFrom: searchTermDateRange.substring(0, 10) || '',
                    dateCreatedTo: searchTermDateRange.substring(10, 20) || ''
                })
            );
        } else {
            setRows([]);
            setFilteredRows([]);
        }

        setStatusId(chosenId);
    };

    const handleServiceChange = (chosenId) => {
        if (!chosenId) chosenId = '';

        if (
            priorityId !== '' ||
            statusId !== '' ||
            chosenId !== '' ||
            requestTypeId !== '' ||
            searchTermDateRange !== ''
        ) {
            dispatch(
                searchDarQueue({
                    priorityId: priorityId,
                    statusId: statusId,
                    serviceId: chosenId,
                    requestTypeId: requestTypeId,
                    dateCreatedFrom: searchTermDateRange.substring(0, 10) || '',
                    dateCreatedTo: searchTermDateRange.substring(10, 20) || ''
                })
            );
        } else {
            setRows([]);
            setFilteredRows([]);
        }

        setServiceId(chosenId);
    };

    const handleRequestTypeChange = (chosenId) => {
        if (!chosenId) chosenId = '';

        if (
            priorityId !== '' ||
            statusId !== '' ||
            serviceId !== '' ||
            chosenId !== '' ||
            searchTermDateRange !== ''
        ) {
            dispatch(
                searchDarQueue({
                    priorityId: priorityId,
                    statusId: statusId,
                    serviceId: serviceId,
                    requestTypeId: chosenId,
                    dateCreatedFrom: searchTermDateRange.substring(0, 10) || '',
                    dateCreatedTo: searchTermDateRange.substring(10, 20) || ''
                })
            );
        } else {
            setRows([]);
            setFilteredRows([]);
        }

        setRequestTypeId(chosenId);
    };

    const onSearchDateRange = (searchRange) => {
        if (searchRange) {
            const inDate = parse(searchRange.substring(0, 10), 'dd/MM/yyyy', new Date());
            const startDate = format(inDate, 'yyyy-MM-dd');
            const outDate = parse(searchRange.substring(13), 'dd/MM/yyyy', new Date());
            const endDate = format(outDate, 'yyyy-MM-dd');
            setSearchTermDateRange(startDate + endDate);

            dispatch(
                searchDarQueue({
                    priorityId: priorityId,
                    statusId: statusId,
                    serviceId: serviceId,
                    requestTypeId: requestTypeId,
                    dateCreatedFrom: startDate,
                    dateCreatedTo: endDate
                })
            );
        } else {
            setSearchTermDateRange('');

            if (priorityId !== '' || statusId !== '' || serviceId !== '' || requestTypeId !== '') {
                dispatch(
                    searchDarQueue({
                        priorityId: priorityId,
                        statusId: statusId,
                        serviceId: serviceId,
                        requestTypeId: requestTypeId
                    })
                );
            } else {
                setRows([]);
                setFilteredRows([]);
            }
        }
    };

    const onCreateDar = () => {
        setFormType('create');
        setShowForm(true);
    };

    // USE EFFECTS
    useEffect(() => {
        setRows([]);
        setFilteredRows([]);
        setClearSelectedPriority(Math.random());
        setClearSelectedDarStatus(Math.random());
        setClearSelectedService(Math.random());
        setClearSelectedRequestType(Math.random());

        darPriorities?.length < 1 && dispatch(loadDarPriorities());
        darStatuses?.length < 1 && dispatch(loadDarStatuses());
        darRequestTypes?.length < 1 && dispatch(loadDarRequestTypes());
    }, []);

    useEffect(() => {
        if (!loggedInUser || Object.keys(loggedInUser).length < 1) return;

        if (loggedInUser?.serviceIds?.length > 0) {
            dispatch(loadUsersByServiceIds(loggedInUser.serviceIds));
            dispatch(loadServiceDetailsByService(loggedInUser.serviceIds));
        }
    }, [loggedInUser.id]);

    useEffect(() => {
        if (Object.keys(currentParticipant)?.length > 0) {
            rows.map((el) => {
                if (el.participantId === currentParticipant.id) {
                    el.ptCode = currentParticipant.ptCode;
                }
            });
            setRows(rows);
            if (rows.every((el) => el.ptCode !== '')) setFilteredRows(rows);
        }
    }, [currentParticipant]);

    useEffect(() => {
        if (!darQueuesSearch || darQueuesSearch?.length < 1) {
            setRows([]);
            setFilteredRows([]);
            return;
        }

        const participantIds = [...new Set(darQueuesSearch.map((el) => el.participantId))];
        participantIds.map((el) => {
            if (el) {
                dispatch(loadParticipant(el));
            }
        });

        const addNames = darQueuesSearch.map((el) => ({
            ...el,
            priorityName: getNameFromId(darPriorities, el.priorityId),
            statusName:
                getNameFromId(darStatuses, el.statusId).toLowerCase() === 'closed'
                    ? `zz${getNameFromId(darStatuses, el.statusId).toLowerCase()}`
                    : getNameFromId(darStatuses, el.statusId).toLowerCase(),
            requestTypeName: getNameFromId(darRequestTypes, el.requestTypeId),
            serviceName: getNameFromId(serviceDetails, el.serviceId),
            ptCode: ''
        }));

        setRows(addNames);
    }, [darQueuesSearch]);

    useEffect(() => {
        if (successMessage === `DAR has been added` || successMessage === `DAR has been updated`) {
            if (
                priorityId !== '' ||
                statusId !== '' ||
                serviceId !== '' ||
                requestTypeId !== '' ||
                searchTermDateRange !== ''
            )
                dispatch(
                    searchDarQueue({
                        priorityId: priorityId,
                        statusId: statusId,
                        serviceId: serviceId,
                        requestTypeId: requestTypeId,
                        dateCreatedFrom: searchTermDateRange.substring(0, 10) || '',
                        dateCreatedTo: searchTermDateRange.substring(10, 20) || ''
                    })
                );
        }
    }, [successMessage]);

    // CALLBACKS
    const onDarChangeEditor = (setOpen) => {
        setDarChangeEditor(setOpen);
    };

    const onDarRow = (row) => {
        setDarRow(row);
    };

    // RENDER
    let content = '';
    if (darPriorities?.length < 1) content = 'No dar priorities';
    if (darRequestTypes?.length < 1) content = 'No dar request types';
    if (darStatuses?.length < 1) content = 'No dar statuses';
    if (serviceDetails?.length < 1) content = 'No service details';
    if (
        darPriorities?.length < 1 ||
        darRequestTypes?.length < 1 ||
        darStatuses?.length < 1 ||
        serviceDetails?.length < 1
    )
        return <LoadingSpinner content={content} />;

    return (
        <>
            <form className={form.form}>
                <br />
                <Typography className={classes.darTitle} variant={'h5'}>
                    Data Amendment Request Search:
                </Typography>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div>
                            <DDLOptionPicker
                                label={'Priority'}
                                key={clearSelectedPriority}
                                id={'priorityId'}
                                disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                menuItems={darPriorities || []}
                                onChange={(chosenId) => handlePriorityChange(chosenId)}
                            />
                            <DDLOptionPicker
                                label={'Status'}
                                key={clearSelectedDarStatus}
                                id={'statusId'}
                                disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                menuItems={darStatuses || []}
                                onChange={(chosenId) => handleDarStatusChange(chosenId)}
                            />
                            <DDLOptionPicker
                                label={'Service'}
                                key={clearSelectedService}
                                id={'serviceId'}
                                disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                menuItems={serviceDetails || []}
                                onChange={(chosenId) => handleServiceChange(chosenId)}
                            />
                        </div>
                    </div>
                    <div className={form.formColumn}>
                        <div className={classes.datePicker}>
                            <strong>
                                <Typography className={classes.darTitle} variant={'p'}>
                                    Date Created
                                </Typography>
                            </strong>
                            <DateRangePicker
                                onSearch={onSearchDateRange}
                                disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            />
                        </div>
                        <DDLOptionPicker
                            label={'Request Type'}
                            key={clearSelectedRequestType}
                            id={'requestId'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            menuItems={darRequestTypes || []}
                            onChange={(chosenId) => handleRequestTypeChange(chosenId)}
                        />
                    </div>
                </div>
                <DarChangeEditor
                    open={darChangeEditor}
                    onDarChangeEditor={onDarChangeEditor}
                    darRow={darRow}
                    darStatuses={darStatuses}
                />

                {filteredRows.length < 1 ? (
                    <LoadingSpinner content="No DARs found" />
                ) : (
                    <DarTable
                        rows={filteredRows}
                        roles={acceptedRoles}
                        darStatuses={darStatuses}
                        darPriorities={darPriorities}
                        darRequestTypes={darRequestTypes}
                        onDarChangeEditor={onDarChangeEditor}
                        onDarRow={onDarRow}
                    />
                )}
            </form>
            <div>
                {!showForm && (
                    <Button
                        disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                        color="primary"
                        variant="contained"
                        size={'small'}
                        onClick={onCreateDar}>
                        Add a DAR
                        <Box width={SCREEN_SETTINGS.gap.medium} />
                        <AddCircleOutlineIcon />
                    </Button>
                )}
                {showForm && formType === 'create' && (
                    <CreateDar
                        onClose={() => setShowForm(false)}
                        roles={loggedInUser.roles}
                        darRequestTypes={darRequestTypes}
                        darPriorities={darPriorities}
                        serviceDetails={serviceDetails}
                    />
                )}
            </div>
        </>
    );
};

export default DarTableManagement;
