import React from 'react';

import { countDays } from '../../../utils/dateFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';

import classes from './actionPlan.module.css';

export const calcDays = (arr, newEntry) => {
    const outcomeName = arr.find((el) => el.id === newEntry.outcomeId)?.name || '';
    let endDate = newEntry.actionDueDate;
    let res;
    if (
        (outcomeName.toLowerCase() === 'completed' ||
            outcomeName.toLowerCase() === 'partially achieved') &&
        newEntry.actionOutcomeDate
    ) {
        endDate = newEntry.actionOutcomeDate;
    }
    res = countDays(newEntry.actionStartDate, endDate);

    if (
        outcomeName.toLowerCase() === 'not completed' ||
        outcomeName.toLowerCase() === 'no longer applicable'
    ) {
        res = 'n/a';
    }
    return res < 0 ? <span className={classes.negative}>{res}</span> : res;
};

export const configDisabled = (newEntry, roles, allowMandatoryActivities) => {
    return {
        barrierId: newEntry.outcomeId
            ? !hasRole([QUALITY, SUPERUSER], roles)
            : !hasRole([ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER], roles),

        mandatory:
            !hasRole([ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER], roles) ||
            !allowMandatoryActivities,

        actionTitle: newEntry.outcomeId
            ? !hasRole([QUALITY, SUPERUSER], roles)
            : !hasRole([ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER], roles),

        action: newEntry.outcomeId
            ? !hasRole([QUALITY, SUPERUSER], roles)
            : !hasRole([ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER], roles),

        actionStartDate: newEntry.outcomeId
            ? !hasRole([QUALITY, SUPERUSER], roles)
            : !hasRole([ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER], roles),

        actionDueDate: newEntry.outcomeId
            ? !hasRole([QUALITY, SUPERUSER], roles)
            : !hasRole([ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER], roles),

        actionOutcomeDate: !hasRole([QUALITY, SUPERUSER], roles) || !newEntry.outcomeId,

        outcomeId: newEntry.outcomeId
            ? !hasRole([QUALITY, SUPERUSER], roles)
            : !hasRole([ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER], roles),

        comments: !hasRole([ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER], roles)
    };
};

export const reConfigDisabled = (isDisabled, row, newEntry) => {
    let disableDueDate = isDisabled.actionDueDate;
    let disableOutcomeDate = isDisabled.actionOutcomeDate;

    if (row.actionDueDate && row.actionDueDate.slice(0, 10) === newEntry.actionDueDate)
        disableDueDate = true;

    if (row.actionOutcomeDate && row.actionOutcomeDate.slice(0, 10) === newEntry.actionOutcomeDate)
        disableOutcomeDate = true;

    return {
        ...isDisabled,
        actionDueDate: disableDueDate,
        actionOutcomeDate: disableOutcomeDate
    };
};
