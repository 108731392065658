export const modalContentFinancialRequestValidationField = {
    contract: {
        subject: 'Contract',
        required: 'select'
    },
    serviceId: {
        subject: 'Service',
        required: 'select'
    },
    teamIds: {
        subject: 'Team',
        required: 'select'
    }
};
