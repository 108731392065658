import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { reverseFormatDate } from '../../../../../utils/dateFunctions';
import { getNameFromId } from '../../../../../utils/directusFunctions';

import classes from '../../../../../commonStyles/pdf.module.css';

const CourseTable = ({ onLoaded, currentParticipant }) => {
    // LOCAL STATE
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState('true');

    // STORE STATE
    const courses = useSelector((state) => state.entities.courseService.participantsCourses);
    const loadingCourses = useSelector(
        (state) => state.entities.courseService.loadingParticipantsCourses
    );
    const attendanceDetails = useSelector(
        (state) => state.entities.directusService.attendanceDetails
    );
    const locations = useSelector((state) => state.entities.directusService.locationsForServices);
    const users = useSelector((state) => state.entities.userService.users);

    // USE EFFECTS
    useEffect(() => {
        if (!loadingCourses && loading === 'true') setLoading('startLoading');
        if (!loadingCourses && loading === 'startLoading') setLoading('false');
    }, [loadingCourses]);

    useEffect(() => {
        if (courses?.length < 1 && loading === 'false') {
            onLoaded('courses');
            return;
        }
        if (users?.length < 1 || locations?.length < 1 || attendanceDetails?.length < 1) return;
        const moduleList = courses
            .map((el) =>
                el.modules.map((entry) => ({
                    ...entry,
                    courseCode: el.code,
                    currentParticipant:
                        entry.participants.find(
                            (pt) => pt.participantId === currentParticipant.id
                        ) || null
                }))
            )
            .flat()
            .filter((el) => el.currentParticipant)
            .map((el) => {
                const user = users.find((entry) => entry.id === el.userId);
                return {
                    id: el.courseCode,
                    module: el?.title,
                    startDate: el?.date ? reverseFormatDate(el.date) : ' - ',
                    startTime: el?.startTime || ' - ',

                    trainer:
                        user?.firstName && user?.lastName
                            ? `${user.firstName} ${user.lastName}`
                            : ' - ',
                    location: getNameFromId(locations, el?.locationId) || '',
                    status:
                        getNameFromId(attendanceDetails, el?.currentParticipant?.statusId) || ' - '
                };
            });
        setRows(moduleList);
    }, [courses, users, locations, attendanceDetails, loading]);

    useEffect(() => {
        rows.length > 0 && onLoaded('courses');
    }, [rows]);

    // RENDER
    return (
        <div>
            <h3>Courses</h3>
            {rows.length < 1 ? (
                'No courses'
            ) : (
                <div className={classes.pdfTable}>
                    <div className={classes.pdfTableHeaders}>
                        <span>Course ID</span>
                        <span>Module</span>
                        <span>Start Date</span>
                        <span>Start Time</span>
                        <span>Trainer</span>
                        <span>Location</span>
                        <span>Status</span>
                    </div>

                    <div className={classes.pdfTableRows}>
                        {rows.map((el, i) => (
                            <div
                                key={i}
                                id={`${el.id}--${i}`}
                                className={`${classes.pdfTableRow} ${
                                    i % 2 === 0 ? classes.rowPlain : classes.rowFilled
                                }`}>
                                <span>{el.id}</span>
                                <span>{el.module}</span>
                                <span>{el.startDate}</span>
                                <span>{el.startTime}</span>
                                <span>{el.trainer}</span>
                                <span className={`${!el.location ? classes.alignColInMed : ''}`}>
                                    {el.location || ' - '}
                                </span>
                                <span>{el.status}</span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

CourseTable.propTypes = {
    currentParticipant: PropTypes.object,
    onLoaded: PropTypes.func
};

export default CourseTable;
