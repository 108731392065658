import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SignaturePad from 'react-signature-pad-wrapper';

import Button from '../../formElements/Button';

import Confirmation from './Confirmation';

import local from './styles/appointments.module.css';

const ESignature = ({
    currentParticipant,
    onSignatureSkip,
    onSignatureCancel,
    onSignatureConfirm,
    onAdviserSignatureSave,
    onParticipantSignatureSave,
    attendanceName
}) => {
    const signaturePadRef = useRef(null);

    // LOCAL STATE
    const [hasFirstConfirmation, setHasFirstConfirmation] = useState(false);
    const [hasSecondConfirmation, setHasSecondConfirmation] = useState(false);
    const [hasThirdConfirmation, setHasThirdConfirmation] = useState(false);

    // HELPER FNS
    const clearPad = () => signaturePadRef.current?.clear();
    const saveSignature = () => signaturePadRef.current?.toDataURL();

    // EVENT HANDLERS
    const onSkip = () => {
        setHasFirstConfirmation(true);
        setHasSecondConfirmation(true);
        setHasThirdConfirmation(true);
        onSignatureSkip();
    };

    const onConfirm = () => {
        if (!hasFirstConfirmation && signaturePadRef.current.isEmpty()) return;
        if (!hasFirstConfirmation) {
            setHasFirstConfirmation(true);
            onAdviserSignatureSave(saveSignature());
            if (attendanceName.toLowerCase() !== 'attended') {
                onSkip();
            }
        } else if (hasFirstConfirmation && !hasSecondConfirmation) {
            setHasSecondConfirmation(true);
            clearPad();
        } else if (hasFirstConfirmation && hasSecondConfirmation) {
            setHasThirdConfirmation(true);
            onParticipantSignatureSave(saveSignature());
            onSignatureConfirm();
        }
    };

    // RENDER
    return (
        <div className={local.eSigModal}>
            <div className={local.eSigHeaderRow}>
                <h3>
                    {hasSecondConfirmation
                        ? hasThirdConfirmation
                            ? ''
                            : "Participant's Signature"
                        : hasFirstConfirmation
                          ? 'Statement'
                          : "Adviser's Signature"}
                </h3>
                {!hasFirstConfirmation && (
                    <Button id="skipButton" type="text" content="Skip" onClick={onSkip} />
                )}
            </div>
            <div>
                {(!hasFirstConfirmation ||
                    (attendanceName.toLowerCase() === 'attended' &&
                        hasFirstConfirmation &&
                        hasSecondConfirmation)) &&
                    !hasThirdConfirmation && (
                        <>
                            <div className={local.eSigPadWrapper}>
                                <div className={local.eSigPad} id="eSigPad" data-testid="eSigPad">
                                    <SignaturePad
                                        width={200}
                                        height={150}
                                        ref={signaturePadRef}
                                        options={{
                                            minWidth: 1,
                                            maxWidth: 2,
                                            penColor: 'rgb(0, 0, 0)'
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={local.eSigPadPrompt}>Sign your name</div>
                        </>
                    )}
                {hasFirstConfirmation &&
                    attendanceName.toLowerCase() === 'attended' &&
                    !hasSecondConfirmation && <Confirmation participant={currentParticipant} />}
            </div>
            {!hasThirdConfirmation && (
                <div className={local.eSigModalButtons}>
                    {!(hasFirstConfirmation && !hasSecondConfirmation) && (
                        <Button id="resetButton" type="text" content="Reset" onClick={clearPad} />
                    )}
                    <Button id="confirmButton" type="text" content="Confirm" onClick={onConfirm} />
                    <Button
                        id="cancelButton"
                        type="text"
                        content="Return"
                        onClick={onSignatureCancel}
                    />
                </div>
            )}
        </div>
    );
};

ESignature.propTypes = {
    currentParticipant: PropTypes.object,
    onSignatureSkip: PropTypes.func,
    onSignatureCancel: PropTypes.func,
    onSignatureConfirm: PropTypes.func,
    onParticipantSignatureSave: PropTypes.func,
    onAdviserSignatureSave: PropTypes.func,
    attendanceName: PropTypes.string
};

export default ESignature;
