import PropTypes from 'prop-types';

import actions from './formActions.module.css';

const FormActionsCancel = ({ onCancel, customClass = '' }) => {
    return (
        <div className={`${actions.formActions} ${actions[customClass]}`}>
            <div
                data-testid="form_actions_cancel_button"
                className={actions.cancelLink}
                onClick={onCancel}>
                Cancel X
            </div>
        </div>
    );
};

FormActionsCancel.propTypes = {
    onCancel: PropTypes.func,
    customClass: PropTypes.string
};

export default FormActionsCancel;
