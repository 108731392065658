import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    searchAppointmentAttendanceDetails,
    searchParticipantAppointments
} from '../../../../store/calendarService';
import {
    loadDocumentTypes,
    loadJobSectorDetails,
    loadParticipantStatusDetails,
    loadPoolAcceptedRejectionReasonsDetails,
    loadPoolRequestedRejectionReasonsDetails,
    loadPoolStatusDetails
} from '../../../../store/directusService';
import { loadParticipantDocumentDetails } from '../../../../store/documentService';
import { loadParticipantEmployability } from '../../../../store/participantService';
import {
    loadPoolApplicationsByParticipant,
    loadPoolsForApplications
} from '../../../../store/recruitmentService';
import { loadUser } from '../../../../store/userService';
import { getNameFromId } from '../../../../utils/directusFunctions';
import {
    DOCUMENT_TYPES_ERROR,
    JOB_SECTOR_DETAILS_ERROR,
    PARTICIPANT_STATUS_DETAILS_ERROR,
    POOL_ACCEPTED_REJECTION_REASONS_DETAILS_ERROR,
    POOL_REQUESTED_REJECTION_REASONS_DETAILS_ERROR,
    POOL_STATUS_DETAILS_ERROR
} from '../../../../utils/formValidation/loadingErrorMessageConstants';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import Button from '../../../formElements/Button';
import DynamicSearch from '../../../search/DynamicSearch';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import ApplyToPoolEditor from './selectPools/ApplyToPoolEditor';
import PoolsTable from './PoolsTable';

import app from '../../../../app.module.css';
import classes from '../submissionStyles/pools.module.css';

const PoolsTableManagement = () => {
    //HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const participantClosedStatusRoles = [MANAGER, QUALITY, SUPERUSER];
    const [participantDocumentsAddName, setParticipantDocumentsAddName] = useState([]);
    const [participantPools, setParticipantPools] = useState([]);
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [poolApplicationsLoaded, setPoolApplicationsLoaded] = useState(false);
    const [storedPools, setStoredPools] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [poolEditor, setPoolEditor] = useState(false);

    // STORE STATE
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const documentTypes = useSelector((state) => state.entities.directusService.documentTypes);
    const jobSectorDetails = useSelector(
        (state) => state.entities.directusService.jobSectorDetails
    );
    const participantStatusDetails = useSelector(
        (state) => state.entities.directusService.participantStatusDetails
    );
    const poolAcceptedRejectionReasonsDetails = useSelector(
        (state) => state.entities.directusService.poolAcceptedRejectionReasonsDetails
    );
    const poolRequestedRejectionReasonsDetails = useSelector(
        (state) => state.entities.directusService.poolRequestedRejectionReasonsDetails
    );
    const poolStatusDetails = useSelector(
        (state) => state.entities.directusService.poolStatusDetails
    );
    const participantDocuments = useSelector(
        (state) => state.entities.documentService.participantDocuments
    );
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const poolApplications = useSelector(
        (state) => state.entities.recruitmentService.poolApplications
    );
    const pools = useSelector((state) => state.entities.recruitmentService.pools);
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);

    // USE EFFECTS
    useEffect(() => {
        documentTypes?.length < 1 && dispatch(loadDocumentTypes());
        jobSectorDetails?.length < 1 && dispatch(loadJobSectorDetails());
        participantStatusDetails?.length < 1 && dispatch(loadParticipantStatusDetails());
        poolAcceptedRejectionReasonsDetails?.length < 1 &&
            dispatch(loadPoolAcceptedRejectionReasonsDetails());
        poolRequestedRejectionReasonsDetails?.length < 1 &&
            dispatch(loadPoolRequestedRejectionReasonsDetails());
        poolStatusDetails?.length < 1 && dispatch(loadPoolStatusDetails());

        dispatch(searchParticipantAppointments(currentParticipant.id));
        dispatch(searchAppointmentAttendanceDetails({ participantIds: [currentParticipant.id] }));
        dispatch(loadParticipantDocumentDetails(currentParticipant.id));
        dispatch(loadParticipantEmployability(currentParticipant.id));
        dispatch(loadPoolApplicationsByParticipant(currentParticipant.id));
    }, []);

    useEffect(() => {
        if (poolApplications.length < 1) return;

        setStoredPools([]);
        const poolIds = poolApplications.map((el) => el.participantPoolId);
        dispatch(loadPoolsForApplications(poolIds));
    }, [poolApplications]);

    useEffect(() => {
        if (poolApplications.length < 1 || pools.length < 1 || storedPools.length > 0) return;

        setStoredPools(pools);
    }, [pools]);

    useEffect(() => {
        if (storedPools.length < 1) return;

        let updatedPoolApplications = poolApplications;

        storedPools.forEach((el) => {
            dispatch(loadUser(el.ownerId));
            updatedPoolApplications = updatedPoolApplications.map((entry) => {
                if (entry.participantPoolId === el.id) {
                    return {
                        ...entry,
                        code: el.code,
                        ownerId: el.ownerId,
                        ownerName: el.ownerId,
                        sectorId: el.jobSectorId
                    };
                } else {
                    return entry;
                }
            });
        });

        setParticipantPools(updatedPoolApplications);
        setRows(updatedPoolApplications);
    }, [storedPools]);

    useEffect(() => {
        if (participantDocuments?.length === 0) {
            setParticipantDocumentsAddName([]);
            return;
        }

        if (
            !participantDocuments?.length ||
            documentTypes?.length < 1 ||
            (participantPools?.length < 1 && !poolApplicationsLoaded)
        )
            return;

        const goldStandardCVId = documentTypes.find(
            (entry) => entry.name.toLowerCase() === 'gold standard cv'
        )?.id;
        const updated = participantDocuments
            .filter((el) => el.typeId === goldStandardCVId)
            .map((el) => ({ ...el, name: el.filename }));
        setParticipantDocumentsAddName(updated);

        const updatedPoolApplications = participantPools.map((el) => ({
            ...el,
            sectorName: getNameFromId(jobSectorDetails, el.sectorId),
            filename: getDocumentName(el.goldStandardCVId),
            statusName: getNameFromId(poolStatusDetails, el.statusId)
        }));

        setRows(updatedPoolApplications);
        setFilteredRows(updatedPoolApplications);
    }, [participantDocuments, documentTypes, poolApplicationsLoaded, participantPools]);

    useEffect(() => {
        if (!rows.length) return;

        const filtered = !searchTerm
            ? rows
            : rows.filter((el) => el.sectorName.toLowerCase().includes(searchTerm.toLowerCase()));
        setFilteredRows(filtered);
    }, [searchTerm]);

    useEffect(() => {
        if (successMessage === `Pool applications have been loaded`) {
            setPoolApplicationsLoaded(true);
        }
    }, [successMessage]);

    // HELPER FNS
    const getDocumentName = (id) => {
        if (!id || id === '') {
            return '';
        }
        return participantDocuments.find((item) => item.id === id)?.filename || '';
    };

    const loadingError = () => {
        if (documentTypes?.length < 1) return DOCUMENT_TYPES_ERROR;
        if (jobSectorDetails?.length < 1) return JOB_SECTOR_DETAILS_ERROR;
        if (poolAcceptedRejectionReasonsDetails?.length < 1)
            return POOL_ACCEPTED_REJECTION_REASONS_DETAILS_ERROR;
        if (poolRequestedRejectionReasonsDetails?.length < 1)
            return POOL_REQUESTED_REJECTION_REASONS_DETAILS_ERROR;
        if (poolStatusDetails?.length < 1) return POOL_STATUS_DETAILS_ERROR;
        if (participantStatusDetails?.length < 1) return PARTICIPANT_STATUS_DETAILS_ERROR;
    };

    // EVENT HANDLERS
    const onSearch = (searchRes) => setSearchTerm(searchRes);

    // EVENT HANDLER CALLBACKS
    const onPoolEditor = (setOpen) => setPoolEditor(setOpen);

    // AWAITING CONTENT
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} time={3} />;

    // RENDER
    return (
        <>
            <h2 className={app.sectionHeading}>Participant Pooling</h2>
            <div className={classes.poolToolBar}>
                <DynamicSearch
                    search={onSearch}
                    searchTerm={searchTerm}
                    placeholder="Enter Search Term"
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                />
                <div>
                    <Button
                        id="ApplyToPoolButton"
                        type="button"
                        customClass="toolBarButton"
                        disabled={
                            !hasRole(acceptedRoles, loggedInUser.roles) ||
                            (getNameFromId(
                                participantStatusDetails,
                                currentParticipant.statusId
                            ) === 'Closed' &&
                                !hasRole(participantClosedStatusRoles, loggedInUser.roles))
                        }
                        content="Apply to new Pool"
                        icon={true}
                        onClick={() => onPoolEditor(true)}
                    />
                    <ApplyToPoolEditor
                        open={poolEditor}
                        onPoolEditor={onPoolEditor}
                        participantDocuments={participantDocumentsAddName}
                        poolApplications={poolApplications}
                    />
                </div>
            </div>

            {filteredRows.length < 1 ? (
                <LoadingSpinner content="No participant pools found" />
            ) : (
                <PoolsTable
                    rows={filteredRows}
                    roles={loggedInUser.roles}
                    searchTerm={searchTerm}
                />
            )}
        </>
    );
};

export default PoolsTableManagement;
