import React from 'react';
import PropTypes from 'prop-types';

const AppointmentTypeSelect = ({
    appointmentTypes = [],
    appointmentTypeId,
    onAppointmentTypeChange,
    errors = {},
    disabled = false
}) => {
    const onChange = (id) => {
        const appointmentType = appointmentTypes.find((el) => el.id === id)?.name || '';
        onAppointmentTypeChange('typeId', id, appointmentType);
    };

    // RENDER
    return (
        <SingleSelect
            id="appointmentTypeId"
            label="Appointment Type"
            placeholder="Select appointment type"
            mandatory={true}
            disabled={disabled}
            menuItems={appointmentTypes}
            selectedId={appointmentTypeId || ''}
            selected={appointmentTypes.find((el) => el.id === appointmentTypeId)}
            error={errors.typeId}
            onChange={onChange}
        />
    );
};

import SingleSelect from '../../formElements/SingleSelect';

AppointmentTypeSelect.propTypes = {
    appointmentTypes: PropTypes.arrayOf(PropTypes.object),
    appointmentTypeId: PropTypes.string,
    onAppointmentTypeChange: PropTypes.func,
    errors: PropTypes.object,
    disabled: PropTypes.bool
};

export default AppointmentTypeSelect;
