import React, { useState } from 'react';

import { Accordion } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import AccordionHeader from '../../ui/accordion/AccordionHeader';
import AccordionIcon from '../../ui/accordion/AccordionIcon';
import AssetRequest from '../requests/assetRequests/AssetRequest';
import FinancialRequest from '../requests/financialRequests/FinancialRequest';

const Requests = () => {
    const [expanded, setExpanded] = useState('');

    const onAccordionChange = (panel) => {
        setExpanded(expanded === panel ? '' : panel);
    };

    return (
        <div>
            <Accordion
                slotProps={{ transition: { mountOnEnter: true } }}
                expanded={expanded === 'financialRequests'}
                onChange={() => onAccordionChange('financialRequests')}>
                <AccordionSummary
                    expandIcon={<AccordionIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <AccordionHeader>Financial Requests</AccordionHeader>
                </AccordionSummary>
                <AccordionDetails>
                    <FinancialRequest />
                </AccordionDetails>
            </Accordion>

            <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
                <AccordionSummary
                    expandIcon={<AccordionIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header">
                    <AccordionHeader>Asset Requests</AccordionHeader>
                </AccordionSummary>
                <AccordionDetails>
                    <AssetRequest />
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default Requests;
