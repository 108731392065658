import { isDateAfter } from '../../../utils/dateFunctions';
import { NO_UPDATE_ERROR } from '../../../utils/formValidation/commonErrorConstants';
import {
    getDateMinMessage,
    getReqMessage
} from '../../../utils/formValidation/errorMessageFunctions';

export const initialErrorState = {};

export const validate = (newEntry, contractConstants) => {
    let newErrors = {};
    if (!newEntry.startDate) {
        newErrors = {
            ...newErrors,
            startDate: { error: true, message: getReqMessage('Start date') }
        };
    }

    if (!newEntry.endDate) {
        newErrors = {
            ...newErrors,
            endDate: { error: true, message: getReqMessage('End date') }
        };
    } else if (!isDateAfter(newEntry.endDate, newEntry.startDate)) {
        newErrors = {
            ...newErrors,
            endDate: { error: true, message: getDateMinMessage('start date') }
        };
    }

    if (Object.keys(newErrors).length < 1) {
        if (
            newEntry.active === contractConstants.active &&
            newEntry.startDate === contractConstants.startDate &&
            newEntry.endDate === contractConstants.endDate
        ) {
            newErrors = { button: { error: true, message: NO_UPDATE_ERROR } };
        }
    }

    return newErrors;
};
