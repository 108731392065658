import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import LoadingSpinner from '../../../ui/LoadingSpinner';

import SelectBarriersTable from './SelectBarriersTable';

const SelectBarriersTableManagement = ({ onRowsUpdated, barrierNameDetails }) => {
    // LOCAL STATE
    const [updatedRows, setUpdatedRows] = useState([]);

    // STORE STATE
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);

    // EVENT HANDLERS

    // HELPER FNS
    const onRowChange = (row, invalidDate) => {
        const rows = updatedRows.map((el) => (el.id === row.id ? row : el));
        setUpdatedRows(rows);
        onRowsUpdated(rows, invalidDate);
    };

    // USE EFFECTS
    useEffect(() => {
        const currentDate = new Date().toISOString().slice(0, 10);

        setUpdatedRows(
            barrierNameDetails.map((el) => ({
                participantId: currentParticipant.id,
                id: el.id,
                name: el.name,
                priority: 'Medium',
                startDate: currentDate,
                dueDate: currentDate
            }))
        );
    }, []);

    // RENDER
    return (
        <>
            {updatedRows.length < 1 ? (
                <LoadingSpinner content="No pools found" />
            ) : (
                <SelectBarriersTable
                    rows={updatedRows}
                    roles={loggedInUser.roles}
                    onRowChange={onRowChange}
                />
            )}
        </>
    );
};

SelectBarriersTableManagement.propTypes = {
    onRowsUpdated: PropTypes.func,
    barrierNameDetails: PropTypes.array
};

export default SelectBarriersTableManagement;
