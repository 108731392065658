import React from 'react';
import PropTypes from 'prop-types';

import { TableCell, TableRow } from '@mui/material';

import classes from '../../../commonStyles/tables.module.css';

const AuditAppointmentTableRow = ({ row }) => (
    <TableRow hover className={`${classes.basicRowStripes}`}>
        <TableCell>{row.oldValue || ' - '}</TableCell>
        <TableCell>{row.newValue || ' - '}</TableCell>
        <TableCell>{row.user || ' - '}</TableCell>
        <TableCell>{row.formattedDate || ' - '}</TableCell>
        <TableCell>{row.formattedTime || ' - '}</TableCell>
    </TableRow>
);

AuditAppointmentTableRow.propTypes = {
    row: PropTypes.object
};

export default AuditAppointmentTableRow;
