import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setLicenseKey } from 'survey-core';
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react';

import { Checkbox } from '@mui/material';

import { loadQuestionnaireTypes } from '../../store/directusService';
import { setErrorMessage } from '../../store/formsState';
import { selectCurrentQuestionnaire } from '../../store/participantSelectors';
import {
    createQuestionnaire,
    loadQuestionnaires,
    updateQuestionnaire
} from '../../store/participantService';
import { selectUserRoles } from '../../store/userSelectors';
import { POLARIS_ROYAL_BLUE } from '../../themes/theme';
import { hasRole, SUPERUSER } from '../../utils/userRoles';
import SingleSelect from '../formElements/SingleSelect';

import { themeJson } from './theme';

import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import form from '../../commonStyles/formStyles.module.css';
import classes from './questionnaireStyles/questionnaireToolbar.module.css';

const VITE_APP_SURVEYJS_BUILDER_LICENCE = import.meta.env.VITE_APP_SURVEYJS_BUILDER_LICENCE;

const QuestionnaireBuilder = () => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const currentQuestionnaire = useSelector(selectCurrentQuestionnaire);
    const questionnaireAnswers = useSelector(
        (state) => state.entities.participantService.questionnaireAnswers
    );
    const questionnaireTypes = useSelector(
        (state) => state.entities.directusService.questionnaireTypes
    );

    // LOCAL STATE
    setLicenseKey(VITE_APP_SURVEYJS_BUILDER_LICENCE);

    const questionTypes = [
        'text',
        'comment',
        'checkbox',
        'radiogroup',
        'dropdown',
        'boolean',
        'rating',
        'matrix',
        'matrixdropdown',
        'matrixdynamic',
        'multipletext',
        'panel',
        'paneldynamic',
        'expression',
        'ranking',
        'buttongroup',
        'flowpanel'
    ];

    const [creator, setCreator] = useState(
        new SurveyCreator({
            showDesignerTab: true,
            showLogicTab: true,
            showThemeTab: false,
            showJSONEditorTab: true,
            isAutoSave: false,
            questionTypes: questionTypes
        })
    );
    const acceptedRoles = [SUPERUSER];
    const [inactive, setInactive] = useState(currentQuestionnaire?.inactive || false);
    const [questionnaireChanged, setQuestionnaireChanged] = useState(false);

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadQuestionnaireTypes());
    }, []);

    useEffect(() => {
        if (questionnaireChanged) {
            dispatch(loadQuestionnaires());
            navigate('/manage_questionnaire');
        }
    }, [questionnaireChanged]);

    useEffect(() => {
        const hasResults =
            currentQuestionnaire && questionnaireAnswers && questionnaireAnswers.length > 0;

        const surveyOptions = {
            showDesignerTab: !hasResults,
            showLogicTab: !hasResults,
            showThemeTab: false,
            showJSONEditorTab: true,
            isAutoSave: false,
            questionTypes: questionTypes
        };

        setCreator(new SurveyCreator(surveyOptions));
    }, [questionnaireAnswers]);

    useEffect(() => {
        creator.theme = themeJson;

        if (currentQuestionnaire) {
            creator.text = JSON.stringify(currentQuestionnaire.data);
        }

        if (questionnaireAnswers.length === 0) {
            creator.saveSurveyFunc = () => {
                onUpdateQuestionnaire();
            };
        }
    }, [creator, currentQuestionnaire, questionnaireAnswers]);

    // EVENT HANDLERS
    const onQuestionnaireTypeChange = (chosenId) => {
        if (
            !currentQuestionnaire ||
            (!currentQuestionnaire.typeId && chosenId === '') ||
            currentQuestionnaire.typeId === chosenId
        )
            return;

        const payload = {
            ...currentQuestionnaire,
            typeId: chosenId
        };
        dispatch(updateQuestionnaire(payload));
    };

    const onUpdateQuestionnaire = () => {
        let error = false;

        const MAX_TITLE_LENGTH = 50;
        const MAX_DESCRIPTION_LENGTH = 250;

        if (creator.survey.title.length === 0 || creator.survey.title.length > MAX_TITLE_LENGTH) {
            dispatch(
                setErrorMessage(
                    `Questionnaire title must be between 1 and ${MAX_TITLE_LENGTH} characters.`
                )
            );
            error = true;
        }

        if (creator.survey.description.length > MAX_DESCRIPTION_LENGTH) {
            dispatch(
                setErrorMessage(
                    `Questionnaire description must be ${MAX_DESCRIPTION_LENGTH} characters or less.`
                )
            );
            error = true;
        }

        creator.survey.pages.forEach((page, index) => {
            if (page.title.length > MAX_TITLE_LENGTH) {
                dispatch(
                    setErrorMessage(
                        `Questionnaire page ${
                            index + 1
                        } title must be ${MAX_TITLE_LENGTH} characters or less`
                    )
                );
                error = true;
            }
        });

        creator.survey.pages.forEach((page, index) => {
            if (page.description.length > MAX_DESCRIPTION_LENGTH) {
                dispatch(
                    setErrorMessage(
                        `Questionnaire page ${
                            index + 1
                        } description must be ${MAX_DESCRIPTION_LENGTH} characters or less`
                    )
                );
                error = true;
            }
        });

        if (error) {
            return;
        }

        if (Object.keys(currentQuestionnaire).length > 0) {
            const payload = {
                id: currentQuestionnaire.id,
                title: creator.survey.title,
                code: currentQuestionnaire.code,
                date_created: currentQuestionnaire.date_created,
                data: JSON.parse(creator.text),
                inactive: inactive,
                typeId: currentQuestionnaire.typeId
            };
            dispatch(updateQuestionnaire(payload));
        } else {
            const payload = {
                title: creator.survey.title,
                data: JSON.parse(creator.text),
                inactive: false
            };
            dispatch(createQuestionnaire(payload));
        }
        setQuestionnaireChanged(true);
    };

    const onCheck = (e) => {
        const { checked } = e.target;

        const newInactiveState = !checked;
        setInactive(newInactiveState);

        const payload = {
            ...currentQuestionnaire,
            inactive: newInactiveState
        };

        dispatch(updateQuestionnaire(payload));
    };

    return (
        <>
            <div className={classes.questionnaireBuilder}>
                <SurveyCreatorComponent creator={creator} />
                <form>
                    <div className={form.formWrapper}>
                        <div className={form.formSection}>
                            <div className={form.formColumn}>
                                <label htmlFor="activeCheckbox">Active</label>
                                <Checkbox
                                    key={currentQuestionnaire?.id || 'id'}
                                    type="checkbox"
                                    sx={{ color: POLARIS_ROYAL_BLUE }}
                                    checked={!inactive}
                                    onChange={onCheck}
                                />
                            </div>
                            <div className={form.formColumn}>
                                <SingleSelect
                                    id={'QuestionnaireTypeId'}
                                    label={'Questionnaire Type'}
                                    placeholder="Select questionnaire type..."
                                    disabled={!hasRole(acceptedRoles, roles)}
                                    menuItems={questionnaireTypes || []}
                                    selectedId={currentQuestionnaire.typeId || ''}
                                    selected={
                                        questionnaireTypes?.find(
                                            (el) => el.id === currentQuestionnaire.typeId
                                        ) || {}
                                    }
                                    onChange={(chosenId) => onQuestionnaireTypeChange(chosenId)}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default QuestionnaireBuilder;
