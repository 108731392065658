import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';

import { Button } from '@mui/material';

import { loadDocumentTypes } from '../../../store/directusService';
import { selectCurrentForm, selectCurrentParticipant } from '../../../store/participantSelectors';
import {
    authoriseFormParticipant,
    loadForm,
    reopenFormParticipant,
    updateFormParticipant,
    withdrawFormParticipant
} from '../../../store/participantService';
import { selectCurrentUser } from '../../../store/userSelectors';
import { loadUser } from '../../../store/userService';
import { hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import AddDocument from '../../documents/AddDocument';
import GenerateFormPDF from '../../pdfGenerator/GenerateFormPDF';

import { themeJson } from './FormTheme';

import form from '../../../commonStyles/formStyles.module.css';
import actions from '../../ui/formActions/formActions.module.css';

const EditForm = ({ row, onClose, roles }) => {
    //HOOKS
    const dispatch = useDispatch();

    //LOCAL STATE
    const authoriseRoles = [MANAGER, QUALITY, SUPERUSER];

    const [surveyModel, setSurveyModel] = useState(new Model('{}'));
    const [isSucceeded, setIsSucceeded] = useState(false);
    const [showDocumentDownload, setShowDocumentDownload] = useState(false);
    const [docType, setDocType] = useState(false);
    const [generatePdf, setGeneratePdf] = useState(false);
    const [surveyComplete, setSurveyComplete] = useState(false);
    const [authorisedButtonDisabled, setAuthorisedButtonDisabled] = useState(false);
    const [withdrawnButtonDisabled, setWithdrawnButtonDisabled] = useState(false);
    const [reopenButtonDisabled, setReopenButtonDisabled] = useState(false);

    //STORE STATE
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const currentParticipant = useSelector(selectCurrentParticipant);
    const currentForm = useSelector(selectCurrentForm);
    const currentUser = useSelector(selectCurrentUser);
    const formStatusDetails = useSelector(
        (state) => state.entities.directusService.formStatusDetails
    );
    const documentTypes = useSelector((state) => state.entities.directusService.documentTypes);

    // EVENT HANDLERS
    const onFormExit = () => {
        onClose();
    };

    const onExit = () => {
        setGeneratePdf(false);
        setIsSucceeded(false);
        setShowDocumentDownload(false);
    };

    const onSurveyComplete = () => {
        setSurveyComplete(true);
    };

    const onAuthorise = () => {
        dispatch(authoriseFormParticipant(row.id));
    };

    const onWithdraw = () => {
        dispatch(withdrawFormParticipant(row.id));
    };

    const onReopen = () => {
        dispatch(reopenFormParticipant(row.id));
    };

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadForm(row.formId));
        documentTypes.length < 1 && dispatch(loadDocumentTypes());
    }, []);

    useEffect(() => {
        if (documentTypes.length < 1) return;
        setDocType(documentTypes.find((el) => el.name === 'Form'));
    }, [documentTypes]);

    useEffect(() => {
        surveyModel.data = row.data;
    }, [surveyModel, row]);

    useEffect(() => {
        if (currentParticipant.userId) {
            dispatch(loadUser(currentParticipant.userId));
        }
    }, [currentParticipant]);

    useEffect(() => {
        if (Object.keys(currentForm).length < 1) return;

        const model = new Model(currentForm.data);
        model.applyTheme(themeJson);
        setSurveyModel(model);
    }, [currentForm]);

    useEffect(() => {
        if (surveyComplete) {
            const payload = {
                id: row.id,
                participantId: currentParticipant.id,
                statusId: formStatusDetails.find((el) => el.name === 'Open')?.id || '',
                pdfName: currentForm.name,
                formId: currentForm.id,
                data: surveyModel.data
            };
            dispatch(updateFormParticipant(payload));
        }
    }, [surveyComplete]);

    useEffect(() => {
        if (successMessage === `Form participant has been authorised successfully`) {
            setGeneratePdf(true);
            setAuthorisedButtonDisabled(true);
            setWithdrawnButtonDisabled(true);
            setReopenButtonDisabled(true);
        } else if (
            successMessage === `Form participant has been updated successfully` ||
            successMessage === `Form participant has been reopened successfully` ||
            successMessage === `Form participant has been withdrawn successfully`
        ) {
            onFormExit();
        } else if (
            successMessage.startsWith(`Document`) &&
            successMessage.endsWith(`has been uploaded`)
        )
            onFormExit();
    }, [successMessage]);

    // CALLBACKS
    const onPdfGenerated = (isPdfGenerated) => {
        setIsSucceeded(isPdfGenerated);
        setShowDocumentDownload(isPdfGenerated);
        setGeneratePdf(false);
    };

    // RENDER
    return (
        <div>
            <Survey model={surveyModel} onComplete={onSurveyComplete} />
            <div className={actions.formActions}>
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={onAuthorise}
                    disabled={
                        !hasRole(authoriseRoles, roles) ||
                        row.statusName === 'Authorised' ||
                        authorisedButtonDisabled
                    }>
                    {' '}
                    Authorise
                </Button>
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={onWithdraw}
                    disabled={
                        !hasRole(authoriseRoles, roles) ||
                        row.statusName === 'Withdrawn' ||
                        withdrawnButtonDisabled
                    }>
                    {' '}
                    Withdraw
                </Button>
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={onReopen}
                    disabled={
                        !hasRole(authoriseRoles, roles) ||
                        row.statusName === 'Open' ||
                        reopenButtonDisabled
                    }>
                    {' '}
                    Re-Open
                </Button>
                <div className={actions.cancelLink} onClick={onFormExit}>
                    Cancel X
                </div>
            </div>
            {generatePdf && (
                <GenerateFormPDF
                    surveyModel={surveyModel}
                    form={currentForm}
                    row={row}
                    currentParticipant={currentParticipant}
                    currentUser={currentUser}
                    onPdfGenerated={onPdfGenerated}
                />
            )}
            {isSucceeded && (
                <div className={form.successMessage}>PDF {currentForm.name} downloaded</div>
            )}
            {showDocumentDownload && (
                <div>
                    <AddDocument
                        onClose={onExit}
                        onCancel={onExit}
                        btnText="Close"
                        currentDocumentType={docType ? docType : null}
                    />
                </div>
            )}
        </div>
    );
};

EditForm.propTypes = {
    row: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string)
};

export default EditForm;
