import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../../../api/pagination';
import {
    loadBusinessSectorDetails,
    loadJobSectorDetails,
    loadVacancyStageDetails
} from '../../../../store/directusService';
import {
    loadBusinessRecords,
    loadVacanciesByOwners,
    loadVacancySubmissionsByParticipantId
} from '../../../../store/recruitmentService';
import { selectLoggedInUser } from '../../../../store/userSelectors';
import { searchUsersForVacancies } from '../../../../store/userService';
import {
    BUSINESS_SECTOR_DETAILS_ERROR,
    JOB_SECTOR_DETAILS_ERROR,
    VACANCY_STAGE_DETAILS_ERROR
} from '../../../../utils/formValidation/loadingErrorMessageConstants';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import CurrentVacanciesTableManagement from './currentVacancies/CurrentVacanciesTableManagement';
import RejectedVacanciesTableManagement from './rejectedVacancies/RejectedVacanciesTableManagement';
import VacancySearchTableManagement from './vacancySearch/VacancySearchTableManagement';

import app from '../../../../app.module.css';
import classes from '../submissionStyles/vacancy.module.css';

const NO_BUSINESSES_ERROR = 'No businesses found';
const NO_VACANCIES_FOR_OWNERS_ERROR = 'No vacancy for owner details';
const NO_VACANCY_SUBMISSIONS_ERROR = 'No vacancy submissions';

const Vacancies = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [businessesForVacancies, setBusinessesForVacancies] = useState([]);
    const [missingUserIds, setMissingUserIds] = useState([]);

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const businessSectorDetails = useSelector(
        (state) => state.entities.directusService.businessSectorDetails
    );
    const jobSectorDetails = useSelector(
        (state) => state.entities.directusService.jobSectorDetails
    );
    const vacancyStageDetails = useSelector(
        (state) => state.entities.directusService.vacancyStageDetails
    );
    const vacancySubmissions = useSelector(
        (state) => state.entities.directusService.vacancySubmissions
    );
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const businesses = useSelector((state) => state.entities.recruitmentService.businesses);
    const businessesMetaData = useSelector(
        (state) => state.entities.recruitmentService.businessesMetaData
    );
    const vacanciesForOwners = useSelector(
        (state) => state.entities.recruitmentService.vacanciesForOwners
    );
    const usersForVacancies = useSelector((state) => state.entities.userService.usersForVacancies);
    const usersForVacanciesMetaData = useSelector(
        (state) => state.entities.userService.usersForVacanciesMetaData
    );

    // USE EFFECTS
    useEffect(() => {
        businesses?.length < 1
            ? dispatch(loadBusinessRecords(0, DEFAULT_PAGE_LOAD_SIZE))
            : setBusinessesForVacancies(businesses);
        businessSectorDetails?.length < 1 && dispatch(loadBusinessSectorDetails());
        jobSectorDetails?.length < 1 && dispatch(loadJobSectorDetails());
        vacanciesForOwners?.length < 1 && dispatch(loadVacanciesByOwners());
        vacancyStageDetails?.length < 1 && dispatch(loadVacancyStageDetails());
        dispatch(loadVacancySubmissionsByParticipantId(currentParticipant.id));
    }, []);

    useEffect(() => {
        if (!loggedInUser || Object.keys(loggedInUser).length < 1) return;
        usersForVacancies?.length < 1 &&
            loggedInUser?.serviceIds?.length > 0 &&
            dispatch(
                searchUsersForVacancies(
                    { serviceIds: loggedInUser.serviceIds },
                    0,
                    DEFAULT_PAGE_LOAD_SIZE
                )
            );
    }, [loggedInUser.id]);

    useEffect(() => {
        if (businessesForVacancies?.length < 1) return;

        if (!businessesMetaData.last) {
            dispatch(loadBusinessRecords(businessesMetaData.number + 1, DEFAULT_PAGE_LOAD_SIZE));
        } else setBusinessesForVacancies(businesses);
    }, [businesses, businessesMetaData]);

    useEffect(() => {
        if (vacanciesForOwners?.length < 1 || usersForVacancies?.length < 1) return;

        const ids = [
            ...new Set(
                vacanciesForOwners
                    .map((el) => el.ownerId)
                    .filter((el) => !usersForVacancies.find((entry) => entry.id === el))
            )
        ];
        setMissingUserIds(ids);
    }, [vacanciesForOwners]);

    useEffect(() => {
        if ('last' in usersForVacanciesMetaData && usersForVacanciesMetaData.last) return;

        if (missingUserIds.length > 0) {
            dispatch(
                searchUsersForVacancies(
                    { userIds: missingUserIds },
                    'number' in usersForVacanciesMetaData
                        ? usersForVacanciesMetaData.number + 1
                        : 0,
                    DEFAULT_PAGE_LOAD_SIZE
                )
            );
        }
    }, [missingUserIds, usersForVacanciesMetaData]);

    // HELPER FNS
    const loadingError = () => {
        if (businesses?.length < 1) return NO_BUSINESSES_ERROR;
        if (businessSectorDetails?.length < 1) return BUSINESS_SECTOR_DETAILS_ERROR;
        if (jobSectorDetails?.length < 1) return JOB_SECTOR_DETAILS_ERROR;
        if (vacanciesForOwners?.length < 1) return NO_VACANCIES_FOR_OWNERS_ERROR;
        if (vacancyStageDetails?.length < 1) return VACANCY_STAGE_DETAILS_ERROR;
        if (vacancySubmissions?.length < 1) return NO_VACANCY_SUBMISSIONS_ERROR;
    };

    // AWAITING CONTENT
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} time={3} />;

    // RENDER
    return (
        <div data-testid="div_start">
            <h2 className={app.sectionHeading}>Vacancy Search</h2>
            <VacancySearchTableManagement />
            <h2 className={`${app.sectionHeading} ${classes.currentVacancies}`}>
                Current Vacancies
            </h2>
            <CurrentVacanciesTableManagement />
            <h2 className={`${app.sectionHeading} ${classes.rejectedVacancies}`}>
                Rejected Vacancies
            </h2>
            <RejectedVacanciesTableManagement />
        </div>
    );
};

export default Vacancies;
