import { isDateEarlier, isDateFuture } from '../../../../utils/dateFunctions';
import { NO_VALUE_SELECTED_ERROR } from '../../../../utils/formValidation/commonErrorConstants';

const ERROR_DATE_FUTURE = 'Date must be in the past or present';
const ERROR_DATE_EARLIER = 'Cannot be earlier than event date';
const ERROR_DATE_EARLIER_2 = 'Cannot be earlier than de-intensified date';
const ERROR_NO_ENTRY = 'Please enter details';
const ERROR_MIN_CHAR = 'Please enter at least 5 characters';

export const initialErrorState = {
    eventTypeId: { error: false, message: '' },
    eventDate: { error: false, message: '' },
    dateReportedToNhse: { error: false, message: '' },
    programmeDeIntensifiedDate: { error: false, message: '' },
    programmeReIntensifiedDate: { error: false, message: '' },
    fibreSupplementDate: { error: false, message: '' },
    otherDetail: { error: false, message: '' }
};

export const validate = (newEntry, errors) => {
    let isValid = true;
    let newErrors = errors;

    if (newEntry.eventType === 'Other') {
        let error = false;
        let message = '';
        if (!newEntry.otherDetail || newEntry.otherDetail.trim() === '') {
            message = ERROR_NO_ENTRY;
            error = true;
            isValid = false;
        }
        if (newEntry.otherDetail.trim().length < 5) {
            message = ERROR_MIN_CHAR;
            error = true;
            isValid = false;
        }
        newErrors = {
            ...newErrors,
            otherDetail: { error, message }
        };
    }

    if (!newEntry.eventTypeId) {
        newErrors = {
            ...errors,
            eventTypeId: { error: true, message: NO_VALUE_SELECTED_ERROR }
        };
        isValid = false;
    }

    newErrors = checkDate('eventDate', newEntry.eventDate, newErrors, newEntry);
    if (newErrors.eventDate.error) isValid = false;

    if (newEntry.dateReportedToNhse) {
        newErrors = checkDate(
            'dateReportedToNhse',
            newEntry.dateReportedToNhse,
            newErrors,
            newEntry
        );
        if (newErrors.dateReportedToNhse.error) isValid = false;
    }

    if (newEntry.programmeDeIntensifiedDate) {
        newErrors = checkDate(
            'programmeDeIntensifiedDate',
            newEntry.programmeDeIntensifiedDate,
            newErrors,
            newEntry
        );
        if (newErrors.programmeDeIntensifiedDate.error) isValid = false;
    }

    if (newEntry.programmeReIntensifiedDate) {
        newErrors = checkDate(
            'programmeReIntensifiedDate',
            newEntry.programmeReIntensifiedDate,
            newErrors,
            newEntry
        );
        if (newErrors.programmeReIntensifiedDate.error) isValid = false;
    }

    if (newEntry.fibreSupplementDate) {
        newErrors = checkDate(
            'fibreSupplementDate',
            newEntry.fibreSupplementDate,
            newErrors,
            newEntry
        );
        if (newErrors.fibreSupplementDate.error) isValid = false;
    }

    return { isValid, newErrors };
};

export const checkDate = (key, date, errors, newEntry) => {
    let newErrors = errors;
    newErrors = isDateFuture(date)
        ? { ...newErrors, [key]: { error: true, message: ERROR_DATE_FUTURE } }
        : { ...newErrors, [key]: initialErrorState[key] };

    if (key === 'dateReportedToNhse') {
        newErrors = isDateEarlier(newEntry.eventDate, date)
            ? { ...newErrors, [key]: { error: true, message: ERROR_DATE_EARLIER } }
            : newErrors;
    }

    if (key === 'programmeReIntensifiedDate' || key === 'fibreSupplementDate') {
        newErrors = !isDateEarlier(date, newEntry.programmeDeIntensifiedDate) // can't be same day either
            ? { ...newErrors, [key]: { error: true, message: ERROR_DATE_EARLIER_2 } }
            : newErrors;
    }

    return newErrors;
};
