import { isDateFuture } from '../../../../utils/dateFunctions';
import { NO_VALUE_SELECTED_ERROR } from '../../../../utils/formValidation/commonErrorConstants';

const MIN_HB = 31;
const MAX_HB = 119;
const MIN_FPG = 2;
const MAX_FPG = 13;

const ERROR_NON_INTEGER = 'Value must be a whole number';
const ERROR_BAD_DATE = 'Date must be in the past or present';
const ERROR_MIN_VALUE = 'Value cannot be lower than minimum value';
const ERROR_MAX_VALUE = 'Value cannot be higher than maximum value';
const ERROR_NO_VALUE = 'Please enter a value';
const ERROR_NO_DATE = 'Please enter a date';

export const initialTestTypeErrorState = {
    testValueHb: { error: false, message: '' },
    testValueFpg: { error: false, message: '' }
};
export const initialErrorState = {
    ...initialTestTypeErrorState,
    eventDate: { error: false, message: '' },
    eventId: { error: false, message: '' },
    sourceId: { error: false, message: '' },
    testType: { error: false, message: '' },
    testValue: { error: false, message: '' }
};

export const validate = (newEntry, errors) => {
    let isValid = true;
    let newErrors = errors;

    if (!newEntry.sourceId) {
        newErrors = {
            ...newErrors,
            sourceId: { error: true, message: NO_VALUE_SELECTED_ERROR }
        };
        isValid = false;
    }
    if (!newEntry.eventId) {
        newErrors = {
            ...newErrors,
            eventId: { error: true, message: NO_VALUE_SELECTED_ERROR }
        };
        isValid = false;
    }

    newErrors = checkTestValue(newEntry.testValue, newErrors, newEntry.testType);
    if (newErrors.testValue.error) isValid = false;

    newErrors = checkDate(newEntry.eventDate, newErrors);
    if (newErrors.eventDate.error) isValid = false;

    return { isValid, newErrors };
};

export const checkTestValue = (value, errors, testType) => {
    let error = false;
    let message = '';
    const min = testType === 'HbA1c' ? MIN_HB : MIN_FPG;
    const max = testType === 'HbA1c' ? MAX_HB : MAX_FPG;
    if (!value) {
        error = true;
        message = ERROR_NO_VALUE;
    } else if (value < min) {
        error = true;
        message = ERROR_MIN_VALUE;
    } else if (value > max) {
        error = true;
        message = ERROR_MAX_VALUE;
    } else if (testType === 'HbA1c' && !Number.isInteger(+value)) {
        error = true;
        message = ERROR_NON_INTEGER;
    }
    return { ...errors, testValue: { error, message } };
};

export const checkDate = (date, errors) => {
    let error = false;
    let message = '';
    if (isDateFuture(date)) {
        error = true;
        message = ERROR_BAD_DATE;
    } else if (!date) {
        error = true;
        message = ERROR_NO_DATE;
    }
    return { ...errors, eventDate: { error, message } };
};
