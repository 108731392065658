import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
    Button,
    Card,
    CardContent,
    Divider,
    FormControlLabel,
    FormLabel,
    Link,
    Radio,
    RadioGroup,
    Stack
} from '@mui/material';

import { createCourseProvision } from '../../../store/courseService';
import {
    loadAllExternalCourseProviderDetails,
    loadContractDetails
} from '../../../store/directusService';
import { SCREEN_SETTINGS } from '../../../themes/theme';
import { hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import * as validate from '../../../validation/validation';
import SingleSelect from '../../formElements/SingleSelect';
import TextAreaField from '../../formElements/TextAreaField';
import FormHeader from '../../layout/FormHeader';
import BusyIndicator from '../../ui/BusyIndicator';
import LabelledTextField from '../../ui/editors/LabelledTextField';
import DDLMultiOptionPicker from '../../ui/pickers/DDLMultiOptionPicker';
import NoYesRadioPicker from '../../ui/pickers/NoYesRadioPicker';

const CHARACTER_LIMIT = 250;

const CreateProvision = () => {
    const {
        clearErrors,
        handleSubmit,
        register,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE

    const initialState = {
        nameId: null,
        title: '',
        description: '',
        deliveryInformation: '',
        qualificationRequired: '',
        registrationMethods: '',
        active: true,
        paymentMethod: 'Invoice',
        costPerParticipant: null,
        ofqualNumber: ''
    };

    const initialErrorState = {};
    const [newEntry, setNewEntry] = useState(initialState);
    const [inputErrors, setInputErrors] = useState(initialErrorState);
    const [selectedProvisionId, setSelectedProvisionId] = useState('');
    const [selectedProvision, setSelectedProvision] = useState({});
    const [selectedContracts, setSelectedContracts] = useState([]);

    const [arrayContracts, setArrayContracts] = useState([]);

    const [isCreateProvisionButtonDisabled, setIsCreateProvisionButtonDisabled] = useState(false);

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const { contractDetails, externalCourseProviderDetails } = useSelector(
        (state) => state.entities.directusService
    );

    const handleNameChange = (chosenId) => {
        clearInputError('selectedProvisionId');
        if (chosenId == null) chosenId = '';
        setSelectedProvisionId(chosenId);
        setSelectedProvision(externalCourseProviderDetails.find((el) => el.id === chosenId) || {});
        setNewEntry((prev) => ({ ...prev, nameId: chosenId }));
    };

    const handleTitleChange = (e) => {
        setNewEntry((prev) => ({ ...prev, title: e.target.value }));
    };

    const handleDescriptionChange = (e) => {
        clearInputError('description');
        setNewEntry((prev) => ({ ...prev, description: e.target.value }));
    };

    const handleDeliveryInformationChange = (e) => {
        clearInputError('deliveryInformation');
        setNewEntry((prev) => ({ ...prev, deliveryInformation: e.target.value }));
    };

    const handleQualificationRequiredChange = (e) => {
        clearInputError('qualificationRequired');
        setNewEntry((prev) => ({ ...prev, qualificationRequired: e.target.value }));
    };

    const handleRegistrationMethodsChange = (e) => {
        clearInputError('registrationMethods');
        setNewEntry((prev) => ({ ...prev, registrationMethods: e.target.value }));
    };

    const handleActiveChange = (option) => {
        setNewEntry((prev) => ({ ...prev, active: option }));
    };

    const handlePaymentMethodChange = (e) => {
        setNewEntry((prev) => ({ ...prev, paymentMethod: e.target.value }));
    };

    const handleCostPerParticipantChange = (e) => {
        setNewEntry((prev) => ({ ...prev, costPerParticipant: e.target.value }));
    };

    const handleContractChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        setSelectedContracts(chosenIds);
        if (chosenIds.length !== 0) {
            clearErrors('selectedContracts');
            setValue('selectedContracts', chosenIds, {
                shouldValidate: true
            });
        }
    };

    const handleOfqualNumberChange = (e) => {
        setNewEntry((prev) => ({ ...prev, ofqualNumber: e.target.value }));
    };

    // HELPER FNS
    const clearData = () => {
        setNewEntry(initialState);
    };

    const clearInputError = (key) => {
        setInputErrors((prev) => ({ ...prev, [key]: { ...inputErrors[key], error: false } }));
    };

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadContractDetails());
        dispatch(loadAllExternalCourseProviderDetails());
    }, []);

    useEffect(() => {
        if (
            contractDetails !== undefined &&
            contractDetails.length !== 0 &&
            arrayContracts.length === 0
        ) {
            setArrayContracts(contractDetails);
        }
    }, [contractDetails]);

    useEffect(() => {
        if (successMessage === `Course Provision details for ${newEntry.title} have been added`) {
            setIsCreateProvisionButtonDisabled(false);
            onNavigate();
        }
    }, [successMessage]);

    const onSubmit = () => {
        let errors = {};
        if (!newEntry.nameId?.trim()) {
            errors = {
                ...errors,
                selectedProvisionId: { error: true, message: 'Please select a name' }
            };
            document.getElementById('selectedProvisionId').focus();
        }
        if (newEntry.description?.trim().length < 50) {
            errors = {
                ...errors,
                description: {
                    error: true,
                    message: 'Provision description must be at least fifty characters.'
                }
            };
            document.getElementById('description').focus();
        }
        if (newEntry.deliveryInformation?.trim().length < 50) {
            errors = {
                ...errors,
                deliveryInformation: {
                    error: true,
                    message: 'Delivery information must be at least fifty characters.'
                }
            };
            document.getElementById('deliveryInformation').focus();
        }
        if (newEntry.qualificationRequired?.trim().length < 50) {
            errors = {
                ...errors,
                qualificationRequired: {
                    error: true,
                    message: 'Qualifications required must be at least fifty characters.'
                }
            };
            document.getElementById('qualificationRequired').focus();
        }
        if (newEntry.registrationMethods?.trim().length < 50) {
            errors = {
                ...errors,
                registrationMethods: {
                    error: true,
                    message: 'Registration methods must be at least fifty characters.'
                }
            };
            document.getElementById('registrationMethods').focus();
        }
        setInputErrors(errors);
        if (Object.values(errors).some((el) => el.error)) return;
        if (selectedContracts.length === 0) {
            // Force Yup validation on required multiselect contract field.
            setValue('selectedContracts', selectedContracts, { shouldValidate: true });
        } else {
            setIsCreateProvisionButtonDisabled(true);
            const payload = { ...newEntry, contractIds: selectedContracts };
            dispatch(createCourseProvision(newEntry.title, payload));
        }
    };

    const onNavigate = () => {
        clearData();
        navigate('/course_management', { state: { accordionPanel: 'provisions' } });
    };

    if (contractDetails.length < 1 || externalCourseProviderDetails.length < 1) {
        return <BusyIndicator />;
    }

    return (
        <Card data-testid="form_start">
            <CardContent>
                <form onSubmit={handleSubmit(onSubmit)} data-testid="form_start">
                    <FormHeader text={'Create Provision'}></FormHeader>

                    <SingleSelect
                        label={'Provider Name'}
                        id={'selectedProvisionId'}
                        mandatory={true}
                        placeholder="Search providers..."
                        menuItems={externalCourseProviderDetails || []}
                        selectedId={selectedProvisionId}
                        selected={selectedProvision}
                        error={inputErrors.selectedProvisionId}
                        onChange={(selected) => handleNameChange(selected)}
                    />

                    <LabelledTextField
                        label={'Provision Title'}
                        id={'title'}
                        disabled={!hasRole([MANAGER, QUALITY, SUPERUSER], roles)}
                        mandatory={true}
                        value={newEntry.title || ''}
                        placeholder={'Enter provision title'}
                        error={errors.title}
                        {...register('title')}
                        onChange={handleTitleChange}
                    />

                    <TextAreaField
                        id="description"
                        label="Provision Description"
                        placeholder="Enter provision description"
                        disabled={!hasRole([MANAGER, QUALITY, SUPERUSER], roles)}
                        mandatory={true}
                        value={newEntry.description || ''}
                        count={`${newEntry.description.length}/${CHARACTER_LIMIT}`}
                        maxLength={CHARACTER_LIMIT}
                        error={inputErrors.description}
                        onChange={handleDescriptionChange}
                    />
                    <TextAreaField
                        id="deliveryInformation"
                        label="Delivery Information"
                        placeholder="Enter delivery information"
                        disabled={!hasRole([MANAGER, QUALITY, SUPERUSER], roles)}
                        mandatory={true}
                        value={newEntry.deliveryInformation || ''}
                        count={`${newEntry.deliveryInformation.length}/${CHARACTER_LIMIT}`}
                        maxLength={CHARACTER_LIMIT}
                        error={inputErrors.deliveryInformation}
                        onChange={handleDeliveryInformationChange}
                    />
                    <TextAreaField
                        id="qualificationRequired"
                        label="Qualifications Required"
                        placeholder="Enter qualifications required"
                        disabled={!hasRole([MANAGER, QUALITY, SUPERUSER], roles)}
                        mandatory={true}
                        value={newEntry.qualificationRequired || ''}
                        count={`${newEntry.qualificationRequired.length}/${CHARACTER_LIMIT}`}
                        maxLength={CHARACTER_LIMIT}
                        error={inputErrors.qualificationRequired}
                        onChange={handleQualificationRequiredChange}
                    />
                    <TextAreaField
                        id="registrationMethods"
                        label="Registration Methods"
                        placeholder="Enter registration methods"
                        disabled={!hasRole([MANAGER, QUALITY, SUPERUSER], roles)}
                        mandatory={true}
                        value={newEntry.registrationMethods || ''}
                        count={`${newEntry.registrationMethods.length}/${CHARACTER_LIMIT}`}
                        maxLength={CHARACTER_LIMIT}
                        error={inputErrors.registrationMethods}
                        onChange={handleRegistrationMethodsChange}
                    />
                    <NoYesRadioPicker
                        disabled={!hasRole([MANAGER, QUALITY, SUPERUSER], roles)}
                        text={'Is Current Provision Active?'}
                        radioButtonPick={newEntry.active || true}
                        onChange={handleActiveChange}
                    />
                    <FormLabel htmlFor="paymentMethod">Payment Method</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="paymentMethod"
                        id="paymentMethod"
                        name="paymentMethod"
                        value={newEntry.paymentMethod || 'Invoice'}
                        onChange={(e) => handlePaymentMethodChange(e)}>
                        <FormControlLabel
                            value="Invoice"
                            control={<Radio />}
                            label="Invoice"
                            size="small"
                        />
                        <FormControlLabel
                            value="Spendesk"
                            control={<Radio />}
                            label="Spendesk"
                            size="small"
                        />
                    </RadioGroup>
                    <br />
                    <LabelledTextField
                        label={'Cost Per participant'}
                        id={'costPerParticipant'}
                        value={newEntry.costPerParticipant || ''}
                        placeholder={'Enter amount'}
                        error={errors.costPerParticipant}
                        {...register('costPerParticipant')}
                        onChange={handleCostPerParticipantChange}
                    />
                    <DDLMultiOptionPicker
                        heading={'Available to which Contracts'}
                        disabled={!hasRole([MANAGER, QUALITY, SUPERUSER], roles)}
                        id="selectedContracts"
                        label="Contracts"
                        placeholder="Contract"
                        mandatory={true}
                        error={errors.selectedContracts}
                        menuItems={contractDetails || []}
                        chosenIds={selectedContracts}
                        {...register('selectedContracts')}
                        onChange={(chosenIds) => handleContractChange(chosenIds)}
                    />
                    <LabelledTextField
                        label={'Ofqual Number'}
                        id={'ofqualNumber'}
                        value={newEntry.ofqualNumber || ''}
                        placeholder={'Enter ofqual number'}
                        error={errors.ofqualNumber}
                        {...register('ofqualNumber')}
                        onChange={handleOfqualNumberChange}
                    />
                    <Divider sx={{ marginTop: '40px', marginBottom: '20px' }} />
                    <Stack direction="row" spacing={4}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            data-testid="testIdSubmitButton1"
                            disabled={isCreateProvisionButtonDisabled}
                            endIcon={<KeyboardArrowRightIcon />}>
                            {'Create Provision'}
                        </Button>
                        <Link
                            label="Cancel X"
                            underline="always"
                            variant="body1"
                            color="primary"
                            onClick={() => onNavigate()}
                            sx={{
                                paddingTop: SCREEN_SETTINGS.margin.large,
                                cursor: 'pointer'
                            }}>
                            Cancel X
                        </Link>
                    </Stack>
                </form>
            </CardContent>
        </Card>
    );
};

const validationSchema = Yup.object().shape({
    title: Yup.string()
        .trim()
        .min(5, 'Provision title must be at least five characters.')
        .max(100, 'Provision title must be 100 characters or less.'),
    costPerParticipant: Yup.string()
        .required('Please enter a cost per participant')
        .nullable()
        .min(0, 'Cost per participant limit cannot be negative')
        .max(9999.99, 'Cost per participant limit must be 9999.99 or less')
        .matches(validate.PRICE_REGEXP, 'Invalid Amount'),
    selectedContracts: Yup.array().min(1, 'You must select at least one contract.').nullable(),
    ofqualNumber: Yup.string().trim().max(10, 'Ofqual number must be 10 characters or less.')
});

export default CreateProvision;
