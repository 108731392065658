import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';

import { loadCourseTemplate, updateCourseModuleParticipants } from '../../../store/courseService';
import { createActionPlan } from '../../../store/participantService';
import { selectUsersForNames } from '../../../store/userSelectors';
import { loadUsersMinimalDetails } from '../../../store/userService';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import LabelledTextField from '../../ui/editors/LabelledTextField';
import LoadingSpinner from '../../ui/LoadingSpinner';
import ParticipantAttendanceStatusView from '../modules/participantAttendance/ParticipantAttendanceStatusView';

import cardClasses from '../../../commonStyles/cardContainer.module.css';
import dropdownClasses from '../courseStyles/rowDropDown.module.css';

const CHARACTER_LIMIT = 1000;

const CurrentCourseDetails = ({ row, onClose }) => {
    const { handleSubmit } = useForm({});

    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [information, setInformation] = useState('');
    const [canBeSubmittedToCourse, setCanBeSubmittedToCourse] = useState(true);
    const [updatedModules, setUpdatedModules] = useState([]);
    const [actionPlanData, setActionPlanData] = useState([]);

    // STORE STATE
    const users = useSelector(selectUsersForNames);
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const attendanceDetails = useSelector(
        (state) => state.entities.directusService.attendanceDetails
    );
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const currentTemplate = useSelector((state) => state.entities.courseService.currentTemplate);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // EVENT HANDLERS
    const handleInformationChange = (e) => {
        setInformation(e.target.value);
    };

    // USE EFFECTS
    useEffect(() => {
        if (!row || Object.keys(row)?.length === 0) return;
        dispatch(loadCourseTemplate(row.courseTemplateId));
        setUpdatedModules(row.modules);
        const userIds = [...new Set(row.modules.map((el) => el.userId))];
        const missingAdviserIds = userIds.filter((el) => !users.find((entry) => entry.id === el));
        missingAdviserIds.length > 0 && dispatch(loadUsersMinimalDetails(missingAdviserIds));
        let notes = row.modules[0].participants?.map((el) =>
            el.participantId === currentParticipant.id ? el.notes : ''
        );
        let participantNotes = notes.filter((el) => el !== '');
        setInformation(participantNotes[0] || '');
    }, [row]);

    useEffect(() => {
        if (successMessage === 'Participant has been submitted to course') {
            setCanBeSubmittedToCourse(false);
        }
    }, [successMessage]);

    const onModuleChange = (module, moduleActionPlan) => {
        const modules = updatedModules.map((el) =>
            el.completionOrder > module.completionOrder &&
            el.date + 'T' + el.startTime > format(new Date(), "yyyy-MM-dd'T'HH:mm")
                ? { ...el, participants: module.participants }
                : el.id === module.id
                  ? module
                  : el
        );
        setUpdatedModules(modules);
        if (moduleActionPlan) setActionPlanData((prev) => [...prev, moduleActionPlan]);
    };

    //  FORM SUBMIT
    const onSubmit = () => {
        const modules = updatedModules.map((el) => {
            // Here is a good place to update modules with any notes
            const updatedParticipants = el.participants?.map((el) =>
                el.participantId === currentParticipant.id ? { ...el, notes: information } : el
            );
            // eslint-disable-next-line
            const { checked, ...rest } = el;
            return { ...rest, participants: updatedParticipants };
        });
        modules.forEach((el) => {
            const msg = `Module details for module ${el.title} have been updated`;
            const { id, participants } = el;
            const payload = { participants };
            dispatch(updateCourseModuleParticipants(id, payload, msg));
        });
        actionPlanData.length > 0 && actionPlanData.forEach((el) => dispatch(createActionPlan(el)));
    };

    return !currentTemplate ||
        currentTemplate.length < 1 ||
        attendanceDetails?.length < 1 ||
        canBeSubmittedToCourse === '' ? (
        <LoadingSpinner content="No current course information found" />
    ) : (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} data-testid="form_start">
                <div className={dropdownClasses.rowDropdown}>
                    <Typography variant="h5">
                        {row.name} ({row.code})
                    </Typography>
                    <p className={dropdownClasses.description}>{row.description}</p>
                    <div className={cardClasses.cardContainer}>
                        <Typography variant="h5">Module selection</Typography>
                        <div hidden={!currentTemplate.modulesInSetOrder}>
                            <p>
                                <strong>
                                    Please note this course is sequential, meaning the Modules need
                                    to be completed in order. You can de-select modules using the
                                    checkboxes to remove a Participant from the attendance list, but
                                    this will de-select all subsequent Modules as well. For example,
                                    if a participant is attending a sequential course with 5
                                    modules, if the participant is removed from attending module 3,
                                    the participant will automatically also be removed from modules
                                    4 and 5.
                                </strong>
                            </p>
                        </div>
                        <div className={cardClasses.cards}>
                            {updatedModules.map((el) => (
                                <div key={el.id}>
                                    <ParticipantAttendanceStatusView
                                        module={el}
                                        attendanceDetails={attendanceDetails}
                                        currentParticipant={currentParticipant}
                                        information={information}
                                        onModuleChange={onModuleChange}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <LabelledTextField
                        id={'information'}
                        mandatory={false}
                        value={information}
                        rows={2}
                        placeholder={
                            'Please type Additional information about your participant for the trainer here'
                        }
                        counter={'true'}
                        helperText={`${information.length}` + '/' + CHARACTER_LIMIT}
                        inputProps={{
                            maxLength: CHARACTER_LIMIT
                        }}
                        onChange={handleInformationChange}
                    />
                    <Button
                        sx={{ marginTop: '-30px', marginBottom: '20px' }}
                        disabled={!canBeSubmittedToCourse || !hasRole(acceptedRoles, roles)}
                        type="submit"
                        color="primary"
                        variant="contained"
                        data-testid="testIdSubmitButton1"
                        endIcon={<KeyboardArrowRightIcon />}>
                        {'Update Participant Course Details'}
                    </Button>
                    <div className={dropdownClasses.exit} onClick={onClose}>
                        Close details
                        <CloseIcon />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CurrentCourseDetails;

CurrentCourseDetails.propTypes = {
    row: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};
