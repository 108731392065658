// Add card viewers here.
export const CONTACT_DETAILS_VIEWER = 'CONTACT_DETAILS_VIEWER';
export const JOB_GOALS_VIEWER = 'JOB_GOALS_VIEWER';
export const PAST_EDUCATION_VIEWER = 'PAST_EDUCATION_VIEWER';
export const WORK_HISTORY_VIEWER = 'WORK_HISTORY_VIEWER';
export const VIEWER_TYPES_ARRAY = [
    CONTACT_DETAILS_VIEWER,
    JOB_GOALS_VIEWER,
    PAST_EDUCATION_VIEWER,
    WORK_HISTORY_VIEWER
];
