import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { TableCell, TableRow } from '@mui/material';

import { loadAttendanceDetails } from '../../../store/directusService';
import { formatJustTime, reverseFormatDate } from '../../../utils/dateFunctions';
import { getNameFromId } from '../../../utils/directusFunctions';

import classes from '../../../commonStyles/tables.module.css';

const TimelineAppointmentEventHistoryTableRow = ({ row }) => {
    // HOOKS
    const dispatch = useDispatch();

    // STORE STATE
    const attendanceDetails = useSelector(
        (state) => state.entities.directusService.attendanceDetails
    );

    // USE EFFECTS
    useEffect(() => {
        attendanceDetails?.length < 1 && dispatch(loadAttendanceDetails());
    }, []);

    console.log(row);
    // RENDER
    return (
        <>
            <TableRow hover className={`${classes.basicRowStripes}`}>
                <TableCell>
                    {row?.loggedDate ? reverseFormatDate(row.loggedDate.substring(0, 10)) : ''}
                </TableCell>
                <TableCell>{row?.loggedTime ? formatJustTime(row.loggedTime) : ''}</TableCell>
                <TableCell>
                    {row?.oldValue ? getNameFromId(attendanceDetails, row.oldValue) : ''}
                </TableCell>
                <TableCell>
                    {row?.newValue ? getNameFromId(attendanceDetails, row.newValue) : ''}
                </TableCell>
            </TableRow>
        </>
    );
};

export default TimelineAppointmentEventHistoryTableRow;

TimelineAppointmentEventHistoryTableRow.propTypes = {
    row: PropTypes.object
};
