import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { DEFAULT_TABLE_ROWS_PER_PAGE } from '../../../utils/formValidation/commonConstants';
import { stableSort } from '../../../utils/sortFunctions';

import AuditAppointmentTableRow from './AuditAppointmentTableRow';

import classes from '../../../commonStyles/tables.module.css';

const headCells = [
    { id: 'from', numeric: false, label: 'From', sortable: false },
    { id: 'to', numeric: false, label: 'To', sortable: false },
    { id: 'user', numeric: false, label: 'Author User Id' },
    { id: 'formattedDate', numeric: false, label: 'Date Changed' },
    { id: 'formattedTime', numeric: false, label: 'Time Changed' }
];

const AuditAppointmentTable = ({ rows }) => {
    // LOCAL STATE
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('formattedDate');
    const [page, setPage] = useState(0);
    const rowsPerPage = DEFAULT_TABLE_ROWS_PER_PAGE;

    // USE EFFECTS
    useEffect(() => {
        setOrder('desc');
        setOrderBy('formattedDate');
    }, []);

    // EVENT HANDLERS
    const onSort = (property, e) => {
        e.stopPropagation();
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPage(0);
    };

    const onChangePage = (e, newPage) => {
        e.stopPropagation();
        const page = newPage > 0 ? newPage : 0;
        setPage(page);
    };

    // RENDER
    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table sx={{ minWidth: 640 }} aria-labelledby="tableTitle" size="medium">
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={(e) => onSort(headCell.id, e)}>
                                            <h5 className={classes.headCell}>{headCell.label}</h5>
                                            {orderBy === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc'
                                                        ? 'sorted descending'
                                                        : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {stableSort(rows, orderBy, order)
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <AuditAppointmentTableRow key={row.id} row={row} />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    data-testid="tablePagination"
                    rowsPerPageOptions={[]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={onChangePage}
                />
            </Paper>
        </Box>
    );
};

AuditAppointmentTable.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default AuditAppointmentTable;
