import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import SingleSelect from '../../formElements/SingleSelect';

import ParticipantSelectedPills from './ParticipantSelectedPills';

import form from '../../formElements/formElementStyles/commonFormElements.module.css';
import scheduler from '../styles/schedulePanel.module.css';

const ParticipantsSelect = ({
    participants = [],
    serviceId,
    updateSelectedParticipants,
    selectedParticipants,
    numOfSlots = null,
    statuses,
    isEditRestrictedByAttendanceStatus,
    editRestrictedRoles,
    roles,
    hasHiddenParticipants = false,
    appointmentDate
}) => {
    // LOCAL STATE
    const [allParticipants, setAllParticipants] = useState([]);
    const [filteredParticipants, setFilteredParticipants] = useState([]);
    const [currentSelectionId, setCurrentSelectionId] = useState('');

    // USE EFFECTS
    useEffect(() => {
        if (isEditRestrictedByAttendanceStatus || hasHiddenParticipants) return;
        const updatedParticipants = participants
            .filter(
                (el) =>
                    el.serviceId === serviceId &&
                    new Date(appointmentDate) >= new Date(el.referralDate)
            )
            .map((el) => ({ ...el, name: `${el.firstName} ${el.lastName} - ${el.ptCode}` }));
        setAllParticipants(updatedParticipants);
        setFilteredParticipants(updatedParticipants);
    }, [participants]);

    useEffect(() => {
        if (isEditRestrictedByAttendanceStatus || hasHiddenParticipants) return;
        setFilteredParticipants(
            allParticipants.filter(
                (el) => !selectedParticipants.find((entry) => entry.id === el.id)
            )
        );
    }, [selectedParticipants, allParticipants]);

    // EVENT HANDLERS
    const onAddParticipant = (participantId) => {
        if (!participantId || selectedParticipants.find((el) => el.id === participantId)) return;
        updateSelectedParticipants([
            ...selectedParticipants,
            allParticipants.find((el) => el.id === participantId)
        ]);
        setCurrentSelectionId(participantId);
    };

    const onRemoveParticipant = (id) => {
        updateSelectedParticipants(selectedParticipants.filter((el) => el.id !== id));
        setCurrentSelectionId('');
    };

    // RENDER
    return (
        <div>
            {!isEditRestrictedByAttendanceStatus && !hasHiddenParticipants ? (
                <div>
                    <SingleSelect
                        id="participantId"
                        label="Participant"
                        placeholder="Select participant"
                        disabled={selectedParticipants.length === numOfSlots}
                        menuItems={filteredParticipants || []}
                        selectedId={currentSelectionId || ''}
                        selected={allParticipants.find((el) => el.id === currentSelectionId) || {}}
                        onChange={onAddParticipant}
                    />

                    <div className={scheduler.participantSpacesFilled}>
                        {selectedParticipants.length === numOfSlots && <>All slots are filled</>}
                    </div>
                </div>
            ) : (
                <div className={form.formLabel}>
                    {selectedParticipants.length ? 'Participants' : ''}
                </div>
            )}
            <ParticipantSelectedPills
                participants={selectedParticipants}
                statuses={statuses}
                isDisabled={isEditRestrictedByAttendanceStatus}
                onRemove={onRemoveParticipant}
                editRestrictedRoles={editRestrictedRoles}
                roles={roles}
            />
        </div>
    );
};

ParticipantsSelect.propTypes = {
    participants: PropTypes.arrayOf(PropTypes.object),
    serviceId: PropTypes.string,
    updateSelectedParticipants: PropTypes.func,
    selectedParticipants: PropTypes.arrayOf(PropTypes.object),
    numOfSlots: PropTypes.number,
    statuses: PropTypes.object,
    isEditRestrictedByAttendanceStatus: PropTypes.bool,
    editRestrictedRoles: PropTypes.bool,
    roles: PropTypes.arrayOf(PropTypes.string),
    hasHiddenParticipants: PropTypes.bool,
    appointmentDate: PropTypes.string
};

export default ParticipantsSelect;
