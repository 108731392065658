import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    setCurrentModule,
    setOpenModuleSchedulerPanel,
    updateCourseModule
} from '../../../store/courseService';
import { loadLocationsForServices } from '../../../store/directusService';
import { isDatePast, reverseFormatDate } from '../../../utils/dateFunctions';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import DateSelect from '../../formElements/DateSelect';
import SingleSelect from '../../formElements/SingleSelect';
import StaticField from '../../formElements/StaticField';
import TextInputField from '../../formElements/TextInputField';
import NotFound from '../../notFound/NotFound';
import Panel from '../../ui/panel/Panel';
import StartAndEndTimesSelect from '../components/StartAndEndTimesSelect';
import { getServiceIds } from '../utils/calendarUtils';
import { validate } from '../validation/validateModuleScheduler';

import sidePanel from '../styles/schedulePanel.module.css';

const ModuleScheduler = ({ currentModule, newEvent, onPending, adviser }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const canEditInPastRoles = [MANAGER, QUALITY, SUPERUSER];
    const canEditPastDateRoles = [QUALITY, SUPERUSER];
    const initialState = {
        id: '',
        title: '',
        locationId: '',
        capacity: 1,
        startTime: '08:00',
        endTime: '09:00',
        date: String(new Date().toISOString().split('T')[0])
    };
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [existingParticipantCount, setExistingParticipantCount] = useState(1);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [mode, setMode] = useState('create');
    const [isPast, setIsPast] = useState(false);

    // STORE STATE
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const errorMessage = useSelector((state) => state.entities.formsState.errorMessage);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const locations = useSelector((state) => state.entities.directusService.locationsForServices);
    const openModuleScheduler = useSelector(
        (state) => state.entities.courseService.openModuleScheduler
    );

    // USEEFFECTS
    useEffect(() => {
        const serviceIds = getServiceIds(adviser);
        dispatch(loadLocationsForServices(serviceIds));

        if (Object.keys(newEvent).length) {
            setNewEntry({
                ...newEvent,
                capacity: newEvent?.capacity || '1',
                startTime: newEvent?.startTime,
                endTime: newEvent?.endTime,
                date: newEvent?.date,
                userId: adviser?.id
            });
            mode !== 'create' && setMode('create');
        } else if (Object.keys(currentModule).length) {
            setNewEntry({
                ...currentModule,
                capacity: currentModule?.capacity || '1',
                startTime: currentModule?.startTime?.slice(0, 5),
                endTime: currentModule?.endTime?.slice(0, 5),
                date: currentModule?.date?.split('T')[0] || '',
                userId: adviser.id
            });
            if (currentModule?.participants?.length > 1)
                setExistingParticipantCount(currentModule.participants.length);
            if (isDatePast(currentModule?.date)) setIsPast(true);
            mode !== 'edit' && setMode('edit');
        }
    }, []);

    useEffect(() => {
        setIsButtonDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    useEffect(() => {
        if (successMessage.includes(`Module details for ${newEntry.title} has been updated`)) {
            clearForm();
            setIsButtonDisabled(false);
        }
    }, [successMessage]);

    useEffect(() => {
        if (errorMessage) {
            setIsButtonDisabled(false);
        }
    }, [errorMessage]);

    // HELPER FNS
    const clearForm = () => setNewEntry(initialState);

    const clearError = (key) => {
        if (key in errors && errors[key].error)
            setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    // EVENT HANDLERS
    const onToggle = (open) => {
        dispatch(setOpenModuleSchedulerPanel(open));
        if (!open) {
            if (!currentModule.date) onPending(newEvent);
            dispatch(setCurrentModule({}));
        }
    };

    const onUpdate = (key, value) => {
        clearError(key);
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onUpdateTime = (key, value) => {
        clearError('startTime');
        clearError('endTime');
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setIsButtonDisabled(true);
        // eslint-disable-next-line
        const { participants, start, end, ...rest } = newEntry;
        const payload = { ...rest };
        const initialModule = Object.keys(newEvent).length ? newEvent : currentModule;
        const newErrors = validate(payload, initialModule, roles, canEditPastDateRoles);
        if (Object.values(newErrors).some((el) => el.error)) {
            setErrors(newErrors);
            return;
        }
        dispatch(
            updateCourseModule(payload, `Module details for ${payload.title} have been updated`)
        );
        onPending({});
        onToggle(false);
    };

    // RENDER
    if (!hasRole(acceptedRoles, roles)) return <NotFound />;

    return (
        <Panel width="500px" open={openModuleScheduler} onToggle={onToggle}>
            <div className={sidePanel.formWrapper}>
                <div className={sidePanel.moduleTitle}>{newEntry.title}</div>
                <form onSubmit={onSubmit}>
                    <StaticField label="Adviser" content={adviser?.emailAddress} />
                    {isPast && !hasRole(canEditInPastRoles, roles) ? (
                        <>
                            <StaticField label="Location" content={newEntry.location} />
                            <StaticField
                                label="Number of slots"
                                content={newEntry.capacity.toString()}
                            />
                        </>
                    ) : (
                        <>
                            <SingleSelect
                                id="locationId"
                                label="Location"
                                placeholder="Select location"
                                mandatory={true}
                                menuItems={locations || []}
                                selectedId={newEntry.locationId || currentModule.locationId || ''}
                                selected={locations.find((el) => el.id === newEntry.locationId)}
                                error={errors.locationId}
                                onChange={(id) => onUpdate('locationId', id)}
                            />

                            <TextInputField
                                id="numberOfSlots"
                                type="number"
                                label="Number of slots"
                                mandatory={true}
                                min={existingParticipantCount}
                                max={99}
                                value={newEntry.capacity || 1}
                                customClass="capacityInput"
                                onChange={(e) => onUpdate('capacity', e.target.value.slice(0, 2))}
                            />
                        </>
                    )}
                    {isPast && !hasRole(canEditPastDateRoles, roles) ? (
                        <>
                            <StaticField label="Start Time" content={newEntry.startTime} />
                            <StaticField label="End Time" content={newEntry.endTime} />
                            <StaticField label="Date" content={reverseFormatDate(newEntry.date)} />
                        </>
                    ) : (
                        <>
                            <StartAndEndTimesSelect
                                startTime={newEntry.startTime}
                                endTime={newEntry.endTime}
                                errors={errors}
                                onTimeChange={onUpdateTime}
                            />
                            <DateSelect
                                mandatory={true}
                                value={newEntry.date}
                                error={errors.date}
                                onDateChange={(date) => onUpdate('date', date)}
                            />
                        </>
                    )}
                    <div className={sidePanel.updateButton}>
                        <Button
                            id="courseModuleSchedulerUpdateButton"
                            content="Update"
                            disabled={
                                isButtonDisabled || (isPast && !hasRole(canEditInPastRoles, roles))
                            }
                            error={errors.button}
                            clearError={() => clearError('button')}
                            customErrorClass="rightSideButtonError"
                        />
                    </div>
                </form>
            </div>
        </Panel>
    );
};

ModuleScheduler.propTypes = {
    currentModule: PropTypes.object,
    newEvent: PropTypes.object,
    onPending: PropTypes.func,
    adviser: PropTypes.object
};

export default ModuleScheduler;
