import { getReqMessage } from '../../../../utils/formValidation/errorMessageFunctions';

export const initialErrorState = {};

export const validate = (newEntry) => {
    let newErrors = {};

    if (newEntry.reasonIds.length < 1) {
        newErrors = {
            ...newErrors,
            reasonIds: { error: true, message: getReqMessage('Rejection Reason') }
        };
    }

    return newErrors;
};
