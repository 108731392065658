import { getLoadingErrorMessage } from './errorMessageFunctions';

export const LOADING = 'Please wait...';
export const ATTRACTION_DETAILS_ERROR = getLoadingErrorMessage('attractions');
export const BARRIER_NAME_DETAILS_ERROR = getLoadingErrorMessage('barrier name details');
export const BUSINESS_SECTOR_DETAILS_ERROR = getLoadingErrorMessage('business sector details');
export const CALLS_ERROR = getLoadingErrorMessage('calls');
export const CONTRACT_DETAILS_ERROR = getLoadingErrorMessage('contracts');
export const CONTRACT_TYPES_ERROR = getLoadingErrorMessage('contract types');
export const COURSE_TEMPLATE_HEALTH_DETAILS_ERROR = getLoadingErrorMessage(
    'course template health details'
);
export const CURRENT_BUSINESS_ERROR = getLoadingErrorMessage('current business');
export const CURRENT_VACANCY_ERROR = getLoadingErrorMessage('current vacancy');
export const DOCUMENT_TYPES_ERROR = getLoadingErrorMessage('document types details');
export const ETHNICITY_DETAILS_ERROR = getLoadingErrorMessage('ethnicity details');
export const FORMS_ERROR = getLoadingErrorMessage('forms');
export const HOUSING_DETAILS_ERROR = getLoadingErrorMessage('housing details');
export const JOB_SECTOR_DETAILS_ERROR = getLoadingErrorMessage('job sector details');
export const LANGUAGE_DETAILS_ERROR = getLoadingErrorMessage('first language details');
export const LICENCE_DETAILS_ERROR = getLoadingErrorMessage('drivers licence details');
export const MARITAL_STATUS_DETAILS_ERROR = getLoadingErrorMessage('marital status details');
export const PARTICIPANT_STATUS_DETAILS_ERROR = getLoadingErrorMessage(
    'participant status details'
);
export const POOL_ACCEPTED_REJECTION_REASONS_DETAILS_ERROR = getLoadingErrorMessage(
    'pool accepted rejection reasons details'
);
export const POOL_REQUESTED_REJECTION_REASONS_DETAILS_ERROR = getLoadingErrorMessage(
    'pool requested rejection reasons details'
);
export const POOL_STATUS_DETAILS_ERROR = getLoadingErrorMessage('pool status details');
export const UNIQUE_IDENTIFIERS_ERROR = getLoadingErrorMessage('unique identifiers');
export const VACANCY_REJECTION_REASONS_DETAILS_ERROR = getLoadingErrorMessage(
    'vacancy rejection reasons details'
);
export const VACANCY_STAGE_DETAILS_ERROR = getLoadingErrorMessage('vacancy stage details');
