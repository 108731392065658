import { onlyEntries, trimEntries } from '../objectUtils';

import {
    getAgeMin,
    getCharMax,
    getCharMin,
    getDateMax,
    getDateMin,
    getDiff,
    getDuplicated,
    getFormat,
    getIsLength,
    getMatches,
    getNumMax,
    getNumMin,
    getRequired,
    getSelected
} from './errorCheckingFunctions';
import { nhsNumberChecksum } from './nhsNumberChecksum';

// RETURN EMPTY ERROR STATE BY VALIDATION FIELDS (KEYS ARE USED TO DEFINE ERROR STATE)
export const getEmptyErrorState = (validationFields, entry = {}) => {
    const emptyErrorState = {};
    const subErrorState = {};
    for (const key of Object.keys(validationFields)) {
        if ('array' in validationFields[key] && entry[key]) {
            for (const element of entry[key]) {
                const subField = element[validationFields[key].array.key];
                const subVfKey = Object.keys(validationFields[key].array).find(
                    (el) => el !== 'key'
                );
                const subVf = { [subVfKey]: validationFields[key].array[subVfKey] };
                const subEntry = { [subVfKey]: element[subVfKey] };
                subErrorState[subField] = {
                    ...subErrorState[subField],
                    ...getEmptyErrorState(subVf, subEntry)
                };
            }
        }
        emptyErrorState[key] = { ...subErrorState, ...{ error: false, message: '' } };
    }
    return emptyErrorState;
};

// VALIDATES A SINGLE FIELD INSTEAD OF ALL OF THEM
export const validateSingleField = (
    entry,
    validationFieldKey,
    errorState,
    validationFieldsObject,
    currentIsValid
) => {
    if (!Object.hasOwn(errorState, validationFieldKey))
        return {
            isValid: currentIsValid,
            errors: errorState
        };
    const { isValid, errors } = validate(
        entry,
        onlyEntries([validationFieldKey], validationFieldsObject)
    );
    return {
        isValid,
        errors: {
            ...errorState,
            ...errors
        }
    };
};

// For rules that are only meant to run under certain conditions.
// This function will add the rules if the conditional function resolves to true.
const resolveCondition = (entry, ruleSets, baseRules, resolutionData) => {
    let validationFieldRules = baseRules;
    ruleSets.forEach((ruleSet) => {
        if (ruleSet.condition(entry, resolutionData)) {
            validationFieldRules = {
                ...validationFieldRules,
                ...trimEntries(['condition'], ruleSet)
            };
        }
    });
    return validationFieldRules;
};

// resolutionData is data against which you may need to resolve a condition,
// so its only needed if the validationFields contains a conditional key,
// or for passing in an array for a duplicate rule
export const validate = (entry, validationFields, resolutionData = {}) => {
    const errorState = {};
    let isValid = true;

    Object.keys(validationFields).forEach((field) => {
        let validators;
        if (validationFields[field].conditional)
            validators = resolveCondition(
                entry,
                validationFields[field].conditional,
                trimEntries(['conditional'], validationFields[field]),
                resolutionData
            );
        else validators = validationFields[field];

        let error = false;
        for (const [validatorName, ruleDef] of Object.entries(validators)) {
            const rule = resolutionData[field]?.[validatorName] || entry[ruleDef] || ruleDef;
            let ia = validators.indefiniteArticle || 'a';
            const validationRequired =
                !!entry[field] || !!validators.required || !!validators.global;
            if (rule !== undefined && validationRequired)
                switch (validatorName) {
                    //
                    // data keys (not rules)
                    case 'subject':
                    case 'indefiniteArticle':
                    case 'key':
                    case 'global':
                        break;
                    //
                    // recursively resolves nested validation when in dynamic array
                    case 'array': {
                        const keyName = validators[validatorName].key;
                        let subError = {
                            isValid: true
                        };

                        entry[field].forEach((arrItem) => {
                            const subField = arrItem[keyName];
                            const key = Object.keys(validators.array).find((el) => el !== 'key');
                            const subValidators = { [key]: validators.array[key] };
                            const validatedArrItem = validate(arrItem, subValidators);
                            subError = {
                                ...subError,
                                ...{
                                    [subField]: {
                                        ...validatedArrItem.errors,
                                        isValid: validatedArrItem.isValid
                                    }
                                },
                                isValid: !(!validatedArrItem.isValid || !subError.isValid)
                            };
                            isValid = !(!isValid || !subError.isValid);
                        });
                        error = subError;
                        break;
                    }
                    //
                    // Rules
                    case 'ageMin':
                        error = getAgeMin(entry[field], rule, validators.subject);
                        break;
                    case 'charMin':
                        error = getCharMin(entry[field], rule, validators.subject);
                        break;
                    case 'charMax':
                        error = getCharMax(entry[field], rule, validators.subject);
                        break;
                    case 'dateMax':
                        error = rule && getDateMax(entry[field], rule, validators.subject);
                        break;
                    case 'dateMin':
                        error = rule && getDateMin(entry[field], rule, validators.subject);
                        break;
                    case 'checkDiff':
                        error = getDiff(entry, rule);
                        break;
                    case 'duplicate':
                        error = getDuplicated(
                            entry[field],
                            resolutionData[ruleDef] || [],
                            validators.subject
                        );
                        break;
                    case 'format':
                        error = getFormat(entry[field], rule, validators.subject);
                        break;
                    case 'length':
                        error = getIsLength(entry[field], rule, validators.subject);
                        break;
                    case 'matches':
                        error = getMatches(
                            entry[field],
                            [entry[rule.sub], rule.name],
                            validators.subject
                        );
                        break;
                    case 'nhsChecksum':
                        error = nhsNumberChecksum(entry[field].replace(/\s/g, ''));
                        break;
                    case 'numMin':
                        error = getNumMin(entry[field], rule || 0, validators.subject);
                        break;
                    case 'numMax':
                        error = getNumMax(entry[field], rule || 0, validators.subject);
                        break;
                    case 'required':
                        if (rule === 'text')
                            error = getRequired(
                                (entry[field] || '').toString(),
                                validators.subject
                            );
                        else if (rule === 'select')
                            error = getSelected(entry[field], validators.subject, ia);
                        break;
                    default:
                        break;
                }
            if (error?.message) {
                isValid = false;
                break;
            }
        }
        errorState[field] = !error
            ? { error: false, message: '' }
            : { ...{ error: false, message: '' }, ...error };
    });
    return { isValid: isValid, errors: errorState };
};

export const isErrorsValid = (errors) => {
    if (errors.error === true) return false;
    return !Object.keys(trimEntries(['error', 'message', 'isValid'], errors)).some((key) => {
        return !isErrorsValid(errors[key]);
    });
};
