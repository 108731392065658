import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../../api/pagination';
import { selectUsersAppointmentsAndSlots } from '../../../store/calendarSelectors';
import { searchUserAppointmentsAndSlots } from '../../../store/calendarService';
import { selectAllCourseIds } from '../../../store/courseSelectors';
import {
    loadCourse,
    loadCourseModulesByUserId,
    searchCoursesByCourseIds,
    setCurrentModule,
    setOpenModuleSchedulerPanel,
    updateCourseModule
} from '../../../store/courseService';
import {
    loadAppointmentTypeDetails,
    loadLocationIdsAndNames
} from '../../../store/directusService';
import { searchParticipantsByLoggedInUserServiceIds } from '../../../store/participantService';
import { loadUserById } from '../../../store/userService';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import Calendar from '../calendar/Calendar';
import AdviserSelect from '../components/AdviserSelect';
import {
    configCourseEventContent,
    configParticipantEventContent,
    fetchLoggedInUserData,
    formatFullCalendarTimes,
    getBusinessHours,
    mapColors,
    mapCommonParams
} from '../utils/calendarUtils';

import ModuleScheduler from './ModuleScheduler';

import '../styles/calendarVendorClasses.css';
import app from '../../../app.module.css';

const PT_LOAD_SIZE = DEFAULT_PAGE_LOAD_SIZE;

const CourseCalendar = () => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const onSelectUsersAppointmentsAndSlots = useMemo(selectUsersAppointmentsAndSlots, []);

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [currentCalendarOwner, setCurrentCalendarOwner] = useState({});
    const [participants, setParticipants] = useState([]);
    const [allModules, setAllModules] = useState([]);
    const [userEvents, setUserEvents] = useState([]);
    const [events, setEvents] = useState([]);
    const [newEvent, setNewEvent] = useState({});
    const [pending, setPending] = useState({});
    const [currentEvent, setCurrentEvent] = useState({});
    const [changedModule, setChangedModule] = useState(false);
    const [isSlotSelectable, setIsSlotSelectable] = useState(false);

    // STORE STATE
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);
    const usersById = useSelector((state) => state.entities.userService.usersById);
    const allAppointments = useSelector(
        (state) => state.entities.calendarService.appointmentsAndSlotsByUser
    );
    const usersAppointmentsAndSlots = useSelector((state) =>
        onSelectUsersAppointmentsAndSlots(state, currentCalendarOwner?.id)
    );
    const courseIds = useSelector(selectAllCourseIds);
    const courseModules = useSelector((state) => state.entities.courseService.courseModules);
    const currentCourse = useSelector((state) => state.entities.courseService.currentCourse);
    const currentModule = useSelector((state) => state.entities.courseService.currentModule);
    const locations = useSelector((state) => state.entities.directusService.locationIdsAndNames);
    const appointmentTypes = useSelector(
        (state) => state.entities.directusService.appointmentTypeDetails
    );
    const participantsByLoggedInUserServiceIds = useSelector(
        (state) => state.entities.participantService.participantsByLoggedInUserServiceIds
    );
    const ptMetaData = useSelector(
        (state) => state.entities.participantService.participantsByLoggedInUserServiceIdsMetaData
    );
    const courses = useSelector((state) => state.entities.courseService.coursesByCourseIds);
    const coursesByCourseIdsMetaData = useSelector(
        (state) => state.entities.courseService.coursesByCourseIdsMetaData
    );
    const openModuleScheduler = useSelector(
        (state) => state.entities.courseService.openModuleScheduler
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // CONFIG FNS
    const configEvents = () => {
        const businessHours = getBusinessHours();
        let modules = courseModules;
        if (currentModule?.id) {
            const found = courseModules?.find((el) => el.id === currentModule?.id);
            if (!found) modules = [...courseModules, currentModule];
        }
        setAllModules(modules);
        let entries;
        if (!modules?.length) entries = [{}];
        else
            entries = modules?.map((el) => {
                const { date, start, end, location } = mapCommonParams(el, locations);
                const content = configCourseEventContent(el, courses, location);
                const { color, textColor } = mapColors(el.id, currentModule?.id);
                const editable = false; // non-draggable
                return {
                    id: el.id,
                    eventType: 'courseModules',
                    timeContent: `${start.slice(-5)} - ${end.slice(-5)}`,
                    content,
                    location,
                    date,
                    start,
                    end,
                    color,
                    textColor,
                    editable,
                    constraint: businessHours
                };
            });
        setEvents(entries);
        const cur = entries?.find((el) => el.id === currentModule?.id);
        if (cur) setCurrentEvent(cur);
    };

    const configBackgroundEvents = () => {
        let events;
        if (usersAppointmentsAndSlots?.length < 1) events = [];
        else
            events = usersAppointmentsAndSlots?.map((el) => {
                const { date, start, end, location } = mapCommonParams(el, locations);
                const { appointmentType, participant, slots, filledSlots } =
                    configParticipantEventContent(el, appointmentTypes, participants);
                return {
                    eventType: 'user',
                    className: 'non-interactive-events',
                    timeContent: `${start.slice(-5)} - ${end.slice(-5)}`,
                    slots,
                    filledSlots,
                    location,
                    participant,
                    appointmentType,
                    editable: false,
                    date,
                    start,
                    end
                };
            });
        setUserEvents(events);
    };

    // USE EFFECTS
    useEffect(() => {
        appointmentTypes?.length < 1 && dispatch(loadAppointmentTypeDetails());
        locations?.length < 1 && dispatch(loadLocationIdsAndNames());
    }, []);

    useEffect(() => {
        if (!loggedInUser?.id) return;
        let owner;
        if (currentModule?.userId) {
            owner =
                usersById[currentModule.userId] ||
                users.find((el) => el.id === currentModule.userId);
            if (!owner) {
                dispatch(loadUserById(currentModule.userId));
                return;
            }
        } else owner = loggedInUser;
        setCurrentCalendarOwner(owner);
    }, [loggedInUser.id, currentModule.id]);

    useEffect(() => {
        if (currentModule?.userId && usersById[currentModule.userId]) {
            const owner = usersById[currentModule.userId];
            setCurrentCalendarOwner(owner);
        }
    }, [usersById]);

    useEffect(() => {
        if (!ptMetaData.last) {
            dispatch(
                searchParticipantsByLoggedInUserServiceIds(
                    loggedInUser.serviceIds,
                    ptMetaData.number + 1,
                    PT_LOAD_SIZE
                )
            );
        } else setParticipants(participantsByLoggedInUserServiceIds);
    }, [participantsByLoggedInUserServiceIds, ptMetaData]);

    useEffect(() => {
        if (!loggedInUser.id) return;
        fetchLoggedInUserData(loggedInUser, dispatch);
        dispatch(
            searchParticipantsByLoggedInUserServiceIds(loggedInUser.serviceIds, 0, PT_LOAD_SIZE)
        );
        setNewEvent({});
    }, [loggedInUser.id]);

    useEffect(() => {
        if (!courseModules || coursesByCourseIdsMetaData.last) return;
        courseIds.length > 0 &&
            dispatch(
                searchCoursesByCourseIds(
                    courseIds,
                    'number' in coursesByCourseIdsMetaData
                        ? coursesByCourseIdsMetaData.number + 1
                        : 0
                )
            );
    }, [courseModules, courseIds, coursesByCourseIdsMetaData]);

    useEffect(() => {
        if (!currentModule?.id) setCurrentEvent({});
        if (currentModule?.id && !currentModule?.date) setIsSlotSelectable(true);
        configEvents();
    }, [currentModule, currentCalendarOwner, courseModules, courses, locations]);

    useEffect(() => {
        if (appointmentTypes.length > 0) configBackgroundEvents();
    }, [usersAppointmentsAndSlots, locations, participants, appointmentTypes]);

    useEffect(() => {
        if (changedModule) {
            onOpenModuleSchedulerPanel();
            setChangedModule(false);
        }
    }, [changedModule]);

    useEffect(() => {
        if (!currentCalendarOwner?.id) return;
        fetchAdviserData();
    }, [currentCalendarOwner.id]);

    useEffect(() => {
        if (successMessage === `Module details for ${currentModule.title} have been updated`) {
            dispatch(loadCourse(currentModule.courseId));
            dispatch(setCurrentModule({}));
        }
    }, [successMessage]);

    // HELPER FNS
    const fetchAdviserData = () => {
        if (currentCalendarOwner?.id) {
            if (!allAppointments[currentCalendarOwner?.id]) {
                dispatch(searchUserAppointmentsAndSlots(currentCalendarOwner?.id));
            }
            dispatch(loadCourseModulesByUserId(currentCalendarOwner?.id));
        }
    };

    // EVENT HANDLERS
    const onDragEditTimes = (time, update) => {
        if (update) {
            const { date, startTime, endTime } = formatFullCalendarTimes(time);
            const updatedModule = {
                ...currentModule,
                date,
                startTime,
                endTime,
                userId: currentCalendarOwner.id
            };
            dispatch(
                updateCourseModule(
                    updatedModule,
                    `Module details for ${currentModule.title} have been updated`
                )
            );
        }
        if (!update) {
            configEvents();
        }
    };

    let userClickCount = 0;
    const onOpenModuleSchedulerPanel = () => {
        if (isSlotSelectable) {
            dispatch(setOpenModuleSchedulerPanel(true));
        }
        if (!isSlotSelectable) {
            userClickCount++;
            setTimeout(() => (userClickCount = 0), 300);
            if (userClickCount > 1) {
                dispatch(setOpenModuleSchedulerPanel(true));
                setNewEvent({});
                setPending({});
            }
        }
    };

    const onSelectEvent = (id) => {
        setIsSlotSelectable(false);
        if (id !== currentModule?.id) {
            const selectedModule = allModules.find((el) => el.id === id);
            selectedModule && dispatch(setCurrentModule(selectedModule));
            setChangedModule(true);
        }
        if (id === currentEvent.id) onOpenModuleSchedulerPanel();
    };

    const onSelectSlot = (start, end) => {
        const { date, startTime, endTime } = formatFullCalendarTimes({ start, end });
        if (Object.keys(pending).length) setNewEvent({ ...pending, date, startTime, endTime });
        else setNewEvent({ ...currentModule, date, startTime, endTime });
        onOpenModuleSchedulerPanel();
    };

    const onAdviserChange = (chosenId) => {
        if (!chosenId) {
            if (currentCalendarOwner.id !== loggedInUser.id) {
                setCurrentCalendarOwner(loggedInUser);
            }
        } else if (chosenId && chosenId !== currentCalendarOwner.id) {
            const chosenAdviser = [...users, loggedInUser].find((el) => el.id === chosenId);
            setCurrentCalendarOwner(chosenAdviser);
        }
    };

    const onReturn = () => {
        const { prevPage } = location?.state || '';
        if (prevPage === 'editCourse')
            navigate('/edit_course', { state: { row: currentCourse, mode: 'edit' } });
        else navigate('/course_management');
    };

    const onPending = (unScheduledModule) => setPending(unScheduledModule);

    // RENDER
    return (
        <div className={app.container}>
            <div className="calendarHeadingMessage">
                Select your Module Date - click into any available slot and double-click or drag to
                edit
            </div>
            <div className="calendar-wrapper">
                <AdviserSelect
                    userId={currentCalendarOwner.id}
                    serviceIds={loggedInUser.serviceIds}
                    initialAdviser={loggedInUser}
                    onAdviserChange={onAdviserChange}
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                    customClass="calendarToolbarAdviserSelect"
                />

                <Calendar
                    events={[...events, ...userEvents]}
                    currentEvent={currentEvent}
                    onDragEditTimes={onDragEditTimes}
                    onSelectSlot={isSlotSelectable ? onSelectSlot : undefined}
                    onSelectEvent={onSelectEvent}
                    allowSlotSelect={true}
                    onReturn={onReturn}
                    initialView="timeGridWeek"
                />
                {openModuleScheduler && (
                    <ModuleScheduler
                        newEvent={newEvent}
                        currentModule={currentModule}
                        onPending={onPending}
                        adviser={currentCalendarOwner}
                    />
                )}
            </div>
        </div>
    );
};

export default CourseCalendar;
