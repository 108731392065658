export const generateUniqueId = () => {
    const s4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

// converts a string like "I am a String" to "iAmAString"
export const convertStringToCamelCase = (str) => {
    return str
        .split(' ')
        .map((el, index) =>
            index === 0 ? el.toLowerCase() : el.charAt(0).toUpperCase() + el.slice(1).toLowerCase()
        )
        .join('');
};

// for short unique ids for ie. successMessages
export const createHash = () => Math.random().toString(36).slice(2, 10);
