import React from 'react';
import PropTypes from 'prop-types';

import ClearIcon from '@mui/icons-material/Clear';

import { hasRole } from '../../../utils/userRoles';
import { unresolvedStatuses } from '../utils/calendarUtils';

import pills from '../../../commonStyles/pillSelections.module.css';

const ParticipantSelectedPills = ({
    participants,
    statuses,
    isDisabled,
    onRemove,
    editRestrictedRoles,
    roles
}) => (
    <div className={pills.selectionList} data-testid={'selected-participant-pills'}>
        {participants.map((el) => (
            <span key={el.id} className={pills.pill} data-testid={'pt_pill'}>
                {(unresolvedStatuses.includes(statuses[el.id]) || !statuses[el.id]) &&
                    !isDisabled &&
                    !el.hidden &&
                    hasRole(editRestrictedRoles, roles) && (
                        <span
                            className={pills.clearIcon}
                            onClick={() => onRemove(el.id)}
                            data-testid={`pill_remove_button_${el.id}`}>
                            <ClearIcon />
                        </span>
                    )}
                <span>
                    {el.firstName ?? el.hidden} {el.lastName}
                </span>
            </span>
        ))}
    </div>
);

ParticipantSelectedPills.propTypes = {
    participants: PropTypes.arrayOf(PropTypes.object),
    statuses: PropTypes.object,
    isDisabled: PropTypes.bool,
    onRemove: PropTypes.func,
    editRestrictedRoles: PropTypes.arrayOf(PropTypes.string),
    roles: PropTypes.arrayOf(PropTypes.string)
};

export default ParticipantSelectedPills;
