import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectUsersForNames } from '../../../store/userSelectors';
import {
    loadUsersMinimalDetails,
    searchUsersByLoggedInUserServiceIds
} from '../../../store/userService';
import LoadingSpinner from '../../ui/LoadingSpinner';

import AuditEventHistoryTable from './AuditEventHistoryTable';

const AuditEventHistoryManagement = ({ auditEventHistory = [], label }) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [rows, setRows] = useState([]);

    // STORE STATE
    const users = useSelector(selectUsersForNames);
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);

    // USE EFFECTS
    useEffect(() => {
        if (!loggedInUser || !('serviceIds' in loggedInUser) || loggedInUser.serviceIds.length < 1)
            return;
        if (!users || users.length < 1)
            dispatch(searchUsersByLoggedInUserServiceIds(loggedInUser.serviceIds));
    }, [loggedInUser]);

    useEffect(() => {
        if (!auditEventHistory?.length > 0 || users?.length < 1) return;
        const userIds = [...new Set(auditEventHistory.map((el) => el.userId))];
        const missingUserIds = userIds
            .filter((el) => !users.find((entry) => entry.id === el))
            .filter((el) => el);
        missingUserIds.length > 0 && dispatch(loadUsersMinimalDetails(missingUserIds));
        const updatedRows = auditEventHistory.map((el) => {
            const user = users.find((entry) => entry.id === el.userId);
            return {
                ...el,
                emailAddress: user
                    ? `${user?.firstName ?? ''} ${user?.lastName ?? ''} (${user?.emailAddress ?? ''})`
                    : 'Polaris System',
                history: el.history?.find((entry) => entry.field === label)
            };
        });
        setRows(updatedRows);
    }, [auditEventHistory, users]);

    // RENDER
    return rows.length < 1 ? (
        <LoadingSpinner content="No audit event history found" time={1} />
    ) : (
        <AuditEventHistoryTable rows={rows} />
    );
};

AuditEventHistoryManagement.propTypes = {
    auditEventHistory: PropTypes.array,
    label: PropTypes.string
};

export default AuditEventHistoryManagement;
