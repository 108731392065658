import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectWellbeingTypeNames } from '../../../store/dataSelectors';
import { loadWellbeingDetailsForContractId } from '../../../store/directusService';
import { selectCurrentParticipant } from '../../../store/participantSelectors';
import { loadReferralData } from '../../../store/participantService';

import AlcoholConsumed from './alcoholConsumed/AlcoholConsumed';
import BloodGlucose from './bloodGlucose/BloodGlucose';
import BloodPressure from './bloodPressure/BloodPressure';
import BloodTests from './bloodTests/BloodTests';
import BMI from './bmi/BMI';
import HealthIncident from './healthIncident/HealthIncident';
import PhysicalActivity from './physicalActivity/PhysicalActivity';
import SecondNature from './secondNature/SecondNature';
import SignPosting from './signPosting/SignPosting';
import Smoking from './smoking/Smoking';
import Steps from './steps/Steps';
import WaistMeasurements from './waistMeasurements/WaistMeasurements';

import local from './wellbeing.module.css';

const Wellbeing = () => {
    const dispatch = useDispatch();

    // STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const types = useSelector(selectWellbeingTypeNames);

    // USE EFFECTS
    useEffect(() => {
        if (currentParticipant?.id) {
            dispatch(loadReferralData(currentParticipant.id));
            dispatch(loadWellbeingDetailsForContractId(currentParticipant.contractId));
        }
    }, [currentParticipant]);

    return (
        <div className={local.wellbeingTables}>
            {types.includes('signposting') && <SignPosting />}
            {types.includes('bmi') && <BMI />}
            {types.includes('waist measurement') && <WaistMeasurements />}
            {types.includes('alcohol consumed') && <AlcoholConsumed />}
            {types.includes('physical activity') && <PhysicalActivity />}
            {types.includes('blood test') && <BloodTests />}
            {types.includes('blood glucose') && <BloodGlucose />}
            {types.includes('blood pressure') && <BloodPressure />}
            {types.includes('health incident') && <HealthIncident />}
            {types.includes('smoking') && <Smoking />}
            {types.includes('steps') && <Steps />}
            {types.includes('second nature') && <SecondNature />}
        </div>
    );
};

export default Wellbeing;
