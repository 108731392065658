import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';

import { loadJobSectorDetails, loadVacancyStageDetails } from '../../../../store/directusService';
import { createVacancy } from '../../../../store/recruitmentService';
import { searchUsersByLoggedInUserServiceIds } from '../../../../store/userService';
import { POLARIS_DARKER_GREY, POLARIS_ROYAL_BLUE } from '../../../../themes/theme';
import { getNameFromId } from '../../../../utils/directusFunctions';
import {
    ADDRESS_LINE_1_MAX_LENGTH,
    ADDRESS_LINE_2_MAX_LENGTH,
    ADDRESS_LINE_3_MAX_LENGTH,
    CITY_MAX_LENGTH,
    CREATE
} from '../../../../utils/formValidation/commonConstants';
import {
    JOB_SECTOR_DETAILS_ERROR,
    VACANCY_STAGE_DETAILS_ERROR
} from '../../../../utils/formValidation/loadingErrorMessageConstants';
import { clearKeys } from '../../../../utils/objectUtils';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import Button from '../../../formElements/Button';
import DateSelect from '../../../formElements/DateSelect';
import RadioButtons from '../../../formElements/RadioButtons';
import SingleSelect from '../../../formElements/SingleSelect';
import TextAreaField from '../../../formElements/TextAreaField';
import TextInputField from '../../../formElements/TextInputField';
import FormActions from '../../../ui/formActions/FormActions';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import { initialErrorState, validate } from './shared/validateVacancy';

import app from '../../../../app.module.css';
import form from '../../../../commonStyles/formStyles.module.css';
import classes from '../../recruitmentStyles/vacancy.module.css';

const SHIFT_PATTERN_LENGTH = 250;
const MAX_DESCRIPTION_LENGTH = 1000;
const MAX_REQUIREMENTS_LENGTH = 250;

const CreateVacancy = () => {
    // HOOKS
    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const initialState = {
        id: null,
        title: '',
        ownerId: '',
        sectorId: '',
        hiringManagerId: '',
        numberOfPositions: '',
        payRateFrom: '',
        payRateTo: '',
        shiftPatternWorkingHours: '',
        expectedWeeklyHours: '',
        vacancyClosingDate: '',
        anticipatedStartDate: '',
        remote: false,
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        postcode: '',
        description: '',
        requirement: '',
        inactive: null,
        stageIds: []
    };
    const acceptedRoles = [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [keys, setKeys] = useState({ ownerId: '0', sectorId: '1', hiringManagerId: '2' });
    const [errors, setErrors] = useState(initialErrorState);
    const [isDisabled, setIsDisabled] = useState(false);
    const [disableFields, setDisableFields] = useState(false);
    const [businessOwners, setBusinessOwners] = useState([]);
    const [hiringManagers, setHiringManagers] = useState([]);
    const [formValues, setFormValues] = useState([{ stageId: '', name: '' }]);
    const [staging, setStaging] = useState([]);
    const [vacancyStageDetailsNew, setVacancyStageDetailsNew] = useState([]);

    // STORE STATE
    const errorMessage = useSelector((state) => state.entities.formsState.errorMessage);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const jobSectorDetails = useSelector(
        (state) => state.entities.directusService.jobSectorDetails
    );
    const vacancyStageDetails = useSelector(
        (state) => state.entities.directusService.vacancyStageDetails
    );
    const currentBusiness = useSelector(
        (state) => state.entities.recruitmentService.currentBusiness
    );
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);
    const userMetaData = useSelector(
        (state) => state.entities.userService.usersByLoggedInUserServiceIdsMetaData
    );
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);

    // USE EFFECTS
    useEffect(() => {
        if (jobSectorDetails?.length < 1) dispatch(loadJobSectorDetails());
        if (vacancyStageDetails?.length < 1) dispatch(loadVacancyStageDetails());
    }, []);

    useEffect(() => {
        if (!loggedInUser || !('serviceIds' in loggedInUser) || loggedInUser.serviceIds.length < 1)
            return;
        if (!users || users.length < 1)
            dispatch(searchUsersByLoggedInUserServiceIds(loggedInUser.serviceIds));
        if (
            loggedInUser.roles.includes(MANAGER) ||
            loggedInUser.roles.includes(RECRUITMENT_MANAGER)
        )
            onUpdate('ownerId', loggedInUser.id);
    }, [loggedInUser]);

    useEffect(() => {
        setBusinessOwners(
            users
                .filter((el) =>
                    el.userTypes?.find(
                        (entry) => entry.role === MANAGER || entry.role === RECRUITMENT_MANAGER
                    )
                )
                .map(({ id, emailAddress: name }) => ({
                    id,
                    name
                }))
        );
    }, [users]);

    useEffect(() => {
        setHiringManagers(
            currentBusiness?.contactCards?.map((el) => ({
                id: el.id,
                name: `${el.firstName} ${el.lastName} ( ${el.officeNumber} )`
            })) || []
        );
    }, [currentBusiness]);

    useEffect(() => {
        setIsDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    useEffect(() => {
        if (vacancyStageDetails?.length)
            setVacancyStageDetailsNew(
                vacancyStageDetails.filter(
                    (el) =>
                        el.name !== 'Accepted' &&
                        el.name !== 'Job offered' &&
                        el.name !== 'Rejected' &&
                        el.name !== 'Submitted' &&
                        el.name !== 'Withdrawn'
                )
            );
    }, [vacancyStageDetails]);

    useEffect(() => {
        if (errorMessage) {
            setDisableFields(false);
            setIsDisabled(false);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (successMessage === `Vacancy has been added`) onNavigate();
    }, [successMessage]);

    // HELPER FNS
    const addFormFields = () => {
        setFormValues([...formValues, { stageId: '' }]);
    };

    const removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
        setStaging(newFormValues);
    };

    const getStageId = (name) => {
        if (!name) return '';
        return vacancyStageDetails.find((el) => el.name === name)?.id || '';
    };

    const getPreSelect = (arr, id) => {
        return arr.find((el) => el.id === id) || {};
    };

    const loadingError = () => {
        if (jobSectorDetails?.length < 1) return JOB_SECTOR_DETAILS_ERROR;
        if (vacancyStageDetails?.length < 1) return VACANCY_STAGE_DETAILS_ERROR;
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    const onNavigate = () => {
        clearData();
        navigate('/recruitment_management', {
            state: { accordionPanel: state?.accordionPanel || 'myBusinesses' }
        });
    };

    const clearData = () => {
        setNewEntry(initialState);
        setKeys(clearKeys(keys));
    };

    // EVENT HANDLERS
    const onPayRateFromChange = (payRateFrom) => {
        clearError('payRateFrom');
        if (payRateFrom && newEntry.payRateTo >= payRateFrom) {
            clearError('payRateTo');
        }
        setNewEntry((prev) => ({ ...prev, payRateFrom }));
    };

    const onPayRateToChange = (payRateTo) => {
        clearError('payRateTo');
        if (payRateTo && newEntry.payRateFrom <= payRateTo) {
            clearError('payRateFrom');
        }
        setNewEntry((prev) => ({ ...prev, payRateTo }));
    };

    const onRemoteChange = (option) => {
        setNewEntry((prev) => ({
            ...prev,
            remote: option
        }));
        if (option) {
            clearError('addressLine1');
            clearError('city');
            clearError('postcode');
        }
    };

    const onVacancyStageChange = (i, chosenId) => {
        clearError('stageId');
        let newFormValues = [...formValues];
        newFormValues[i]['stageId'] = chosenId;
        newFormValues[i]['name'] = getNameFromId(vacancyStageDetails, chosenId);
        setStaging(newFormValues);
        setNewEntry((prev) => ({ ...prev, stageIds: newFormValues }));
    };

    const onUpdate = (key, value) => {
        errors[key] && clearError(key);
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onLoadMoreBusinessOwners = () => {
        if ((!users.length || users.length < userMetaData.totalElements) && !userMetaData.last) {
            dispatch(
                searchUsersByLoggedInUserServiceIds(
                    loggedInUser.serviceIds,
                    !users.length ? 0 : userMetaData.number + 1
                )
            );
        }
    };

    // FORM SUBMIT
    const onSubmit = (e) => {
        e.preventDefault();

        const newErrors = validate(newEntry, CREATE);

        if (Object.keys(newErrors)?.length > 0) {
            setErrors(newErrors);
            return;
        }

        const startId = getStageId('Submitted');
        const endId = getStageId('Job offered');
        const arrValues = staging.map(({ stageId }) => stageId);
        const newArr = [startId, ...arrValues, endId];
        const payload = {
            ...newEntry,
            businessRecordId: currentBusiness.id,
            inactive: false,
            stageIds: newArr
        };

        setDisableFields(true);
        setIsDisabled(true);
        dispatch(createVacancy(payload));
    };

    // AWAITING CONTENT
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} time={3} />;

    // RENDER
    return (
        <div className={form.formWrapper}>
            <form onSubmit={onSubmit} data-testid="form_start" className={form.form}>
                <h1 className={app.mainHeading}>Create Vacancy</h1>
                <h2 className={app.sectionHeading}>Vacancy Overview:</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'title'}
                            label={'Vacancy Title'}
                            placeholder={'Enter vacancy title'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            maxLength={100}
                            value={newEntry.title || ''}
                            error={errors.title}
                            onChange={(e) => onUpdate('title', e.target.value)}
                        />
                        <SingleSelect
                            id={'ownerId'}
                            key={keys.ownerId}
                            label={'Vacancy Owner'}
                            placeholder="Select Vacancy Owner..."
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            menuItems={businessOwners}
                            selectedId={newEntry.ownerId}
                            selected={getPreSelect(businessOwners, newEntry.ownerId)}
                            error={errors.ownerId}
                            onLoadMoreItems={onLoadMoreBusinessOwners}
                            moreItemsToLoad={
                                !userMetaData.totalElements ||
                                users.length < userMetaData.totalElements
                            }
                            onChange={(chosenId) => onUpdate('ownerId', chosenId)}
                        />
                        <TextInputField
                            id={'businessName'}
                            label={'Business Record'}
                            disabled={true}
                            value={currentBusiness.name}
                        />
                        <SingleSelect
                            id={'hiringManagerId'}
                            key={keys.hiringManagerId}
                            label={'Hiring Manager'}
                            placeholder="Select Hiring Manager..."
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            menuItems={hiringManagers}
                            selectedId={newEntry.hiringManagerId}
                            selected={getPreSelect(hiringManagers, newEntry.hiringManagerId)}
                            error={errors.hiringManagerId}
                            onChange={(chosenId) => onUpdate('hiringManagerId', chosenId)}
                        />
                        <SingleSelect
                            label={'Job Sector'}
                            key={keys.sectorId}
                            id={'sectorId'}
                            placeholder="Select Job Sector..."
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            menuItems={jobSectorDetails}
                            selectedId={newEntry.sectorId}
                            selected={getPreSelect(jobSectorDetails, newEntry.sectorId)}
                            error={errors.sectorId}
                            onChange={(chosenId) => onUpdate('sectorId', chosenId)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'numberOfPositions'}
                            label={'Number of Positions'}
                            placeholder={'Enter number of Positions'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            maxLength={3}
                            value={newEntry.numberOfPositions || ''}
                            error={errors.numberOfPositions}
                            onChange={(e) =>
                                onUpdate(
                                    'numberOfPositions',
                                    e.target.value.replace(/\D/g, '') || ''
                                )
                            }
                        />
                        <TextAreaField
                            id={'description'}
                            label={'Description'}
                            placeholder={'Enter job description'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            value={newEntry.description || ''}
                            count={`${newEntry.description.length}/${MAX_DESCRIPTION_LENGTH}`}
                            maxLength={MAX_DESCRIPTION_LENGTH}
                            error={errors.description}
                            onChange={(e) => onUpdate('description', e.target.value)}
                        />
                        <TextAreaField
                            id={'requirement'}
                            label={'Requirements'}
                            placeholder={'Enter job requirements'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            value={newEntry.requirement || ''}
                            count={`${newEntry.requirement.length}/${MAX_REQUIREMENTS_LENGTH}`}
                            maxLength={MAX_REQUIREMENTS_LENGTH}
                            error={errors.requirement}
                            onChange={(e) => onUpdate('requirement', e.target.value)}
                        />
                    </div>
                </div>
                <h2 className={app.sectionHeading}>Vacancy Detail:</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'payRateFrom'}
                            label={'Hourly Pay Rate - From'}
                            placeholder={'Enter hourly pay rate from'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            maxLength={6}
                            value={newEntry.payRateFrom || ''}
                            error={errors.payRateFrom}
                            onChange={(e) =>
                                onPayRateFromChange(e.target.value.replace(/[^\d*.?]/g, '') || '')
                            }
                        />
                        <TextInputField
                            id={'payRateTo'}
                            label={'Hourly Pay Rate - To'}
                            placeholder={'Enter hourly pay rate to'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            maxLength={6}
                            value={newEntry.payRateTo || ''}
                            error={errors.payRateTo}
                            onChange={(e) =>
                                onPayRateToChange(e.target.value.replace(/[^\d*.?]/g, '') || '')
                            }
                        />
                        <div className={`${form.formColumnSplit}`}>
                            <DateSelect
                                id="vacancyClosingDate"
                                label="Vacancy Closing Date"
                                value={newEntry.vacancyClosingDate || ''}
                                disabled={
                                    !hasRole(acceptedRoles, loggedInUser.roles) || disableFields
                                }
                                mandatory={true}
                                error={errors.vacancyClosingDate}
                                onDateChange={(value) => onUpdate('vacancyClosingDate', value)}
                            />
                            <DateSelect
                                id="anticipatedStartDate"
                                label="Anticipated Start Date"
                                value={newEntry.anticipatedStartDate || ''}
                                disabled={
                                    !hasRole(acceptedRoles, loggedInUser.roles) || disableFields
                                }
                                error={errors.anticipatedStartDate}
                                onDateChange={(value) => onUpdate('anticipatedStartDate', value)}
                            />
                        </div>
                    </div>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'expectedWeeklyHours'}
                            label={'Expected Weekly Hours'}
                            placeholder={'Enter expected weekly hours'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            mandatory={true}
                            maxLength={4}
                            value={newEntry.expectedWeeklyHours || ''}
                            error={errors.expectedWeeklyHours}
                            onChange={(e) =>
                                onUpdate(
                                    'expectedWeeklyHours',
                                    e.target.value.replace(/[^\d*.?]/g, '') || ''
                                )
                            }
                        />
                        <TextAreaField
                            id={'shiftPatternWorkingHours'}
                            label={'Shift Patterns/Working Hours'}
                            placeholder={'Enter shift patterns/working hours description'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                            value={newEntry.shiftPatternWorkingHours || ''}
                            count={`${newEntry.shiftPatternWorkingHours.length}/${SHIFT_PATTERN_LENGTH}`}
                            maxLength={SHIFT_PATTERN_LENGTH}
                            error={errors.shiftPatternWorkingHours}
                            onChange={(e) => onUpdate('shiftPatternWorkingHours', e.target.value)}
                        />
                    </div>
                </div>
                <h2 className={app.sectionHeading}>Address:</h2>
                <RadioButtons
                    id="remote"
                    label="Remote"
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles) || disableFields}
                    value={newEntry.remote}
                    onChange={(option) => onRemoteChange(option)}
                />
                <div hidden={newEntry.remote}>
                    <div className={form.formSection}>
                        <div className={form.formColumn}>
                            <TextInputField
                                id={'address1'}
                                label={'Address Line 1'}
                                placeholder={'Enter address line 1'}
                                disabled={
                                    !hasRole(acceptedRoles, loggedInUser.roles) || disableFields
                                }
                                mandatory={true}
                                value={newEntry.addressLine1 || ''}
                                error={errors.addressLine1}
                                maxLength={ADDRESS_LINE_1_MAX_LENGTH}
                                onChange={(e) => onUpdate('addressLine1', e.target.value)}
                            />
                            <TextInputField
                                id={'address2'}
                                label={'Address Line 2'}
                                placeholder={'Enter address line 2'}
                                disabled={
                                    !hasRole(acceptedRoles, loggedInUser.roles) || disableFields
                                }
                                value={newEntry.addressLine2 || ''}
                                error={errors.addressLine2}
                                maxLength={ADDRESS_LINE_2_MAX_LENGTH}
                                onChange={(e) => onUpdate('addressLine2', e.target.value)}
                            />
                            <TextInputField
                                id={'address3'}
                                label={'Address Line 3'}
                                placeholder={'Enter address line 3'}
                                disabled={
                                    !hasRole(acceptedRoles, loggedInUser.roles) || disableFields
                                }
                                value={newEntry.addressLine3 || ''}
                                error={errors.addressLine3}
                                maxLength={ADDRESS_LINE_3_MAX_LENGTH}
                                onChange={(e) => onUpdate('addressLine3', e.target.value)}
                            />
                        </div>
                        <div className={form.formColumn}>
                            <TextInputField
                                id={'city'}
                                label={'City'}
                                placeholder={'Enter city'}
                                disabled={
                                    !hasRole(acceptedRoles, loggedInUser.roles) || disableFields
                                }
                                mandatory={true}
                                value={newEntry.city || ''}
                                error={errors.city}
                                maxLength={CITY_MAX_LENGTH}
                                onChange={(e) => onUpdate('city', e.target.value)}
                            />
                            <TextInputField
                                id={'postcode'}
                                label={'Postcode'}
                                placeholder={'Enter postcode'}
                                disabled={
                                    !hasRole(acceptedRoles, loggedInUser.roles) || disableFields
                                }
                                mandatory={true}
                                value={newEntry.postcode || ''}
                                error={errors.postcode}
                                onChange={(e) => onUpdate('postcode', e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <h2 className={app.sectionHeading}>Application Process:</h2>
                <p>Select all the items involved in the application process</p>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <h3 className={app.fieldHeading}>Stage 1</h3>
                        <p>Submitted</p>
                        {formValues.map((el, i) => (
                            <div key={i}>
                                <div className={form.inputSet}>
                                    <div className={form.inputSetInput}>
                                        <SingleSelect
                                            id={'stageId'}
                                            label={'Stage ' + `${i + 2}`}
                                            placeholder="Select Stage..."
                                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                            mandatory={true}
                                            menuItems={vacancyStageDetailsNew}
                                            selectedId={el.stageId}
                                            selected={getPreSelect(
                                                vacancyStageDetailsNew,
                                                el.stageId
                                            )}
                                            error={errors.stageId}
                                            onChange={(chosenId) =>
                                                onVacancyStageChange(i, chosenId)
                                            }
                                        />
                                    </div>
                                    <div className={form.inputSetAction}>
                                        {i ? (
                                            <DeleteIcon
                                                className={classes.deleteIcon}
                                                onClick={() => removeFormFields(i)}
                                                sx={{
                                                    color: POLARIS_ROYAL_BLUE,
                                                    marginTop: '10px'
                                                }}></DeleteIcon>
                                        ) : (
                                            <DeleteIcon
                                                disabled={true}
                                                sx={{
                                                    color: POLARIS_DARKER_GREY,
                                                    marginTop: '10px'
                                                }}></DeleteIcon>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                        <h4 className={app.fieldHeading}>Final Stage</h4>
                        <p>Job offered</p>
                    </div>
                    <div className={form.formColumn}></div>
                </div>
                <Button
                    id="addStage"
                    type="button"
                    content="Add Stage"
                    disabled={
                        !hasRole(acceptedRoles, loggedInUser.roles) ||
                        formValues[formValues.length - 1].stageId === '' ||
                        formValues.length === 8
                    }
                    icon={true}
                    customClass="buttonMargin"
                    onClick={() => addFormFields()}
                />
                <FormActions
                    id="create"
                    btnText="Create Vacancy"
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles) || isDisabled}
                    onClose={onSubmit}
                    onCancel={onNavigate}
                />
            </form>
        </div>
    );
};

export default CreateVacancy;
