import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';

import { getNameFromId } from '../../../../utils/directusFunctions';
import TextAreaField from '../../../formElements/TextAreaField';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import dropdownClasses from '../submissionStyles/rowDropDown.module.css';

const RejectionDetails = ({ row, onClose }) => {
    // LOCAL STATE
    const [reasonNames, setReasonNames] = useState([]);

    // STORE STATE
    const poolAcceptedRejectionReasonsDetails = useSelector(
        (state) => state.entities.directusService.poolAcceptedRejectionReasonsDetails
    );
    const poolRequestedRejectionReasonsDetails = useSelector(
        (state) => state.entities.directusService.poolRequestedRejectionReasonsDetails
    );

    // EVENT HANDLERS
    useEffect(() => {
        if (!row || row.rejection.rejectionIds.length < 1) return;
        let reasonNames = row.rejection.rejectionIds.map((el) =>
            getNameFromId(poolAcceptedRejectionReasonsDetails, el)
        );
        if (reasonNames[0] === '')
            reasonNames = row.rejection.rejectionIds.map((el) =>
                getNameFromId(poolRequestedRejectionReasonsDetails, el)
            );
        setReasonNames(reasonNames);
    }, []);

    useEffect(() => {
        if (!row || row.rejection.rejectionIds.length < 1) return;
        let reasonNames = row.rejection.rejectionIds.map((el) =>
            getNameFromId(poolAcceptedRejectionReasonsDetails, el)
        );
        if (reasonNames[0] === '')
            reasonNames = row.rejection.rejectionIds.map((el) =>
                getNameFromId(poolRequestedRejectionReasonsDetails, el)
            );
        setReasonNames(reasonNames);
    }, []);

    return !row ? (
        <LoadingSpinner content="No rejection information found" />
    ) : (
        <div className={dropdownClasses.rowDropdown} data-testid="div_start">
            <h5>Rejection Reasons</h5>
            <TextAreaField id={'reasons'} disabled={true} value={reasonNames.join(', ')} rows={3} />
            <h5>Rejection Feedback</h5>
            <TextAreaField
                id={'notes'}
                disabled={true}
                value={row.rejection.notes || ''}
                rows={4}
            />
            <div className={dropdownClasses.exit} onClick={onClose}>
                Close details
                <CloseIcon />
            </div>
        </div>
    );
};

export default RejectionDetails;

RejectionDetails.propTypes = {
    row: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};
