import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    createService,
    loadContractDetails,
    loadServiceDetails
} from '../../store/directusService';
import { clearKeys } from '../../utils/objectUtils';
import { hasRole, SUPERUSER } from '../../utils/userRoles';
import Button from '../formElements/Button';
import DateSelect from '../formElements/DateSelect';
import RadioButtons from '../formElements/RadioButtons';
import SingleSelect from '../formElements/SingleSelect';
import TextInputField from '../formElements/TextInputField';
import LoadingSpinner from '../ui/LoadingSpinner';

import { DATE_RANGE_INVALID_2, initialErrorState, validate } from './validateCreateService';

import app from '../../app.module.css';
import form from '../../commonStyles/formStyles.module.css';

const CreateService = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        name: '',
        contract: '',
        company_name: '',
        active: true,
        adviser_authorisation_limit: null,
        start_date: '',
        end_date: '',
        claim_file_prefix: '',
        status: 'published'
    };
    const acceptedRoles = [SUPERUSER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const [isDisabled, setIsDisabled] = useState(false);
    const [keys, setKeys] = useState({ contract: '0' });

    // STORE STATE
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const contractDetails = useSelector((state) => state.entities.directusService.contractDetails);
    const serviceDetails = useSelector((state) => state.entities.directusService.serviceDetails);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // USE EFFECTS
    useEffect(() => {
        contractDetails?.length < 1 && dispatch(loadContractDetails());
        serviceDetails?.length < 1 && dispatch(loadServiceDetails());
        setNewEntry((prev) => ({ ...prev, active: true }));
    }, []);

    useEffect(() => {
        setIsDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    useEffect(() => {
        if (successMessage.includes(`Service details for ${newEntry.name} have been added`))
            clearForm();
    }, [successMessage]);

    // HELPER FNS
    const clearForm = () => {
        setNewEntry(initialState);
        setErrors(initialErrorState);
        setKeys(clearKeys(keys));
        setIsDisabled(false);
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // EVENT HANDLERS
    const onNameChange = (e) => {
        clearError('name');
        setNewEntry((prev) => ({ ...prev, name: e.target.value }));
    };

    const onContractChange = (chosenId) => {
        clearError('contract');
        if (chosenId == null) return;
        setNewEntry((prev) => ({ ...prev, contract: chosenId }));
    };

    const onCompanyNameChange = (e) => {
        clearError('company_name');
        setNewEntry((prev) => ({ ...prev, company_name: e.target.value }));
    };

    const onAdviserAuthLimitChange = (e) => {
        clearError('adviser_authorisation_limit');
        setNewEntry((prev) => ({
            ...prev,
            adviser_authorisation_limit: e.target.value.replace(/[^\d*.?]/g, '').slice(0, 7) || ''
        }));
    };

    const onActiveChange = (option) => {
        clearError('active');
        if (option) {
            setNewEntry((prev) => ({ ...prev, active: true }));
        } else {
            setNewEntry((prev) => ({
                ...prev,
                active: false,
                start_date: null,
                end_date: null
            }));
            clearError('start_date');
            clearError('end_date');
        }
    };

    const onServiceDateChange = (key, value) => {
        clearError(key);
        if (
            key === 'end_date' &&
            errors['start_date'].message === DATE_RANGE_INVALID_2 &&
            (!value || value > newEntry.start_date)
        ) {
            clearError('start_date');
        }
        setNewEntry((prev) => ({ ...prev, [key]: value === '' ? null : value }));
    };

    const onClaimFilePrefixChange = (e) => {
        clearError('claim_file_prefix');
        setNewEntry((prev) => ({ ...prev, claim_file_prefix: e.target.value }));
    };

    const onSubmit = (e) => {
        setIsDisabled(true);
        e.preventDefault();
        const { isValid, newErrors } = validate(newEntry, errors, serviceDetails);
        setErrors(newErrors);
        if (!isValid) return;
        dispatch(createService(newEntry));
    };

    // AWAITING CONTENT
    if (contractDetails?.length < 1)
        return <LoadingSpinner content={'No contract details found'} />;

    // RENDER
    return (
        <div className={form.formWrapper} data-testid="form_start">
            <form className={form.form} onSubmit={onSubmit}>
                <h2 className={app.mainHeading}>Create Service</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            label={'Name'}
                            id={'name'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            value={newEntry.name || ''}
                            placeholder={'Enter service name'}
                            error={errors.name}
                            onChange={onNameChange}
                        />
                        <SingleSelect
                            id={'contract'}
                            key={keys.contract}
                            label={'Contract'}
                            placeholder="Search contracts..."
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={contractDetails || []}
                            selectedId={newEntry.contract || ''}
                            selected={
                                contractDetails.find((el) => el.id === newEntry.contract) || {}
                            }
                            error={errors.contract}
                            onChange={(chosenId) => onContractChange(chosenId)}
                        />
                        <TextInputField
                            label={'Company Name'}
                            id={'companyName'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={newEntry.company_name || ''}
                            placeholder={'Enter company name'}
                            error={errors.company_name}
                            onChange={onCompanyNameChange}
                        />
                        <TextInputField
                            label={'Adviser Authorisation Limit £ (9999.99 MAX)'}
                            id={'adviserAuthLimit'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={newEntry.adviser_authorisation_limit || ''}
                            placeholder={'Enter amount'}
                            error={errors.adviser_authorisation_limit}
                            onChange={onAdviserAuthLimitChange}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <RadioButtons
                            id="active"
                            disabled={!hasRole(acceptedRoles, roles)}
                            label="Active?"
                            value={newEntry.active}
                            error={errors.active}
                            onChange={onActiveChange}
                        />
                        <div className={`${form.formColumnSplit}`}>
                            <DateSelect
                                label="Service Start Date"
                                disabled={!hasRole(acceptedRoles, roles) || !newEntry.active}
                                mandatory={true}
                                value={newEntry.start_date}
                                error={errors.start_date}
                                onDateChange={(date) => onServiceDateChange('start_date', date)}
                            />
                            <DateSelect
                                label="Service End Date"
                                disabled={!hasRole(acceptedRoles, roles) || !newEntry.active}
                                mandatory={true}
                                value={newEntry.end_date}
                                error={errors.end_date}
                                onDateChange={(date) => onServiceDateChange('end_date', date)}
                            />
                        </div>
                        <TextInputField
                            label={'Claim File Prefix'}
                            id={'claimFilePrefix'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={newEntry.claim_file_prefix || ''}
                            placeholder={'Enter a claim file prefix'}
                            error={errors.claim_file_prefix}
                            onChange={onClaimFilePrefixChange}
                        />
                    </div>
                </div>
                <Button
                    id="createService"
                    content="Create"
                    disabled={!hasRole(acceptedRoles, roles) || isDisabled}
                />
            </form>
        </div>
    );
};

export default CreateService;
