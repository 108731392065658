import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
    Box,
    Grid,
    Link,
    MenuItem,
    Paper,
    Select,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { updateCourseProvision } from '../../../store/courseService';
import {
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../../store/directusService';
import { loadParticipant } from '../../../store/participantService';
import { POLARIS_VICTORIA_BLUE_BG, POLARIS_WHITE } from '../../../themes/theme';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import { getNameFromId } from '../../../utils/directusFunctions';
import {
    DEFAULT_TABLE_ROW_OPTIONS,
    DEFAULT_TABLE_ROWS_PER_PAGE,
    NO_SELECTION
} from '../../../utils/formValidation/commonConstants';

import classes from '../../../app.module.css';

/**
 * Table for managing course instances. See PPL-615 for details.
 *
 * @param props.rows {Array} Array of course instance objects.
 *
 * @param props.roles {Array} Array of roles for the current user.
 *
 * Each row has
 * - id {string}
 * - ptCode {string}
 * - firstName {string}
 * - lastName {string}
 * - notes {icon}
 *
 * Clicking on the ptCode icon for a row calls calls the Edit Partcicipant Page (once link has been clicked to show sensitive information)
 *
 *
 * @returns {JSX.Element}
 */
const FullProvisionAttendanceTable = (props) => {
    const { rows, roles } = props;

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('courseName');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_TABLE_ROWS_PER_PAGE);

    /**
     * This called when a table head is clicked to sort the table according to the column clicked.
     * @param event
     * @param property
     */
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [hideInfo, setHideInfo] = useState(false);

    const timeLimit = 30;

    useEffect(() => {
        const timer = setTimeout(() => {
            setHideInfo(false);
        }, timeLimit * 1000); // Convert timeLimit to milliseconds

        return () => {
            clearTimeout(timer);
        };
    }, [timeLimit, hideInfo]);

    const onRevealInfo = () => {
        setHideInfo(!hideInfo);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <br />
                    {!hideInfo && (
                        <div className={classes.center}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Grid item xs={1}>
                                    <VisibilityOff sx={{ color: 'red' }} />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        sx={{ color: 'red' }}
                                        xs={8}>
                                        The hidden information is of a sensitive nature. You must
                                        ensure that nobody else has the ability to see your screen
                                        before revealing it.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Link sx={{ cursor: 'pointer' }} onClick={onRevealInfo}>
                                Click here to reveal
                            </Link>
                        </div>
                    )}
                    <Table sx={{ minWidth: 640 }} aria-labelledby="tableTitle" size="medium">
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <RowPlusDropDown
                                            row={row}
                                            key={row.id}
                                            roles={roles}
                                            hideInfo={hideInfo}
                                        />
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    data-testid="tablePagination"
                    rowsPerPageOptions={DEFAULT_TABLE_ROW_OPTIONS}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};

FullProvisionAttendanceTable.propTypes = {
    rows: PropTypes.array.isRequired,
    roles: PropTypes.array,
    id: PropTypes.string
};

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// MUI docs comment.
// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'id',
        numeric: false,
        label: 'Participant ID'
    },
    {
        id: 'firstName',
        numeric: false,
        label: 'First name'
    },
    {
        id: 'lastName',
        numeric: false,
        label: 'Last name'
    },
    {
        id: 'dateSubmitted',
        numeric: false,
        label: 'Date Submitted'
    },
    {
        id: 'status',
        numeric: false,
        label: 'Status'
    }
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}>
                            <Typography variant={'subtitle3'} color={'primary'}>
                                {headCell.label}
                            </Typography>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

const StyledTableRow = styled(TableRow)`
    &:nth-of-type(2n + 1) {
        background-color: ${() => POLARIS_VICTORIA_BLUE_BG};
    }

    ,
&: nth-of-type(even) {
        background-color: ${() => POLARIS_WHITE};
    }
`;

/**
 * Create a single row which has a hidden row underneath it.
 *
 * A hidden row can be shown/hidden by clicking on a row.
 *
 * @param props - The props for the row.
 * Has the following properties:
 * - row: The row to display.
 * - roles: The roles of the user.
 * - sendEditCourse: Callback to send the course id to the parent.
 *
 * @returns {JSX.Element}
 */
function RowPlusDropDown(props) {
    const { row } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleItemClick = (participantId, contractId) => {
        // Set current participant in Redux participant slice.
        dispatch(loadParticipant(participantId));
        dispatch(loadContractDetailsForContractId(contractId));
        dispatch(loadServiceDetailsForContractId(contractId));

        navigate('/edit_participant');
    };

    const { provisionStatusDetails } = useSelector((state) => state.entities.directusService);

    useEffect(() => {
        setParticipants([...row.payload.participants]);
    }, []);

    const [selectedName, setSelectedName] = useState('');

    const [participants, setParticipants] = useState();
    const [payload, setPayload] = useState();

    const handleChange = (e) => {
        const { value } = e.target;
        setSelectedName(value);

        const index = participants.findIndex((item) => item.participantId === row.id);
        const selectedProvisionStatus = provisionStatusDetails.find((item) => item.name === value);

        participants[index] = {
            participantId: participants[index].participantId,
            statusId: selectedProvisionStatus.id,
            dateSubmitted: participants[index].dateSubmitted
        };

        if (participants) {
            setParticipants(participants);
        }

        setPayload({
            ...row.payload,
            participants: participants
        });
    };

    useEffect(() => {
        if (payload) {
            dispatch(updateCourseProvision(`Course Provision details have been updated`, payload));
        }
    }, [payload]);

    useEffect(() => {
        setSelectedName(getNameFromId(provisionStatusDetails, row.status));
    }, [provisionStatusDetails]);

    return (
        <>
            <StyledTableRow hover>
                <TableCell
                    sx={{ width: 'auto', textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                        props.hideInfo && handleItemClick(row.id, row.contractId);
                    }}>
                    {row.ptCode}
                </TableCell>
                {props.hideInfo && <TableCell> {row.firstName}</TableCell>}
                {props.hideInfo && <TableCell>{row.lastName}</TableCell>}
                {props.hideInfo && <TableCell>{reverseFormatDate(row.dateSubmitted)}</TableCell>}
                {props.hideInfo && (
                    <TableCell>
                        <Select
                            style={{ width: '120px' }}
                            size="small"
                            value={selectedName}
                            onChange={handleChange}>
                            <MenuItem value={''} key={NO_SELECTION}></MenuItem>
                            {provisionStatusDetails.map((mi) => (
                                <MenuItem value={mi.name} key={mi.id}>
                                    {mi.name}{' '}
                                </MenuItem>
                            ))}
                        </Select>
                    </TableCell>
                )}
            </StyledTableRow>
        </>
    );
}

RowPlusDropDown.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.string.isRequired,
        ptCode: PropTypes.string.isRequired,
        contractId: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        dateSubmitted: PropTypes.string,
        status: PropTypes.string.isRequired,
        payload: PropTypes.object.isRequired
    }).isRequired,
    hideInfo: PropTypes.bool.isRequired
};

export default FullProvisionAttendanceTable;
