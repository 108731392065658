import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Accordion, Box, Dialog, DialogTitle } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import {
    loadMostRecentAppointmentAttendanceDetails,
    searchParticipantAppointmentsAndSlots,
    updateAppointmentAttendanceDetail
} from '../../../store/calendarService';
import { clearParticipantsCourses } from '../../../store/courseService';
import { selectAttendedStatusId } from '../../../store/dataSelectors';
import {
    loadActionPlanStatusDetails,
    loadAppointmentTypeDetails,
    loadAssetRequestAssetDetails,
    loadAssetRequestStatusDetails,
    loadAttendanceDetails,
    loadBarrierNameDetails,
    loadContractDetailsForContractId,
    loadDocumentTypes,
    loadFinancialRequestStatusDetails,
    loadFinancialRequestTypeDetails,
    loadJobSectorDetails,
    loadServiceDetailsForContractId,
    loadVacancyStageDetails
} from '../../../store/directusService';
import { setErrorMessage } from '../../../store/formsState';
import {
    clearParticipantActionPlans,
    clearParticipantAssetRequests,
    clearParticipantBarriers,
    clearParticipantFinancialRequests,
    clearParticipantJobGoals,
    clearQuestionnaireAnswers,
    loadContractConstants,
    loadParticipantSectionHeadings
} from '../../../store/participantService';
import { clearVacancySubmissions } from '../../../store/recruitmentService';
import { selectLoggedInUserFirstAndLastName } from '../../../store/userSelectors';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import { clearKeys } from '../../../utils/objectUtils';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import AllActiveCourseAndProvisionManagement from '../../courses/allActiveCourseAndProvision/AllActiveCourseAndProvisionManagement';
import Button from '../../formElements/Button';
import CancelButton from '../../formElements/CancelButton';
import SingleSelect from '../../formElements/SingleSelect';
import ParticipantStickyMenu from '../../navigation/participant/ParticipantStickyMenu';
import MandatoryActivitiesSwitch from '../../scheduling/components/MandatoryActivitiesSwitch';
import AccordionHeader from '../../ui/accordion/AccordionHeader';
import AccordionIcon from '../../ui/accordion/AccordionIcon';
import ConfirmPrompt from '../../ui/notices/confirmPrompt/ConfirmPrompt';
import InitialAppointmentPONumberNotice from '../../ui/notices/initialAppointmentPONumber/InitialAppointmentPONumberNotice';
import ActionPlan from '../actionPlan/ActionPlan';
import Barriers from '../barriers/Barriers';
import JobGoals from '../jobGoals/JobGoals';
import Questionnaire from '../questionnaire/Questionnaire';
import Vacancies from '../submissions/vacancies/Vacancies';

import AppointmentPdfManagement from './pdfManagement/AppointmentPDFManagement';
import ESignature from './ESignature';
import General from './General';
import NotesCurrent from './NotesCurrent';
import NotesPast from './NotesPast';
import Requests from './Requests';

import app from '../../../app.module.css';
import local from './styles/appointments.module.css';

const Appointments = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const accordions = [
        'questionnaire',
        'jobReadiness',
        'barriers',
        'actionPlan',
        'courses',
        'request',
        'vacancy'
    ];

    const initialIsActiveState = {
        questionnaire: false,
        jobReadiness: false,
        barriers: false,
        actionPlan: false,
        courses: false,
        request: false,
        vacancy: false
    };

    const initialErrorState = {
        attendanceId: { error: false, message: 'Please select an attendance status' }
    };

    const initialCurrentAppointment = {
        id: '',
        attendanceId: '',
        participantId: '',
        attendanceName: '',
        lastAppointmentNotes: '',
        currentNotes: '',
        nextAppointmentNotes: '',
        calendarAppointmentId: '',
        endTime: '',
        startTime: '',
        mostRecentAppointmentId: ''
    };

    const initialNextAppointment = {
        date: ' - ',
        startTime: ' - ',
        appointmentType: ' - '
    };

    const [isInitiallyActive, setIsInitiallyActive] = useState(initialIsActiveState);
    const [isActive, setIsActive] = useState(initialIsActiveState);
    const [participantsActiveAccordions, setParticipantsActiveAccordions] = useState({});
    const [expanded, setExpanded] = useState('');

    const [attendanceName, setAttendanceName] = useState('');
    const [currentAppointment, setCurrentAppointment] = useState(initialCurrentAppointment);
    const [nextAppointment, setNextAppointment] = useState(initialNextAppointment);

    const [exitFormTemplateId, setExitFormTemplateId] = useState('');
    const [complianceActivityFormQuestionnaireId, setComplianceActivityFormQuestionnaireId] =
        useState('');
    const [doubtFormId, setDoubtFormId] = useState('');

    const [isProceedButtonDisabled, setIsProceedButtonDisabled] = useState(true);
    const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);

    const [adviserSignature, setAdviserSignature] = useState('');
    const [participantSignature, setParticipantSignature] = useState('');
    const [signatureDateTime, setSignatureDateTime] = useState('');
    const [fileName, setFileName] = useState('');

    const [errors, setErrors] = useState(initialErrorState);
    const [keys, setKeys] = useState({ selectedAttendanceStatus: '0' });

    // STORE STATE
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const sectionHeadings = useSelector(
        (state) => state.entities.participantService.participantSectionHeadings
    );
    const contractConstants = useSelector(
        (state) => state.entities.participantService.contractConstants
    );
    const attendanceDetails = useSelector(
        (state) => state.entities.directusService.attendanceDetails
    );
    const documentTypes = useSelector((state) => state.entities.directusService.documentTypes);
    const appointmentTypes = useSelector(
        (state) => state.entities.directusService.appointmentTypeDetails
    );
    const mostRecentAppointment = useSelector(
        (state) => state.entities.calendarService.mostRecentAppointment
    );
    const participantAppointments = useSelector(
        (state) => state.entities.calendarService.participantAppointmentsAndSlots
    );

    const jobSectorDetails = useSelector(
        (state) => state.entities.directusService.jobSectorDetails
    );
    const barrierNameDetails = useSelector(
        (state) => state.entities.directusService.barrierNameDetails
    );
    // for action plan
    const actionPlanStatuses = useSelector(
        (state) => state.entities.directusService.actionPlanStatusDetails
    );
    // for financial requests
    const financialRequestTypeDetails = useSelector(
        (state) => state.entities.directusService.financialRequestTypeDetails
    );
    const financialRequestStatusDetails = useSelector(
        (state) => state.entities.directusService.financialRequestStatusDetails
    );
    // for asset requests
    const assetRequestStatusDetails = useSelector(
        (state) => state.entities.directusService.assetRequestStatusDetails
    );
    const assetRequestAssetDetails = useSelector(
        (state) => state.entities.directusService.assetRequestAssetDetails
    );
    // for vacancies
    const vacancyStageDetails = useSelector(
        (state) => state.entities.directusService.vacancyStageDetails
    );
    const attendedStatusId = useSelector(selectAttendedStatusId);

    const adviserName = useSelector(selectLoggedInUserFirstAndLastName);

    // USE EFFECTS
    useEffect(() => {
        dispatch(clearParticipantBarriers());
        dispatch(clearQuestionnaireAnswers());
        dispatch(clearParticipantActionPlans());
        dispatch(clearParticipantAssetRequests());
        dispatch(clearParticipantFinancialRequests());
        dispatch(clearParticipantsCourses());
        dispatch(clearParticipantJobGoals());
        dispatch(clearVacancySubmissions());
        setErrors(initialErrorState);
        if (attendanceDetails?.length < 1) dispatch(loadAttendanceDetails());
        if (documentTypes?.length < 1) dispatch(loadDocumentTypes());
        if (appointmentTypes?.length < 1) dispatch(loadAppointmentTypeDetails());
    }, []);

    useEffect(() => {
        if (!currentParticipant?.id) return;
        dispatch(loadParticipantSectionHeadings(currentParticipant.contractId));
        dispatch(loadContractConstants(currentParticipant.contractId));
        dispatch(loadContractDetailsForContractId(currentParticipant.contractId));
        dispatch(loadServiceDetailsForContractId(currentParticipant.contractId));
        dispatch(loadMostRecentAppointmentAttendanceDetails(currentParticipant.id));
        dispatch(searchParticipantAppointmentsAndSlots(currentParticipant.id));
    }, [currentParticipant]);

    useEffect(() => {
        if (sectionHeadings?.contractId === currentParticipant?.contractId) {
            const accordionStatus = accordions.reduce(
                (acc, cur) => ({ ...acc, [cur]: sectionHeadings[cur] }),
                {}
            );
            setIsActive(accordionStatus);
            setIsInitiallyActive(accordionStatus);
            setParticipantsActiveAccordions(accordionStatus);

            if (accordionStatus.request) {
                if (assetRequestAssetDetails?.length < 1) dispatch(loadAssetRequestAssetDetails());
                if (assetRequestStatusDetails?.length < 1)
                    dispatch(loadAssetRequestStatusDetails());
                if (financialRequestStatusDetails?.length < 1)
                    dispatch(loadFinancialRequestStatusDetails());
                if (financialRequestTypeDetails?.length < 1)
                    dispatch(loadFinancialRequestTypeDetails());
            }
            if (accordionStatus.actionPlan || accordionStatus.barriers) {
                if (barrierNameDetails?.length < 1) dispatch(loadBarrierNameDetails());
                if (accordionStatus.actionPlan && actionPlanStatuses?.length < 1)
                    dispatch(loadActionPlanStatusDetails());
            }
            if (accordionStatus.jobReadiness || accordionStatus.vacancy) {
                if (jobSectorDetails?.length < 1) dispatch(loadJobSectorDetails());
                if (accordionStatus.vacancy && vacancyStageDetails?.length < 1)
                    dispatch(loadVacancyStageDetails());
            }
        }
    }, [sectionHeadings]);

    useEffect(() => {
        if (!isPDFModalOpen || !state?.appointment || participantAppointments?.length < 1) return;
        configNextAppointment(
            state?.appointment?.date,
            state?.appointment?.startTime,
            participantAppointments
        );
    }, [participantAppointments, isPDFModalOpen]);

    useEffect(() => {
        selectAttendance(currentAppointment.attendanceId);
    }, [currentAppointment.attendanceId]);

    useEffect(() => {
        if (attendanceDetails?.length) {
            setCurrentAppointment((prev) => ({
                ...prev,
                participantId: currentParticipant?.id,
                calendarAppointmentId: state?.appointment?.id
            }));
            initializeAttendance();
        }
    }, [attendanceDetails]);

    useEffect(() => {
        if (!mostRecentAppointment?.id) return;

        setCurrentAppointment((prev) => ({
            ...prev,
            lastAppointmentNotes: mostRecentAppointment.nextAppointmentNotes || '',
            mostRecentAppointmentId: mostRecentAppointment.id
        }));
    }, [mostRecentAppointment]);

    useEffect(() => {
        setIsProceedButtonDisabled(
            Object.values(errors).some((el) => el.error) || attendanceName === 'Not known'
        );
    }, [errors, attendanceName]);

    // HELPER FNS
    const configNextAppointment = (baseDate, baseStartTime, appointmentList) => {
        const baseTimestamp = new Date(`${baseDate} ${baseStartTime}`).getTime();
        let closestFutureDate = null;
        let appointment = null;
        let appointmentType = null;
        appointmentList.forEach((el) => {
            const timestamp = new Date(`${el.date} ${el.startTime}`).getTime();
            if (
                timestamp > baseTimestamp &&
                (closestFutureDate === null || timestamp < closestFutureDate)
            ) {
                closestFutureDate = timestamp;
                appointment = el;
                appointmentType = el.type;
            }
        });
        setNextAppointment({
            date: reverseFormatDate(appointment?.date) || ' - ',
            startTime: appointment?.startTime || ' - ',
            appointmentType: appointmentType || ' - '
        });
    };

    const initializeAttendance = () => {
        const entry = attendanceDetails.find((el) => el.name === 'Not known');
        if (entry) {
            setCurrentAppointment((prev) => ({
                ...prev,
                attendanceId: entry.id,
                attendanceName: entry.name,
                attendanceTime: format(new Date(), `dd / MM / yyyy , HH :mm`).replace(/:/g, ' : ')
            }));
        }
    };

    const configDocumentName = () => {
        const fileName = `${state?.appointment?.type.replaceAll(' ', '_')}_${format(
            new Date(),
            `dd_MMM_yyyy_HH_mm`
        )}`;
        setFileName(fileName);
    };

    const resetAppointment = () => {
        setCurrentAppointment(initialCurrentAppointment);
        setErrors(initialErrorState);
        initializeAttendance();
        setKeys(clearKeys(keys));
        setExpanded('');
    };

    const selectAttendance = (id) => {
        setCurrentAppointment((prev) => ({
            ...prev,
            attendanceId: id
        }));
        const attendanceTitle = attendanceDetails.find((el) => el.id === id)?.name || '';
        setAttendanceName(attendanceTitle);
        if (attendanceTitle === 'Failed to Attend' || attendanceTitle.includes('Cancelled')) {
            setIsActive((prev) => ({
                ...prev,
                questionnaire: false,
                jobReadiness: false,
                barriers: false,
                courses: false,
                request: false,
                vacancy: false
            }));
        } else setIsActive(isInitiallyActive);
    };

    // EVENT HANDLERS
    const onNotesCurrentChange = (value) => {
        setCurrentAppointment((prev) => ({
            ...prev,
            currentNotes: value
        }));
    };

    const onNotesNextChange = (value) => {
        setCurrentAppointment((prev) => ({
            ...prev,
            nextAppointmentNotes: value
        }));
    };

    const onValidateAttendance = () => {
        let isValid = true;
        if (!currentAppointment.attendanceId) {
            dispatch(setErrorMessage('No attendance selected'));
            isValid = false;
        }
        if (!currentAppointment.calendarAppointmentId) {
            dispatch(setErrorMessage('No appointment ID'));
            isValid = false;
        }
        return isValid;
    };

    const onAttendanceSelect = (id) => {
        setErrors(initialErrorState);
        setExpanded('');
        if (
            state?.appointment?.type.toLowerCase() === 'initial' &&
            id === attendedStatusId &&
            contractConstants.requirePoNumberBeforeAppointmentInitialAttended &&
            !currentParticipant.poNumber
        ) {
            setErrors({
                attendanceId: {
                    error: true,
                    message:
                        'Participant Record missing PO number, Initial Appointment cannot be marked as Attended'
                }
            });
        }
        setCurrentAppointment((prev) => ({
            ...prev,
            attendanceId: id,
            attendanceName: attendanceDetails.find((el) => el.id === id)?.name
        }));
    };

    const onAccordionChange = (panel) => setExpanded(expanded === panel ? '' : panel);

    const onCancelAppointment = () => setIsCancelModalOpen(true);

    const onUpdateAppointmentEntry = () => {
        const payload = {
            statusId: currentAppointment.attendanceId,
            notes: currentAppointment.currentNotes,
            nextAppointmentNotes: currentAppointment.nextAppointmentNotes
        };
        dispatch(updateAppointmentAttendanceDetail(payload, state.attendanceAppointmentId));
    };

    const onProceed = () => {
        const isValid = onValidateAttendance();
        if (!isValid) return;
        contractConstants?.displayESignature ? setIsSignatureModalOpen(true) : onSignatureSkip();
        setExitFormTemplateId(contractConstants?.exitFormTemplateId || '');
        setComplianceActivityFormQuestionnaireId(
            contractConstants?.complianceActivityFormQuestionnaireId || ''
        );
        setDoubtFormId(contractConstants?.doubtFormIds[0] || '');
    };

    const onSavePDF = () => {
        onPDFModalClose();
        resetAppointment();
    };

    const onCancelPDF = () => {
        onPDFModalClose();
    };

    const onPDFModalClose = () => {
        setIsPDFModalOpen(false);
    };

    const onAdviserSignatureSave = (signature) => setAdviserSignature(signature);

    const onParticipantSignatureSave = (signature) => {
        setParticipantSignature(signature);
        setSignatureDateTime(format(new Date(), `dd  MMM,  yyyy   HH:mm`));
        configDocumentName();
    };

    const onSignatureConfirm = () => {
        setIsSignatureModalOpen(false);
        setIsPDFModalOpen(true);
        onUpdateAppointmentEntry();
    };

    const onSignatureSkip = () => {
        setIsSignatureModalOpen(false);
        setIsPDFModalOpen(true);
        onUpdateAppointmentEntry();
        setSignatureDateTime(format(new Date(), `dd MMM, yyyy HH:mm`));
        configDocumentName();
    };

    const onSignatureCancel = () => setIsSignatureModalOpen(false);

    const onSignatureModalClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setIsSignatureModalOpen(false);
        }
    };

    const onCancelModalClose = (_, cancelChanges) => {
        if (cancelChanges) {
            resetAppointment();
            setIsCancelModalOpen(false);
            navigate(state?.returnPath);
        }
        if (!cancelChanges) setIsCancelModalOpen(false);
    };

    // RENDER
    return (
        <div className={`${app.container} ${local.appointmentsContainer}`}>
            <div className={app.withSideBar}>
                <div className={local.headingContent}>
                    <h1 className={app.mainHeading}>
                        {state?.appointment?.type || ''}{' '}
                        {reverseFormatDate(state?.appointment?.date)}
                    </h1>
                    {state.showWarning && <span>{InitialAppointmentPONumberNotice()}</span>}
                </div>

                <Accordion
                    slotProps={{ transition: { mountOnEnter: true } }}
                    expanded={expanded === 'general'}
                    onChange={() => onAccordionChange('general')}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <AccordionHeader>General</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <General onClose={onAccordionChange} acceptedRoles={acceptedRoles} />
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    slotProps={{ transition: { mountOnEnter: true } }}
                    expanded={expanded === 'lastNotes'}
                    onChange={() => onAccordionChange('lastNotes')}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <AccordionHeader>Notes from last Appointment</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <NotesPast notes={currentAppointment.lastAppointmentNotes} />
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    hidden={!isActive.questionnaire}
                    slotProps={{ transition: { mountOnEnter: true } }}
                    expanded={expanded === 'questionnaire'}
                    onChange={() => onAccordionChange('questionnaire')}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <AccordionHeader>Questionnaire</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={local.componentSection}>
                            <Questionnaire />
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    hidden={!isActive.jobReadiness}
                    slotProps={{ transition: { mountOnEnter: true } }}
                    expanded={expanded === 'jobGoals'}
                    onChange={() => onAccordionChange('jobGoals')}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <AccordionHeader>Job Readiness & Goals</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={local.componentSection}>
                            <JobGoals
                                roles={roles}
                                acceptedRoles={acceptedRoles}
                                currentParticipant={currentParticipant}
                            />
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    hidden={!isActive.barriers}
                    slotProps={{ transition: { mountOnEnter: true } }}
                    expanded={expanded === 'barriers'}
                    onChange={() => onAccordionChange('barriers')}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <AccordionHeader>Barriers</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={local.componentSection}>
                            <Barriers />
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    hidden={!isActive.actionPlan}
                    slotProps={{ transition: { mountOnEnter: true } }}
                    expanded={expanded === 'actionPlan'}
                    onChange={() => onAccordionChange('actionPlan')}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <AccordionHeader>Action Plan</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={local.componentSection}>
                            <ActionPlan />
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    hidden={!isActive.courses}
                    slotProps={{ transition: { mountOnEnter: true } }}
                    expanded={expanded === 'courses'}
                    onChange={() => onAccordionChange('courses')}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <AccordionHeader>Courses</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={local.componentSection}>
                            <AllActiveCourseAndProvisionManagement />
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    hidden={!isActive.request}
                    slotProps={{ transition: { mountOnEnter: true } }}
                    expanded={expanded === 'requests'}
                    onChange={() => onAccordionChange('requests')}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <AccordionHeader>Requests</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={local.componentSection}>
                            <Requests />
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    hidden={!isActive.vacancy}
                    slotProps={{ transition: { mountOnEnter: true } }}
                    expanded={expanded === 'vacancy'}
                    onChange={() => onAccordionChange('vacancy')}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <AccordionHeader>Vacancy</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={local.componentSection}>
                            <Vacancies />
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    slotProps={{ transition: { mountOnEnter: true } }}
                    expanded={expanded === 'notes'}
                    onChange={() => onAccordionChange('notes')}
                    disabled={!hasRole(acceptedRoles, roles)}>
                    <AccordionSummary
                        expandIcon={<AccordionIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <AccordionHeader>Appointment Notes</AccordionHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <NotesCurrent
                            notesCurrent={currentAppointment.currentNotes}
                            notesNext={currentAppointment.nextAppointmentNotes}
                            onChangeNotesCurrent={onNotesCurrentChange}
                            onChangeNotesNext={onNotesNextChange}
                            onClose={onAccordionChange}
                            roles={roles}
                            acceptedRoles={acceptedRoles}
                        />
                    </AccordionDetails>
                </Accordion>

                <div className={local.appointmentsToolbar}>
                    <SingleSelect
                        id={'selectedAttendanceStatus'}
                        key={keys.selectedAttendanceStatus}
                        label="Attendance"
                        placeholder="Select an attendance status"
                        disabled={!hasRole(acceptedRoles, roles)}
                        customClass="widthControl"
                        menuItems={attendanceDetails || []}
                        selectedId={currentAppointment.attendanceId}
                        selected={
                            attendanceDetails.find(
                                (el) => el.id === currentAppointment.attendanceId
                            ) || {}
                        }
                        error={errors.attendanceId}
                        onChange={(chosenId) => onAttendanceSelect(chosenId)}
                    />

                    <MandatoryActivitiesSwitch
                        checked={state?.appointment?.mandatory}
                        disabled={true}
                    />

                    <div className={local.completeFormActions}>
                        <Button
                            id="appointmentsProceedButton"
                            disabled={isProceedButtonDisabled}
                            content="Proceed"
                            onClick={onProceed}
                        />
                        <CancelButton onClick={onCancelAppointment} />
                    </div>
                </div>

                {isSignatureModalOpen && (
                    <Dialog onClose={onSignatureModalClose} open={isSignatureModalOpen}>
                        <ESignature
                            currentParticipant={currentParticipant}
                            onParticipantSignatureSave={onParticipantSignatureSave}
                            onAdviserSignatureSave={onAdviserSignatureSave}
                            onSignatureSkip={onSignatureSkip}
                            onSignatureConfirm={onSignatureConfirm}
                            onSignatureCancel={onSignatureCancel}
                            attendanceName={attendanceName}
                        />
                    </Dialog>
                )}
                {isPDFModalOpen && (
                    <Dialog fullWidth={true} maxWidth={'md'} open={isPDFModalOpen}>
                        <DialogTitle id="id">
                            <Box display="flex" alignItems="center">
                                <Box flexGrow={1}>Preview PDF</Box>
                            </Box>
                        </DialogTitle>
                        <div className={local.pdfModal}>
                            <div className={local.pdfModalContent}>
                                <AppointmentPdfManagement
                                    onSavePDF={onSavePDF}
                                    onCancelPDF={onCancelPDF}
                                    fileName={fileName}
                                    appointmentType={{
                                        id: state?.appointment.typeId,
                                        name: state?.appointment.type
                                    }}
                                    participantsActiveAccordions={participantsActiveAccordions}
                                    adviserName={adviserName || 'User not logged in'}
                                    currentParticipant={currentParticipant}
                                    participantSignature={participantSignature}
                                    adviserSignature={adviserSignature}
                                    currentAppointment={currentAppointment}
                                    appointment={state?.appointment}
                                    nextAppointment={nextAppointment}
                                    signatureDateTime={signatureDateTime}
                                    exitFormTemplateId={exitFormTemplateId}
                                    doubtFormId={doubtFormId}
                                    complianceActivityFormQuestionnaireId={
                                        complianceActivityFormQuestionnaireId
                                    }
                                />
                            </div>
                        </div>
                    </Dialog>
                )}
                {isCancelModalOpen && (
                    <ConfirmPrompt
                        isOpen={isCancelModalOpen}
                        onCancel={(e) => onCancelModalClose(e, false)}
                        onConfirm={(e) => onCancelModalClose(e, true)}
                        content="Do you want to cancel these changes?"
                    />
                )}
                <div className={app.sideBar}>
                    <ParticipantStickyMenu />
                </div>
            </div>
        </div>
    );
};
export default Appointments;
