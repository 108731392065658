import React from 'react';
import PropTypes from 'prop-types';

import StaticField from '../../formElements/StaticField';

import local from './styles/appointments.module.css';

const NotesPast = ({ notes }) => {
    return (
        <div className={local.componentSection}>
            {notes ? (
                <StaticField id="last-notes" label="Notes from last Appointment" content={notes} />
            ) : (
                <div data-testid="no-notes"> No notes from last appointment </div>
            )}
        </div>
    );
};

export default NotesPast;

NotesPast.propTypes = {
    notes: PropTypes.string
};
