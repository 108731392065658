import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import {
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../../../store/directusService';
import {
    loadRecentParticipants,
    saveCurrentParticipantId,
    setCurrentlySelectedParticipant
} from '../../../../store/participantService';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import Button from '../../../formElements/Button';

import classes from '../../../../commonStyles/tables.module.css';

const RemoveParticipantRow = ({ row, roles, type, onRemoveRow }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];

    const onNav = () => {
        dispatch(setCurrentlySelectedParticipant(row));
        dispatch(loadContractDetailsForContractId(row.contractId));
        dispatch(loadServiceDetailsForContractId(row.contractId));
        navigate('/edit_participant');
        saveCurrentParticipantId(row.id);
        dispatch(loadRecentParticipants());
    };

    return (
        <TableRow
            hover
            className={`${classes.basicRowStripes} ${type === 'SMS' ? classes.rowHeight : ''}`}>
            <TableCell align="center" className={classes.rowLink} onClick={onNav}>
                {row.ptCode}
            </TableCell>
            <TableCell align="center">{row.firstName}</TableCell>
            <TableCell align="center">{row.lastName}</TableCell>
            <TableCell align="center">
                {type === 'SMS' ? (
                    <>{row.selectedContact.contactNumber}</>
                ) : (
                    <>{row.emailAddress === 'z' ? ' - ' : row.emailAddress}</>
                )}
            </TableCell>
            <TableCell align="center">
                {row.currentStatus === 'zz' ? ' - ' : row.currentStatus}
            </TableCell>
            <TableCell align="center">
                <Button
                    id="removeParticipantButton"
                    customClass="noButtonMargin"
                    content="Remove"
                    disabled={!hasRole(acceptedRoles, roles)}
                    onClick={(e) => {
                        e.preventDefault();
                        onRemoveRow();
                    }}
                />
            </TableCell>
        </TableRow>
    );
};

export default RemoveParticipantRow;

RemoveParticipantRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
    onRemoveRow: PropTypes.func
};
