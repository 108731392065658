import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Collapse, TableCell, TableRow } from '@mui/material';

import OnCreateIcon from '../../../../icons/OnCreateIcon';
import OnDeleteIcon from '../../../../icons/OnDeleteIcon';
import OnEditIcon from '../../../../icons/OnEditIcon';
import { deleteBusinessRecord, setCurrentBusiness } from '../../../../store/recruitmentService';
import { MANAGER, RECRUITMENT_MANAGER, SUPERUSER } from '../../../../utils/userRoles';
import BusinessDetails from '../shared/BusinessDetails';

import classes from '../../../../commonStyles/tables.module.css';

const BusinessTableRow = ({ row, roles, toggleDropdown, openId, jobsOfferedForVacancies }) => {
    // HOOKS
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [MANAGER, RECRUITMENT_MANAGER, SUPERUSER];
    const [open, setOpen] = useState(false);
    const [active, setActive] = useState(false);

    // STORE STATE
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS
    const onNavigate = () => {
        navigate('/recruitment_management', {
            state: { accordionPanel: 'myBusinesses' }
        });
    };

    // USE EFFECTS
    useEffect(() => {
        setActive(row.contactCards?.length > 0);
    }, [row]);

    useEffect(() => {
        if (!row || openId !== row.id) setOpen(null);
    }, [openId]);

    useEffect(() => {
        if (
            successMessage.startsWith(`Business record has been updated`) ||
            successMessage.startsWith(`Business record has been deleted`)
        ) {
            onNavigate();
        }
    }, [successMessage]);

    // EVENT HANDLERS
    const onDeleteBusiness = () => {
        dispatch(deleteBusinessRecord(row.id));
    };

    const onEditRow = (row) => {
        dispatch(setCurrentBusiness(row));
        navigate('/edit_business', {
            state: {
                id: row.id,
                accordionPanel: 'myBusinesses'
            }
        });
    };

    const onAddVacancy = (row) => {
        dispatch(setCurrentBusiness(row));
        navigate('/create_vacancy', { state: { id: row.id, accordionPanel: 'myBusinesses' } });
    };

    const onToggleDropdown = (e) => {
        if (e.target.nodeName !== 'TD') return;
        toggleDropdown(row.id, e);
        setOpen(open === row.id ? null : row.id);
    };

    // RENDER
    return !row ? null : (
        <>
            <TableRow
                key={row.id}
                hover
                className={`${classes.tableRowStripes} ${classes.canOpen}`}
                onClick={onToggleDropdown}>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell align="center">{row.vacancies?.length || ''}</TableCell>
                <TableCell align="center">
                    {row.positions > 0
                        ? `${row.positions - row.jobsOffered} / ${row.positions}`
                        : ''}
                </TableCell>
                <TableCell>{row.postcode}</TableCell>
                <TableCell align="right">
                    <OnCreateIcon
                        onCreate={() => onAddVacancy(row)}
                        titleString={'Vacancy'}
                        roles={roles}
                        acceptedRoles={acceptedRoles}
                        active={active}
                        inactiveMessage="No Business Contacts"
                    />
                </TableCell>
                <TableCell align="right">
                    <OnEditIcon
                        onEdit={() => onEditRow(row)}
                        roles={roles}
                        acceptedRoles={acceptedRoles}
                    />
                </TableCell>
                <TableCell align="right">
                    <OnDeleteIcon
                        onDelete={onDeleteBusiness}
                        roles={roles}
                        acceptedRoles={acceptedRoles}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderTop: 'none',
                        borderBottom: 'none'
                    }}
                    colSpan={9}>
                    <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                        <Box>
                            <BusinessDetails
                                row={row}
                                onClose={() => setOpen(null)}
                                jobsOfferedForVacancies={jobsOfferedForVacancies}
                                accordionPanelFrom="myBusinesses"
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default BusinessTableRow;

BusinessTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    openId: PropTypes.string,
    toggleDropdown: PropTypes.func,
    jobsOfferedForVacancies: PropTypes.array
};
