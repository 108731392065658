import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { loadClaimEventTypes, loadContractDetails } from '../../store/directusService';
import {
    createEligibilityGroup,
    searchClaimEvents,
    updateEligibilityGroup
} from '../../store/participantService';
import { hasRole, SUPERUSER } from '../../utils/userRoles';
import IconError from '../IconError';
import AccordionHeader from '../ui/accordion/AccordionHeader';
import AccordionIcon from '../ui/accordion/AccordionIcon';
import LabelledTextField from '../ui/editors/LabelledTextField';
import FormActions from '../ui/formActions/FormActions';
import LoadingSpinner from '../ui/LoadingSpinner';
import DDLMultiOptionPicker from '../ui/pickers/DDLMultiOptionPicker';
import DDLOptionPicker from '../ui/pickers/DDLOptionPicker';
import NoYesRadioPicker from '../ui/pickers/NoYesRadioPicker';

import Claim from './Claim';
import { initialErrorState, validate } from './validateEligibilityGroups';

import app from '../../app.module.css';
import form from '../../commonStyles/formStyles.module.css';

const CreateEligibilityGroup = ({ onClose, roles, formType, row = {} }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [SUPERUSER];
    const initialState = {
        id: '',
        name: '',
        prapFileName: '',
        mandatoryActivity: true,
        employedBeforeClaimableDays: '',
        contractId: '',
        serviceDays: '',
        initialWithoutPo: false,
        claimWindowDays: '',
        jobOutcomeFromJobStartDays: '',
        higherOutcomeFromJobOutcomeDays: '',
        selfEmployedIncomeRequirementDays: '',
        engagements: false,
        earningsToJobOutcome: '',
        earningsToHigherOutcome: '',
        payeHigherOutcomeWindow: '',
        claimTypeIds: []
    };

    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const [isClearSelectedContract, setIsClearSelectedContract] = useState('0');
    const [selectedClaimEventTypes, setSelectedClaimEventTypes] = useState([]);
    const [preSelectedClaimEventTypes, setPreSelectedClaimEventTypes] = useState([]);
    const [isClearClaimEventTypes, setIsClearClaimEventTypes] = useState('1');
    const [claimAccordions, setClaimAccordions] = useState([]);
    const [expanded, setExpanded] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    let msg = '';

    // STORE STATE
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const contractDetails = useSelector((state) => state.entities.directusService.contractDetails);
    const claimEventTypes = useSelector((state) => state.entities.directusService.claimEventTypes);
    const claimEvents = useSelector((state) => state.entities.participantService.claimEvents);

    // OTHER FNS

    const clearForm = () => {
        setNewEntry(initialState);
        setErrors(initialErrorState);
        setIsClearSelectedContract(Math.random());
        setIsClearClaimEventTypes(Math.random());
        setSelectedClaimEventTypes([]);
        setPreSelectedClaimEventTypes([]);
    };

    const clearError = (key) =>
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));

    const onAccordionChange = (panel) => setExpanded(expanded === panel ? '' : panel);

    // USEEFFECTS

    useEffect(() => {
        if (claimEventTypes?.length < 1) dispatch(loadClaimEventTypes());
        dispatch(loadContractDetails());
    }, []);

    useEffect(() => {
        clearForm();
        if (Object.keys(row).length < 1) return;
        setNewEntry({
            ...row,
            earningsToJobOutcome: row.earningsToJobOutcome || '',
            earningsToHigherOutcome: row.earningsToHigherOutcome || '',
            payeHigherOutcomeWindow: row.payeHigherOutcomeWindow || ''
        });
        setSelectedClaimEventTypes(row.claimTypeIds);
        setPreSelectedClaimEventTypes(row.claimTypeIds);
        if (claimEventTypes?.length < 1 || row.claimTypeIds?.length < 1) return;
        const selectedClaimEventTypes = claimEventTypes.filter((el) =>
            row.claimTypeIds.includes(el.id)
        );
        setClaimAccordions(selectedClaimEventTypes);
        dispatch(searchClaimEvents({ eligibilityGroupId: row?.id }));
    }, [row, claimEventTypes]);

    useEffect(() => {
        setIsDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    useEffect(() => {
        if (!successMessage) return;
        if (successMessage.includes(`Eligibility group ${newEntry.name}`)) {
            onFormExit();
        }
    }, [successMessage]);

    // EVENT HANDLERS

    const onUpdate = (key, value) => {
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onFormExit = () => {
        setIsDisabled(false);
        clearForm();
        onClose();
    };

    // FORM SUBMIT
    const onSubmit = (e) => {
        setIsDisabled(true);
        e.preventDefault();
        const { isValid, newErrors } = validate(newEntry, errors);
        setErrors(newErrors);
        if (!isValid) return;
        if (formType === 'create') {
            msg = `Eligibility group ${newEntry.name} has been created`;
            dispatch(createEligibilityGroup(newEntry, msg));
        }
        if (formType === 'edit') {
            msg = `Eligibility group ${newEntry.name} has been updated`;
            dispatch(updateEligibilityGroup(newEntry, msg));
        }
    };

    // RENDER
    let content = '';
    if (contractDetails?.length < 1) content = 'No contract details';
    if (claimEventTypes?.length < 1) content = 'No claim types';
    if (contractDetails?.length < 1 || claimEventTypes?.length < 1)
        <LoadingSpinner content={content} />;

    return (
        <div className={form.formWrapper}>
            <div className={app.sectionHeading}>
                {formType === 'create' ? 'Create Eligibility Group' : 'Edit Eligibility Group'}
            </div>

            <form
                className={form.formWrapper}
                onSubmit={onSubmit}
                data-testid="form_start_CreateEligibilityGroup">
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Group Name'}
                            id={'name'}
                            mandatory={true}
                            value={newEntry.name || ''}
                            placeholder={'Enter group name'}
                            onChange={(e) => {
                                clearError('name');
                                setNewEntry((prev) => ({
                                    ...prev,
                                    name: e.target.value.slice(0, 100)
                                }));
                            }}
                        />
                        {errors.name.error && (
                            <div className={form.selectInputError}>
                                <IconError text={errors.name} />
                            </div>
                        )}

                        <LabelledTextField
                            label={'PRaP File Name'}
                            id={'prapFileName'}
                            value={newEntry.prapFileName || ''}
                            placeholder={'Enter PRaP file name'}
                            onChange={(e) => {
                                clearError('prapFileName');
                                setNewEntry((prev) => ({
                                    ...prev,
                                    prapFileName: e.target.value.slice(0, 100)
                                }));
                            }}
                        />
                        {errors.prapFileName.error && (
                            <div className={form.selectInputError}>
                                <IconError text={errors.prapFileName} />
                            </div>
                        )}

                        <div className={form.optionInputWrapper}>
                            <NoYesRadioPicker
                                id="mandatoryActivity"
                                mandatory={true}
                                disabled={!hasRole(acceptedRoles, roles)}
                                radioButtonPick={newEntry.mandatoryActivity}
                                label="Mandatory Activity Set"
                                onChange={(option) =>
                                    onUpdate('mandatoryActivity', option)
                                }></NoYesRadioPicker>
                        </div>

                        <LabelledTextField
                            label={'Number of Days from Employed Before Claimable'}
                            id={'employedBeforeClaimableDays'}
                            mandatory={true}
                            value={newEntry.employedBeforeClaimableDays}
                            placeholder={'Enter number of days'}
                            onChange={(e) => {
                                clearError('employedBeforeClaimableDays');
                                setNewEntry((prev) => ({
                                    ...prev,
                                    employedBeforeClaimableDays: e.target.value
                                        .replace(/[^\d*]/g, '')
                                        .slice(0, 3)
                                }));
                            }}
                        />
                        {errors.employedBeforeClaimableDays.error && (
                            <div className={form.selectInputError}>
                                <IconError text={errors.employedBeforeClaimableDays} />
                            </div>
                        )}
                    </div>
                    <div className={form.formColumn}>
                        <div>
                            <DDLOptionPicker
                                label={'Contract'}
                                key={isClearSelectedContract}
                                id={'contractId'}
                                mandatory={true}
                                menuItems={contractDetails || []}
                                chosenName={
                                    contractDetails.find((el) => el.id === newEntry.contractId)
                                        ?.name || ''
                                }
                                chosenId={newEntry.contractId}
                                onChange={(chosenId) => {
                                    clearError('contractId');
                                    setNewEntry((prev) => ({ ...prev, contractId: chosenId }));
                                }}
                            />
                            {errors.contractId.error && (
                                <div className={form.selectInputError}>
                                    <IconError text={errors.contractId} />
                                </div>
                            )}
                        </div>

                        <LabelledTextField
                            label={'Time on Service in Days'}
                            id={'serviceDays'}
                            mandatory={true}
                            value={newEntry.serviceDays}
                            placeholder={'Enter number of days'}
                            onChange={(e) => {
                                clearError('serviceDays');
                                setNewEntry((prev) => ({
                                    ...prev,
                                    serviceDays: e.target.value.replace(/[^\d*]/g, '').slice(0, 3)
                                }));
                            }}
                        />
                        {errors.serviceDays.error && (
                            <div className={form.selectInputError}>
                                <IconError text={errors.serviceDays} />
                            </div>
                        )}

                        <div className={form.optionInputWrapper}>
                            <NoYesRadioPicker
                                id="initialWithoutPo"
                                mandatory={true}
                                disabled={!hasRole(acceptedRoles, roles)}
                                radioButtonPick={newEntry.initialWithoutPo}
                                label="Initial without PO"
                                onChange={(option) =>
                                    onUpdate('initialWithoutPo', option)
                                }></NoYesRadioPicker>
                        </div>

                        <LabelledTextField
                            label={'Claim Window Length in Days'}
                            id={'claimWindowDays'}
                            mandatory={true}
                            value={newEntry.claimWindowDays}
                            placeholder={'Enter number of days'}
                            onChange={(e) => {
                                clearError('claimWindowDays');
                                setNewEntry((prev) => ({
                                    ...prev,
                                    claimWindowDays: e.target.value
                                        .replace(/[^\d*]/g, '')
                                        .slice(0, 3)
                                }));
                            }}
                        />
                        {errors.claimWindowDays.error && (
                            <div className={form.selectInputError}>
                                <IconError text={errors.claimWindowDays} />
                            </div>
                        )}
                    </div>
                </div>

                <div className={app.sectionHeading}>Time Bound</div>

                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Days to Job Outcome from Job Start'}
                            id={'jobOutcomeFromJobStartDays'}
                            mandatory={true}
                            value={newEntry.jobOutcomeFromJobStartDays || ''}
                            placeholder={'Enter number of days'}
                            onChange={(e) => {
                                clearError('jobOutcomeFromJobStartDays');
                                setNewEntry((prev) => ({
                                    ...prev,
                                    jobOutcomeFromJobStartDays: e.target.value
                                        .replace(/[^\d*]/g, '')
                                        .slice(0, 3)
                                }));
                            }}
                        />
                        {errors.jobOutcomeFromJobStartDays.error && (
                            <div className={form.selectInputError}>
                                <IconError text={errors.jobOutcomeFromJobStartDays} />
                            </div>
                        )}

                        <LabelledTextField
                            label={'Days to Higher Outcome from Job Outcome'}
                            id={'higherOutcomeFromJobOutcomeDays'}
                            mandatory={true}
                            value={newEntry.higherOutcomeFromJobOutcomeDays || ''}
                            placeholder={'Enter number of days'}
                            onChange={(e) => {
                                clearError('higherOutcomeFromJobOutcomeDays');
                                setNewEntry((prev) => ({
                                    ...prev,
                                    higherOutcomeFromJobOutcomeDays: e.target.value
                                        .replace(/[^\d*]/g, '')
                                        .slice(0, 3)
                                }));
                            }}
                        />
                        {errors.higherOutcomeFromJobOutcomeDays.error && (
                            <div className={form.selectInputError}>
                                <IconError text={errors.higherOutcomeFromJobOutcomeDays} />
                            </div>
                        )}
                    </div>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Days Self-Employed Income Requirement'}
                            id={'selfEmployedIncomeRequirementDays'}
                            mandatory={true}
                            value={newEntry.selfEmployedIncomeRequirementDays}
                            placeholder={'Enter number of days'}
                            onChange={(e) => {
                                clearError('selfEmployedIncomeRequirementDays');
                                setNewEntry((prev) => ({
                                    ...prev,
                                    selfEmployedIncomeRequirementDays: e.target.value
                                        .replace(/[^\d*]/g, '')
                                        .slice(0, 3)
                                }));
                            }}
                        />
                        {errors.selfEmployedIncomeRequirementDays.error && (
                            <div className={form.selectInputError}>
                                <IconError text={errors.selfEmployedIncomeRequirementDays} />
                            </div>
                        )}
                        <div className={form.optionInputWrapper}>
                            <NoYesRadioPicker
                                id="engagements"
                                mandatory={true}
                                disabled={!hasRole(acceptedRoles, roles)}
                                radioButtonPick={newEntry.engagements}
                                label="Engagements?"
                                onChange={(option) =>
                                    onUpdate('engagements', option)
                                }></NoYesRadioPicker>
                        </div>
                    </div>
                </div>

                <div className={app.sectionHeading}>Financial</div>

                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            type="text"
                            label={'Earnings to Job Outcome'}
                            id={'earningsToJobOutcome'}
                            value={newEntry.earningsToJobOutcome || ''}
                            placeholder={'Enter value'}
                            onChange={(e) => {
                                clearError('earningsToJobOutcome');
                                setNewEntry((prev) => ({
                                    ...prev,
                                    earningsToJobOutcome:
                                        e.target.value.replace(/[^\d*.?]/g, '').slice(0, 7) || ''
                                }));
                            }}
                        />
                        {errors.earningsToJobOutcome.error && (
                            <div className={form.selectInputError}>
                                <IconError text={errors.earningsToJobOutcome} />
                            </div>
                        )}
                    </div>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Earnings to Higher Earnings Outcome'}
                            id={'earningsToHigherOutcome'}
                            value={newEntry.earningsToHigherOutcome || ''}
                            placeholder={'Enter value'}
                            onChange={(e) => {
                                clearError('earningsToHigherOutcome');
                                setNewEntry((prev) => ({
                                    ...prev,
                                    earningsToHigherOutcome:
                                        e.target.value.replace(/[^\d*.?]/g, '').slice(0, 7) || ''
                                }));
                            }}
                        />
                        {errors.earningsToHigherOutcome.error && (
                            <div className={form.selectInputError}>
                                <IconError text={errors.earningsToHigherOutcome} />
                            </div>
                        )}

                        <LabelledTextField
                            label={'PAYE Higher Earnings Outcome Window'}
                            id={'payeHigherOutcomeWindow'}
                            value={newEntry.payeHigherOutcomeWindow || ''}
                            placeholder={'Enter number of days'}
                            onChange={(e) => {
                                clearError('payeHigherOutcomeWindow');
                                setNewEntry((prev) => ({
                                    ...prev,
                                    payeHigherOutcomeWindow: e.target.value
                                        .replace(/[^\d*]/g, '')
                                        .slice(0, 3)
                                }));
                            }}
                        />
                        {errors.payeHigherOutcomeWindow.error && (
                            <div className={form.selectInputError}>
                                <IconError text={errors.payeHigherOutcomeWindow} />
                            </div>
                        )}
                    </div>
                </div>

                <div className={app.sectionHeading}>Claim Types</div>

                <div className={`${form.formSection} ${form.basePadding}`}>
                    <div className={form.formColumn}>
                        <div className={form.colSplitSection} id="claimTypeIds">
                            <DDLMultiOptionPicker
                                disabled={!hasRole(acceptedRoles, roles)}
                                id="claimTypeIds"
                                key={isClearClaimEventTypes}
                                mandatory={true}
                                label="Claim Types"
                                placeholder="Claim Types"
                                menuItems={claimEventTypes || []}
                                preSelectedIds={preSelectedClaimEventTypes}
                                chosenIds={selectedClaimEventTypes}
                                onChange={(chosenIds) => {
                                    clearError('claimTypeIds');
                                    setSelectedClaimEventTypes(chosenIds);
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        claimTypeIds: chosenIds
                                    }));
                                }}
                            />
                            {errors.claimTypeIds.error && (
                                <div className={form.selectInputError}>
                                    <IconError text={errors.claimTypeIds} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </form>

            {formType === 'edit' && (
                <div>
                    {claimAccordions.map((el) => (
                        <div key={el.id}>
                            <Accordion
                                expanded={expanded === el.id}
                                onChange={() => onAccordionChange(el.id)}
                                TransitionProps={{ mountOnEnter: true }}>
                                <AccordionSummary
                                    expandIcon={<AccordionIcon />}
                                    aria-controls={`panel1a-content`}
                                    sx={{ borderTop: '1px solid #000' }}
                                    id={`panel1a-header`}>
                                    <AccordionHeader>{el.name}</AccordionHeader>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {expanded === el.id ? (
                                        <Claim
                                            eligibilityGroupId={row.id}
                                            claim={el}
                                            claimEvent={
                                                claimEvents.find(
                                                    (entry) => entry.claimTypeId === el.id
                                                ) || {}
                                            }
                                            roles={roles}
                                            onClose={onAccordionChange}
                                        />
                                    ) : null}
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    ))}
                </div>
            )}
            <FormActions
                onClose={onSubmit}
                onCancel={onFormExit}
                btnText={formType === 'create' ? 'Create' : 'Update'}
                disabled={expanded?.length > 0 || isDisabled}
            />
        </div>
    );
};

CreateEligibilityGroup.propTypes = {
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    formType: PropTypes.string,
    row: PropTypes.object
};

export default CreateEligibilityGroup;
