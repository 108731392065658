import { mapAndFilterUnique } from './arrayUtils';

export const processPlaceholderErrors = (participants, errors) => {
    const processedErrors = {};

    participants.forEach((el) => {
        const ptErrors = errors[el.id];
        if (ptErrors) {
            processedErrors[el.id] = mapAndFilterUnique(
                ptErrors,
                (entry) => `${entry}: could not resolve`
            );
        }
    });

    return processedErrors;
};
