import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { reverseFormatDate } from '../../../../../utils/dateFunctions';
import { getNameFromId } from '../../../../../utils/directusFunctions';

import classes from '../../../../../commonStyles/pdf.module.css';

const BarrierTable = ({ onLoaded }) => {
    // LOCAL STATE
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState('true');

    // STORE STATE
    const barrierNameDetails = useSelector(
        (state) => state.entities.directusService.barrierNameDetails
    );
    const barriers = useSelector(
        (state) => state.entities.participantService.currentParticipantBarriers
    );
    const loadingBarriers = useSelector(
        (state) => state.entities.participantService.loadingParticipantBarriers
    );

    // USE EFFECTS
    useEffect(() => {
        if (!loadingBarriers && loading === 'true') setLoading('startLoading');
        if (!loadingBarriers && loading === 'startLoading') setLoading('false');
    }, [loadingBarriers]);

    useEffect(() => {
        if (barriers?.length < 1 && loading === 'false') {
            onLoaded('barriers');
            return;
        }
        if (barrierNameDetails?.length < 1) return;
        const data = barriers.map((el) => ({
            id: el.id,
            name: getNameFromId(barrierNameDetails, el.barrierId),
            priority: el?.priority || ' - ',
            startDate: el?.startDate ? reverseFormatDate(el.startDate) : ' - ',
            dueDate: el?.dueDate ? reverseFormatDate(el.dueDate) : ' - ',
            status: el?.completeDate ? 'Completed' : 'Current'
        }));
        setRows(data);
    }, [barriers, barrierNameDetails, loading]);

    useEffect(() => {
        rows.length > 0 && onLoaded('barriers');
    }, [rows]);

    // RENDER
    return (
        <div>
            <h3>Barriers</h3>
            {rows.length < 1 ? (
                'No barriers'
            ) : (
                <div className={classes.pdfTable}>
                    <div className={classes.pdfTableHeaders}>
                        <span>Barrier Name</span>
                        <span>Priority</span>
                        <span>Start Date</span>
                        <span>Due Date</span>
                        <span>Status</span>
                    </div>

                    <div className={classes.pdfTableRows}>
                        {rows.map((el, i) => (
                            <div
                                key={el.id}
                                id={`${el.id}--${i}`}
                                className={`${classes.pdfTableRow} ${
                                    i % 2 === 0 ? classes.rowPlain : classes.rowFilled
                                }`}>
                                <span>{el.name}</span>
                                <span>{el.priority}</span>
                                <span>{el.startDate}</span>
                                <span>{el.dueDate}</span>
                                <span>{el.status}</span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

BarrierTable.propTypes = {
    onLoaded: PropTypes.func
};

export default BarrierTable;
