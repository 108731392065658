import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ClearIcon from '@mui/icons-material/Clear';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../../api/pagination';
import { searchUserAppointmentsAndSlots } from '../../../store/calendarService';
import { loadCourseModulesByUserId } from '../../../store/courseService';
import { loadServiceDetailsByService } from '../../../store/directusService';
import { searchParticipants } from '../../../store/participantService';
import { searchUsersByLoggedInUserServiceIds } from '../../../store/userService';
import MultiSelect from '../../formElements/MultiSelect';
import { getServiceIds } from '../utils/calendarUtils';

import screen from '../styles/schedulingAssistant.module.css';

const PT_LOAD_SIZE = DEFAULT_PAGE_LOAD_SIZE;

const SchedulingAssistantAdviserPanel = ({
    advisers,
    selectedAdvisers,
    onChangeSelectedAdvisers,
    activeAdviser,
    onChangeActiveAdviser,
    disabled = false
}) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const [existingServiceIds, setExistingServiceIds] = useState([]);
    const [calendarHeight, setCalendarHeight] = useState(1000);
    const [listItemHeight, setListItemHeight] = useState(30);

    const [userIdsSearched, setUserIdsSearched] = useState([]);

    // STORE STATE
    const loggedInUser = useSelector((state) => state.entities.userService.loggedInUser);
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);
    const usersMetaData = useSelector(
        (state) => state.entities.userService.usersByLoggedInUserServiceIdsMetaData
    );
    const serviceDetailsById = useSelector(
        (state) => state.entities.directusService.serviceDetailsById
    );
    const participantsSearch = useSelector(
        (state) => state.entities.participantService.participantsSearch
    );
    const participantsSearchMetaData = useSelector(
        (state) => state.entities.participantService.participantsSearchMetaData
    );

    // USE EFFECTS
    useEffect(() => {
        const el = document.querySelector(`.${screen.timeviewContentContainer}`);
        el && setCalendarHeight(el.getBoundingClientRect().height - 170);
    });

    useEffect(() => {
        setListItemHeight(~~(calendarHeight / selectedAdvisers.length));
    }, [selectedAdvisers]);

    useEffect(() => {
        if (activeAdviser) {
            const serviceIds = getServiceIds(activeAdviser);
            const missingServiceDetailIds = serviceIds.filter((el) => !(el in serviceDetailsById));
            if (missingServiceDetailIds.length)
                dispatch(loadServiceDetailsByService(missingServiceDetailIds));
            const newServiceIds = serviceIds.filter((el) => !existingServiceIds.includes(el));
            if (newServiceIds.length) {
                dispatch(
                    searchParticipants(
                        { serviceIds: [...existingServiceIds, ...newServiceIds] },
                        0,
                        PT_LOAD_SIZE
                    )
                );
            }
            setExistingServiceIds([...existingServiceIds, ...newServiceIds]);
        }
    }, [activeAdviser]);

    useEffect(() => {
        if (!participantsSearchMetaData.last && existingServiceIds?.length > 0) {
            dispatch(
                searchParticipants(
                    { serviceIds: existingServiceIds },
                    participantsSearchMetaData.number + 1,
                    PT_LOAD_SIZE
                )
            );
        }
    }, [participantsSearch, participantsSearchMetaData]);

    // EVENT HANDLERS
    const onToggleActiveAdviser = (adviser) => {
        adviser.id === activeAdviser.id
            ? onChangeActiveAdviser('')
            : onChangeActiveAdviser(adviser);
    };

    const onRemoveAdviser = (id, e) => {
        e.stopPropagation();
        onChangeSelectedAdvisers(selectedAdvisers.filter((el) => el.id !== id));
        id === activeAdviser.id && onChangeActiveAdviser('');
    };

    const onUpdateSelectedAdvisers = (chosenIds) => {
        !chosenIds.includes(activeAdviser.id) && onChangeActiveAdviser('');
        onChangeSelectedAdvisers(chosenIds.map((el) => advisers.find((entry) => entry.id === el)));
        const userIds = chosenIds.filter((el) => !userIdsSearched.includes(el));
        userIds.length > 0 &&
            userIds.forEach((el) => {
                dispatch(loadCourseModulesByUserId(el));
                dispatch(searchUserAppointmentsAndSlots(el));
            });

        setUserIdsSearched((prev) => [...prev, ...userIds]);
    };

    const onLoadMoreAdvisers = () => {
        if (usersMetaData?.last) return;
        dispatch(
            searchUsersByLoggedInUserServiceIds(
                loggedInUser.serviceIds,
                !users.length ? 0 : usersMetaData.number + 1
            )
        );
    };

    // RENDER
    return (
        <div className={screen.adviserPanel}>
            <div>
                <MultiSelect
                    id="adviserIds"
                    label="Advisers"
                    placeholder="Select advisers..."
                    disabled={disabled}
                    mandatory={true}
                    capitalizeList={false}
                    customClass="absolutePosition"
                    menuItems={advisers || []}
                    preSelectedIds={selectedAdvisers.map((el) => el.id)}
                    preSelects={selectedAdvisers}
                    onChange={onUpdateSelectedAdvisers}
                    onLoadMoreItems={onLoadMoreAdvisers}
                    moreItemsToLoad={!usersMetaData.last}
                />
            </div>
            <div className={screen.selectedAdvisers}>
                {selectedAdvisers.map((el) => (
                    <div
                        key={el.id}
                        data-testid={'selected-advisers'}
                        onClick={() => onToggleActiveAdviser(el)}
                        className={`${screen.adviserListItem} ${activeAdviser.id === el.id ? screen.selected : ''}`}
                        style={{ height: `${listItemHeight}px` }}>
                        <span
                            className={screen.clearIcon}
                            onClick={(e) => onRemoveAdviser(el.id, e)}>
                            <ClearIcon />
                        </span>
                        <span className={screen.title}>
                            {' '}
                            {el.firstName} {el.lastName}{' '}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

SchedulingAssistantAdviserPanel.propTypes = {
    advisers: PropTypes.arrayOf(PropTypes.object),
    selectedAdvisers: PropTypes.arrayOf(PropTypes.object),
    onChangeSelectedAdvisers: PropTypes.func,
    activeAdviser: PropTypes.object,
    onChangeActiveAdviser: PropTypes.func,
    disabled: PropTypes.bool
};

export default SchedulingAssistantAdviserPanel;
