import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import {
    loadCallOutcomes,
    loadFrequencyDetails,
    loadHoursPerWeekDetails,
    loadJobSectorDetails,
    loadOwnPlacementDetails,
    loadPlacementEmploymentTypeDetails,
    loadPlacementStatusDetails,
    loadWorkConfidence
} from '../../../../store/directusService';
import { loadPlacementsForParticipant } from '../../../../store/recruitmentService';
import { selectLoggedInUser } from '../../../../store/userSelectors';
import { loadUsersByServiceIds } from '../../../../store/userService';
import { SCREEN_SETTINGS } from '../../../../themes/theme';
import { getNameFromId } from '../../../../utils/directusFunctions';
import { hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../../utils/userRoles';
import AccordionHeader from '../../../ui/accordion/AccordionHeader';
import AccordionIcon from '../../../ui/accordion/AccordionIcon';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import AddOwnPlacementEditor from './placement/AddOwnPlacementEditor';
import Placement from './placement/Placement';

import app from '../../../../app.module.css';
import local from '../submissionStyles/placement.module.css';

const PlacementManagement = ({ openLastCreatedPlacement, setOpenLastCreatedPlacement }) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [expanded, setExpanded] = useState('');
    const [rows, setRows] = useState([]);
    const [ownPlacementEditor, setOwnPlacementEditor] = useState(false);
    const participantClosedStatusRoles = [MANAGER, QUALITY, SUPERUSER];

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const placements = useSelector((state) => state.entities.recruitmentService.placements);
    const currentPlacement = useSelector(
        (state) => state.entities.recruitmentService.currentPlacement
    );
    const {
        callOutcomes,
        frequencyDetails,
        hoursPerWeekDetails,
        jobSectorDetails,
        ownPlacementDetails,
        participantStatusDetails,
        placementEmploymentTypeDetails,
        placementStatusDetails,
        workConfidence
    } = useSelector((state) => state.entities.directusService);

    // EVENT HANDLERS
    const setOwnPlacement = () => {
        onOwnPlacementEditor(true);
    };

    const onOwnPlacementEditor = (setOpen) => {
        setOwnPlacementEditor(setOpen);
    };

    // USE EFFECTS
    useEffect(() => {
        currentParticipant?.serviceId &&
            dispatch(loadUsersByServiceIds([currentParticipant.serviceId]));
        callOutcomes?.length < 1 && dispatch(loadCallOutcomes());
        placements?.length < 1 && dispatch(loadPlacementsForParticipant(currentParticipant.id));
        ownPlacementDetails?.length < 1 && dispatch(loadOwnPlacementDetails());
        placementEmploymentTypeDetails?.length < 1 &&
            dispatch(loadPlacementEmploymentTypeDetails());
        placementStatusDetails?.length < 1 && dispatch(loadPlacementStatusDetails());
        hoursPerWeekDetails?.length < 1 && dispatch(loadHoursPerWeekDetails());
        jobSectorDetails?.length < 1 && dispatch(loadJobSectorDetails());
        frequencyDetails?.length < 1 && dispatch(loadFrequencyDetails());
        workConfidence?.length < 1 && dispatch(loadWorkConfidence());
        if (openLastCreatedPlacement) {
            setExpanded(currentPlacement.id);
            setOpenLastCreatedPlacement(false);
        }
    }, []);

    useEffect(() => {
        if (placements?.length < 1) return;

        const rows = placements.map((el) => ({
            ...el
        }));
        setRows(rows);
    }, [placements]);

    const onAccordionChange = (panel) => setExpanded(expanded === panel ? '' : panel);

    // RENDER
    let content = '';
    if (callOutcomes?.length < 1) content = 'No call outcome details';
    if (placementEmploymentTypeDetails?.length < 1)
        content = 'No placement employment type details';
    if (ownPlacementDetails?.length < 1) content = 'No own placement details';
    if (placementStatusDetails?.length < 1) content = 'No placement status details';
    if (hoursPerWeekDetails?.length < 1) content = 'No hours per week details';
    if (jobSectorDetails?.length < 1) content = 'No job sector details';
    if (frequencyDetails?.length < 1) content = 'No frequency details';
    if (workConfidence?.length < 1) content = 'No work confidence details';

    if (
        callOutcomes?.length < 1 ||
        ownPlacementDetails?.length < 1 ||
        placementEmploymentTypeDetails?.length < 1 ||
        placementStatusDetails?.length < 1 ||
        hoursPerWeekDetails?.length < 1 ||
        jobSectorDetails?.length < 1 ||
        frequencyDetails?.length < 1 ||
        workConfidence?.length < 1
    )
        return <LoadingSpinner content={content} />;

    return (
        <div>
            <div className={app.sectionHeading}>Placements</div>
            <Button
                disabled={
                    getNameFromId(participantStatusDetails, currentParticipant.statusId) ===
                        'Closed' && !hasRole(participantClosedStatusRoles, loggedInUser.roles)
                }
                color="primary"
                variant="contained"
                size={'small'}
                onClick={setOwnPlacement}>
                Add Own Placement
                <Box width={SCREEN_SETTINGS.gap.medium} />
                <AddCircleOutlineIcon />
            </Button>

            <AddOwnPlacementEditor
                open={ownPlacementEditor}
                roles={loggedInUser.roles}
                onOwnPlacementEditor={onOwnPlacementEditor}
                placementEmploymentTypeDetails={placementEmploymentTypeDetails}
                hoursPerWeekDetails={hoursPerWeekDetails}
                jobSectorDetails={jobSectorDetails}
                ownPlacementDetails={ownPlacementDetails}
            />
            <div className={local.accordionContent}>
                {rows.map((el) => (
                    <div key={el.id}>
                        <Accordion
                            expanded={expanded === el.id}
                            onChange={() => onAccordionChange(el.id)}
                            TransitionProps={{ mountOnEnter: true }}>
                            <AccordionSummary
                                expandIcon={<AccordionIcon />}
                                aria-controls={`panel1a-content`}
                                sx={{ borderTop: '1px solid #000' }}
                                id={`panel1a-header`}>
                                <AccordionHeader>
                                    <div className={local.accordionTitle}>
                                        <span>{el.code}</span>
                                        <span>{el.businessName}</span>
                                        <span>{el.vacancyTitle}</span>
                                    </div>
                                </AccordionHeader>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Placement
                                    row={el}
                                    roles={loggedInUser.roles}
                                    placementEmploymentTypeDetails={placementEmploymentTypeDetails}
                                    placementStatusDetails={placementStatusDetails}
                                    hoursPerWeekDetails={hoursPerWeekDetails}
                                    jobSectorDetails={jobSectorDetails}
                                    ownPlacementDetails={ownPlacementDetails}
                                    callOutcomes={callOutcomes}
                                    frequencyDetails={frequencyDetails}
                                    workConfidence={workConfidence}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </div>
                ))}
            </div>
        </div>
    );
};

PlacementManagement.propTypes = {
    openLastCreatedPlacement: PropTypes.bool.isRequired,
    setOpenLastCreatedPlacement: PropTypes.func.isRequired
};

export default PlacementManagement;
