import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getTimeFromDate, reverseFormatDate } from '../../../../../utils/dateFunctions';

import classes from '../../../../../commonStyles/pdf.module.css';

const QuestionnaireTable = ({ onLoaded }) => {
    // LOCAL STATE
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState('true');

    // STORE STATE
    const questionnaires = useSelector((state) => state.entities.participantService.questionnaires);
    const loadingQuestionnaires = useSelector(
        (state) => state.entities.participantService.loadingQuestionnaires
    );
    const questionnaireAnswers = useSelector(
        (state) => state.entities.participantService.questionnaireAnswers
    );
    const loadingQuestionnaireAnswers = useSelector(
        (state) => state.entities.participantService.loadingQuestionnaireAnswers
    );

    // USE EFFECTS
    useEffect(() => {
        if (!loadingQuestionnaireAnswers && !loadingQuestionnaires && loading === 'true')
            setLoading('startLoading');
        if (!loadingQuestionnaireAnswers && !loadingQuestionnaires && loading === 'startLoading')
            setLoading('false');
    }, [loadingQuestionnaireAnswers]);

    useEffect(() => {
        if (
            (questionnaireAnswers?.length < 1 || questionnaires?.length < 1) &&
            loading === 'false'
        ) {
            onLoaded('questionnaire');
            return;
        }
        const data = questionnaireAnswers.map((el) => ({
            code: el?.code || ' - ',
            title: questionnaires.find((entry) => entry.id === el.questionnaireId)?.title || ' - ',
            dateStarted: el?.dateStarted ? reverseFormatDate(el?.dateStarted) : ' - ',
            dateCompleted: el?.dateCompleted ? reverseFormatDate(el?.dateCompleted) : ' - ',
            time: el?.dateCompleted ? getTimeFromDate(el.dateCompleted) : ' - ',
            status: el?.draft ? 'In-Progress' : 'Completed'
        }));
        setRows(data);
    }, [questionnaires, questionnaireAnswers, loading]);

    useEffect(() => {
        rows.length > 0 && onLoaded('questionnaire');
    }, [rows]);

    // RENDER
    return (
        <div>
            <h3>Questionnaire</h3>
            {rows.length < 1 ? (
                'No questionnaires'
            ) : (
                <div className={classes.pdfTable}>
                    <div className={classes.pdfTableHeaders}>
                        <span>ID</span>
                        <span>Type</span>
                        <span>Date Started</span>
                        <span>Completed Date</span>
                        <span className={classes.alignColInMed}>Time</span>
                        <span>Status</span>
                    </div>

                    <div className={classes.pdfTableRows}>
                        {rows.map((el, i) => (
                            <div
                                key={el.code}
                                id={`${el.code}--${i}`}
                                className={`${classes.pdfTableRow} ${
                                    i % 2 === 0 ? classes.rowPlain : classes.rowFilled
                                }`}>
                                <span>{el.code}</span>
                                <span>{el.title}</span>
                                <span>{el.dateStarted}</span>
                                <span
                                    className={`${
                                        el.dateCompleted === ' - '
                                            ? classes.alignColInMax
                                            : classes.alignColInMed
                                    }`}>
                                    {el.dateCompleted}
                                </span>
                                <span
                                    className={`${
                                        el.time === ' - '
                                            ? classes.alignColInMedPlus
                                            : classes.alignColInMed
                                    }`}>
                                    {el.time}
                                </span>
                                <span>{el.status}</span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

QuestionnaireTable.propTypes = {
    onLoaded: PropTypes.func
};

export default QuestionnaireTable;
